var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{staticClass:"size",attrs:{"vs-type":"flex","id":'popup',"fullscreen":"","title":_vm.studentDetails.identity +
      ' - ' +
      _vm.studentDetails.person_name +
      ' - ' +
      _vm.studentDetails.level +
      ' - ' +
      _vm.studentDetails.iiml_level +
      ' - ' +
      _vm.unixTimestampConvert(_vm.studentDetails.last_call),"active":_vm.open},on:{"update:active":function($event){_vm.open=$event}}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"6"}}),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"flex-start","vs-w":"6"}})],1),_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start","vs-w":"6"}},[_c('vs-row',[_c('vs-card',[_c('card1',{attrs:{"studentDetails":_vm.studentDetails}})],1)],1)],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start","vs-w":"6"}},[_c('card3',{attrs:{"payload":_vm.studentDetails}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }