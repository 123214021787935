<template>
  <vs-card style="height: 840px; overflow-y: auto">
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
        style="height:100px"
      >
        <vs-navbar
          text-color="black"
          color="rgba(241, 241, 241, 1.6)"
          active-text-color="white"
          class="EngageNavbar"
          v-model="ActiveNavBarTab"
        >
          <vs-navbar-item index="ALL" class="nav_item">
            <a>ALL</a>
          </vs-navbar-item>
          <vs-navbar-item index="CALL" class="nav_item">
            <a>CALL</a>
          </vs-navbar-item>
          <vs-navbar-item index="VISIT" v-if="!getrole('exed2')" class="nav_item">
            <a>VISIT</a>
          </vs-navbar-item>
          <vs-navbar-item index="EMAIL" v-if="!getrole('exed2')" class="nav_item">
            <a>EMAIL</a>
          </vs-navbar-item>
          <vs-navbar-item index="onboard form" class="nav_item">
            <a>ONBOARDING</a>
          </vs-navbar-item>
          <vs-navbar-item index="referral" class="nav_item">
            <a>REFERRAL</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item index="APPLICATION">
            <a>WILEY APPLICATION</a>
          </vs-navbar-item> -->
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-show="ActiveNavBarTab === 'ALL'" style="width: 100%">
      <all-section
        v-if="ActiveNavBarTab === 'ALL'"
        :candidate_details="candidate_details"
        @change-navBar-options="setNavBar"
      ></all-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CALL'" style="width: 100%">
      <call-section
        v-if="ActiveNavBarTab === 'CALL' && !getrole('exed2')"
        :payload="candidate_details"
      ></call-section>
      <iiml-ba-call-section
        v-if="ActiveNavBarTab === 'CALL' && getrole('exed2')"
        :payload="candidate_details"
      ></iiml-ba-call-section>
    </div>
    <div v-show="ActiveNavBarTab === 'VISIT'" style="width: 100%">
      <visit-section
        v-if="ActiveNavBarTab === 'VISIT'"
        :payload="candidate_details"
      ></visit-section>
    </div>
    <div v-show="ActiveNavBarTab === 'WHATSAPP'" style="width: 100%">
      <!-- <whatsapp-section
        v-if="ActiveNavBarTab === 'WHATSAPP'"
        :payload="candidate_details"
      ></whatsapp-section> -->
    </div>
    <div v-show="ActiveNavBarTab === 'onboard form'" style="width: 100%">
      <onbaord-form
        v-if="ActiveNavBarTab === 'onboard form'"
        :payload="candidate_details"
      ></onbaord-form>
    </div>
    <div v-show="ActiveNavBarTab === 'referral'" style="width: 100%">
      <referral-form
        v-if="ActiveNavBarTab === 'referral'"
        :payload="candidate_details"
      ></referral-form>
    </div>
    <!-- <div v-show="ActiveNavBarTab === 'APPLICATION'" style="width: 100%">
      <application-wiley
        v-if="ActiveNavBarTab === 'APPLICATION'"
        :payload="candidate_details"
      ></application-wiley>
    </div> -->
  </vs-card>
</template>

<script>
import AllSection from "./AllSection.vue";
import CallSection from "./CallSection.vue";
import VisitSection from "./VisitSection.vue";
import EmailSection from "./EmailSection.vue";
import WhatsappSection from "./WhatsappSection.vue";
import CallSectionIIMLBA from "./CallSectionIIMLBA.vue";
import OnboardForm from "../InfoSection/onboard.vue";
import Referral from "./referralSection.vue";
import ApplicationWiley from "./ApplicationWiley.vue"
import eventbus from "../../eventbus.js";
export default {
  props: {
    candidate_details: {
      required: true,
      type: Object
    }
  },
  components: {
    "all-section": AllSection,
    "call-section": CallSection,
    "iiml-ba-call-section": CallSectionIIMLBA,
    "visit-section": VisitSection,
    "email-section": EmailSection,
    "whatsapp-section": WhatsappSection,
    "onbaord-form": OnboardForm,
    "referral-form": Referral,
    "application-wiley": ApplicationWiley
  },
  data() {
    return {
      ActiveNavBarTab: "ALL"
    };
  },
  mounted(){
    eventbus.$on("change-connection-status",(data, status) => {
      console.log("change-connection-status1", data, status);
      this.ActiveNavBarTab = 'CALL';
      setTimeout(() => {
        eventbus.$emit("change-connection-stat", data, status);
      }, 750);
    });
  },
  watch: {},
  methods: {
    setNavBar(option) {
      this.ActiveNavBarTab = option;
    }
  }
};
</script>

<style>
.vs-navbar--item .is-active-item .vs-navbar-item-black{
  color: black;
    background: #0044BA;
    border-radius: 15px;
}
.EngageNavbar{
     /* box-shadow: none !important; 
     -webkit-box-shadow: none !important; */
    /* width: max-content; */
    display: flex;
    justify-content: center;
    width:100%;
    border-radius: 15px;
    padding-block: 8px;
}
.EngageNavbar .nav_item a{
    background: rgba(241, 241, 241, 0.6);
    border-radius: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-inline:5px;
    text-align: center;
    color: #000000;
    }

  .EngageNavbar .vs-navbar--item.is-active-item .vs-navbar-after {
    background:rgba(241, 241, 241, 0.6)!important;
}  
.EngageNavbar .is-active-item > a {
  background: #0044bf;
  color:white;
  border-radius: 10px;
  padding-inline: 15px;
  padding-block: 5px;
}
</style>