<template>
  <vs-popup fullscreen id="delays-by-subteam" :title="title" :active.sync="open">
    <leadsDataTable />
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-chip color="primary">
          <b>{{ totalCount }}</b>
        </vs-chip>
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import leadsDataTable from "./LeadsDataTable.vue";
import axios from "axios";
import constants from "../../../constants.json";

export default {
  data() {
    return {
      open: false,
      searchd_obj: {},
      url: "",
      response: "",
      currentTablePage: 1,
      tablelinks: 0,
      sendingurl: "",
      title: "",
      totalCount: 0
    };
  },
  components: {
    leadsDataTable
  },
  watch: {
    currentTablePage: function() {
      this.paginationRequests();
    },
    open(value) {
      if (!value) {
        EventBus.$emit("updateCoverage");
      }
    }
  },
  mounted() {
    // document.getElementById("delays-by-subteam").children[1].style.width =
    //   "80%";
    EventBus.$on("open-delays-subteam", payload => {
      console.log("usahsdui", payload);
      this.currentTablePage = 1;
      this.searchd_obj = payload[0];
      this.url = payload[1];
      this.response = payload[2];
      this.sendingurl = payload[3];
      this.tablelinks = this.response.last_page;
      this.title = payload[4];
      this.totalCount = payload[2].total;
      console.log("usahsdui", this.title);
      this.open = true;
    });
    EventBus.$on("refreshCoveragePopup", () => {
      this.paginationRequests();
    });
  },
  methods: {
    paginationRequests() {
      let url = `${constants.SERVER_API}${this.sendingurl}`;
      this.searchd_obj.page = this.currentTablePage;
      axios
        .post(url, this.searchd_obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          response.data.data.forEach(lead => {
            lead.engagements = [];
            lead.mobile = [];
            lead.email = [];
          });
          this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style></style>
