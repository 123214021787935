<template>
  <div>
    <div v-for="(application, index) in this.application_status" :key="index">
      <vs-card>
        <h5 style="margin-left: 1%">{{application[0].loan_id}} :</h5  >
        <vs-row vs-w="12" style="margin-top: 2%">
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1.5"
              >
              <p ><b>LoanID</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1.5"
              >
                <p ><b>Status</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
              >
                <p><b>Disbursed Amount</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                
              >
                <p><b>ROI</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p ><b>Amount</b></p>
          </vs-col>
        
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p ><b>Tenure</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p><b>EMI</b></p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
              >
                <p><b>Date</b></p>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-top: 2%" v-for="item in application" :key="item.id">
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1.5"
              >
              <p>{{item.loan_id}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1.5"
              >
                <p>{{item.status}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
              >
              <p >{{item.disbursed_amount}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p >{{item.roi}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p>{{item.amount}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p>{{item.tenure}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
              >
                <p>{{item.emi}}</p>
          </vs-col>
          <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
              >
                <p>{{item.timestamp}}</p>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      application_status: [],
    };
  },
  mounted(){ 
    this.getWebhookApplicationStatus(); 
    console.log("application failures",this.application_status);
  },
  methods:{
    getWebhookApplicationStatus(){
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        identity: this.studentDetails.mwb.identity
      };
      // let url =  `https://liquiloans.milesforce.com/api/getLiveWebhookApplicationStatus`;
      let url =  `https://liquiloans.milesforce.com/api/getWebhookApplicationStatusMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Application Status", response.data.data);
          this.application_status = response.data.data.application_status;
          this.customDateFormat(this.application_status);
          const groupArrayBy = (prop) => (arr) =>
          Object .values (
            arr .reduce ((a, x) => ({...a, [x [prop]]: [... (a [x [prop]] || []), x]}), {})
          )
          const input = this.application_status;
          this.application_status = groupArrayBy ('loan_id') (input);
         })
        .catch((error) => {
         this.handleError(error);
        });
    },
    customDateFormat(application_status){
        for (let i = 0; i < application_status.length; i++) {
          const element = application_status[i];
          this.application_status[i].timestamp = moment(element.timestamp).format("YYYY-MM-DD hh:mm:ss");
        }
    }
  }
}
</script>
<style>
</style>