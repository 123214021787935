<template>
    <div style="color: #2c2c2c">
      <vs-row class="heading_section" style="
              padding: 4rem 2rem;
              background-image: url(https://milesforce.com/img/vuesax-login-bg.04351a33.jpg);
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            ">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <div>
            <h1 class="mb-4">Welcome</h1>
            <p>
              Hi, this is <b>{{ spoc_name }}</b> from miles education, AM i speaking with <b>{{ candidate_name }}</b>
            </p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="mt-3 row_data">
        <vs-col vs-w="12">
          <h3><b>Greetings of the day to you!</b></h3>
          <p class="mt-2 ">
            I’m calling you to commence your onboarding process for <b>{{courses}}</b>. Is it a good time to talk?
          </p>
          <h4 class="pt-5 mb-2"><b>(If the candidate says yes)</b></h4>
          <p class="mb-5">
            Thank you for your time ! Firstly, Welcome Miles Education Family. At Miles, we believe in providing end-to-end
            support to our candidates. Having said that, I am happy to let you know that I will be your Dedicated Student
            Relationships Manager / SR Manager throughout your journey with Miles. It means, I will be the one point of
            contact for all kinds of assistance or clarification or concerns you may have throughout your journey with
            Miles.
          </p>
          <p class="mb-5">
            So I would like to request that you kindly save my number to receive timely communications from my end.
          </p>
          <p class="mb-5">
            As I can check that you have enrolled with us successfully for <b>{{courses}}</b> on Date  <b>{{ enrollmentDate }}</b>
          </p>
          <p>
            I would like to share the benefits of what Miles offers for the subscription.
          </p>
        </vs-col>
      </vs-row>
      <vs-row class="mt-3 row_data">
        <vs-col vs-w="12">
          <h3 class="my-5"><b>CPA Benefits:-</b></h3>
          <!-- <p class="py-5 my-6">
            May I know if you have any other professional qualifications like CA?
          </p>
          <p><strong>3 options here: </strong></p> -->
          <ul>
            <li>Miles CPA - Study Guides - MC Graw Hill – authored by Varun Jain.</li>
            <li>Miles Online Test Bank access for online practice questions and mock exams (8000+ MCQs and 500+ Simulations
              including DRS).</li>
            <li>350+ hours of Blended learning experience:
              <ul class="inside_list">
                <li><b>130+ hours of pre-recorded videos including both "concepts on the whiteboard" and "annotations on your
                  book" by Varun Jain.</b></li>
                <li> <b>220+ hours of live online & classroom training by Varun Jain & Team spread over 100+ webinar &
                  classroom sessions</b></li>
              </ul>
            </li>
            <li>Up to 100 one-on-one mentoring sessions for CPA formalities and content/subject doubts & queries (book 15-30 min slots on Miles LMS)</li>
            <li>360 degree support - Exam Eligibility, State Board Selection, Foreign Credential Evaluation, NTS</li>
            <li>CPA licensure assistance  ‘</li>
            <li>Placement guarantee with Big 4 & MNCs</li>
            <li>Global mobility options including US & Canada </li>
            <li>Additional support - Support-till-you-pass, M.A.C Membership, Subvention Facility (EMIs), Bridge course (at additional cost payable to University.</li>
            <li>Unlimited mentoring session.</li>
            <li>Miles CPA 101 Bootcamp.
              <ul  class="inside_list">
                <li>If you are a final year student/fresh graduate/early-stage professional, looking for a job with Big 4/MNCs than MilesCPA.101 Bootcamp is for you</li>
              </ul>
            </li>
            <li>
              MilesFAB (Finance & Accounting Basics).
              <ul  class="inside_list">
                <li>This course is primarily aimed at students from a non-accounting background who are facing difficulty in grasping the nuances of the financial concepts in the CPA/CMA course.</li>
                <li>Also, useful for students who have lost touch with accounting since graduation and/or those who just need a refresher course. </li>
              </ul>
            </li>
            <li>Also, I would like to share our classes timings and duration
              <ul  class="inside_list">
                <li>During classes: </li>
                <li>Varun’s webinars @4 hrs/week </li>
                <li>Varun’s videos @6 hrs/week </li>
                <li>Practice webinars @6 hrs/week </li>
                <li>Practice sessions @6 hrs/week </li>
                <li>Post-classes: Revision @22 hours/week </li>
                <li>Have to share the time table according to the batch.</li>
              </ul>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <vs-row class="row_data">
        <vs-col vs-w="12">
          <h3 class="my-5"><b>CMA Benefits:- </b></h3>
          <ul>
            <li>Miles CMA Study Materials compiled in exam-focused notes format and including multiple aids for conceptual understanding and memory.</li>
            <li>
              250+ hours of blended learning experience:
              <ul  class="inside_list">
                <li>
                  50+ hours of pre-recorded videos including both "concepts on the whiteboard" & "annotations on your book" by Varun Jain.
                </li>
                <li>
                  200+ hours of live online & classroom training by Varun Jain & Team spread over 100+ webinar & classroom sessions
                </li>
              </ul>
            </li>
            <li>Up to 100+ one-on-one mentoring sessions.</li>
            <li>Advisory support for CMA exam application, CMA certification, etc.</li>
            <li>100% Placement assurance (for self-sponsored candidates).</li>
            <li>Unlimited mentoring session</li>
            <li>
              Also, I would like to share our classes timings and duration
              <ul  class="inside_list">
                <li>
                  Varun’s webinars @4 hrs/week 
                </li>
                <li>Varun’s videos @6 hrs/week</li>
                <li>Practice webinars @6 hrs/week </li>
                <li>Practice sessions @6 hrs/week </li>
                <li>Post-classes: Revision @22 hours/week</li>
                <li>Have to share the time table according to the batch.</li>
              </ul>
            </li>
            <li>
              Payment Confirmation:-
              <ul  class="inside_list">
                <li>As I can check that your payment status is incomplete, I would like to request that you kindly clear the rest of the payment so that we can release our benefits and you can start taking the classes on a regular basis.</li>
                <li>(SR can ask the payment expected date or document expected date and further assistance)</li>
              </ul>
            </li>
            <li>
              Referral Program
              <ul  class="inside_list">
                <li>
                  <p>Hey! Now that you have enrolled with Miles, you are now a Miles Ambassador! And we “value” our ambassadors like nobody else does. So, let me ask you this question.</p>
                  <p>Do you have a friend or a colleague or any family members, who wants to make it big like you? Who aspires to add a global edge to his F&A career? </p>
                  <p>All you need to do is refer them to Miles Education and you will in return earn some crazy incentives. For every referral who enrolls with Miles (CPA/CMA) you earn an assured referral bonus between 2500 to 5000 M.A.C coins. These coins can then be redeemed on the M.A.C store as Cash, Miles Merchandise, Apple Products, Diamonds, High-end Bikes or who knows you may drive home a brand new BMW.</p>
                  <p>It is as easy as 1,2,3! </p>
                  <p>Invite your friends or a colleague or any family members.</p>
                  <p>They register!</p>
                  <p>You get 2500 to 5000 M.A.C coins!</p>
                </li>
              </ul>
            </li>
            <li>
              CR Lead generation (2nd Call).
              <ul  class="inside_list">
                <li># May I know are you a working professional? If yes, take details of the company, designation, total work experience, As I see you are working with XYZ Company, I would like to take you through our corporate tie-up benefits too. Pitch for CR if the company is not impaneled with us</li>
                <li>
                  Miles’ known for its credibility and quality in training delivery, space is a training partner with most of the Big 4 and over 220+ MNCs (includes 75+ Fortune 500 companies). Under this corporate tie-up, our CPA/CMA program will be a part of your company’s educational benefits policy for employees and companies sponsor 50-100% program fee based on employee’s designation & tenure with them. You have a chance to get all your fee sponsored by your company after the empanelment and added benefit from Miles too. Miles will refund half of your agreed training  fees after the empanelment. I would need your L&D contact details for the same. (If there is no L&D or CAN is unable to share the L&D details then ask for HR contact details)
                </li>
              </ul>
            </li>
            <li> If the candidate says no:-
              <ul  class="inside_list">
                <li>
                  Miles: No worries. Please let me know a suitable time to connect. I will take XX minutes of yours to explain the process.
                </li>
                <li>
                  (candidate) …
                </li>
                <li>
                  Miles: Sure. I am confirming your appointment for xx hours on xx date. Thank you for your time. Have a good day ahead.
                </li>
              </ul>
            </li>
          </ul>
        </vs-col>
      </vs-row>
    </div>
  </template>
  <script>
  import moment from "moment";
  export default {
    props: {
      studentDetails: {
        required: true,
      },
    },
    mounted() {
      this.spoc_name = localStorage.getItem("spoc_name");
      this.candidate_name = this.studentDetails.mwb.person_name;

        if(this.studentDetails.mwb.enrollments.lenght != 0){
            let courses =[];
            let latestDate = this.studentDetails.mwb.enrollments[0].enrollment_date
            for (let i = 0; i < this.studentDetails.mwb.enrollments.length; i++) {
                const element = this.studentDetails.mwb.enrollments[i];
                if (element.course == "CPA" || element.course == "CMA" || element.course == "CFA" || element.course == "FRM" || element.course == "CPA-AA" || element.course == "AICPA" || element.course == "CPA-US") {
                  if(element.enrollment_date > latestDate){
                    latestDate = element.enrollment_date
                  }
                  if( latestDate == element.enrollment_date){
                    courses.push(element.course)
                  }
                }
            }
            console.log("find data",this.studentDetails.mwb.enrollments);
            this.enrollmentDate = moment.unix(latestDate).format("DD-MMM-YYYY");
            this.courses = courses.join();
        }   
    },
    data() {
      return {
        candidate_name: "",
        spoc_name: "",
        enrollmentDate:"",
        courses:""
      };
    },
  };
  </script>
  
  <style scoped>
  h1 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  .get_data {
    width: 250px;
    margin-block: 10px;
  }
  
  .hide_select_data {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 0px;
    background-color: #ffffff;
  }
  
  ul {
    list-style-type: disc;
    margin-inline-start: 44px;
  }
  
  ol {
    list-style-type: decimal;
    margin-inline-start: 44px;
  }
  
  li {
    font-size: 16px;
    font-weight: 400;
    /* margin-block: 5px; */
    line-height: 30px;
  }
  
  label {
    font-size: 16px;
  }
  
  li>p {
    font-size: 16px;
  }
  .inside_list{
    list-style-type: circle;
    margin-block: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
  
  .row_data {
    padding: 0rem 2rem;
  }
  </style>