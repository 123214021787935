<template>
    <div>
        <vs-row>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Can-ID
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Can.Name
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Course
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >AgreedCost</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Enrollment Date
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Refferal Given Status
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="0.5"
                style="font-size: 12px"
            >
            </vs-col>
        </vs-row>
        <vs-collapse
            :type="type"
            v-for="(referral, index) in popupreferralHistory"
            :key="index"
        >
            <vs-collapse-item id="dynamicheight" icon-arrow="false" v-if="referral.more_referrals_given === 'Yes'">
                <div slot="header">
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.identity }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.can_name }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.course }}
                        </vs-col>

                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.agreed_cost }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{
                                convertTimestampToDate(referral.enrollment_date)
                            }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="center"
                            vs-w="1"
                            style="font-size: 12px;margin-left: 2%;"
                        >
                            {{ referral.more_referrals_given }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="end"
                            vs-align="center"
                            vs-w="0.5"
                            style="font-size: 12px"
                        >
                         <div @click="childreferrals(referral)"><vs-icon icon="add"></vs-icon></div>
                        </vs-col>
                    </vs-row>
                </div>
                <div>
                    <referral-child :referralChildData="referralHistory"></referral-child>
                </div>
            </vs-collapse-item>
            <vs-collapse-item icon-arrow="remove" v-if="referral.more_referrals_given === 'No'">
                <div slot="header">
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.identity }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.can_name }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.course }}
                        </vs-col>

                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.agreed_cost }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{
                                convertTimestampToDate(referral.enrollment_date)
                            }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px;margin-left: -6%;"
                        >
                            {{ referral.more_referrals_given }}
                        </vs-col>
                    </vs-row>
                </div>
            </vs-collapse-item>
        </vs-collapse>
    </div>
</template>

<script>
import axios from "axios";
// import chatInterface from "./DashboardChatInterface.vue";
import constants from "../../../../constants.json";
// import vSelect from "vue-select";
import refferalChild from "./refererralChildSection.vue"
export default {
    props: {
        payload: {
            required: true,
            type: Object,
        },
    },
    components: {
        "referral-child": refferalChild
    },
    mounted() {
        console.log(this.payload);
        this.can_identity = this.payload.mwb.identity;
        this.getRefferalData();
    },
    data() {
        return {
            type: "border",
            bool: false,
            referralHistory: [],
            can_identity: "",
            popupreferralHistory: []
        };
    },
    methods: {
        getRefferalData() {
            this.referralHistory = [];
            let obj = {
                identity: this.can_identity,
            };
            console.log(obj);
            let url = `${constants.SERVER_API}getReferralGivenDetails`;
            axios
                .get(url, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("referral", response.data.referrals);
                    this.popupreferralHistory = response.data.referrals;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        childreferrals(data) {
            
            console.log("child", data)
            this.referralHistory = [];
            let obj = {
                identity: data.identity,
            };
            console.log(obj);
            let url = `${constants.SERVER_API}getReferralGivenDetails`;
            axios
                .get(url, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("referral", response.data.referrals);
                    this.referralHistory = response.data.referrals;
                    document.getElementById("dynamicheight").children[1].style.maxHeight = "273px";
                })
                .catch((error) => {
                    this.handleError(error);
                });
        }
    },
};
</script>

<style>
.vs-collapse-item--content {
    max-height: 273px;
}
</style>
