<template>
  <vs-popup
    class="holamundo"
    :id="'utilization_untracked'"
    :title="this.typename + ' - ' + this.spoc_name + ' - ' + this.count"
    :active.sync="open"
  >
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Can-ID</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b>Person Name</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Course</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Actual Cost</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Agreed Cost</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b>Discount</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>Enrollment Date</b>
      </vs-col>
    </vs-row>
    <div style="overflow-y: scroll;height: 500px;">
      <div v-for="(items, index) in newcalls" :key="index">
        <vs-divider v-if="items === 'br'" />
        <vs-row style="margin-top: 2%" v-else>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.identity }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="3"
            >{{ items.person_name }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.course }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.actual_cost }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.agreed_cost }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="3"
            >{{ items.reason_for_discount }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
            >{{ unixTimestampConvert(items.enrollment_date) }}</vs-col
          >
        </vs-row>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
// import moment from "moment";
import playButton from "./callLogsPlayButton.vue";
export default {
  components: {
    playButton,
  },
  data() {
    return {
      open: false,
      calls: [],
      newcalls: [],
      title: "Enrollments",
      spoc_name: "",
      count: "",
      alldata: "",
      typename: "",
      length: "",
      spocName: ""
    };
  },
  mounted() {
    document.getElementById("utilization_untracked").children[1].style.width =
      "90%";
    EventBus.$on("utilization-enrollment", (payload) => {
      console.log(payload);
      // console.log("emait", emit)
      this.newcalls = payload[0];
      console.log("single",this.newcalls);
      this.length = payload[1];
      this.spocName = payload[2];
      this.typename = "Enrollments";
      this.spoc_name  = this.spocName;
      this.count = this.length;
      this.open = true;
      // // this.open = true;
      

        // if(this.length !== undefined || this.length !== 0 || this.length !== null) {
        //   console.log("all data", this.length)
        //   if(this.newcalls.length === this.length) {
            
        //     console.log(this.typename,this.spoc_name,this.count)
        //   } else if(this.newcalls.length === this.length) {
        //     this.typename = "Complete Payments";
        //     this.spoc_name  = this.spocName;
        //     this.count = this.length;
        //     this.open = true;
        //     console.log(this.typename,this.spoc_name,this.count)
        //   }
        // } else {
        //   this.typename = "Enrollment";
        //   // this.spoc_name  = this.alldata.spoc_name;
        //   this.count = this.length;
        //   this.open = true;
        //   console.log(this.typename,this.count)
        // }
        
      
      // this.open = true;
    });
  },
  methods: {
    calculatecount() {
      var unique = this.calls
        .map((name) => {
          return {
            count: 1,
            name: name.phone_number,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let clonearr = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (unique[key] === 1) {
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                clonearr.push(call);
                clonearr.push("br");
              }
            });
          } else {
            let tempArr = [];
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                tempArr.push(call);
              }
            });
            tempArr.forEach((temp, index) => {
              if (index > 0) {
                temp.count = "";
                tempArr[0].call_duration =
                  tempArr[0].call_duration + temp.call_duration;
                temp.call_duration = "";
                temp.level = "";
              } else {
                temp.count = unique[key];
              }
              clonearr.push(temp);
            });
            clonearr.push("br");
          }
        }
      }
      this.newcalls = clonearr;
    },
    // unixTimestampConvert(value) {
    //   var dateString = moment.unix(value).format("DD-MMM-YYYY");
    //   var date = new Date(value * 1000);
    //   var hours = date.getHours();
    //   var minutes = "0" + date.getMinutes();
    //   var seconds = "0" + date.getSeconds();
    //   var formattedTime =
    //     hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
    //   switch (dateString) {
    //     case "01/01/1970":
    //       return "";
    //     default:
    //       return `${dateString} - ${formattedTime}`;
    //   }
    // },
    // secondsToHms(d) {
    //   d = Number(d);

    //   var h = Math.floor(d / 3600);
    //   var m = Math.floor((d % 3600) / 60);
    //   var s = Math.floor((d % 3600) % 60);

    //   var formatteddate =
    //     ("0" + h).slice(-2) +
    //     ":" +
    //     ("0" + m).slice(-2) +
    //     ":" +
    //     ("0" + s).slice(-2);

    //   return `${formatteddate}`;
    // },
  },
};
</script>

<style></style>
