<template>
  <vs-popup
    v-if="open"
    id="addmrReceiptpopup"
    class="holamundo"
    :title="'Add ' + title + ' Number'"
    :active.sync="open"
  >
    <vs-row style="margin-top: 10px;" v-if="enableTDS">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-input v-model="mrNumber" placeholder="TDS / ADJ" />
      </vs-col>
    </vs-row>

    <!-- <vs-row style="margin-top: 10px;" v-if="!enableTDS">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
       
      </vs-col>
    </vs-row> -->

    <vs-row style="margin-top: 10px;" v-if="!enableTDS">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <!-- <b style="color: gray; margin-right: 2%">{{ title }}</b> -->
        <v-select style="width: 100%; z-index: 2000;"
          :options="['MR', 'VR', 'PR', 'MP', 'VP', 'DR', 'EV', 'LL', 'AV']"
          v-model="title"
        />
        </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input style="width: 100%;" v-model="mrNumber" placeholder="Number"/>
      </vs-col>
      <vs-col class="mt-4" v-if="showUTR" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input style="width: 100%;" v-model="utr_number" placeholder="UTR Number" />
      </vs-col>
      <vs-col class="mt-4"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
      >
        <datepicker
          placeholder="Deposit Date"
          style="z-index: 1000; position: static; width: 255px;"
          :disabledDates="disabledDates"
          v-model="addedMrdate"
        ></datepicker>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 5%;">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button
          color="dark"
          size="medium"
          type="border"
          @click="addMrNumber()"
          >Add {{ title }} Number</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
// import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
  components: {
    // vSelect,
    Datepicker,
    "v-select": vSelect,
  },
  data() {
    return {
      mrNumber: "",
      utr_number: "",
      open: false,
      title: "VR",
      obj: "",
      addedMrdate: new Date(),
      enableTDS: false, 
      purchaseData: "",
      showUTR : false,
      disabledDates: {
					from: new Date(),
				},
    };
  },
  mounted() {
    eventbus.$on("open-add-mrNumber-popup", (payload, payload2) => {
      // console.log("this is the popup event");
      console.log(payload);
      console.log(payload2);
      this.purchaseData = payload;
      this.title = "VR";
      if (payload !== undefined) {
        if (payload.transaction_type.toLowerCase() === "refund") {
          this.title = "MP";
        }
      }
      this.addedMrdate = new Date();
      this.disabledDates = {
					from: new Date(),
			},
      this.mrNumber = "";
      this.utr_number = "";
      this.obj = payload;
      this.enableTDS = payload.isTDSorADJ;

      this.open = true;
    });
  },
  watch: {
    open(value) {
      if (!value) {
        this.enableTDS = false;
      }
    },
    mrNumber() {
      this.checkLength();
    },
    title(value) {
      console.log(value)
      if(value == 'MP' || value == 'VP'){
        this.showUTR = true;
      }else{
        this.showUTR = false;
      }
      if(value === 'EV') {
        this.getLatestLoanSeriesNumber('EV');
      }else if(value === 'LL'){
        this.getLatestLoanSeriesNumber('LL');
      }
      else if(value === 'AV'){
        this.getLatestLoanSeriesNumber('AV');
      }
      else {
        this.mrNumber = "";
      }
    }
  },
  methods: {
    checkLength() {
      if (this.mrNumber.length > 6) {
        this.mrNumber = this.mrNumber.substring(0, this.mrNumber.length - 1);
      }
    },
    check() {
      if (this.enableTDS) {
        return true;
      }
      if (this.addedMrdate !== "" && this.mrNumber !== "") {
        return true;
      } else {
        this.$vs.notify({
          title: "Fill all the required details",
          text: "Fill all the required details to continue",
          color: "warning",
        });
        return false;
      }
    },
    addMrNumber() {
      this.obj.mr_number = "";
      this.obj.utr_number = this.utr_number;
      this.obj.deposit_date = this.datatoTimestamp(this.addedMrdate);
      // console.log(this.obj);
      if (!this.enableTDS) {
        this.obj.mr_number = `${this.title}${this.mrNumber}`;
      } else {
        this.obj.mr_number = `${this.mrNumber}`;
      }
      if (this.check()) {
        console.log(this.obj);
        // this.paymentInquickbooks();
        if((this.title == 'MP' || this.title == 'VP') && this.utr_number == ''){
            this.$vs.notify({
            title: "Fill all the required details",
            text: "Fill all the required details to continue",
            color: "warning",
          });
        }else{
        this.$vs.loading();
        axios
          .post(
            `${constants.SERVER_API}addMRNumberForPurchaseReceipt`,
            this.obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            eventbus.$emit("reloadreceipts");

            this.handleNotification(response);
            this.$vs.loading.close();
            this.open = false;
            // if (response.data.status !== "error") {
              
              // this.paymentInquickbooks();
            // }
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
        }
      }
    },
    getLatestLoanSeriesNumber(loan_series) {
        axios
          .get(
            `${constants.SERVER_API}getLatestLoanSeriesNumber?loan_series=${loan_series}`,
            // `http://milescmback.2x2.ninja/api/getLatestLoanSeriesNumber?loan_series=${loan_series}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.random_number);
            this.mrNumber = response.data.random_number;
            // eventbus.$emit("reloadreceipts");

            // this.handleNotification(response);
            // if (response.data.status !== "error") {
              
            //   this.paymentInquickbooks();
            //   this.$vs.loading();
            // }
          })
          .catch((error) => {
            this.handleError(error);
          });
      
    },
    paymentInquickbooks() {
      let paymentobj = {
        mr_number: `${this.title}${this.mrNumber}`,
        mwb_id: this.purchaseData.mwb_id,
        amount: this.purchaseData.paid_amount,
        deposit_date: this.datatoTimestamp(this.addedMrdate),
        can_name: this.purchaseData.can_name,
        identity: this.purchaseData.identity,
        purchase_id: this.purchaseData.purchase_id
      }
      console.log(paymentobj)
        axios
          .post(
            `https://accounts.milesforce.com/api/addReceiptForPaymentInQuickBooks`,
            paymentobj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            // eventbus.$emit("reloadreceipts");

            this.handleNotification(response);
            this.$vs.loading.close();
            this.open = false;
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
    }
  },
};
</script>

<style></style>
