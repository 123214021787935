<template>
    <!-- <div v-if="student_ne_data.length > 0">
      <h6 style="margin-bottom: 1%; margin-left: 1%">NE Journey :</h6>
      <vs-table :data="enquiries_aging_source">
        <template slot-scope="{ data }">
          <vs-tr>
            <vs-td>NMT :</vs-td>
            <vs-td
              align="right"
              justify="right"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
               {{ tr.aging }}
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td>Source :</vs-td>
            <vs-td
              align="right"
              justify="right"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              {{ tr.source }}({{ tr.count }})
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div> -->
    <div v-if="student_ne_data.length > 0">
      <p class="heading">NE Journey</p>
      <!-- <div class="ne_table" vs-w="11.7">
        <div class="ne_width">
          <div style="display: flex;" class="m-3">
            <div class="text-data" style="margin:0 30px 0 30px; display: flex;"> NMT: </div>
            <div
              v-for="(item, index) in enquiries_aging_source"
              :key="index"
              class="ne_data">
              <span class="text-data">{{ item.aging }}</span>
            </div>
          </div> -->
          <!-- <div
              style="
                border-top: 1px solid rgba(0, 0, 0, 0.25);
                margin-inline: 37px;
              "
            ></div> -->
          <!-- <div style="display:flex" class="m-3">
            <span class="text-data" style="margin:0 30px 0 30px "> Source: </span>
          <div v-for="(item, index) in enquiries_aging_source" :key="index"  class="ne_data">
            <span class="text-data">{{ item.source }}({{ item.count }})</span>
          </div>
          </div>
        </div>
      </div> -->
      <div class="ne_table">
        <div style="margin: 10px;width:100%;overflow-x: scroll;">
           <div style="display:flex">
            <div  class="text-data" style="margin:0 30px 0 30px;"> NMT: </div>
            <div
              v-for="(item, index) in enquiries_aging_source"
              :key="index"
              class="ne_data">
              {{ item.aging }}
            </div>
           </div>
            <vs-divider color="rgba(0, 0, 0, 0.25)" style="width:97%;position: absolute;z-index: 200;top:16px"></vs-divider>
           <div style="display:flex; margin-top:2%; margin-bottom: 10px;">
            <div  class="text-data" style="margin:0 30px 0 30px;"> Source: </div>
            <div
              v-for="(item, index) in enquiries_aging_source"
              :key="index"
              class="ne_source">
              {{ item.source }}({{ item.count }})
            </div>
           </div>
        </div>
        <!-- <div style=" display: flex;overflow-x: scroll; width: 1100px;"> -->
          
        <!-- </div> -->
      </div>
  </div>
</template>

<script>
export default {
  props: {
    student_ne_data: {
      required: true,
    },
  },
  data() {
    return {
      enquiries_aging_source: [],
      net_enquiry_type_count: {},
    };
  },
  mounted() {
    let ne_journey = JSON.parse(JSON.stringify(this.student_ne_data));
    this.getSourceTAT(ne_journey);
  },
  methods: {
    geetClassName(){
      if (this.enquiries_aging_source.length > 4) {
        return 'ne_width'
      }
    },
    getSourceTAT(student_ne_data) {
      let sources = [];
      sources.push(student_ne_data[0].source);
      for (let i = 1; i < student_ne_data.length; i++) {
        sources.push(student_ne_data[i].source);
        const a = new Date(student_ne_data[i - 1].enquiry_date);
        const b = new Date(student_ne_data[i].enquiry_date);
        const days_difference = this.dateDiffInDays(a, b);
        student_ne_data[i].aging = days_difference;
        student_ne_data[i].count = null;
      }
      let today = new Date();
      student_ne_data[0].aging = this.dateDiffInDays(
        today,
        new Date(student_ne_data[0].enquiry_date)
      );
      this.getSumOfSourceAging(student_ne_data);
    },
    getSumOfSourceAging(student_ne_data) {
      for (let i = 1; i < student_ne_data.length; i++) {
        if (student_ne_data[i].source == student_ne_data[i - 1].source) {
          student_ne_data[i].aging =
            student_ne_data[i].aging + student_ne_data[i - 1].aging;
        }
      }
      let count = 1;
      this.enquiries_aging_source = [];
      student_ne_data[0].count = 1;
      this.enquiries_aging_source.push(student_ne_data[0]);
      for (var i = 0; i < student_ne_data.length - 1; i++) {
        if (student_ne_data[i].source != student_ne_data[i + 1].source) {
          count = 1;
          student_ne_data[i + 1].count = count;
          this.enquiries_aging_source.push(student_ne_data[i + 1]);
        } else {
          count++;
          let length = this.enquiries_aging_source.length;
          let max_number = Math.max(
            student_ne_data[i].aging,
            student_ne_data[i + 1].aging
          );
          if (max_number == student_ne_data[i].aging) {
            student_ne_data[i].count = count;
            this.enquiries_aging_source[length - 1] = student_ne_data[i];
          }
          if (max_number == student_ne_data[i + 1].aging) {
            student_ne_data[i + 1].count = count;
            this.enquiries_aging_source[length - 1] = student_ne_data[i + 1];
          }
        }
      }
      console.log("enquir", this.enquiries_aging_source);
    },
    dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc1 = Date.UTC(
        Math.abs(a.getFullYear()),
        a.getMonth(),
        a.getDate()
      );
      const utc2 = Date.UTC(
        Math.abs(b.getFullYear()),
        b.getMonth(),
        b.getDate()
      );
      return Math.floor((utc1 - utc2) / _MS_PER_DAY);
    },
  },
};
</script>

<style scoped>
.ne_table {
  display: flex;
  justify-content: center;
  width:98% !important;
  margin-left: 1%;
  margin-top: 20px;
  box-sizing: border-box;
  min-height: 86.23px;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  position: relative;
}
.text-data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 15px; */
  /* identical to box height */
  /* width: 70px; */
  text-align: center;

  color: #000000;
}
.heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  margin-left: 1%;
  font-size: 16px;
  color: #000000;
}
.ne_data {
  display: inline;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-left: 60px;
}
.ne_source{
  display: inline;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-left: 20px;
}
.ne_width{
  /* min-width:800px; */
  overflow-x: scroll;
  /* text-align: center; */
}
.vs-divider {
    /* width: 100%; */
    /* min-width: 500px;
    max-width: 1100px; */
    width: 75vw;
    /* width: 100%; */
    }
</style>
