<template>
<vs-popup class="holamundo"  :title="'No. Students  : '+sum " :active.sync="open">
    <vs-checkbox v-model="checkall" @click="checkalltype('manual')">ALL</vs-checkbox>
      <vs-list v-for="(item, index) in studentsData" :key="index">        
        <vs-checkbox id="index" value="index" v-model="checkBoxnum" :vs-value="item">{{item}}</vs-checkbox>
    </vs-list>
    </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
    mounted() {
        EventBus.$on('open-list-popup', (payload) => {
            this.studentsData = payload
            this.checkBoxnum = []
            this.checkall = true
            this.studentsData.forEach((student) => {
                this.checkBoxnum.push(student)
            })
            this.open = true
        })
    },
    data() {
        return {
            studentsData: '',
            checkall: false,
            numStudents: 0,
            open: false,
            checkBox1:'',
            checkBoxnum:[],
            sum: 0,
        }
    },  
    watch: {
        checkBoxnum: function() {
            this.sum = this.checkBoxnum.length
            if (this.checkBoxnum.length === this.studentsData.length) {
                this.checkall = true
            } else {
                this.checkall = false
            }
            // this.checkalltype('program')
            EventBus.$emit('new-count', this.sum)  
        },
    },
    methods:{
        checkalltype(type) {
            if (type === 'manual') {
                console.log(this.checkall)
                if (this.checkall === false) {
                    this.checkBoxnum = []
                    this.studentsData.forEach((student) => {
                        this.checkBoxnum.push(student)
                    })
                    this.checkall = true
                }
            }
        }
    },
    computed:{
    }

}
</script>

<style>

</style>
