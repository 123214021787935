<template lang="html">
    <vs-popup class="holamundo"  title="EXAMS" :active.sync="open">
      <vs-row style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
          <b style="color: gray; font-size: 18px">AUD</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
           <vs-input class="inputx" placeholder="AUD" v-model="AUD"/>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
          <b style="color: gray; font-size: 18px">FAR</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
           <vs-input class="inputx" placeholder="FAR" v-model="FAR"/>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
          <b style="color: gray; font-size: 18px">BEC</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
           <vs-input class="inputx" placeholder="BEC" v-model="BEC"/>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
          <b style="color: gray; font-size: 18px">REG</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
           <vs-input class="inputx" placeholder="REG" v-model="REG"/>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
          <vs-button color="dark" type="gradient" icon="" @click="open = false">Save & Update</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
  mounted() {
    EventBus.$on('open-exam-edit', (payload) => {
      this.AUD = payload.AUD
      this.FAR = payload.FAR
      this.BEC = payload.BEC
      this.REG = payload.REG
      this.data = payload
      this.open = true
    })
  },
  data(){
    return {
      AUD: '',
      FAR: '',
      BEC: '',
      REG: '',
      data: '',
      open:false,
    }
  }
}
</script>