<template>
    <div>
      <div class="boxed">
           <vs-row class="gap" v-for="enrollment in usp_enrollments" :key="enrollment.batch">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
              <b style="color: gray; font-size: 12px; margin-right: 3%"
                >Enrollment Date:</b
              >
              <vs-chip color="dark">
                <b>{{ enrollment.date }}</b>
              </vs-chip>
            </vs-col>
  
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <b style="color: gray; font-size: 12px; margin-right: 3%">Batch:</b>
              <vs-chip color="dark">
                <b>{{ enrollment.batch }}</b>
              </vs-chip>
            </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="2"
            v-if="getrole('cm4')"
          >
            <vs-button
              radius
              color="warning"
              type="border"
              icon="chat"
              @click="editBatch()"
            ></vs-button>
          </vs-col> -->
        </vs-row>
      </div>
      <div class="boxed mt-5">
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <div class="boxed" style="width: 50%; margin-right: 2%">
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                  style="font-size: 15px; color: gray"
                  ><h5><b>Billing Address</b></h5></vs-col
                >
              </vs-row>
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                  ><vs-textarea
                    v-model="billingaddress"
                    :disabled="billingAddressDisable"
                /></vs-col>
              </vs-row>
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                ></vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="4"
                ></vs-col>
                <vs-col
                  v-if="this.billingAddressDisable === true"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="5"
                  ><vs-button type="gradient" color="warning" @click="editBillAdd"
                    >Edit</vs-button
                  ></vs-col
                >
                <vs-col
                  v-if="this.billingAddressDisable === false"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                  ><vs-button
                    type="gradient"
                    color="success"
                    @click="
                      saveAdd('mailing_address', getEnrolId(), editBillAdd())
                    "
                    >Save</vs-button
                  ></vs-col
                >
              </vs-row>
            </div>
            <div class="boxed" style="width: 50%">
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                  style="font-size: 15px; color: gray"
                  ><h5><b>Shipping Address</b></h5></vs-col
                >
              </vs-row>
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                  ><vs-textarea
                    v-model="shippingaddress"
                    :disabled="shippingAddressDisable"
                /></vs-col>
              </vs-row>
              <vs-row class="gap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                ></vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="4"
                ></vs-col>
                <vs-col
                  v-if="this.shippingAddressDisable === true"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="5"
                  ><vs-button type="gradient" color="warning" @click="editShipAdd"
                    >Edit</vs-button
                  ></vs-col
                >
                <vs-col
                  v-if="this.shippingAddressDisable === false"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                  ><vs-button
                    type="gradient"
                    color="success"
                    @click="
                      shiipingSaveAdd(
                        'shipping_address',
                        getEnrolId(),
                        editBillAdd()
                      )
                    "
                    >Save</vs-button
                  ></vs-col
                >
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center" class="my-4" style="letter-spacing: 2px;font-size:16px" v-if="this.show_reenrollment != 'no data'">USP Re-Enrollment Status  :</vs-col>
      <!-- <vs-col vs-w="4" vs-type="flex" vs-justify="flex-start" vs-align="center" class="my-4" style="letter-spacing: 2px"><vs-chip :color="getColorOfChip(show_reenrollment)" v-if="this.show_reenrollment != 'no data'"><b>{{ show_reenrollment }}</b></vs-chip></vs-col> -->
      <vs-col vs-w="7" vs-type="flex" vs-justify="flex-end" vs-align="center" class="my-4" v-if="this.usp_re_enrollment_status_data.length <= 2 && this.studentDetails.mwb.iiml_level == 'U7R'">
        <!-- <vs-button type="gradient" color="primary" @click="uspReEnrollment()">USP Re-Enrollment</vs-button> -->
        <loading-button type="gradient" id="USP-Re-Enrollment" :loading="btn_loading" :classn="'usp-re-enrollment'"
          @clicked="uspReEnrollment()"></loading-button>
      </vs-col>

      <table class="usp_re_enrollment" style="margin-top:40px; width:100%" v-if="this.show_reenrollment != 'no data'">
        <tr>
          <th class="usp_heading">Requested By</th>
          <th class="usp_heading">Approval Status</th>
          <th class="usp_heading">Date</th>
        </tr>
        <tr v-for="(re_enrollment_data, i) in usp_re_enrollment_status_data" :key="generateKey(i)">
          <td class="usp_data">{{re_enrollment_data.first_name }} {{ re_enrollment_data.last_name }}</td>
          <td class="usp_data">{{ getStatus(re_enrollment_data.approval_status) }}</td>
          <td class="usp_data">{{ getDate(re_enrollment_data.created_at) }}</td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  import LoadingButton from "../../utilities/LoadingButton";
  import constants from "../../../../constants.json";
  import axios from "axios";
  import EventBus from "../../eventbus";
  import moment from "moment";
  export default {
      props: {
      studentDetails: {
        type: Object,
        required: true,
      },
    },
    components: {
      LoadingButton,
    },
    mounted() {
      
      this.getBillAddress();
      this.getShipAddress();
      console.log("student details usp",this.studentDetails);
      this.uspReEnrollmentStatus()
      this.getUspEnrollments()
    },
    data(){
      return {
          btn_loading: false,
          shippingaddress: "",
          shippingAddressDisable: true,
          show_reenrollment: '',
          usp_re_enrollment_status_data: [],
          billingAddressDisable: true,
          billingaddress: "",
          usp_enrollments:[]
      }
    },
    methods: {
      getBillAddress() {
        this.billingAddressDisable = true;
        this.studentDetails.mwb.enrollments.forEach((bill) => {
          console.log("mail", bill);
          if (bill.course === "USP") {
            this.billingaddress = bill.address;
            console.log("mail", this.billingaddress);
          }
        });
        return this.billingaddress;
      },
      getUspEnrollments() {
        this.usp_enrollments = []
        this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        console.log('shashi',enrollment);
          if (enrollment.course === "USP") {
            let obj = {'date':this.unixTimestampConvert(enrollment.enrollment_date),batch: enrollment.batch}
            this.usp_enrollments.push(obj)
          }
        });
      },
      getEnrolId() {
        this.studentDetails.mwb.enrollments.forEach((en) => {
          if (en.course === "USP") {
            this.enrollId = en.id;
          }
        });
        return this.enrollId;
      },
      editShipAdd() {
        this.shippingAddressDisable = false;
      },
      getShipAddress() {
        this.shippingAddressDisable = true;
        this.studentDetails.mwb.enrollments.forEach((ship) => {
          if (ship.course === "USP") {
            this.shippingaddress = ship.shipping_address;
          }
        });
        return this.shippingaddress;
      },
      editBillAdd() {
        this.billingAddressDisable = false;
      },
      uspReEnrollmentStatus(){
        this.usp_re_enrollment_status_data = [];
        this.show_reenrollment = ''
        let url = `${constants.SERVER_API}getUSPReEnrollmentStatus?mwb_id=${this.studentDetails.mwb.id}`;
        axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}`}})
          .then((response) => {
            if(response.data.usp_re_enrollment_status.length != 0){
              this.usp_re_enrollment_status_data = response.data.usp_re_enrollment_status;
            }else {
              this.show_reenrollment = 'no data'
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      },
      uspReEnrollment(){
        this.btn_loading = true;
        let url = `${constants.SERVER_API}addRequestForUSPReEnrollment`
        let obj = {'mwb_id':this.studentDetails.mwb.id,'person_id':this.studentDetails.mwb.person_id}
        axios.post(url,obj, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}`}})
          .then((response) => {
            console.log(response);
            this.uspReEnrollmentStatus();
            this.handleNotification(response);
            this.btn_loading = false;
          })
          .catch((error) => {
            this.handleError(error);
            this.btn_loading = false;
          });
      },
      generateKey(item) {
        return `${item}-${Math.random()}`;
      },
      shiipingSaveAdd(address_type, id) {
        this.billingAddressDisable = true;
        let url = `${constants.SERVER_API}changeAddress`;
        let params = {
          enrollment_id: id,
          address_type: address_type,
          new_address: this.shippingaddress,
        };
        console.log("data", params);
        axios
          .post(url, params, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            EventBus.$emit("reload-bigpopup-data");
            this.shippingAddressDisable = true;
            this.handleNotification(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
      },
      saveAdd(address_type, id) {
        let url = `${constants.SERVER_API}changeAddress`;
        let params = {
          enrollment_id: id,
          address_type: address_type,
          new_address: this.billingaddress,
        };
        console.log(params);
        axios
          .post(url, params, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            EventBus.$emit("reload-bigpopup-data");
            this.handleNotification(response);
            this.billingAddressDisable = true;
            // this.shippingAddressDisable = true;
          })
          .catch((error) => {
            this.handleError(error);
          });
      },
      getColorOfChip(type){
        if (type == 'approved') {
          return 'success'
        } else if(type == 'pending'){
          return 'warning'
        }else if(type == 'rejected'){
          return 'danger'
        }
        else if(type == 'no data'){
          return 'primary'
        }
      },
      getStatus(type){
        if (type == 'approved') {
          return 'Approved'
        } else if(type == 'pending'){
          return 'Pending'
        }else if(type == 're_enrollment_done'){
          return 'Re Enrollment Done'
        }
      },
      getDate(date){
            return moment(date).format("DD-MMM-YYYY")
      }
    }
  }
  </script>
  
  <style>
  .update_bridge_levels_button {
    margin-top: 4px;
  }
  .bridge_update_button {
    direction: rtl;
  }
  .padding {
    padding: 5px;
  }
  .usp_re_enrollment {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  }

  .usp_heading, .usp_data {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  </style>