<template>
  <div>
    <vs-row vs-w="12" vs-type="flex">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="5">
        <div class="container">
          <template id="timeline-template">
            <div v-for="(event,i) in events" :key="i">
              <ul class="timeline">
                <li class="timeline-item">
                  <div class="timeline-badge" :style="getIconColor(event.data_source,event.directory)">
                    <vs-icon :icon="getIcon(event.data_source)" size="medium" style="margin-top: 16%;">
                    </vs-icon>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">

                      <div class="timeline-title"
                        style="display: flex;justify-content: space-between;font-size:12px; font-weight: 600;">
                        <p style="max-width: 50%;">{{ source[event.data_source] }} -
                          <span v-if="event.data_source == 'enrollments'">{{event.course}}</span>
                          <span v-if="event.data_source == 'mwb'">{{event.source}}</span>
                          <span v-if="event.engagement_type">{{event.engagement_type}}</span> <span
                            v-if="event.net_enquiry_type">{{event.net_enquiry_type}}</span>
                          <span v-if="event.directory">{{event.directory}}</span>
                          
                        </p>
                        <div v-if="!event.webinar_id">
                          <p>{{ getDateFormate(event.date) }}</p>

                        </div>
                        <div v-if="event.webinar_id">
                          <p>{{ getDateFormate(event.attended_on) }}</p>
                          <p>{{ event.spoc_name }} </p>
                        </div>
                      </div>
                      <div class="timeline-title mt-1" v-if="event.net_enquiry_type"
                        style="display: flex;justify-content: space-between;font-size:12px; font-weight: 600;">
                      <p>Created At - </p>
                      <div>
                        <p>{{ getDateFormate(event.created_at) }}</p>
                      </div>
                      </div>

                      <!-- <p>{{ event.spoc_name }} </p> -->
                      <h6 v-if="event.webinar_id">WEBINAR TOPIC : {{event.webinar_topic}}</h6>

                    </div>
                    <div class="timeline-title" style="justify-content: space-between; ">
                      <p v-if="event.engagement_type" style="">
                        <b style=" font-size: 11px; padding: 3px;margin: 4px 0 4px 0; border-radius: 5px;">
                          {{ event.previous_level ? event.previous_level : "-" }}
                          <vs-icon icon="forward" color="dark" style="margin-right: 4px; position: relative; top: 2px">
                          </vs-icon>
                          {{ event.level ? event.level : "-" }} |
                          {{
                          event.previous_iiml_level
                          ? event.previous_iiml_level
                          : "-"
                          }}
                          <vs-icon icon="forward" color="dark" style="margin-right: 4px; position: relative; top: 2px">
                          </vs-icon>
                          {{ event.iiml_level ? event.iiml_level : "-" }}
                        </b>
                      </p>
                      <p v-if="!event.webinar_id && !event.message_content"> {{ event.details }} </p>
                      <p v-if="!event.webinar_id && !event.message_content">{{ event.source_details }}</p>
                      <p v-if="event.message_content">{{event.message_content}}</p>
                      <p v-if="event.name">{{event.name}} - {{event.can_id}} - {{event.level}}</p>
                      <p v-if="event.data_source == 'call_logs'"> {{ secondsToTime(event.call_duration) }} </p>
                      <p v-if="event.webinar_id">{{getWebinarDetails(event.attended,event.interested)}}</p>
                      <p style="display: flex;justify-content: end;">{{event.spoc_name}} - {{event.team}} - {{event.city_classification}}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="start" vs-sm="4" vs-lg="5" style="margin-left: -8%">
        <vs-row v-show="journey_count">
          <vs-col>
            <div style="position:fixed; width:17%;">
              <vs-list-item icon="fiber_manual_record" title="Enrollments" >
                <span v-show="show_course"  style="font-size: 12px;" v-for="(course_name,i) in course_names"
                  :key="i">
                  {{course_name }},
                </span>
                <span v-show="hide_course" style="font-size: 12px;"> 0
                </span>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Number of Calls">
                <div style="font-size: 12px;">{{call_count}}</div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Webinar">
                <div style="display:flex; font-size: 12px;">
                  <p> WAI[{{webinar_attend_intrested}}]</p> &nbsp; &nbsp;
                  <p>WA[{{webinar_attend}}]</p> &nbsp; &nbsp;
                  <p> WR[{{webinar_register}}]</p>
                </div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Whatsapp Message">
                <div style="font-size: 12px;">
                  {{whatsapp_message_count}}
                </div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Net Enquiry">
                <div style="font-size: 12px;">
                  {{net_enquiry_count}}
                </div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Engagements">
                <div style="font-size: 12px;">
                  {{engagements_count}}
                </div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Referrals Given">
                <div style="font-size: 12px;">
                  {{referrals_given}}
                </div>
              </vs-list-item>
              <vs-list-item icon="fiber_manual_record" title="Referred By">
                <div style="font-size: 12px;">
                  {{referred_by}}
                </div>
              </vs-list-item>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    }
  },
  mounted() {
    this.getJourneyDetails(this.studentDetails.mwb.person_id)
  },
  data() {
    return {
      course_names: [],
      journey_count: false,
      show_course: false,
      hide_course: true,
      engagements_count:0,
      webinar_attend_intrested: 0,
      webinar_attend: 0,
      webinar_register: 0,
      whatsapp_message_count: 0,
      net_enquiry_count:0,
      call_log_count:0,
      referrals_given:0,
      referred_by:0,
      call_count: '',
      person_id: '',
      person_name: '',
      events: [],
      source: {
        "engagements": "Engagements",
        "enrollments": "Enrollments",
        "call_logs": "Call Logs",
        "mwb": "MWB",
        "referrals_given":"Referrals Given",
        "referred_by":"Referred By",
        "net_enquiries": "Net Enquiries",
        "whatsapp_message": "Whatsapp Message"
      },
      // message_counts: "",
    }
  },
  methods: {
    getJourneyDetails(person_id) {
      this.$vs.loading();
      let url = `https://mfcomms.2x2.ninja/api/candidateJourney?person_id=${person_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.events = response.data.events;
          if (this.events.length != 0) {
            this.journey_count = true
          }
          let data = response.data.events
          this.person_name = response.data.person_name
          this.$vs.loading.close();
          this.show_course = false
          this.hide_course = true
          this.course_names = []
          let courses = [...new Set(data.map((item) => item.course))];
          for (let i = 0; i < courses.length; i++) {
            const element = courses[i];
            if(element !== undefined){
              this.course_names.push(element)
            }
          }
          if(this.course_names.length <= 0 ){
            this.show_course = false
            this.hide_course = true
          }
          else  if (this.course_names.length > 0) {
            this.hide_course = false
            this.show_course = true
          } 
          console.log('course',this.course_names)
          this.call_count = response.data.counts.call_logs
          this.net_enquiry_count = response.data.counts.net_enquiries
          this.whatsapp_message_count = response.data.counts.whatsapp_message
          this.engagements_count = response.data.counts.engagements
          this.referrals_given = response.data.counts.referrals_given
          this.referred_by = response.data.counts.referred_by
          let WAI = 0
          let WA = 0
          let WR = 0
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.attended == '1' && element.interested == '1') {
              WAI++
            } else if (element.attended == '1' && element.interested == '0') {
              WA++
            } else if (element.attended == '0' && element.interested == '0') {
              WR++
            }
          }
          this.webinar_attend_intrested = WAI
          this.webinar_attend = WA
          this.webinar_register = WR
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error,
            color: "danger",
          });
          this.journey_count = false
        });
    },
    secondsToTime(e) {
      const h = Math.floor(e / 3600).toString().padStart(2, '0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');
      return h + ':' + m + ':' + s;
    },
    getIconColor(data_source, directory) {
      if (data_source == "call_logs") {
        if (directory == "OUTGOING" || directory == "INCOMING") {
          return "background-color: green;"
        } else {
          return "background-color: red;"
        }

      } else if (data_source == "engagements") {
        return " background-color: #2b43a4;"
      }
      else if (data_source == "enrollments") {
        return " background-color: black;"
      } else if (data_source == "mwb") {
        return " background-color: #e6637a;"
      } else if (data_source == "whatsapp_message") {
        return "background-color: rgb(13 144 68);"
      } else if (data_source == "net_enquiries") {
        return "background-color: rgb(120 13 210);"
      }else if (data_source == "referrals_given") {
        return "background-color:  rgb(215, 108, 47);"
      }else if (data_source == "referred_by") {
        return "background-color: rgb(150 18 102);"
      }
      return "background-color: rgb(24 21 24);"
    },
    getDateFormate(date) {
      return moment(date, "YYYY-MM-DD h:mm:ss").format("DD-MMM-YYYY h:mm A");
    },
    getIcon(data_source) {
      if (data_source == "call_logs") {
        return "call"
      } else if (data_source == "engagements") {
        return "message"
      }
      else if (data_source == "enrollments") {
        return "star_purple500"
      } else if (data_source == "mwb") {
        return "how_to_reg"
      } else if (data_source == "whatsapp_message") {
        return "perm_phone_msg"
      } else if (data_source == "net_enquiries") {
        return "record_voice_over"
      }else if (data_source == "referrals_given") {
        return "person_add_alt_1"
      }else if (data_source == "referred_by") {
        return "people_alt"
      }
      return "live_tv"
    },
    // 
    getWebinarDetails(attended, interested) {
      if (attended == '1' && interested == '1') {
        return "Webinar-WAI"
      } else if (attended == '1' && interested == '0') {
        return "Webinar-WA"
      } else {
        return "Webinar-WR"
      }
    },
  }

}
</script>
<style lang="scss">
.container {
  margin-left: -12%;
}

#timeline-header {
  font-size: 26px;
}

.timeline {
  list-style: none;
  // padding: 20px 0 20px;
  padding: 14px 0 0px;
  position: relative;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }

  >li {
    margin-bottom: 20px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    >.timeline-panel {
      border-radius: 2px;
      width: 80%;
      min-height: 60px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 10px;
      position: relative;
      word-break: break-word;

      .timeline-heading {
        .timeline-panel-controls {
          position: absolute;
          right: 8px;
          top: 5px;

          .timestamp {
            display: inline-block;
          }

          .controls {
            display: inline-block;
            padding-right: 5px;
            border-right: 1px solid #aaa;

            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;

              &:hover {
                color: #333;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .controls+.timestamp {
            padding-left: 5px;
          }
        }
      }
    }

    .timeline-badge {
      background-color: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;
    }

    .timeline-badge+.timeline-panel {
      &:before {
        border-bottom: 15px solid transparent;
        border-left: 0 solid #ccc;
        border-right: 15px solid #ccc;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -15px;
        right: auto;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 0 solid #fff;
        border-right: 14px solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -14px;
        right: auto;
        top: 27px;
      }
    }
  }
}

.timeline-badge {
  &.primary {
    background-color: #2e6da4 !important;
  }

  &.success {
    background-color: #3f903f !important;
  }

  &.warning {
    background-color: #f0ad4e !important;
  }

  &.danger {
    background-color: #d9534f !important;
  }

  &.info {
    background-color: #5bc0de !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {

  >p,
  >ul {
    margin-bottom: 0;
  }

  >p+p {
    margin-top: 5px;
  }
}

.tat {
  background-color: #3250e1;
  border-radius: 100%;
  color: #fff;
  font-size: 1.4em;
  height: 37px;
  left: 50px;
  line-height: 40px;
  margin-left: 85%;
  position: absolute;
  text-align: center;
  top: 20px;
  width: 40px;
  z-index: 100;
}

.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;

  >* {
    color: #444;
  }
}

</style>