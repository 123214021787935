<template>
  <div>
    
    <div class="boxed">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="dark" type="border" @click="sendPaymentlink()"
            >Send Payment link</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Enrollment Date:</b
          >
          <vs-chip color="dark">
            <b>{{ getDate() }}</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <b style="color: gray; font-size: 12px; margin-right: 3%">Batch:</b>
          <vs-chip color="dark">
            <b>{{ getBatch() }}</b>
          </vs-chip>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="2"
          v-if="getrole('cm4')"
        >
          <vs-button
            radius
            color="warning"
            type="border"
            icon="chat"
            @click="editBatch()"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div>
      <new-poa-section :student_details="studentDetails" :course="poa_section_course"></new-poa-section>

    </div>
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <p style="color: gray; font-size: 15px">IMA Schloarship Details:</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.name_of_college }}</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.schloarship_status }}</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>{{ studentDetails.schloarship_comments }}</b>
        </vs-col>
      </vs-row>
    </div>
    
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="10"
        >
          <p class="heading">STUDENT REFERENCE</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="add"
            @click="add_student_cma_referrance"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Can-ID</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Candidate Name</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Referrence Date</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Current Status</b>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Bonus</b>
        </vs-col>-->
      </vs-row>
      <div v-for="users in studentDetails.student_reference" :key="users.index">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_identity }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_person_name }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ convertTimestampToDate(users.reference_given_date) }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">{{ users.bonus_status }}</b>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            City: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" style="font-size: 15px; color: gray">
            <vs-select class="selectExample" label="Cities" v-model="city">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in cityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            Eligibility: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
            <vs-select class="selectExample" label="Eligibility" v-model="eligibility">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in eligibilityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        
    </div>-->
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">OTHER COURSES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>CPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>DA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>RPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>FOF(O)</vs-checkbox>
          </vs-col>
        </vs-row>
    </div>-->
    <!-- <vs-divider class="mt-6"></vs-divider> -->
    <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">FEEDBACK ON MILES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On Call:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
            
            <star-rating :star-size="20" v-model="customrating" @rating-selected="setRating" />
            
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
            
            {{ratingFeedback}}
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On App:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
            
          </vs-col>
        </vs-row>
    </div>-->

    <!-- <CMAPOAEdit /> -->

    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <div
            class="boxed"
            style="width: 50%;margin-right: 2%;"
          >
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Billing Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="billingaddress"
                  :disabled="billingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.billingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editBillAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.billingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    saveAdd('mailing_address', getEnrolId(), editBillAdd())
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
          <div class="boxed" style="width: 50%;">
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Shipping Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="shippingaddress"
                  :disabled="shippingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.shippingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editShipAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.shippingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    shiipingSaveAdd(
                      'shipping_address',
                      getEnrolId(),
                      editBillAdd()
                    )
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import EventBus from "../../eventbus";
// import CMAPOAEdit from "../../pagesComponents/CMAPOAEdit.vue";
import AddCmaStudentReference from "../../pagesComponents/AddCmaStudentReference.vue";
import axios from "axios";
import constants from "../../../../constants.json";
import NewPoaSection from "../../EWB/CPA/Candidates/Details_Popup/NewPoaSection.vue";

export default {
  data() {
    return {
      billingaddress: "",
      shippingaddress: "",
      billingAddressDisable: true,
      shippingAddressDisable: true,
      poa_section_course: "CMA"
    };
  },
  components: {
    // CMAPOAEdit,
    AddCmaStudentReference,
    "new-poa-section": NewPoaSection,
  },
  props: {
    studentDetails: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // console.log("emitted", this.studentDetails.student_reference);
    this.getBillAddress();
    this.getShipAddress();
  },
  methods: {
    editBillAdd() {
      this.billingAddressDisable = false;
    },
    getEnrolId() {
      this.studentDetails.mwb.enrollments.forEach((en) => {
        if (en.course === "CMA") {
          this.enrollId = en.id;
        }
      });
      return this.enrollId;
    },

    editShipAdd() {
      this.shippingAddressDisable = false;
    },
    getBillAddress() {
      this.billingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((bill) => {
        console.log("mail", bill);
        if (bill.course === "CMA") {
          this.billingaddress = bill.address;
          console.log("mail", this.billingaddress);
        }
      });
      return this.billingaddress;
    },
    getShipAddress() {
      this.shippingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((ship) => {
        if (ship.course === "CMA") {
          this.shippingaddress = ship.shipping_address;
        }
      });
      return this.shippingaddress;
    },

    shiipingSaveAdd(address_type, id) {
      this.billingAddressDisable = true;
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.shippingaddress,
      };
      console.log("data", params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.shippingAddressDisable = true;
          this.handleNotification(response);

          //chaitanya completed design
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    saveAdd(address_type, id) {
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.billingaddress,
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.handleNotification(response);
          this.billingAddressDisable = true;
          // this.shippingAddressDisable = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editBatch() {
      EventBus.$emit("open-edit-batch-code", this.getEnrollment());
      console.log("editing");
      // this.open = true;
    },
    getEnrollment() {
      let data = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CMA") {
          data = enrollment;
        }
      });
      return data;
    },
    sendPaymentlink() {
      let url = `${constants.SERVER_API}sendPaymentLinkEmail`;
      let params = {
        purchase_id: this.getPurchaseID(),
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getPurchaseID() {
      let purchase_id = null;
      this.studentDetails.purchases.forEach((purchase) => {
        if (purchase.name === "CMA") {
          purchase_id = purchase.id;
        }
      });
      return purchase_id;
    },
    poaEscalation() {
      EventBus.$emit("poa-escalation");
    },
    editPOA() {
      this.EmitEvent("open-edit-cma-POA", this.studentDetails);
    },
    add_student_cma_referrance() {
      EventBus.$emit("open-add-student-cma-referrance", this.studentDetails);
      // this.EmitEvent('open-add-student-referrance', this.studentDetails.id)
    },
    EmitEvent(emit, payload) {
      // console.log("emitting");
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    getDate() {
      let date = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CMA") {
          date = enrollment.enrollment_date;
        }
      });
      return this.unixTimestampConvert(date);
    },
    getBatch() {
      let batch = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CMA") {
          batch = enrollment.batch;
        }
      });
      return batch;
    },
  },
};
</script>

<style></style>
