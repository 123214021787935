<template>
    <div>
        <vs-popup class="holamundo" title="F2F Webinar Registration" :active.sync="f2f_popup" @close="closePopup">
            <vs-row  v-for="(city, index) in city_options" :key="index">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" :vs-w="city.text== 'Bangalore' ? 6 :12"
                    style="margin-top: 5%;">
                    <vs-radio v-model="selected_city" :vs-value="city.text">{{ city.text }} -
                        {{ city.date }}</vs-radio>
                       
                </vs-col>
                <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="6"  style="margin-top: 5%;" v-if="selected_city == 'Bangalore' && city.text== 'Bangalore'">
                    <vs-radio v-model="registration_type" vs-value="face_to_face">F2F</vs-radio>
                    <vs-radio v-model="registration_type" vs-value="zoom">Zoom</vs-radio>
                </vs-col>
            </vs-row>

            <vs-row style="margin-top: 5%;">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
                    <vs-button color="dark" type="gradient" @click="storeRegistrant">Confirm</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ApiServices from "../../../ApiServices.js";
export default {
    props: ["f2f_popup", "mwb_id"],
    components: {
        Datepicker,
    },
    data() {
        return {
            registration_type: "face_to_face",
            open: false,
            selected_city: null,
            city_options: [
                { text: "Bangalore", date: "28 Aug 2022" },
                { text: "Delhi", date: "21 Aug 2022" },
                { text: "Hyderabad", date: "20 Aug 2022" },
                { text: "Kochi", date: "25 Aug 2022" },
                { text: "Mumbai", date: "27 Aug 2022" },
            ],
            city_date: {
                "Bangalore": "28 Aug 2022",
                "Delhi": "21 Aug 2022",
                "Hyderabad": "20 Aug 2022",
                "Kochi": "25 Aug 2022",
                "Mumbai": "27 Aug 2022",
            }
        }
    },
    methods: {
        closePopup() {
            this.$emit('close-f2f-popup')
            this.selected_city = null
        },
        storeRegistrant() {
            console.log("this.selected_city", this.selected_city)
            if (this.selected_city != "" || this.selected_city != null) {
                let webinar_date = this.city_date[this.selected_city];
                console.log("this.webinar_date", webinar_date)
                let getParams = {
                    webinar_date: moment(webinar_date).unix(),
                    mwb_id: this.mwb_id,
                    city: this.selected_city,
                    type: this.registration_type
                };
                console.log("ew", getParams);
                ApiServices.storeRegistrant(getParams)
                    .then((response) => {
                        if (response.data === "success") {
                            this.$vs.notify({
                                title: 'Successfully Registered for F2F Webinar',
                                color: "success",
                            });
                            this.closePopup();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: error,
                            color: "danger",
                        });
                        this.closePopup();
                    });
            }
            else {
                this.$vs.notify({
                    title: "Please select city",
                    color: "danger",
                });
            }
        }
    }
}
</script>