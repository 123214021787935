<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
      >
        <p class="heading">PURCHASES</p>
        <!-- <vs-button
          color="danger"
          type="gradient"
          size="small"
          style="margin-left:3%"
          @click="escalate(purchaseData.Books, 'BOOKS')"
        >
          <b>New Purchase</b>
        </vs-button> -->
      </vs-col>
    </vs-row>
    <!-- <div class="boxed" v-for="(purchase, index) in purchaseData.purchases" :key="index"> -->
    <!-- <vs-row class="gap" v-if="purchase.admin_approval_required === 1">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-chip color="danger" :style="{ opacity: '100%' }">
            <b>Admin Approval Required</b>
          </vs-chip>
        </vs-col>
    </vs-row>-->
    <!-- <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"></vs-col>
        <vs-col
          v-for="(book, index) in getPurchaseDeliverables(purchase, 'book')"
          :key="index"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b style="color: gray; font-size: 12px">{{ book.name }}</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b style="color: gray; font-size: 12px">Books</b>
        </vs-col>
        <vs-col
          v-for="(book, index) in getPurchaseDeliverables(purchase, 'book')"
          :key="index"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-tooltip :text="getTextforDisplay(book)">
            <vs-icon size="medium" :icon="generateicon(book)" :color="generatecolor(book)"></vs-icon>
            <p>{{ book.version }}</p>
          </vs-tooltip>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Amount Paid</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Amount Pending</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Total Amount</b>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="success">{{ purchase.amount_paid }}</vs-chip>
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="danger">{{ purchase.amount_pending }}</vs-chip>
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">
            <vs-chip color="dark">{{ purchase.agreed_cost }}</vs-chip>
          </b>
        </vs-col>
    </vs-row>-->
      <!-- <h6 v-if="purchased_books.length != 0" class="books-head">CPA Book Versions Chosen By Candidates</h6>
      <div class="vs-list--header vs-header-list-primary" style="display:flex">
        <span  v-for="book in purchased_books" :key="book"><p style="margin:10px" class="font-awesome">{{book.book}} - {{book.version}}</p></span>
      </div> -->
    <vx-card
      class="boxed"
      v-for="(purchase, index) in studentDetails.purchases"
      :key="index"
    >
      <div
        v-if="
          purchase.type == 'Miscellaneous' ||
            purchase.type !== 'Annonymous Purchase'
        "
      >
        <div>
          <vs-row class="gap" v-if="purchase.admin_approval_required === 1">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-chip color="danger" :style="{ opacity: '100%' }">
                <b style="font-size: 13px;">Admin Approval Required!</b>
              </vs-chip>
            </vs-col>
          </vs-row>
        </div>
       
        <!-- <div><b style="font-size: 13px;"
                ></b></div> -->
        <div>
          <!-- <vs-button
            v-if="purchase.name === 'CPA'"
            color="dark"
            type="gradient"
            size="small"
            style="margin-left:3%; margin-bottom: 2%"
            @click="resendLMSEmail()"
          >
            <b>Resend LMS Email</b>
          </vs-button> -->
          <!-- <vs-table :data="getPurchasesDeliverable(purchase)">
            <template slot="thead">
              <vs-th>TYPE</vs-th>
              <vs-th>NAME</vs-th>
              <vs-th>DELIVARY STATUS</vs-th>
            </template>
            <template slot-scope="{ data }">
              <tbody ref="tbody">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>{{ sortname(tr.type) }}</div>
                  </vs-td>
                  <vs-td v-if="tr.type === 'video_access' || tr.type === 'otb'">
                    <div>{{ tr.subject }}</div>
                  </vs-td>
                  <vs-td v-else>
                    <div>{{ tr.name }}</div>
                  </vs-td>
                  <vs-td>{{ sortdeliveryName(tr.delivery_status) }}</vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table> -->

          
          <!-- <vs-table :data="getPurchasesDeliverable(purchase)">
            <template slot="thead">
              <vs-th></vs-th>
              <vs-th
                v-for="(subject, index) in getSubjects(purchase)"
                :key="index"
                >{{ subject }}</vs-th
              >
            </template>

            <template slot-scope="{ data }">
              <tbody ref="tbody">
                 <b>{{ tr.category }}</b>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>
                      <b>{{ tr.category }}</b>
                    </div>
                  </vs-td>

                  <vs-td  :key="indextr2" v-for="(br, indextr2) in tr.items">
                     {{br}} 
                    <div  v-if="tr.category === 'BOOKS'">
                      <span class="myDIV"> {{ getTextforDisplay(br.data) }}</span> 
                      <span class="myDIV" v-if="br.deliverable.courier_number !== null || br.deliverable.courier_number !== '' || br.deliverable.status !== null">{{br.deliverable.status}} </span>
                      <span class="myDIV" v-if="br.deliverable.status === null">{{br.deliverable.delivery_status}} </span>
                      
                      <div class="hide">
                         {{br.deliverable}} 
                        <p v-if="br.deliverable.name !== null">name : {{br.deliverable.name}} </p><p v-else>name : N/A </p>
                        <p v-if="br.deliverable.courier_number !== null">Courier Number : {{br.deliverable.courier_number}} </p><p v-else>Courier Number : N/A </p>
                       <p v-if="br.deliverable.last_event !== null">Last Event : {{br.deliverable.last_event}} </p><p v-else>Last Event : N/A </p>
                      <p v-if="br.deliverable.status !== null">Status : {{br.deliverable.status}} </p><p v-else>Status : N/A </p>
                      
                      </div>
                      
                       {{br.deliverable}} 
                    </div>
                    <div v-else>{{ unixTimestampConvert(br.data) }}</div>
                    
                  </vs-td>
                   
                </vs-tr>
                
              </tbody>
            </template>
          </vs-table> -->

          <div v-if="purchase.type == 'course' && purchase.enrollment_id != null && getBooksData(purchase.id)">
            <vs-table :data="dispatch_request" v-if="dispatch_request.length > 0">
            <template slot="thead">
              <vs-th>BOOKS</vs-th>
              <vs-th v-for="(dispatch_item, index) in dispatch_request" :key="index">
                <span v-if="getSkuBooks(dispatch_item.enrollment_id, purchase.enrollment_id)">{{ dispatch_item.sku_name }}</span>
              </vs-th>
            </template>
            <template >
              <tbody ref="tbody">
                <vs-tr>
                  <vs-td >
                    <b>STATUS</b> 
                  </vs-td>
                  <vs-td v-for="(dispatch_item, index) in dispatch_request" :key="index">
                    <span v-if="dispatch_item.shipway_status != null && getSkuBooks(dispatch_item.enrollment_id, purchase.enrollment_id)">{{ dispatch_item.shipway_status }}</span>
                    <span v-if="dispatch_item.shipway_status == null && getSkuBooks(dispatch_item.enrollment_id, purchase.enrollment_id)">{{ dispatch_item.status }}</span>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
            </vs-table>
          </div>

          <div v-if="purchase.type == 'Additional Purchase' && getBooksData(purchase.id)">
            <vs-table :data="dispatch_request" v-if="dispatch_request.length > 0">
            <template slot="thead">
              <vs-th>BOOKS</vs-th>
              <vs-th v-for="(dispatch_item, index) in dispatch_request" :key="index">
                <span v-for="(receipt, index) in purchase.receipts" :key="index" v-if="dispatch_item.purpose == 'LMS Purchase' && purchase.name == 'Additional Purchase' && receipt.transaction_id == dispatch_item.invoice_number">{{ dispatch_item.sku_name }}</span>
              </vs-th>
            </template>
            <template >
              <tbody ref="tbody">
                <vs-tr>
                  <vs-td>
                    <b>STATUS</b> 
                  </vs-td>
                  <vs-td v-for="(dispatch_item, index) in dispatch_request" :key="index">
                    <span v-for="(receipt, index) in purchase.receipts" :key="index" v-if="dispatch_item.shipway_status != null && dispatch_item.purpose == 'LMS Purchase' && purchase.name == 'Additional Purchase' && receipt.transaction_id == dispatch_item.invoice_number">{{ dispatch_item.shipway_status }}</span>
                    <span v-for="(receipt, index) in purchase.receipts" :key="index" v-if="dispatch_item.shipway_status == null && dispatch_item.purpose == 'LMS Purchase' && purchase.name == 'Additional Purchase' && receipt.transaction_id == dispatch_item.invoice_number">{{ dispatch_item.status }}</span>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
            </vs-table>
          </div>
        </div>
       

        <div slot="footer">
          <vs-row class="gap">
            <vs-col
              vs-w="5" v-if="purchased_books.length != 0 && findPurchaseBooks(purchase)"
            >
            <div class="my-4" >
              <table class="cpa-table" style="margin-top:8px; width:80%">
                <tr>
                  <th>Subjects</th>
                  <th>2023</th>
                  <th>2024</th>
                </tr>
                <tr v-for="book in book_list" :key="book">
                  <td>{{book.book}}</td>
                  <td v-if="book.version == '2023'" class="check-box"><vs-checkbox disabled="true" v-model="book.version"></vs-checkbox></td>
                  <td v-else><vs-checkbox disabled="true" class="check-box"></vs-checkbox></td>
                  <td v-if="book.version == '2024'" class="check-box"><vs-checkbox disabled="true" v-model="book.version"></vs-checkbox></td>
                  <td v-else ><vs-checkbox disabled="true" class="check-box"></vs-checkbox></td>
                </tr>
              </table>
            </div>
            </vs-col>
            <vs-col
              vs-w="7" v-if="purchased_books.length != 0 && findPurchaseBooks(purchase)"
              style="font-size: 13px; display: flex; align-items: center;"
              >
              <div>
              <vs-row>
                <vs-col vs-w="6">
                <b style="font-size: 13px;">{{ purchase.name }}&nbsp;</b> <b style="font-size: 13px;">Payments</b>
              </vs-col>
              <vs-col vs-w="2">:</vs-col>
                <vs-col vs-w="4">
                <b style="font-size: 13px;"
                  >{{ sumOfAmount(purchase) }}/{{ purchase.agreed_cost }}</b
                >
              </vs-col>
              </vs-row>
              <!-- <vs-row style="margin-top: 10%;">
                <vs-col vs-w="6">
                <b style="font-size: 13px;">Purchases Comments</b>
                </vs-col>
                <vs-col vs-w="2">:</vs-col>
                <vs-col vs-w="4">
                <b style="font-size: 13px;">{{ purchase.comments }}</b>
              </vs-col>
              </vs-row> -->
            </div>
            </vs-col
            >
            <vs-col
              vs-w="12" v-else
              style="font-size: 13px; display: flex; align-items: center; padding-left: 0;"
              >
              <vs-row>
                <vs-col vs-w="7">
                <b style="font-size: 13px;">{{ purchase.name }}&nbsp;</b> <b style="font-size: 13px;">Payments</b>
              </vs-col>
              <vs-col vs-w="1"><b>: </b></vs-col>
                <vs-col vs-w="4">
                <b style="font-size: 13px;"
                  >{{ sumOfAmount(purchase) }}/{{ purchase.agreed_cost }}</b
                >
              </vs-col>
              </vs-row>
            </vs-col
            >
          </vs-row>
          <!-- <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            >
              
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
              style="font-size: 13px;"
              ><b style="font-size: 13px;">Purchases Comments:</b>{{ purchase.comments }}</vs-col
            >
          </vs-row> -->
         
        </div>
      </div>
      <vs-table  :data="purchase"  >
        <template slot="thead" class="exc">
          <vs-th>Type</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Agreed Cost</vs-th>
          <vs-th>Paid</vs-th>
          <vs-th>Pending</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :state="getdefaulterColor(data)" >
                  <vs-td>
                      <div>{{ data.type }}</div>
                  </vs-td>
                  <vs-td>
                      <div>{{ data.name }}</div>
                  </vs-td>
                  <vs-td >
                      <div>{{ data.agreed_cost }}</div>
                  </vs-td>
                  <vs-td>
                      <div>{{ sumOfAmountCal(data.receipts) }}</div>
                  </vs-td>
                  <vs-td>
                      <div>
                          {{
                              data.agreed_cost -
                              sumOfAmountCal(data.receipts)
                          }}
                      </div>
                  </vs-td>
                  <template class="expand-user" slot="expand">
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="12"
                        >
                            <div style="width: 100%">
                                <vs-table :data="purchase.receipts">
                                    <template slot="thead">
                                        <vs-th
                                            >Transaction Type</vs-th
                                        >
                                        <vs-th>Cheque No</vs-th>
                                        <vs-th>PDC Date</vs-th>
                                        <vs-th
                                            >Transaction Id</vs-th
                                        >
                                        <vs-th>TID</vs-th>
                                        <vs-th>City</vs-th>
                                        <vs-th>Receipt No</vs-th>
                                        <vs-th>Amount</vs-th>
                                        <vs-th>Receipt Date</vs-th>
                                    </template>
                                    <template slot-scope="{ data }">
                                        <vs-tr
                                            :key="indextr"
                                            v-for="(
                                                tr, indextr
                                            ) in data"
                                        >
                                            <vs-td>{{
                                                tr.transaction_type
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.cheque_no
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.pde_date
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.transaction_id
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.cc_machine_tid_no
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.city
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.receipt_no
                                            }}</vs-td>
                                            <vs-td>{{
                                                tr.amount
                                            }}</vs-td>
                                            <vs-td>{{
                                                unixTimestampConvert(
                                                    tr.receipt_date
                                                )
                                            }}</vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                            </div>
                        </vs-col>
                    </vs-row>
                  </template>
                </vs-tr>
        </template>
      </vs-table>      
      <!-- <div v-if="purchase.type == 'Miscellaneous'">
        <div slot="footer">
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <b style="font-size: 13px;">Miscellaneous</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
            >
              <b style="font-size: 13px;"> : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 13px;"
              ><b style="font-size: 13px;"
                >{{ sumOfAmount(purchase) }}/{{ purchase.agreed_cost }}</b
              ></vs-col
            >
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <b style="font-size: 13px;">Comments</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
            >
              <b style="font-size: 13px;"> : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 13px;"
              >{{ purchase.comments }}</vs-col
            >
          </vs-row>
        </div>
      </div> -->
      <div v-if="purchase.type == 'Annonymous Purchase'">
        <div slot="footer">
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-chip color="danger" :style="{ opacity: '100%' }">
                <b style="font-size: 13px;">Annonymous Purchase!</b>
              </vs-chip>
            </vs-col>
          </vs-row>

          <!-- <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            >
              <b style="font-size: 13px;">Annonymous Purchase: </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
              style="font-size: 13px;"
              ><b style="font-size: 13px;"
                >{{ purchase.amount_paid }}/{{ purchase.agreed_cost }}</b
              ></vs-col
            >
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            >
              <b style="font-size: 13px;">Comments:</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
              style="font-size: 13px;"
              >{{ purchase.comments }}</vs-col
            >
          </vs-row> -->

          <!-- <vs-table :data="purchase.receipts">
            <template slot="thead">
              <vs-th>Type</vs-th>
              <vs-th>Transaction Id</vs-th>
              <vs-th>Receipt No</vs-th>
              <vs-th>Amount</vs-th>
              <vs-th>Date</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{
                  tr.transaction_type == "HDFC Payment Gateway"
                    ? "HDFC"
                    : tr.transaction_type
                }}</vs-td>
                <vs-td>{{ tr.transaction_id }}</vs-td>
                <vs-td>{{ tr.receipt_no }}</vs-td>
                <vs-td>{{ tr.amount }}</vs-td>
                <vs-td>{{ unixTimestampConvert(tr.receipt_date) }}</vs-td>
              </vs-tr>
            </template>
          </vs-table> -->
        </div>
      </div>
     
    </vx-card>
  </div>
</template>

<script>
import EventBus from "../../eventbus";
import constants from "../../../../constants.json";
// import _ from "lodash";
import axios from "axios";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
    purchaseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      book_list: [],
      purchased_books:[],
      books: [],
      opacity: 0,
       candidate_id : null,
      dispatch_request: [],
      // purchaseData: "",
      opacityMeter: "0%",
    };
  },
  mounted() {
    console.log("studentDetails", this.studentDetails);
    this.purchased_books =[]
    if (this.studentDetails.mwb.enrollments.length != 0) {
      this.studentDetails.mwb.enrollments.forEach(enrollment => {
        let new_obj = {
              enrollment_id : enrollment.id,
              books_list : []
            }
        if (enrollment.candidate_books_selected.length != 0) {
                    enrollment.candidate_books_selected.forEach(book => {
            let obj={'book':book.book_name,'version':book.version}
            new_obj.books_list.push(obj);
          this.purchased_books.push(new_obj)

          });

        }
      });
    }
    
    console.log("this.purchaseData", this.studentDetails.mwb.enrollments[0].candidate_books_selected);
      this.candidate_id = this.studentDetails.mwb.identity
    // this.books = [];
    // this.purchaseData.deliverables.forEach((deliverable) => {
    //   console.log("this", deliverable);
    //   if (deliverable.type === "book") {
    //     this.books.push(deliverable);
    //   }

    // });
    
    // this.startAnimation();
     this.getDispatchRequests(this.candidate_id)
  },
  methods: {
    getBooksData(id){
      for (let j = 0; j < this.dispatch_request.length; j++) {
        const ele = this.dispatch_request[j];
        if(ele.purchase_id == id){
          return true;
        }
      }
    },
    getSkuBooks(a, b){
      if(a == b){
        return true;
      }
    },
    findPurchaseBooks(purchase){
    let obj = this.purchased_books.find(obj => obj.enrollment_id === purchase.enrollment_id);
    if(obj){
      this.book_list = obj.books_list
      return true;
    }else {
      return false;
    }
    },
    getDispatchRequests(candidate_id) {
      axios
        .get(`${constants.MILES_WAREHOUSE}getDispatchesForCandidate?candidate_id=${candidate_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // let data = response.data;
          // for (let i = 0; i < data.length; i++) {
          //   const element = data[i].dispatch_items;
          //   this.dispatch_request = element
          // }
          this.dispatch_request = response.data;
          console.log("getDispatchesForCandidate", this.dispatch_request);

        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sumOfAmountCal(data) {
      // console.log("amount", data)
      var msgTotal = data.reduce(function (prev, cur) {
          return prev + cur.amount;
      }, 0);
      // console.log('Total Messages:', msgTotal);
      return msgTotal;
    },
    sumOfAmount(data) {
        console.log("amount", data)
        var msgTotal = data.amount_paid + data.amount_in_process;
        console.log('Total Messages:', msgTotal);
        return msgTotal;
    },
  
    getSubjects(purchase) {
      console.log("purchase data",this.purchaseData, purchase);
      let subjectsChosen = [];
      this.purchaseData.deliverables.forEach((deliverable) => {
        if (deliverable.purchase_id === purchase.id && deliverable.type === "book") {
          subjectsChosen.push(deliverable);
        }
        
      });
      let sortedDeliverables = [];
      var unique = subjectsChosen
        .map((subject) => {
          return {
            count: 1,
            subject: subject.name,
          };
        })
        .reduce((a, b) => {
          a[b.subject] = (a[b.subject] || 0) + b.count;
          return a;
        }, {});
        console.log("dfas", unique)
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            if(key !== 'Wiley Part 1' && key !== 'Wiley Part 2'){
              sortedDeliverables.push(key);
            }
          }
        }
        console.log("hello",sortedDeliverables);
      return sortedDeliverables;
    },
    resendLMSEmail() {
      console.log(this.studentDetails);
      let obj = {
        person_id: this.studentDetails.mwb.person_id,
      };
      axios
        .post(`${constants.SERVER_API}resendLMSEmail`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sortdeliveryName(name) {
      // console.log(name);
      let changedName = name.replace(/_/g, " ");
      return changedName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    sortname(name) {
      switch (name) {
        case "video_access":
          return "Video";
        case "otb":
          return "OTB";
        case "book":
          return "Book";
      }
    },
    startAnimation() {
      // let count = 0;
      // window.setInterval(function() {
      //   this.opacityMeter = (++count % 101) + "%";
      // }, 50);
      // console.log("something is happening");
    },
    upsellingcolor(number) {
      number = parseInt(number);
      if (number < 30 || number < 31) {
        return "red";
      } else {
        return "";
      }
    },
    escalate() {
      EventBus.$emit("escalate-popup", this.studentDetails);
    },
    /*     getPurchaseDeliverables(purchase, type) {
      let arr = [];
      this.purchaseData.deliverables.forEach(deliverable => {
        if (deliverable.type === type) {
          if (deliverable.purchase_id === purchase.id) {
            arr.push(deliverable);
          }
        }
      });
      return arr;
    },
 */
    getPurchasesDeliverable(purchase) {
      let DeliverablesArr = [];
      let categoriesArr = [];
      let subjectsChosen = this.getSubjects(purchase);
      if(purchase.name === "CPA") {
        categoriesArr = ["BOOKS", "Miles LMS"];
      }
      if(purchase.name === "CMA") {
        // categoriesArr = ["BOOKS", "WileyOTB", "Miles LMS"];
        categoriesArr = ["BOOKS", "Miles LMS"];
      }
      let returnArr = [];
      this.purchaseData.deliverables.forEach((deliverable) => {
        
        if (deliverable.purchase_id === purchase.id) {
          
          DeliverablesArr.push(deliverable);
        }
      });
      categoriesArr.forEach((category) => {
        console.log("purchaseData 123456",this.purchaseData);
        let obj = {
          category: category,
          items: [],
        };
        console.log("subjectsChosen",subjectsChosen);
        if(subjectsChosen.length === 0){
        if (category === "Miles LMS") {
            let dataObj = {
            data: null,
          };
            if(purchase.name === "CMA") {
              if(this.purchaseData.student_subjects.cma.length === 0) {
                dataObj.data = [];
                obj.items.push(dataObj);
              }
              if(this.purchaseData.student_subjects.cma.length !== 0) {
                dataObj.data = this.datatoTimestamp(this.purchaseData.student_subjects.cma[0].lms_sent_date);
                obj.items.push(dataObj);
              }
              
            } 
            if(purchase.name === "CPA") {
              if(this.purchaseData.student_subjects.cpa.length === 0) {
                dataObj.data = [];
                obj.items.push(dataObj);
              }
              if(this.purchaseData.student_subjects.cpa.length !== 0) {
                dataObj.data = this.datatoTimestamp(this.purchaseData.student_subjects.cpa[0].lms_sent_date);
                obj.items.push(dataObj);
              }
              
            } 
        }
        }
       
        subjectsChosen.forEach((subject) => {
          
          let dataObj = {
            data: null,
          };
          if (category === "BOOKS") {
            DeliverablesArr.forEach((deliverable) => {
              console.log("DeliverablesArr", deliverable.name, subject)
              if (subject !== "REG") {
                if (deliverable.name === subject) {
                  dataObj.data = this.getdeliverableStatus(deliverable);
                  dataObj.deliverable = deliverable
                  obj.items.push(dataObj);
                } else if (subject === "AUD" && deliverable.name === "Wiley AUD"){
                  dataObj.data = this.getdeliverableStatus(deliverable);
                  dataObj.deliverable = deliverable
                  obj.items.push(dataObj);
                } else if (subject === "BEC" && deliverable.name === "Wiley BEC"){
                  dataObj.data = this.getdeliverableStatus(deliverable);
                  dataObj.deliverable = deliverable
                  obj.items.push(dataObj);
                } else if (subject === "FAR" && deliverable.name === "Wiley FAR"){
                    dataObj.data = this.getdeliverableStatus(deliverable);
                    dataObj.deliverable = deliverable
                    obj.items.push(dataObj);
                }  else if (subject === "REG" && deliverable.name === "REG 1") {
                  dataObj.data = this.getdeliverableStatus(deliverable);
                  obj.items.push(dataObj);
                }
              }else if (subject === "REG" && deliverable.name === "Wiley REG"){
                    dataObj.data = this.getdeliverableStatus(deliverable);
                    dataObj.deliverable = deliverable
                    obj.items.push(dataObj);
                }
            });
          } else if (category === "Miles LMS") {
            
            if(purchase.name === "CPA") {
              if(this.purchaseData.student_subjects.cpa.length === 0) {
                dataObj.data = [];
                obj.items.push(dataObj);
              }
              if(this.purchaseData.student_subjects.cpa.length !== 0) {
                dataObj.data = this.datatoTimestamp(this.purchaseData.student_subjects.cpa[0].lms_sent_date);
                obj.items.push(dataObj);
              }
              
            } 
            if(purchase.name === "CMA") {
              if(this.purchaseData.student_subjects.cma.length === 0) {
                dataObj.data = [];
                obj.items.push(dataObj);
              }
              if(this.purchaseData.student_subjects.cma.length !== 0) {
                dataObj.data = this.datatoTimestamp(this.purchaseData.student_subjects.cma[0].lms_sent_date);
                obj.items.push(dataObj);
              }
              
            } 
          } else if (category === "WileyOTB") {
            if(purchase.name === "CMA") {
              if(this.purchaseData.wiley_otb.length === 0) {
                  dataObj.data = [];
                  obj.items.push(dataObj);
              }
              if(this.purchaseData.wiley_otb.length !== 0) {
                  dataObj.data = this.datatoTimestamp(this.purchaseData.wiley_otb[0].wiley_otb_sent_date);
                  obj.items.push(dataObj);
              }
                  
            } 
          }
          
       
        });
        returnArr.push(obj);
      });
       
      console.log(returnArr);
      return returnArr;
    },
    getdeliverableStatus(deliverable) {
      let name = "";
      if (deliverable.dispatch_status === "dispatched") {
        name = deliverable.delivery_status;
      } else {
        name = deliverable.dispatch_status;
      }
      return name;
    },
   
    generateicon(book) {
      // console.log("got the book for icon genereation");
      let icon = "";
      if (book.delivery_status === "delivered") {
        icon = "done";
      } else if (book.dispatch_status === "dispatched") {
        icon = "done";
      } else if (book.dispatch_status === "to_be_dispatched") {
        icon = "clear";
      } else if (book.dispatch_status === "amount_pending") {
        icon = "report_problem";
      }
      return icon;
    },
    generatecolor(book) {
      let color = "";
      if (book.delivery_status === "delivered") {
        color = "success";
      } else if (book.dispatch_status === "dispatched") {
        color = "warning";
      } else if (book.dispatch_status === "to_be_dispatched") {
        color = "danger";
      } else if (book.dispatch_status === "amount_pending") {
        color = "danger";
      }
      return color;
    },
    getTextforDisplay(text) {
      let color = "";
      if (text === "delivered" || text === "Delivered") {
        color = "Delivered";
      } else if (text === "dispatched" || text === "Dispatched") {
        color = "Dispatched";
      } else if (text === "to_be_dispatched" ) {
        color = "To Be Dispatched";
      } else if (text === "amount_pending") {
        color = "Amount Pending";
      }
      return color;
    },
    getdefaulterColor(fetch) {
      console.log("fetch", fetch.defaulter);
      switch (fetch.defaulter) {
        case 0:
        return ""
        case 1:
        return "danger";
      }
    }
  },
};
</script>

<style>
.books-head{
  margin:20px 0 10px 10px;
  color: #0043bfbd !important
}
.font-awesome,.books-head{
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1.25px;
  color: #626262;
}
.boxed {
  border: 1px solid gray;
  margin-bottom: 1%;
}
.hide {
  display: none;
}
    
.myDIV:hover + .hide {
  display: block;
  background: #7b7b7b;
    border-radius: 5px;
    border: 1px solid gainsboro;
    padding: 5px;
    color: #ffffff;
}
@media only screen and (max-width: 1415px) {
  .font-awesome,.books-head{
  font-size: 12px !important;
}
}
.cpa-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cpa-table td ,.cpa-table th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
.check-box{
  display: flex !important;
  justify-content: center !important;
}
.cpa-table td:first-child {
  border-left: 1px solid #ddd;
}
.vs-con-table .vs-con-tbody {
  background: #f8f8f8;
  border: 2px solid #f8f8f8;
  width: 100%;
  overflow: auto;
}
</style>