<template>
    <div>
        <vs-row>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Can-ID
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Can.Name
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Course
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >AgreedCost</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2"
                style="font-size: 12px"
            >
                Enrollment Date
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="1"
                style="font-size: 12px"
            >
                Refferal Given Status
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="0.5"
                style="font-size: 12px"
            >
            </vs-col>
        </vs-row>
        <vs-collapse
            :type="type"
            v-for="(referral, index) in referralChildData"
            :key="index"
        >
            <vs-collapse-item :key="referral.enrollment_id" icon-arrow="false" v-if="referral.more_referrals_given === 'Yes'">
                <div slot="header">
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.identity }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.can_name }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.course }}
                        </vs-col>

                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.agreed_cost }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{
                                convertTimestampToDate(referral.enrollment_date)
                            }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="center"
                            vs-w="1"
                            style="font-size: 12px;margin-left: 2%;"
                        >
                            {{ referral.more_referrals_given }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="end"
                            vs-align="center"
                            vs-w="0.5"
                            style="font-size: 12px"
                        >
                         <div @click="childreferrals(referral)"><vs-icon icon="add"></vs-icon></div>
                        </vs-col>
                    </vs-row>
                </div>
                <div>
                    <referral-child :referralChildData="referralHistory"></referral-child>
                </div>
            </vs-collapse-item>
            <vs-collapse-item v-bind:key="referral.enrollment_id" icon-arrow="remove" v-if="referral.more_referrals_given === 'No'">
                <div slot="header">
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.identity }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.can_name }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.course }}
                        </vs-col>

                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.agreed_cost }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{
                                convertTimestampToDate(referral.enrollment_date)
                            }}
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-w="2"
                            style="font-size: 12px"
                        >
                            {{ referral.more_referrals_given }}
                        </vs-col>
                    </vs-row>
                </div>
            </vs-collapse-item>
        </vs-collapse>
    </div>
</template>

<script>
import axios from "axios";
// import chatInterface from "./DashboardChatInterface.vue";
import constants from "../../../../constants.json";
// import vSelect from "vue-select";
import refferalChild from "./refererralChildSection.vue"
export default {
    props: {
        referralChildData: {
            required: true,
            type: Array,
        },
    },
    components: {
        "referral-child": refferalChild
    },
    mounted() {
        console.log(this.payload);
        this.canData = this.payload;
        // this.getRefferalData();
    },
    data() {
        return {
            type: "border",
            bool: false,
            can_identity: "",
            dummyJson: [
                {
                    "can_name": "T S Lakshmi Deepika",
                    "identity": "C-8030",
                    "course": "CPA",
                    "agreed_cost": 99000,
                    "enrollment_date": 1605551400,
                    "more_referrals_given": "Yes"
                },
                {
                    "can_name": "Manasa Tammana",
                    "identity": "H-37351",
                    "course": "CPA",
                    "agreed_cost": 89000,
                    "enrollment_date": 1611470136,
                    "more_referrals_given": "No"
                },
                {
                    "can_name": "D Hemalatha",
                    "identity": "H-78295",
                    "course": "CPA",
                    "agreed_cost": 89000,
                    "enrollment_date": 1613739554,
                    "more_referrals_given": "No"
                },
                {
                    "can_name": "Vaidehi Acharya",
                    "identity": "H-84454",
                    "course": "CPA",
                    "agreed_cost": 89000,
                    "enrollment_date": 1614409981,
                    "more_referrals_given": "No"
                }
            ]
        };
    },
    methods: {
        childreferrals(data) {
            
            console.log("child", data)
            this.referralHistory = [];
            let obj = {
                identity: data.identity,
            };
            console.log(obj);
            let url = `${constants.SERVER_API}getReferralGivenDetails`;
            axios
                .get(url, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("referral", response.data.referrals);
                    this.referralHistory = response.data.referrals;
                    document.getElementById("dynamicheight").children[1].style.maxHeight = "273px";
                })
                .catch((error) => {
                    this.handleError(error);
                });
        }
    }
};
</script>

<style>
.vs-collapse-item--content {
    max-height: 273px;
}
</style>