<template>
  <vs-popup id="receiptpopup" class="holamundo" title="Adjust Receipt" :active.sync="open">
    <div>
      <vs-row style="margin-bottom: 5%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <b style="color: gray; margin-right: 2%">
            Adjust receipt for {{ enrollmentData.person_name }} whose
            candidateID is {{ enrollmentData.identity }} for
            {{ enrollmentData.course }} course.
          </b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom:5%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <v-select
            style="width:240px; z-index:1000;"
            placeholder="Type"
            v-model="transaction_type"
            :options="list_of_transactions"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom:3%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            onkeypress="if(this.value.length==6) return false;"
            min="0"
            v-model="amount"
            type="number"
            placeholder="Amount"
            style="width: 244px; margin-top: -3%;"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom:3%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input v-model="ReceiptNumber" placeholder="Receipt Number" style="width: 244px;" />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <vs-button
            style="margin-left: 2%;width: auto;float: right;left: 200px;"
            color="dark"
            size="medium"
            type="border"
            @click="adjustReceiptToEnrollment()"
          >Add Receipt</vs-button>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  components: {
    vSelect,
    Datepicker
  },
  watch: {},
  mounted() {
    eventbus.$on("open-AdjustReceiptPopup", tr => {
      console.log("fds", tr);
      this.enrollmentData = tr;
      this.amount = tr.amount;
      this.ReceiptNumber = tr.receipt_no;
      this.ReceiptId = tr.id;
      this.open = true;
    });
    // document.getElementById("receiptpopup").children[1].style.width = "80%";
  },
  data() {
    return {
      open: false,
      popupwidth: "40%",
      rawdata: [],
      ReceiptNumber: "",
      selectedData: [],
      list_of_transactions: ["ADJ-"],
      transaction_type: "",
      amount: "",
      ReceiptId: "",
      enrollmentData: ""
    };
  },
  methods: {
    refresh() {
      this.amount = "";
      this.ReceiptNumber = "";
    },
    adjustReceiptToEnrollment() {
      if (this.checkParameters()) {
        let obj = {
          receipt_id: this.ReceiptId,
          transaction_type: this.transaction_type,
          adjustment_amount: this.amount
        };
        if (this.transaction_type === "ADJ-") {
          obj.adjustment_amount = -1 * obj.adjustment_amount;
        }
        console.log(obj);
        axios
          .post(`${constants.SERVER_API}adjustReceipt`, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            console.log(response);
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Successfully added a receipt",
                text: "Receipt's been added to this enrollment",
                color: "success"
              });
              eventbus.$emit("reloadreceipts");
              this.open = false;
            } else {
              this.$vs.notify({
                title: response.data.status,
                text: response.data.message,
                color: "danger"
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    },
    checkZeroValue() {
      let bool = false;
      let receipt_number = this.ReceiptNumber;
      receipt_number = receipt_number.replace(/[^0]/g, "").length;
      if (receipt_number === this.ReceiptNumber.length) {
        bool = true;
      }
      // console.log(bool);
      return bool;
    },
    checkParameters() {
      if (this.checkZeroValue()) {
        // return true;
        this.$vs.notify({
          title: "Enter a valid receipt number",
          text: "A valid receipt number is required for adding a receipt",
          color: "danger"
        });
        return false;
      }
      if (
        this.transaction_type === "ADJ-" ||
        this.transaction_type === "ADJ+"
      ) {
        if (this.amount !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter an amount",
            text: "Enter an amount to continue",
            color: "danger"
          });
          return false;
        }
      }
      return false;
    },
    enrollmentReceiptAdding(item) {
      this.enrollmentData = item;
      this.showReceiptAdding = true;
    },
    receiptadding() {
      if (this.rawdata.length === 0) {
        this.$vs.notify({
          title: "Search for a lead",
          text: "Search for a lead to a receipt",
          color: "warning"
        });
        return;
      } else {
        this.addReceipt = true;
      }
    }
  }
};
</script>

<style>
#datepickered {
  width: 244px;
  right: 0px;
}
.divied {
  /* width: 100%; */
  /* position: relative; */
  /* display: block; */
  margin: 7px 0;
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
</style>