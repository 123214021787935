<template>
  <vs-popup
    title="ADD A EMAIL ADDRESS"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
        <vs-input
          icon="add"
          v-model="email"
          label-placeholder="Email Address"
        />
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-end"
        vs-w="4"
      >
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="gradient"
          icon="add"
          @click="addEmail"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("add-email", (payload) => {
      this.payload = payload;
      this.open = true;
      this.email = "";
    });
  },
  data() {
    return {
      open: false,
      email: "",
      payload: "",
    };
  },
  methods: {
    async addEmail() {
      let obj = {
        can_id: this.payload.can_id,
        person_id: this.payload.person_id,
        person_name: this.payload.person_name,
        emails: this.email,
      };
      // console.log(obj);
      // return;
      axios
        .post(`${constants.SERVER_API}addEmail`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if (response.data.status === `success`) {
            let emailId = {
              id: response.data.new_email_id,
              masked_email: response.data.new_masked_email,
            };
            this.$store.commit("UPDATE_EMAIL", [this.payload, emailId]);
            EventBus.$emit("addedEWBEmail", emailId);
            if (this.$route.path === "/queue") {
              // EventBus.$emit("update-queue-addition");
              EventBus.$emit("update-activelead-data");
            } else {
              EventBus.$emit("reload-bigpopup-data");
            }
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
              time: 10000,
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   } else {
          //     this.$vs.notify({
          //       title: "Network Error",
          //       text: "Please contact Admin",
          //       color: "danger",
          //       time: 10000
          //     });
          //   }
          // }
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
