<template>
  <div>
    <vx-card actionable class="cardx" title="Wiley Application">
      <div style="margin-top: 2%" v-for="(course, index) in TotalApplicationData" :key="index">
          <b>{{ course.miles_course }}</b>
        <vs-table :data="course.application_status">
          <template slot="thead">
            <!-- <vs-th>Loan Type</vs-th>
            <vs-th>Application ID</vs-th>
            <vs-th>Date</vs-th> -->
            <vs-th>Application Stage</vs-th>
            <vs-th>Application Sub Stage</vs-th>
            <vs-th>Date</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data">
                <!-- <vs-td>
                  {{ getLoanType(tr) }}
                </vs-td>
                <vs-td>
                  {{ getApplicationID(tr) }}
                </vs-td>
                <vs-td>
                  {{ formattedCreatedAt(tr.created_at) }}
                </vs-td> -->
                <vs-td>
                  {{ tr.application_stage }}
                </vs-td>
                <vs-td>
                  {{ tr.application_sub_stage }}
                </vs-td>
                <vs-td>
                  {{ formatDate(tr.created_at) }}
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../../constants.json";
// import EventBus from "../../eventbus";
import moment from "moment";
export default {
  mounted() { 
    this.getUserInfor();
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trackingdata: [],
      candidate_uuid: "",
      popup: false,
      LoanData:[],
      TotalApplicationData: []
    };
  },
  components: {
  },
  watch: {},
  methods: {
    
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY");
      return formttedDate;
    },
    getUserInfor() {
      const url = `${constants.SERVER_API}wileyApplicationStatus?mwb_id=${this.payload.mwb.id}`;
      // const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=469671`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
            console.log("mwb data", response);
            this.TotalApplicationData = response.data.data;
            // response.data.data.forEach(element => {
            //     console.log("element", element)
            //     if(element.miles_course === "IIML-BA") {
            //         this.LoanData = element["IIML-BA"];
            //     } else if(element.miles_course === "IITM-FS") {
            //         this.LoanData = element["IITM-FS"];
            //     } else if(element.miles_course === "IIML-PM") {
            //         this.LoanData = element["IIML-PM"];
            //     }
            // });
          

        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    
    
    
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
</style>