<template>
  <vs-card style="height: 840px; overflow-y: auto">
    <div style="display:flex;justify-content:left;align-items:center">
      <img src="../../../assets/images/pages/login/whatsapp.svg" width="30px" height="30px" style="margin-top:8px" v-if="whatsapp_ne || candidate_details.whatsapp_whitelisted == 'Yes'"/>

      <!-- <h6 class="candidate_heading" v-if="pathway_data != 'NA'">
        {{
          candidate_details.mwb.identity +
            " - "  +
            candidate_details.mwb.person_name +
            " - " +
            candidate_details.mwb.level +
            " - " +
            unixTimestampConvert(candidate_details.mwb.last_call) +
            getIIMLData(candidate_details.mwb) +
            " - " +
            spocsNames
        }}
      </h6> -->
      
      <h6 class="candidate_heading">
        {{
    candidate_details.mwb.identity +
    " - " +
    candidate_details.mwb.person_name +
    " - " +
    candidate_details.mwb.level +
    " - " +
    unixTimestampConvert(candidate_details.mwb.last_call) +
    getIIMLData(candidate_details.mwb) +
    " - " +
    spocsNames
}}
      </h6>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" style="height: 100px">
        <vs-navbar text-color="black" color="#ffffff" active-text-color="#FFFFFF" class="myNavbar"
          v-model="ActiveNavBarTab">
          <vs-navbar-item class="nav_item" index="PURCHASES" v-if="
            candidate_details.purchases !== null &&
            candidate_details.purchases.length !== 0
          ">
            <a>PURCHASES</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item index="IIML SR">
            <a>IIML SR</a>
          </vs-navbar-item> -->
          <vs-navbar-item index="CPA SR" v-if="candidate_details.cpa !== null" class="nav_item">
            <a>CPA SR</a>
          </vs-navbar-item>
          <vs-navbar-item index="CPA-AA" v-if="cpa_aa_tab" class="nav_item">
            <a>CPA-AA</a>
          </vs-navbar-item>
          <vs-navbar-item index="CFA 1" v-if="cfa_tab" class="nav_item">
            <a>CFA 1</a>
          </vs-navbar-item>
          <vs-navbar-item index="CFA 2" v-if="cfa_tab2" class="nav_item">
            <a>CFA 2</a>
          </vs-navbar-item>
          <vs-navbar-item index="CFA 3" v-if="cfa_tab3" class="nav_item">
            <a>CFA 3</a>
          </vs-navbar-item>
          <vs-navbar-item index="FRM 1" v-if="frm_tab" class="nav_item">
            <a>FRM 1</a>
          </vs-navbar-item>
          <vs-navbar-item index="FRM 2" v-if="frm_tab2" class="nav_item">
            <a>FRM 2</a>
          </vs-navbar-item>
          <vs-navbar-item index="FRM 3" v-if="frm_tab3" class="nav_item">
            <a>FRM 3</a>
          </vs-navbar-item>
          <vs-navbar-item index="USP" v-if="usp_tab" class="nav_item">
            <a>USP</a>
          </vs-navbar-item>
          <vs-navbar-item index="CPA+USP" v-if="cpa_usp_tab" class="nav_item">
            <a>CPA+USP</a>
          </vs-navbar-item>
          <vs-navbar-item index="AICPA" v-if="aicpa_tab" class="nav_item">
            <a>AICPA</a>
          </vs-navbar-item>
          <vs-navbar-item index="CMA SR" v-if="candidate_details.cma !== null" class="nav_item">
            <a>CMA SR</a>
          </vs-navbar-item>
          <vs-navbar-item index="ABOUT" class="nav_item">
            <a>ABOUT</a>
          </vs-navbar-item>
          <vs-navbar-item index="SCRIPT" class="nav_item">
            <a>SCRIPT</a>
          </vs-navbar-item>
          <vs-navbar-item v-if="historyTab" index="HISTORY" class="nav_item">
            <a>HISTORY</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item v-if="candidate_details.loan_status.length > 0" index="LOANCHECK" class="nav_item">
            <a>EDUVANZ LOAN</a>
          </vs-navbar-item> -->
          <vs-navbar-item index="LIQUILOANS" class="nav_item">
            <a>LIQUI LOANS</a>
          </vs-navbar-item>
          <vs-navbar-item v-if="enquiriesTab" index="ENQUIRIES" class="nav_item">
            <a>ENQUIRIES</a>
          </vs-navbar-item>
          <vs-navbar-item index="SCHEDULES" class="nav_item">
            <a>VISIT SCHEDULE</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item index="JOURNEY">
            <a>JOURNEY</a>
          </vs-navbar-item> -->
          <!-- <vs-navbar-item index="Call Recordings">
            <a>PLAY</a>
          </vs-navbar-item> -->
          <!-- <vs-navbar-item index="LTH">
            <a>LTH</a>
          </vs-navbar-item> -->
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-show="ActiveNavBarTab === 'PURCHASES'" style="width: 100%">
      <purchase-section v-if="ActiveNavBarTab === 'PURCHASES'" :studentDetails="candidate_details"
        :purchaseData="purchaseData"></purchase-section>
    </div>
    <div v-show="ActiveNavBarTab === 'IIML SR'" style="width: 100%">
      <iiml-section></iiml-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CPA SR'" style="width: 100%">
      <cpa-section v-if="ActiveNavBarTab === 'CPA SR'" :studentDetails="candidate_details"></cpa-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CPA-AA'" style="width: 100%">
      <cpa-aa-section v-if="ActiveNavBarTab === 'CPA-AA'" :studentDetails="candidate_details"></cpa-aa-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CFA 1'" style="width: 100%">
      <cfa-section v-if="ActiveNavBarTab === 'CFA 1'" :studentDetails="candidate_details"></cfa-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CFA 2'" style="width: 100%">
      <cfa-section-2 v-if="ActiveNavBarTab === 'CFA 2'" :studentDetails="candidate_details"></cfa-section-2>
    </div>
    <div v-show="ActiveNavBarTab === 'CFA 3'" style="width: 100%">
      <cfa-section-3 v-if="ActiveNavBarTab === 'CFA 3'" :studentDetails="candidate_details"></cfa-section-3>
    </div>
    <div v-show="ActiveNavBarTab === 'FRM 1'" style="width: 100%">
      <frm-section v-if="ActiveNavBarTab === 'FRM 1'" :studentDetails="candidate_details"></frm-section>
    </div>
    <div v-show="ActiveNavBarTab === 'FRM 2'" style="width: 100%">
      <frm-section-2 v-if="ActiveNavBarTab === 'FRM 2'" :studentDetails="candidate_details"></frm-section-2>
    </div>
    <div v-show="ActiveNavBarTab === 'FRM 3'" style="width: 100%">
      <frm-section-3 v-if="ActiveNavBarTab === 'FRM 3'" :studentDetails="candidate_details"></frm-section-3>
    </div>
    <div v-show="ActiveNavBarTab === 'CMA SR'" style="width: 100%">
      <cma-section v-if="ActiveNavBarTab === 'CMA SR'" :studentDetails="candidate_details"></cma-section>
    </div>
    <div v-show="ActiveNavBarTab === 'USP'" style="width: 100%">
      <usp-section v-if="ActiveNavBarTab === 'USP'" :studentDetails="candidate_details"></usp-section>
    </div>
    <div v-show="ActiveNavBarTab === 'CPA+USP'" style="width: 100%">
      <cpa-usp-section v-if="ActiveNavBarTab === 'CPA+USP'" :studentDetails="candidate_details"></cpa-usp-section>
    </div>
    <div v-show="ActiveNavBarTab === 'AICPA'" style="width: 100%">
      <aicpa-section v-if="ActiveNavBarTab === 'AICPA'" :studentDetails="candidate_details"></aicpa-section>
    </div>
    <div v-show="ActiveNavBarTab === 'ABOUT'" style="width: 100%">
      <about-section v-if="ActiveNavBarTab === 'ABOUT'" :studentDetails="candidate_details"></about-section>
    </div>
    <div v-show="ActiveNavBarTab === 'SCRIPT'" style="width: 100%">
      <script-section v-if="ActiveNavBarTab === 'SCRIPT'" :studentDetails="candidate_details"></script-section>
    </div>
    <div v-show="ActiveNavBarTab === 'HISTORY'" style="width: 100%">
      <history-section v-if="ActiveNavBarTab === 'HISTORY'" :studentDetails="candidate_details"></history-section>
    </div>
    <div v-show="ActiveNavBarTab === 'LOANCHECK'" style="width: 100%">
      <loan-check v-if="ActiveNavBarTab === 'LOANCHECK'" :studentDetails="candidate_details"></loan-check>
    </div>
    <div v-show="ActiveNavBarTab === 'LIQUILOANS'" style="width: 100%">
      <liqui-loans v-if="ActiveNavBarTab === 'LIQUILOANS'" :studentDetails="candidate_details"></liqui-loans>
    </div>
    <div v-show="ActiveNavBarTab === 'ENQUIRIES'" style="width: 100%">
      <enquiries-section v-if="ActiveNavBarTab === 'ENQUIRIES'" :studentDetails="candidate_details"></enquiries-section>
    </div>
    <div v-show="ActiveNavBarTab === 'SCHEDULES'" style="width: 100%">
      <schedules-history-section v-if="ActiveNavBarTab === 'SCHEDULES'"
        :studentDetails="candidate_details"></schedules-history-section>
    </div>
    <!-- <div v-show="ActiveNavBarTab === 'JOURNEY'" style="width: 100%">
      <journey-section v-if="ActiveNavBarTab === 'JOURNEY'"
        :studentDetails="candidate_details"></journey-section>
    </div> -->
    <!-- <div v-show="ActiveNavBarTab === 'Call Recordings'" style="width: 100%">
      <play-section
        v-if="ActiveNavBarTab === 'Call Recordings'"
        :studentDetails="candidate_details"
      ></play-section>
    </div> -->
    <!-- <div v-show="ActiveNavBarTab === 'LTH'" style="width: 100%">
      <lth-section  
        v-if="ActiveNavBarTab === 'LTH'"
        :studentDetails="candidate_details" 
       ></lth-section>
    </div> -->
  </vs-card>
</template>

<script>
import AboutSection from "./AboutSection.vue";
import PurchaseSection from "./PurchaseSection.vue";
import CfaSection from "./CfaSection.vue"
import CFASection2 from "./CFASection2.vue"
import CFASection3 from "./CFASection3.vue"
import FrmSection from "./FrmSection.vue"
import FrmSection2 from "./FRMSection2.vue"
import FrmSection3 from "./FRMSection3.vue"
import CpaSection from "./CpaSection.vue";
import IimlSection from "./IimlSection.vue";
import CmaSection from "./CmaSection.vue";
import CpaAaSection from "./CpaAaSection.vue";
import HistorySection from "./HistorySection.vue";
import EnquiriesSection from "./EnquiriesSection.vue";
import SchedulesHistorySection from "./SchedulesHistorySection.vue";
import ScriptSection from "./ScriptSection.vue"
// import JourneySection from "./JourneySection.vue";
// import PLAY from "./play";
import LoanCheck from "./LoanApplicationCheck.vue";
import LthSection from "./LthSection.vue";
// import constants from "../../../../constants.json";
import LiquiLoans from "./LiquiLoans.vue";
// import _ from "lodash";
// import axios from "axios";
import UspSection from './UspSection.vue';
import CpaUspSection from './CpaUspSection.vue';
import AicpaSection from './AicpaSection.vue'
export default {
  components: {
    "about-section": AboutSection,
    "purchase-section": PurchaseSection,
    "cpa-section": CpaSection,
    "cma-section": CmaSection,
    "cpa-aa-section": CpaAaSection,
    "history-section": HistorySection,
    "iiml-section": IimlSection,
    "schedules-history-section": SchedulesHistorySection,
    "enquiries-section": EnquiriesSection,
    "loan-check": LoanCheck,
    // "play-section":PLAY,
    "lth-section": LthSection,
    "liqui-loans": LiquiLoans,
    "script-section": ScriptSection,
    "cfa-section": CfaSection,
    "cfa-section-2": CFASection2,
    "cfa-section-3": CFASection3,
    "frm-section" : FrmSection,
    "frm-section-2" : FrmSection2,
    "frm-section-3" : FrmSection3,
    "usp-section": UspSection,
    "cpa-usp-section" : CpaUspSection,
    "aicpa-section" : AicpaSection
    // "journey-section": JourneySection
  },
  props: {
    candidate_details: {
      required: true,
      type: Object,
    },
    spocsNames: {
      required: true,
    },
  },
  data() {
    return {
      ActiveNavBarTab: "ABOUT",
      purchaseData: "",
      cpa_aa_tab : false,
      frm_tab : false,
      frm_tab2 : false,
      frm_tab3 : false,
      cfa_tab : false,
      cfa_tab2 : false,
      cfa_tab3 : false,
      usp_tab:false,
      cpa_usp_tab:false,
      aicpa_tab:false,
      pathway_data: "",
      whatsapp_ne : false,
      whatsapp_ne_source : ['Website Whatsapp','Gupshup','WhatsApp','Verloop','gupshup','whatsapp','Website Whatsapp'],
      historyTab: true,
      enquiriesTab: true,
    };
  },
  mounted() {
    let login_team = localStorage.getItem("team")
    if(login_team == 'CM'){
      this.enquiriesTab = false;
      let negative_m_levels = ["M1", "M2", "M4", "M4-", "DND", "N/A"];
      if(!negative_m_levels.includes(this.candidate_details.mwb.level) && this.candidate_details.engagements[0].added_by_id != 3226){
        this.historyTab = true;
      }else{
        this.historyTab = false;
      }
    }
    // this.getPurchaseData();
    console.log("CANDIDATE DETAILS IN INFO SECTION",this.candidate_details)
    console.log("Enrolled DETAILS IN INFO SECTION",this.candidate_details.mwb.enrollments)
    if(this.candidate_details.mwb.enrollments.length > 0){
      this.candidate_details.mwb.enrollments.forEach(data => {
        console.log("find course name",data);
       if(data.course == "CPA-AA"){
         this.cpa_aa_tab = true;
       }
       if(data.course == "USP"){
         this.usp_tab = true;
       }
       if(data.course == "CPA+USP"){
         this.cpa_usp_tab = true;
       }
       if(data.course == "CFA"){
        if(data.cfa_selected_level == 'Level 1'){
          this.cfa_tab = true;
        }
        if(data.cfa_selected_level == 'Level 2'){
          this.cfa_tab2 = true;
        }
        if(data.cfa_selected_level == 'Level 3'){
          this.cfa_tab3 = true;
        }
       }
       if(data.course == "FRM"){
        if(data.frm_selected_part == 'Part 1'){
          this.frm_tab = true;
        }
        if(data.frm_selected_part == 'Part 2'){
          this.frm_tab2 = true;
        }
        if(data.frm_selected_part == 'Part 3'){
          this.frm_tab3 = true;
        }
       }
      })
    }
    if(this.candidate_details.ne_data.length > 0){
      this.candidate_details.ne_data.forEach(ne => {
          if(this.whatsapp_ne_source.includes(ne.source)){
              this.whatsapp_ne = true
          }
      });
    }
    if (this.candidate_details.persona_details == null) {
      this.pathway_data = "NA"
    } else {
      this.pathway_data = this.candidate_details.persona_details.pathway
    }

    if (this.candidate_details.persona_details.pathway == null) {
      this.pathway_data = "NA"

    } else {
      this.pathway_data = this.candidate_details.persona_details.pathway
    }
  },
  methods: {
    getIIMLData(mwb) {
      if (mwb.iiml_level !== null) {
        return ` - ${mwb.iiml_level}`;
      } else {
        return "";
      }
    },
    // getPurchaseData() {
    //   console.log(this.candidate_details);
    //   let obj = {
    //     person_id: this.candidate_details.mwb.person_id,
    //     mwb_id: this.candidate_details.mwb.id,
    //   };
    //   axios
    //     .get(`${constants.SERVER_API}getBooksLMSWileyOTBStatus`, {
    //       params: obj,
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("response", response.data);

    //       this.purchaseData = response.data;

    //       console.log("purchse data infosection", this.purchaseData);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
  },
};
</script>

<style>
.vs-navbar--item .is-active-item .vs-navbar-item-black {
  color: black;
  background: #0044ba;
  border-radius: 15px;
}

.myNavbar {
  box-shadow: none !important;
}

.nav_item a {
  background: rgba(241, 241, 241, 0.6);
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-inline: 2px;
  text-align: center;
  color: #000000;
}

.myNavbar .vs-navbar--item.is-active-item .vs-navbar-after {
  background: #ffffff !important;
}

.is-active-item>a {
  background: #0044bf;
  /* color: white; */
  border-radius: 10px;
}

.myNavbar .nav_item>a:hover {
  color: #000000;
}

.myNavbar .vs-navbar--item:hover {
  /* padding-bottom: 2px; */
  color: black !important
}

.myNavbar .is-active-item:hover,
.myNavbar .is-active-item>a:hover {
  color: #FFFFFF !important
}

.is-active-item>a :hover {
  color: #0044bf !important;
}

.candidate_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 5px;
  border-radius: 15px;
  padding: 5px 0px 5px 8px;
  min-height: 28px;
  height: auto;
  background: rgba(241, 241, 241, 0.6);
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
</style>
