<template>
  <div>
    <vs-popup class="assignspocpopupwidth" :id="'assignlead'" :title="'Assign Leads'" :active.sync="open">
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">Search</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
          <vs-chip color="warning" v-for="(chip, index) in search" :key="index">{{chip}}</vs-chip>
        </vs-col>
      </vs-row>-->
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b>{{ assignLeadPopupHeading }}</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="8"
        >
          <v-select
            :options="cities"
            placeholder="Select City"
            v-model="cityselected"
            style="width:24%; position: fixed; z-index:1100"
          />
        </vs-col>
      </vs-row>
      <br/>
      <vs-row v-if="team == 'CM'" style="margin-top: 1%">
        <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>User Tag :</b></vs-col>
        <vs-col  vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
          <v-select
            style="width:24%; position: fixed; z-index:1000"
            v-model="selected_user_tag"
            :options="selected_city_usertags"
            placeholder="Select User Tag"
          ></v-select>
        </vs-col>
      </vs-row>
      <br/>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b style="color: gray">SPOC</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="8"
        >
          <v-select
            label="full_name"
            :multiple="false"
            :options="cityspoc"
            v-model="value1"
            placeholder="Select Spoc"
            style="position: fixed; z-index:900;width : 24%;"
          />
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="1"
        >
         <vs-button color="dark" type="filled" @click="assignAPI" style="margin-left:-15px"
            >Assign</vs-button
          > 
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
// import moment from "moment";
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      selected_city_usertags: [],
      selected_user_tag: "",
      selected_cm_spocs: [],
      open: false,
      decidebool: false,
      cityspoc: [],
      title: "Example",
      spoc_names: [],
      totalleadcount: "",
      value1: "",
      cities: [],
      cityselected: "",
      selected: "",
      deselected: "",
      allselect: "",
      sendobj: "",
      search: "",
      rawBdeSR: [],
      team: null,
      assignLeadPopupHeading: null,
      user_tag: "",
      EntrollmentDateRange: {
        startDate: null,
        endDate: null,
      },
      logged_in_id:"",
      logged_in_team: '',
    };
  },
  components: {
    vSelect,
    Treeselect
  },
  mounted() {
    this.logged_in_team = localStorage.getItem("team")
    this.logged_in_id = localStorage.getItem("spoc_id");
    EventBus.$on(
      "assign-lead",
      (payload, payload2, selectall, sendobj, totalleadcount, team) => {
        this.cities = [];
        this.team = team;
        if (team === "CM") {
          this.assignLeadPopupHeading = "City";
          this.getCMSRSpocs();
          this.getSpoc();
        } else if (team === "SR") {
          this.assignLeadPopupHeading = "City";
          this.getSRSpocs();
        } else {
          this.getExed();
          this.assignLeadPopupHeading = "Courses";
          console.log("this.team", team);
        }
        
        this.decidebool = false;
        this.value1 = "";
        this.cityselected = "";
        this.totalleadcount = totalleadcount;
        this.selected = payload;
        this.deselected = payload2;
        let lengthstring = 0;
        this.allselect = selectall;
        this.sendobj = sendobj;
        this.filtersearch();
        if (selectall === true) {
          lengthstring = totalleadcount - payload2.length;
          let title = "";
          title = `Assigning ${lengthstring} leads`;
          this.title = title;
        } else {
          lengthstring = payload.length;
          this.title = `Assigning ${lengthstring} lead`;
        }
        // console.log(lengthstring);
        if (lengthstring > 1) {
          this.decidebool = true;
        }
        this.open = true;
      }
    );
    console.log("date range")
      
  },
  watch: {
    cityselected(value) {
      if (this.team !== "SR") {
        this.filterspoc(value);
      } else {
        console.log("spoc sr name", value);
        this.srfilterspoc(value);
      }
      
    },
    selected_user_tag(value){
      this.filterUserTag(value);
    },
  },
  methods: {
    filterspoc(val) {
      this.value1 = "";
      this.selected_user_tag = "";
      this.selected_city_usertags = [];
      this.cityspoc = [];
      this.spoc_names.forEach((spoc) => {
        if (this.team === "CM" && localStorage.getItem("team") != "GM") {
          if (spoc.city === val) {
            this.selected_city_usertags.push(spoc);
          }
        } else if (localStorage.getItem("team") === "GM"){
          if(localStorage.getItem("spoc_id") == spoc.reporting_to){
            this.selected_city_usertags.push(spoc);
          }  
        } else if (this.team === "SR") {
          console.log("spoc name", spoc);
          if (spoc.city === val) {
            this.cityspoc.push(spoc);
            
          }
        } else {
          if (spoc.sub_team === val) {
            this.cityspoc.push(spoc);
            console.log("spoc name", spoc);
          }
        }
      });
      if(this.team === "CM"){
        this.selected_city_usertags = [...new Set(this.selected_city_usertags.map((item) => item.user_tag)), ];
      }
    },
    srfilterspoc(val) {
      this.value1 = "";
      this.cityspoc = [];
      this.spoc_names.forEach((spoc) => {
        console.log("spoc sr name", spoc);
          if (spoc.city === val) {
            this.cityspoc.push(spoc);
            
          }
        
      });
    },
    filterUserTag(selected_user_tag) {
      this.value1 = [];
      this.cityspoc = [];
      this.spoc_names.forEach((spoc) => {
        if (spoc.user_tag === selected_user_tag && spoc.city == this.cityselected) {
          if(localStorage.getItem("team") != "GM"){
            this.cityspoc.push(spoc);
          }
          if(localStorage.getItem("team") == "GM"){
            if(localStorage.getItem("spoc_id") == spoc.reporting_to){ 
              this.cityspoc.push(spoc);
            }
          }
        }
      });
      console.log("city spocs", this.cityspoc);
    },
    filtersearch() {
      let course = [];
      if (this.sendobj.courses !== "") {
        course = this.sendobj.courses.split(",");
      }
      let levels = [];
      if (this.sendobj.levels !== "") {
        levels = this.sendobj.levels.split(",");
      }
      let bde = [];
      if (this.sendobj.spoc_ids !== "") {
        bde = this.sendobj.spoc_ids.split(",");
      }
      // console.log(course);
      // console.log(levels);
      // console.log(bde);
      let bdenames = [];
      bde.forEach((id) => {
        // console.log(this.spoc_names);
        this.spoc_names.forEach((spoc) => {
          if (parseInt(id) === spoc.id) {
            bdenames.push(spoc.full_name);
          }
        });
      });
      // console.log(bdenames);
      this.search = course.concat(levels, bdenames);
      // console.log(this.search);
    },
    assignAPI() {
      // this.$vs.loading({
      //   type: "default",
      // });
      if (this.value1 === "") {
        this.$vs.notify({
          title: "Select a Spoc",
          text: "Select a Spoc to assign the selected leads",
          color: "danger",
        });
      } else {
        let selected = [];
        let deselected = [];
        if (this.allselect === true) {
          selected = [];
          deselected = [];
          this.deselected.forEach((each) => {
            deselected.push(each.id);
          });
        } else {
          selected = [];
          this.selected.forEach((each) => {
            selected.push(each.id);
          });
          deselected = [];
        }
        let allselect = "";
        if (this.allselect == true) {
          allselect = "yes";
        } else {
          allselect = "no";
        }
        this.sendobj.selected_lead_ids = selected.join();
        if (this.sendobj.selected_lead_ids === "") {
          this.sendobj.selected_lead_ids = null;
        }
        this.sendobj.excluded_lead_ids = deselected.join();
        if (this.sendobj.excluded_lead_ids === "") {
          this.sendobj.excluded_lead_ids = null;
        }
        // this.sendobj.spoc_name = this.value1.full_name;
        // let ids = [];
        // if (this.decidebool === false) {
        //   this.sendobj.spoc_id = this.value1.id;
        // } else {
        //   this.value1.forEach((value) => {
        //     ids.push(value.id);
        //   });
        //   this.sendobj.spoc_id = ids.join();
        // }

        let names = [];
        if (this.decidebool === false) {
          this.sendobj.spoc_name = this.value1.full_name;
        } else {
          this.value1.forEach((value) => {
            names.push(value.full_name);
          });
          this.sendobj.spoc_name = names.join();
        }

        let spocsNew = [];
        if (this.decidebool === false) {
          this.sendobj.spocs = [
            { spoc_name: this.value1.full_name, spoc_id: this.value1.id },
          ];
        } else {
          this.value1.forEach((value) => {
            let obj = {
              spoc_name: value.full_name,
              spoc_id: value.id,
            };
            spocsNew.push(obj);
          });
          this.sendobj.spocs = spocsNew;
        }
        // if (this.sendobj.my_focus) {
        //   this.sendobj.my_focus = this.sendobj.my_focus.toLowerCase();
        // }

        this.sendobj.select_all_leads_state = allselect;
        this.sendobj.EntrollmentDateRange_startDate = this.EntrollmentDateRange.startDate
        this.sendobj.EntrollmentDateRange_toDate = this.EntrollmentDateRange.toDate
        let url = "";
        if (this.team === "CM") {
          url = `${constants.SERVER_API}assignLeads`;
        } else if (this.team === "IIML") {
          url = `${constants.SERVER_API}assignLeadsIIML`; //iiml
        } else if (this.team === "SR") {
          url = `${constants.SERVER_API}assignSrLeads`; //SR
        }

        this.sendobj.count = 0;

        // if (this.sendobj.my_focus === "untapped without level") {
        //   this.sendobj.my_focus = "untapped_without_level";
        // } else if (this.sendobj.my_focus === "untapped with level") {
        //   this.sendobj.my_focus = "untapped_with_level";
        // }
        console.log("iiml obj url", this.team);

        console.log('Assign single spoc obj',this.sendobj, url);
        if(this.sendobj.spoc_ids === ""){
          this.$vs.notify({
          title: "Please select from SPOC",
          text: "",
          color: "danger",
        });
        } else {
          this.$vs.loading({
          type: "default",
          });
        axios
          .post(url, this.sendobj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              EventBus.$emit("reload-assignments");
              this.open = false;
            }
            this.$vs.loading.close();
            // console.log(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
        }
      }
    },
    getCMSRSpocs() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.rawBdeSR = response.data.spocs;
         
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs() {
      let spoc_id = localStorage.getItem("spoc_id")
      let url = ''
      if (this.logged_in_team == 'GM' && localStorage.getItem("sub_team") != 'GM') {
       url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${spoc_id}`;
      }else{
       url = `${constants.SERVER_API}getSRSpocs`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          let sr_spocs = [];
          for (let j = 0; j < response.data.spocs.length; j++) {
            const element = response.data.spocs[j];
            if (element.team == 'SR' && element.sub_team == 'SR') {
              sr_spocs.push(element);
            }
          }
          this.spoc_names = sr_spocs;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              
              if (
                key !== "null"
              ) {
                this.cities.push(key);
              }
              
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          this.rawBdeSR.forEach((sr) => {
            this.spoc_names.push(sr);
          });
          let filtered_spocs = [];
          response.data.spocs.forEach((spoc) => {
            if(localStorage.getItem("team") != "GM"){
              filtered_spocs.push(spoc);
            }
            if(localStorage.getItem("team") == "GM"){
              if(localStorage.getItem("spoc_id") == spoc.reporting_to){ 
                filtered_spocs.push(spoc);
              }
            }
          });
          console.log("gm spocs list",filtered_spocs);
          var unique = filtered_spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.cities.push(key);
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExed() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          this.rawBdeSR.forEach((sr) => {
            this.spoc_names.push(sr);
          });
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.sub_team,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              if (key !== "null") {
                this.cities.push(key);
              }
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
.assignspocpopupwidth .vs-popup {
  width: 45% !important;
  height: 30% !important;
}
</style>
