<template>
    <div>
        <vs-popup
      class="holamundo"
      title="Showing Previous Months Leaves"
      :active.sync="showattendancePopup"
    >
      <div style="margin-top: 2%">
        <vs-table :data="data">
          <template slot="thead">
            <vs-th>Month</vs-th>
            <vs-th>Leaves</vs-th>
          </template>

          <template slot-scope="">
            <tbody ref="tbody">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
              >
                <vs-td>
                  {{ tr.month_year }}
                </vs-td>
                <vs-td>
                  {{ tr.leaves }}
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vs-popup>
    </div>
</template>
<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      showattendancePopup: false,
      userId: "",
      data: []
    };
  },
  mounted() {
    EventBus.$on("open-Showattendance", (userid) => {
      
      this.userId = userid;
      console.log("opening", this.userId);
      this.showattendancePopup = true;
      this.showleaves();
    });
  },
  methods: {
      showleaves() {
       let obj = {
           user_id: this.userId
       }   
      let url = `${constants.MILES_CM_BACK}getMonthlyLeaves`;
      axios
        .post(url, obj , {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("data", response);
          this.data = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
      }
  },
  watch: {
    
  },
};
</script>

<style></style>