<template>
  <vs-popup
    class="popupwidth"
    v-if="open"
    :title="getPopupTitle()"
    :id="'addLeadForm'"
    :active.sync="open"
    style="position:fixed;z-index:70010"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Name*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="nameinput" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Email</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-if="radios2 === 'net_enquiry'" disabled="true" v-model="emailinput" class="w-full" type="email" />
              <vs-input v-else  v-model="emailinput" class="w-full" type="email" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Mobile*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-if="radios2 === 'net_enquiry'  && login_spoc_id != 332 && login_spoc_id != 3018" disabled="true" v-model="mobileinput" class="w-full" />
              <vs-input v-else  v-model="mobileinput" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Course</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CPA"
                >CPA</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CPA-AA"
                >CPA-AA</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CMA"
                >CMA</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CFA"
                >CFA</vs-checkbox
              >
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CFA-L1"
                >CFA-L1</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CFA-L2"
                >CFA-L2</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CFA-L3"
                >CFA-L3</vs-checkbox
              > -->
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="FRM"
                >FRM</vs-checkbox
              >
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="FRM-P1"
                >FRM-P1</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="FRM-P2"
                >FRM-P2</vs-checkbox
              > -->
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="XLRI-HR"
                >XLRI-HR</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="XLRI-SH"
                >XLRI-SH</vs-checkbox
              >
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIMI-BA"
                >IIMI-BA</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIMI-AA"
                >IIMI-AA</vs-checkbox
              > -->
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="RPO"
                >RPO</vs-checkbox
              > -->
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="USP"
                >USP</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIMI-BA"
                >IIMI-BA</vs-checkbox
              >
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-FT"
                >IIML-FT</vs-checkbox
              >
              <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-SF"
                >IIML-SF</vs-checkbox
              > -->
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2') || checkIfRoleis('exed2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="AICPA"
                >AICPA</vs-checkbox
              > -->
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CISA"
                >CISA</vs-checkbox
              > -->
              <!-- <vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="EA"
                >EA</vs-checkbox
              ><vs-checkbox
                v-if="
                  checkIfRoleis('cm1') || checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('wh2')
                "
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="CIA"
                >CIA</vs-checkbox
              > -->
            <!--  <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-FA"
                >IIML-FA</vs-checkbox
              >

              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-SF"
                >IIML-SF</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-BA"
                >IIML-BA</vs-checkbox
              >

              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-PM"
                >IIML-PM</vs-checkbox
              >

              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IIML-HR"
                >IIML-HR</vs-checkbox
              >

              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IITR-BF"
                >IITR-BF</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="IITR-DB"
                >IITR-DB</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                vs-value="IITM-AA"
                >IITM-AA</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                vs-value="IIMK-CX"
                >IIMK-CX</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                vs-value="IITM-FS"
                >IITM-FS</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                vs-value="IITR-CC"
                >IITR-CC</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                vs-value="IIMK-FT"
                >IIMK-FT</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                 vs-value="IIML-AB"
                >IIML-AB</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                 vs-value="IIML-SH"
                >IIML-SH</vs-checkbox
              >
              <vs-checkbox
                v-if="checkIfRoleis('exed2') || checkIfRoleis('cm4') || checkIfRoleis('wh2')"
                class="inline-flex"
                color="dark"
                v-model="check1"
                 vs-value="IITJ-DE"
                >IITJ-DE</vs-checkbox
              > -->
              <!-- <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="DA"
                >DA</vs-checkbox
              >
              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="RPA"
                >RPA</vs-checkbox
              >
              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="FOF(O)"
                >FOF(O)</vs-checkbox
              >
              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="WCBA"
                >WCBA</vs-checkbox
              >
              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="NO-BA"
                >NO-BA</vs-checkbox
              >
              <vs-checkbox
                color="dark"
                class="inline-flex"
                v-model="check1"
                vs-value="MAYBE"
                >MAYBE</vs-checkbox
              >-->
            </div>
          </div>
          <div class="vx-row mb-6" v-if="radios2 === 'net_enquiry'">
            <div class="vx-col sm:w-1/6">
              <span>Enquired course</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input disabled="true" v-model="ne_course" class="w-full" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
            </div>
            <div class="vx-col sm:w-1/3">
            </div>
            <div v-if="show_cfa_dropdown === true" class="vx-col sm:w-1/6">
              <span>Select CFA Course levels:</span>
            </div>
            <div v-if="show_cfa_dropdown === true" class="vx-col sm:w-1/3">
              <vs-select
                class="w-full"
                label="Select CFA level"
                style="width: 100%"
                v-model="selected_cfa_level"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in cfa_levels"
                />
              </vs-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
            </div>
            <div class="vx-col sm:w-1/3">
            </div>
            <div v-if="show_frm_dropdown === true" class="vx-col sm:w-1/6">
              <span>Select FRM Course parts:</span>
            </div>
            <div v-if="show_frm_dropdown === true" class="vx-col sm:w-1/3">
              <vs-select
                class="w-full"
                label="Select FRM part"
                style="width: 100%"
                v-model="selected_frm_part"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in frm_parts"
                />
              </vs-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6" v-if="getrole('cm1') || getrole('cm4') || checkIfRoleis('wh2')">
              <span>Level</span>
            </div>
            <div class="vx-col sm:w-1/3" v-if="getrole('cm1') || getrole('cm4') || checkIfRoleis('wh2')">
              <v-select
                
                class="select-large w-full"
                v-model="levelinput"
                :options="levels"
                :reduce="(level) => level.value"
                :disabled="selected_connection_status"
                clearable
                label="text"
              ></v-select>
            </div>
            <!-- <div class="vx-col sm:w-1/6">
              <span>Designation</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="designationinput" class="w-full" />
            </div> -->

            <!-- <div class="vx-col sm:w-1/6">
              <span>Company / University</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="companyinput" class="w-full" />
            </div> -->
            <div class="vx-col sm:w-1/6">
              <span>City</span>
            </div>
            <div class="vx-col sm:w-1/3" style="z-index: 1000">
              <v-select
                class="w-full"
                placeholder="Cities"
                v-model="cityinput"
                :options="city"
                label="text"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6 w-full">
              <span>USP Level</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <v-select v-model="selected_macc_level"
                style="border-radius: 5px; " :options="macc_levels"
                :reduce="(option) => option.id" :disabled="selected_connection_status">
              </v-select>
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Next Call date*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <datepicker
                placeholder="Select Date"
                :disabledDates="datepickerDisabled"
                v-model="dateinput"
              ></datepicker>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>College</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="college_name" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Designation</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="designation" class="w-full" />
            </div>
          </div>
          <!-- <div class="vx-row mb-6"> -->
            <!-- <div class="vx-col sm:w-1/6">
              <span>Education</span>
            </div> -->
            <!-- <div class="vx-col sm:w-1/3" style="z-index: 2000">
              <v-select
                multiple
                clearable
                class="w-full"
                placeholder="Education"
                v-model="educationinput"
                :options="educcationTags"
              >
                < <vs-select-item
                  :key="index"
                  :value="item.text"
                  :text="item.text"
                  v-for="(item,index) in options1"
                />
              </v-select>
              <vs-row class="gaps">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                  style="margin-top: 5px"
                >
                  <vs-chip
                    @click="remove(chip)"
                    closable
                    color="primary"
                    v-for="chip in educationinput"
                    :key="chip.index"
                  >
                    <b>{{ chip }}</b>
                  </vs-chip>
                </vs-col>
              </vs-row>
            </div> -->
          <!-- </div> -->

          <!-- <div class="vx-row mb-6"> -->
            <!-- <div class="vx-col sm:w-1/6">
              <span>Experience*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="experienceinput" class="w-full" />
            </div> -->
           
          <!-- </div> -->

          <div class="vx-row mb-6" v-if="getSubTeam('INT')">
            <div class="vx-col sm:w-1/6">
              <span>Country</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="country" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>International City</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="international_city" class="w-full" />
            </div>
          </div>

          <div class="vx-row mb-6" v-if="radios2 === 'net_enquiry'">
            <div class="vx-col sm:w-1/6">
              <span>Source*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <b>Net Enquiry</b>
            </div>
            <div class="vx-col sm:w-1/6"></div>
            <div class="vx-col sm:w-1/3"></div>
          </div>

          <div class="vx-row mb-6" v-if="radios2 === 'net_enquiry'">
            <div class="vx-col sm:w-1/6">
              <span>Details</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <b>{{ source_details }}</b>
            </div>

            <div class="vx-col sm:w-1/6">
              <span>CTC (In Lakhs ex: 3.5 or 5)</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-on:keypress="isNumber(event)" v-model="ctc" class="w-full" />
            </div>
            <!-- <div class="vx-col sm:w-1/6"></div>
            <div class="vx-col sm:w-1/3"></div> -->
          </div>

          <div class="vx-row mb-6" v-else>
            <div class="vx-col sm:w-1/6">
              <span>Source*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <!-- <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="net_enquiry"
              >Net Enquiry</vs-radio>-->
              <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="referral"
                >Referral</vs-radio
              >
              <!-- <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="corporate"
                >Corporate</vs-radio
              >
              <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="university"
                >University</vs-radio
              > -->
              <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="direct"
                >Incoming Calls</vs-radio
              >
              <vs-radio
                style="margin-right: 1%"
                v-model="radios2"
                class="inline-flex"
                vs-value="IVR"
                >IVR</vs-radio
              >
            </div>

            <div class="vx-col sm:w-1/6">
              <span>CTC (In Lakhs ex: 3.5 or 5)</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input type="text" v-on:keypress="isNumber(event)" v-model="ctc" class="w-full" />
            </div>
            
          </div>
          <div class="vx-row mb-6" v-show="toggle">
            <div class="vx-col sm:w-1/6">
              <span>Reference</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <!-- <vs-radio v-model="radios1" vs-value="ewb">EWB</vs-radio> -->
              <vs-radio style="margin-right: 1%" v-model="radios1" vs-value="mwb"
                >MWB</vs-radio
              >
              <vs-radio v-model="radios1" vs-value="others">Others</vs-radio>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="ewb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <v-select
                style="width: 300px"
                v-model="source_details"
                placeholder="EWB Names"
                :options="ewb_names"
                label="label"
              ></v-select>
              <!-- <v-select label="label" v-model="source_details" :options="ewb_names" :value="ewb_names.label" placeholder="EWB Name"></v-select> -->
            </div>
          </div>
          <div class="vx-row mb-6" v-show="mwb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Search With :</span>
            </div>
            <div class="vx-col sm:w-1/3">
             <div>
            <span><vs-radio v-model="searchType" vs-name="searchType" vs-value="Name">Name</vs-radio>&nbsp;&nbsp;</span>
            <span>&nbsp;&nbsp;<vs-radio v-model="searchType" vs-name="searchType" vs-value="Identity">Can-ID</vs-radio></span>
          </div>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="mwb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <v-select
                style="width: 400px"
                :filterable="false"
                :options="mwb_names"
                @search="autoCompleteName"
                v-model="source_details"
              />
            </div>
          </div>
          <div class="vx-row mb-6" v-show="others_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="source_details" class="w-full" />
            </div>
          </div>
          <div class="vx-row mb-6" v-show="socialMedia">
            <div class="vx-col sm:w-1/6 w-full">
              <span>Incoming Calls</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                style="width: 300px"
                v-model="source_details"
                placeholder="Social Media"
                :options="social_media"
                :reduce="(text) => text.text"
                label="text"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="corporateList">
            <div class="vx-col sm:w-1/6 w-full">
              <span>Corporate</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <!-- <v-select v-model="source_details" :options="corporates"></v-select> -->
              <v-select
                autocomplete
                style="width: 300px"
                v-model="source_details"
                placeholder="Corporates"
                :options="corporates"
                :reduce="(label) => label.label"
                label="label"
              ></v-select>
              <br />
            </div>
          </div>
          <div class="vx-row mb-6" v-show="universityList">
            <div class="vx-col sm:w-1/6 w-full">
              <span>University</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <!-- <v-select v-model="source_details" :options="universities"></v-select> -->
              <v-select
                autocomplete
                style="width: 300px"
                v-model="source_details"
                placeholder="University"
                :options="universities"
                :reduce="(label) => label.label"
                label="label"
              ></v-select>
              <br />
            </div>
          </div>
          <vs-divider>ENGAGEMENT DETAILS</vs-divider>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6 w-full">
              <span>Connection Status*</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                class="w-full"
                placeholder="Select a Connection Status"
                v-model="connection_status"
                :options="connection_status_list"
                :reduce="(connection) => connection.form"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-12">
            <div class="vx-col w-full">
              <span>Personna</span>
            </div>
            <div v-if="!connected_status.includes(connection_status)"  class="vx-col sm:w-1/2 w-full">
              <add-lead-hide-personna-details></add-lead-hide-personna-details>
            </div>
            <div v-if="connected_status.includes(connection_status)" class="vx-col sm:w-1/2 w-full">
              <add-lead-personna-details></add-lead-personna-details>
            </div>
            <div class="vx-col sm:w-1/6 w-full">
              <span>Engagement*</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <vs-textarea v-model="engagementinput" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <!-- <div class="vx-col sm:w-1/6 w-full">
              <span>Engagement*</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <vs-textarea v-model="engagementinput" />
            </div> -->
            <div class="vx-col sm:w-1/6"></div>
            <div class="vx-col sm:w-1/3"></div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button
                class="mr-3 mb-2 float-right"
                color="danger"
                type="gradient"
                @click="B2CAPI"
                >Submit</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <spamDialog></spamDialog>
    <!-- <MapthisEnquiryPopup></MapthisEnquiryPopup> -->
  </vs-popup>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import EventBus from "../eventbus.js";
import axios from "axios";
import moment from "moment";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import spamDialog from "./RemoveFromSpamDialog.vue";
// import MapthisEnquiryPopup from "./MapthisEnquiryPopup.vue";
import "vue-select/dist/vue-select.css";
import AddLeadHidePersonnaDetails from './AddLeadHidePersonnaDetails.vue';
import AddLeadPersonnaDetails from './AddLeadPersonnaDetails.vue'
export default {
  components: {
    "v-select": vSelect,
    Datepicker,
    spamDialog,
    AddLeadHidePersonnaDetails,
    AddLeadPersonnaDetails
    // MapthisEnquiryPopup,
  },
  watch: {
    levelinput(val){
      if(this.connection_status == 'CD' && (val == "M1" || val == "M2")){
        this.$vs.notify({
          title: "Error",
          text: "Sorry you can't downgrade the level",
          color: "danger",
        });
        this.levelinput = "M3"
      }
    },
    // selected_macc_level(val){
    //   if(this.connection_status == 'CD' && (val == "U0" || val == "U1" || val == "U1+" || val == "U2" || val == "U3-")){
    //     this.$vs.notify({
    //       title: "Error",
    //       text: "Sorry you can't downgrade the level",
    //       color: "danger",
    //     });
    //     this.selected_macc_level = "U3"
    //   }
    // },
    connection_status(val){
      this.setNextCallDate();
      if(val == 'CMB' || val == 'NE'){
        this.levelinput = "M1";
        this.selected_macc_level = 'U0';
        this.selected_connection_status = true;
      }
      else if(val == 'CD'){
        this.levelinput = "M3";
        this.selected_macc_level = 'U3';
        this.selected_connection_status = false;
      }else if(val == 'CB' || val == 'B' || val == 'NR' || val == 'NC' || val == 'D' || val == 'SO'){
        this.levelinput = "M3";
        this.selected_macc_level = 'U3';
        this.selected_connection_status = true;
      }else if(val == 'CN' || val == 'CNI' || val == 'IN' || val == 'CW'){
        this.levelinput = "M1";
        this.selected_macc_level = 'U0';
        this.selected_connection_status = true;
      }
      console.log("connected_status",this.connected_status.includes(this.connection_status), val);
      
    },
    check1(val){
      console.log(val);
      if(val.includes('CFA')){
        this.show_cfa_dropdown = true;
      } else {
        this.show_cfa_dropdown = false;
        this.selected_cfa_level = "";
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          for (let i = 0; i < this.cfa_levels.length; i++) {
            const level = this.cfa_levels[i].value;
            if(level === element){
              val.splice(val.indexOf(element), 1);
            }
          }
        }
      }
      if(val.includes('FRM')){
        this.show_frm_dropdown = true;
      } else {
        this.show_frm_dropdown = false;
        this.selected_frm_part = "";
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          for (let i = 0; i < this.frm_parts.length; i++) {
            const level = this.frm_parts[i].value;
            if(level === element){
              val.splice(val.indexOf(element), 1);
            }
          }
        }
      }
    },
    selected_cfa_level(val){
      for (let i = 0; i < this.cfa_levels.length; i++) {
        const element = this.cfa_levels[i].value;
        if(this.check1.indexOf(element) != -1 ){
          this.check1.splice(this.check1.indexOf(element), 1);
        }
      }
      if(this.check1.indexOf(val) == -1 && this.selected_cfa_level != ""){
        this.check1.push(val);
      }
    },
    selected_frm_part(val){
      for (let i = 0; i < this.frm_parts.length; i++) {
        const element = this.frm_parts[i].value;
        if(this.check1.indexOf(element) != -1 ){
          this.check1.splice(this.check1.indexOf(element), 1);
        }
      }
      if(this.check1.indexOf(val) == -1 && this.selected_frm_part != ""){
        this.check1.push(val);
      }
    },
    searchType(val){
      if(val === ""){
      this.mwb_names = []
      this.source_details = ""
      this.net_enquiry_type = ""
      this.ne_course = ""
      }
    },
    cityinput(val){
      this.cityinput = val;
    },
    open: function (value) {
      if (value === false) {
        this.showoptional = true;
        EventBus.$emit("reloadEntireData");
        // if (this.$route.path === "/queue") {
        //   EventBus.$emit("update-queue");
        // }
      }
    },
    radios2: function (value) {
      switch (value) {
        case "direct":
          this.socialMedia = false;
          this.toggle = false;
          this.ewb_reference = false;
          this.corporateList = false;
          this.mwb_reference = false;
          this.others_reference = false;
          this.sourceinput = "Incoming Calls";
          this.source_details = "Direct";
          this.universityList = false;
          this.setNextCallDate();
          break;
        case "referral":
          this.toggle = true;
          this.socialMedia = false;
          this.sourceinput = "Referral";
          this.source_details = "";
          this.universityList = false;
          this.ewb_reference = false;
          this.corporateList = false;
          this.mwb_reference = false;
          this.others_reference = false;
          this.setNextCallDate();
          break;
        case "net_enquiry":
          // if (this.$route.path !== "/netEnquiryTable") {
          //   this.source_details = "Net Enquiry";
          // }
          this.sourceinput = "Net Enquiry";
          this.toggle = false;
          this.socialMedia = false;
          this.corporateList = false;
          this.ewb_reference = false;
          this.mwb_reference = false;
          this.others_reference = false;
          this.universityList = false;
          this.setNextCallDate();
          break;
        // case "corporate":
        //   this.socialMedia = false;
        //   this.toggle = false;
        //   this.ewb_reference = false;
        //   this.mwb_reference = false;
        //   this.others_reference = false;
        //   this.sourceinput = "Corporate";
        //   this.source_details = "";
        //   this.universityList = false;
        //   this.corporateList = true;
        //   this.setNextCallDate();
        //   break;
        // case "university":
        //   this.socialMedia = false;
        //   this.toggle = false;
        //   this.ewb_reference = false;
        //   this.mwb_reference = false;
        //   this.others_reference = false;
        //   this.sourceinput = "University";
        //   this.source_details = "";
        //   this.universityList = true;
        //   this.corporateList = false;
        //   this.setNextCallDate();
        //   break;
        case "IVR":
          this.socialMedia = false;
          this.toggle = false;
          this.ewb_reference = false;
          this.corporateList = false;
          this.mwb_reference = false;
          this.others_reference = false;
          this.sourceinput = "IVR";
          this.source_details = "IVR";
          this.universityList = false;
          this.setNextCallDate();
          break;
      }
    },
    radios1: function (value) {
      switch (value) {
        case "ewb":
          this.ewb_reference = true;
          this.mwb_reference = false;
          this.source_details = "";
          this.others_reference = false;
          break;
        case "mwb":
          this.source_details = "";
          this.ewb_reference = false;
          this.mwb_reference = true;
          this.others_reference = false;
          break;
        case "others":
          this.source_details = "";
          this.ewb_reference = false;
          this.mwb_reference = false;
          this.others_reference = true;
          break;
      }
    },
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.getEducationTags();

    EventBus.$on("open-add-lead-popup", (bool) => {
      if (bool == undefined) {
        this.visit_status = false
      //   this.connection_status_list.unshift({name: "Connected, Discussed",value: 0,form: "CD"},)
      //   this.connection_status_list = [...new Map(this.connection_status_list.map(item => [item['name'], item])).values()]
      }
      this.selected_connection_status = false;
      this.new_persona_data = {};
      this.nameinput = "";
      this.designation = "";
      this.college_name = "";
      this.ctc = "";
      this.levelinput = "";
      this.selected_macc_level = "";
      this.coursesinput = "";
      // this.companyinput = "";
      // this.designationinput = "";
      this.connection_status = "";
      // this.experienceinput = "";
      this.country = "";
      this.international_city = "";
      this.source_details = "";
      // this.educationinput = [];
      this.engagementinput = "";
      this.dateinput = "";
      this.cityinput = "";
      this.eligibilityinput = "";
      this.sourceinput = "";
      this.emailinput = "";
      this.mobileinput = "";
      this.corporateList = false;
      this.universityList = false;
      this.toggle = false;
      this.socialMedia = false;
      this.ewb_reference = false;
      this.mwb_reference = false;
      this.others_reference = false;
      this.radios2 = "";
      this.titlebatch = "";
      this.radios1 = "";
      this.check1 = [];
      this.open = true;
      this.untrackedcall = false;
      // console.log(this.ConstantUsers)
      // console.log("add lead called too?");

      this.getDefaultCity();

      this.setNextCallDate();
    });
    EventBus.$on("pre-filled-b2c", (payload) => {
      this.selected_connection_status = false;
      this.nameinput = "";
      this.designation = "";
      this.college_name = "";
      this.ctc = "";
      this.levelinput = "";
      this.coursesinput = "";
      // this.companyinput = "";
      // this.designationinput = "";
      // this.experienceinput = "";
      this.source_details = "";
      // this.educationinput = [];
      this.engagementinput = "";
      this.net_enquiry_type = "";
      this.ne_course = "";
      this.dateinput = "";
      this.cityinput = "";
      this.eligibilityinput = "";
      this.sourceinput = "";
      this.emailinput = "";
      this.mobileinput = "";
      this.corporateList = false;
      this.universityList = false;
      this.toggle = false;
      this.socialMedia = false;
      this.ewb_reference = false;
      this.mwb_reference = false;
      this.others_reference = false;
      this.radios2 = "";
      this.titlebatch = "";
      this.radios1 = "";
      this.check1 = [];
      this.mobileinput = payload.mobile_number;
      this.untrackedcall = true;

      this.getDefaultCity();

      this.setNextCallDate();
      // this.url = payload.url;
      // this.post_API_obj = payload.apiobj;
    });
    EventBus.$on("prefilled-netEnquiry", (payload) => {
      this.selected_connection_status = false;
      // if(payload.visit_scheduled){
      //   this.connection_status_list.shift({name: "Connected, Discussed",value: 0,form: "CD"},)
      // }else{
      //   this.connection_status_list.unshift({name: "Connected, Discussed",value: 0,form: "CD"},)
      //   this.connection_status_list = [...new Map(this.connection_status_list.map(item => [item['name'], item])).values()]
      // }
      this.visit_status = payload.visit_scheduled
      this.scheduled_city = payload.scheduled_city
      this.scheduled_date = payload.scheduled_date
      console.log("payload", payload)
      this.levelinput = "";
      this.showoptional = false;
      console.log("net enquiry query", payload);
      if (Array.isArray(payload.mobile) && payload.mobile.length > 0) {
        this.mobileinput = payload.mobile[0];
      } else {
        this.mobileinput = payload.mobile;
      }

      if (Array.isArray(payload.email) && payload.email.length > 0) {
        this.emailinput = payload.email[0];
      } else {
        this.emailinput = payload.email;
      }

      // this.emailinput = payload.email;
      this.nameinput = payload.name;
      // if (payload.net_enquiry_type === "chat" || payload.net_enquiry_type === "form") {
      //   this.source_details = payload.id;
      // } else {
      //   this.source_details = payload.net_enquiry_type;
      // }
      this.source_details = payload.source;
      this.net_enquiry_type = payload.net_enquiry_type;
      this.ne_course = payload.course;
      this.check1.push(this.ne_course);
      this.radios2 = "net_enquiry";
      this.sourceinput = "Net Enquiry";
      if(this.check1.includes('USP')){
        this.levelinput = "M3";
        this.selected_macc_level = 'U3';
      }
      this.setNextCallDate();
    });
    EventBus.$on("close-addLead-popup", () => {
      this.open = false;
    });
    
    // document.getElementById("addLeadForm").children[1].style.width = "100%";
    // document.getElementById("addLeadForm").children[1].style.height = "83%";
    //  this.getCandidatePersonaDetails();
    EventBus.$on('changed-add-lead-macc-level', (macc_level) => {
      this.selected_macc_level = macc_level;
    })

    EventBus.$on('check-add-lead-persona-data', (new_data) => {
      this.checkPersonaData(new_data);
    })
  },
  data() {
    return {
      visit_status:false,
      scheduled_city:'',
      scheduled_date:null,
      selected_connection_status: false,
      login_spoc_id: '',
      new_persona_data: {},
      selected_macc_level: "",
      macc_levels: [
        // { value: "",  text: ""},
        { id: "U0--", label: "U0-- : Not Eligible Lead"},
        { id: "U0", label: "U0 : Not interested in US/Canda"},
        { id: "U1", label: "U1 : Interest expressed, dropped out"},
        { id: "U1+", label: "U1+ : Interest expressed, yet to graduate"},
        { id: "U2", label: "U2 : Interest expressed, in pipeline (warm)"},
        { id: "U3-", label: "U3- : Postponed"},
        { id: "U3", label: "U3 : Interest expressed, in pipeline (hot)"},
        { id: "U3+", label: "U3+ : Zoom Attended(Very Hot)"},
        { id: "U3++", label: "U3++ : Ready to Enroll"},
        { id: "U4", label: "U4 : Visited but Postpone/Not Interested"},
        { id: "U5", label: "U5 : Visited and Very Interested"},
        { id: "U6", label: "U6 : Visited and Ready to Enroll"},
        { id: "U7-", label: "U7- : Application initiated, dropped out"},
        { id: "U7R", label: "U7R : Refunded 20k"},
        { id: "U7", label: "U7 : Enrolled (20k)"},
        { id: "U7+", label: "U7+ : Application Initiated"},
        { id: "U8", label: "U8 : Application done, I-20 yet to be received"},
        { id: "U8+", label: "U8+ : Conditional Offer Received"},
        { id: "U9-", label: "U9- : I-20 received, Dropped Out"},
        { id: "U9", label: "U9 : I 20 Rec. Yet to apply for Visa"},
        { id: "U9+", label: "U9+ : I 20 Rec. Applied for Visa"},
        { id: "U10-", label: "U10- : Visa Received But Dropped out"},
        { id: "U10", label: "U10 : Visa received"},
        { id: "U11", label: "U11 : IIM Indore"},
        { id: "U11+", label: "U11+ : Started program in USA"},
        { id: "U12", label: "U12 : Completed program in USA"},
      ],
      connected_status: ["CD", "CNI", "VCNF", "VCMP", "VRE"],
      selected_cfa_level: "",
      selected_frm_part: "",
      cfa_levels: [
        {text:"CFA-L1", value:"CFA-L1"},
        {text:"CFA-L2", value:"CFA-L2"},
        {text:"CFA-L3", value:"CFA-L3"},
      ],
      frm_parts: [
       {text:"FRM-P1", value:"FRM-P1"},
       {text:"FRM-P2", value:"FRM-P2"},
      ],
      show_cfa_dropdown: false,
      show_frm_dropdown: false,
      searchType:'Name',
      datepickerDisabled: {},
      connection_status: "",
      connection_status_list: [
      {name: "Connected, Discussed",value: 0,form: "CD"},
      {
            name: "Visit Confirmed",
            value: 0,
            form: "VCNF",
          },
          {
            name: "Visit Completed",
            value: 0,
            form: "VCMP",
          },
          {
            name: "Visit Rescheduled",
            value: 0,
            form: "VRE",
          },
          {
            name: "Visit Cancelled",
            value: 0,
            form: "VCNL",
          },
          {
            name: "Booked Visit By Mistake",
            value: 0,
            form: "BVBM",
          },
          {
            name: "Communication barrier",
            value: 0,
            form: "CMB",
          },
          {
            name: "Not Educated",
            value: 0,
            form: "NE",
          },
          {
            name: "Not Connected(NL/SO/NR)",
            value: 0,
            form: "NC",
          },
          {
            name: "Connected, Not Interested",
            value: 0,
            form: "CNI",
          },
          {
            name: "Invalid Number, Wrong number",
            value: 0,
            form: "INWN",
          },
          {
            name: "Connected/ Call Back",
            value: 0,
            form: "CCB",
          },
      ],
      // educcationTags: [
      //   "PUC",
      //   "INTER",
      //   "B.Com",
      //   "B.Com(P)",
      //   "M.Com",
      //   "M.Com(P)",
      //   "MBA",
      //   "MBA(P)",
      //   "CA",
      //   "CWA",
      //   "CS",
      //   "BAF",
      //   "BMS",
      //   "BMS(P)",
      //   "BBA",
      //   "BBA(P)",
      //   "PGD",
      //   "PGD(P)",
      //   "LLB",
      //   "LLB(P)",
      //   "B.Tech",
      //   "B.Tech(P)",
      //   "B.Sc",
      //   "B.Sc(P)",
      //   "BA",
      //   "BA(P)",
      //   "CA-Inter",
      //   "CWA-Inter",
      //   "CS-Inter",
      //   "PG(P)",
      //   "CA Final",
      //   "CWA Final",
      //   "CS Final",
      //   "IGNOU - B.Com (CA)",
      //   "IGNOU - B.Com (CWA)",
      //   "IGNOU - B.Com (CS)",
      //   "MCA",
      //   "PGDPA",
      //   "PGDSF",
      //   "PGDM",
      //   "BBM",
      //   "SCESA",
      //   "MA",
      //   "CFA",
      //   "FRM",
      //   "CFA(P)",
      //   "MFA",
      //   "MFA(P)",
      //   "CMA(US)",
      //   "CPA(US)",
      // ],
      educcationTags: [],
      international_city: "",
      country: "",
      options1: [
        { text: "MA", value: 0 },
        { text: "B.Com", value: 0 },
        { text: "B.Com(P)", value: 18 },
        { text: "M.Com", value: 1 },
        { text: "M.Com(P)", value: 19 },
        { text: "MBA", value: 2 },
        { text: "MBA(P)", value: 20 },
        { text: "CA", value: 3 },
        { text: "CWA", value: 4 },
        { text: "CS", value: 5 },
        { text: "BMS", value: 6 },
        { text: "BMS(P)", value: 21 },
        { text: "BBA", value: 7 },
        { text: "BAF", value: 7 },
        { text: "BBA(P)", value: 17 },
        { text: "PGD", value: 8 },
        { text: "PGD(P)", value: 22 },
        { text: "LLB", value: 9 },
        { text: "LLB(P)", value: 23 },
        { text: "B.Tech", value: 10 },
        { text: "B.Tech(P)", value: 24 },
        { text: "B.Sc", value: 11 },
        { text: "B.Sc(P)", value: 25 },
        { text: "BA", value: 12 },
        { text: "BA(P)", value: 26 },
        { text: "CA-Inter", value: 13 },
        { text: "CWA-Inter", value: 14 },
        { text: "CS-Inter", value: 15 },
        { text: "PG(P)", value: 16 },
        { text: "CA Final", value: 16 },
        { text: "CWA Final", value: 16 },
        { text: "CS Final", value: 16 },
        // { text: "IGNOU", value: 16 },
        { text: "IGNOU - B.Com (CA)", value: 16 },
        { text: "IGNOU - B.Com (CWA)", value: 16 },
        { text: "IGNOU - B.Com (CS)", value: 16 },
        { text: "MCA", value: 16 },
        { text: "PGDPA", value: 16 },
        { text: "PGDSF", value: 16 },
        { text: "PGDM", value: 16 },
        { text: "BBM", value: 16 },
        { text: "SCESA", value: 16 },
      ],
      nameAutocomplete: [],
      corporateList: false,
      universityList: false,
      popupheight: "53%",
      toggle: false,
      socialMedia: false,
      ewb_reference: false,
      mwb_reference: false,
      others_reference: false,
      radios2: "",
      titlebatch: "",
      radios1: "",
      open: false,
      check1: [],
      showoptional: true,
      check2: "",
      check3: "",
      universities: [
        { text: "", value: "" },
        { label: "Jain University", value: 0 },
        { label: "St Joseph's College Of Commerce", value: 1 },
        { label: "Dayananda Sagar University", value: 2 },
        { label: "Christ University", value: 3 },
        { label: "Kristu Jayanti College", value: 4 },
        { label: "Garden City University", value: 5 },
        { label: "Cambridge Group of Institutions", value: 6 },
        { label: "Mount Carmel College", value: 7 },
        { label: "St Joseph's College Of Commerce (Lalbagh)", value: 8 },
        { label: "ISBR College", value: 9 },
        { label: "Guukul Institute of Management", value: 10 },
        { label: "Presidency University", value: 11 },
        { label: "Jyoti Nivas", value: 12 },
        { label: "Jagran Lakecity University", value: 13 },
        { label: "Bhopal School of Social Studies", value: 14 },
        { label: "Chitkara University", value: 15 },
        { label: "Loyola College", value: 16 },
        { label: "Dwaraka Doss Goverdhan Doss Vaishnav College", value: 17 },
        { label: "SRM Institute Of Science And Technology", value: 18 },
        { label: "SRM ITD", value: 19 },
        {
          label: "B. S. Abdur Rahman Crescent Institute Of Science And Technology",
          value: 20,
        },
        { label: "Ethiraj College", value: 21 },
        { label: "Graphic Era University", value: 22 },
        { label: "Manava Rachana University", value: 23 },
        { label: "KL University", value: 24 },
        { label: "Amity University", value: 25 },
        { label: "Loyola Academy Degree And Pg College", value: 26 },
        { label: "Government City College", value: 27 },
        { label: "St.Ann’s College For Women", value: 28 },
        { label: "Hindi Mahavidyala", value: 29 },
        { label: "Daly College Business School", value: 30 },
        { label: "IIS University", value: 31 },
        { label: "Kanoria PG Mahila Mahavidyalaya", value: 32 },
        { label: "Kaziranga University", value: 33 },
        { label: "Adamas University", value: 34 },
        { label: "CMS", value: 35 },
        { label: "Amity University", value: 36 },
        { label: "Lovely Professional University", value: 37 },
        { label: "BMCC", value: 38 },
        { label: "ASM Group Of Institutes", value: 39 },
        { label: "Ness Wadia College of Commerce", value: 40 },
        {
          label: "Shikshan Prasarak Mandal’s Sir Parashurambhau College",
          value: 41,
        },
        {
          label: "Dr. D. Y. Patil Institute of Management & Research",
          value: 42,
        },
        {
          label: "The Poona Gujarati Kelvani Mandal's Haribhai V. Desai College",
          value: 43,
        },
        {
          label: "Pratibha College of Commerce And Computer Studies",
          value: 44,
        },
        { label: "Indira College of Commerce and Science", value: 45 },
        { label: "MMCC college", value: 46 },
        { label: "MATS University", value: 47 },
        { label: "Auro University", value: 48 },
        {
          label: "Sahrdaya College Of Advance Studies For Arts And Science",
          value: 49,
        },
        { label: "Naipunnya Institute Of Management", value: 50 },
        { label: "Graphic Era Hill University", value: 51 },
        {
          label: "Kejriwal Institute of Management & Development Studies",
          value: 52,
        },
        { label: "Doon Business School", value: 53 },
        { label: "BFIT", value: 54 },
        { label: "Uttaranchal University", value: 55 },
        { label: "RIMT", value: 56 },
        { label: "St Alberts College", value: 57 },
      ],
      corporates: [
        { text: "", value: "" },
        { label: "Amazon", value: 0 },
        { label: "Accenture", value: 1 },
        { label: "AIG", value: 2 },
        { label: "American Express", value: 3 },
        { label: "Aon Hewitt", value: 4 },
        { label: "BMC Software", value: 5 },
        { label: "Deloitte & Touche (DTT-US) - AUDIT", value: 6 },
        { label: "EY Assurance", value: 7 },
        { label: "Flex", value: 8 },
        { label: "JP Morgan", value: 9 },
        { label: "KPMG GDC", value: 10 },
        { label: "VMware", value: 11 },
        { label: "Genpact", value: 12 },
        { label: "MacMillan", value: 13 },
        { label: "DXC Technology", value: 60 },
        { label: "Maersk Global Services", value: 14 },
        { label: "Metlife US", value: 15 },
        { label: "Prime Healthcare", value: 16 },
        { label: "World Bank", value: 17 },
        { label: "Advantage One Tax", value: 18 },
        { label: "EA Sports", value: 19 },
        { label: "Philips", value: 20 },
        { label: "Tata Power", value: 21 },
        { label: "Alexion Pharmaceuticals Inc", value: 22 },
        { label: "Axa Business Services", value: 23 },
        { label: "Barclays", value: 23 },
        { label: "Capgemini", value: 25 },
        { label: "Citrin Cooperman", value: 26 },
        { label: "Diageo", value: 27 },
        { label: "EY Tax", value: 28 },
        { label: "FIS", value: 29 },
        { label: "Ford Motors", value: 30 },
        { label: "Grant Thornton (US TAX)", value: 31 },
        { label: "Infoblox", value: 32 },
        { label: "Invesco", value: 33 },
        { label: "Johnson & Johnson", value: 34 },
        {
          label: "Max Life Insurance (Previously, New York life Insurance )",
          value: 35,
        },
        { label: "PWC (SDC-US)", value: 36 },
        { label: "Qualcomm", value: 37 },
        { label: "Redhat", value: 38 },
        { label: "Sendan KSA", value: 39 },
        { label: "GE Capital / Synchrony", value: 40 },
        { label: "United Health Group (UHG)", value: 41 },
        { label: "WNS Global Services (P) Ltd", value: 42 },
        { label: "TE Connectivity", value: 43 },
        { label: "BDO LLP", value: 44 },
        { label: "Cognizant", value: 45 },
        { label: "Continuum Managed Solutions Private Limited", value: 46 },
        { label: "DuPont", value: 47 },
        { label: "EXL Services", value: 48 },
        { label: "IHG", value: 49 },
        { label: "Allergan India", value: 50 },
        { label: "Blackbuck", value: 51 },
        { label: "Avaya", value: 52 },
        { label: "Juniper Networks", value: 53 },
        { label: "CGI", value: 54 },
        { label: "Taxevo Inc", value: 55 },
        { label: "AEON", value: 56 },
        { label: "Flowserve", value: 57 },
        { label: "Zenwork", value: 58 },
        { label: "Sintex", value: 59 },
        { label: "RENEW POWER", value: 60 },
        { label: "PAPERCHASE ACCOUNTANCY INDIA PVT. LTD", value: 61 },
        { label: "SOFTWARE AG", value: 62 },
        { label: "MANUBHAI & SHAH LLP", value: 63 },
        { label: "Unison Globus", value: 64 },
        { label: "Withum Smith+Brown", value: 65 },
        { label: "CROWE LLP", value: 66 },
        { label: "PKF O'Connor Davies, LLP", value: 67 },
        { label: "Entigrity", value: 68 },
      ],
      levels: [
        // { text: "M7 - Enrolled", value: "M7" },
        // { text: "M6 - Visited & Ready to Enroll", value: "M6" },
        // { text: "M5 - Visited & Positive", value: "M5" },
        // { text: "M4 - Visited but Postponed", value: "M4" },
        // { text: "M4- - Visited but not interested", value: "M4-" },
        { text: "M3++ - Ready to enroll - Not visited", value: "M3++" },
        { text: "M3+ - Called & Coming", value: "M3+" },
        { text: "M3 - Called & Positive", value: "M3" },
        { text: "M2 - Did not Visit & Postponed", value: "M2" },
        { text: "M1 - Did not Visit & not intersted", value: "M1" },
      ],
      IIMLlevels: [
        { text: "Cold", value: "Cold" },
        { text: "Hot", value: "Hot" },
        { text: "Warm", value: "Warm" },
        { text: "Very Hot", value: "Very Hot" },
        { text: "Application Received", value: "Application Received" },
        { text: "Exam-Pass", value: "Exam-Pass" },
        { text: "Exam-Fail", value: "Exam-Fail" },
        { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
        { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
        { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },
        { text: "Net Enquiry", value: "Net Enquiry" },
        { text: "Busy/NL", value: "Busy/NL" },
        { text: "Application Approved", value: "Application Approved" },
        { text: "Enrolled", value: "Enrolled" },
        { text: "Final Payment Received", value: "Final payment Received" },
        { text: "NA", value: "NA" },
        // { text: "Enquiry", value: "Enquiry" },
        // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
        // { text: "RPO-M7", value: "RPO-M7" },
        // { text: "IIML-SF-M7", value: "IIML-SF-M7" },
        // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
        // { text: "IIML-BA-M7", value: "IIML-BA-M7" },
        // { text: "IIML-PM-M7", value: "IIML-PM-M7" },
        // { text: "IIML-HR-M7", value: "IIML-HR-M7" },
        // { text: "IITR-BF-M7", value: "IITR-BF-M7" },
        // { text: "IITR-DB-M7", value: "IITR-DB-M7" },
        // { text: "IITM-AA-M7", value: "IITM-AA-M7" },
        // { text: "IIMK-CX-M7", value: "IIMK-CX-M7" },
        // { text: "IITM-FS-M7", value: "IITM-FS-M7" },
        // { text: "IITR-CC-M7", value: "IITR-CC-M7" },
        // { text: "IIMK-FT-M7", value: "IIMK-FT-M7" },
        // { text: "IIML-AB-M7", value: "IIML-AB-M7" },
        // { text: "IIML-SH-M7", value: "IIML-SH-M7" },
        // { text: "IITJ-DE-M7", value: "IITJ-DE-M7" },
        // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
        // { text: "IIMI-BA-M7", value: "IIMI-BA-M7" },
        // { text: "XLRI-DM-M7", value: "XLRI-DM-M7" },
        // { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
      ],
      city: [
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Ernakulam",
        "Hyderabad",
        "Kolkata",
        "Mumbai",
        "Pune",
        "International",
        "Global"
        // { text: "", value: "" },
        // { text: "Hyderabad", value: 1 },
        // { text: "Delhi", value: 2 },
        // { text: "Mumbai", value: 3 },
        // { text: "Chennai", value: 4 },
        // { text: "Bangalore", value: 5 },
        // { text: "Ernakulam", value: 6 },
        // { text: "Ahmadabad", value: 7 },
        // { text: "Jaipur", value: 8 },
        // { text: "Pune", value: 9 },
        // { text: "Kolkata", value: 10 }
      ],
      social_media: [
        { text: "Google Ads", value: 1 },
        { text: "Twitter", value: 2 },
        { text: "Whatsapp", value: 3 },
        { text: "Youtube", value: 4 },
        { text: "SMS", value: 5 },
        { text: "Facebook", value: 6 },
        { text: "Linkedin", value: 7 },
        // { text: "Whatsapp", value: 8 },
        { text: "Website", value: 9 },
        { text: "Hoarding", value: 10 },
        { text: "Word of mouth", value: 11 },
        { text: "Corporates", value: 12 },
        { text: "University", value: 13 },
        { text: "CA Activity", value: 14 },
        { text:"Wiley",value: 15},
        { text:"LeadSquared", value: 16},
        { text:"Incoming Call", value: 17}
      ],
      ewb_names: [],
      mwb_names: [],
      nameinput: "",
      designation : "",
      college_name : "",
      ctc : "",
      levelinput: "M3",
      coursesinput: "",
      // companyinput: "",
      // designationinput: "",
      // experienceinput: "",
      source_details: "",
      net_enquiry_type: "",
      ne_course: "",
      // educationinput: [],
      engagementinput: "",
      dateinput: "",
      cityinput: "",
      eligibilityinput: "",
      sourceinput: "",
      emailinput: "",
      mobileinput: "",
      url: "",
      post_API_obj: "",
      untrackedcall: false,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        this.$vs.notify({
          text: "Please enter numbers",
          color: "danger",
        });
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getPopupTitle(){
      if (this.visit_status) {
        let formttedDate = moment.unix(this.scheduled_date).format("DD MMM YYYY hh:mm A");
        return `ADD A LEAD - Visit Scheduled : ${formttedDate} City: ${this.scheduled_city}`
      } else {
        return 'ADD A LEAD'
      }
    },
    checkPersonaData(new_data) {
      this.new_persona_data = new_data;
      console.log("checkPersonaData",this.new_persona_data);
    },
    getEducationTags() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)

          if(response.data.length > 0){
            // for (let i = 0; i < response.data.length; i++) {
            //   this.educcationTags.push({
            //     id : response.data[i],
            //     label : response.data[i]
            //   })

            // }
          this.educcationTags = response.data


          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    setNextCallDate() {
      let today = new Date();
      let from = new Date();
      
      if((this.connection_status != 'CD' && this.connection_status != 'CNI') && this.radios2  == 'net_enquiry'){
        from.setDate(today.getDate() + 3);
        today.setDate(today.getDate() - 1);
      } else {
        today.setDate(today.getDate() - 1);
        from.setDate(today.getDate() + 8);
      }
      this.datepickerDisabled = {
        to: today,
        from: from,
      };

      let nextCallDate = new Date();
      if((this.connection_status != 'CD' && this.connection_status != 'CNI') && this.radios2  == 'net_enquiry'){
        nextCallDate.setDate(nextCallDate.getDate() + 3);
      } else {
       nextCallDate.setDate(nextCallDate.getDate() + 7);
      }
      this.dateinput = nextCallDate;
    },

    getDefaultCity() {
      let  url = `${constants.SERVER_API}giveMeMyId`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("city is ",response);
          console.log(this.city);
          if(this.city.includes(response.data.city))
          {
            this.cityinput = response.data.city;
          }
          else{
            this.cityinput = "Bangalore";
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // getSpoc() {
    //   // console.log("from add lead");
    //   // let url = `${constants.SERVER_API}getSpocs`;
    //   let url = ``;

    //   if (
    //     localStorage.getItem("role") === "cm1" ||
    //     localStorage.getItem("role") === "ac1"
    //   ) {
    //     url = `${constants.SERVER_API}getSpocs`;
    //   } else if (
    //     localStorage.getItem("role") === "exed" ||
    //     localStorage.getItem("role") === "exed2"
    //   ) {
    //     url = `${constants.SERVER_API}getExed`;
    //   }
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       // this.generateCityList(response);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    // generateCityList(response) {
    //   var unique = response.data.spocs
    //     .map((spoc) => {
    //       return {
    //         count: 1,
    //         name: spoc.city,
    //       };
    //     })
    //     .reduce((a, b) => {
    //       a[b.name] = (a[b.name] || 0) + b.count;
    //       return a;
    //     }, {});
    //   this.city = [];
    //   for (var key in unique) {
    //     if (unique.hasOwnProperty(key)) {
    //       let itemObj = {
    //         text: key,
    //         value: unique[key],
    //       };
    //       this.city.push(itemObj);
    //     }
    //   }
    //   if (this.getSubTeam("INT")) {
    //     let extraOptions = ["International", "Global"];

    //     extraOptions.forEach((option) => {
    //       let itemObj = {
    //         text: option,
    //         value: option,
    //       };
    //       this.city.push(itemObj);
    //     });
    //   }
    //   // this.selectSpocCity(response);
    // },
    // selectSpocCity(response) {
    //   // console.log(response);
    //     response.data.spocs.forEach((spoc) => {
    //       if (spoc.id === response.data.logged_in_user_id && spoc.city !== "") {
    //         this.cityinput = spoc.city
    //       } else if (spoc.id === response.data.logged_in_user_id && spoc.city === "") {
    //         this.cityinput = "Mumbai";
    //       }
    //     });
    // },
    // remove(item) {
    //   this.educationinput.splice(this.educationinput.indexOf(item), 1);
    // },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      // console.log('what are we searching for', search)
      loading(true);
      let searchtype = this.searchType;
      this.fetchMwbs(loading, search,searchtype, this);
    },
    fetchMwbs: _.debounce((loading, search,searchtype, vm) => {
      let url = `${constants.SERVER_API}searchMwbNameForReferenceWEB?name=${escape(
				search
			)}&type=${searchtype}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          vm.mwb_names = response.data.map(
            (lead) => `${lead.identity} - ${lead.person_name} - ${lead.level}`
          );
          loading(false);
        });
    }, 350),
    getNext_call() {
      let value = new Date(this.dateinput).getTime() / 1000;
      if (isNaN(value)) {
        return "";
      } else {
        return value;
      }
    },
    storePersona(mwb_id) {
      console.log("new_persona_data", this.new_persona_data)
      if(mwb_id == '' || mwb_id == undefined || mwb_id == null){
        this.$vs.notify({
          title: "Error",
          text: "Something went wrong",
          color: "danger",
        });
        this.open = true;
        return;
      }
      this.new_persona_data.mwb_id = mwb_id;
      let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
      axios
        .post(url, this.new_persona_data, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Personna Data", response.data);

          if (response.data.status == "success") {
            this.$vs.notify({
              title: "Success",
              text: "Persona Details Updated Successfully",
              color: "success",
            });
            this.open = false;
            // console.log("API AT EMIT",this.existing_persona_data ,obj)

          } else {
            this.$vs.notify({
              title: "Error",
              text: "Something went wrong",
              color: "danger",
            });
            this.open = true;

          }
        })
        .catch((error) => {
          this.open = true;
          this.handleError(error);
          this.$vs.loading.close();
        });

    },
    async B2CAPI() {
      // this.$vs.loading();
      let allowed_status_for_persona = ["CD", "CNI", "VCNF", "VCMP", "VRE"];
      let cfa_list = ["CFA-L1", "CFA-L2", "CFA-L3"];
      let frm_list = ["FRM-P1", "FRM-P2"];
      let cfa_array_length = _.intersection(cfa_list, this.check1);
      let frm_array_length = _.intersection(frm_list, this.check1);
      if (!this.validateName(this.nameinput) || !this.validateNumber(this.mobileinput)) {
        console.log("data")
        this.$vs.loading.close();
        return;
      }
      if (this.getSubTeam("INT") && this.city === "") {
        this.$vs.notify({
          title: "Fill all the required forms",
          text: "please fill all the required forms to continue adding a lead",
          color: "danger",
          position: "top-center",
        });
        this.$vs.loading.close();
        return;
      }

      console.log(this.dateinput);
      console.log(this.engagementinput);
      console.log(this.connection_status);

      if (
        this.nameinput === "" ||
        this.mobileinput === "" ||
        this.source === "" ||
        this.source_details === "" ||
        this.dateinput === "" ||
        this.engagementinput === "" ||
        this.connection_status === "" 
      ) {
        this.$vs.notify({
          title: "Fill all the required forms",
          text: "please fill all the required forms to continue adding a lead",
          color: "danger",
          position: "top-center",
        });
        this.$vs.loading.close();
      } else if(this.check1.includes('CFA') && cfa_array_length.length == 0){
         this.$vs.notify({
          title: "Please Select CFA level",
          color: "danger",
        });
        this.$vs.loading.close();
      } else if (this.check1.includes('FRM') && frm_array_length.length == 0){
        console.log("test FRM",this.check1, frm_array_length);
        this.$vs.notify({
          title: "Please Select FRM Part",
          color: "danger",
        });
        this.$vs.loading.close();
      } else if(this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.graduation_year == '' || this.new_persona_data.graduation_year == null)){
        this.$vs.notify({
            title: 'Graduation year cannot be empty.',
            color: "danger",
            time: 3000
        });
        this.$vs.loading.close();
      } 
      else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && (this.new_persona_data.location == null || this.new_persona_data.location == "") && allowed_status_for_persona.includes(this.connection_status)) {
        this.$vs.notify({
          text: "Location cannot be empty",
          color: "danger",
        });
        this.$vs.loading.close();
      }
      else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && this.new_persona_data.pathway_value != 'Yes' && (this.new_persona_data.placement_assistance == '' || this.new_persona_data.placement_assistance == null || this.new_persona_data.placement_assistance == undefined)) {
        this.$vs.notify({
          text: "Please check Placement Assistance",
          color: "danger",
        });
         this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.company == '' || this.new_persona_data.company == null)) {
        this.$vs.notify({
          title: 'In Personna Company name cannot be empty.',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.years_of_experience == 0 || this.new_persona_data.years_of_experience == '' )) {
        this.$vs.notify({
          title: 'In Personna Experience cannot be empty',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && isNaN(this.new_persona_data.years_of_experience)) {
        this.$vs.notify({
          title: 'Experience cannot be string.',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.global_professional_qualification == '' || this.new_persona_data.global_professional_qualification == null)) {
        this.$vs.notify({
          title: 'Global Professional Qualification cannot be empty.',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.indian_professional_qualification == '' || this.new_persona_data.indian_professional_qualification == null)) {
        this.$vs.notify({
          title: 'Indian Professional Qualification cannot be empty.',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else if (this.new_persona_data.pathway_value != '' && this.new_persona_data.pathway_value != null && this.new_persona_data.pathway_value != 'Yes' && allowed_status_for_persona.includes(this.connection_status) && (this.new_persona_data.ug_qualification == '' || this.new_persona_data.ug_qualification == null)) {
        this.$vs.notify({
          title: 'UG Qualification cannot be empty.',
          color: "danger",
          time: 4000
        });
        this.$vs.loading.close();
      } else {
        this.$vs.loading();
        switch (this.untrackedcall) {
          case true:
            // this.url = `${constants.SERVER_API}saveUntrackedToNewLead`;
            // this.post_API_obj = {
            //   name: this.nameinput,
            //   level: this.levelinput,
            //   courses: this.check1.join(),
            //   type: "B2C",
            //   company: this.companyinput,
            //   designation: this.designationinput,
            //   experience: this.experienceinput,
            //   education: this.educationinput.join(),
            //   city: this.cityinput.text,
            //   eligibility: this.eligibilityinput,
            //   source: this.sourceinput,
            //   source_details: this.source_details,
            //   email: this.emailinput,
            //   phone_number: this.mobileinput,
            //   details: this.engagementinput,
            //   acads: 0,
            //   next_call: this.getNext_call()
            // };
            this.url = `${constants.SERVER_API}addMwb`;
            this.post_API_obj = {
              name: this.nameinput,
              designation: this.designation,
              college: this.college_name,
              current_ctc: this.ctc,
              level: this.levelinput,
              iiml_level: this.selected_macc_level,
              courses: this.check1.join(),
              type: "B2C",
              // company: this.companyinput,
              country: this.country,
              international_city: this.international_city,
              // designation: this.designationinput,
              // experience_int: this.experienceinput,
              // education: this.educationinput.join(),
              // city: this.cityinput.text,
              city: this.cityinput,
              eligibility: this.eligibilityinput,
              source: this.sourceinput,
              source_details: this.source_details,
              net_enquiry_type: this.net_enquiry_type,
              ne_course: this.ne_course,
              emails: this.emailinput,
              mobiles: parseInt(this.mobileinput),
              details: this.engagementinput,
              acads: 0,
              next_call: this.datatoTimestamp(this.dateinput),
              connection_status: this.connection_status,
            };
            break;
          case false:
            this.url = `${constants.SERVER_API}addMwb`;
            this.post_API_obj = {
              name: this.nameinput,
              designation: this.designation,
              college: this.college_name,
              current_ctc: this.ctc,
              level: this.levelinput,
              iiml_level: this.selected_macc_level,
              courses: this.check1.join(),
              type: "B2C",
              // company: this.companyinput,
              // designation: this.designationinput,
              // experience_int: this.experienceinput,
              // education: this.educationinput.join(),
              // city: this.cityinput.text,
              city: this.cityinput,
              country: this.country,
              international_city: this.international_city,
              eligibility: this.eligibilityinput,
              source: this.sourceinput,
              source_details: this.source_details,
              net_enquiry_type: this.net_enquiry_type,
              ne_course: this.ne_course,
              emails: this.emailinput,
              mobiles: parseInt(this.mobileinput),
              details: this.engagementinput,
              acads: 0,
              next_call: this.datatoTimestamp(this.dateinput),
              connection_status: this.connection_status,
            };
            break;
        }
        console.log(this.url);
        console.log(this.post_API_obj);
        //return;
        axios
          .post(this.url, this.post_API_obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.$vs.loading.close();
            if (response.data.status === "success") {
              EventBus.$emit("update-placementdrive", response.data);
              if (this.$route.path === "/queue") {
                this.showoptional = true;
                EventBus.$emit("update-queue");
                // EventBus.$emit("update-queue-addition");
              }
              if (this.$route.path !== "/netEnquiryTable") {
                this.open = false;
                EventBus.$emit("update-dashboard");
              }
              if (allowed_status_for_persona.includes(this.connection_status)) {
                this.storePersona(response.data.mwb_data);
              } else {
                this.open = false;
              }
              this.$vs.notify({
                time: 20000,
                title: "Response",
                text: response.data.message,
                color: "success",
                position: "top-center",
                click: () => {},
              });
              EventBus.$emit("close-ne-popup");
            } else if (response.data.status === "error") {
              if (response.data.message.includes("spam")) {
                EventBus.$emit("open-spam-popup", this.post_API_obj.mobiles);
                // this.openConfirm(this.post_API_obj.mobiles);
                return;
              } else if (
                !this.showoptional &&
                response.data.message.includes("Identity :")
              ) {
                EventBus.$emit(
                  "open-netEnquiries-map-popup",
                  this.post_API_obj.mobiles,
                  response.data.message
                );
                console.log("This error is from net enquiry");
                return;
              } else {
                this.$vs.notify({
                  time: 20000,
                  title: "Error",
                  text: response.data.message,
                  color: "danger",
                  position: "top-center",
                  click: () => {},
                });
                return;
              }
            }
            console.log("showoptional", this.showoptional);
            if (this.showoptional === false) {
              // response.data.mwb_data.engagements = [];
              // response.data.mwb_data.mobile = [];
              // response.data.mwb_data.emails = [];
              // console.log("before");
              this.openBigPopup(response.data.mwb_data);
              // this.$store.commit("MOUNT_MWB_LEADS", [response.data.mwb_data]);
              // axios
              //   .get(
              //     `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.mwb_data.can_id}&person_id=${response.data.mwb_data.person_id}`,
              //     {
              //       headers: {
              //         Authorization: `Bearer ${localStorage.userAccessToken}`
              //       }
              //     }
              //   )
              //   .then(response2 => {
              //     this.$store.commit("UPDATE_NEW_DATA", [
              //       response.data.mwb_data,
              //       response2.data.engagements,
              //       response2.data.mobile,
              //       response2.data.emails
              //     ]);
              //     EventBus.$emit(
              //       "open-student-details-popup",
              //       this.$store.getters.getObjectMwbLead(response.data.mwb_data)
              //     );
              //     this.open = false;
              //   })
              //   .catch(error => {
              //     this.handleError(error);
              //   });
            }
            if (this.$route.path === "/netEnquiryTable") {
              EventBus.$emit("updateCompleteNetEnquiry");
              //   console.log(response.data.mwb_data);
              //   EventBus.$emit("clickedatachange", response.data.mwb_data);
              //   EventBus.$emit("updateNetEnquiry", { connection_status: "" });
              //   console.log("entered");
              //   axios
              //     .get(
              //       `${constants.SERVER_API}getMwbLead?mwb_id=${response.data.mwb_data.id}`,
              //       {
              //         headers: {
              //           Authorization: `Bearer ${localStorage.userAccessToken}`
              //         }
              //       }
              //     )
              //     .then(response => {
              //       // console.log(response);
              //       response.data.data.engagements = [];
              //       response.data.data.mobile = [];
              //       response.data.data.emails = [];
              //       this.$store.commit("MOUNT_MWB_LEADS", [response.data.data]);
              //       axios
              //         .get(
              //           `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.data.can_id}&person_id=${response.data.data.person_id}`,
              //           {
              //             headers: {
              //               Authorization: `Bearer ${localStorage.userAccessToken}`
              //             }
              //           }
              //         )
              //         .then(response2 => {
              //           this.$store.commit("UPDATE_NEW_DATA", [
              //             response.data.data,
              //             response2.data.engagements,
              //             response2.data.mobile,
              //             response2.data.emails
              //           ]);
              //           this.open = false;
              //           EventBus.$emit(
              //             "open-student-details-popup",
              //             this.$store.getters.getObjectMwbLead(response.data.data)
              //           );
              //           // EventBus.$emit("netEnquiryDetails", details);
              //           this.$vs.loading.close();
              //         })
              //         .catch(error => {
              //           this.handleError(error);
              //         });
              //       // console.log(this.$store.getters.getObjectMwbLead(response.data.data));
              //     })
              //     .catch(error => {
              //       this.handleError(error);
              //     });
            }
          }).catch((error) => {
            this.handleError(error);
          });
      }
    },
    validateName(name) {
      if (this.allLetter(name)) {
        if (name.length >= 3) {
          return true;
        } else {
          this.$vs.notify({
            title: "Name should be atleast three characters in length",
            text: "atleast 3 characters required",
            color: "danger",
            position: "top-center",
          });
          this.$vs.loading.close();
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Enter a valid name",
          text: "Enter a valid Name to continue",
          color: "danger",
          position: "top-center",
        });
        this.$vs.loading.close();
        return false;
      }

    },

    allLetter(text) {
      var letters = /^[A-Za-z ]+$/;
      if (text.match(letters)) {
        return true;
      } else {
        return false;
      }
    },

    validateNumber(number) {
      if (number.length >= 8) {
        number = number.substr(number.length - 8);
        if (parseInt(number)) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid number",
            text: "Enter a valid number to continue",
            color: "danger",
            position: "top-center",
          });
           this.$vs.loading.close();
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Number should be atleast 8 characters in length",
          text: "Enter a valid number",
          color: "danger",
          position: "top-center",
        });
         this.$vs.loading.close();
        return false;
      }
    },
  },
};
</script>

<style>
.popupwidth .vs-popup {
  min-width: 98%;
}
</style>
