<template>
  <div>

    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
        <vs-card>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <p>
                <strong>PREVIOUS ENGAGEMENT -</strong>
                {{ payload.mwb.engagement_details }}
              </p>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <p class="status">Connection Status :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <vs-select class="w-full" v-model="select1" style="border: 1px solid #0044ba; border-radius: 5px" :disabled="schedule_status">
          <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item, index) in options1" />
        </vs-select>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="center" vs-align="center">
        <!-- <vs-button v-if="show === true" @click="googleMeetButton" color="primary" type="gradient" icon="video_call"></vs-button> -->

        <!-- <vs-button v-if="show === true" ref="loadableButton" id="button-with-loading" color="#0044ba"
          class="vs-con-loading__container gap connection_btn" @click="googleMeetButton()">Google Invite</vs-button> -->
        <!-- </vs-col>
        <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"> -->
        <!-- <vs-button v-if="select1 == 'Connected / Discussed'" class="vs-con-loading__container gap connection_btn"
          color="#0044ba" @click="meet_and_greet_popup = true">BIG 4 Meet & Greet</vs-button> -->
        <!-- <vs-button
          v-if="select1 == 'Connected / Discussed'"
          class="vs-con-loading__container gap"
          @click="f2f_popup = true"
          type="gradient"
          color="primary"
          style="top: 10px"
          ><small>F2F Webinar</small></vs-button
        > -->
        <!-- <vs-button
          v-if="select1 == 'Connected / Discussed'"
          class="vs-con-loading__container gap connection_btn"
          @click="macc_popup = true"
          color="#0044ba">USP Meet & Greet
        </vs-button> -->
        <!-- <vs-button
          v-if="select1 == 'Connected / Discussed'"
          class="vs-con-loading__container gap connection_btn"
          @click="accelerator_popup = true"
          color="#0044ba">Accelerator Session
        </vs-button> -->
      </vs-col>
    </vs-row>
    <div v-if="personna_data == true && connected_status.includes(select1)" class="mb-5">
      <personna-details :persona_details="persona_details" :mwb_id="payload.mwb.id"></personna-details>
    </div>
    <div v-if="!connected_status.includes(select1) && personna_data == true" class="mb-5">
      <personna-hide-details :persona_details="persona_details"></personna-hide-details>
    </div>
    <div v-if="show === true" class="mt-2">
      <!-- <vs-divider class="my-6"></vs-divider> -->
      <!-- <div v-if="connected === true">
        <vs-row style="margin-bottom: 3%" class="mt-4">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="3"
          >
            <p class="status">Response :</p>
          </vs-col>
          <vs-col vs-justify="center" vs-align="flex-start" vs-w="4">
            <vs-radio
              color="#0044ba"
              class="myRadioBtn"
              v-model="responseSelection"
              vs-value="sameResponse"
            >
              <p style="font-size: 12px">SAME RESPONSE</p>
            </vs-radio>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <vs-radio
              color="#0044ba"
              id="new"
              class="myRadioBtn"
              v-model="responseSelection"
              vs-value="new"
            >
              <p style="font-size: 12px">NEW</p>
            </vs-radio>
          </vs-col>
        </vs-row>
      </div> -->


      <!-- <vs-row
        style="margin-bottom: 3%"
        v-if="select1 == 'Connected / Discussed'"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="3"
        >
          <p class="status">Location :</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="9"
        >
          <vue-google-autocomplete
            ref="address"
            id="map"
            placeholder="Please type your address"
            v-on:placechanged="getAddressData"
            types="(cities)"
            style="
              position: relative;
              z-index: 103002;
              width: 50%;
              height: 40px;
              border: 1px solid #0044ba;
              border-radius: 6px;
            "
            v-model="current_location"
          >
          </vue-google-autocomplete>
        </vs-col>
      </vs-row> -->
      <!-- <vs-divider class="my-6"></vs-divider>
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <h6 style="color: gray">Escalation:</h6>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        > -->
      <!-- <vs-select
            :disabled="!escalation_boolean"
            autocomplete
            placeholder
            style="margin-top: 4%; margin: 10px"
            class="w-full"
            v-model="selected_escalation_member"
          >
            <vs-select-item value text />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.full_name"
              v-for="(item, index) in visible_members"
            />
          </vs-select> -->
      <!-- <div></div>
        </vs-col>
      </vs-row> -->
      <!-- <vs-row style="margin-bottom: 2%" v-if="escalation_boolean">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <vs-textarea label="Comments" v-model="escalation_comments" />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%" v-if="escalation_boolean">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button @click="escalateThisLead()" color="danger" type="border"
            >Escalate</vs-button
          >
        </vs-col>
      </vs-row> -->
      <!-- <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <h6 style="color: gray">Push this Lead:</h6>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        > -->
      <!-- <vs-select
            :disabled="!transfer_boolean"
            autocomplete
            placeholder
            style="margin-top: 4%; margin: 10px"
            class="w-full"
            v-model="selected_transfer_member"
          >
            <vs-select-item value text />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.full_name"
              v-for="(item, index) in visible_transfer_members"
            />
          </vs-select> -->
      <!-- <div></div>
        </vs-col>
      </vs-row> -->
      <!-- <vs-row style="margin-bottom: 3%" v-if="transfer_boolean">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button color="warning" type="border" @click="transferLead()"
            >Push</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <h6 style="color: gray">Second Level Counselling:</h6>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        > -->
      <!-- <vs-select
            :disabled="!second_visit_boolean"
            autocomplete
            placeholder
            style="margin-top: 4%; margin: 10px"
            class="w-full"
            v-model="selected_second_visit_member"
          >
            <vs-select-item value text />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.full_name"
              v-for="(item, index) in visible_second_visit_members"
            />
          </vs-select> -->
      <!-- <div></div>
        </vs-col>
      </vs-row> -->
      <!-- <vs-row style="margin-bottom: 2%" v-if="second_visit_boolean">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <vs-textarea label="Comments" v-model="second_visit_comments" />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%" v-if="second_visit_boolean">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button @click="addSecondVisit()" color="danger" type="border"
            >Add Second Level Counselling</vs-button
          >
        </vs-col>
      </vs-row> -->
      <!-- <vs-divider class="my-6"></vs-divider> -->

      <div class="inside-container">
        <div class="m-6">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <p class="status1">LEVELS</p>
            </vs-col>
          </vs-row>
          <vs-row class="mt-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7">
              <p class="status2">Level : </p>
            </vs-col>
            <vs-col v-if="!getrole('exed2')" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <div>
                <v-select autocomplete class="invc-input" v-model="levels" style="
                    border: 1px solid #0044ba;
                    border-radius: 5px;
                    width: 375px;
                  " :options="level_options" :reduce="(option) => option.id" :disabled="selected_connection_status">
                </v-select>
              </div>
            </vs-col>
          </vs-row>
          <!-- <vs-row class="mt-10" v-if="hide_select">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7">
              <p class="status2">Exed Level :</p>
            </vs-col>
            <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <v-select autocomplete class="invc-input" v-model="exed_level"
                style="border: 1px solid #0044ba; border-radius: 5px;width:375px;" :options="exed_levels"
                :reduce="(option) => option.id">
              </v-select>
            </vs-col>
          </vs-row> -->
          <vs-row class="mt-10" v-if="false">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7">
              <p class="status2">RPO Level :</p>
            </vs-col>
            <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <div>
                <v-select :disabled="!(iimlConnected === true)" autocomplete class=" invc-input" v-model="iimlLevel"
                  style="border: 1px solid #0044ba; border-radius: 5px;width:375px;" :options="iimlLevels"
                  :reduce="(option) => option.id">
                </v-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7">
              <p class="status2">USP Level :</p>
            </vs-col>
            <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <v-select autocomplete class=" invc-input" v-model="selected_macc_level"
                style="border: 1px solid #0044ba; border-radius: 5px; width:375px;" :options="macc_levels"
                :reduce="(option) => option.id" :disabled="selected_connection_status">
              </v-select>
            </vs-col>
          </vs-row>
          <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7">
              <p class="status2">Finance Courses :</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9" style="margin-left: -20px">
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('CPA')" v-model="course" color="#0044BA" vs-value="CPA"
                    class="checkboxInput">CPA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4')
                  " :disabled="disableCourse('CPA-AA')" color="#0044BA" v-model="course" vs-value="CPA-AA"
                    class="checkboxInput">CPA-AA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('CMA')" v-model="course" color="#0044BA" vs-value="CMA"
                    class="checkboxInput">CMA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('CFA')" color="#0044BA" v-model="course" vs-value="CFA"
                    class="checkboxInput">CFA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <!-- <vs-checkbox
                    v-if="
                      checkIfRoleis('cm1') ||
                      checkIfRoleis('ac1') ||
                      checkIfRoleis('cm4') ||
                      checkIfRoleis('exed2')
                    "
                    :disabled="disable"
                    color="dark"
                    v-model="course"
                    vs-value="CFA-L1"
                    class="checkboxInput"
                    >CFA-L1</vs-checkbox
                  >
                  <vs-checkbox
                    v-if="
                      checkIfRoleis('cm1') ||
                      checkIfRoleis('ac1') ||
                      checkIfRoleis('cm4') ||
                      checkIfRoleis('exed2')
                    "
                    :disabled="disable"
                    color="dark"
                    v-model="course"
                    vs-value="CFA-L2"
                    style="margin-right: 1%"
                    >CFA-L2</vs-checkbox
                  >
                  <vs-checkbox
                    v-if="
                      checkIfRoleis('cm1') ||
                      checkIfRoleis('ac1') ||
                      checkIfRoleis('cm4') ||
                      checkIfRoleis('exed2')
                    "
                    :disabled="disable"
                    color="dark"
                    v-model="course"
                    vs-value="CFA-L3"
                    style="margin-right: 1%"
                    >CFA-L3</vs-checkbox
                  > -->
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
          <vs-row class="mt-5">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9.3" style="margin-left: -20px">
              <vs-row vs-w="12">
                <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disable" color="#0044BA" v-model="course" vs-value="AICPA" class="checkboxInput">AICPA
                  </vs-checkbox>
                </vs-col> -->
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('FRM')" color="#0044BA" v-model="course" vs-value="FRM"
                    class="checkboxInput">FRM
                  </vs-checkbox>
                </vs-col>
                <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " disabled class="checkboxInput" v-model="course" vs-value="MAcc" color="#0044BA">MAcc
                  </vs-checkbox>
                </vs-col> -->
                 <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  "  class="checkboxInput" v-model="course" vs-value="USP" color="#0044BA">USP
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
          <!-- <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
              <p class="status2">ExEd Courses :</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9.3" style="margin-left: -20px">
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('IIMI-BA')" color="#0044BA" v-model="course" vs-value="IIMI-BA"
                    class="checkboxInput">IIMI-BA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('XLRI-HR')" color="#0044BA" v-model="course" vs-value="XLRI-HR"
                    class="checkboxInput">XLRI-HR
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('XLRI-SH')" color="#0044BA" v-model="course" vs-value="XLRI-SH"
                    class="checkboxInput">XLRI-SH
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('IIML-FT')" color="#0044BA" v-model="course" vs-value="IIML-FT"
                    class="checkboxInput">IIML-FT
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row> -->
          <vs-row class="mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
              <!-- <p class="status2">ExEd Courses :</p> -->
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9.3" style="margin-left: -20px">
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('IIML-SF')" color="#0044BA" v-model="course" vs-value="IIML-SF"
                    class="checkboxInput">IIML-SF
                  </vs-checkbox>
                </vs-col>
                 <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('IIMI-AA')" color="#0044BA" v-model="course" vs-value="IIMI-AA"
                    class="checkboxInput">IIMI-AA
                  </vs-checkbox>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disableCourse('IIMI-BA')" color="#0044BA" v-model="course" vs-value="IIMI-BA"
                    class="checkboxInput">IIMI-BA
                  </vs-checkbox>
                </vs-col>
              </vs-row>
            </vs-col>
           </vs-row>
           <vs-row class="mt-5">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9.3" style="margin-left: -20px">
              <vs-row vs-w="12">
                <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " :disabled="disable" color="#0044BA" v-model="course" vs-value="AICPA" class="checkboxInput">AICPA
                  </vs-checkbox>
                </vs-col> -->
                <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
                  <vs-checkbox v-if="
                    checkIfRoleis('cm1') ||
                    checkIfRoleis('ac1') ||
                    checkIfRoleis('cm4') ||
                    checkIfRoleis('exed2')
                  " disabled class="checkboxInput" v-model="course" vs-value="IIMI-AA" color="#0044BA">IIMI-AA
                  </vs-checkbox>
                </vs-col> -->

              </vs-row>
            </vs-col>
          </vs-row>
          <vs-row class="mt-10" v-if="show_cfa_dropdown === true">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
              <p class="status2">Select CFA Course levels :</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <vs-select class="cf_select_course" style="border: 1px solid #0044ba; border-radius: 5px;width:375px;"
                v-model="selected_cfa_level">
                <vs-select-item :key="index" :value="item.value" :text="item.text"
                  v-for="(item, index) in cfa_levels" />
              </vs-select>
              <!-- <v-select
                  autocomplete
                  class="invc-input"
                  v-model="selected_cfa_level"
                  style="border: 1px solid #0044ba; border-radius: 5px;width:375px;"
                  :options="cfa_levels.text" :reduce="(option) => option.value"
                  >
                </v-select> -->
            </vs-col>
          </vs-row>
          <vs-row v-if="show_frm_dropdown === true" class="mt-10">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
              <p class="status2">Select FRM Course parts :</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
              <vs-select class="w-full" style="border: 1px solid #0044ba; border-radius: 5px"
                v-model="selected_frm_parts">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in frm_parts" />
              </vs-select>
            </vs-col>
          </vs-row>
          <!-- <vs-row class="mt-10">
            <vs-col vs-w="2.7" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <span class="status2">Select NFD Level :</span>
            </vs-col>
            <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-radio :class="{'mradio': isActive}" v-model="type_of_nfd" vs-value="m_nfd" vs-name='radioo' color="#0044ba" class="myRadioBtn">
                <p class="schedule_checkbox">M Level</p>
              </vs-radio>
              &nbsp;
              <vs-radio v-model="type_of_nfd" vs-value="u_nfd" vs-name='radioo' color="#0044ba" class="myRadioBtn">
                <p class="schedule_checkbox">U Level</p>
              </vs-radio> -->
            <!-- </vs-col>
          </vs-row> -->
          <vs-row v-if="show_schedule_call" class="mt-10">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <vs-button @click="schedule_call_popup = true" icon="call" color="#DAF8E6" style=" color: #35ad67;
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                ">Schedule Call</vs-button>
            </vs-col>
          </vs-row>
          <vs-row v-if="checkLevel()" class="mt-10">
            <vs-col v-if="getSubTeam('IITM-AA') || getSubTeam('IITR-CC')" vs-w="12" vs-type="flex" vs-justify="center"
              vs-align="center">
              <vs-button @click="openWileyPopup()" type="gradient" color="dark" style="margin-left: -10%"><small>Send
                  Wiley Application</small></vs-button>
            </vs-col>
          </vs-row>
          <vs-row class="mt-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.7">
              <p class="status2">Follow up Date :</p>
            </vs-col>
            <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
              <p class="status2">{{ getSelectedDate() }}</p>
            </vs-col>
          </vs-row>
        </div>
      </div>

      <!-- <div class="inside-container mt-6">
        <div class="m-6">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <p class="status2">Escalation :</p>
            </vs-col>
          </vs-row>
          <vs-row class="mt-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-checkbox color="#0044BA" class="checkboxInput" v-model="escalation_boolean"></vs-checkbox>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-select :disabled="!escalation_boolean" style="border: 1px solid #0044ba; border-radius: 5px"
                class="w-full" v-model="selected_team">
                <vs-select-item value text />
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in teams" />
              </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <treeselect style="border: 1px solid #0044ba; border-radius: 6px" :disabled="!escalation_boolean"
                :options="spocs_tree_data" :disable-branch-nodes="true" v-model="selected_escalation_member" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%" v-if="escalation_boolean">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-textarea label="Comments" v-model="escalation_comments" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-bottom: 3%" v-if="escalation_boolean">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
              <vs-button @click="escalateThisLead()" color="danger" type="border">Escalate</vs-button>
            </vs-col>
          </vs-row>
          <vs-row class="mt-5">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
              <p class="status2">Push this Lead :</p>
            </vs-col>
          </vs-row>
          <vs-row class="mt-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-checkbox color="#0044BA" class="checkboxInput" v-model="transfer_boolean"></vs-checkbox>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-select :disabled="!transfer_boolean" style="border: 1px solid #0044ba; border-radius: 5px"
                class="w-full" v-model="selected_transfer_team">
                <vs-select-item value text />
                <vs-select-item :key="index" :value="item.value" :text="item.text"
                  v-for="(item, index) in transfer_teams" />
              </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <treeselect style="border: 1px solid #0044ba; border-radius: 6px" :disabled="!transfer_boolean"
                :options="transfer_spocs_tree_data" :disable-branch-nodes="true" v-model="selected_transfer_member" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%" v-if="transfer_boolean">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
              <vs-button color="warning" type="border" @click="transferLead()">Push</vs-button>
            </vs-col>
          </vs-row>
          <vs-row class="mt-5">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
              <p class="status2">Second Level Counselling :</p>
            </vs-col>
          </vs-row>
          <vs-row class="mt-3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-checkbox color="#0044BA" class="checkboxInput" v-model="second_visit_boolean"></vs-checkbox>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <vs-select :disabled="!second_visit_boolean" class="w-full"
                style="border: 1px solid #0044ba; border-radius: 5px" v-model="selected_second_visit_team">
                <vs-select-item value text />
                <vs-select-item :key="index" :value="item.value" :text="item.text"
                  v-for="(item, index) in second_visit_teams" />
              </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <treeselect :disabled="!second_visit_boolean" style="border: 1px solid #0044ba; border-radius: 6px"
                :options="spocs_tree_data" placeholder="Select Spoc" :disable-branch-nodes="true"
                v-model="selected_second_visit_member" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%" v-if="second_visit_boolean">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-textarea label="Comments" v-model="second_visit_comments" />
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%" v-if="second_visit_boolean">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
              <vs-button @click="addSecondVisit()" color="danger" type="border">Add Second Level Counselling</vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div> -->
      <vs-row style="margin-bottom: 3%; margin-top:4%;">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
          <p class="status">Engagement :</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
          <vs-textarea style="
              font-weight: 500 !important;
              font-size: 12px !important;
              border-radius: 10px;
              font-family: montserrat;
            " v-model="textAre" />
        </vs-col>
      </vs-row>

      <div class="inside-container mt-6">
        <div class="m-6">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <p class="status2">Miles US Pathway - US Visa Orientation Session :</p>
            </vs-col>
          </vs-row>
          <AcceleratorSessionPopup :mwb="payload.mwb" :email_data="payload.emails"></AcceleratorSessionPopup>
        </div>
      </div>
      <save-engagement :mwb_courses="mwb_courses" :macc_level="selected_macc_level" :payload="payload" :whatsapp_button_status="stopLoading" :selected_courses="course" :phone_number="phone_number"
        :connection_status="connection_status" :person_name="payload.mwb.person_name" :connected_value="select1"
        @add-engagement="addEngagement" :save_loading="save_loading"
        :save_and_whatsapp_loading="save_and_whatsapp_loading" :all_loading="all_loading" :pathway="pathway"
        :persona="persona" :meetbutton="onlygooglemeetbutton"></save-engagement>
    </div>
    <vs-popup class="holamundo" title="Are you sure you want to send application ?" :active.sync="popupOpen">
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p>
            Please confirm that you have taken
            <b>{{ this.WlieyPersonName }}</b> 's consent to registering him/her
            to submit application for <b>{{ this.SelectedCOurse }}</b> with
            these contact details <b>{{ this.PrimaryMobile }}</b> and
            <b>{{ this.PrimaryEmail }}</b>
          </p>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="popupOpen = false">Cancel</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button color="success" type="border" @click="sendWileyApplication()">Yes</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <!--Schedule Call Popup -->
    <vs-popup class="holamundo schedule_popup" title="Schedule Call" :active.sync="schedule_call_popup"
      style="border-radius: 15px !important">
      <vs-row>
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="grid_view"></vs-icon> &nbsp;
            <span class="schedule_title">&nbsp; Type of next call : </span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vs-radio v-model="type_of_next_call" vs-value="office" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Visit</p>
          </vs-radio>
          &nbsp;
          <vs-radio v-model="type_of_next_call" vs-value="video" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Video call</p>
          </vs-radio>
          &nbsp;
          <vs-radio v-model="type_of_next_call" vs-value="phone" color="#0044ba" class="myRadioBtn">
            <p class="schedule_checkbox">Phone call</p>
          </vs-radio>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="calendar_today"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Date :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <!-- <datepicker placeholder="DD-MM-YYYY" :format="customFormatter" v-model="schedule_date"
                  style="position: fixed; z-index:2000;">
                </datepicker> -->
          <datepicker v-if="!getrole('exed2')" :disabledDates="disabledDates" placeholder="Select Date"
            :format="customFormatter" v-model="dates" style="
              position: fixed;
              z-index: 2000;
              border: 1px solid #0044ba;
              border-radius: 5px;
              width: 205px;
            "></datepicker>
          <datepicker v-else placeholder="Select Date" :format="customFormatter" v-model="dates" style="
              position: fixed;
              z-index: 2000;
              border: 1px solid #0044ba;
              border-radius: 5px;
              width: 205px;
            "></datepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="schedule"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Time :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vue-timepicker v-if="type_of_next_call == 'phone'" :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="10" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="
              position: fixed;
              z-index: 1500;
              border: 1px solid #0044ba;
              border-radius: 3px;
              width: 205px;
            ">
          </vue-timepicker>
          <vue-timepicker v-else :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="30" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="
              position: fixed;
              z-index: 1500;
              border: 1px solid #0044ba;
              border-radius: 3px;
              width: 205px;
            ">
          </vue-timepicker>
        </vs-col>
      </vs-row>

      <div style="display: flex; justify-content: center; margin-top: 2%">
        <vs-button color="#0044ba" @click="scheduleCall">Okay</vs-button>
      </div>
    </vs-popup>
    <register-for-webinar-popup :f2f_popup="f2f_popup" :mwb_id="payload.mwb.id" @close-f2f-popup="f2f_popup = false">
    </register-for-webinar-popup>
    <meet-and-greet-popup v-if="meet_and_greet_popup" :meet_and_greet_popup="meet_and_greet_popup" :payload="payload"
      @close-meet-and-greet-popup="meet_and_greet_popup = false">
    </meet-and-greet-popup>
    <div class="mb-5">
      <macc-popup v-if="macc_popup" :macc_popup="macc_popup"  :payload="payload" @close-macc-popup="macc_popup = false"></macc-popup>
    </div>
    <!-- <updated-meet-and-greet-popup :meet_and_greet_popup="meet_and_greet_popup" @close-meet-and-greet-popup="meet_and_greet_popup = false"></updated-meet-and-greet-popup> -->
  </div>
</template>
<script>
// import AutoComplete from "./AutoComplete";
import MaccPopup from "./MaccPopup.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PersonnaDetails from "./PersonnaDetails.vue"
import PersonnaHideDetails from "./PersonnaHideDetails.vue"
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import _ from "lodash";
import datetime from "vuejs-datetimepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import EventBus from "../../eventbus";
import constants from "../../../../constants.json";
import axios from "axios";
import moment from "moment";
import SaveEngagement from "./SaveEngagement.vue";
import VueTimepicker from "vue2-timepicker";
import RegisterForWebinarPopup from "./RegisterForWebinarPopup";
import AcceleratorSessionPopup from "./AcceleratorSessionPopup";
import MeetAndGreetPopup from "./MeetAndGreetPopup.vue";
import ApiServices from "../../../ApiServices";
// import UpdatedMeetAndGreetPopup from "./UpdatedMeetAndGreetPopup.vue"
// import { Datetime } from 'vue-datetime';
export default {
  props: {
    payload: {
      required: true,
      type: Object,
    }
  },
  components: {
    MaccPopup,
    "vue-google-autocomplete": VueGoogleAutocomplete,
    Datepicker,
    "v-select": vSelect,
    DateRangePicker,
    datetime,
    SaveEngagement,
    // AutoComplete,
    Treeselect,
    VueTimepicker,
    // datetime: Datetime
    // chatInterface,
    RegisterForWebinarPopup,
    MeetAndGreetPopup,
    AcceleratorSessionPopup,
    PersonnaDetails,
    PersonnaHideDetails
    // UpdatedMeetAndGreetPopup
    ,
},
  mounted() {
    this.schedule_status = false
    EventBus.$on("change-connection-stat", (data, status) => {
      // if(data.visit_status != ''){
        this.select1 = status
        this.type_of_next_call = 'office'
        this.schedule_status = true
        console.log("change-connection-status2", this.select1, data);
        this.upcoming_visits_date = data.upcoming_visits[0].scheduled_date - 86400;
        this.dates = moment.unix(this.upcoming_visits_date).format("DD-MMM-YYYY");
        // this.schedule_time.HH = moment.unix(this.upcoming_visits_date).format("HH");
        // this.schedule_time.mm = moment.unix(this.upcoming_visits_date).format("mm");
        // this.dates = this.upcoming_visits_date;
      // }
    })

    if(this.payload.upcoming_visits.length != 0){
      this.visit_found = 1;
    }else{
      this.visit_found = 0;
      this.options1.unshift({ text: "Connected / Discussed", value: 1 },)
    }
    this.course = [];
    this.course = this.payload.mwb.courses.split(",");
    this.mwb_courses = this.payload.mwb.courses.split(",");
    if (this.payload.mwb != undefined && this.payload.mwb.enrollments.length > 0) {
      this.payload.mwb.enrollments.forEach(enrollment => {
        if (!this.course.includes(enrollment.course)) {
          this.course.push(enrollment.course)
        }if (enrollment.enrollment_loan_status == 1) {
          this.loan_status = true
        }
        this.enrolled_courses.push(enrollment.course)
      });
    }

    console.log("COURSES IN CALL SECTION", this.course)

    EventBus.$off("path-way-select")
    let sub_team = localStorage.getItem('sub_team')
    let team = localStorage.getItem('team')
    if (sub_team == 'CM' && team == 'CM') {
      this.hide_select = false
    } else if (sub_team == 'SR' && team == 'SR') {
      this.hide_select = false
    }
    this.levels = this.payload.mwb.level;
    // this.selected_macc_level = this.payload.mwb.iiml_level;
    if (this.payload.mwb.iiml_level != '') {
      if (this.macc_levels.find((item) => item.id === this.payload.mwb.iiml_level)) {
        this.selected_macc_level = this.payload.mwb.iiml_level;
        this.changedULevel(this.payload.mwb.iiml_level);
      }
      if (this.exed_levels.find((item) => item.id === this.payload.mwb.iiml_level)) {
        this.exed_level = this.payload.mwb.iiml_level;
      }
    }

    // this.$refs.address.focus();
    if (this.show === true) {
      this.$refs.address.focus();
    }
    // new google.maps.places.Autocomplete((window.parent.document.getElementById('locationSearch')),
    //   {types: ['geocode']});
    if (this.getSubTeam("IIML-FT") || this.getSubTeam("IIMI-BA")) {
      if (this.payload.mwb.iiml_level != "") {
        this.exed_level = this.payload.mwb.iiml_level;
      }
      if (
        this.payload.mwb.iiml_level == "" ||
        this.payload.mwb.iiml_level == null
      ) {
        this.exed_level = "Net Enquiry";
      }
    }
    console.log("this.payload", this.payload);
    // this.getWIleyApplicationData();
    this.google_spoc_id = this.payload.mwb.spoc_id;
    this.google_mwb_id = this.payload.mwb.id;
    this.phone_number = this.getPrimaryMobile(this.payload.mobiles);
    // this.payload.whats_app_messages.forEach((template) => {
    //   // if (template.course === 'CPA') {
    //   this.whatsapp_options.forEach((message_template) => {
    //     if (message_template.course === template.course) {
    //       message_template.message = template.message;
    //     }
    //   });
    //   // }
    // });
    this.wileyCourseArray = this.payload.mwb.courses.split(",");
    this.wileyCourseArray.forEach((wileyCourse) => {
      console.log("wiley_course", wileyCourse);
      if (wileyCourse === "IITM-FS") {
        this.wiley_course = "IITM-FS";
      }
    });
    this.whatsapp_information = this.payload.whats_app_messages;
    console.log("this fetched");
    if (
      this.payload.mwb.level === "M4-" ||
      this.payload.mwb.level === "M4" ||
      this.payload.mwb.level === "M5" ||
      this.payload.mwb.level === "M6" ||
      this.payload.mwb.level === "L4-" ||
      this.payload.mwb.level === "L4" ||
      this.payload.mwb.level === "L5" ||
      this.payload.mwb.level === "L6" ||
      this.payload.mwb.level === "M7"
      // this.payload.mwb.level === "M7-" ||
      // this.payload.mwb.level === "M8" ||
      // this.payload.mwb.level === "M8-" ||
      // this.payload.mwb.level === "M9" ||
      // this.payload.mwb.level === "M9-" ||
      // this.payload.mwb.level === "M10"
    ) {
      if (this.getTeam("SR")) {
        this.labelOptions = [
          { label: "", id: "" },
          { label: "M11 : Received CPA license", id: "M11" },
          { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
          { label: "M12 : Received License Renewal Advice", id: "M12" },
          { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
          { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
          { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
          { label: "M9- : Drop-out (after clearing an exam) (90 days)", id: "M9-" },
          { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
          { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
          { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
          { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
          { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
          { label: "M8- : Drop-out (after evaluation) (90 days)", id: "M8-" },
          { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
          { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
          { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
          { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
          { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
          { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
          { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
          { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
          { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
          { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
          { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
          { label: "M7D1 : Defaulter", id: "M7D1" },
          { label: "M7D2 : Loan Defaulter", id: "M7D2" },
          { label: "M7+ LMS demo completed (90 days)", id: "M7+" },
          { label: "M7# : Onboarding Done (90 days)", id: "M7#" },
          { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
          { label: "M7- : Drop-out (90 days)", id: "M7-" },
          { label: "M7 : Enrolled (90 Days)", id: "M7" },
          { label: "M6 : Visited & Ready to Enroll", id: "M6" },
          { label: "M5 : Visited & Positive", id: "M5" },
          { label: "M4 : Visited but Postponed", id: "M4" },
          { label: "M4- : Visited but not interested", id: "M4-" },
          { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
          { label: "M3+ : Called & Coming", id: "M3+" },
          { label: "M3 : Called & Positive", id: "M3" },
          { label: "M2 : Did not Visit & Postponed", id: "M2" },
          { label: "M1 : Did not Visit & not intersted", id: "M1" },
        ];
        this.level_options = this.labelOptions;
      } else {
        if (
          this.payload.mwb.level === "M4-" ||
          this.payload.mwb.level === "M4" ||
          this.payload.mwb.level === "M5" ||
          this.payload.mwb.level === "M6" ||
          this.payload.mwb.level === "L4-" ||
          this.payload.mwb.level === "L4" ||
          this.payload.mwb.level === "L5" ||
          this.payload.mwb.level === "L6" ||
          this.payload.mwb.level === "M7" ||
          this.payload.mwb.level === "N/A" ||
          this.payload.mwb.level === "DND"
        ) {
          this.connected = true;
          this.labelOptions = [
            { label: "", id: "" },
            { label: "M7 : Enrolled", id: "M7" },
            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
            { label: "M5 : Visited & Positive", id: "M5" },
            { label: "M4 : Visited but Postponed", id: "M4" },
            { label: "M4- : Visited but not interested", id: "M4-" },
            { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
            { label: "M3+ : Called & Coming", id: "M3+" },
            { label: "M3 : Called & Positive", id: "M3" },
            { label: "M2 : Did not Visit & Postponed", id: "M2" },
            { label: "M1 : Did not Visit & not intersted", id: "M1" },
          ];
        } else {
          this.connected = false;
          this.labelOptions = this.labelOptionsvisit;
        }
      }
    }
    if (this.getTeam("SR")) {
      if (this.payload.mwb.level.indexOf("M10") !== -1) {
        this.connected = false;
      }
    }
    if (!this.getTeam("SR")) {
      if (this.payload.mwb.level.indexOf("M7") !== -1) {
        this.connected = false;
      }
    }

    this.current_location = this.payload.google_location;

    EventBus.$on('update-persona-details', () => {
      // console.log("LISTENING TO update-persona-details")
      this.getPersonnaUpdatedData();

    }),

      EventBus.$on('path-way-select', (pathway) => {
        this.pathway = pathway;

        // this.determineMaccLevel();

      }),

      EventBus.$on('changed-macc-level', (macc_level) => {
        this.selectedLevel = macc_level;
        this.selected_macc_level = macc_level;
      }),

      EventBus.$on('persona-changed', (persona) => {
        // console.log("Received persona on emit", persona)

        this.persona = persona
      }),

      EventBus.$on('check-persona-data', (new_data) => {
        this.checkPersonaData(new_data);

      }),

      this.generateWhatsAppMessage();
    // this.fetchEscalationsMembers();
    this.getWhatsAppDisplayText();
    this.send_intro_options = this.payload.mwb.courses.split(",");
    this.getCandidatePersonaDetails();
    let user_id = localStorage.getItem('user_id')
    if (user_id == 2613 || user_id == 2474) {
        this.collection_team = true
    }
    console.log("DISABLED DATES", this.disabledDates)
  },
  watch: {
    type_of_next_call(){
      this.schedule_time = {
          HH: null,
          mm: null,
      }
    },
    textAre(){
      this.generateEngagementText(this.select1);
    },
    selected_macc_level: function (value) {
      this.changedULevel(value);
      console.log("exed_level", value,);
      this.isActive = false
      this.type_of_nfd = 'u_nfd';
      // this.selectedULevel(value);
      this.freezeMAccLevels();
    },
    selected_cfa_level(val) {
      for (let i = 0; i < this.cfa_levels.length; i++) {
        const element = this.cfa_levels[i].value;
        if (this.course.indexOf(element) != -1) {
          this.course.splice(this.course.indexOf(element), 1);
        }
      }
      if (this.course.indexOf(val) == -1) {
        this.course.push(val);
      }
    },
    selected_frm_parts(val) {
      for (let i = 0; i < this.frm_parts.length; i++) {
        const element = this.frm_parts[i].value;
        if (this.course.indexOf(element) != -1) {
          this.course.splice(this.course.indexOf(element), 1);
        }
      }
      if (this.course.indexOf(val) == -1) {
        this.course.push(val);
      }
    },
    selectedGoogleMeetDate(value) {
      let currentDate = moment().format("DD-MMM-YYYY h:mm:ss a");
      let selected_date = moment(value);
      let dateselected = moment(selected_date._i, "DD-MM-YYYY HH:mm:ss").format(
        "DD-MMM-YYYY h:mm:ss a"
      );
      console.log("current", currentDate);
      console.log("date selected", dateselected);
      let query = moment(currentDate).isSameOrAfter(dateselected);
      console.log("status", query);
      if (query === false) {
        console.log("query", query);
        this.googleMeetDate = dateselected;
        this.googleMeetDateStatus = true;
      } else {
        console.log("query", query);
        this.googleMeetDate = dateselected;
        this.googleMeetDateStatus = false;
      }
    },
    sendIntrocourse(value) {
      console.log(this.whatsapp_information);
      this.whatsapp_information.forEach((message_template) => {
        if (message_template.course === value) {
          let custom_wam = message_template.message;
          custom_wam = custom_wam.replace(
            "{{candidate_name}}",
            this.payload.mwb.person_name
          );
          console.log("custom_wam", custom_wam);
          this.whatsapp_message = custom_wam;
        }
      });
    },
    transfer_boolean(value) {
      this.selected_transfer_member = null;
      if (value) {
        let city = null;
        console.log(city);
        this.escalationsObj.cm_team.forEach((spoc) => {
          if (spoc.id === this.escalationsObj.logged_in_user_id) {
            city = spoc.city;
          }
        });
        let arr = [];
        this.escalationsObj.cm_team.forEach((spoc) => {
          console.log("cm team", spoc);
          // if (spoc.city === city) {
          arr.push(spoc);
          // }
        });
        this.escalationsObj.sr_team.forEach((spoc) => {
          console.log("cm team", spoc);
          // if (spoc.city === city) {
          arr.push(spoc);
          // }
        });
        this.visible_transfer_members = arr;
        // this.changeSpocVisibility("cm_team", "transfer");
      }
    },
    // selected_template(value) {
    // this.whatsapp_message = value.message;
    // this.getWhatsAppDisplayText();
    // },
    selected_team(value) {
      if (this.selected_team != "") {
        this.changeSpocVisibility(value, "escalation");
      }
    },
    selected_transfer_team(value) {
      if (this.selected_transfer_team != "") {
        this.transferSpocVisibility(value, "transfer");
      }
    },
    selected_second_visit_team(value) {
      console.log(value);
      this.visible_second_visit_members = [];
      if (value === "acads_team") {
        this.visible_second_visit_members = this.escalationsObj[value];
      } else if (value === "cm_team") {
        this.visible_second_visit_members = this.escalationsObj[value];
      } else if (value === "iiml_team") {
        this.visible_second_visit_members = this.escalationsObj[value];
      } else if (value === "sr_team") {
        this.visible_second_visit_members = this.escalationsObj[value];
      } else if (value === "gm_team") {
        this.visible_second_visit_members = this.escalationsObj[value];
      } else {
        this.visible_second_visit_members = this.second_level_preload[value];
      }
      this.spocs_tree_data = [];
      this.spoc_cities = [];
      var unique = this.escalationsObj[value]
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.spoc_cities.push(key);
          var obj = {
            id: key,
            label: key,
            children: [],
          };
          this.spocs_tree_data.push(obj);
        }
      }
      this.escalationsObj[value].forEach((server_spoc) => {
        this.spocs_tree_data.forEach((bde) => {
          if (bde.label === server_spoc.city) {
            var obj = {
              id: server_spoc.id,
              label: server_spoc.full_name,
            };
            bde.children.push(obj);
          }
        });
      });
      this.spocs_tree_data = this.sortNestedArrayAlphabetically(
        this.spocs_tree_data
      );
    },
    visit_escalation: function (value) {
      this.escalation_visit = value;
    },
    levels: function (value) {
      if((value == 'M1' || value == 'M2') && (this.selected_macc_level == 'U3' || this.selected_macc_level == 'U3+' || this.selected_macc_level == 'U3++')){
        this.$vs.notify({
            title: "Not Allowed",
            text: `You can't select the ${value} level when ${this.selected_macc_level} is chosen`,
            color: "danger",
          });
          this.levels = this.payload.mwb.level;
      }else{
        this.isActive = true
        this.type_of_nfd = 'm_nfd';
        this.selectedMLevel(value);
      }
    },
    // exed_level: function (value) {

    //   let limit = 180;
    //   let suggested = 180;
    //   let nfds = {};
    //   nfds = {

    //     "IIML-FA-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-SF-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-FT-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-BA-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-PM-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-HR-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITR-BF-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITR-DB-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITM-AA-M7": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIMK-CX": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITM-FS": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITR-CC": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIMK-FT": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-AB": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIML-SH": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IITJ-DE": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "XLRI-HR": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "IIMI-BA": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //      "IIMI-AA": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     "XLRI-DM": {
    //       limit: 180,
    //       suggested: 30,
    //     },
    //     Cold: {
    //       limit: 0,
    //       suggested: 0,
    //     },
    //     "Exam-Pass-Dropout": {
    //       limit: 30,
    //       suggested: 30,
    //     },
    //     "Application Received": {
    //       limit: 5,
    //       suggested: 2,
    //     },
    //     "Exam-Pass": {
    //       limit: 7,
    //       suggested: 7,
    //     },
    //     "Very Hot": {
    //       limit: 3,
    //       suggested: 2,
    //     },
    //     Warm: {
    //       limit: 1,
    //       suggested: 3,
    //     },
    //     Hot: {
    //       limit: 3,
    //       suggested: 2,
    //     },
    //     "Exam-Fail": {
    //       limit: 30,
    //       suggested: 30,
    //     },
    //     "Net Enquiry": {
    //       limit: 1,
    //       suggested: 1,
    //     },
    //     "Busy/NL": {
    //       limit: 5,
    //       suggested: 2,
    //     },
    //     "Application Approved": {
    //       limit: 5,
    //       suggested: 2,
    //     },
    //     Enrolled: {
    //       limit: 365,
    //       suggested: 7,
    //     },
    //     "Final payment Received": {
    //       limit: 5,
    //       suggested: 2,
    //     },
    //     NA: {
    //       limit: 0,
    //       suggested: 0,
    //     },
    //     Enquiry: {
    //       limit: 7,
    //       suggested: 7,
    //     },

    //     "Exam-Fail-Dropout": {
    //       limit: 30,
    //       suggested: 30,
    //     },
    //     "Exam-Noshow-Dropout": {
    //       limit: 30,
    //       suggested: 30,
    //     },
    //     "Lead-DB": {
    //       limit: 180,
    //       suggested: 180,
    //     },
    //     "Lead-IR": {
    //       limit: 180,
    //       suggested: 180,
    //     },
    //   };
    //   if (!this.getTeam("SR") && value === "Cold") {
    //     if (this.levels.includes("M3") || this.levels.includes("M3+") || this.levels.includes("M3++") ||this.levels.includes("M4") || this.levels.includes("M5") || this.levels.includes("M6") || this.levels.includes("M2")) {
    //       nfds = {
    //         M6: {
    //           limit: 7,
    //           suggested: 1,
    //         },
    //         M5: {
    //           limit: 30,
    //           suggested: 1,
    //         },
    //         M4: {
    //           limit: 365,
    //           suggested: 90,
    //         },
    //         M3: {
    //           limit: 60,
    //           suggested: 1,
    //         },
    //         M2: {
    //           limit: 365,
    //           suggested: 90,
    //         },
    //         "M3+": {
    //           limit: 40,
    //           suggested: 1,
    //         },
    //       }
    //     }
    //   }
    //   if (this.getTeam("SR") && value === "Cold") {
    //     if (this.levels.includes("M3") || this.levels.includes("M3+") || this.levels.includes("M3++") || this.levels.includes("M4") || this.levels.includes("M5") || this.levels.includes("M6") || this.levels.includes("M2")) {
    //       nfds = {
    //         M6: {
    //           limit: 14,
    //           suggested: 7,
    //         },
    //         M5: {
    //           limit: 14,
    //           suggested: 7,
    //         },
    //         M4: {
    //           limit: 180,
    //           suggested: 90,
    //         },
    //         "M3+": {
    //           limit: 14,
    //           suggested: 7,
    //         },
    //         "M3++": {
    //           limit: 14,
    //           suggested: 1,
    //         },
    //         M3: {
    //           limit: 30,
    //           suggested: 14,
    //         },
    //         M2: {
    //           limit: 180,
    //           suggested: 90,
    //         },
    //       }
    //     }
    //   }
    //   limit = nfds[value].limit;
    //   suggested = nfds[value].suggested;
    //   if (this.getSubTeam("IIML-FT") && value == "Warm") {
    //     limit = 7;
    //     suggested = 7;
    //   }
    //   this.disabledDates.from = new Date(
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     new Date().getDate() + limit
    //   );
    //   this.disabledDates.to = new Date(
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     new Date().getDate()
    //   );
    //   this.dates = new Date(
    //     new Date(
    //       new Date().getFullYear(),
    //       new Date().getMonth(),
    //       new Date().getDate() + suggested
    //     )
    //   );
    //   // }
    //   if (value == "Cold" || value == "NA" || value == "Enrolled") {
    //     this.showDatepicker = false;
    //     this.dates = "";
    //   } else {
    //     this.showDatepicker = true;
    //   }

    //   if (localStorage.getItem('role') == 'cm1') {

    //     this.disabledDates.from = new Date(
    //       new Date().getFullYear(),
    //       new Date().getMonth(),
    //       new Date().getDate() + 365
    //     );
    //     this.disabledDates.to = new Date(
    //       new Date().getFullYear(),
    //       new Date().getMonth(),
    //       new Date().getDate()
    //     );
    //   }

    //   console.log("suggetsed 2", this.dates, this.disabledDates)
    // },
    select1: function (value) {
      console.log("selctedValue", value);
      if (this.payload.mwb.iiml_level != '' || this.payload.mwb.iiml_level != null ) {
      if (this.macc_levels.find((item) => item.id === this.payload.mwb.iiml_level)) {
        this.selected_macc_level = this.payload.mwb.iiml_level;
      }
      if (this.exed_levels.find((item) => item.id === this.payload.mwb.iiml_level)) {
        this.exed_level = this.payload.mwb.iiml_level;
      }
      }
      if(this.payload.mwb.iiml_level == '' || this.payload.mwb.iiml_level == null){
        this.selected_macc_level = '';
      }
      this.levels = this.payload.mwb.level;
      console.log(value);
      this.select_value = value;
      this.exceptionOption = false;
      let valuetext = value;
      if (valuetext === "Connected / Discussed" || valuetext === "Visit Confirmed") {
        this.show_schedule_call = true;
        this.selected_connection_status = false;
        this.disable = false;
        this.iimlConnected = true;
        if (this.getTeam("SR")) {
          if (this.payload.mwb.level.indexOf("M10") !== -1) {
            this.connected = false;
          } else {
            this.connected = true;
          }
        } else {
          if (this.payload.mwb.level.indexOf("M7") !== -1) {
            this.connected = false;
          } else if (this.payload.mwb.level.indexOf("M8") !== -1) {
            this.connected = false;
          } else if (this.payload.mwb.level.indexOf("M9") !== -1) {
            this.connected = false;
          } else {
            this.connected = true;
          }
        }
        this.connection_status = true;
        this.responseSelection = "new";
        this.textAre = "";
        this.show = true;
      }
      // else if(valuetext == "Invalid Number/Wrong number"){
      //   this.levels = "M1";
      //   this.selected_macc_level = 'U0';
      // }
      else if(valuetext == 'Communication barrier' || valuetext == 'Not Educated' || valuetext == "Invalid Number/Wrong number"){
          this.dates = "";
          this.show_schedule_call = false;
          this.selected_connection_status = false;
          this.selected_macc_level = 'U0';
          console.log("kkk", this.levels);
          if(this.payload.mwb.level > 'M6'){
            this.levels = this.payload.mwb.level
          }else{this.levels = "M1";}
          if (this.getrole("exed2")) {
            this.disable = false;
            this.connected = false;
            this.iimlConnected = false;
            this.textAre = value +', ';
            this.generateEngagementText(valuetext);
            this.show = true;
          } else {
            this.disable = false;
            this.connected = false;
            this.iimlConnected = false;
            this.textAre = value + ', ';
            this.generateEngagementText(valuetext);
            this.show = true;
          }
      }
      else if (valuetext === "Connected / Not Interested") {
        this.show_schedule_call = true;
        this.selected_connection_status = false;
        this.disable = false;
        this.iimlConnected = true;
        if (this.getTeam("SR")) {
          if (this.payload.mwb.level.indexOf("M10") !== -1) {
            this.connected = false;
          } else {
            this.connected = true;
          }
        } else {
          if (this.payload.mwb.level.indexOf("M7") !== -1) {
            this.connected = false;
          } else if (this.payload.mwb.level.indexOf("M8") !== -1) {
            this.connected = false;
          } else if (this.payload.mwb.level.indexOf("M9") !== -1) {
            this.connected = false;
          } else {
            this.connected = true;
          }
        }
        this.connection_status = true;
        this.responseSelection = "new";
        this.textAre = "";
        this.show = true;
      }
      else if (valuetext === "") {
        this.show = false;
      } else if (valuetext === "Connected / Never call back") {
        this.show_schedule_call = true;
        this.selected_connection_status = false;
        if (this.getrole("exed2")) {
          this.connection_status = true;
          this.textAre = valuetext;
          this.disable = false;
          this.connected = false;
          this.iimlConnected = false;
          this.show = true;
        } else {
          this.connection_status = true;
          this.textAre = valuetext;
          this.disable = true;
          this.connected = false;
          this.iimlConnected = false;
          this.show = true;
        }
      }
      else if(valuetext === 'Connected / Busy' || valuetext === 'Connected / Wrong Number'){
          this.show_schedule_call = true;
          this.selected_connection_status = false;
          if (this.getrole("exed2")) {
            this.disable = false;
            this.connected = false;
            this.iimlConnected = false;
            this.textAre = value +', ';
            this.generateEngagementText(valuetext);
            this.show = true;
          } else {
            this.disable = false;
            this.connected = false;
            this.iimlConnected = false;
            this.textAre = value + ', ';
            this.generateEngagementText(valuetext);
            this.show = true;
          }
      }
      else {
        this.show_schedule_call = true;
        this.selected_connection_status = true;
        if (this.getrole("exed2")) {
          this.disable = false;
          this.connected = false;
          this.iimlConnected = false;
          this.textAre = value +', ';
          this.generateEngagementText(valuetext);
          this.show = true;
        } else {
          this.disable = false;
          this.connected = false;
          this.iimlConnected = false;
          this.textAre = value + ', ';
          this.generateEngagementText(valuetext);
          this.show = true;
        }
      }
      let exceptions = [
        "Connected / Busy",
        "Connected / Never call back",
        "Connected / Wrong Number",
        "Busy",
        "Not Lifting",
        "Not Reachable",
        "Disconnected",
        "Invalid Number",
        "Switched Off",
        "Connected / Not Interested",
        "Communication barrier",
        "Not Educated",
      ];
      let exceptionOptions = {
        M11: [{ text: "M11 : Received CPA license", value: "M11" }],
        "M11#": [{ text: "M11# : Yet to recieve License renewal advice from Miles", value: "M11#" }],
        M12: [{ text: "M12 : Received License Renewal Advice", value: "M12" }],
        M10: [{ text: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", value: "M10" }],
        "M10#": [{ text: "M10# : Received license advice from Miles; License application WIP", value: "M10#" }],
        M9: [{ text: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", value: "M9" }],
        "M9-": [{ text: "M9- : Drop-out (after clearing an exam) (once every 3 months)", value: "M9-" }],
        "M9+1": [{ text: "M9+1 : Received NTS rescheduling advice from Miles for other exams", value: "M9+1" }],
        "M9+2": [{ text: "M9+2 : Received New NTS; Yet to receive approval by GP", value: "M9+2" }],
        "M9+3": [{ text: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", value: "M9+3" }],
        M8: [{ text: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", value: "M8" }],
        "M8+": [{ text: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", value: "M8+" }],
        "M8-": [{ text: "M8- : Drop-out (after evaluation) (once every 3 months)", value: "M8-", }],
        "M8#1": [{ text: "M8#1 : Enrolled:Yet to recive evaluation advice", value: "M8#1" }],
        "M8#1-": [{ text: "M8#1- : Incomplete Documents; Unable to release evaluation advice", value: "M8#1-" }],
        "M8#1+": [{ text: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", value: "M8#1+" }],
        "M8#2": [{ text: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", value: "M8#2" }],
        "M8+1": [{ text: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", value: "M8+1" }],
        "M8+2": [{ text: "M8+2 : Applied for NTS; Yet to receive NTS", value: "M8+2" }],
        "M8+3": [{ text: "M8+3 : Received NTS; Yet to receive exam scheduling advice", value: "M8+3" }],
        "M8+4": [{ text: "M8+4 : Received exam scheduling advice; Yet to schedule exam", value: "M8+4" }],
        "M8+5": [{ text: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", value: "M8+5" }],
        "M8+6": [{ text: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", value: "M8+6" }],
        M7X: [{ text: "M7X : Do Not Ever Call (NFD - Blank)", value: "M7X" }],
        M7D1: [{ text: "M7D1 : Defaulter", value: "M7D1" }],
        M7D2: [{ text: "M7D2 : Loan Defaulter", value: "M7D2" }],
        "M7-JJ": [{ text: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", value: "M7-JJ" }],
        "M7#": [{ text: "M7# : Onboarding Done (30-60 days)", value: "M7#" }],
        "M7+": [{ text: "M7+ : LMS demo completed (30-60 days)", value: "M7+" }],
        M7: [{ text: "M7 : Enrolled", value: "M7" }],
        "M7-": [{ text: "M7- : Drop-out (30 days)", value: "M7-" }],
        M6: [
          { text: "M6 : Visited & Ready to Enroll", value: "M6" },
          { text: "M5 : Visited & Positive", value: "M5" },
        ],
        M5: [
          { text: "M5 : Visited & Positive", value: "M5" },
          { text: "M4 : Visited but Postponed", value: "M4" },
        ],
        M4: [
          { text: "M4 : Visited but Postponed", value: "M4" },
          { text: "M4- : Visited but not interested", value: "M4-" },
        ],
        "M4-": [{ text: "M4- : Visited but not interested", value: "M4-" }],
        "M3+": [
          { text: "M3++ : Ready to enroll - Not visited", value: "M3++" },
          { text: "M3+ : Called & Coming", value: "M3+" },
          { text: "M3 : Called & Positive", value: "M3" },
        ],
        M3: [
          { text: "M3 : Called & Positive", value: "M3" },
          { text: "M2 : Did not Visit & Postponed", value: "M2" },
        ],
        M2: [
          { text: "M2 : Did not Visit & Postponed", value: "M2" },
          { text: "M1 : Did not Visit & not intersted", value: "M1" },
        ],
        M1: [{ text: "M1 - Did not Visit & not intersted", value: "M1" }],
      };
      console.log("exceptions", exceptions.indexOf(value));
      if (exceptions.indexOf(value) !== -1) {
        if (this.payload.mwb.level === "" || this.payload.mwb.level === null) {
          this.exceptionOption = false;
          console.log("exceptions", exceptionOptions.M3[0].text);
          if (!this.getrole("exed2")) {
            this.levels = "M2";
          }
          if (this.getrole("exed2")) {
            this.iimlLevel = "Net Enquiry";
          }
        } else if (
          this.payload.mwb.level === "M3" ||
          this.payload.mwb.level === "M3+" ||
          this.payload.mwb.level === "M3++" ||
          this.payload.mwb.level === "M2" ||
          this.payload.mwb.level === "M1"
        ) {
          this.labelOptions = [
            { label: "", id: "" },
            { label: "M7 : Enrolled", id: "M7" },
            { label: "M6 : Visited & Ready to Enroll", id: "M6" },
            { label: "M5 : Visited & Positive", id: "M5" },
            { label: "M4 : Visited but Postponed", id: "M4" },
            { label: "M4- : Visited but not interested", id: "M4-" },
            { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
            { label: "M3+ : Called & Coming", id: "M3+" },
            { label: "M3 : Called & Positive", id: "M3" },
            {
              label: "M2 : Did not Visit & Postponed",
              id: "M2",
            },
            {
              label: "M1 : Did not Visit & not intersted",
              id: "M1",
            },
          ];
        } else {
          if (this.getTeam("SR")) {
            this.exceptionOption = false;
            this.labelOptions = this.SRlabelOptionsvisit;
            // console.log("fdfsdfsdfsd", this.labelOptions);
          } else {
            if (
              this.payload.mwb.level === "M4-" ||
              this.payload.mwb.level === "M4" ||
              this.payload.mwb.level === "M5" ||
              this.payload.mwb.level === "M6" ||
              this.payload.mwb.level === "L4-" ||
              this.payload.mwb.level === "L4" ||
              this.payload.mwb.level === "L5" ||
              this.payload.mwb.level === "L6" ||
              this.payload.mwb.level === "M7" ||
              this.payload.mwb.level === "N/A" ||
              this.payload.mwb.level === "DND"
            ) {
              this.connected = true;
              this.labelOptions = [
                { label: "", id: "" },
                { label: "M7 : Enrolled", id: "M7" },
                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                { label: "M5 : Visited & Positive", id: "M5" },
                { label: "M4 : Visited but Postponed", id: "M4" },
                { label: "M4- : Visited but not interested", id: "M4-" },
                { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
                { label: "M3+ : Called & Coming", id: "M3+" },
                { label: "M3 : Called & Positive", id: "M3" },
                { label: "M2 : Did not Visit & Postponed", id: "M2" },
                { label: "M1 : Did not Visit & not intersted", id: "M1" },
              ];
            } else {
              this.connected = false;
              this.exceptionOption = false;
              this.labelOptions = this.labelOptionsvisit;
              // console.log("fdfsdfsdfsd 0", this.labelOptions);
            }
          }
          // this.labelOptions = exceptionOptions[this.payload.level];
        }
        // if(this.payload.mwb.level === "" || this.payload.mwb.level === null) {
        //   this.exceptionOption = false;
        //   console.log("exceptions",exceptionOptions.M3[0].text)
        //   // this.labelOptions = [
        //   //   { text: "M3 - Called & Positive", value: "M3" },
        //   // ];
        //   this.levels = "M3";
        //   // if(this.labelOptions.text === "M3 - Called & Positive") {
        //   //   this.levels = "M3 - Called & Positive";
        //   // }
        // } else {
        //    this.exceptionOption = true;

        //   this.labelOptions = exceptionOptions[this.payload.mwb.level];
        // }
      } else {
        if (
          this.payload.mwb.level === "M4-" ||
          this.payload.mwb.level === "M4" ||
          this.payload.mwb.level === "M5" ||
          this.payload.mwb.level === "M6" ||
          this.payload.mwb.level === "L4-" ||
          this.payload.mwb.level === "L4" ||
          this.payload.mwb.level === "L5" ||
          this.payload.mwb.level === "L6" ||
          this.payload.mwb.level === "M7"
          // this.payload.mwb.level === "M7-" ||
          // this.payload.mwb.level === "M8" ||
          // this.payload.mwb.level === "M8-" ||
          // this.payload.mwb.level === "M9" ||
          // this.payload.mwb.level === "M9-" ||
          // this.payload.mwb.level === "M10"
        ) {
          // this.labelOptions = this.labelOptionsvisit;
          if (this.getTeam("SR")) {
            this.labelOptions = [
              { label: "", id: "" },
              { label: "M11 : Received CPA license", id: "M11" },
              { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
              { label: "M12 : Received License Renewal Advice", id: "M12" },
              { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
              { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
              { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
              { label: "M9- : Drop-out (after clearing an exam) (90 days)", id: "M9-" },
              { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
              { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
              { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
              { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
              { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
              { label: "M8- : Drop-out (after evaluation) (90 days)", id: "M8-" },
              { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
              { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
              { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
              { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
              { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
              { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
              { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
              { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
              { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
              { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
              { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
              { label: "M7D1 : Defaulter", id: "M7D1" },
              { label: "M7D2 : Loan Defaulter", id: "M7D2" },
              { label: "M7+ LMS demo completed (90 days)", id: "M7+" },
              { label: "M7# : Onboarding Done (90 days)", id: "M7#" },
              { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
              { label: "M7- : Drop-out (90 days)", id: "M7-" },
              { label: "M7 : Enrolled (90 Days)", id: "M7" },
              { label: "M6 : Visited & Ready to Enroll", id: "M6" },
              { label: "M5 : Visited & Positive", id: "M5" },
              { label: "M4 : Visited but Postponed", id: "M4" },
              { label: "M4- : Visited but not interested", id: "M4-" },
              { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
              { label: "M3+ : Called & Coming", id: "M3+" },
              { label: "M3 : Called & Positive", id: "M3" },
              { label: "M2 : Did not Visit & Postponed", id: "M2" },
              { label: "M1 : Did not Visit & not intersted", id: "M1" },
            ];
            this.level_options = this.labelOptions;
          } else {
            if (
              this.payload.mwb.level === "" ||
              this.payload.mwb.level === null
            ) {
              this.exceptionOption = false;
              console.log("exceptions", exceptionOptions.M3[0].text);
              if (!this.getrole("exed2")) {
                this.levels = "M3";
              }
              if (this.getrole("exed2")) {
                this.iimlLevel = "Net Enquiry";
              }
            } else if (
              this.payload.mwb.level === "M3" ||
              this.payload.mwb.level === "M3+" ||
              this.payload.mwb.level === "M3++" ||
              this.payload.mwb.level === "M2" ||
              this.payload.mwb.level === "M1"
            ) {
              this.labelOptions = [
                { label: "", id: "" },
                { label: "M7 : Enrolled", id: "M7" },
                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                { label: "M5 : Visited & Positive", id: "M5" },
                { label: "M4 : Visited but Postponed", id: "M4" },
                { label: "M4- : Visited but not interested", id: "M4-" },
                { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
                { label: "M3+ : Called & Coming", id: "M3+" },
                { label: "M3 : Called & Positive", id: "M3" },
                {
                  label: "M2 : Did not Visit & Postponed",
                  id: "M2",
                },
                {
                  label: "M1 : Did not Visit & not intersted",
                  id: "M1",
                },
              ];
            } else {
              if (this.getTeam("SR")) {
                this.exceptionOption = true;
                this.labelOptions = this.SRlabelOptionsvisit;
                // console.log("fdfsdfsdfsd 1", this.labelOptions);
              } else {
                if (
                  this.payload.mwb.level === "M4-" ||
                  this.payload.mwb.level === "M4" ||
                  this.payload.mwb.level === "M5" ||
                  this.payload.mwb.level === "M6" ||
                  this.payload.mwb.level === "L4-" ||
                  this.payload.mwb.level === "L4" ||
                  this.payload.mwb.level === "L5" ||
                  this.payload.mwb.level === "L6" ||
                  this.payload.mwb.level === "M7" ||
                  this.payload.mwb.level === "N/A" ||
                  this.payload.mwb.level === "DND"
                ) {
                  this.connected = true;
                  this.labelOptions = [
                    { label: "", id: "" },
                    { label: "M7 : Enrolled", id: "M7" },
                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                    { label: "M5 : Visited & Positive", id: "M5" },
                    { label: "M4 : Visited but Postponed", id: "M4" },
                    { label: "M4- : Visited but not interested", id: "M4-" },
                    { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
                    { label: "M3+ : Called & Coming", id: "M3+" },
                    { label: "M3 : Called & Positive", id: "M3" },
                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                  ];
                } else {
                  this.connected = false;
                  this.exceptionOption = false;
                  this.labelOptions = this.labelOptionsvisit;
                  // console.log("fdfsdfsdfsd 2", this.labelOptions);
                }
              }
            }
          }
        } else {
          if (this.getTeam("SR")) {
            this.labelOptions = [
              { label: "", id: "" },
              { label: "M11 : Received CPA license", id: "M11" },
              { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
              { label: "M12 : Received License Renewal Advice", id: "M12" },
              { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
              { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
              { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
              { label: "M9- : Drop-out (after clearing an exam) (90 days)", id: "M9-" },
              { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
              { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
              { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
              { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
              { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
              { label: "M8- : Drop-out (after evaluation) (90 days)", id: "M8-" },
              { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
              { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
              { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
              { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
              { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
              { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
              { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
              { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
              { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
              { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
              { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
              { label: "M7D1 : Defaulter", id: "M7D1" },
              { label: "M7D2 : Loan Defaulter", id: "M7D2" },
              { label: "M7+ LMS demo completed (90 days)", id: "M7+" },
              { label: "M7# : Onboarding Done (90 days)", id: "M7#" },
              { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
              { label: "M7- : Drop-out (90 days)", id: "M7-" },
              { label: "M7 : Enrolled (90 Days)", id: "M7" },
              { label: "M6 : Visited & Ready to Enroll", id: "M6" },
              { label: "M5 : Visited & Positive", id: "M5" },
              { label: "M4 : Visited but Postponed", id: "M4" },
              { label: "M4- : Visited but not interested", id: "M4-" },
              { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
              { label: "M3+ : Called & Coming", id: "M3+" },
              { label: "M3 : Called & Positive", id: "M3" },
              { label: "M2 : Did not Visit & Postponed", id: "M2" },
              { label: "M1 : Did not Visit & not intersted", id: "M1" },
            ];
            this.level_options = this.labelOptions;
          } else {
            if (
              this.payload.mwb.level === "" ||
              this.payload.mwb.level === null
            ) {
              this.exceptionOption = false;
              console.log("exceptions", exceptionOptions.M3[0].text);
              if (!this.getrole("exed2")) {
                this.levels = "M2";
              }
              if (this.getrole("exed2")) {
                this.iimlLevel = "Net Enquiry";
              }
            } else if (
              this.payload.mwb.level === "M3++" ||
              this.payload.mwb.level === "M3" ||
              this.payload.mwb.level === "M3+" ||
              this.payload.mwb.level === "M2" ||
              this.payload.mwb.level === "M1"
            ) {
              this.labelOptions = [
                { label: "", id: "" },
                { label: "M7 : Enrolled", id: "M7" },
                { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                { label: "M5 : Visited & Positive", id: "M5" },
                { label: "M4 : Visited but Postponed", id: "M4" },
                { label: "M4- : Visited but not interested", id: "M4-" },
                { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
                { label: "M3+ : Called & Coming", id: "M3+" },
                { label: "M3 : Called & Positive", id: "M3" },
                {
                  label: "M2 : Did not Visit & Postponed",
                  id: "M2",
                },
                {
                  label: "M1 : Did not Visit & not intersted",
                  id: "M1",
                },
              ];
            } else {
              if (this.getTeam("SR")) {
                this.exceptionOption = true;
                this.labelOptions = this.SRlabelOptionsvisit;
                // console.log("fdfsdfsdfsd 3", this.labelOptions);
              } else {
                if (
                  this.payload.mwb.level === "M4-" ||
                  this.payload.mwb.level === "M4" ||
                  this.payload.mwb.level === "M5" ||
                  this.payload.mwb.level === "M6" ||
                  this.payload.mwb.level === "L4-" ||
                  this.payload.mwb.level === "L4" ||
                  this.payload.mwb.level === "L5" ||
                  this.payload.mwb.level === "L6" ||
                  this.payload.mwb.level === "M7" ||
                  this.payload.mwb.level === "N/A" ||
                  this.payload.mwb.level === "DND"
                ) {
                  this.connected = true;
                  this.labelOptions = [
                    { label: "", id: "" },
                    { label: "M7 : Enrolled", id: "M7" },
                    { label: "M6 : Visited & Ready to Enroll", id: "M6" },
                    { label: "M5 : Visited & Positive", id: "M5" },
                    { label: "M4 : Visited but Postponed", id: "M4" },
                    { label: "M4- : Visited but not interested", id: "M4-" },
                    { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
                    { label: "M3+ : Called & Coming", id: "M3+" },
                    { label: "M3 : Called & Positive", id: "M3" },
                    { label: "M2 : Did not Visit & Postponed", id: "M2" },
                    { label: "M1 : Did not Visit & not intersted", id: "M1" },
                  ];
                } else {
                  this.connected = false;
                  this.exceptionOption = false;
                  this.labelOptions = this.labelOptionsvisit;
                  // console.log("fdfsdfsdfsd 4", this.labelOptions);
                }
              }
            }
            // this.labelOptions = [
            //   { text: "", value: "" },
            //   { text: "M7 - Enrolled", value: "M7" },

            //   { text: "M6 - Visited & Ready to Enroll", value: "M6" },
            //   { text: "M5 - Visited & Positive", value: "M5" },
            //   { text: "M4 - Visited but Postponed", value: "M4" },
            //   { text: "M4- - Visited but not interested", value: "M4-" },
            //   { text: "M3+ - Called & Coming", value: "M3+" },
            //   { text: "M3 - Called & Positive", value: "M3" },
            //   { text: "M2 - Did not Visit & Postponed", value: "M2" },
            //   { text: "M1 - Did not Visit & not intersted", value: "M1" },
            // ];
          }
        }
      }
      if (this.course.includes("IIML-FT") && !this.course.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", 1, -2)
      } else if (!this.course.includes("IIML-FT") && this.course.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", -1, 2)
      } else if (this.course.includes("IIML-FT") && this.course.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", 1, 2)
      } else if (!this.course.includes("IIML-FT") && !this.course.includes("IIML-SF")) {
        this.levelOptions();
        console.log("condition", -1, -2)
      }
    },
    responseSelection: function (val) {
      switch (val) {
        case "sameResponse":
          this.textAre = this.payload.mwb.engagement_details;
          break;
        case "noResponse":
          this.textAre = "Not Reachable";
          break;
        case "new":
          this.textAre = "";
          break;
      }
    },
    escalation_boolean() {
      this.selected_team = "";
      this.selected_escalation_member = null;
      this.escalation_comments = "";
    },
    course: function (val) {
      console.log("selecte courses outside 1", val, this.course);
      if (val.includes("CFA")) {
        console.log("selecte courses - 1");
        this.show_cfa_dropdown = true;
      }
      if (!val.includes("CFA")) {


        this.show_cfa_dropdown = false;
        for (let i = 0; i < this.course.length; i++) {
          const element = this.course[i];
          for (let i = 0; i < this.cfa_levels.length; i++) {
            const level = this.cfa_levels[i].value;
            if (level === element) {
              this.course.splice(this.course.indexOf(element), 1);
            }
          }
        }
        console.log("selecte courses - 2", this.course);
      }
      if (val.includes("FRM")) {
        console.log("selecte courses - 3");

        this.show_frm_dropdown = true;
      }
      if (!val.includes("FRM")) {


        this.show_frm_dropdown = false;
        for (let i = 0; i < this.course.length; i++) {
          const element = this.course[i];
          for (let i = 0; i < this.frm_parts.length; i++) {
            const level = this.frm_parts[i].value;
            if (level === element) {
              this.course.splice(this.course.indexOf(element), 1);
            }
          }
        }
        console.log("selecte courses - 4", this.course);
      }
      if (val.includes("IIML-FT") && !val.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", 1, -2)
      } else if (!val.includes("IIML-FT") && val.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", -1, 2)
      } else if (val.includes("IIML-FT") && val.includes("IIML-SF")) {
        this.M6LevelOptions();
        console.log("condition", 1, 2)
      } else if (!val.includes("IIML-FT") && !val.includes("IIML-SF")) {
        this.levelOptions();
        console.log("condition", -1, -2)
      }

      this.send_intro_options = val;
      this.webinar_options = val;

      console.log("selecte courses outside 2", val, this.course);
    },
    // type_of_nfd(val){
    //   if(val == 'm_nfd'){
    //     this.selectedMLevel(this.levels);
    //   }
    //   else if(val == 'u_nfd'){
    //     this.isActive = false
    //     this.selectedULevel(this.selected_macc_level);
    //   }

    // }
  },
  computed: {
    getWhatsappMessage() {
      let status = false;
      return status;
    },
  },
  data() {
    return {
      schedule_status:false,
      visit_found: 0,
      upcoming_visits_date: '',
      mwb_courses: [],
      loan_status:false,
      level_verify: false,
      collection_team: false,
      selected_connection_status: false,
      macc_popup :  false,
      pathway: "",
      hide_select: true,
      selected_macc_level: "",
      selectedLevel: '',
      show_macc_level: false,
      macc_levels: [
        // { value: "",  text: ""},
        { id: "U0--", label: "U0-- : Not Eligible Lead", level_priority: 1 },
        { id: "U0", label: "U0 : Not interested in US/Canda", level_priority: 1 },
        { id: "U1", label: "U1 : Interest expressed, dropped out", level_priority: 1 },
        { id: "U1+", label: "U1+ : Interest expressed, yet to graduate", level_priority: 1 },
        { id: "U2", label: "U2 : Interest expressed, in pipeline (warm)", level_priority: 1 },
        { id: "U3-", label: "U3- : Postponed", level_priority: 1 },
        { id: "U3", label: "U3 : Interest expressed, in pipeline (hot)", level_priority: 1 },
        { id: "U3+", label: "U3+ : Zoom Attended(Very Hot)", level_priority: 1 },
        { id: "U3++", label: "U3++ : Ready to Enroll", level_priority: 1 },
        { id: "U4", label: "U4 : Visited but Postpone/Not Interested", level_priority: 2 },
        { id: "U5", label: "U5 : Visited and Very Interested", level_priority: 2 },
        { id: "U6", label: "U6 : Visited and Ready to Enroll", level_priority: 2 },
        { id: "U7-", label: "U7- : Application initiated, dropped out", level_priority: 3 },
        { id: "U7R", label: "U7R : Refunded 20k", level_priority: 3 },
        { id: "U7", label: "U7 : Enrolled (20k)", level_priority: 3 },
        { id: "U7+", label: "U7+ : Application Initiated", level_priority: 3 },
        { id: "U8", label: "U8 : Application done, I-20 yet to be received", level_priority: 4 },
        { id: "U8+", label: "U8+ : Conditional Offer Received", level_priority: 4 },
        { id: "U9-", label: "U9- : I-20 received, Dropped Out", level_priority: 4 },
        { id: "U9", label: "U9 : I 20 Rec. Yet to apply for Visa", level_priority: 4 },
        { id: "U9+", label: "U9+ : I 20 Rec. Applied for Visa", level_priority: 4 },
        { id: "U10-", label: "U10- : Visa Received But Dropped out", level_priority: 4 },
        { id: "U10", label: "U10 : Visa received", level_priority: 4 },
        { id: "U11", label: "U11 : IIM Indore", level_priority: 4 },
        { id: "U11+", label: "U11+ : Started program in USA", level_priority: 4 },
        { id: "U12", label: "U12 : Completed program in USA", level_priority: 4 },
      ],
      meet_and_greet_popup: false,
      address: null,
      location: "",
      schedule_time: {
        HH: null,
        mm: null,
      },
      spoc_cities: [],
      transfer_spoc_cities: [],
      spocs_tree_data: [],
      select_value: null,
      transfer_spocs_tree_data: [],
      show_cfa_dropdown: false,
      show_frm_dropdown: false,
      selected_cfa_level: "",
      selected_frm_parts: "",
      PrimaryMobile: "",
      PrimaryEmail: "",
      WlieyPersonName: "",
      SelectedCOurse: "",
      popupOpen: false,
      text: "",
      wileyCourseArray: [],
      wiley_course: "",
      onlygooglemeetbutton: false,
      registeringforwebinar: "",
      webinar_options: [],
      webinarIntro: "",
      sendIntrocourse: "",
      send_intro_options: [],
      webinarCourse: "CPA",
      exceptionOption: false,
      /* For Escalations */
      teams: [
        { text: "CM Team", value: "cm_team" },
        { text: "Acads Team", value: "acads_team" },
        { text: "IIML Team", value: "iiml_team" },
        { text: "SR Team", value: "sr_team" },
        { text: "GM Team", value: "gm_team" },
      ],
      transfer_boolean: false,
      transfer_teams: [
        { text: "CM Team", value: "cm_team" },
        { text: "SR Team", value: "sr_team" },
      ],
      selected_transfer_team: "",
      visible_transfer_members: [],
      selected_transfer_member: null,
      selected_team: "",
      visible_members: [],
      selected_escalation_member: null,
      escalationsObj: {},
      escalation_comments: "",
      selected_second_visit_team: "",
      visible_second_visit_members: [],
      selected_second_visit_member: null,
      second_visit_teams: [
        { text: "CM Team", value: "cm_team" },
        { text: "Acads Team", value: "acads_team" },
        // { text: "IIML Team", value: "iiml_team" },
        { text: "SR Team", value: "sr_team" },
        { text: "GM Team", value: "gm_team" },
      ],
      second_level_preload: {
        iiml_team: [
          {
            id: 216,
            first_name: "Anoop",
            team: "iiml_team",
          },
          {
            id: 62,
            first_name: "Micheal",
            team: "iiml_team",
          },
          {
            id: 41,
            first_name: "Akansha",
            team: "iiml_team",
          },
          {
            id: 67,
            first_name: "Kiran",
            team: "iiml_team",
          },
        ],
        cm_team: [
          // {
          //   id: 1,
          //   first_name: "Varun",
          //   team: "cm_team",
          // },
          {
            id: 122,
            first_name: "Meenakshi",
            team: "cm_team",
          },
          {
            id: 132,
            first_name: "Nida",
            team: "cm_team",
          },
          {
            id: 68,
            first_name: "Vasudha",
            team: "cm_team",
          },
          {
            id: 199,
            first_name: "Nazia",
            team: "cm_team",
          },
          {
            id: 103,
            first_name: "Ayisha",
            team: "cm_team",
          },
          {
            id: 133,
            first_name: "Mohit",
            team: "cm_team",
          },
          {
            id: 76,
            first_name: "Ismail",
            team: "cm_team",
          },
          {
            id: 75,
            first_name: "Imran",
            team: "cm_team",
          },
          {
            id: 83,
            first_name: "Roopa",
            team: "cm_team",
          },
        ],
      },
      second_visit_boolean: false,
      second_visit_comments: "",
      /* Ends Here */
      phone_number: ``,
      whatsapp_message: "",
      cfa_levels: [
        { text: "CFA-L1", value: "CFA-L1" },
        { text: "CFA-L2", value: "CFA-L2" },
        { text: "CFA-L3", value: "CFA-L3" },
      ],
      frm_parts: [
        { text: "FRM-P1", value: "FRM-P1" },
        { text: "FRM-P2", value: "FRM-P2" },
      ],
      iimlLevel: this.payload.mwb.iiml_level,
      iimlLevels: [
        { label: "R1 : Not Interested", id: "R1" },
        { label: "R2: Interested but Postponed", id: "R2" },
        { label: "R2- : Untapped Not Lifting", id: "R2-" },
        { label: "R3- : Net Enquiry Not Lifting", id: "R3-" },
        { label: "R3 : Interested", id: "R3" },
        { label: "R3+ : Received CV", id: "R3+" },
        { label: "R4 : Submitted documents and Postponed", id: "R4" },
        { label: "R5 : Shortlisted", id: "R5" },
        { label: "R5- : Profile Rejected", id: "R5-" },
        { label: "R6 : Assessment/Interview Cleared", id: "R6" },
        { label: "R6- : Assessment/Interview Rejected", id: "R6-" },
        { label: "R7 : Offer letter received", id: "R7" },
        { label: "R7+ : Documents BGV initiated", id: "R7+" },
        { label: "R7- : Backout", id: "R7-" },
        { label: "R8 : Placed/On boarded", id: "R8" },
        { label: "R8+ : Due to Bill", id: "R8+" },
        { label: "R8- : Replacement", id: "R8-" },
        { label: "RX : Never to Call", id: "RX" },
        // { text: "Cold", value: "Cold" },
        // { text: "Hot", value: "Hot" },
        // { text: "Warm", value: "Warm" },
        // { text: "Very Hot", value: "Very Hot" },
        // { text: "Application Received", value: "Application Received" },
        // { text: "Exam-Pass", value: "Exam-Pass" },
        // { text: "Exam-Fail", value: "Exam-Fail" },
        // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
        // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
        // { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },
        // // { text: "Enquiry", value: "Enquiry" },
        // { text: "RPO", value: "RPO" },
        // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
        // { text: "IIML-SF-M7", value: "IIML-SF-M7" },
        // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
        // { text: "IIMI-BA-M7", value: "IIMI-BA-M7" },
        // { text: "IIML-BA-M7", value: "IIML-BA-M7" },
        // { text: "IIML-PM-M7", value: "IIML-PM-M7" },
        // { text: "IIML-HR-M7", value: "IIML-HR-M7" },
        // { text: "IITR-BF-M7", value: "IITR-BF-M7" },
        // { text: "IITR-DB-M7", value: "IITR-DB-M7" },
        // { text: "IITM-AA-M7", value: "IITM-AA-M7" },
        // { text: "IIMK-CX-M7", value: "IIMK-CX-M7" },
        // { text: "IITM-FS-M7", value: "IITM-FS-M7" },
        // { text: "IITR-CC-M7", value: "IITR-CC-M7" },
        // { text: "IIMK-FT-M7", value: "IIMK-FT-M7" },
        // { text: "IIML-AB-M7", value: "IIML-AB-M7" },
        // { text: "IIML-SH-M7", value: "IIML-SH-M7" },
        // { text: "IITJ-DE-M7", value: "IITJ-DE-M7" },
        // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },

        // { text: "XLRI-DM-M7", value: "XLRI-DM-M7" },
        // { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
      ],
      exed_levels: [
        { label: "Cold", id: "Cold" },
        { label: "Hot", id: "Hot" },
        { label: "Warm", id: "Warm" },
        { label: "Very Hot", id: "Very Hot" },
        { label: "Application Received", id: "Application Received" },
        // { text: "Exam-Pass", value: "Exam-Pass" },
        // { text: "Exam-Fail", value: "Exam-Fail" },
        // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
        // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
        // { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },
        // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
        // { text: "IIMI-BA-M7", value: "IIMI-BA-M7" },
        // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
        { label: "Net Enquiry", id: "Net Enquiry" },
        { label: "Busy/NL", id: "Busy/NL" },
        { label: "Application Approved", id: "Application Approved" },
        { label: "Enrolled", id: "Enrolled" },
        { label: "Final Payment Received", id: "Final payment Received" },
        { label: "NA", id: "NA" },
      ],
      exed_level: "",
      checkboxdisable: false,
      tagVisible: true,
      netEnquiryDetails: "",
      // arr: [
      //   {
      //     name: "Connected, Busy",
      //     value: 0,
      //     form: "CB",
      //   },
      //   {
      //     name: "Connected, Never call back",
      //     value: 0,
      //     form: "CN",
      //   },
      //   {
      //     name: "Connected, Wrong Number",
      //     value: 0,
      //     form: "CW",
      //   },
      //   {
      //     name: "Busy",
      //     value: 0,
      //     form: "B",
      //   },
      //   {
      //     name: "Not Lifting",
      //     value: 0,
      //     form: "NL",
      //   },
      //   {
      //     name: "Not Reachable",
      //     value: 0,
      //     form: "NR",
      //   },
      //   {
      //     name: "Disconnected",
      //     value: 0,
      //     form: "D",
      //   },
      //   {
      //     name: "Invalid Number",
      //     value: 0,
      //     form: "IN",
      //   },
      //   {
      //     name: "Switched Off",
      //     value: 0,
      //     form: "SO",
      //   },
      //   {
      //     name: "Connected, Not Interested",
      //     value: 0,
      //     form: "CNI",
      //   },
      //   {
      //     name: "Communication barrier",
      //     value: 0,
      //     form: "CMB",
      //   },
      //   {
      //     name: "Not Educated",
      //     value: 0,
      //     form: "NE",
      //   },
      // ],
      arr: [
        {
          name: "Visit Confirmed",
          value: 0,
          form: "VCNF",
        },
        {
          name: "Visit Completed",
          value: 0,
          form: "VCMP",
        },
        {
          name: "Visit Rescheduled",
          value: 0,
          form: "VRE",
        },
        {
          name: "Visit Cancelled",
          value: 0,
          form: "VCNL",
        },
        {
          name: "Not Connected(NL/SO/NR)",
          value: 0,
          form: "NC",
        },
        {
          name: "Booked Visit By Mistake",
          value: 0,
          form: "BVBM",
        },
        {
          name: "Invalid Number/Wrong number",
          value: 0,
          form: "INWN",
        },
        {
          name: "Connected, Not Interested",
          value: 0,
          form: "CNI",
        },
        {
          name: "Communication barrier",
          value: 0,
          form: "CMB",
        },
        {
          name: "Not Educated",
          value: 0,
          form: "NE",
        },
        {
          name: "Connected/ Call Back",
          value: 0,
          form: "CCB",
        },
      ],
      indexActive: 0,
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 5),
        from: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 26),
      },
      connected: false,
      iimlConnected: false,
      escalation_connect: false,
      escalations_visit: false,
      secondvisit_connect: false,
      show: false,
      disable: true,
      level_priority_condition:false,
      level_defaulter_condition:false,
      defaulter_level:false,
      // options1: [
      //   { text: "", value: 1 },
      //   { text: "Connected / Discussed", value: 2 },
      //   { text: "Connected / Busy", value: 3 },
      //   { text: "Connected / Never call back", value: 4 },
      //   { text: "Connected / Wrong Number", value: 4 },
      //   { text: "Connected / Not Interested", value: 11 },
      //   { text: "Busy", value: 5 },
      //   { text: "Not Lifting", value: 6 },
      //   { text: "Not Reachable", value: 7 },
      //   { text: "Disconnected", value: 8 },
      //   { text: "Invalid Number", value: 9 },
      //   { text: "Switched Off", value: 10 },
      //   { text: "Communication barrier", value: 11 },
      //   { text: "Not Educated", value: 12 },
      // ],
      options1: [
        // { text: "", value: 1 },
        { text: "Visit Confirmed", value: 2 },
        { text: "Visit Completed", value: 3 },
        { text: "Visit Rescheduled", value: 4 },
        { text: "Visit Cancelled", value: 5 },
        { text: "Booked Visit By Mistake", value: 6 },
        { text: "Communication barrier", value: 7 },
        { text: "Not Educated", value: 8 },
        { text: "Not Connected(NL/SO/NR)", value: 9 },
        { text: "Connected / Not Interested", value: 10 },
        { text: "Invalid Number/Wrong number", value: 11 },
        { text: "Connected/ Call Back", value: 12 },
      ],
      connected_status: ["Connected / Discussed", "Connected / Not Interested", "Visit Confirmed","Visit Completed", "Visit Rescheduled"],
      current_location: "",
      dates: "",
      course: [],
      levels: "",
      textAre: "",
      phone_number_toupdate: "",
      responseSelection: "",
      select1: "",
      latestEngagement: "",
      navTitleName: "",
      allpage: true,
      visitpage: false,
      callpage: false,
      whatsapp_page: false,
      emailpage: false,
      lastengagement: "",
      details: "",
      labelOptionsvisit: [
        { label: "", id: "" },
        { label: "M11 : Received CPA license", id: "M11" },
        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
        { label: "M12 : Received License Renewal Advice", id: "M12" },
        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
        { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-" },
        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
        { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
        { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-" },
        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
        { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X" },
        { label: "M7D2 : Loan Defaulter", id: "M7D2" },
        { label: "M7D1 : Defaulter", id: "M7D1" },
        { label: "M7+ : LMS demo completed (30-60 days)", id: "M7+" },
        { label: "M7- : Drop-out (30 days)", id: "M7-" },
        { label: "M7# : Onboarding Done (30-60 days)", id: "M7#" },
        { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ" },
        { label: "M7 : Enrolled", id: "M7" },
        { label: "M7- : Drop-out (30 days)", id: "M7-" },
        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
        { label: "M5 : Visited & Positive", id: "M5" },
        { label: "M4 : Visited but Postponed", id: "M4" },
        { label: "M4- : Visited but not interested", id: "M4-" },
      ],
      SRlabelOptions: [
        { label: "", id: "" },
        { label: "M11 : Received CPA license", id: "M11" },
        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
        { label: "M12 : Received License Renewal Advice", id: "M12" },
        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
        { label: "M9- : Drop-out (after clearing an exam)", id: "M9-" },
        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
        { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
        { label: "M8- : Drop-out (after evaluation)", id: "M8-" },
        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
        { label: "M7 - INT  ", id: "M7-INT"},
        { label: "M7-IR", id: "M7-IR"},
        { label: "M7X : Do Not Ever Call", id: "M7X" },
        { label: "M7D1 : Miles Defaulters", id: "M7D1" },
        { label: "M7D2 : Loan Defaulters", id: "M7D2" },
        { label: "M7+ : LMS demo completed", id: "M7+" },
        { label: "M7# : Onboarding Done", id: "M7#" },
        { label: "M7-JJ : Non Miles Jain Enrolled Candidates", id: "M7-JJ"},
        { label: "M7- : Drop-out", id: "M7-" },
        { label: "M7 : Enrolled", id: "M7" },
        // { text: "M7- - Drop-out (30 days)", value: "M7-" },
        { label: "M6 : Visited & Ready to Enroll", id: "M6" },
        { label: "M5 : Visited & Positive", id: "M5" },
        { label: "M4 : Visited but Postponed", id: "M4" },
        { label: "M4- : Visited but not interested", id: "M4-" },
        { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
        { label: "M3+ : Called & Coming", id: "M3+" },
        { label: "M3 : Called & Positive", id: "M3" },
        { label: "M2 : Did not Visit & Postponed", id: "M2" },
        { label: "M1 : Did not Visit & not intersted", id: "M1" },
      ],
      SRlabelOptionsvisit: [
        { text: "", value: "" },
        { text: "M11 : Received CPA license", value: "M11" },
        { text: "M11# : Yet to recieve License renewal advice from Miles", value: "M11#" },
        { text: "M12 : Received License Renewal Advice", value: "M12" },
        { text: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", value: "M10" },
        { text: "M10# : Received license advice from Miles; License application WIP", value: "M10#" },
        { text: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", value: "M9" },
        { text: "M9- : Drop-out (after clearing an exam) (90days)", value: "M9-" },
        { text: "M9+1 : Received NTS rescheduling advice from Miles for other exams", value: "M9+1" },
        { text: "M9+2 : Received New NTS; Yet to receive approval by GP", value: "M9+2" },
        { text: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", value: "M9+3" },
        { text: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", value: "M8" },
        { text: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", value: "M8+" },
        { text: "M8- : Drop-out (after evaluation) (90 days)", value: "M8-" },
        { text: "M8#1 : Enrolled:Yet to recive evaluation advice", value: "M8#1" },
        { text: "M8#1- : Incomplete Documents; Unable to release evaluation advice", value: "M8#1-" },
        { text: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", value: "M8#1+" },
        { text: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", value: "M8#2" },
        { text: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", value: "M8+1" },
        { text: "M8+2 : Applied for NTS; Yet to receive NTS", value: "M8+2" },
        { text: "M8+3 : Received NTS; Yet to receive exam scheduling advice", value: "M8+3" },
        { text: "M8+4 : Received exam scheduling advice; Yet to schedule exam", value: "M8+4" },
        { text: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", value: "M8+5" },
        { text: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", value: "M8+6" },
        { text: "M7X : Do Not Ever Call (NFD - Blank)", value: "M7X" },
        { text: "M7D1 : Defaulter", value: "M7D1" },
        { text: "M7D2 : Loan Defaulter", value: "M7D2" },
        { text: "M7+ : LMS demo completed (90 days)", value: "M7+" },
        { text: "M7# : Onboarding Done (90 days)", value: "M7#" },
        { text: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", value: "M7-JJ" },
        { text: "M7- : Drop-out (90 days)", value: "M7-" },
        { text: "M7 : Enrolled (90 days)", value: "M7" },
        // { text: "M7- - Drop-out (30 days)", value: "M7-" },
        { text: "M6 : Visited & Ready to Enroll", value: "M6" },
        { text: "M5 : Visited & Positive", value: "M5" },
        { text: "M4 : Visited but Postponed", value: "M4" },
        { text: "M4- : Visited but not interested", value: "M4-" },
        { label: "M3++ : Ready to enroll - Not visited", id: "M3++" },
        { label: "M3+ : Called & Coming", id: "M3+" },
        { label: "M3 : Called & Positive", id: "M3" },
        { label: "M2 : Did not Visit & Postponed", id: "M2" },
        { label: "M1 : Did not Visit & not intersted", id: "M1" },
      ],
      labelOptions: [
        { label: "", id: "" },
        { label: "M11 : Received CPA license", id: "M11" },
        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
        { label: "M12 : Received License Renewal Advice", id: "M12" },
        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10" },
        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#" },
        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9" },
        { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)",id: "M9-" },
        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1" },
        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2" },
        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3" },
        { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8" },
        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+" },
        { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-" },
        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1" },
        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-" },
        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+" },
        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2" },
        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1" },
        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2" },
        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3" },
        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4" },
        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5" },
        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6" },
        { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X", level_priority: 1 },
        { label: "M7D2 : Loan Defaulter", id: "M7D2", level_priority: 10 },
        { label: "M7D1 : Defaulter", id: "M7D1", level_priority: 10 },
        { label: "M7+ LMS demo completed (30-60 days)", id: "M7+", level_priority: 1 },
        { label: "M7- : Drop-out (30 days)", id: "M7-", level_priority: 1 },
        { label: "M7# : Onboarding Done (30-60 days)", id: "M7#", level_priority: 1 },
        { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ", level_priority: 1 },
        { label: "M7 : Enrolled", id: "M7", level_priority: 3 },
        { label: "M6 : Visited & Ready to Enroll", id: "M6", level_priority: 2 },
        { label: "M5 : Visited & Positive", id: "M5", level_priority: 2 },
        { label: "M4 : Visited but Postponed", id: "M4", level_priority: 2 },
        { label: "M4- : Visited but not interested", id: "M4-", level_priority: 2 },
        { label: "M3+ : Called & Coming", id: "M3+", level_priority: 1 },
        { label: "M3++ : Ready to enroll - Not visited", id: "M3++", level_priority: 1 },
        { label: "M3 : Called & Positive", id: "M3", level_priority: 1 },
        { label: "M2 : Did not Visit & Postponed", id: "M2", level_priority: 1 },
        { label: "M1 : Did not Visit & not intersted", id: "M1", level_priority: 1 },
      ],
      level_options: [
        { label: "M1 : Did not Visit & not intersted", id: "M1", level_priority: 1 },
        { label: "M2 : Did not Visit & Postponed", id: "M2", level_priority: 1 },
        { label: "M3 : Called & Positive", id: "M3", level_priority: 1 },
        { label: "M3+ : Called & Coming", id: "M3+", level_priority: 1 },
        { label: "M3++ : Ready to enroll - Not visited", id: "M3++", level_priority: 1 },
        { label: "M4 : Visited but Postponed", id: "M4", level_priority: 2 },
        { label: "M4- : Visited but not interested", id: "M4-", level_priority: 2 },
        { label: "M5 : Visited & Positive", id: "M5", level_priority: 2 },
        { label: "M6  : Visited & Ready to Enroll", id: "M6", level_priority: 2 },
        { label: "M7 : Enrolled", id: "M7", level_priority: 3 },
        { label: "M7D1 : Defaulter", id: "M7D1", level_priority: 10 },
        { label: "M7D2 : Loan Defaulter", id: "M7D2", level_priority: 10 },
        { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ", level_priority: 4 },
        { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X", level_priority: 4 },
        { label: "M7- : Drop-out (30 days)", id: "M7-", level_priority: 4 },
        { label: "M7+ LMS demo completed (30-60 days)", id: "M7+", level_priority: 5 },
        { label: "M7# : Onboarding Done (30-60 days)", id: "M7#", level_priority: 5 },
        { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8", level_priority: 5 },
        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+", level_priority: 6 },
        { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-", level_priority: 7 },
        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1", level_priority: 5 },
        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-", level_priority: 5 },
        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+", level_priority: 5 },
        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2", level_priority: 5 },
        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1", level_priority: 6  },
        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2", level_priority: 6  },
        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3", level_priority: 6  },
        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4", level_priority: 6  },
        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5", level_priority: 6  },
        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6", level_priority: 6  },
        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9", level_priority: 8 },
        { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-", level_priority: 8 },
        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1", level_priority: 8 },
        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2", level_priority: 8 },
        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3", level_priority: 8 },
        { label: "M11 : Received CPA license", id: "M11" },
        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
        { label: "M12 : Received License Renewal Advice", id: "M12" },
        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10", level_priority: 9 },
        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#", level_priority: 9 },
        { label: "M7-IR  ", id: "M7-IR", level_priority: 11 },
        { label: "M7-INT  ", id: "M7-INT", level_priority: 10 },
      ],
      escalation_boolean: false,
      escalation_acads_member: "",
      escaltionOptions: [
        { text: "", value: "" },
        { text: "Samiya Zafar", value: 114 },
        { text: "Seet Mumbai", value: 170 },
        { text: "Etisha", value: 70 },
        { text: "Namitha", value: 82 },
        { text: "Pallavi", value: 87 },
        // { text: "Seet Mumbai", value: 170 }
      ],
      second_visit: false,
      second_visit_select: "",
      secondvisitOptions: [
        { text: "", value: "" },
        { text: "Samiya Zafar", value: 114 },
        // { text: "Seet Mumbai", value: 170 },
        { text: "Etisha", value: 70 },
        { text: "Namitha", value: 82 },
        { text: "Pallavi", value: 87 },
      ],
      visit_escalation: "",
      escalation_visit: false,
      escaltionvisitOptions: [
        { text: "", value: "" },
        { text: "Esccalation_1", value: "Esccalation_1" },
        { text: "Esccalation_2", value: "Esccalation_2" },
        { text: "Esccalation_3", value: "Esccalation_3" },
        { text: "Esccalation_4", value: "Esccalation_4" },
      ],
      whatsapp_information: [],
      whatsapp_options: [
        {
          course: "CPA",
          name: "CPA Game Plan",
          message: "",
        },
        {
          course: "CPA-Message-2",
          name: "CPA Webinar",
          message: "",
        },
        {
          course: "CMA",
          name: "CMA Game Plan",
          message: "",
        },
        {
          course: "CFA",
          name: "CFA Game Plan",
          message: "",
        },
        {
          course: "FRM",
          name: "FRM Game Plan",
          message: "",
        },
        // {
        //   course: "MAcc",
        //   name: "MAcc Game Plan",
        //   message: "",
        // },
        {
          course: "USP",
          name: "USP Game Plan",
          message: "",
        },
        {
          course: "CMA-Webinar",
          name: "CMA Webinar",
          message: "",
        },
        {
          course: "IIML-FA",
          name: "IIML-FA Message",
          message: "",
        },
        {
          course: "IIML-SF",
          name: "IIML-SF Message",
          message: "",
        },
        {
          course: "IIML-FT",
          name: "IIML-FT Message",
          message: "",
        },
      ],
      selected_template: {},
      whatsApptext: "Send Whatsapp Message",
      typeSelction: "",
      courseSelection: "",
      connection_status: false,
      latestDate: "",
      enrollment: false,
      save_loading: false,
      save_and_whatsapp_loading: false,
      all_loading: false,
      type_of_next_call: "office",
      schedule_call_popup: false,
      date_time: null,
      scheduled_call: false,
      f2f_popup: false,
      accelerator_popup: false,
      persona_details: {},
      personna_data: false,
      old_persona_data: {},
      new_persona_data: {},
      persona: "",
      enrolled_courses: [],
      type_of_nfd: 'm_nfd',
      isActive: true,
      show_schedule_call: false,
    };
  },
  methods: {
    changedULevel(val){
     let u_levels = ['U2','U3','U3+','U3++'];
      if(u_levels.includes(val)){
        if(this.levels == 'M1' || this.levels == 'M2'){
          this.levels = 'M3'
        }else if(this.levels == 'M4' || this.levels == 'M4-'){
          this.levels = 'M5'
        }
      }else if(this.select1 != 'Communication barrier' && this.select1 != 'Not Educated' && this.select1 != 'Invalid Number/Wrong number'){
        this.levels = this.payload.mwb.level;
      }
    },
    M6LevelOptions() {
      this.level_options = [
        { label: "M1 : Did not Visit & not intersted", id: "M1", level_priority: 1 },
        { label: "M2 : Did not Visit & Postponed", id: "M2", level_priority: 1 },
        { label: "M3 : Called & Positive", id: "M3", level_priority: 1 },
        { label: "M3+ : Called & Coming", id: "M3+", level_priority: 1 },
        { label: "M3++ : Ready to enroll - Not visited", id: "M3++", level_priority: 1 },
        { label: "M4 : Visited but Postponed", id: "M4", level_priority: 2 },
        { label: "M4- : Visited but not interested", id: "M4-", level_priority: 2 },
        { label: "M5 : Visited & Positive", id: "M5", level_priority: 2 },
        { label: "M6  : Visited & Ready to Enroll", id: "M6", level_priority: 2 },
        { label: "M6 AR : Visited & Ready to Enroll, Application Received", id: "M6 AR", level_priority: 2 },
        { label: "M6 AR- : Visited & Ready to Enroll, Application Received - Drop Out", id: "M6 AR-", level_priority: 2 },
        { label: "M7 : Enrolled", id: "M7", level_priority: 3 },
        { label: "M7D1 : Defaulter", id: "M7D1", level_priority: 10 },
        { label: "M7D2 : Loan Defaulter", id: "M7D2", level_priority: 10 },
        { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ", level_priority: 4 },
        { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X", level_priority: 4 },
        { label: "M7- : Drop-out (30 days)", id: "M7-", level_priority: 4 },
        { label: "M7+ LMS demo completed (30-60 days)", id: "M7+", level_priority: 5 },
        { label: "M7# : Onboarding Done (30-60 days)", id: "M7#", level_priority: 5 },
        { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8", level_priority: 5 },
        { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+", level_priority: 6 },
        { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-", level_priority: 7 },
        { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1", level_priority: 5 },
        { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-", level_priority: 5 },
        { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+", level_priority: 5 },
        { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2", level_priority: 5 },
        { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1", level_priority: 6  },
        { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2", level_priority: 6  },
        { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3", level_priority: 6  },
        { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4", level_priority: 6  },
        { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5", level_priority: 6  },
        { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6", level_priority: 6 },
        { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9", level_priority: 8 },
        { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-", level_priority: 8 },
        { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1", level_priority: 8 },
        { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2", level_priority: 8 },
        { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3", level_priority: 8 },
        { label: "M11 : Received CPA license", id: "M11" },
        { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
        { label: "M12 : Received License Renewal Advice", id: "M12" },
        { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10", level_priority: 9 },
        { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#", level_priority: 9 },
        { label: "M7-IR  ", id: "M7-IR", level_priority: 11 },
        { label: "M7-INT  ", id: "M7-INT", level_priority: 10 },
      ]
    },
    levelOptions() {
      if(!this.getTeam('SR')){
        this.level_options = [
          { label: "M1 : Did not Visit & not intersted", id: "M1", level_priority: 1 },
          { label: "M2 : Did not Visit & Postponed", id: "M2", level_priority: 1 },
          { label: "M3 : Called & Positive", id: "M3", level_priority: 1 },
          { label: "M3+ : Called & Coming", id: "M3+", level_priority: 1 },
          { label: "M3++ : Ready to enroll - Not visited", id: "M3++", level_priority: 1 },
          { label: "M4 : Visited but Postponed", id: "M4", level_priority: 2 },
          { label: "M4- : Visited but not interested", id: "M4-", level_priority: 2 },
          { label: "M5 : Visited & Positive", id: "M5", level_priority: 2 },
          { label: "M6  : Visited & Ready to Enroll", id: "M6", level_priority: 2 },
          { label: "M7 : Enrolled", id: "M7", level_priority: 3 },
          { label: "M7D1 : Defaulter", id: "M7D1", level_priority: 10 },
          { label: "M7D2 : Loan Defaulter", id: "M7D2", level_priority: 10 },
          { label: "M7-JJ : Non Miles Jain Enrolled Candidates (30-60 days)", id: "M7-JJ", level_priority: 4 },
          { label: "M7X : Do Not Ever Call (NFD - Blank)", id: "M7X", level_priority: 4 },
          { label: "M7- : Drop-out (30 days)", id: "M7-", level_priority: 4 },
          { label: "M7+ LMS demo completed (30-60 days)", id: "M7+", level_priority: 5 },
          { label: "M7# : Onboarding Done (30-60 days)", id: "M7#", level_priority: 5 },
          { label: "M8 : Sent docs to evaluation agency; Yet to receive the evaluation report", id: "M8", level_priority: 5 },
          { label: "M8+ : Received the evaluation report; Yet to receive NTS advice from Miles", id: "M8+", level_priority: 6 },
          { label: "M8- : Drop-out (after evaluation) (once every 3 months)", id: "M8-", level_priority: 7 },
          { label: "M8#1 : Enrolled:Yet to recive evaluation advice", id: "M8#1", level_priority: 5 },
          { label: "M8#1- : Incomplete Documents; Unable to release evaluation advice", id: "M8#1-", level_priority: 5 },
          { label: "M8#1+ : Sent Education documents to Miles; Yet to receive Evaluation Advice", id: "M8#1+", level_priority: 5 },
          { label: "M8#2 : Received Evaluation Advice from Miles; Yet to send docs to evaluation agency", id: "M8#2", level_priority: 5 },
          { label: "M8+1 : Received NTS advice from Miles; Yet to apply for NTS", id: "M8+1", level_priority: 6  },
          { label: "M8+2 : Applied for NTS; Yet to receive NTS", id: "M8+2", level_priority: 6  },
          { label: "M8+3 : Received NTS; Yet to receive exam scheduling advice", id: "M8+3", level_priority: 6  },
          { label: "M8+4 : Received exam scheduling advice; Yet to schedule exam", id: "M8+4", level_priority: 6  },
          { label: "M8+5 : Scheduled exam; Prometric confirmation yet to be verified by Miles", id: "M8+5", level_priority: 6  },
          { label: "M8+6 : Prometric confirmation verified by Miles; Received last minute checks instructions", id: "M8+6", level_priority: 6  },
          { label: "M9 : Appeared for 1 or more exams yet to pass all 4; Yet to receive NTS rescheduling advice from Miles for other exams", id: "M9", level_priority: 8 },
          { label: "M9- : Drop-out (after clearing an exam) (once every 3 months)", id: "M9-", level_priority: 8 },
          { label: "M9+1 : Received NTS rescheduling advice from Miles for other exams", id: "M9+1", level_priority: 8 },
          { label: "M9+2 : Received New NTS; Yet to receive approval by GP", id: "M9+2", level_priority: 8 },
          { label: "M9+3 : New NTS Approved; Appeared for 1 or more exams yet to pass all 4", id: "M9+3", level_priority: 8 },
          { label: "M11 : Received CPA license", id: "M11" },
          { label: "M11# : Yet to recieve License renewal advice from Miles", id: "M11#" },
          { label: "M12 : Received License Renewal Advice", id: "M12" },
          { label: "M10 : Passed all 4 exams; Yet to receive license advice from Miles", id: "M10", level_priority: 9 },
          { label: "M10# : Received license advice from Miles; License application WIP", id: "M10#", level_priority: 9 },
          { label: "M7-IR  ", id: "M7-IR", level_priority: 11 },
          { label: "M7-INT  ", id: "M7-INT", level_priority: 10 },
        ]
      }
      if(this.getTeam('SR') && this.getSubTeam('SR')){
        this.level_options = this.SRlabelOptions;
      }
    },
    disableCourse(course) {
      // console.log("PAYLOAD", this.payload, course, this.enrolled_courses)
      // console.log("PAYLOAD", this.payload, this.course)

      if (this.enrolled_courses.includes(course)) {
        return true
      } else {
        return false
      }
    },
    levelChange(){
      let level_from_server = this.payload.mwb.level;
      if (level_from_server != null && level_from_server != "") {
        let priority_of_level_from_server = this.level_options.find((item) => item.id === level_from_server).level_priority;
        let priority_of_level_from_selected = this.level_options.find((item) => item.id === this.levels).level_priority;
        if (priority_of_level_from_selected  < priority_of_level_from_server) {
          this.$vs.notify({
            title: "Not Allowed",
            text: "Sorry you can't downgrade the level",
            color: "danger",
          });
          this.levels = level_from_server;
          this.level_priority_condition = true;
        }else{
          this.level_priority_condition = false;
        }
        if(this.levels == 'M7D1' && this.loan_status && this.collection_team){
          this.$vs.notify({
            title: "Not Allowed",
            text: "Enrolled with loan can't mark it as M7D1",
            color: "danger",
          });
          this.level_defaulter_condition = true;
        }else{
          this.level_defaulter_condition = false;
        }
        if(this.levels == 'M7D2' && !this.loan_status && this.collection_team){
          this.$vs.notify({
            title: "Not Allowed",
            text: "Not enrolled with loan can't mark it as M7D2",
            color: "danger",
          });
          this.defaulter_level = true;
        }else{
          this.defaulter_level = false;
        }
      }
    },
    freezeMAccLevels() {
      let macc_level_from_server = this.payload.mwb.iiml_level
      console.log("freezeMAccLevels called on change ", macc_level_from_server)
      if (macc_level_from_server != null && macc_level_from_server != "") {
        // let downgradable_levels = ['U0', 'U1', 'U2', 'U3-', 'U3', 'U3+', 'U4-']
        // if(!downgradable_levels.includes(macc_level_from_server))
        // {
        //   let index_of_macc_level_from_server = this.macc_levels.findIndex((item) => item.id === macc_level_from_server)
        //   console.log("index_of_macc_level_from_server ", index_of_macc_level_from_server, "length", downgradable_levels.length);
        //   if (index_of_macc_level_from_server > (downgradable_levels.length))
        //   {
        //     this.$vs.notify({
        //         title: "Not Allowed",
        //         text: "To downgrade a higher U Level",
        //         color: "danger",
        //       });
        //     this.selected_macc_level = macc_level_from_server;
        //   }
        // }
        let priority_of_macc_level_from_server = this.macc_levels.find((item) => item.id === macc_level_from_server).level_priority;
        let priority_of_macc_level_from_selected = this.macc_levels.find((item) => item.id === this.selected_macc_level).level_priority;
        console.log("priority_of_macc_level_from_server", priority_of_macc_level_from_server);
        console.log("priority_of_macc_level_from_selected", priority_of_macc_level_from_selected);
        if (priority_of_macc_level_from_selected < priority_of_macc_level_from_server) {
          this.$vs.notify({
            title: "Not Allowed",
            text: "To downgrade from higher U Level",
            color: "danger",
          });
          this.selected_macc_level = macc_level_from_server;
        }
      }
    },

    storePersona() {
      console.log("STORE PERSONA FROM CALL SECTION", this.new_persona_data)

      let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
      axios
        .post(url, this.new_persona_data, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Personna Data", response.data);

          if (response.data.status == "success") {
            this.$vs.notify({
              title: "Success",
              text: "Persona Details Updated Successfully",
              color: "success",
            });
            this.getCandidatePersonaDetails();

            // console.log("API AT EMIT",this.existing_persona_data ,obj)

          } else {
            this.$vs.notify({
              title: "Error",
              text: "Something went wrong",
              color: "danger",
            });


          }
        })
        .catch((error) => {
          this.handleError(error);
          // this.getCandidatePersonaDetails();
          this.$vs.loading.close();
        });

    },
    checkPersonaData(new_data) {
      // this.old_persona_data = existing_data;
      this.new_persona_data = new_data
      // console.log("OLD PERSONA",existing_data);
      // console.log("NEW PERSONA", new_data);

    },
    determineMaccLevel() {
      console.log("DETERMINE MACC LEVEL PATHWAY CHANGE", this.pathway, this.course)

      let downgradable_levels = ["U0--","U0", "U1", "U1+", "U2", "U3-", "U3", "U3+","U3++", "U4-"]

      // let hot_level_pathways = ["Pathway 2 - US", "Pathway 3 - Canada", "Pathway 4 - US or Canada"]

      //For Pathway 2,3,4 U level = U3
      // if this.payload.mwb.iiml_level includes (U0, U1)

      // if (hot_level_pathways.includes(this.pathway)) {
      //   if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
      //     this.selected_macc_level = "U3"
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }
      //   } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
      //     this.selected_macc_level = "U3"
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }

      //   } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
      //     this.selected_macc_level = this.payload.mwb.iiml_level
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }

      //   }
      // }

      //For Pathway 5 U Level = U2
      // let warm_level_pathways = ["Pathway 5 - India/US/Canada"]

      // if (warm_level_pathways.includes(this.pathway)) {
      //   if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }
      //     this.selected_macc_level = "U2"

      //   } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
      //     this.selected_macc_level = "U2"
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }

      //   } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
      //     this.selected_macc_level = this.payload.mwb.iiml_level
      //     if (!this.course.includes('MAcc')) {
      //       this.course.push('MAcc');
      //     }

      //   }
      // }

      // For Pathway 1 U Level = U0
      if (this.pathway == "Pathway 1 - India") {
        if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
          this.selected_macc_level = "U0"
          // if (this.course.includes('MAcc')) {
          //   this.course.splice(this.course.indexOf('MAcc'), 1);
          // }


        } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
          this.selected_macc_level = this.payload.mwb.iiml_level
          this.$vs.notify({
            title: "U-level cannot be downgraded",
            color: "danger",
            time: 5000
          });
          EventBus.$emit('make-pathway-empty');

        } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
          this.selected_macc_level = "U0"

          // if (this.course.includes('MAcc')) {
          //   this.course.splice(this.course.indexOf('MAcc'), 1);
          // }

        }
      }

      console.log("DETERMINE MACC LEVEL PATHWAY CHANGE 2", this.pathway, this.course)

    },
    checkTeam() {
      if (localStorage.getItem('team') == 'CM') {
        return true;
      } else {
        return false
      }
    },
    getPersonnaUpdatedData() {
      this.getCandidatePersonaDetails();
    },
    getCandidatePersonaDetails() {
      this.persona_details = {};
      // this.$vs.loading();
      let mwb_id = this.payload.mwb.id;
      let url = `https://mfcomms.2x2.ninja/api/getCandidatePersonaDetails?mwb_id=${mwb_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.persona_details = response.data;
          this.personna_data = true;
          //  console.log("Personna call Data",  this.persona_details, this.persona_details.length);
          // this.$vs.loading.close();
          EventBus.$emit("persona-candidate-updated-data", this.persona_details)
        })
        .catch((error) => {
          this.handleError(error);
          this.personna_data = true;
          this.$vs.loading.close();
        });
    },
    getAddressData: function (addressData) {
      this.current_location =
        addressData.locality +
        "," +
        addressData.administrative_area_level_1 +
        "," +
        addressData.country;
      // console.log("address", this.current_location);
    },
    checkLevel() {
      if (
        (this.levels === "M1" || this.levels === "M4-" || this.levels === 'M4' || this.levels === 'M2')  &&
        !this.getSubTeam("IIML-FT")
      ) {
        return false;
      }
       else {
        return true;
      }
    },

    scheduleCall() {
      console.log("date_sc--", this.dates);
      console.log("schedule_time--", this.schedule_time);

      if (
        this.schedule_time.HH != null &&
        this.schedule_time.mm != null &&
        this.schedule_time.HH != "" &&
        this.schedule_time.mm != ""
      ) {
        let schedule_date = moment(this.dates, "DD-MM-YYYY").format(
          "DD-MMM-YYYY"
        );
        console.log("scheduleDate--", schedule_date);
        let hours_minutes = this.schedule_time.HH.concat(
          ":",
          this.schedule_time.mm
        );
        this.date_time = schedule_date + " " + hours_minutes;

        console.log("scheduleDateTime--", this.date_time);

        // let momentData = new Date(date_time).getTime() / 1000;
        // console.log("momentData--", momentData)

        //  this.dates = schedule_date + ' ' + hours_minutes
        // console.log("converted--", new Date(this.dates).getTime() / 1000);
        this.scheduled_call = true;
      } else {
        this.scheduled_call = false;
      }
      this.schedule_call_popup = false;
    },
    getSelectedDate() {
      console.log("date--", this.dates);
      console.log("date_time--", this.date_time);
      if (this.scheduled_call) {
        return moment(this.date_time).format("DD-MMM-YYYY HH:mm");
      } else {
        if (this.dates != "") {
          return moment(this.dates).format("DD-MMM-YYYY");
        }
      }
    },
    // getWIleyApplicationData() {
    //   const url = `${constants.SERVER_API}wileyApplicationStatus?mwb_id=${this.payload.mwb.id}`;
    //   // const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=469671`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //         console.log("mwb data", response);
    //         this.TotalApplicationData = response.data.data;
    //         // response.data.data.forEach(element => {
    //         //     console.log("element", element)
    //         //     if(element.miles_course === "IIML-BA") {
    //         //         this.LoanData = element["IIML-BA"];
    //         //     } else if(element.miles_course === "IITM-FS") {
    //         //         this.LoanData = element["IITM-FS"];
    //         //     } else if(element.miles_course === "IIML-PM") {
    //         //         this.LoanData = element["IIML-PM"];
    //         //     }
    //         // });

    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       this.handleError(error);
    //     });
    // },
    openWileyPopup() {
      console.log("COurse", this.course[0]);

      if (this.course.length > 1) {
        this.showCustomNotification(
          "Please Select only one course",
          "For this candidate multiple courses are seleted.please select only one course for sending wiley application ",
          "danger"
        );
        return;
      }
      if (
        this.course.toString() !== "IITR-CC" &&
        this.course.toString() !== "IITM-AA"
      ) {
        this.showCustomNotification(
          "Please Select only IITR-CC OR IITM-AA courses",
          "For selecting course " +
          this.course.toString() +
          " , ITRAC Application is not created. Please select only IITR-CC OR IITM-AAcourses.",
          "danger"
        );
        return;
      }
      // if (this.course.toString()!== "IITM-AA") {
      //   this.showCustomNotification(
      //     "Please Select only IITR-CC and IITM-AA course",
      //     "For selecting course " + this.course.toString() + " , ITRAC Application is not created. Please select only IITR-CC and IITM-AAcourses.",
      //     "danger"
      //   );
      //   return;
      // }
      if (!this.primaryExists(this.payload.emails)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary email is required for registered user to send wiley application",
          "danger"
        );
        return;
      } else if (!this.primaryExists(this.payload.mobiles)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary mobile number is required for registered user to send wiley application",
          "danger"
        );
        return;
      }
      this.text = "Testing";
      this.popupOpen = true;
      console.log("course", this.course.toString());
      this.PrimaryMobile = this.getMobileForCandidate(this.payload.mobiles);
      this.PrimaryEmail = this.getEmailForCandidate(this.payload.emails);
      this.WlieyPersonName = this.payload.mwb.person_name;
      this.SelectedCOurse = this.course.toString();
      // this.course.forEach(element => {
      //   this.SelectedCOurse = element;
      // });
    },
    primaryExists(PurchaseData) {
      console.log("dim", PurchaseData);
      let bool = false;
      PurchaseData.forEach((item) => {
        if (item.pri_mary === 1) {
          bool = true;
        }
      });
      return bool;
    },
    sendWileyApplication() {
      let obj = {
        mwb_id: this.payload.mwb.id,
        can_name: this.payload.mwb.person_name,
        miles_course: this.SelectedCOurse,
        mobile: this.getMobileForCandidate(this.payload.mobiles),
        email: this.getEmailForCandidate(this.payload.emails),
      };
      console.log(obj);
      let url = `${constants.SERVER_API}createWileyApplication`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            this.$vs.notify({
              title: response.data.message,
              color: "success",
            });
            this.popupOpen = false;
          }
          if (
            response.data.status === "failed" ||
            response.data.status === "error"
          ) {
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    stopLoading() {
      this.$vs.loading.close(this.$refs.loadableButton.$el);
    },
    googleMeetButton() {
      this.$vs.loading({
        background: "primary",
        color: "#ffffff",
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      });
      this.onlygooglemeetbutton = true;
      setTimeout(() => {
        let meetButton = document.getElementById("meet-now-button");
        meetButton.click();
        this.onlygooglemeetbutton = false;
      }, 1000);
    },
    sendZoomInvite() {
      console.log(this.payload.mwb.spoc_name);
      let mobile = this.getMobileForCandidate(this.payload.mobiles);
      let email = this.getEmailForCandidate(this.payload.emails);
      if (mobile === null || email === null) {
        return;
      }
      this.sendMilesZoomInvite(
        this.payload.mwb,
        this.webinarIntro,
        email,
        mobile,
        this.payload.mwb.spoc_name
      );
    },
    getSpocName(spoc_id) {
      let name = null;
      this.visible_transfer_members.forEach((member) => {
        console.log("member", member);
        if (member.id === spoc_id) {
          name = member.full_name;
        }
      });
      return name;
    },
    transferLead() {
      if (this.payload.mwb.mhp_flag == 1) {
        this.$vs.notify({
          title: "Can't transfer MHP lead",
          color: "danger",
        });
      } else if (this.payload.mwb.source == "Net Enquiry") {
        this.$vs.notify({
          title: "Can't transfer Net Enquiry source lead",
          color: "danger",
        });
      } else if (
        this.payload.mwb.level == "M5" ||
        this.payload.mwb.level == "M6"
      ) {
        this.$vs.notify({
          title: "Can't transfer Level M5 or Level M6 lead",
          color: "danger",
        });
      } else {
        let obj = {
          mwb_id: this.payload.mwb.id,
          transfer_to_id: this.selected_transfer_member,
          transfer_to_name: this.getSpocName(this.selected_transfer_member),
        };
        let url = `${constants.SERVER_API}transferLead`;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getPrimaryMobile(mobiles) {
      let primary = null;
      mobiles.forEach((mobile) => {
        if (mobile.pri_mary === 1) {
          primary = `91${mobile.last_ten_digits}`;
        }
      });
      if (primary === null && mobiles.length > 0) {
        primary = `91${mobiles[0].last_ten_digits}`;
      }
      // console.log(primary);
      return primary;
    },
    sendWA() {
      let obj = {
        course: this.sendIntrocourse,
      };
      this.sendIIMLSMS(this.payload, obj);
      console.log(this.selected_template);
      let exclude = [];
      let email = this.getEmailForCandidate(this.payload.emails);
      if (exclude.indexOf(this.sendIntrocourse) === -1 && email !== null) {
        this.sendIntroEmail(this.sendIntrocourse);
      }
    },
    getWhatsAppDisplayText() {
      let mobile = this.getMobileForCandidate(this.payload.mobiles);
      let email = this.getEmailForCandidate(this.payload.emails);
      let course = this.selected_template.course;
      this.whatsApptext = `Send Whatsapp and ${course} Intro Email`;
      if (mobile == null) {
        this.whatsApptext = `Send ${course} Intro Email`;
      } else if (email == null) {
        this.whatsApptext = "Send Whatsapp Message";
      }
      // return text;
    },
    sendIntroEmail(course) {
      let obj = {
        mwb_id: this.payload.mwb.id,
      };
      let url = null;
      // `${constants.SERVER_API}sentIIMLPAIntorEmail`
      if (course === "CPA") {
        url = `${constants.SERVER_API}sentCPAIntroEmail`;
      }
      // else if (course === "CIA") {
      //   url = `${constants.SERVER_API}sentCIAIntroEmail`;
      // }
      else if (course === "CMA") {
        url = `${constants.SERVER_API}sentCMAIntroEmail`;
      } else if (course === "CFA") {
        url = `${constants.SERVER_API}sentCFAIntroEmail`;
      } else if (course === "FRM") {
        url = `${constants.SERVER_API}sentFRMIntroEmail`;
      } else if (course === "USP") {
        url = `${constants.SERVER_API}sentMASTEMIntroEmail`;
      }
      // else if (course === "CISA") {
      //   url = `${constants.SERVER_API}sentCISAIntroEmail`;
      // }
      // else if (course === "EA") {
      //   url = `${constants.SERVER_API}sentEAIntroEmail`;
      // }
      else if (course === "CPA-AA") {
        url = `${constants.SERVER_API}sentCPAAAIntroEmail`;
      } else if (course === "IIML-FA") {
        url = `${constants.SERVER_API}sentIIMLFAIntroEmail`;
      }
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    addSecondVisit() {
      let obj = {
        sv_added_to_id: this.selected_second_visit_member,
        sv_added_to_name: "",
        sv_comments: this.second_visit_comments,
        mwb_id: this.payload.mwb.id,
        identity: this.payload.mwb.identity,
        person_id: this.payload.mwb.person_id,
        person_name: this.payload.mwb.person_name,
      };
      this.visible_second_visit_members.forEach((member) => {
        if (member.id == this.selected_second_visit_member) {
          obj.sv_added_to_name = member.full_name;
        }
      });
      console.log(obj);
      let url = `${constants.SERVER_API}addSecondVisit`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    generateWhatsAppMessage() {
      let selected_courses_array = this.payload.mwb.courses.split(",");
      if (this.getrole("exed")) {
        if (selected_courses_array.indexOf("IIML-BA") !== -1) {
          this.whatsapp_message =
            "Be an IIM Lucknow MDP Alumni as you upskill in Business & Data Analytics. IIM Lucknow & Wiley - Executive Education Program in Business and Data Analytics - 6 months, Part-time, Online plus 6 days immersions at IIML campus. Stay Safe and attend Intro Webinar on Saturday (April 25th) at 5 PM. Register at: https://mileseducation.com/roadmap/iiml-ba";
        } else if (selected_courses_array.indexOf("IIML-FA") !== -1) {
          this.whatsapp_message =
            "Be an IIM Lucknow MDP Alumni as you upskill in Analytics for Finance. Register for Webinar on IIM Lucknow & Wiley - Executive Education Program in Analytics for Finance & Accounting Professionals, 6 months, Part-time, Online plus 6 days immersions at IIML campus. The session will be hosted by Varun Jain, CPA, CMA, Harvard B-School Alumnus. Date: Apr 19, 2020, at 11 AM IST. Registration link: https://mileseducation.com/roadmap/iiml-fa";
        }
      } else if (this.getrole("cm1") || this.getrole("cm4")) {
        this.selected_template = this.whatsapp_options[0];
      } else {
        this.whatsapp_message = "";
      }
    },
    getvisibilityStatus() {
      let status = false;
      let selected_courses_array = this.payload.mwb.courses.split(",");
      if (selected_courses_array.indexOf("IIML-BA") !== -1) {
        status = true;
      } else {
        status = false;
      }
      return status;
    },
    escalateThisLead() {
      let obj = {
        escalated_to_id: this.selected_escalation_member,
        escalated_to_name: "",
        escalated_comments: this.escalation_comments,
        mwb_id: this.payload.mwb.id,
        identity: this.payload.mwb.identity,
        person_id: this.payload.mwb.person_id,
        person_name: this.payload.mwb.person_name,
      };
      this.visible_members.forEach((member) => {
        if (member.id == this.selected_escalation_member) {
          obj.escalated_to_name = member.full_name;
        }
      });
      console.log(obj);
      let url = `${constants.SERVER_API}addEscalation`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    changeSpocVisibility(value, section) {
      if (section === "escalation") {
        this.selected_escalation_member = null;
        this.visible_members = [];
        this.visible_members = this.escalationsObj[value];
        this.spocs_tree_data = [];
        this.spoc_cities = [];
        var unique = this.escalationsObj[value]
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            this.spoc_cities.push(key);
            var obj = {
              id: key,
              label: key,
              children: [],
            };
            this.spocs_tree_data.push(obj);
          }
        }
        this.escalationsObj[value].forEach((server_spoc) => {
          this.spocs_tree_data.forEach((bde) => {
            if (bde.label === server_spoc.city) {
              var obj = {
                id: server_spoc.id,
                label: server_spoc.full_name,
              };
              bde.children.push(obj);
            }
          });
        });
        this.spocs_tree_data = this.sortNestedArrayAlphabetically(
          this.spocs_tree_data
        );
      }
    },
    transferSpocVisibility(value, section) {
      if (section === "transfer") {
        this.selected_transfer_member = null;
        this.visible_transfer_members = [];
        this.visible_transfer_members = this.escalationsObj[value];
        this.transfer_spocs_tree_data = [];
        this.transfer_spoc_cities = [];
        var unique = this.escalationsObj[value]
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            this.transfer_spoc_cities.push(key);
            var obj = {
              id: key,
              label: key,
              children: [],
            };
            this.transfer_spocs_tree_data.push(obj);
          }
        }
        this.escalationsObj[value].forEach((server_spoc) => {
          this.transfer_spocs_tree_data.forEach((bde) => {
            if (bde.label === server_spoc.city) {
              var obj = {
                id: server_spoc.id,
                label: server_spoc.full_name,
              };
              bde.children.push(obj);
            }
          });
        });
        this.transfer_spocs_tree_data = this.sortNestedArrayAlphabetically(
          this.transfer_spocs_tree_data
        );
      }
    },
    // fetchEscalationsMembers() {
    //   let url = `${constants.SERVER_API}getEscalationMembers`;
    //   axios
    //     .get(url, {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.userAccessToken}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.escalationsObj = response.data;
    //       // this.handleNotification(response);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    checkIfRoleis(role) {
      if (localStorage.getItem("role") === role) {
        return true;
      } else {
        return false;
      }
    },
    getNextDateStatus(payload) {
      console.log(payload.next_call);
      if (payload.next_call === null) {
        this.tagVisible = false;
        return;
      }
      this.tagVisible = true;
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `Waiting - ${this.convertTimestampToDate(payload.next_call)}`;
      } else if (nextCall < today) {
        return `Delay - ${this.convertTimestampToDate(payload.next_call)}`;
      } else {
        return `Today - ${this.convertTimestampToDate(payload.next_call)}`;
      }
    },
    getNextDateColor(payload) {
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `success`;
      } else if (nextCall < today) {
        return `danger`;
      } else {
        return `warning`;
      }
    },
    generateEngagementInfoBadges() {
      if (this.payload.incommunicado_status !== 0) {
        for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
          if (this.payload.engagements[x].connection_status === "CD") {
            break;
          } else {
            this.arr.forEach((ar) => {
              if (this.payload.engagements[x].connection_status === ar.form) {
                ar.value = ar.value + 1;
              }
            });
          }
        }
      }
      // console.log(this.arr);
    },
    generateEngagementText(value) {
      if(this.select1 != "Connected / Discussed" || this.select1 != "Visit Confirmed"){
        console.log("engagement description",this.textAre);
        let engagementString = "";
        console.log(value);
        if (
          this.payload.mwb.engagement_details === null ||
          this.payload.mwb.engagement_details === ""
        ) {
          // this.textAre = value;
          this.latestDate = `${this.getData()} - ${this.textAre}`;
          this.connection_status = false;
        } else {
          if (this.payload.mwb.engagement_details.includes("|")) {
              let char1 =  this.payload.mwb.engagement_details.indexOf(',') + 1;
              let char2 =  this.payload.mwb.engagement_details.lastIndexOf("|");
                  engagementString = `${this.getData()} - ${this.textAre} | ${this.payload.mwb.engagement_details.substring(
                  char1, char2
                )}`;
          }
          else {
            console.log("in the else block");
            engagementString = `${this.getData()} - ${this.textAre} | ${this.payload.mwb.engagement_details
              }`;
          }
          // this.textAre = value;
          this.latestDate = engagementString;
          this.connection_status = false;
        }
        console.log(this.latestDate);
      }
    },
    getData() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    emailform() {
      EventBus.$emit("open-email-engagement", this.payload);
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    getEscalationStatus() {
      if (this.escalation_boolean) {
        return 1;
      } else {
        return 0;
      }
    },
    getStatus(bool) {
      if (bool) {
        return 1;
      } else {
        return 0;
      }
    },
    async API_post_add_engagement(mode) {
      let courses_list = [
        "AICPA",
        "CFA",
        "FRM",
        "IIML-FT",
        "IIML-SF",
        // "MAcc",
        "USP",
        "CMA",
        "CPA",
        "CPA-AA",
        "IIMI-BA",
        "IIMI-AA",
        "XLRI-HR",
        "XLRI-SH"
      ];

      let allowed_status_for_persona = ["Connected / Discussed", "Visit Confirmed"];
      let cfa_list = ["CFA-L1", "CFA-L2", "CFA-L3"];
      let frm_list = ["FRM-P1", "FRM-P2"];
      let course_array_length = _.intersection(courses_list, this.course);
      let cfa_array_length = _.intersection(cfa_list, this.course);
      let frm_array_length = _.intersection(frm_list, this.course);
      this.textAre = this.textAre.trim();
      let check_lvl = this.levels == this.payload.mwb.level
      if (this.textAre === "") {
        this.resetLoadingButtons();
        this.$vs.notify({
          title: "Fill out the form",
          text: "Please fill out the details in the engagement form",
          color: "danger",
        });
        this.resetLoadingButtons();
      }
      // else if (this.select1 == "Connected / Discussed" && this.payload.google_location == "" && this.current_location == "") {
      //   this.$vs.notify({
      //     title: "Fill out the form",
      //     text: "The candidate location field is required.",
      //     color: "danger",
      //   });
      //   this.resetLoadingButtons();
      // }
      else if(this.level_priority_condition == true){
        this.resetLoadingButtons();
        this.$vs.notify({
            title: "Not Allowed",
            text: "Sorry you can't downgrade the level",
            color: "danger",
          });
        this.resetLoadingButtons();
      }
      else if(this.level_defaulter_condition){
        this.$vs.notify({
            title: "Not Allowed",
            text: "Enrolled with loan can't mark it as M7D1",
            color: "danger",
        });
        this.resetLoadingButtons();
      }
      else if(this.defaulter_level){
        this.$vs.notify({
            title: "Not Allowed",
            text: "Not enrolled with loan can't mark it as M7D2",
            color: "danger",
          });
        this.resetLoadingButtons();
      }
    //   else if (
    //     this.new_persona_data.years_of_experience === null ||
    //     this.new_persona_data.years_of_experience === ""
    //   ) {
    //     this.resetLoadingButtons();
    //     this.$vs.notify({
    //       title: "Please add experience to save engagement",
    //       color: "danger",
    //     });
    //     this.resetLoadingButtons();
    //   }
      else if (course_array_length.length == 0) {
        this.resetLoadingButtons();
        this.$vs.notify({
          title: "Please Select Course",
          color: "danger",
        });
        this.resetLoadingButtons();
      } else if (this.course.includes("CFA") && cfa_array_length.length == 0) {
        this.resetLoadingButtons();
        this.$vs.notify({
          title: "Please Select CFA level",
          color: "danger",
        });
        this.resetLoadingButtons();
      } else if (this.course.includes("FRM") && frm_array_length.length == 0) {
        this.resetLoadingButtons();
        this.$vs.notify({
          title: "Please Select FRM Part",
          color: "danger",
        });
        this.resetLoadingButtons();
      }
      else if (isNaN(Number(this.new_persona_data.years_of_experience)) && allowed_status_for_persona.includes(this.select1)) {
        this.resetLoadingButtons();
        this.$vs.notify({
          text: "Experience Cannot Be String in Persona",
          color: "danger",
        });

        this.resetLoadingButtons();
      }
      // else if ((this.new_persona_data.location == null || this.new_persona_data.location == "") && allowed_status_for_persona.includes(this.select1)) {
      //   this.$vs.notify({
      //     text: "Location cannot be empty",
      //     color: "danger",
      //   });
      //   this.resetLoadingButtons();
      // }
      else if ((this.select1 == 'Connected / Discussed' || this.select1 == "Visit Confirmed") && this.new_persona_data.pathway_value != 'Yes' && (this.new_persona_data.placement_assistance == '' || this.new_persona_data.placement_assistance == null || this.new_persona_data.placement_assistance == undefined)) {
        this.$vs.notify({
          text: "Please check Placement Assistance",
          color: "danger",
        });
        this.resetLoadingButtons();
      }else if(this.level_verify && !check_lvl){
        this.$vs.notify({
          title: "Not Allowed",
          text: "You don't have access to change the level to defaulter",
          color: "danger",
        });
        this.resetLoadingButtons();
      }else if(!this.loan_status && this.levels.includes('M7D2')){
        this.$vs.notify({
          title: "Not Eligible",
          text: "Candidate doesn't have any loan status",
          color: "danger",
        });
        this.resetLoadingButtons();
      }
      else {
        const engagementType = "call";
        if (this.connection_status === false && engagementType === "call") {
          this.textAre = this.latestDate;
        }
        let timestmp = "";
        // timestmp = new Date(this.dates).getTime() / 1000;

        // if (this.payload.mwb.level !== "M7") {
        // timestmp = new Date(this.dates).getTime() / 1000;
        // }

        if (this.scheduled_call) {
          timestmp = new Date(this.date_time).getTime() / 1000;
        } else {
          timestmp = new Date(this.dates).getTime() / 1000;
        }
        let connect_status = "";
        // switch (this.select1) {
        //   case "Connected / Discussed":
        //     connect_status = "cd";
        //     break;
        //   case "Connected / Busy":
        //     connect_status = "cb";
        //     break;
        //   case "Connected / Never call back":
        //     connect_status = "cn";
        //     break;
        //   case "Connected / Wrong Number":
        //     connect_status = "cw";
        //     break;
        //   case "Busy":
        //     connect_status = "b";
        //     break;
        //   case "Not Lifting":
        //     connect_status = "nl";
        //     break;
        //   case "Not Reachable":
        //     connect_status = "nr";
        //     break;
        //   case "Disconnected":
        //     connect_status = "d";
        //     break;
        //   case "Invalid Number":
        //     connect_status = "in";
        //     break;
        //   case "Switched Off":
        //     connect_status = "so";
        //     break;
        //   case "Connected / Not Interested":
        //     connect_status = "cni";
        //     break;
        //   case "Communication barrier":
        //     connect_status = "cmb";
        //     break;
        //   case "Not Educated":
        //     connect_status = "ne";
        //     break;
        // }
        switch (this.select1) {
          case "Connected / Discussed":
            connect_status = "cd";
            break;
          case "Visit Confirmed":
            connect_status = "vcnf";
            break;
          case "Visit Completed":
            connect_status = "vcmp";
            break;
          case "Visit Rescheduled":
            connect_status = "vre";
            break;
          case "Visit Cancelled":
            connect_status = "vcnl";
            break;
          case "Booked Visit By Mistake":
            connect_status = "bvbm";
            break;
          case "Not Connected(NL/SO/NR)":
            connect_status = "nc";
            break;
          case "Invalid Number/Wrong number":
            connect_status = "inwn";
            break;
          case "Connected / Not Interested":
            connect_status = "cni";
            break;
          case "Communication barrier":
            connect_status = "cmb";
            break;
          case "Not Educated":
            connect_status = "ne";
            break;
          case "Connected/ Call Back":
            connect_status = "ccb";
            break;
        }
        if (engagementType === "visit") {
          connect_status = "cd";
        }
        let copyenrollment = "";
        if (this.enrollment) {
          copyenrollment = "Yes";
        } else {
          copyenrollment = "No";
        }
        // console.log(this.enrollment);
        // this.course.forEach((subject, index) => {
        //   if (subject === "None") {
        //     this.course.splice(index, 1);
        //   }
        // });

        if (mode === "all") {
          this.registeringforwebinar = "yes";
        } else {
          this.registeringforwebinar = "";
        }
        if (this.select1 == "Connected / Discussed" || this.select1 == "Visit Confirmed") {
          if (this.current_location != this.payload.google_location) {
            this.saveCandidateLocation();
          }
        }
        // this.determineMaccLevel();
        if (allowed_status_for_persona.includes(this.select1)) {
          this.storePersona();
        }
        console.log("add Engagement Obj", this.levels);
        let levels_name = this.levels;
        let no_nfd_levels = ['M7-IR','M7-JJ', 'M7D1', 'M7D2', 'M7-INT', 'M7X','M4-','M4','M2','M1', 'DND'];
        if((this.dates == '' || this.dates == undefined || this.dates == null) && this.getTeam('SR') && this.getSubTeam('SR') && !no_nfd_levels.includes(this.levels)){
          this.$vs.notify({
            title: "Next call date is required",
            text: "Please select a next call date for adding an engagement",
            color: "danger",
          });
          this.resetLoadingButtons();
          return;
        }
        let co = this.course.join();
        let url = `${constants.SERVER_API}addEngagement`;
        // let url = `https://crm.milesforce.com/api/addEngagement`;
        let obj = {
          can_id: this.payload.mwb.can_id,
          identity: this.payload.mwb.identity,
          person_id: this.payload.mwb.person_id,
          person_name: this.payload.mwb.person_name,
          escalation_status: this.getStatus(this.escalation_boolean),
          escalated_to: this.escalation_acads_member,
          second_visit_status: this.getStatus(this.second_visit),
          second_visit_to: this.second_visit_select,
          level: levels_name.trim(),
          // iiml_level: this.iimlLevel,
          iiml_level: this.exed_level,
          courses: co,
          details: this.textAre,
          type: "call",
          acads: 0,
          next_call: timestmp,
          type_of_nfd: this.type_of_nfd,
          google_next_call_type: this.type_of_next_call,
          update_call_log: "Yes",
          connection_status: connect_status.toUpperCase(),
          upcoming_visit_found: this.visit_found,
          enrollment: copyenrollment,
          registering_for_webinar: this.registeringforwebinar,
          scheduled_call: this.scheduled_call,
        };
        // if(localStorage.getItem("sub_team") == 'MAcc' && localStorage.getItem("role") == 'exed2'){
        //   obj.iiml_level = this.selected_macc_level;
        // }
        // if(localStorage.getItem("team") == 'CM' && (localStorage.getItem("sub_team") == 'CM' || localStorage.getItem("sub_team") == 'INT')){
        //   obj.iiml_level = this.selected_macc_level;
        // }
        // if(localStorage.getItem("team") == 'SR' && localStorage.getItem("sub_team") == 'SR'){
        //   obj.iiml_level = this.selected_macc_level;
        // }

        if (localStorage.getItem("team") == "ExEd" && localStorage.getItem("sub_team") != "MAcc") {
          obj.iiml_level = this.exed_level;
        }
        else if (localStorage.getItem("team") == "CM" && localStorage.getItem("sub_team") == "IIML-FT") {
          obj.iiml_level = this.exed_level;
        }
        else {
          obj.iiml_level = this.selected_macc_level;
        }

        if (this.getSubTeam("IIML-FT") || this.getSubTeam("IIMI-BA")) {
          if (
            this.payload.mwb.iiml_level == "" ||
            obj.iiml_level == null ||
            obj.iiml_level == ""
          ) {
            obj.iiml_level = "Net Enquiry";
          }
        }
        if (
          obj.iiml_level === "CM-Yet-To-Connect" &&
          obj.connection_status === "CD" &&
          this.getrole("cm1")
        ) {
          this.$vs.notify({
            title: "Update the IIML Level",
            text: "Please update the IIML level to add an engagement",
            color: "warning",
          });
          this.resetLoadingButtons();
          return;
        }
        console.log("add Engagement dfgdgf",url, obj);
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              if (this.$route.path === "/queue") {
                EventBus.$emit("update-queue");
              }
              EventBus.$emit("close-big-popup");
            }
            this.resetLoadingButtons();
          })
          .catch((error) => {
            this.handleError(error);
            this.resetLoadingButtons();
          });
      }
    },
    resetLoadingButtons() {
      this.all_loading = false;
      this.save_and_whatsapp_loading = false;
      this.save_loading = false;
    },
    addEngagement(mode, whatsapp_message) {
            console.log("Add engagement mode:", mode);
            //to be reactiavted before webinar starts
            // if(mode == 'all' && (this.course.includes("CPA") || this.course.includes("CMA") || this.course.includes("USP"))){
            //   this.milesCallbackWebhook();
            // }
            var webinar_courses = [];
            if(this.new_persona_data.pathway_value == 'Yes' && (mode == 'save only' || mode == 'save and whatsapp') ){
              webinar_courses.push("USP");
              this.milesCallbackWebhook(webinar_courses);
            }else if(mode == 'all'){
              webinar_courses = this.course;
              if(this.new_persona_data.pathway_value == 'Yes' && !webinar_courses.includes("USP")){
                webinar_courses.push("USP");
              }
              this.milesCallbackWebhook(webinar_courses);
            }
            //to be reactiavted before webinar starts
            if (this.select1 === "Connected / Discussed" || this.select1 === "Visit Confirmed") {
                let data = this.new_persona_data
                let fields = ['ug_qualification','global_professional_qualification','indian_professional_qualification','years_of_experience','company','location','graduation_year', 'pathway_value']
                let headers = {company: 'Company',graduation_year: 'Graduation Year',location:'Location',pathway_value:'Pathway',indian_professional_qualification:'Indian Professional Qualification',global_professional_qualification:'Global Professional Qualification',ug_qualification:'UG Qualification',years_of_experience:'Years Of Experience'}
                let empty_field = ''
                let status = true;
                console.log("find add value",this.new_persona_data)
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    console.log("find data",data[field])
                    if (data[field] === '' || data[field] === null ) {
                    empty_field = field
                    status = false
                    }
                }
                console.log("pathwayVal", this.new_persona_data.pathway_value);
                if(this.new_persona_data.pathway_value != 'Yes'){
                if (!status) {
                  if (empty_field == 'company' && (this.payload.mwb.college == null || this.payload.mwb.college == '')) {
                    this.$vs.notify({
                      title: 'If the company name is blank, enter the collage name on the left hand side.',
                      color: "danger",
                      time: 4000
                  });
                  }else{
                    if (empty_field != 'company') {
                    this.$vs.notify({
                        title: headers[empty_field] +' cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                    }else{
                      this.addEngagementPassed(mode, whatsapp_message)
                    }
                }
                }
                else{
                this.addEngagementPassed(mode, whatsapp_message)
                }
                }
                else if(this.new_persona_data.pathway_value == 'Yes' && (this.new_persona_data.graduation_year == '' || this.new_persona_data.graduation_year == null)){
                    this.$vs.notify({
                        title: 'Graduation year cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                }
                else if(this.new_persona_data.pathway_value == 'Yes' && (this.new_persona_data.location == '' || this.new_persona_data.location == null)){
                    this.$vs.notify({
                        title: 'Location cannot be empty.',
                        color: "danger",
                        time: 1000
                    });
                }
                else{
                this.addEngagementPassed(mode, whatsapp_message)
                }
            }else{
                this.addEngagementPassed(mode, whatsapp_message)
            }
        },
    milesCallbackWebhook(webinar_courses){
      let email = "";
      if(this.payload.emails.length > 0){
        for (let i = 0; i < this.payload.emails.length; i++) {
          const element = this.payload.emails[i];
          if(element.pri_mary == 1){
            email = element.email;
          }
        }
        if(email == ""){
        email = this.payload.emails[0].email
      }
      }


      let number = "";
      if(this.payload.mobiles.length > 0){
        for (let i = 0; i < this.payload.mobiles.length; i++) {
          const element = this.payload.mobiles[i];
          if(element.pri_mary == 1){
            number = element.phone_number;
          }
        }
        if(number == ""){
          number = this.payload.mobiles[0].phone_number;
        }
      }
      console.log("webinar_courses", webinar_courses);
      const url= "https://webhook.miles-api.com/webhook/register";
      const obj = {
        "fname": this.payload.mwb.person_name,
        "lname": "",
        "phone": number,
        "email": email,
        "location": this.payload.mwb.city,
        "src": "MilesForce",
        "type": "",
        "camp": "",
        "course" : webinar_courses
      }
      if(email != '' && number != ''){
        axios
        .post(url, obj)
        .then((response) => {
          console.log("milesCallbackWebhook",response.data.message);
          if(response.data.status == true){
            this.$vs.notify({
              title: "Successfully registered for Breaking News USP Webinar",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: response.data.message,
              color: "success",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
      } else {
        this.$vs.notify({
          title: "Please Add Email And Mobile Number to register Breaking News USP Webinar",
          color: "warning",
        });
      }

    },
    addEngagementPassed(mode, whatsapp_message){
      // console.log("data--",whatsapp_message)
      let timestmp = "";
      if (this.scheduled_call) {
        timestmp = new Date(this.date_time).getTime() / 1000;
      } else {
        timestmp = new Date(this.dates).getTime() / 1000;
      }
      console.log("Add engagement mode: ", mode);
      let getParams = {
        mwb_id: this.payload.mwb.id,
        next_follow_up_date: timestmp,
        previous_discussion: this.textAre,
        type_of_nfd: this.type_of_nfd,
      };
      console.log("getParamss", getParams);
      ApiServices.storeNextFollowUps(getParams)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.$vs.notify({
            title: error,
            color: "danger",
          });
        });
      if (mode === "save only") {
        //   if (this.payload.candidate_location != "") {
        //   this.saveCandidateLocation();
        // }
        this.save_loading = true;
        this.API_post_add_engagement(mode);
      } else if (mode === "save and whatsapp") {
        this.storeWhatsAppSentData(whatsapp_message);
        this.save_and_whatsapp_loading = true;
        let mobile = this.getMobileForCandidate(this.payload.mobiles);
        let email = this.getEmailForCandidate(this.payload.emails);
        if (mobile === null) {
          this.resetLoadingButtons();
          return;
        }
        if (email != null) {
          // this.sendIntroEmails();
        }
        this.clickWhatsappButton();
        this.API_post_add_engagement(mode);
      } else if (mode === "all") {
        this.storeWhatsAppSentData(whatsapp_message);
        this.all_loading = true;
        let mobile = this.getMobileForCandidate(this.payload.mobiles);
        let email = this.getEmailForCandidate(this.payload.emails);
        if (mobile === null || email === null) {
          this.resetLoadingButtons();
          return;
        }
        // this.sendIntroEmails();
        this.clickWhatsappButton();
        this.sendWebinarInvite();
        this.API_post_add_engagement(mode);
      }
    },
    storeWhatsAppSentData(whatsapp_message) {
      let obj = {
        mwb_id: this.payload.mwb.id,
        user_id: localStorage.getItem("spoc_id"),
        text: whatsapp_message,
      };
      console.log("whatsapp_message", obj);
      axios
        .post("https://mfcomms.2x2.ninja/api/storeWhatsAppSentData", obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("storeWhatsAppSentDataresponse:", response.data);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    saveCandidateLocation() {
      let obj = {
        mwb_id: this.payload.mwb.id,
        candidate_location: this.current_location,
      };
      console.log("location params", obj);
      let url = `${constants.SERVER_API}saveCandidateLocation`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("savelocation", response.data[0]);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sendWebinarInvite() {
      console.log("Sending webinar invite", {
        mwb_id: this.payload.mwb.id,
        courses: this.course.join(),
      });
      const obj = { mwb_id: this.payload.mwb.id, courses: this.course.join() };
      const url = `${constants.SERVER_API}saveWebinarSentDetails`;
      console.log(obj, url);
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("savewebinarresponse:", response.data);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sendIntroEmails() {
      console.log("Sending intro emails", {
        mwb_id: this.payload.mwb.id,
        courses: this.course.join(),
      });
      const obj = { mwb_id: this.payload.mwb.id, courses: this.course.join() };
      const url = `${constants.SERVER_API}sendIntroEmailWithCourses`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("savewebinarresponse:", response.data);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    clickWhatsappButton() {
      let whatsappButton = document.getElementById("send_whatsapp_message_btn");
      whatsappButton.click();
      console.log("clicking whatsapp button", whatsappButton);
    },
    navSettings(text) {
      this.navTitleName = text;
      this.escalation_boolean = false;
      this.escalation_acads_member = "";
      this.second_visit = false;
      this.second_visit_select = "";
      switch (text) {
        case "All":
          this.indexActive = 0;
          this.allpage = true;
          this.callpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Call":
          this.indexActive = 1;
          this.textAre = "";
          this.select1 = "";
          this.levels = this.payload.level;
          this.enrollment = false;
          this.callpage = true;
          this.allpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Visit":
          this.indexActive = 2;
          this.textAre = "";
          this.enrollment = false;
          this.visitpage = true;
          this.disable = false;
          this.connected = true;
          this.connection_status = true;
          this.allpage = false;
          this.callpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          if (
            !this.payload.level.includes("4") &&
            !this.payload.level.includes("5") &&
            !this.payload.level.includes("6") &&
            !this.payload.level.includes("7")
          ) {
            1;
            this.levels = "M5";
          } else {
            if (this.payload.level.includes("L")) {
              this.levels = "M5";
            } else {
              this.levels = this.payload.level;
            }
          }
          break;
        case "Email":
          this.indexActive = 3;
          this.emailpage = true;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          this.whatsapp_page = false;
          break;
        case "Whatsapp":
          this.indexActive = 4;
          this.whatsapp_page = true;
          this.emailpage = false;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          break;
      }
    },
    registerCandidate() {
      this.f2f_popup = true;
      console.log("f2f_popup", this.f2f_popup);
    },
    selectedULevel(value){
       let limit = 180;
      let suggested = 180;

      const macc_nfds = {
        "U0": {
          limit: 0,
          suggested: 0,
        },
        "U1": {
          limit: 365,
          suggested: 7,
        },
        "U1+": {
          limit: 365,
          suggested: 7,
        },
        "U2": {
          limit: 30,
          suggested: 7,
        },
        "U3-": {
          limit: 365,
          suggested: 7,
        },
        "U3": {
          limit: 5,
          suggested: 3,
        },
        "U3+": {
          limit: 3,
          suggested: 1,
        },
        "U3++": {
          limit: 3,
          suggested: 1,
        },
        "U4-": {
          limit: 15,
          suggested: 1,
        },
        "U4": {
          limit: 3,
          suggested: 1,
        },
        "U4R": {
          limit: 3,
          suggested: 1,
        },
        "U5": {
          limit: 20,
          suggested: 7,
        },
        "U5+": {
          limit: 5,
          suggested: 2,
        },
        "U6-": {
          limit: 15,
          suggested: 2,
        },
        "U6": {
          limit: 30,
          suggested: 7,
        },
        "U6+": {
          limit: 30,
          suggested: 7,
        },
        "U7-": {
          limit: 5,
          suggested: 1,
        },
        "U7": {
          limit: 3,
          suggested: 1,
        },
        "U8": {
          limit: 3,
          suggested: 1,
        },
        "U9": {
          limit: 30,
          suggested: 7,
        },
        "U10": {
          limit: 90,
          suggested: 30,
        },
      }
      limit = macc_nfds[value].limit;
      suggested = macc_nfds[value].suggested;

      this.disabledDates.from = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + limit
      );
      this.disabledDates.to = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      if(!this.getTeam('SR') && !this.getSubTeam("SR")){
        this.dates = new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + suggested
          )
        );
      }
      console.log("manual dates", this.dates);
      this.showDatepicker = true;
      // if(value == "U0"){
      //   this.dates = "";
      //   this.show_schedule_call = false;
      // }else{
      //   this.show_schedule_call = true;
      // }
      // this.freezeMAccLevels();
      var pathway_value = "Yes";
      if(value == "U2" || value == "U1" || value == "U3-" ){
        pathway_value = "Maybe";
      }else if (value == "U0" ){
        pathway_value = "No";
      }else if(value == "U1+"){
        pathway_value = "Yes but graduating in 2025 or after"
      }
      EventBus.$emit("changed-pathway-value", pathway_value);
    },
    selectedMLevel(value){
      if((value.includes('M7D1') || value.includes('M7D2')) && !this.collection_team){
        this.level_verify = true
      }else{
        this.level_verify = false
      }
      console.log("FINDVAL ",value);
      let limit = 180;
      let suggested = 180;
      let nfds = {};
      if (this.getTeam("SR")) {
        nfds = {
          M6: {
            limit: 30,
            suggested: 1,
          },
          // M5: {
          //   limit: 14,
          //   suggested: 14,
          // },
          M5: {
            limit: 60,
            suggested: 1,
          },
          // M4: {
          //   limit: 365,
          //   suggested: 90,
          // },
          // "M4-": {
          //   limit: 365,
          //   suggested: 365,
          // },
          // "M3+": {
          //   limit: 21,
          //   suggested: 21,
          // },
          "M3+": {
            limit: 40,
            suggested: 1,
          },
          "M3++": {
            limit: 14,
            suggested: 1,
          },
          M3: {
            limit: 60,
            suggested: 1,
          },
          // M2: {
          //   limit: 365,
          //   suggested: 90,
          // },
          // M1: {
          //   limit: 365,
          //   suggested: 365,
          // },
          M7: {
            limit: 90,
            suggested: 1,
          },
          "M7-": {
            limit: 90,
            suggested: 1,
          },
          M8: {
            limit: 90,
            suggested: 1,
          },
          "M8+": {
            limit: 90,
            suggested: 1,
          },
          "M8-": {
            limit: 90,
            suggested: 1,
          },
          M9: {
            limit: 90,
            suggested: 1,
          },
          "M9-": {
            limit: 90,
            suggested: 1,
          },
          M10: {
            limit: 90,
            suggested: 1,
          },
          "M7+": {
            limit: 90,
            suggested: 1,
          },
          "M7#": {
            limit: 90,
            suggested: 1,
          },
          "M7-JJ": {
            limit: 0,
            suggested: 0,
          },
          M7X: {
            limit: 0,
            suggested: 0,
          },
          M7D1: {
            limit: 0,
            suggested: 0,
          },
          M7D2: {
            limit: 0,
            suggested: 0,
          },
          L6: {
            limit: 30,
            suggested: 14,
          },
          L5: {
            limit: 60,
            suggested: 15,
          },
          L4: {
            limit: 120,
            suggested: 30,
          },
          "L4-": {
            limit: 180,
            suggested: 120,
          },
          "L3+": {
            limit: 90,
            suggested: 30,
          },
          L3: {
            limit: 90,
            suggested: 30,
          },
          L2: {
            limit: 120,
            suggested: 30,
          },
          L1: {
            limit: 180,
            suggested: 120,
          },
        };
      }
      if (!this.getTeam("SR")) {
        nfds = {
          M6: {
            limit: 30,
            suggested: 1,
          },
          // M5: {
          //   limit: 14,
          //   suggested: 14,
          // },
          M5: {
            limit: 60,
            suggested: 1,
          },
          // M4: {
          //   limit: 365,
          //   suggested: 90,
          // },
          // "M4-": {
          //   limit: 365,
          //   suggested: 365,
          // },
          // "M3+": {
          //   limit: 21,
          //   suggested: 21,
          // },
          "M3+": {
            limit: 40,
            suggested: 1,
          },
          "M3++": {
            limit: 14,
            suggested: 1,
          },
          // M3: {
          //   limit: 21,
          //   suggested: 21,
          // },
          M3: {
            limit: 60,
            suggested: 1,
          },
          // M2: {
          //   limit: 365,
          //   suggested: 90,
          // },
          // M1: {
          //   limit: 365,
          //   suggested: 365,
          // },
          M7: {
            limit: 90,
            suggested: 1,
          },
          "M7#": {
            limit: 90,
            suggested: 1,
          },
          "M7+": {
            limit: 90,
            suggested: 1,
          },
          "M7-": {
            limit: 90,
            suggested: 1,
          },
          M8: {
            limit: 90,
            suggested: 1,
          },
          "M8+": {
            limit: 90,
            suggested: 1,
          },
          "M8-": {
            limit: 90,
            suggested: 1,
          },
          M9: {
            limit: 90,
            suggested: 1,
          },
          "M9-": {
            limit: 90,
            suggested: 1,
          },
          M10: {
            limit: 90,
            suggested: 1,
          },
          // "M7+LMS": {
          //   limit: 30,
          //   suggested: 60,
          // },
          // "M7X": {
          //   limit: "",
          //   suggested: "",
          // },
          L6: {
            limit: 30,
            suggested: 14,
          },
          L5: {
            limit: 60,
            suggested: 15,
          },
          L4: {
            limit: 120,
            suggested: 30,
          },
          "L4-": {
            limit: 180,
            suggested: 120,
          },
          "L3+": {
            limit: 90,
            suggested: 30,
          },
          L3: {
            limit: 90,
            suggested: 30,
          },
          L2: {
            limit: 120,
            suggested: 30,
          },
          L1: {
            limit: 180,
            suggested: 120,
          },
        };
      }
      if (nfds[value] !== undefined) {
        limit = nfds[value].limit;
        console.log("limit", limit);
        suggested = nfds[value].suggested;
        this.disabledDates.from = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + limit
        );
        this.disabledDates.to = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        if(!this.getTeam("SR") && !this.getSubTeam("SR")){
          this.dates = new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + suggested
            )
          );
        }
        let sr_show_nfd_levels = ["M3", "M3+", "M3++", "M5", "M6"]
        if(sr_show_nfd_levels.includes(value) && this.getTeam("SR") && this.getSubTeam("SR")){
          this.dates = new Date(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + suggested
              )
            );
        }
      }

      if (value === 'M7-IR' || value === 'M7-INT' || value === "M7X" || value === "M7-JJ" || value === "M7D1" || value === "M7D2" || value === "M4-" || value === "M4" || value === "M1" || value === "M2") {
        this.dates = "";
        this.show_schedule_call = false;
      }else{
        this.show_schedule_call = true;
      }
      // if (value === "M4-" || value === "M1") {
      //   this.disabledDates.from = "";
      //   this.disabledDates.to = "";
      // }
      console.log("suggetsed", this.dates, this.disabledDates);
      this.levelChange();
    }

  },
};
</script>
<style >
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 100000000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.status {
  font-family: "montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.7px;
  color: #000000;
}

.status1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.status2 {
  font-family: "montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17.7px;
  color: #000000;
}

.connection_btn {
  color: #ffffff;
  background: #0044ba !important;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 9px 2px !important;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
}

.connection_btn .vs-button-text {
  padding-inline: 10px;
}

.inside-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-left: 5px;
}

/* .vs-checkbox-primary .vs-checkbox {
    border: 2px solid #0044ba !important;
    border-radius: 4px;
}
.checkbox_x .vs-checkbox{
   border: 2px solid #0044ba !important;
} */
.checkboxInput {
  display: -webkit-inline-box !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000 !important;
}

.checkboxInput .vs-checkbox {
  border: 1px solid #0044ba !important;
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
}

.checkboxInput .vs-checkbox .vs-checkbox--check {
  background: #ffffff !important;
}

.checkboxInput .vs-checkbox .vs-checkbox--check .vs-checkbox--icon {
  color: #0044ba;
}

.myRadioBtn .vs-radio--borde {
  border: 1px solid #0044ba !important;
}

/* .vue-treeselect__control {
  border: 1px solid #0044ba;
} */
/* .MyVsSelect .vs-select--options > ul{
    border: 1px solid #ae2f2f;
    z-index: 530001;
}
.vs-select--options .vs-select-primary > ul{
  border: 1px solid #ae2f2f;
} */
.schedule_popup .vs-popup--header {
  background: #0044ba;
  border-radius: 15px;
}

.schedule_popup .vs-popup--title h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  padding-block: 10px;
}

.schedule_popup .vs-popup--close {
  background: #0044ba !important;
  margin-top: auto;
  margin-right: 18px;
  color: #ffffff;
}

.schedule_popup .vs-popup {
  border-radius: 15px !important;
}

.myTimePicker .display-time {
  border: 1px solid #ffffff;
  width: 203px !important;
  height: 37px !important;
  padding: 0.3em 0.5em;
  font-size: 1em;
}

.schedule_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.schedule_checkbox {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 10px;
}

.invc-input .vs-input--input:focus {
  border: 1px solid #cccccc !important
}

.invc-input>.vs__dropdown-menu {
  border: 1px solid #cccccc !important;
}

.invc-input>.vs__dropdown-menu .vs__dropdown-option {
  font-size: 1rem;
}

.invc-input .vs__dropdown-toggle .vs__selected-options .vs__selected {
  font-size: 12px !important;
}

/* @media only screen and (max-width: 1525px) {
.invc-input .vs__dropdown-toggle .vs__selected-options .vs__selected{
  font-size: 10px !important;
    }
    .invc-input>.vs__dropdown-menu{
  width:150%
}
}

@media only screen and (max-width: 1380px) {
.invc-input .vs__dropdown-toggle .vs__selected-options .vs__selected{
  font-size: 8px !important;
    }
} */
.mradio .vs-radio{
  background: rgb(0, 68, 186);
}
</style>
