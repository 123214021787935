<template>
  <vs-popup
    class="popwidth"
    title="Attendance Filling Form "
    :active.sync="open"
    style="min-width:700px"
  >
    <vs-card>
      <div>
        <div class="con-radios-example">
          <vs-row vs-w="12">
            <vs-col vs-w="4"></vs-col>
            <vs-col vs-w="4">
              <h6>Total Students Count : {{mainValue}}</h6>
            </vs-col>
            <vs-col vs-w="4"></vs-col>
          </vs-row>
        </div>
        <vs-collapse class="collapseBorder">
          <vs-collapse-item>
            <div slot="header">
              <vs-row vs-w="12">
                <vs-col vs-w="6">
                  <small>Batch No :</small>
                  <small>CPA-M7-01</small>
                </vs-col>
                <vs-col vs-w="6">
                  <small>No. Of Students :</small>
                  <small>{{batchno}}</small>
                </vs-col>
              </vs-row>
            </div>
            <!-- <div >
                <vs-row vs-w="12" :key="index" :value="item.batch_no" :text="item.batch_no" v-for="(item,index) in batch1">
                <vs-col vs-w="4">
                  <vs-checkbox v-model="batch1checkbox" :vs-value="item.can_name"></vs-checkbox>
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can-ID :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <h6>{{item.can_no}}</h6>
                    </vs-col>
                  </vs-row> 
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can Name :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <small>{{item.can_name}}</small>
                    </vs-col>
                  </vs-row>              
                   
                </vs-col>
              </vs-row>
            </div>-->
            <div>
              <vs-table :data="batch1">
                <template slot="thead">
                  <vs-th>Check</vs-th>
                  <vs-th>Can-ID</vs-th>
                  <vs-th>Name</vs-th>
                  <vs-th>Signature</vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr]">
                      <vs-checkbox v-model="batch1checkbox" :vs-value="data[indextr].can_name"></vs-checkbox>
                    </vs-td>
                    <vs-td :data="data[indextr].can_no">{{data[indextr].can_no}}</vs-td>

                    <vs-td :data="data[indextr].can_name">{{data[indextr].can_name}}</vs-td>
                    <vs-td :data="data[indextr]">
                      <!-- <vs-checkbox v-model="batch1checkbox" :vs-value="data[indextr].can_name"></vs-checkbox> -->
                      <vs-input type="text" disabled />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <vs-button
              color="primary"
              size="small"
              type="filled"
              style="margin-right: 1%; float:right;"
            >ADD</vs-button>
          </vs-collapse-item>
        </vs-collapse>
        <vs-collapse class="collapseBorder">
          <vs-collapse-item>
            <div slot="header">
              <vs-row vs-w="12">
                <vs-col vs-w="6">
                  <small>Batch No :</small>
                  <small>CPA-M7-02</small>
                </vs-col>
                <vs-col vs-w="6">
                  <small>No. Of Students :</small>
                  <small>{{batch2no}}</small>
                </vs-col>
              </vs-row>
            </div>
            <!-- <div >
                <vs-row vs-w="12" :key="index" :value="item.batch_no" :text="item.batch_no" v-for="(item,index) in batch2">
                <vs-col vs-w="4">
                  <vs-checkbox v-model="batch2checkbox" :vs-value="item.can_name"></vs-checkbox>
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can-ID :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <h6>{{item.can_no}}</h6>
                    </vs-col>
                  </vs-row> 
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can Name :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <small>{{item.can_name}}</small>
                    </vs-col>
                  </vs-row>              
                   
                </vs-col>
              </vs-row>
            </div>-->
            <div>
              <vs-table :data="batch2">
                <template slot="thead">
                  <vs-th>Check</vs-th>
                  <vs-th>Can-ID</vs-th>
                  <vs-th>Name</vs-th>
                  <vs-th>Signature</vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr]">
                      <vs-checkbox v-model="batch2checkbox" :vs-value="data[indextr].can_name"></vs-checkbox>
                    </vs-td>
                    <vs-td :data="data[indextr].can_no">{{data[indextr].can_no}}</vs-td>

                    <vs-td :data="data[indextr].can_name">{{data[indextr].can_name}}</vs-td>
                    <vs-td :data="data[indextr]">
                      <!-- <vs-checkbox v-model="batch1checkbox" :vs-value="data[indextr].can_name"></vs-checkbox> -->
                      <vs-input type="text" disabled />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <vs-button color="primary" size="medium" type="filled" style="margin-right: 1%">ADD</vs-button>
          </vs-collapse-item>
        </vs-collapse>
        <vs-collapse class="collapseBorder">
          <vs-collapse-item>
            <div slot="header">
              <vs-row vs-w="12">
                <vs-col vs-w="6">
                  <small>Batch No :</small>
                  <small>CPA-M7-03</small>
                </vs-col>
                <vs-col vs-w="6">
                  <small>No. Of Students :</small>
                  <small>{{batch3no}}</small>
                </vs-col>
              </vs-row>
            </div>
            <div>
              <vs-table :data="batch3">
                <template slot="thead">
                  <vs-th>Check</vs-th>
                  <vs-th>Can-ID</vs-th>
                  <vs-th>Name</vs-th>
                  <vs-th>Signature</vs-th>
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr]">
                      <vs-checkbox v-model="batch3checkbox" :vs-value="data[indextr].can_name"></vs-checkbox>
                    </vs-td>
                    <vs-td :data="data[indextr].can_no">{{data[indextr].can_no}}</vs-td>

                    <vs-td :data="data[indextr].can_name">{{data[indextr].can_name}}</vs-td>
                    <vs-td :data="data[indextr]">
                      <!-- <vs-checkbox v-model="batch1checkbox" :vs-value="data[indextr].can_name"></vs-checkbox> -->
                      <vs-input type="text" disabled />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <!-- <div >
                <vs-row vs-w="12" :key="index" :value="item.batch_no" :text="item.batch_no" v-for="(item,index) in batch3">
                <vs-col vs-w="4">
                  <vs-checkbox v-model="batch3checkbox" :vs-value="item.can_name"></vs-checkbox>
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can-ID :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <h6>{{item.can_no}}</h6>
                    </vs-col>
                  </vs-row> 
                </vs-col>
                <vs-col vs-w="4">
                  <vs-row vs-w="12">
                    <vs-col vs-w="6">
                      <h6>Can Name :</h6>
                    </vs-col>
                    <vs-col vs-w="6">
                      <small>{{item.can_name}}</small>
                    </vs-col>
                  </vs-row>              
                   
                </vs-col>
              </vs-row>
            </div>-->
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vs-card>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
export default {
  data() {
    return {
      batchno: 0,
      batch2no: 0,
      batch3no: 0,
      mainValue: "",
      batch1checkbox: [],
      batch2checkbox: [],
      batch3checkbox: [],
      Batches: [
        { batch_no: "CPA-M7-01", cource: "CPA" },
        { batch_no: "CPA-M7-02", cource: "CPA" },
        { batch_no: "CPA-M7-03", cource: "CPA" },
        { batch_no: "CPA-M7-04", cource: "CPA" },
        { batch_no: "CPA-M7-05", cource: "CPA" }
      ],
      batch1: [
        { batch_no: "CPA-M7-01", can_no: 8, can_name: "Pawan Alreja" },
        { batch_no: "CPA-M7-01", can_no: 9, can_name: "Thomas Jacob" },
        { batch_no: "CPA-M7-01", can_no: 10, can_name: "Amod Sagvekar" },
        { batch_no: "CPA-M7-01", can_no: 32, can_name: "Sadiq Amin Kunda" },
        { batch_no: "CPA-M7-01", can_no: 75, can_name: "Nilesh Shenai" },
        { batch_no: "CPA-M7-01", can_no: 81, can_name: "Kunti Desai" },
        {
          batch_no: "CPA-M7-01",
          can_no: 108,
          can_name: "Harshvardhan Agarwal"
        },
        { batch_no: "CPA-M7-01", can_no: 222, can_name: "Divya Jagannath" },
        { batch_no: "CPA-M7-01", can_no: 230, can_name: "Alka Narwani" },
        { batch_no: "CPA-M7-01", can_no: 233, can_name: "Rahul Naik" }
      ],
      batch2: [
        { batch_no: "CPA-M7-02", can_no: 866, can_name: "Ibrahim" },
        { batch_no: "CPA-M7-02", can_no: 887, can_name: "Tina Bendale" },
        { batch_no: "CPA-M7-02", can_no: 896, can_name: "Arun Purohit" },
        { batch_no: "CPA-M7-02", can_no: 915, can_name: "Vivek Velandy" },
        { batch_no: "CPA-M7-02", can_no: 934, can_name: "Murad Mohammad" },
        { batch_no: "CPA-M7-02", can_no: 993, can_name: "Harsh Shah" },
        { batch_no: "CPA-M7-02", can_no: 1012, can_name: "Pratima Chavan" },
        { batch_no: "CPA-M7-02", can_no: 661, can_name: "Meenaz Pagdiwala" }
      ],
      batch3: [
        { batch_no: "CPA-M7-03", can_no: 476, can_name: "Rumi Verma" },
        { batch_no: "CPA-M7-03", can_no: 395, can_name: "Umesh Bej" },
        { batch_no: "CPA-M7-03", can_no: 956, can_name: "Saumil Halani" },
        { batch_no: "CPA-M7-03", can_no: 716, can_name: "Sheetal Parab" },
        { batch_no: "CPA-M7-03", can_no: 756, can_name: "Nitin Pujara" },
        { batch_no: "CPA-M7-03", can_no: 766, can_name: "Puneet Saini" },
        { batch_no: "CPA-M7-03", can_no: 826, can_name: "Saurav Tekriwal" },
        { batch_no: "CPA-M7-03", can_no: 916, can_name: "naren" },
        { batch_no: "CPA-M7-03", can_no: 616, can_name: "roma Purohit" },
        { batch_no: "CPA-M7-03", can_no: 746, can_name: "Ankit ragava" },
        { batch_no: "CPA-M7-03", can_no: 736, can_name: "Ankur" }
      ],
      batch4: [
        { batch_no: "CPA-M7-04", can_no: 212, can_name: "mahesh" },
        { batch_no: "CPA-M7-04", can_no: 213, can_name: "rajesh" },
        { batch_no: "CPA-M7-04", can_no: 214, can_name: "jain" },
        { batch_no: "CPA-M7-04", can_no: 215, can_name: "johny" },
        { batch_no: "CPA-M7-04", can_no: 216, can_name: "paparaj" },
        { batch_no: "CPA-M7-04", can_no: 217, can_name: "moganlal" },
        { batch_no: "CPA-M7-04", can_no: 218, can_name: "lokesh" }
      ],
      batch5: [
        { batch_no: "CPA-M7-05", can_no: 219, can_name: "mahesh" },
        { batch_no: "CPA-M7-05", can_no: 220, can_name: "rajesh" },
        { batch_no: "CPA-M7-05", can_no: 210, can_name: "jain" },
        { batch_no: "CPA-M7-05", can_no: 209, can_name: "johny" },
        { batch_no: "CPA-M7-05", can_no: 206, can_name: "paparaj" },
        { batch_no: "CPA-M7-05", can_no: 205, can_name: "moganlal" },
        { batch_no: "CPA-M7-05", can_no: 202, can_name: "lokesh" }
      ],
      open: false
    };
  },
  watch: {
    batch1checkbox: function(value) {
      this.batchno = value.length;
    },
    batch2checkbox: function(value) {
      this.batch2no = value.length;
    },
    batch3checkbox: function(value) {
      this.batch3no = value.length;
    },
    batchno: function(value) {
      console.log(value)
      this.mainValue = this.batchno + this.batch2no + this.batch3no;
    },
    batch2no: function(value) {
      console.log(value)
      this.mainValue = this.batchno + this.batch2no + this.batch3no;
    },
    batch3no: function(value) {
      console.log(value)
      this.mainValue = this.batchno + this.batch2no + this.batch3no;
    }
  },
  mounted() {
    EventBus.$on("open-attendanceFillingForm-popup", () => {
      this.batch3checkbox = [];
      this.batch2checkbox = [];
      this.batch1checkbox = [];

      this.batch1.forEach(can => {
        this.batch1checkbox.push(can.can_name);
      });
      this.batch2.forEach(can => {
        // this.batch2checkbox=[]
        this.batch2checkbox.push(can.can_name);
      });
      this.batch3.forEach(can => {
        this.batch3checkbox.push(can.can_name);
      });
      this.open = true;
    });
  }
};
</script>
<style>
.collapseBorder {
  border-bottom: 0.5px solid;
  margin-bottom: 5px;
}
.popwidth .vs-popup {
  min-width: 700px;
  min-height: 620px;
}
</style>
