<template>
  <div>
    <vs-row vs-w="12" style="margin-top: 2%">
      <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
           <p><b>Reason</b></p>
      </vs-col>
      <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p><b>Date</b></p>
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-top: 2%" v-for="item in application_failures" :key="item.created_at">
       <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
           <p>{{item.application_status}}</p>
      </vs-col>
      <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p>{{item.created_at}}</p>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      application_failures: [],
    };
  },
  mounted(){ 
   this.getWebhookApplicationStatus();
   console.log("application failures",this.application_failures);
  },
  methods:{
    getWebhookApplicationStatus(){
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        identity: this.studentDetails.mwb.identity
      };
      // let url =  `https://liquiloans.milesforce.com/api/getLiveWebhookApplicationStatus`;
      let url =  `https://liquiloans.milesforce.com/api/getWebhookApplicationStatusMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Application Status", response.data.data);
          this.application_failures = response.data.data.application_failures;
          this.customDateFormat(this.application_failures);
         })
        .catch((error) => {
         this.handleError(error);
        });
    },
    customDateFormat(application_failures){
         for (let i = 0; i < application_failures.length; i++) {
          const element = application_failures[i];
          this.application_failures[i].created_at = moment(element.created_at).format("YYYY-MM-DD hh:mm:ss");
        }
    }
  }
}
</script>
<style>
</style>