<template>
  <vs-popup title="Visit Approval " :active.sync="open">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b style="color: gray">Approve the utilization for the following visit entry?</b>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <b style="color: gray">Candidate</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">{{details.person_name}}</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">{{details.identity}}</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">{{details.courses}}</vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <b style="color: gray">Visit Type</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">{{details.visit_status}}</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <b style="color: gray">SPOC</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">{{details.spoc_name}}</vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="9">
        <vs-button color="success" @click="decision('approve')" type="gradient">Approve</vs-button>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <vs-button color="danger" @click="decision('reject')" type="gradient">Reject</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("open-approval-popup", payload => {
      console.log(payload);
      this.details = payload;
      this.open = true;
    });
  },
  data() {
    return {
      details: "",
      open: false
    };
  },
  methods: {
    decision(choice) {
      let obj = {
        identity: this.details.identity,
        engagement_id: this.details.engagement_id,
        visit_approved: ""
      };
      if (choice === "approve") {
        obj.visit_approved = "Yes";
      } else if (choice === "reject") {
        obj.visit_approved = "Rejected";
      }
      // console.log(obj);
      let url = `${constants.SERVER_API}approveVisit`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log(response);
          let color = "success";
          if (response.data.status === "error") {
            color = "danger";
          }
          this.$vs.notify({
            title: response.data.status,
            text: response.data.message,
            color: color
          });
          EventBus.$emit("reload-visits");
          this.open = false;
        })
        .catch(error => {
          
          this.handleError(error);
        });
    }
  }
};
</script>

<style>
</style>
