<template>
  <vs-popup
    class="holamundo"
    title="Resend Email"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row>
      <vs-input
        class="w-full resend-mail"
        label="Resend Email"
        placeholder="Email Address"
        v-model="resend_email"
      />
    </vs-row>
    <vs-row style="padding-top:3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="#0044BA" type="border" @click="sent_mail()"
          >Send</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      open: false,
      resend_email: "",
      enrollment_id: ""
    };
  },
  mounted() {
    EventBus.$on("open-resend-email", tr => {
      console.log(tr);
      this.open = true;
      this.resend_email = "";
      this.enrollment_id = tr.id;
      console.log("gummy data", this.enrollment_id);
    });
  },
  methods: {
    sent_mail() {
      this.url = `${constants.SERVER_API}resendAgreementEmail`;
      this.post_API_obj = {
        enrollment_id: this.enrollment_id,
        email_id: this.resend_email
      };
      // console.log(this.post_API_obj);
      axios
        .post(this.url, this.post_API_obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status == "success") {
            this.open = false;
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>
<style>
.resend-mail>.vs-con-input>.vs-inputx {
  border:1px solid #0044ba !important
}
</style>
