<template>
  <vs-popup
    title="ADD A MOBILE NUMBER"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
        <!-- <vs-input style="width: 15%" v-model="code" label-placeholder="Code" /> -->
        <vs-input
          icon="add"
          v-model="number"
          label-placeholder="Mobile Number"
        />
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-end"
        vs-w="4"
      >
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="gradient"
          icon="add"
          @click="addMobile"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("add-mobile-number", (payload) => {
      this.payload = payload;
      this.open = true;
      this.number = "";
      this.code = "";
    });
  },
  data() {
    return {
      payload: "",
      open: false,
      number: "",
      code: "",
    };
  },
  methods: {
    async addMobile() {
      let obj = {
        can_id: this.payload.can_id,
        person_id: this.payload.person_id,
        person_name: this.payload.person_name,
        mobiles: this.number,
        country_code: this.code,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addMobile`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === `success`) {
            let mobileId = {
              id: response.data.new_mobile_id,
              masked_number: response.data.new_masked_mobile,
              invalid: 0,
            };
            this.$store.commit("UPDATE_MOBILE", [this.payload, mobileId]);
            EventBus.$emit("addedEWBMobile", mobileId);
            if (this.$route.path === "/queue") {
              // EventBus.$emit("update-queue-addition");
              EventBus.$emit("update-activelead-data");
            } else {
              EventBus.$emit("reload-bigpopup-data");
            }
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
              time: 10000,
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   } else {
          //     this.$vs.notify({
          //       title: "Network Error",
          //       text: "Please contact Admin",
          //       color: "danger",
          //       time: 10000
          //     });
          //   }
          // }
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
