<template>
  <div>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Source:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
        style="font-size: 15px; color: gray"
        >{{ studentDetails.source }} - {{ studentDetails.source_details }}</vs-col
      >
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Mobile:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <div
          v-for="(mob, index) in studentDetails.mobile"
          :key="index"
          style="margin-left: 1%"
          :data-tooltip="mob.masked_number"
        >
          <!-- <span class="tooltiptext">
            <b>{{mob.masked_number}}</b>
          </span> -->
          <vs-button
            color="warning"
            type="gradient"
            icon="local_phone"
            @click="mobilePopup(mob)"
          >
            <!-- <vs-icon icon="lcal_phone "></vs-icon> -->
          </vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="local_phone"
          @click="EmitEvent('add-mobile-number', studentDetails.mwb)"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Email:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <div
          v-for="(email, indexemail) in studentDetails.emails"
          :key="indexemail"
          style="margin-left: 1%"
          :data-tooltip="email.masked_email"
        >
          <!-- <span class="tooltiptext">
            <b>{{email.masked_email}}</b>
          </span> -->
          <vs-button
            style="margin-left: 1%"
            color="warning"
            type="gradient"
            icon="email"
            @click="emailPopup(email)"
          ></vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
        <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="email"
          @click="EmitEvent('add-email', studentDetails.mwb)"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Course:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="CPA"
          >CPA</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="CMA"
          >CMA</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="CFA"
          >CFA</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="FRM"
          >FRM</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="USP"
          >USP</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="DA"
          >DA</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="RPA"
          >RPA</vs-checkbox
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="FOF(O)"
          >FOF(O)</vs-checkbox
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        ></vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox disabled="true" color="dark" v-model="course" vs-value="AICPA"
          >AICPA</vs-checkbox
        >
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Company:</vs-col
      >
      <vs-col
        v-if="companyEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ studentDetails.cpa.company }}</b>
      </vs-col>
      <vs-col
        v-else-if="companyEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="editCompany" />
      </vs-col>
      <vs-col
        v-if="companyEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="create"
          @click="companyeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="companyEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="done_outline"
          @click="editCompanyAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Experience:</vs-col
      >
      <vs-col
        v-if="exEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ studentDetails.cpa.experience }}</b>
      </vs-col>
      <vs-col
        v-else-if="exEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="editExperience" />
      </vs-col>
      <vs-col
        v-if="exEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="create"
          @click="exeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="exEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="done_outline"
          @click="editExperienceAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Designation:</vs-col
      >
      <vs-col
        v-if="designationEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ studentDetails.cpa.designation }}</b>
      </vs-col>
      <vs-col
        v-else-if="designationEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="editDesignation" />
      </vs-col>
      <vs-col
        v-if="designationEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="create"
          @click="designationeditfunc"
        ></vs-button>
      </vs-col>
      <vs-col
        v-if="designationEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="done_outline"
          @click="editDesignationAPI"
        ></vs-button>
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Archived Education:</p>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ studentDetails.cpa.education }}</b>
      </vs-col>
    </vs-row> -->
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Educational Tags:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <v-select
          placeholder="Badges"
          multiple
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="select1"
          :options="options1"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <!-- <p style="color: gray; font-size: 15px">Educational Tags:</p> -->
        <vs-button size="small" color="danger" type="gradient" @click="updateEducation">
          <b>Update</b>
        </vs-button>
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Educational Tags:</p>
      </vs-col>-->
    </vs-row>
    <!-- <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <vs-select
          placeholder="Badges"
          multiple
          class="selectExample"
          label="Badges"
          v-model="select1"
        >
          <vs-select-item
            :key="index"
            :value="item.text"
            :text="item.text"
            v-for="(item,index) in options1"
          /></vs-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-button size="small" color="danger" type="gradient" @click="updateEducation">
          <b>Update Education</b>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        
        
      </vs-col>
    </vs-row>-->
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
        <vs-chip
          @click="remove(chip)"
          closable
          color="primary"
          v-for="chip in select1"
          :key="chip.index"
        >
          <b>{{ chip }}</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
      >Eligibility:</vs-col>
      
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"></vs-col>
    </vs-row>-->
    <!-- <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Eligibility Submitted Documents:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxdisable"
          v-model="submitted_documents"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submit === false"
          disabled
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          placeholder
          v-model="eligibility"
          :options="eligibilityOptions"
        ></v-select>
        <v-select
          v-if="submit === true"
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          placeholder
          v-model="eligibility"
          :options="eligibilityOptions"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="enablebutton === true"
          @click="addEligibility"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row> -->
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Requested For Loan Assistance</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxloandisable"
          v-model="applied_for_loan"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submitLoan === false"
          disabled
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          placeholder
          v-model="loan"
          :options="loanOptions"
        ></v-select>
        <v-select
          v-if="submitLoan === true"
          style="z-index: 1000; margin-top: 4%; margin: 10px"
          class="w-full"
          placeholder
          v-model="loan"
          :options="loanOptions"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="enablebuttonloan === true"
          @click="addLoan"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import constants from "../../../../../../constants.json";
import axios from "axios";
import EventBus from "../../../../eventbus";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  props: {
    studentDetails: {
      required: true,
    },
  },
  data() {
    return {
      applied_for_loan: "",
      checkboxloandisable: false,
      navTitleName: "",
      options1 : [],
      // options1: [
      //   "PUC",
      //   "INTER",
      //   "B.Com",
      //   "B.Com(P)",
      //   "M.Com",
      //   "M.Com(P)",
      //   "MBA",
      //   "MBA(P)",
      //   "CA",
      //   "CWA",
      //   "CS",
      //   "BMS",
      //   "BMS(P)",
      //   "BBA",
      //   "BBA(P)",
      //   "PGD",
      //   "PGD(P)",
      //   "LLB",
      //   "LLB(P)",
      //   "B.Tech",
      //   "B.Tech(P)",
      //   "B.Sc",
      //   "B.Sc(P)",
      //   "BA",
      //   "BA(P)",
      //   "CA-Inter",
      //   "CWA-Inter",
      //   "CS-Inter",
      //   "PG(P)",
      //   "CA Final",
      //   "CWA Final",
      //   "CS Final",
      //   "IGNOU - B.Com (CA)",
      //   "IGNOU - B.Com (CWA)",
      //   "IGNOU - B.Com (CS)",
      //   "MCA",
      //   "PGDPA",
      //   "PGDSF",
      //   "PGDM",
      //   "BBM",
      //   "SCESA",
      //   "CFA",
      //   "FRM",
      //   "CFA(P)",
      //   "MFA",
      //   "MFA(P)",
      //   "CMA(US)",
      //   "CPA(US)",
      //   // { text: "", value: 0 },
      //   // { text: "B.Com", value: 0 },
      //   // { text: "B.Com(P)", value: 18 },
      //   // { text: "M.Com", value: 1 },
      //   // { text: "M.Com(P)", value: 19 },
      //   // { text: "MBA", value: 2 },
      //   // { text: "MBA(P)", value: 20 },
      //   // { text: "CA", value: 3 },
      //   // { text: "CWA", value: 4 },
      //   // { text: "CS", value: 5 },
      //   // { text: "BMS", value: 6 },
      //   // { text: "BMS(P)", value: 21 },
      //   // { text: "BBA", value: 7 },
      //   // { text: "BBA(P)", value: 17 },
      //   // { text: "PGD", value: 8 },
      //   // { text: "PGD(P)", value: 22 },
      //   // { text: "LLB", value: 9 },
      //   // { text: "LLB(P)", value: 23 },
      //   // { text: "B.Tech", value: 10 },
      //   // { text: "B.Tech(P)", value: 24 },
      //   // { text: "B.Sc", value: 11 },
      //   // { text: "B.Sc(P)", value: 25 },
      //   // { text: "BA", value: 12 },
      //   // { text: "BA(P)", value: 26 },
      //   // { text: "CA-Inter", value: 13 },
      //   // { text: "CWA-Inter", value: 14 },
      //   // { text: "CS-Inter", value: 15 },
      //   // { text: "PG(P)", value: 16 },
      //   // { text: "CA Final", value: 16 },
      //   // { text: "CWA Final", value: 16 },
      //   // { text: "CS Final", value: 16 },
      //   // // { text: "IGNOU", value: 16 },
      //   // { text: "IGNOU - B.Com (CA)", value: 16 },
      //   // { text: "IGNOU - B.Com (CWA)", value: 16 },
      //   // { text: "IGNOU - B.Com (CS)", value: 16 },
      //   // { text: "MCA", value: 16 },
      //   // { text: "PGDPA", value: 16 },
      //   // { text: "PGDSF", value: 16 },
      //   // { text: "PGDM", value: 16 },
      //   // { text: "BBM", value: 16 },
      //   // { text: "SCESA", value: 16 },
      //   // { text: "CFA", value: 16 },
      //   // { text: "CFA(P)", value: 16 },
      //   // { text: "MFA", value: 16 },
      //   // { text: "MFA(P)", value: 16 },
      //   // { text: "CMA(US)", value: 16 },
      //   // { text: "CPA(US)", value: 16 }
      // ],
      cityOptions: ["HYD", "BNG", "BOM"],
      eligibilityOptions: [
        // { text: "", value: "" },
        // { text: "Pending", value: 4 },
        // { text: "Clear", value: 1 },
        // { text: "Check", value: 2 },
        // { text: "PGDPA", value: 3 }
        "Pending",
        "Clear",
        "Check",
        "PGDPA",
      ],
      loanOptions: [
        // { text: "", value: "" },
        // { text: "WIP", value: 3 },
        // { text: "Not Interested", value: 4 },
        // { text: "Not Eligible", value: 5 },
        // { text: "Postponed", value: 6 },
        // { text: "Rejected", value: 4 },
        // { text: "Processed", value: 1 },
        // { text: "Disbursed", value: 2 }
        "WIP",
        "Not Interested",
        "Not Eligible",
        "Postponed",
        "Rejected",
        "Processed",
        "Disbursed",
      ],
      editCompany: this.studentDetails.cpa.company,
      editDesignation: this.studentDetails.cpa.designation,
      editExperience: this.studentDetails.cpa.experience,
      companyEdit: false,
      companyEditbutton: true,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      additionalInfo: "",
      eligibility: "",
      loan: "",
      select1: [],
      submitted_documents: "",
      engagementsload: [],
      getMaskedNumber: [],
      course: [],
      submit: false,
      submitLoan: false,
      enablebutton: false,
      enablebuttonloan: false,
      checkboxdisable: false,
    };
  },
  watch: {
    eligibility: function () {
      this.enablebutton = true;
    },
    submit: function (value) {
      switch (value) {
        case true:
          this.eligibility = "Pending";
          break;
        case false:
          this.eligibility = "";
          this.enablebutton = false;
          break;
      }
    },
    submitted_documents: function (value) {
      this.submit = value;
    },
    applied_for_loan: function (value) {
      this.submitLoan = value;
    },
    submitLoan: function (value) {
      switch (value) {
        case true:
          if (this.studentDetails.loan_status === "") {
            this.loan = "WIP";
          } else {
            this.loan = this.studentDetails.loan_status;
          }
          this.enablebuttonloan = true;
          break;
        case false:
          this.loan = "";
          this.enablebuttonloan = false;
      }
    },
  },
  mounted() {
    this.getEducationTags();
    this.course = [];
    this.course = this.studentDetails.mwb.courses.split(",");
    // console.log(this.studentDetails);
    // this.select1 = [];
    if (this.studentDetails.mwb.education !== null) {
      this.select1 = this.studentDetails.mwb.education.split(",");
    } else {
      this.select1 = [];
    }
    // this.select1.push(this.studentDetails.education)
    if (this.studentDetails.mwb.submitted_documents !== "No") {
      this.submitted_documents = true;
      if (this.studentDetails.mwb.eligibility === "") {
        this.eligibility = "Pending";
      } else {
        this.eligibility = this.studentDetails.mwb.eligibility;
      }
    }
    if (this.studentDetails.mwb.applied_for_loan === 1) {
      this.applied_for_loan = true;
      if (this.studentDetails.mwb.loan === "") {
        this.eligibility = "WIP";
      } else {
        this.eligibility = this.studentDetails.mwb.loan;
        this.checkboxloandisable = false;
      }
    }
    // console.log("loan");
    // console.log(this.studentDetails.applied_for_loan);
    if (this.studentDetails.mwb.applied_for_loan === 1) {
      // console.log("this is loan status, ", this.studentDetails.loan_status);
      this.applied_for_loan = true;
      this.loan = this.studentDetails.mwb.loan_status;
      // console.log(this.loan);
    } else {
      this.applied_for_loan = false;
    }

    EventBus.$on("addedEWBMobile", (payload) => {
      this.studentDetails.mobile.push(payload);
    });
    EventBus.$on("addedEWBEmail", (payload) => {
      this.studentDetails.emails.push(payload);
    });
  },
  methods: {
    getEducationTags() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)

          if(response.data.length > 0){
            // for (let i = 0; i < response.data.length; i++) {
            //   this.options1.push({
            //     id : response.data[i],
            //     label : response.data[i]
            //   })
              
            // }
          this.options1 = response.data

              
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async addLoan() {
      let sendValue = "";
      // console.log(this.requested_loan)
      switch (this.applied_for_loan) {
        case true:
          sendValue = "Yes";
          break;
        case false:
          sendValue = "No";
          break;
      }
      // console.log(sendValue)
      let obj = {
        mwb_id: this.studentDetails.id,
        person_id: this.studentDetails.person_id,
        applied_for_loan: sendValue,
        loan_status: this.loan,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addAppliedForLoan`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.checkboxloandisable = true;
          console.log(response);
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Updated",
              text: "Successfully updated",
              color: "success",
              position: "top-center",
            });
          } else {
            this.$vs.notify({
              title: "Not Updated",
              text: response.data.message,
              color: "danger",
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
    },
    async addEligibility() {
      let submitvalue = "";
      switch (this.submitted_documents) {
        case true:
          submitvalue = "Yes";
          break;
        case false:
          submitvalue = "No";
          break;
      }
      let obj = {
        mwb_id: this.studentDetails.id,
        person_id: this.studentDetails.person_id,
        eligibility: this.eligibility,
        submitted_documents: submitvalue,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addEligibility`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.checkboxdisable = true;
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Updated",
              text: "Successfully updated",
              color: "success",
              position: "top-center",
            });
          } else {
            this.$vs.notify({
              title: "Not Updated",
              text: "An Error occured while updating, please try again",
              color: "danger",
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
      // console.log(postreq);
    },
    async mobilePopup(mobile) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-mobile-options-popup", [
            response.data,
            this.studentDetails,
            mobile,
          ]);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
        });
    },
    updateEducation() {
      // console.log(this.select1.join());
      // console.log(this.studentDetails);
      let obj = {
        mwb_id: this.studentDetails.id,
        person_id: this.studentDetails.person_id,
        education_tags: this.select1.join(),
      };
      axios
        .post(`${constants.SERVER_API}editEducation`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: response.data.status,
            text: response.data.message,
            color: "success",
          });
          // console.log(response);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
    },
    async emailPopup(email) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-email-options-popup", [
            response.data,
            this.studentDetails,
            email,
          ]);
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    remove(item) {
      this.select1.splice(this.select1.indexOf(item), 1);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    async editCompanyAPI() {
      let obj = {
        mwb_id: this.studentDetails.id,
        person_id: this.studentDetails.person_id,
        edited_company: this.editCompany,
      };
      axios
        .post(`${constants.SERVER_API}editCompany`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(obj);
          console.log(response);
          if (response.data.status === "success") {
            this.$store.commit("UPDATE_COMPANY", [this.studentDetails, this.editCompany]);
            this.companyeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Company details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the company of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error", error.response.status);
            if (error.response.status === 401) {
              this.EmitEvent("close-MWB-popup");
              this.ReDirect_to_login();
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Network Error!",
              color: "danger",
            });
          }
        });
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    async editDesignationAPI() {
      axios
        .post(
          `${constants.SERVER_API}editDesignation`,
          {
            mwb_id: this.studentDetails.id,
            person_id: this.studentDetails.person_id,
            edited_designation: this.editDesignation,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit("UPDATE_DESIGNATION", [
              this.studentDetails,
              this.editDesignation,
            ]);
            this.designationeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Designation details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the designation of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error", error.response.status);
            if (error.response.status === 401) {
              this.EmitEvent("close-MWB-popup");
              this.ReDirect_to_login();
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Network Error!",
              color: "danger",
            });
          }
        });
      // console.log(postreq);
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    async editExperienceAPI() {
      const obj = {
        mwb_id: this.studentDetails.id,
        person_id: this.studentDetails.person_id,
        edited_experience: this.editExperience,
      };
      axios
        .post(`${constants.SERVER_API}editExperience`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status === "success") {
            this.$store.commit("UPDATE_EXPERIENCE", [
              this.studentDetails,
              this.editExperience,
            ]);
            this.exeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Experience details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the experience of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error", error.response.status);
            if (error.response.status === 401) {
              this.EmitEvent("close-MWB-popup");
              this.ReDirect_to_login();
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Network Error!",
              color: "danger",
            });
          }
        });
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
  },
};
</script>

<style>
.gaps {
  margin-bottom: 4%;
}
</style>
