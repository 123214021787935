<template>
    <div>
        <!-- <vs-popup class="holamundo" title="Accelrator Session Registration" :active.sync="open_popup" @close="closePopup"> -->
            <vs-row style="margin-top: 3%;">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4.5">
                    <v-select placeholder="City" class="w-full cityDrop" v-model="selected_city"
                        :options="city_options"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4.5">
                    <v-select placeholder="Course" multiple class="w-full cityDrop" v-model="selected_course"
                        :options="course_options"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <vs-button color="dark" type="gradient" @click="storeAcceleratorSession">Register</vs-button>
                </vs-col>
            </vs-row>
            <vs-row vs-justify="center" style="margin-top: 5%;" v-if="selected_details.length != 0">
                <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <vs-card  v-for="(card_data, indextr) in selected_details" :key="indextr">
                        <h5 style="display: flex; justify-content: center; align-items: center;" >{{ card_data.course }}</h5>
                        <vs-row class="gaps" style="margin-top: 3%;">
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                                <h6 class="details-subhead">Start Date :</h6>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                                customDateFormatter(card_data.start_date) }}
                            </vs-col>
                        </vs-row>
                        <vs-row class="gaps">
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                                <h6 class="details-subhead">Start Time :</h6>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                                card_data.start_time }}
                            </vs-col>
                        </vs-row>
                        <vs-row class="gaps">
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                                <h6 class="details-subhead">Address :</h6>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-w="8">{{ card_data.venue_details }}
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </vs-col>
            </vs-row>

            <!-- <vs-row style="margin-top: 3%;">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="7">
                    <vs-button color="dark" type="gradient" @click="storeAcceleratorSession">Register</vs-button>
                </vs-col>
            </vs-row> -->
        <!-- </vs-popup> -->
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ApiServices from "../../../ApiServices.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import EventBus from "../../eventbus.js";

export default {
    props: ["accelerator_popup", "mwb", "email_data"],
    components: {
        Datepicker,
        "v-select": vSelect,
    },
    data() {
        return {
            open_popup: false,
            selected_city: null,
            selected_course: ['USP'],
            course_options: [
                // 'CPA',
                // 'CMA',
                'USP'
            ],
            city_options: [
                // {
                //     id: 1,
                //     label: 'AHMEDABAD',
                // },
                // {
                //     id: 2,
                //     label: 'BANGALORE',
                // },
                // {
                //     id: 3,
                //     label: 'CHENNAI',
                // },
                {
                    id: 4,
                    label: 'DELHI',
                },
                // {
                //     id: 5,
                //     label: 'ERNAKULAM',
                // },
                // {
                //     id: 6,
                //     label: 'KOLKATA',
                // },
                // {
                //     id: 7,
                //     label: 'HYDERABAD',
                // },
                {
                    id: 8,
                    label: 'MUMBAI',
                },
                // {
                //     id: 9,
                //     label: 'PUNE',
                // },

            ],
            sample_cpa_data: [
                { "int_city_id": 1, "start_date": "2023-12-03", "start_time": "15:00:00", "venue_details": "Miles Education, 704, 7th floor, Satya one complex, Opp. R3 Mall, Memnagar, Ahmedabad, Gujarat, 380052" },
                { "int_city_id": 2, "start_date": "2023-12-17", "start_time": "15:00:00", "venue_details": "Mantri Avenue, 2nd Floor, Miles Education (P) Ltd. KHB Games Village, Koramangala, Bengaluru, Karnataka 560047" },
                { "int_city_id": 4, "start_date": "2023-12-10", "start_time": "15:00:00", "venue_details": "Miles Education, 3rd-4th floor, OLD Fort, DLF Avenue complex, Saket District Centre, New Delhi, Delhi 110017" },
                // { "int_city_id": 6, "start_date": "2023-07-22", "start_time": "15:00:00", "venue_details": "Miles Education,  Apeejay House, Block-C, 4th Floor,  Park Street, West Bengal. Kolkata-700016. " },
                { "int_city_id": 7, "start_date": "2023-12-09", "start_time": "15:00:00", "venue_details": "Jayabheri Silicon Towers, Level -1, Hitech City Rd, Hyderabad, Telangana 500084" },
                { "int_city_id": 8, "start_date": "2023-12-16", "start_time": "15:00:00", "venue_details": "911/912, Wing A, Kanakia Wall Street, Andheri Kurla Road, Mumbai - 400093" },
                // { "int_city_id": 9, "start_date": "2023-01-06", "start_time": "15:00:00", "venue_details": "4, Rajiv Gandhi Salai,India TIDEL PARK,Chennai,Tamil Nadu 600113" },
                // { "int_city_id": 10, "start_date": "2023-01-07", "start_time": "15:00:00", "venue_details": "Manikchand Ikon, A Wing ,behind Sheraton Grand,opposite to Wadiya college, Sangamvadi,Pune, Maharashtra 411001" },

            ],
            sample_cma_data: [
                { "int_city_id": 1, "start_date": "2023-12-03", "start_time": "11:00:00", "venue_details": "Miles Education, 704, 7th floor, Satya one complex, Opp. R3 Mall, Memnagar, Ahmedabad, Gujarat, 380052" },
                { "int_city_id": 2, "start_date": "2023-12-17", "start_time": "11:00:00", "venue_details": "Mantri Avenue, 2nd Floor, Miles Education (P) Ltd. KHB Games Village, Koramangala, Bengaluru, Karnataka 560047" },
                { "int_city_id": 4, "start_date": "2023-12-10", "start_time": "11:00:00", "venue_details": "Miles Education, 3rd-4th floor, OLD Fort, DLF Avenue complex, Saket District Centre, New Delhi, Delhi 110017" },
                // { "int_city_id": 6, "start_date": "2023-07-22", "start_time": "11:00:00", "venue_details": "Miles Education,  Apeejay House, Block-C, 4th Floor,  Park Street, West Bengal. Kolkata-700016" },
                { "int_city_id": 7, "start_date": "2023-12-09", "start_time": "11:00:00", "venue_details": "Jayabheri Silicon Towers, Level -1, Hitech City Rd, Hyderabad, Telangana 500084" },
                { "int_city_id": 8, "start_date": "2023-12-16", "start_time": "11:00:00", "venue_details": "911/912, Wing A, Kanakia Wall Street, Andheri Kurla Road, Mumbai - 400093" },
                // { "int_city_id": 9, "start_date": "2023-01-06", "start_time": "11:00:00", "venue_details": "4, Rajiv Gandhi Salai,India TIDEL PARK,Chennai,Tamil Nadu 600113" },
                // { "int_city_id": 10, "start_date": "2023-01-07", "start_time": "11:00:00", "venue_details": "Manikchand Ikon, A Wing ,behind Sheraton Grand,opposite to Wadiya college, Sangamvadi,Pune, Maharashtra 411001" },
            ],
            sample_usp_data: [
                // { "int_city_id": 1, "start_date": "2024-09-15", "start_time": "4-6 PM", "venue_details": "Miles Education, 704, 7th floor, Satya one complex, Opp. R3 Mall, Memnagar, Ahmedabad, Gujarat, 380052" },
                // { "int_city_id": 2, "start_date": "2024-08-25", "start_time": "4-6 PM", "venue_details": "Mantri Avenue, 2nd Floor, Miles Education (P) Ltd. KHB Games Village, Koramangala, Bengaluru, Karnataka 560047" },
                // { "int_city_id": 3, "start_date": "2024-04-27", "start_time": "3-5 PM", "venue_details": "4, Rajiv Gandhi Salai,India TIDEL PARK,Chennai,Tamil Nadu 600113" },
                { "int_city_id": 4, "start_date": "2024-09-22", "start_time": "4-6 PM", "venue_details": "Miles Education, 3rd-4th floor, OLD Fort, DLF Avenue complex, Saket District Centre, New Delhi, Delhi 110017" },
                // { "int_city_id": 5, "start_date": "2024-04-24", "start_time": "3-5 PM", "venue_details": "11th Floor, Muthoottu, Royal tower, 36, Banerji Rd, Kaloor, Ernakulam, Kochi, Kerala 682017" },
                // { "int_city_id": 6, "start_date": "2023-07-22", "start_time": "11:00:00", "venue_details": "Miles Education,  Apeejay House, Block-C, 4th Floor,  Park Street, West Bengal. Kolkata-700016" },
                // { "int_city_id": 7, "start_date": "2024-08-24", "start_time": "5-7 PM", "venue_details": "Jayabheri Silicon Towers, Level -1, Hitech City Rd, Hyderabad, Telangana 500084" },
                { "int_city_id": 8, "start_date": "2024-09-21", "start_time": "4-6 PM", "venue_details": "911/912, Wing A, Kanakia Wall Street, Andheri Kurla Road, Mumbai - 400093" },
                // { "int_city_id": 9, "start_date": "2024-04-20", "start_time": "3-5 PM", "venue_details": "Manikchand Ikon, A Wing ,behind Sheraton Grand,opposite to Wadiya college, Sangamvadi,Pune, Maharashtra 411001" },
            ],
            selected_details: [],
            email: '',
            cpa_data: '',
            cma_data: '',
            usp_data:''
        }
    },
    watch: {
        accelerator_popup: function (val) {
            this.open_popup = val;
        },
        selected_city(val) {
            this.selected_details = [];
            console.log("selectedcity", val, this.selected_course);
                if(this.selected_course.length == 1){
                    for (let i = 0; i < this.selected_course.length; i++) {
                    const element = this.selected_course[i];
                    if(element == 'CPA'){
                        this.cma_data = '';
                        for (let j = 0; j < this.sample_cpa_data.length; j++) {
                        const ele = this.sample_cpa_data[j];
                        ele.course = 'CPA'
                        const city_id = ele.int_city_id;
                        if (val.id == city_id) {
                            this.selected_details.push(ele);
                            this.cpa_data = ele;
                        }
                        }
                    }else if(element == 'CMA'){
                        this.cpa_data = '';
                        for (let j = 0; j < this.sample_cma_data.length; j++) {
                        const ele = this.sample_cma_data[j];
                        ele.course = 'CMA'
                        const city_id = ele.int_city_id;
                        if (val.id == city_id) {
                            this.selected_details.push(ele);
                            this.cma_data = ele;
                        }
                        }
                    }else if(element == 'USP'){
                        this.cma_data = '';
                        this.cpa_data = '';
                        for (let j = 0; j < this.sample_usp_data.length; j++) {
                        const ele = this.sample_usp_data[j];
                        ele.course = 'USP'
                        const city_id = ele.int_city_id;
                        if (val.id == city_id) {
                            this.selected_details.push(ele);
                            this.usp_data = ele;
                        }
                        }
                    }
                }
                }
                else if(this.selected_course.includes('CPA','CMA')){
                    let arr = [];
                    for (let j = 0; j < this.sample_cpa_data.length; j++) {
                        const ele = this.sample_cpa_data[j];
                        ele.course = 'CPA'
                        const city_id = ele.int_city_id;
                        if (val.id == city_id) {
                            arr.push(ele);
                            this.cpa_data = ele;
                        }
                    }
                    for (let j = 0; j < this.sample_cma_data.length; j++) {
                        const ele = this.sample_cma_data[j];
                        ele.course = 'CMA'
                        const city_id = ele.int_city_id;
                        if (val.id == city_id) {
                            arr.push(ele);
                            this.cma_data = ele;
                        }
                    }
                    this.selected_details = arr;
                }

        },
        selected_course(val) {
            this.selected_details = [];
            if(val.length == 1){
                    for (let i = 0; i < val.length; i++) {
                    const element = val[i];
                    if(element == 'CPA'){
                        this.cma_data = '';
                        for (let j = 0; j < this.sample_cpa_data.length; j++) {
                        const ele = this.sample_cpa_data[j];
                        ele.course = 'CPA'
                        const city_id = ele.int_city_id;
                        if (this.selected_city.id == city_id) {
                            this.selected_details.push(ele);
                            this.cpa_data = ele;
                        }
                        }
                    }else if(element == 'CMA'){
                        this.cpa_data = '';
                        for (let j = 0; j < this.sample_cma_data.length; j++) {
                        const ele = this.sample_cma_data[j];
                        ele.course = 'CMA'
                        const city_id = ele.int_city_id;
                        if (this.selected_city.id == city_id) {
                            this.selected_details.push(ele);
                            this.cma_data = ele;
                        }
                        }
                    }else if(element == 'USP'){
                        this.cma_data = '';
                        this.cpa_data = '';
                        for (let j = 0; j < this.sample_usp_data.length; j++) {
                        const ele = this.sample_usp_data[j];
                        ele.course = 'USP'
                        const city_id = ele.int_city_id;
                        if (this.selected_city.id == city_id) {
                            this.selected_details.push(ele);
                            this.usp_data = ele;
                        }
                        }
                    }
                }
                }
                else if(val.includes('CPA','CMA')){
                    let arr = [];
                    for (let j = 0; j < this.sample_cpa_data.length; j++) {
                        const ele = this.sample_cpa_data[j];
                        ele.course = 'CPA'
                        const city_id = ele.int_city_id;
                        if (this.selected_city.id == city_id) {
                            arr.push(ele);
                            this.cpa_data = ele;
                        }
                    }
                    for (let j = 0; j < this.sample_cma_data.length; j++) {
                        const ele = this.sample_cma_data[j];
                        ele.course = 'CMA'
                        const city_id = ele.int_city_id;
                        if (this.selected_city.id == city_id) {
                            arr.push(ele);
                            this.cma_data = ele;
                        }
                    }
                    this.selected_details = arr;
                }
        }
    },
    mounted() {
        // console.log("mailss", this.email_data);
    },
    methods: {
        customDateFormatter(date) {
            return moment(date, 'YYYY-MM-DD').format("DD-MMM-YYYY");
        },
        getEmail() {
            for (let i = 0; i < this.email_data.length; i++) {
                const element = this.email_data[i];
                if (element.pri_mary == 1) {
                    this.email = element.email
                }
            }
        },
        refresh() {
            this.$emit('close-accelerator-popup')
            // this.selected_city = ''
            this.selected_course = ['USP']
            this.selected_details = []
        },
        storeAcceleratorSession() {
            this.getEmail();
            if (this.selected_city != null && this.selected_course != null && this.email != '') {
                this.$vs.loading();
                let getParams = {
                    "mwb_id": this.mwb.id,
                    "course": this.selected_course.toString(),
                    "city": this.selected_city.id,
                    "email": this.email,
                    "person_name": this.mwb.person_name,
                    "identity": this.mwb.identity,
                    "is_enrolled": '',
                    "person_id": this.mwb.person_id

                };
                if (this.mwb.enrollments.length != 0) {
                    getParams.is_enrolled = 1
                } else {
                    getParams.is_enrolled = 0
                }
                if(this.cpa_data != ''){
                    getParams.cpa = this.cpa_data
                }
                if(this.cma_data != ''){
                    getParams.cma = this.cma_data
                }
                if(this.usp_data != ''){
                    getParams.usp = this.usp_data
                }
                console.log("getParams", getParams);
                ApiServices.registerForAcceleratorSession(getParams)
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.$vs.notify({
                                title: response.data.message,
                                color: "success",
                            });
                            EventBus.$emit('select-orientation-session');
                            this.refresh();
                        }
                        console.log("response", response);
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: error,
                            color: "danger",
                        });
                        this.refresh();
                    });
            }
            else if (this.selected_city == null) {
                this.$vs.notify({
                    title: "Please select city",
                    color: "danger",
                });
            } else if (this.selected_course == null) {
                this.$vs.notify({
                    title: "Please select course",
                    color: "danger",
                });
            } else if (this.email == '') {
                this.$vs.notify({
                    title: "Please mark any email as primary",
                    color: "danger",
                });
            }
        }
    }
}
</script>
<style>
/* .cityDrop>.vs__dropdown-menu {
    position: relative;
    z-index: 999999 !important;
} */
</style>
