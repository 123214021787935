<template>
  <div>
    <vs-popup :id="'assignlead'" :title="'Assign Leads'" :active.sync="open">
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">Search</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
          <vs-chip color="warning" v-for="(chip, index) in search" :key="index">{{chip}}</vs-chip>
        </vs-col>
      </vs-row>-->
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-w="2" style="margin-bottom: 1.5%">
          <b style="color: gray">CITY</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="10"
        >
          <v-select
            :options="cities"
            v-model="cityselected"
            style="width:450px; position: fixed; z-index:1000"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-w="2" style="margin-bottom: 1.5%">
          <b style="color: gray">SPOC</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="10"
        >
          <v-select
            label="full_name"
            :multiple="true"
            :options="cityspoc"
            v-model="value1"
            style="width:450px; position: fixed; z-index:900"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="12"
        >
          <vs-button color="dark" type="filled" @click="assignAPI"
            >Assign</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
export default {
  data() {
    return {
      open: false,
      decidebool: false,
      cityspoc: [],
      title: "Example",
      spoc_names: [],
      totalleadcount: "",
      value1: "",
      cities: [],
      cityselected: "",
      selected: "",
      deselected: "",
      allselect: "",
      sendobj: "",
      search: "",
      rawBdeSR: [],
      mergespocs: [],
      logged_in_id:"",
      logged_in_team: '',
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    this.logged_in_team = localStorage.getItem("team")
    this.logged_in_id = localStorage.getItem("spoc_id");
    EventBus.$on(
      "assign-sr-multiple",
      (payload, payload2, selectall, sendobj, totalleadcount) => {
        console.log("step 3", sendobj);
        // console.log(payload);
        // console.log(payload2);
        // console.log(selectall);
        // console.log("multiple", sendobj);
        // console.log(totalleadcount);
        this.cities = [];
        // this.getSpoc();
        this.getSRSpocs();
        this.decidebool = false;
        this.value1 = "";
        this.totalleadcount = totalleadcount;
        this.selected = payload;
        this.deselected = payload2;
        let lengthstring = 0;
        this.allselect = selectall;
        this.sendobj = sendobj;
        this.filtersearch();
        if (selectall === true) {
          this.decidebool = true;
          lengthstring = totalleadcount - payload2.length;
          let title = "";
          title = `Assigning ${lengthstring} leads`;
          this.title = title;
        } else {
          lengthstring = payload.length;
          this.title = `Assigning ${lengthstring} lead`;
        }
        if (lengthstring > 1) {
          this.decidebool = true;
        }
        this.open = true;
      }
    );
  },
  watch: {
    cityselected(value) {
      this.filterspoc(value);
    },
  },
  methods: {
    filterspoc(city) {
      this.value1 = "";
      this.cityspoc = [];
      this.mergespocs = Array.from(new Set(this.mergespocs));
      this.mergespocs.forEach((spoc) => {
        // console.log("data", spoc)
        if (spoc.city === city) {
          this.cityspoc.push(spoc);
          // console.log("data", this.cityspoc)
        }
      });
    },
    filtersearch() {
      let course = [];
      if (this.sendobj.courses !== "") {
        course = this.sendobj.courses.split(",");
      }
      let levels = [];
      if (this.sendobj.levels !== "") {
        levels = this.sendobj.levels.split(",");
      }
      let bde = [];
      if (this.sendobj.spoc_ids !== "") {
        bde = this.sendobj.spoc_ids.split(",");
      }
      // console.log(course);
      // console.log(levels);
      // console.log(bde);
      this.mergespocs = Array.from(new Set(this.mergespocs));
      console.log(this.mergespocs)
      let bdenames = [];
      bde.forEach((id) => {
        // console.log(this.spoc_names);
        this.mergespocs.forEach((spoc) => {
          if (parseInt(id) === spoc.id) {
            bdenames.push(spoc.full_name);
          }
        });
      });
      // console.log(bdenames);
      this.search = course.concat(levels, bdenames);
      // console.log(this.search);
    },
    assignAPI() {
      this.$vs.loading({
        type: "default",
      });
      if (this.value1 === "") {
        this.$vs.notify({
          title: "Select a Spoc",
          text: "Select a Spoc to assign the selected leads",
          color: "danger",
        });
      } else {
        let selected = [];
        let deselected = [];
        if (this.allselect === true) {
          selected = [];
          deselected = [];
          this.deselected.forEach((each) => {
            deselected.push(each.id);
          });
        } else {
          selected = [];
          this.selected.forEach((each) => {
            selected.push(each.id);
          });
          deselected = [];
        }
        let allselect = "";
        if (this.allselect == true) {
          allselect = "yes";
        } else {
          allselect = "no";
        }
        this.sendobj.selected_lead_ids = selected.join();
        if (this.sendobj.selected_lead_ids === "") {
          this.sendobj.selected_lead_ids = null;
        }
        this.sendobj.excluded_lead_ids = deselected.join();
        if (this.sendobj.excluded_lead_ids === "") {
          this.sendobj.excluded_lead_ids = null;
        }
        // this.sendobj.spoc_name = this.value1.first_name;
        let ids = [];
        if (this.decidebool === false) {
          this.sendobj.spoc_id = this.value1.id;
        } else {
          this.value1.forEach((value) => {
            ids.push(value.id);
          });
          this.sendobj.spoc_id = ids.join();
        }
        let names = [];
        if (this.decidebool === false) {
          this.sendobj.spoc_name = this.value1.full_name;
          
        } else {
          this.value1.forEach((value) => {
            // console.log("dast", value);
            names.push(value.full_name);
          });
          this.sendobj.spoc_name = names.join();
          // console.log("dast", this.sendobj.spoc_name);
        }

        let spocsNew = [];
        if (this.decidebool === false) {
          this.sendobj.spocs = [
            { spoc_name: this.value1.full_name, spoc_id: this.value1.id },
          ];
        } else {
          this.value1.forEach((value) => {
            let obj = {
              spoc_name: value.full_name,
              spoc_id: value.id,
            };
            spocsNew.push(obj);
          });
          this.sendobj.spocs = spocsNew;
        }

        this.sendobj.select_all_leads_state = allselect;
        let url = `${constants.SERVER_API}assignSrLeads`;
        if (this.sendobj.my_focus) {
          this.sendobj.my_focus = this.sendobj.my_focus.toLowerCase();
        }
        this.sendobj.count = 0;
        if (this.sendobj.my_focus === "untapped without level") {
          this.sendobj.my_focus = "untapped_without_level";
        } else if (this.sendobj.my_focus === "untapped with level") {
          this.sendobj.my_focus = "untapped_with_level";
        }
        console.log(this.sendobj);
        // this.$vs.loading.close();
        axios
          .post(url, this.sendobj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              EventBus.$emit("reload-assignments");
              this.open = false;
            }
            this.$vs.loading.close();
            // console.log(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpoc() {
      this.mergespocs = [];
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          
          this.spoc_names.forEach((spoc) => {
            this.mergespocs.push(spoc);
            console.log("spoc", this.mergespocs);
            
          });

          this.getSRSpocs();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs() {
      let spoc_id = localStorage.getItem("spoc_id")
      let url = ''
      if (this.logged_in_team == 'GM' && localStorage.getItem("sub_team") != 'GM') {
       url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${spoc_id}`;
      }else{
       url = `${constants.SERVER_API}getSRSpocs`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.rawBdeSR = response.data.spocs;
          let sr_spocs = [];
          for (let j = 0; j < response.data.spocs.length; j++) {
            const element = response.data.spocs[j];
            if (element.team == 'SR' && element.sub_team == 'SR') {
              sr_spocs.push(element);
            }
          }
          this.rawBdeSR = sr_spocs;
          // console.log("this.mergespocs", this.rawBdeSR)
          this.rawBdeSR.forEach((spoc) => {
            this.mergespocs.push(spoc);
          });
          this.getMergeSpocs();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getMergeSpocs() {
      var unique = this.mergespocs
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          var obj = {
                id: key,
                label: key,
            };
            if (
            obj.label !== "null"
            ) {
                this.cities.push(key);
            }
        }
      }
      this.cities = Array.from(new Set(this.cities));
      this.cities = this.sortArrayAlphabetically(this.cities);
      let ids = this.mergespocs.map((o) => o.id);
      
      this.mergespocs = this.mergespocs.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      // console.log("this.mergespocs", this.mergespocs);
    },
  },
};
</script>

<style></style>