<template>
  <vs-popup
    vs-type="flex"
    :id="'popup'"
    class="size popsize"
    title="Attendance"
    :active.sync="open"
  >
    <vs-row>
      <vs-card>
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <h6>Date</h6>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col">
                <div class="vx-col">
                  <datepicker
                    class="datepicker datepic"
                    placeholder="Select Date"
                    style="width: 120px;"
                    v-model="date"
                    size="120px"
                  ></datepicker>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <h6>Venue</h6>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <v-select
                  class="w-full"
                  placeholder="Venue"
                  v-model="select4"
                  :options="Venue"
                  label="text"
                  width="120px"
                ></v-select>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <h6>Course</h6>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="3">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <vs-radio v-model="radios1" vs-value="CPA">CPA</vs-radio>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="3">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <vs-radio v-model="radios1" vs-value="CMA">CMA</vs-radio>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <h6>CPA Class Subject</h6>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <v-select
                  class="w-full"
                  placeholder="CPA Classes"
                  v-model="select3"
                  :options="subject"
                  label="text"
                  width="120px"
                ></v-select>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <h6>Trainer Name</h6>
              </div>
            </div>
          </vs-col>
          <vs-col vs-w="6">
            <div class="vx-row mb-6">
              <div class="vx-col w-full con-select-example">
                <v-select
                  class="w-full"
                  placeholder="Trainer Name"
                  v-model="select2"
                  :options="traineename"
                  label="text"
                  width="120p"
                ></v-select>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </vs-card>
      <vs-row>
        <vs-spacer></vs-spacer>
        <vs-button color="success" @click="getalldata()" type="filled">ADD</vs-button>
        <vs-spacer></vs-spacer>
      </vs-row>
    </vs-row>
  </vs-popup>
</template>


<script>
import EventBus from "../eventbus.js";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
  watch: {
    radios1: function(value) {
      this.subject = [];
      this.traineename = [];
      if (value == "CPA") {
        this.subject1.forEach(eachsubject => {
          this.subject.push(eachsubject);
        });
        this.traineename1.forEach(eachname => {
          this.traineename.push(eachname);
        });
      } else if (value == "CMA") {
        this.subject2.forEach(eachsubject => {
          this.subject.push(eachsubject);
        });
        this.traineename2.forEach(eachname => {
          this.traineename.push(eachname);
        });
      }
    }
  },
  data() {
    return {
      open: false,
      subject: [],
      traineename: [],
      radios1: "",
      date: new Date(),
      student_count: "0",
      student_name: "",
      demo_classes: "0",
      repeat_classes: "0",
      M_tea: "0",
      Lunch: "0",
      E_tea: "0",
      petty_cash: "",
      number1: 0,
      number2: 0,
      number3: 0,
      select1: 0,
      select2: 0,
      select3: 0,
      select4: 0,
      batchNo: [
        {
          batch: "CPA-M7-01",
          value: 1,
          students: ["naren", "suren", "laren", "paren", "danen", "jaren"]
        },
        {
          batch: "CPA-M7-02",
          value: 2,
          students: ["naren", "suren", "laren", "paren", "danen", "jaren"]
        },
        {
          batch: "CPA-M7-03",
          value: 3,
          students: ["naren", "suren", "laren", "paren", "danen", "jaren"]
        }
      ],
      traineename1: [
        { text: "Adnan Khan ", value: 1 },
        { text: "Ranjeet", value: 2 },
        { text: "Srinivas Soni", value: 3 },
        { text: "Vivek Nathan", value: 4 },
        { text: "Phanindra", value: 5 },
        { text: "Mahmood", value: 6 },
        { text: "Chaitanya", value: 7 },
        { text: "Deepika Jain", value: 8 }
      ],
      traineename2: [
        { text: "Durga prasad", value: 1 },
        { text: "Brahmaiah Raju", value: 2 },
        { text: "Arun", value: 3 },
        { text: "Narasimham", value: 4 },
        { text: "Sanchita Patnaik", value: 5 },
        { text: "Birendra Maharana", value: 6 },
        { text: "Pavan Kumar", value: 7 },
        { text: "Amol Darak", value: 8 }
      ],
      subject1: [
        { text: "AUD", value: 1 },
        { text: "FAR", value: 2 },
        { text: "BEC", value: 3 },
        { text: "REG", value: 4 }
      ],
      subject2: [
        { text: "AUD", value: 1 },
        { text: "FAR", value: 2 },
        { text: "BEC", value: 3 },
        { text: "REG", value: 4 }
      ],
      Venue: [
        { text: "A-Block", value: 1 },
        { text: "B-Block", value: 2 },
        { text: "C-Block", value: 3 },
        { text: "D-Block", value: 4 }
      ]
    };
  },
  methods: {
    getalldata: function() {
      this.open = false;
    }
  },
  mounted() {
    EventBus.$on("open-attendence-popup", () => {
      this.open = true;
    });
    EventBus.$on("new-count", payload => {
      this.student_count = payload;
      // console.log(this.student_count = payload)
    });
  },
  components: {
    Datepicker,
    "v-select": vSelect
  }
};
</script>
<style>
.datepic input {
  width: 125px;
}
.datepic .vdp-datepicker__calendar {
  width: 250px;
}
</style>

