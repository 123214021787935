<template>
  <div>
    <div
      v-show="play_audio"
    >
      <div id="mydiv">
        <div id="mydivheader">
          <vs-row class="py-1">
            <vs-col
              vs-type="flex"
              vs-w="11"
              vs-align="center"
              style="padding: 0px"
            >
              <p class="ml-2" style="font-size: 1.2rem">
                {{ Person_name }} - {{ contact_type }} - {{ directory }}
              </p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-w="1"
              vs-align="center"
              vs-justify="flex-end"
              style="padding: 0px"
            >
              <vs-button
                color="primary"
                size="small"
                type="border"
                @click="play_audio = false"
                icon="cancel"
              ></vs-button>
            </vs-col>
          </vs-row>
        </div>
        <div style="margin: 10px">
          <vs-row style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="flex-start"
              vs-w="12"
            >
              {{ call_time }}
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="flex-start"
              vs-w="12"
            >
              <b> {{ contact_number }} ( {{ Person_name }} ) </b>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="flex-start"
              vs-w="12"
            >
              <audio id="my-audio" controls>
                <source :src="audiolink" type="audio/mpeg" />
                <!-- <source src="https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/1593_1674012863000.mp3" /> -->
              </audio>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="12" style="text-align: center">
              <vs-button size="small" color="transparent" @click="backward()">
                <vs-icon icon="replay_10" color="black" size="medium"></vs-icon>
              </vs-button>
              <vs-button size="small" color="transparent" @click="forward()">
                <vs-icon
                  icon="forward_10"
                  color="black"
                  size="medium"
                ></vs-icon>
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EventBus from "../../eventbus.js";
export default {
  data() {
    return {
      Person_name: "",
      contact_type: "",
      play_audio: false,
      directory: "",
      call_time: "",
      contact_number: "",
      audiolink: "",
    };
  },
  mounted() {
    this.dragElement();
    EventBus.$on("open-call-recording-popup", (payload, payload2) => {
        this.play_audio = true;
        this.contact_type = payload2.contact_type;
        this.directory = payload2.directory;
        this.call_time = this.getMomentDateFromTimestamp(payload2.time);
        this.Person_name = payload2.person_name;
        this.audiolink = payload;
        let audioplayer = document.getElementById("my-audio");
        audioplayer.load();
    })
    EventBus.$on("open-call-recording-popup-chat-history", (payload, payload2, payload3) => {
        this.play_audio = true;
        this.contact_type = payload2.contact_type;
        this.directory = payload2.directory;
        this.call_time = this.getMomentDateFromTimestamp(payload2.time);
        this.Person_name = payload3.person_name;
        this.audiolink = payload;
        let audioplayer = document.getElementById("my-audio");
        audioplayer.load();
    })
  },
  methods: {
    dragElement() {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      var elmnt = document.getElementById("mydiv")
      if (document.getElementById(elmnt.id + "header")) {
      document.getElementById(elmnt.id + "header").onmousedown = (e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
        // call a function whenever the cursor moves:
        document.onmousemove = (e)=>{
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        };
          };
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      document.getElementById("mydiv").onmousedown =(e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
      };
    }
    },
    hidePhoneNumber(value){
    //  console.log("val",value)
     var number = value;
     var mobile = ''
     if(number.slice(0,1)=='+'){
       mobile = number.substring(3)
       var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
       return phone;
     }else{
       return number.replace(number.substring(0,6), 'XXXXXX');
     }
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value / 1000).format("DD-MMM-YYYY HH:mm");
      return dateString;
    },
    forward() {
      var aud = document.getElementById("my-audio");
      // console.log("aud", vid);
      aud.currentTime += 10;
    },
    backward() {
      var aud = document.getElementById("my-audio");
      aud.currentTime -= 10;
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
  },
};
</script>

<style>
#mydiv {
  position: absolute;
  z-index: 20000000;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  top: 17%;
  left: 23%;
  border-radius: 10px;
  width: 500px;
  
}
#mydivheader {
  /* padding: 10px; */
  cursor: move;
  z-index: 20000000;
  background: rgba(0,0,0,.05);
  color: rgb(26, 21, 21);
}
</style>