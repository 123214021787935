<template>
  <vs-popup
    class="popupwidth"
    :id="'MissedCallPopup'"
    :title="'Are you sure you want to call ?'"
    :active.sync="open"
  >
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button
          style=" margin-left: 2%"
          color="primary"
          type="border"
          icon="local_phone"
          @click="openEngagementPopup"
          >YES</vs-button
        >
        <vs-button
          style=" margin-left: 2%"
          color="primary"
          type="border"
          @click="open = false"
          >NO</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  // prop: ["userdata"],
  mounted() {
    EventBus.$on("open-missed-call-popup", (payload, payload2) => {
      console.log(payload);
      this.userdata = payload;
      this.open = true;
      this.url = payload2;
    });
    document.getElementById("MissedCallPopup").children[1].style.marginBottom =
      "25%";
    // document.getElementById("addLeadForm").children[1].style.height = "83%";
  },
  data() {
    return {
      userdata: "",
      open: false,
      hidden: true,
      url: ""
    };
  },
  methods: {
    switchHidden() {
      this.hidden = !this.hidden;
    },
    async openEngagementPopup() {
      console.log("user dataa popup---",this.userdata);
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error"
            });
            return;
          }
        }
      }
      let params = {};
      switch (this.url) {
        case "clickToCallPusher":
          this.url = `${constants.SERVER_API}clickToCallPusher?mobile_id=${this.userdata.mobile_id}`;
          break;
        case "clickToCallUntracked":
          this.url = constants.SERVER_API + "clickToCallUntracked";
          params.phone_number = this.userdata.phone_number;
          break;
      }
      axios
        .get(this.url, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
            // "content-type": "application/x-www-form-urlencoded;charset=utf-8"
          }
        })
        .then(() => {
          if (this.userdata.person_name === null) {
            this.open = false;
            EventBus.$emit("open-untracked-details-popup", this.userdata);
            console.log("untracekd popup");
          } else {
            this.openBigPopup(this.userdata.mwb_id);
            this.open = false;
            // console.log("mwb popup");
            // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${this.userdata.can_id}&person_id=${this.userdata.person_id}`;
            // axios
            //   .get(url, {
            //     headers: {
            //       Authorization: `Bearer ${localStorage.userAccessToken}`
            //     }
            //   })
            //   .then(response => {
            //     console.log(response);
            //     this.$store.commit("UPDATE_NEW_DATA", [
            //       this.userdata,
            //       response.data.engagements,
            //       response.data.mobile,
            //       response.data.emails
            //     ]);
            //     console.log(this.$store.getters.getObject(this.userdata));
            //     this.open = false;
            //     EventBus.$emit(
            //       "open-student-details-popup",
            //       this.$store.getters.getObject(this.userdata)
            //     );
            //   });
          }
        })
        .catch(error => {
          // if (error.response) {
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    }
  }
};
</script>

<style></style>
