<template>
    <vs-popup
       v-if="open"
        class="holamundo"
        :id="'LoanPopup'"
        title=""
        :active.sync="open"
        :before-close="handleClose"
        style="z-index: 53003"
        width="20%"
    >
        <vx-card actionable class="cardx" title="CIBIL CHECK" v-if="open">
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span> Applicant Type</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                <v-select
                        placeholder=""
                        style="z-index: 1000"
                        class="w-full"
                        v-model="applicant_type"
                        :options="applicant_type_list"
                    ></v-select>  
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Looking A Loan For</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                    v-if ="applicant_type === 'Student'"
                        style="margin-right: 3%"
                        color="dark"
                        v-model="loanCheckType"
                        vs-value="self"
                        vs-name="loanCheck"
                        >Self</vs-radio
                    >
                    <vs-radio
                    v-if ="applicant_type !== 'Student'"
                        style="margin-right: 3%"
                        color="dark"
                        v-model="loanCheckType"
                        vs-value="self"
                        vs-name="loanCheck"
                        >Self</vs-radio
                    >
                    <vs-radio
                        v-if ="applicant_type === 'Student'"
                        disabled = "true"
                        color="dark"
                        v-model="loanCheckType"
                        vs-value="supported"
                        vs-name="loanCheck"
                        >Supported</vs-radio
                    >
                    <vs-radio
                        v-if ="applicant_type !== 'Student'"
                        color="dark"
                        v-model="loanCheckType"
                        vs-value="supported"
                        vs-name="loanCheck"
                        >Supported</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Financial Applicant Employment Type</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <v-select
                        placeholder=""
                        style="z-index: 1000"
                        class="w-full"
                        v-model="profession_details"
                        :options="professionList"
                    ></v-select>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'self' && applicant_type === 'Working professional'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >First Name *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        class="w-full"
                        placeholder="First Name"
                        v-model="full_name"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" v-if="loanCheckType == 'self' && applicant_type === 'Working professional'">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: In First Name Don't Give Space In Between Names</b
                    >
                </vs-col>
            </vs-row>
            
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'self' && applicant_type === 'Working professional'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Last Name *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        class="w-full"
                        placeholder="Last Name"
                        v-model="last_name"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" v-if="loanCheckType == 'self' && applicant_type === 'Working professional' ">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: In Last Name Don't Give Space In Between Names</b
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Student Full Name *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="Full Name"
                        v-model="student_full_name"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" v-if="loanCheckType == 'supported' || applicant_type === 'Student'">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: Give student full name and surname or last name with spaces.</b
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Student Gender *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="student_gender"
                        vs-value="student_gender_male"
                        vs-name="gender"
                        >Male</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="student_gender"
                        vs-value="student_gender_female"
                        vs-name="student_gender_female"
                        >Female</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Student DOB *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="date"
                        id="birthday"
                        class="w-full"
                        v-model="student_date_of_birth"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type == 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type == 'Student'"
                            >Financial Applicant </span
                        >First Name *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        class="w-full"
                        placeholder="First Name"
                        v-model="first_name"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" v-if="loanCheckType == 'supported' || applicant_type === 'Student'">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: In First Name Don't Give Space In Between Names</b
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Last Name *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        class="w-full"
                        placeholder="Last Name"
                        v-model="last_name"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" v-if="loanCheckType == 'supported' || applicant_type === 'Student'">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: In Last Name Don't Give Space In Between Names</b
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Gender *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="radio4"
                        vs-value="gender_male"
                        vs-name="gender"
                        >Male</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="radio4"
                        vs-value="gender_female"
                        vs-name="gender_female"
                        >Female</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="loanCheckType == 'self' && applicant_type === 'Working professional'"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Gender *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="radio3"
                        vs-value="1"
                        vs-name="gender"
                        >Male</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="radio3"
                        vs-value="2"
                        vs-name="gender"
                        >Female</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >DOB *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="date"
                        id="birthday"
                        class="w-full"
                        v-model="date_of_birth"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Marital Status *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="marital_status"
                        vs-value="unmarried"
                        vs-name="marital_status"
                        >Single</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="marital_status"
                        vs-value="married"
                        vs-name="marital_status"
                        >Married</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Mobile *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                    v-if="loanCheckType == 'self' && applicant_type === 'Working professional'"
                >
                    <div
                        v-for="(mob, index) in userdata.mobiles"
                        :key="index"
                        style="margin-left: 1%"
                        :data-tooltip="mob.masked_number"
                    >
                        <!-- <span class="tooltiptext">
              <b>{{ mob.masked_number }}</b>
            </span> -->
                        <vs-button
                            :color="getMobileColor(mob)"
                            :type="mob.invalid === 0 ? 'gradient' : 'filled'"
                            icon="local_phone"
                            @click="mobilePopup(mob)"
                        >
                        </vs-button>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                    v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                >
                    <vs-input
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="mobile number"
                        v-model="mobile"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Email *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                    v-if="loanCheckType == 'self' && applicant_type === 'Working professional'"
                >
                    <div
                        v-for="(email, indexemail) in userdata.emails"
                        :key="indexemail"
                        style="margin-left: 1%"
                        :data-tooltip="email.masked_email"
                    >
                        <!-- <span class="tooltiptext">
              <b>{{ email.masked_email }}</b>
            </span> -->
                        <vs-button
                            style="margin-left: 1%"
                            :color="getEmailColor(email)"
                            type="gradient"
                            icon="email"
                            @click="emailPopup(email)"
                        ></vs-button>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                    v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                >
                    <vs-input
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="email"
                        v-model="email"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >PAN *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="PAN Number"
                        v-model="pan_number"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Aadhar Address *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-textarea
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="Address"
                        v-model="address1"
                    />
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Aadhar Land Mark *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-textarea
                        type="text"
                        id="feesselect"
                        class="w-full"
                        placeholder="Address"
                        v-model="address2"
                    />
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >City</span
                    >
                </vs-col>
                <vs-col
                    v-if="userdata !== ''"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                    v-model="applicant_city"
                >
                    <b>{{ userdata.mwb.city }}</b>
                </vs-col>
            </vs-row>

            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Aadhar Pincode *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="number"
                        id="feesselect"
                        class="w-full"
                        placeholder="Pin Code"
                        v-model="pin_code"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row class="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="6"
                    style="font-size: 15px; color: gray"
                    ><h5><b>Current Residential Address</b></h5></vs-col
                >
            </vs-row>
            <vs-row class="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                    ><span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Flat, House no., Building, Company, Apartment * :</span
                    ></vs-col
                >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input class="w-full" v-model="billingaddress.street" />
                </vs-col>
            </vs-row>
            <vs-row class="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                    ><span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Town/City * :</span
                    ></vs-col
                >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input class="w-full" v-model="billingaddress.city" />
                </vs-col>
            </vs-row>
            <vs-row class="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                    ><span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >State / Province / Region * :</span
                    ></vs-col
                >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-select
                        class="w-full"
                        placeholder="State"
                        v-model="billingaddress.state"
                    >
                        <vs-select-item
                            :key="index"
                            :value="item.name"
                            :text="item.name"
                            v-for="(item, index) in QBStateList"
                        />
                    </vs-select>
                </vs-col>
            </vs-row>
            <vs-row class="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                    ><span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >PIN code * :</span
                    ></vs-col
                >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input class="w-full" v-model="billingaddress.zipcode" />
                </vs-col>
            </vs-row>
            <vs-row type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: Current Residential Address must be the same as
                        Shiiping Address in EF Form.</b
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%" v-if="this.eduvanz_note !== ''">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
              >
                <span
                  >Previous Current Residential Address</span
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="9"
              >
               <b>{{ this.eduvanz_note }}</b>
              </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Is Permanent Pincode Same As Current Pincode *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-checkbox v-model="pincodeconform"></vs-checkbox>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.pincodeconform === false"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span
                        ><span v-if="loanCheckType == 'supported' || applicant_type === 'Student'"
                            >Financial Applicant </span
                        >Permanent Pincode *</span
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="number"
                        id="feesselect"
                        class="w-full"
                        placeholder="Pin Code"
                        v-model="permanent_pincode"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Course *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <v-select
                        placeholder=""
                        style="z-index: 1000"
                        class="w-full"
                        v-model="course"
                        :options="course_details"
                    ></v-select>
                </vs-col>
            </vs-row>
            <vs-row type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <b style="font-size: 11px; color: red"
                        >Hint: Please Select Atleast Once Course</b
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Tenure *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="cibil_tenture"
                        vs-value="three_months"
                        vs-name="loanCheck"
                        >3 Months</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="cibil_tenture"
                        vs-value="six_months"
                        vs-name="loanCheck"
                        >6 Months</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Loan Amount *</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="9"
                >
                    <vs-input
                        type="number"
                        id="feesselect"
                        class="w-full"
                        placeholder="Amount"
                        v-model="loan_amount"
                    ></vs-input>
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 3%" v-if="this.courseMessage === false">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                ></vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <vs-button
                        class="float-center"
                        color="primary"
                        type="gradient"
                        @click="approvalCheck()"
                        >CHECK</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                ></vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 3%" v-if="this.courseMessage === true">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="9"
                >
                Are you sure! you want to cibil check for this course <b style="color:red;margin-left: 2%;font-size:16px;">{{ this.course }}</b>
                </vs-col>
                <!-- <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                >
                
                </vs-col> -->
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="3"
                    style="margin-left: -8%;"
                >
                .If it is correct 
                </vs-col>
                <p style="margin-left: 4%;">please click on 'yes' button.</p>
            </vs-row>
            <vs-row type="flex" style="margin-top: 3%" v-if="this.courseMessage === true">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                ></vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <vs-button
                        class="float-center"
                        color="success"
                        type="gradient"
                        @click="LoanCheck()"
                        >YES</vs-button
                    >
                    <vs-button
                        class="float-center"
                        color="danger"
                        type="gradient"
                        @click="disApprovalStatus()"
                        style="margin-left: 3%;"
                        >NO</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                ></vs-col>
            </vs-row>
        </vx-card>
        <!-- IPA Component -->
        <vx-card
            actionable
            class="cardx"
            title="IPA CHECK"
            v-if="
                this.IMDBResponse === 'Partial IPA Approved' &&
                this.ProductList === undefined &&
                this.ListData.message !== 'No product details present' &&
                open
            "
        >
            <div>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="3"
                    >
                        <span>Requested Amount</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="9"
                    >
                        <vs-input
                            disabled
                            type="text"
                            class="w-full"
                            placeholder="First Name"
                            v-model="requested_amount"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="3"
                    >
                        <span>IPA AMOUNT</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="9"
                    >
                        <vs-input
                            disabled
                            type="text"
                            class="w-full"
                            placeholder="First Name"
                            v-model="ipa_amount"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="12"
                    >
                        <vs-radio
                            style="margin-right: 3%"
                            color="dark"
                            v-model="loanIpaType"
                            vs-value="0"
                            vs-name="ipaAMount"
                            >Are You Continue With Requested Amount</vs-radio
                        >
                        <vs-radio
                            color="dark"
                            v-model="loanIpaType"
                            vs-value="1"
                            vs-name="ipaAMount"
                            >Are You Continue With IPA Amount</vs-radio
                        >
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <vs-button
                            class="float-center"
                            color="primary"
                            type="gradient"
                            @click="IpaCheck()"
                            >CHECK</vs-button
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                </vs-row>
            </div>
        </vx-card>
        <!-- Product List Component -->
        <vx-card
            actionable
            class="cardx"
            title="PRODUCT LIST"
            v-if="productlistcard"
        >
            <div>
                <vs-table title="Select Your Plan" :data="this.ProductList">
                    <template slot="thead">
                        <vs-th> Sr. No. </vs-th>
                        <vs-th>Tenure</vs-th>
                        <vs-th style="width: 10%">EMI Amount</vs-th>
                        <vs-th>Rate Of Interest (Flat)</vs-th>
                        <vs-th style="width: 20%">Total Repayable Amount</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <tbody ref="tbody">
                            <vs-tr
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                            >
                                <vs-td>
                                    <div>
                                        <label>
                                            <input
                                                @change="vSelectUpdate(tr)"
                                                type="radio"
                                                color="dark"
                                                :id="
                                                    'att-present-' +
                                                    tr.bre_emi_id
                                                "
                                                :name="'att-' + tr.bre_emi_id"
                                                :value="tr.emiAmount"
                                                v-model="seletcPlan"
                                            />
                                        </label>
                                    </div>
                                </vs-td>
                                <vs-td>
                                    <div>{{ tr.tenure }}</div>
                                </vs-td>
                                <vs-td>
                                    <div>{{ tr.emiAmount }}</div>
                                </vs-td>
                                <vs-td>
                                    <div style="margin-left: 25%">
                                        {{
                                            tr.finalinterestrateorrateofintfixed
                                        }}
                                        %
                                    </div>
                                </vs-td>
                                <vs-td>
                                    <div>{{ tr.totalRepayableAmount }}</div>
                                </vs-td>
                                <!-- <vs-td>
              <div>{{ tr.emiAmount }}</div>
            </vs-td> -->
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
                <vs-row type="flex" style="margin-top: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                        v-show="enablebutton"
                    >
                        <vs-button
                            class="float-center"
                            color="primary"
                            type="gradient"
                            @click="ProductCheck()"
                            >SUBMIT</vs-button
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                        v-show="disablebutton"
                    >
                        <vs-button
                            disabled
                            class="float-center"
                            color="primary"
                            type="gradient"
                            @click="ProductCheck()"
                            >SUBMIT</vs-button
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                </vs-row>
            </div>
        </vx-card>
        <vx-card
            actionable
            class="cardx"
            title="PRODUCT LIST"
            v-if ="imdb_response === 'IPA Failed'"
        >
        <p vs-justify="center" vs-align="center">Product Details are not available. This is not a pre approved load. Please go ahead with the next steps.</p>
        </vx-card>
        <!-- Level 2 Code -->
        <div v-if="open">
            <vx-card
                actionable
                class="cardx"
                title="Personal And Professional Details"
                v-if="
                    this.ApplicationId !== '' || this.ApplicationId != undefined
                "
            >
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Application No. *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <b>{{ ApplicationId }}</b>
                    </vs-col>
                </vs-row>
                <!-- <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Profession</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <v-select
                            placeholder="Profession Type"
                            style="z-index: 1000"
                            class="w-full"
                            v-model="professionType"
                            :options="profession_opions"
                        ></v-select>
                    </vs-col>
                </vs-row> -->
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Aadhar Number *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Aadhar Number"
                            v-model="aadhar_number"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Current Residence Type *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-radio
                            style="margin-right: 3%"
                            color="dark"
                            v-model="CurrentResidenceType"
                            vs-name="CurrentResidenceType"
                            vs-value="Rented"
                            >Rented</vs-radio
                        >
                        <vs-radio
                            color="dark"
                            v-model="CurrentResidenceType"
                            vs-name="CurrentResidenceType"
                            vs-value="Owned"
                            >Owned</vs-radio
                        >
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="CurrentResidenceType == 'Rented'"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Current Monthly Rent *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Current Monthly Rent"
                            v-model="current_monthly_rent"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Current Stay Of Duration *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <v-select
                            placeholder="Stay Of Duration"
                            style="z-index: 1000"
                            class="w-full"
                            v-model="current_duration_of_stay"
                            :options="duration_of_stay"
                        ></v-select>
                    </vs-col>
                </vs-row>
                <!-- <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Is Permanent Address Same As Current*</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-checkbox v-model="checkBox2"></vs-checkbox>
                    </vs-col>
                </vs-row> -->
                <!-- <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Address</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <template>
                            <b>{{ this.DataUser.eduvanz_request.notes }}</b>
                        </template>
                    </vs-col>
                </vs-row> -->
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Residence Type *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <template>
                            <vs-radio
                                style="margin-right: 3%"
                                color="dark"
                                v-model="permanent_residence_type"
                                vs-value="1"
                                vs-name="permanent_residence_type"
                                >Rented</vs-radio
                            >
                            <vs-radio
                                color="dark"
                                v-model="permanent_residence_type"
                                vs-value="0"
                                vs-name="permanent_residence_type"
                                >Owned</vs-radio
                            >
                        </template>
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2 && this.permanent_residence_type !== '0'"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                        v-if="this.permanent_residence_type !== 2"
                    >
                        <span>Permanent Monthly Rent *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Permanent Monthly Rent"
                            v-model="permanent_monthly_rent"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Stay Of Duration *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <v-select
                            placeholder="Permanent Stay Of Duration"
                            style="z-index: 1000"
                            class="w-full"
                            v-model="permanent_duration_of_stay"
                            :options="Permanentdurationofstay"
                        ></v-select>
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Address Line 1 *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-textarea
                            type="text"
                            id="feesselect"
                            class="w-full"
                            placeholder="Address 1"
                            v-model="permanent_address_line1"
                        />
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Land Mark *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-textarea
                            type="text"
                            id="feesselect"
                            class="w-full"
                            placeholder="Address 2"
                            v-model="permanent_address_line2"
                        />
                    </vs-col>
                </vs-row>
                <vs-row
                    type="flex"
                    style="margin-top: 2%"
                    v-if="this.AddressChecking === 2"
                >
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Permanent Address Pincode *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="number"
                            id="feesselect"
                            class="w-full"
                            placeholder="Permanent Pincode"
                            v-model="permanent_address_pincode"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Comapny Name *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Company Name"
                            v-model="work_company_name"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Employer Sector Type *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-radio
                            color="dark"
                            style="margin-right: 3%"
                            v-model="EmployeType"
                            vs-name="EmployeType"
                            vs-value="Private"
                            >Private</vs-radio
                        >
                        <vs-radio
                            color="dark"
                            v-model="EmployeType"
                            vs-name="EmployeType"
                            vs-value="Goverment"
                            >Goverment</vs-radio
                        >
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Annual Income *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Annual Income"
                            v-model="annual_income"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Current Employee Stay Of Duration *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <v-select
                            placeholder="Employee Stay Of Duration"
                            style="z-index: 1000"
                            class="w-full"
                            v-model="current_employment_duration"
                            :options="duration_employee"
                        ></v-select>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Company Email *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="text"
                            class="w-full"
                            placeholder="Company Email"
                            v-model="work_email_id"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Comapny Address Line 1 *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-textarea
                            type="text"
                            id="feesselect"
                            class="w-full"
                            placeholder="Address 1"
                            v-model="work_address_line1"
                        />
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Comapny Land Mark *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-textarea
                            type="text"
                            id="feesselect"
                            class="w-full"
                            placeholder="Address 2"
                            v-model="work_address_line2"
                        />
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <span>Company Pincode *</span>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="8"
                    >
                        <vs-input
                            type="number"
                            id="feesselect"
                            class="w-full"
                            placeholder="Comapny Pincode"
                            v-model="work_pincode"
                        ></vs-input>
                    </vs-col>
                </vs-row>
                <vs-row type="flex" style="margin-top: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <vs-button
                            :disabled="isDisabled"
                            class="float-center"
                            color="primary"
                            type="gradient"
                            @click="submit()"
                            >Submit</vs-button
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                </vs-row>
            </vx-card>
        </div>
        <!-- Level 3 Code -->
        <vx-card
            actionable
            class="cardx"
            title="Document Verification"
            v-if="
                this.documents_details != '' ||
                this.DocumentData != '' ||
                this.documents_details.length != 0 ||
                this.imdb_response === 'IPA Failed'
            "
        >
            <h5>Mandate Documents</h5>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="2"
                >
                    <span>Candidate Photo</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <label>
                        <input
                            type="file"
                            id="Profilefile"
                            ref="Profilefile"
                            v-on:change="onProfileChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                >
                    <vs-button
                        size="small"
                        @click="ProfilePic()"
                        style="margin-top: 0%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="ProfileShowImage"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                            style="margin-top: 1%; margin-left: -3%"
                        ></vs-icon
                    ></span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                    v-show="ProfileShowImage"
                >
                    <vs-button
                        size="small"
                        @click="ProfileshowImg()"
                        style="margin-top: 0%"
                        >Show Image</vs-button
                    >
                </vs-col>
            </vs-row>
            <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="2"
                >
                    <span>Pan Card</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <label>
                        <input
                            type="file"
                            id="Panfile"
                            ref="Panfile"
                            v-on:change="onPanChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                >
                    <vs-button
                        size="small"
                        @click="PanCard()"
                        style="margin-top: 10%; margin-top: 0%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="PanShowImage"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                            style="margin-top: 1%; margin-left: -3%"
                        ></vs-icon
                    ></span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                    v-show="PanShowImage"
                >
                    <vs-button
                        size="small"
                        @click="PanshowImg()"
                        style="margin-top: 10%; margin-top: 0%"
                        >Show Image</vs-button
                    >
                </vs-col>
            </vs-row>
            <h5 style="margin-top: 4%">KYC Documents</h5>
            <vs-row type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Select Document Type</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="5"
                >
                    <v-select
                        placeholder="Select Document Type"
                        style="z-index: 19000"
                        class="w-full"
                        v-model="kyc_documents"
                        :options="KycDocument"
                    ></v-select>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="KycShowImage"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                    v-show="KycShowImage"
                >
                    <vs-button
                        size="small"
                        @click="KycshowImg()"
                        style="margin-top: 0%"
                        >Show Image</vs-button
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.kyc_documents != ''"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                ></vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="6"
                >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="document_upload"
                        vs-value="1"
                        vs-name="front_side"
                        >Front Side</vs-radio
                    >
                    <vs-radio
                        style="margin-right: 3%"
                        color="dark"
                        v-model="document_upload"
                        vs-value="2"
                        vs-name="back_side"
                        >Back Side</vs-radio
                    >
                    <vs-radio
                        color="dark"
                        v-model="document_upload"
                        vs-value="3"
                        vs-name="both_side"
                        >Both Sides</vs-radio
                    >
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.document_upload == 1"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Front Side</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <label>
                        <input
                            type="file"
                            id="Frontfile"
                            ref="Frontfile"
                            v-on:change="onKycFrontChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    style="margin-bottom: 1.5%"
                >
                    <vs-button
                        size="small"
                        @click="FrontSide()"
                        style="margin-top: 5%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="ShowSuccessIcon"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.document_upload == 2"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Back Side</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <label>
                        <input
                            type="file"
                            id="Bakcfile"
                            ref="Backfile"
                            v-on:change="onKycBackChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    v-show="ShowButton"
                    style="margin-bottom: 1.5%"
                >
                    <vs-button
                        size="small"
                        @click="BackSide()"
                        style="margin-top: 5%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="ShowSuccessIcon"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.document_upload == 3"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Both Sides</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <label>
                        <input
                            type="file"
                            id="Bothfile"
                            ref="Bothfile"
                            v-on:change="onKycBothChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    v-show="ShowButton"
                    style="margin-bottom: 1.5%"
                >
                    <vs-button
                        size="small"
                        @click="BothSide()"
                        style="margin-top: 5%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="ShowSuccessIcon"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="8"
                >
                    <h5
                        style="
                            margin-top: 4%;
                            margin-bottom: 3%;
                            margin-left: -3%;
                        "
                    >
                        FINANCIAL Documents
                    </h5>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="1"
                    v-show="BankPic"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="3"
                    v-show="BankPic"
                >
                    <vs-button
                        size="small"
                        @click="FinanceImg()"
                        style="margin-top: 0%"
                        >Show Image</vs-button
                    >
                </vs-col>
            </vs-row>
            <!-- banks start -->
            <banks></banks>
            <!-- banks end -->
            <vs-row type="flex" style="margin-bottom: 3%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Select Account Type</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="7"
                >
                    <v-select
                        placeholder="Select Document Type"
                        style="z-index: 14000"
                        class="w-full"
                        v-model="account_type"
                        :options="account_list"
                    ></v-select>
                </vs-col>
            </vs-row>
            <vs-row type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="3"
                >
                    <span>Select Document Type</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="7"
                >
                    <!-- <vs-select class="selectExample" label="Cities" v-model="bank_documents">
          <vs-select-item :key="index" :value="item.document_type_id" :text="item.document_name" v-for="(item,index) in finance_documents"/>
        </vs-select> -->
                    <v-select
                        placeholder="Select Document Type"
                        style="z-index: 13000"
                        class="w-full"
                        v-model="bank_documents"
                        :options="financeDocuments"
                    ></v-select>
                </vs-col>
            </vs-row>
            <vs-row
                type="flex"
                style="margin-top: 2%"
                v-if="this.bank_documents != ''"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <span>Upload Document</span>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                >
                    <label>
                        <input
                            type="file"
                            id="Bankfile"
                            ref="Bankfile"
                            v-on:change="onBankChangeFileUpload()"
                        />
                    </label>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    style="margin-bottom: 1.5%"
                >
                    <vs-button
                        size="small"
                        @click="BankDocument()"
                        style="margin-top: 5%"
                        >Upload</vs-button
                    >
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    v-show="BankShowSuccessIcon"
                >
                    <span
                        ><vs-icon
                            icon="check_circle"
                            size="small"
                            color="success"
                        ></vs-icon
                    ></span>
                </vs-col>
            </vs-row>
        </vx-card>
        <!-- repayments start -->
        <repayments :repay="userdata" :payment="DataUser"></repayments>
        <!-- repayments end -->
    </vs-popup>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus";
import banks from "./banklist.vue";
import repayments from "./repayments.vue";
export default {
    props:["popup","studentDetails","LoanData"],
    mounted() {
        console.log("popup value child mounted",this.popup);
        this.getPopupData();
        // EventBus.$on("open-LoanCheckPopup", (studentDetails, LoanData) => {
        //     this.open = true;
        //     this.clearAll();
        //     this.userdata = studentDetails;
        //     // console.log("popup data", this.userdata);
        //     // this.getUserInfor();
        //     // this.DocumentsData();
        //     // this.getDocumentsInfo();
        //     // this.BankListData();
        //     this.EduData = LoanData;
        //     // console.log("Loan data", this.EduData);
        //     if (this.EduData != "") {
        //         let DataUser = JSON.parse(this.EduData.payload);
        //         console.log("dg", DataUser);
        //         this.DataUser = DataUser;
        //         this.LeadId = DataUser.eduvanz_response.lead_id;
        //         this.eduvanz_note = DataUser.eduvanz_request.notes;
        //         this.requested_amount =
        //             DataUser.eduvanz_response.requested_amount;
        //         this.ipa_amount = DataUser.eduvanz_response.ipa_amount;
        //         this.FinancialId =
        //             DataUser.eduvanz_response.financial_applicant_id;
        //         this.ApplicationId = DataUser.eduvanz_response.application_id;
        //         this.CibilStatus = DataUser.eduvanz_response.cibil_status;
        //         this.responseUserid = DataUser.eduvanz_response.user_id;
        //         this.IMDBResponse = DataUser.eduvanz_response.imdb_response;
        //         if (DataUser.eduvanz_response.imdb_response !== "") {
        //             this.getProductList();
        //         }

        //         this.DocumentsData();
        //         this.getUserInfor();
        //         this.getDocumentsInfo();
        //         // this.getProductList();
        //     }
        //     this.full_name = this.userdata.mwb.person_name;
        //     this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
        //     this.email = this.getEmailForCandidate(this.userdata.emails);
        //     this.address1 = this.userdata.mwb.address;
        //     this.applicant_city = this.userdata.mwb.city;
        //     this.getClientLocationId(this.applicant_city);
        //     if (this.DocumentsList.length > 1) {
        //         this.financeDocuments = [];
        //         this.KycDocument = [];
        //     }
        // });
        // document.getElementById("LoanPopup").children[1].style.width = "50%";
    },
    data() {
        return {
            imdb_response: "",
            applicant_type: "Working professional",
            applicant_type_list: ["Working professional", "Student"],
            AddressChecking: "",
            professionList: ["Salaried","Self Employed Profession", "Self Employed NonProfession"],
            profession_details: "Salaried",
            working_profession: 1,
            QBStateList: [
              { name: "ANDAMAN AND NICOBAR ISLANDS", code: 35 },
              { name: "ANDHRA PRADESH", code: 37 },
              { name: "ARUNACHAL_PRADESH", code: 12 },
              { name: "ASSAM", code: "18" },
              { name: "BIHAR", code: "10" },
              { name: "CHANDIGARH", code: "04" },
              { name: "CHHATTISGARH", code: "22" },
              { name: "DADRA_AND_NAGAR_HAVELI", code: "26" },
              { name: "DAMAN_AND_DIU", code: "25" },
              { name: "DELHI", code: "07" },
              { name: "GOA", code: "30" },
              { name: "GUJARAT", code: "24" },
              { name: "HARYANA", code: "06" },
              { name: "HIMACHAL_PRADESH", code: "02" },
              { name: "JAMMU_AND_KASHMIR", code: "01" },
              { name: "JHARKHAND", code: "20" },
              { name: "KARNATAKA", code: "29" },
              { name: "KERALA", code: "32" },
              { name: "LAKSHADWEEP", code: "31" },
              { name: "MADHYA_PRADESH", code: "23" },
              { name: "MAHARASHTRA", code: "27" },
              { name: "MANIPUR", code: "14" },
              { name: "MEGHALAYA", code: "17" },
              { name: "MIZORAM", code: "15" },
              { name: "NAGALAND", code: "13" },
              { name: "ODISHA", code: "21" },
              { name: "PONDICHERRY", code: "34" },
              { name: "PUNJAB", code: "03" },
              { name: "RAJASTHAN", code: "08" },
              { name: "SIKKIM", code: "11" },
              { name: "TAMIL_NADU", code: "33" },
              { name: "TELANGANA", code: "36" },
              { name: "TRIPURA", code: "16" },
              { name: "UTTAR_PRADESH", code: "09" },
              { name: "UTTARAKHAND", code: "05" },
              { name: "WEST_BENGAL", code: "19" },
              { name: "OUTSIDE_INDIA", code: "97" },
            ],
            address: {},
            billingaddress: {
                street: "",
                city: "",
                state: "",
                zipcode: "",
            },
            productlistcard: false,
            IMDBResponse: "",
            permanent_pincode: "",
            item: "",
            finance: [],
            repay: "",
            payment: "",
            KYC: [],
            allDoc: [],
            mandidate: [],
            CibilStatus: "",
            account_type: "",
            account_list: [
                "SAVINGS ACCOUNT",
                "CURRENT ACCOUNT",
                "CREDIT CARD",
                "SAVING-NRE",
                "SAVING-NRO",
                "OTHER",
                "OVER DRAFT",
            ],
            bank_name: "",
            bank_list: [],
            doc_data: "",
            userdata: "",
            EduData: "",
            open: false,
            radio4: "gender_male",
            first_name: "",
            last_name: "",
            student_date_of_birth: "",
            student_gender: "student_gender_male",
            loan_amount: "",
            mobile: "",
            email: "",
            full_name: "",
            radio3: "1",
            pan_number: "",
            address1: "",
            address2: "",
            pin_code: "",
            date_of_birth: "",
            loanCheckType: "self",
            // city: "",
            course: "CPA",
            course_details: ["CPA", "CMA", "CFA"],
            client_institute_id: 1, // required hard coded - UAT 1
            client_location_id: "", // required - as per spoc city - UAT 1
            client_course_id: "", // selectabel course - UAT 1
            cibil_tenture: "",
            looking_loan_for: "self",
            type_of_loan: 3,
            marital_status: "unmarried",
            consent_for_tems_condition: 1,
            consent_for_covid_terms_conditions: 1,
            student_full_name: "",
            profession: {
                self: 1,
                supported: 1,
            },
            looking_for_loan_structure: {
                self: 1,
                supported: 2,
            },
            //new data
            DocumentsList: "",
            DocumentData: [],
            BankShowSuccessIcon: false,
            BankShowImage: false,
            bank_details: false,
            bank_documents: "",
            ApplicationId: "",
            LeadId: "",
            success: "",
            documents: [],
            // KycShowImage: false,
            DataUser: "",
            document_id: "",
            bank_document_id: "",
            document_upload: "",
            back_kyc: false,
            front_kyc: false,
            ProfileShowSuccessIcon: false,
            ProfileShowImage: false,
            KycShowImage: false,
            PanShowImage: false,
            BankPic: false,
            kyc_documents: "",
            documents_details: [],
            finance_documents: [],
            ShowButton: true,
            ShowSuccessIcon: false,
            pan_card: "",
            attachmentProfile: null,
            attachmentAadhar: null,
            attachmentaddress: null,
            attachmentpan: null,
            attachmentresidence: null,
            attachmentsalary: null,
            saveFile: [],
            render: false,
            Permanentdurationofstay: [
                "Less_Than_six_Months",
                "Six_Months_to_One_Year",
                "One_Year_to_Two_Years",
                "More_Than_Two_years",
            ],
            permanent_address_line1: "",
            permanent_address_line2: "",
            permanent_address_pincode: "",
            permanent_residence_type: "",
            permanent_monthly_rent: "",
            permanent_duration_of_stay: "",
            profession_opions: [
                "Salaried",
                "Self_Employed_Profession",
                "Self_Employed_Non_Profession",
            ],
            work_pincode: "",
            work_address_line2: "",
            work_address_line1: "",
            work_email_id: "",
            current_employment_duration: "",
            annual_income: "",
            EmployeType: "",
            CurrentResidenceType: "",
            aadhar_number: "",
            professionType: "",
            duration_of_stay: [
                "Less_Than_six_Months",
                "Six_Months_to_One_Year",
                "One_Year_to_Two_Years",
                "More_Than_Two_years",
            ],
            duration_employee: [
                "Less_Than_six_Months",
                "Six_Months_to_One_Year",
                "One_Year_to_Two_Years",
                "More_Than_Two_years",
            ],
            current_duration_of_stay: "",
            current_monthly_rent: "",
            work_company_name: "",
            checkBox2: true,
            pincodeconform: false,
            uploaded: false,
            // KycDocument: [],
            KycDocument: [
                // "Photo",
                // "PAN Card",
                "Aadhar Card",
                "Passport",
                "Voter Id",
                "Driving License",
            ],
            financeDocuments: [
                "Bank Statement - 3 Months",
                "Bank Statement - 6 Months",
                "Salary Slip - 3 Months",
                "Cancelled Cheque",
                "Scanned Copy of Bank Statement/ Passbook front page",
            ],
            // IPA details
            requested_amount: "",
            ipa_amount: "",
            loanIpaType: "",

            // Poructs data
            enablebutton: true,
            disablebutton: false,
            ProductList: [],
            Product: "",
            seletcPlan: [],
            savedvalues: "",
            selectProductList: "",
            ListData: "",
            eduvanz_note: "",
            applicant_city: "",
            cibilCheckButton: true,
            courseMessage: false,
            approvalButton: false,
            student_dob: "",
            student_genders: ""
        };
    },
    components: {
        "v-select": vSelect,
        banks,
        repayments,
    },
    computed: {
        isDisabled: function () {
            return !this.work_pincode;
        },
        
        // financeDocuments: function () {
        //   let mandidate = [];
        //   let Financearr = [];
        //   if (
        //     (this.documents_details != "" && this.documents_details.length > 0) ||
        //     this.documents_details != ""
        //   ) {
        //     for (var key in this.documents_details) {
        //       if (this.documents_details.hasOwnProperty(key)) {
        //         let itemObj = {
        //           text: key,
        //           value: this.documents_details[key],
        //         };

        //         mandidate.push(itemObj);
        //       }
        //       console.log("documents", mandidate);
        //     }
        //     if (
        //       this.documents_details.document_categories["Banking Details"] !== undefined ||
        //       (this.documents_details.document_categories["Banking Details"] !== "" &&
        //         mandidate.length > 0)
        //     ) {
        //       console.log(
        //         "after foreach",
        //         this.documents_details.document_categories["Banking Details"]
        //       );
        //       this.documents_details.document_categories["Banking Details"].forEach(
        //         (financedoc) => {
        //           console.log("cgtfs", financedoc);
        //           var Financelist = {
        //             id: financedoc.document_type_id,
        //             label: financedoc.document_name,
        //           };
        //           Financearr.push(Financelist);
        //         }
        //       );

        //       return Financearr;
        //     }
        //   }
        // },
        // KycDocument: function () {
        //   let kyccand = [];
        //   let arr = [];
        //   if (
        //     (this.documents_details != "" && this.documents_details.length > 0) ||
        //     this.documents_details != ""
        //   ) {
        //     for (var key in this.documents_details) {
        //       if (this.documents_details.hasOwnProperty(key)) {
        //         let Obj = {
        //           text: key,
        //           value: this.documents_details[key],
        //         };

        //         kyccand.push(Obj);
        //       }
        //       console.log("kyc documents", kyccand);
        //     }
        //     if (
        //       this.documents_details.document_categories.KYC !== undefined ||
        //       (this.documents_details.document_categories.KYC !== "" && kyccand.length > 1)
        //     ) {
        //       console.log("before foreach", this.documents_details.document_categories.KYC);
        //       this.documents_details.document_categories.KYC.forEach((kycdoc) => {
        //         var Kyclist = {
        //           id: kycdoc.document_type_id,
        //           label: kycdoc.document_name,
        //         };
        //         arr.push(Kyclist);
        //       });
        //       return arr;
        //     }
        //   }
        // },
    },
    watch: {
        applicant_type:function(val){
            if(val === "Student"){
                // console.log(val, "fugjhghj12");
                this.loanCheckType = "self";
            }
            console.log(this.loanCheckType, "fugjhghj12");
        },
        popup:function(val){
                console.log('watch val',val);
                if(val === true){
                    this.getPopupData();
                }              
        },
        open: function (value) {
            // console.log("tag", value);
            if (value === true) {
                this.getUserInfor();
            }else{
                console.log("close");
                this.handleClose();
            }
        },
        profession_details: function() {
            if(this.profession_details === "Salaried"){
                 this.financial_applicant_profession = 1;
            }
            if(this.profession_details === "Self Employed Profession"){
                 this.financial_applicant_profession = 3;
            }
            if(this.profession_details === "Self Employed NonProfession"){
                 this.financial_applicant_profession = 4;
            }
            console.log("this.financial_applicant_profession",this.financial_applicant_profession);

            // if (this.profession_details === "Working professional") { 
            //     this.working_profession = 1;
            // } else if (this.profession_details === "Student") { 
            //     this.working_profession = 2;
            // } else if (this.profession_details === "Self Employed Profession") { 
            //     this.working_profession = 3;
            // } else if (this.profession_details === "Self Employed NonProfession") { 
            //     this.working_profession = 4;
            // }
        },
        loanCheckType: function () {
            if (this.loanCheckType === "supported") {
                this.financial_applicant_profession = "";
                // this.professionList = ["Working professional","Self Employed Profession", "Self Employed NonProfession"],
                this.professionList = ["Salaried","Self Employed Profession", "Self Employed NonProfession"],
                // this.professionList = ["Working professional", "Student"];
                // this.profession_details = "Working professional";
                this.student_full_name = this.userdata.mwb.person_name;
                this.mobile = "";
                this.email = "";
                this.address1 = "";
                this.address2 = "";
                this.looking_loan_for = "others";
                this.date_of_birth = "";
                this.loan_amount = "";
                this.cibil_tenture = "";
                this.pan_number = "";
                this.pin_code = "";
                // this.course = "";
                this.loan_amount = "";
                this.first_name = "";
                this.last_name = "";
            } 
            if (this.loanCheckType === "self") {
                this.financial_applicant_profession = "";
                this.professionList = ["Salaried", "Self Employed Profession", "Self Employed NonProfession"]
                // this.profession_details = "Working professional";
                this.full_name = this.userdata.mwb.person_name;
                this.mobile = this.userdata.mobiles[0].phone_number;
                this.email = this.userdata.emails[0].email;
                this.address1 = this.userdata.mwb.address;
                this.address2 = "";
                this.student_gender = "";
                this.student_full_name = "";
                this.address = "";
                this.date_of_birth = "";
                this.loan_amount = "";
                this.cibil_tenture = "";
                this.pan_number = "";
                this.pin_code = "";
                // this.course = "";
                this.loan_amount = "";
                this.student_date_of_birth = "";
                this.first_name = "";
                this.last_name = "";
            }
            // if(this.applicant_type === "Student"){
            //     // console.log(val, "fugjhghj12");
            //     this.loanCheckType = "Self";
            // }
        },
        cibil_tenture: function () {
            this.applicant_city;
            if (this.course === "CPA" && this.cibil_tenture === "three_months") {
                this.loan_amount = 109800;
                this.client_course_id = 825;
            } else if (this.course === "CMA" && this.cibil_tenture === "three_months") {
                this.loan_amount = 85800;
                this.client_course_id = 828;
            } else if (this.course === "CFA" && this.cibil_tenture === "three_months") {
                this.loan_amount = 29000;
                this.client_course_id = 68716;
            } else if (this.course === "CPA" && this.cibil_tenture === "six_months") {
                this.loan_amount = 109800;
                this.client_course_id = 825;
            } else if (this.course === "CMA" && this.cibil_tenture === "six_months") {
                this.loan_amount = 85800;
                this.client_course_id = 828;
            } else if (this.course === "CFA" && this.cibil_tenture === "six_months") {
                this.loan_amount = 29000;
                this.client_course_id = 68716;
            }
        },
        // applicant_city: function () {
        //     if (this.applicant_city === "Hyderabad") {
        //         this.client_location_id = 22;
        //     } else if (this.applicant_city === "Pune") {
        //         this.client_location_id = 20;
        //     } else if (this.applicant_city === "Delhi") {
        //         this.client_location_id = 21;
        //     } else if (this.applicant_city === "Mumbai") {
        //         this.client_location_id = 23;
        //     } else if (this.applicant_city === "Chennai") {
        //         this.client_location_id = 24;
        //     } else if (this.applicant_city === "Jaipur") {
        //         this.client_location_id = 26;
        //     } else if (
        //         this.applicant_city === "Bengaluru" ||
        //         this.applicant_city === "Bengalore" ||
        //         this.applicant_city === "Bangalore"
        //     ) {
        //         this.client_location_id = 76;
        //     } else if (this.applicant_city === "Lucknow") {
        //         this.client_location_id = 100;
        //     } else if (this.applicant_city === "Kolkata") {
        //         this.client_location_id = 101;
        //     } else if (this.applicant_city === "Ahmedabad") {
        //         this.client_location_id = 104;
        //     } else if (this.applicant_city === "Ernakulam") {
        //         this.client_location_id = 165;
        //     } else {
        //         this.client_location_id = 76;
        //     }
        // },
        // city: function () {
        //     if (this.city === "Hyderabad") {
        //         this.client_location_id = 22;
        //     } else if (this.city === "Pune") {
        //         this.client_location_id = 20;
        //     } else if (this.city === "Delhi") {
        //         this.client_location_id = 21;
        //     } else if (this.city === "Mumbai") {
        //         this.client_location_id = 23;
        //     } else if (this.city === "Chennai") {
        //         this.client_location_id = 24;
        //     } else if (this.city === "Jaipur") {
        //         this.client_location_id = 26;
        //     } else if (
        //         this.city === "Bengaluru" ||
        //         this.city === "Bengalore" ||
        //         this.city === "Bangalore"
        //     ) {
        //         this.client_location_id = 76;
        //     } else if (this.city === "Lucknow") {
        //         this.client_location_id = 100;
        //     } else if (this.city === "Kolkata") {
        //         this.client_location_id = 101;
        //     } else if (this.city === "Ahmedabad") {
        //         this.client_location_id = 104;
        //     } else if (this.city === "Ernakulam") {
        //         this.client_location_id = 165;
        //     } else {
        //         this.client_location_id = 76;
        //     }
        // },
        //Level 3 Code
        kyc_documents: function (value) {
            if (value === "Aadhar Card") {
                this.document_id = 3;
                this.kyc_documents = "Aadhar_Card";
            } else if (value === "Photo") {
                this.document_id = 1;
                this.kyc_documents = "Photo";
            } else if (value === "PAN Card") {
                this.document_id = 2;
                this.kyc_documents = "Pan_Card";
            } else if (value === "Passport") {
                this.document_id = 4;
                this.kyc_documents = "Passport";
            } else if (value === "Voter Id") {
                this.document_id = 5;
                this.kyc_documents = "Voter_Id";
            } else if (value === "Driving License") {
                this.document_id = 6;
                this.kyc_documents = "Driving_License";
            }
        },
        bank_documents: function (value) {
            // console.log("gas", value);
            if (value === "Bank Statement - 3 Months") {
                this.bank_document_id = 19;
                this.bank_documents = "Bank Statement - 3 Months";
            } else if (value === "Bank Statement - 6 Months") {
                this.bank_document_id = 20;
                // console.log("gas", this.bank_document_id);
                this.bank_documents = "Bank Statement - 6 Months";
            } else if (value === "Cancelled Cheque") {
                this.bank_document_id = 38;
                this.bank_documents = "Cancelled Cheque";
            } else if (
                value === "Scanned Copy of Bank Statement/ Passbook front page"
            ) {
                this.bank_document_id = 77;
                this.bank_documents =
                    "Scanned Copy of Bank Statement/ Passbook front page";
            } else if (value === "Salary Slip - 3 Months") {
                this.bank_document_id = 18;
                this.bank_documents = "Salary Slip - 3 Months";
            }
        },
        bank_name: function (value) {
            if (value.label === value.label) {
                this.bank_document_id = value.id;
                this.bank_list_name = value.label;
                // console.log("bank_namd", this.bank_document_id, this.bank_list_name);
            }
        },
        account_type: function (value) {
            console.log("account type", value);
            if (this.account_type === "SAVINGS ACCOUNT") {
                this.account_type_id = 1;
            } else if (this.account_type === "CURRENT ACCOUNT") {
                this.account_type_id = 2;
            } else if (this.account_type === "CREDIT CARD") {
                this.account_type_id = 3;
            } else if (this.account_type === "SAVING-NRE") {
                this.account_type_id = 4;
            } else if (this.account_type === "SAVING-NRO") {
                this.account_type_id = 5;
            } else if (this.account_type === "OTHER") {
                this.account_type_id = 6;
            } else if (this.account_type === "OVER DRAFT") {
                this.account_type_id = 7;
            }
        },
    },
    methods: {
        handleClose() {
            this.$emit('update:popup', false);
        },
        getPopupData(){
            console.log("running getpopup data");
            console.log("popup value child in function",this.popup);
            if(this.popup === true){
             this.open = true;
            this.clearAll();
            this.userdata = this.studentDetails;
            // console.log("popup data", this.userdata);
            // this.getUserInfor();
            // this.DocumentsData();
            // this.getDocumentsInfo();
            // this.BankListData();
            this.EduData = this.LoanData;
            // console.log("Loan data", this.EduData);
            if (this.EduData != "") {
                let DataUser = JSON.parse(this.EduData.payload);
                
                this.DataUser = DataUser;
                this.ApplicationId = DataUser.eduvanz_response.application_id;
                console.log("dg", this.ApplicationId);
                this.LeadId = DataUser.eduvanz_response.lead_id;
                this.eduvanz_note = DataUser.eduvanz_request.notes;
                this.requested_amount =
                    DataUser.eduvanz_response.requested_amount;
                this.ipa_amount = DataUser.eduvanz_response.ipa_amount;
                this.FinancialId =
                    DataUser.eduvanz_response.financial_applicant_id;
                
                this.CibilStatus = DataUser.eduvanz_response.cibil_status;
                this.responseUserid = DataUser.eduvanz_response.user_id;
                this.IMDBResponse = DataUser.eduvanz_response.imdb_response;
                this.AddressChecking = this.DataUser.eduvanz_request.applicant_details.is_permanent_pincode_same_as_current_pincode;
                this.permanent_address_line1 = this.DataUser.eduvanz_request.applicant_details.current_address_line1;
                this.permanent_address_line2 = this.DataUser.eduvanz_request.applicant_details.current_address_line2;
                this.permanent_address_pincode = this.DataUser.eduvanz_request.applicant_details.pincode;
                this.permanent_monthly_rent = "0";
                this.current_monthly_rent = "0";
                if (DataUser.eduvanz_response.imdb_response !== "") {
                    this.getProductList();
                }
                this.DocumentsData();
                this.getUserInfor();
                this.getDocumentsInfo();
                // this.getProductList();
            }
            this.full_name = this.userdata.mwb.person_name;
            this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
            console.log("mobile", this.mobile);
            this.email = this.getEmailForCandidate(this.userdata.emails);
            this.address1 = this.userdata.mwb.address;
            this.applicant_city = this.userdata.mwb.city;
            if (this.DocumentsList.length > 1) {
                this.financeDocuments = [];
                this.KycDocument = [];
            }
        }
        },
        approvalCheck() {
            this.courseMessage = true;
        },
        disApprovalStatus() {
            this.courseMessage = false;
        },
        getClientLocationId(locationId) {
            if (locationId === "Hyderabad") {
                this.client_location_id = 22;
            } else if (locationId === "Pune") {
                this.client_location_id = 20;
            } else if (locationId === "Delhi") {
                this.client_location_id = 21;
            } else if (locationId === "Mumbai") {
                this.client_location_id = 23;
            } else if (locationId === "Chennai") {
                this.client_location_id = 24;
            } else if (locationId === "Jaipur") {
                this.client_location_id = 26;
            } else if (
                locationId === "Bengaluru" ||
                locationId === "Bengalore" ||
                locationId === "Bangalore"
            ) {
                this.client_location_id = 76;
            } else if (locationId === "Lucknow") {
                this.client_location_id = 100;
            } else if (locationId === "Kolkata") {
                this.client_location_id = 101;
            } else if (locationId === "Ahmedabad") {
                this.client_location_id = 104;
            } else if (locationId === "Ernakulam") {
                this.client_location_id = 165;
            } else {
                this.client_location_id = 76;
            }
            console.log("location Id", this.client_location_id);
        },
        // approvalCheck() {
        //     this.courseMessage = true;
        // },
        // disApprovalStatus() {
        //     this.courseMessage = false;
        // },
        clearAll() {
            // EventBus.$emit("reload-bigpopup-data");
            this.financial_applicant_profession = "";
            this.aadhar_number = "";
            this.professionType = "";
            this.CurrentResidenceType = "";
            this.current_monthly_rent = "";
            this.current_duration_of_stay = "";
            this.checkBox2 = "";
            this.permanent_address_line1 = "";
            this.permanent_address_line2 = "";
            this.permanent_address_pincode = "";
            this.permanent_residence_type = "";
            this.permanent_monthly_rent = "";
            this.permanent_duration_of_stay = "";
            this.work_company_name = "";
            this.EmployeType = "";
            this.annual_income = "";
            this.current_employment_duration = "";
            this.work_email_id = "";
            this.work_address_line1 = "";
            this.work_address_line2 = "";
            this.work_pincode = "";
            this.kyc_documents = "";
            this.bank_documents = "";
            this.bank_name = "";
            this.account_type = "";
            this.genders = "";
            this.pan_number = "";
            this.pin_code = "";
            this.pincodeconform = false;
            this.permanent_pincode = "";
            this.last_name = "";
            this.loan_amount = "";
            this.cibil_tenture = "";
            // this.course = "";
            this.radio3 = "";
            this.date_of_birth = "";
            this.address2 = "";
            this.eduvanz_note = "";
            this.billingaddress.street = "";
            this.billingaddress.city = "";
            this.billingaddress.state = "";
            this.billingaddress.zipcode = "";
            this.student_dob = "";
            this.student_full_name = "";
            this.student_genders = "";
            // this.applicant_city = "";
            if (this.DocumentData === "") {
                this.KycShowImage = false;
                this.BankPic = false;
                this.ProfileShowImage = false;
                this.PanShowImage = false;
            }
        },
        IpaCheck() {
            this.$vs.loading();
            let obj = {
                lead_id: this.LeadId,
                applicant_id: this.FinancialId,
                user_id: this.responseUserid,
                loan_amount: this.ipa_amount,
                continue_with_ipa_amount: this.loanIpaType,
            };
            console.log("data", obj);
            const url = `https://loans.milesforce.com/api/instantDecisionWithIPA`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("IPA data", response);
                    this.$emit("reload-bigpopup-data");
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: response.data.eduvanz_response.message,
                        color: "success",
                    });
                    this.open = false;
                    //   this.ProductList = response.data.eduvanz_response.product_list;
                    // console.log("data", response);
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getProductList() {
            this.$vs.loading();
             let obj = {
                    lead_id: this.LeadId,
                    applicant_id: this.FinancialId,
                    user_id: this.responseUserid,
                };
                console.log("data", obj);
            if (this.LeadId === "" || this.FinancialId === "" || this.responseUserid === "" || obj.applicant_id === undefined || obj.lead_id === undefined || obj.user_id === undefined) {
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            }  else {
                const url = `https://loans.milesforce.com/api/getProductList`;
                axios
                    .post(url, obj, {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        this.$vs.loading.close();
                        this.ListData = response.data.eduvanz_response;
                        console.log("listdata", this.ListData);
                        this.seletcPlan = [];
                        if (this.ListData.product_list !== "") {
                            this.ProductList =
                                response.data.eduvanz_response.product_list;
                            this.productlistcard = true;
                        }
                        console.log("product data", this.ProductList);
                        console.log("data", this.ListData);
                        // if(this.ListData.message !== "Lead Already Rejected/Dropped" || this.ListData.message !== "No product details present, pleae contact to Eduvanz" || this.ListData.message !== "Lead not in edit stage") {
                            
                        // } else {
                        //     this.seletcPlan = response.data.eduvanz_response.message;
                        // }
                        if(this.imdb_response === "IPA Failed"){
                            this.productlistcard = false;
                            this.enablebutton = false;  
                        }
                        if(this.ListData.message === "Lead Already Rejected/Dropped" || this.ListData.message === "No product details present, pleae contact to Eduvanz" || this.ListData.message === "Lead not in edit stage" || this.ListData.message === "No product details present") {
                            this.seletcPlan = [];
                        } else {
                            if (
                                response.data.eduvanz_response.selected_product !==
                                undefined || response.data.eduvanz_response.selected_product !==
                                ""
                            ) {
                                
                                
                                this.seletcPlan = response.data.eduvanz_response.selected_product.requested_emi;
                                
                                console.log("data", this.seletcPlan);
                                console.log("dummy", this.seletcPlan !== null);
                                if (this.seletcPlan !== null) {
                                    this.enablebutton = false;
                                    console.log("dummy", this.enablebutton);
                                    this.disablebutton = true;
                                } else if (this.seletcPlan === null) {
                                    this.enablebutton = true;

                                    this.disablebutton = false;
                                    console.log("dummy", this.enablebutton);
                                }
                            }
                        }

                        // console.log("data", response);
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.handleError(error);
                    });
            }
            
        },
        vSelectUpdate(value) {
            //   console.log("data", value);
            this.savedvalues = value;
        },
        ProductCheck() {
            this.$vs.loading();
            let obj = {
                lead_id: this.LeadId,
                applicant_id: this.FinancialId,
                user_id: this.responseUserid,
                tenure: this.savedvalues.tenure,
                flatroi: this.savedvalues.flatroi,
                emiAmount: this.savedvalues.emiAmount,
                finalinterestrateorrateofintfixed: this.savedvalues
                    .finalinterestrateorrateofintfixed,
                product: this.savedvalues.product,
                bre_emi_id: this.savedvalues.bre_emi_id,
            };
            console.log("data", obj);
            const url = `https://loans.milesforce.com/api/updateProductLIst`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("data", response);
                    EventBus.$emit("reload-bigpopup-data");
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: response.data.eduvanz_response.message,
                        color: "success",
                    });
                    this.open = false;
                    //   this.ProductList = response.data.eduvanz_response.product_list;
                    // console.log("data", response);
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getUserInfor() {
            const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=${this.userdata.mwb.id}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    if(response.data != ""){
                        this.UserId = response.data.id;
                        let loan_details_payload = JSON.parse(response.data.payload);
                        console.log("getLoanApplicationDetails",loan_details_payload.eduvanz_response.imdb_response);
                        this.imdb_response = loan_details_payload.eduvanz_response.imdb_response;
                    }
                    // console.log("mwb2 data", this.UserId);
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getDocumentsInfo() {
            axios
                .get(
                    `https://loans.milesforce.com/api/getLeadSubmissionDocumentsList?lead_id=${this.LeadId}`,
                    {
                        headers: {
                            headers: {
                                Authorization: `Bearer ${localStorage.userAccessToken}`,
                            },
                        },
                    }
                )
                .then((response) => {
                    this.DocumentData = response.data;
                    // console.log("message submit", this.DocumentData);
                    this.DocumentData.forEach((documents) => {
                        this.DocumentsList = documents;
                        // console.log(this.DocumentsList.document_name);

                        if (this.DocumentsList.document_name == "Photo") {
                            this.ProfileShowImage = true;
                        } else if (
                            this.DocumentsList.document_name == "Pan_Card"
                        ) {
                            this.PanShowImage = true;
                        } else if (
                            this.DocumentsList.document_name == "Aadhar_Card" ||
                            this.DocumentsList.document_name ==
                                "Driving_License" ||
                            this.DocumentsList.document_name == "Voter_id" ||
                            this.DocumentsList.document_name == "Passport"
                        ) {
                            this.KycShowImage = true;
                        } else if (
                            this.DocumentsList.document_name ==
                                "Bank Statement - 3 Months" ||
                            this.DocumentsList.document_name ==
                                "Bank Statement - 6 Months" ||
                            this.DocumentsList.document_name ==
                                "Cancelled Cheque" ||
                            this.DocumentsList.document_name ==
                                "Bank Passbook copy or Bank Statement with address"
                        ) {
                            this.BankPic = true;
                        }
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        DocumentsData() {
            axios
                .get(
                    `https://loans.milesforce.com/api/getEduvanzLeadDocumentsList?lead_id=${this.LeadId}`,
                    {
                        headers: {
                            headers: {
                                Authorization: `Bearer ${localStorage.userAccessToken}`,
                            },
                        },
                    }
                )
                .then((response) => {
                    // console.log("message", response.data);
                    // if (response != "") {
                    //   this.documents = response.data.eduvanz_response.doc_data;
                    //   console.log("message", this.documents);
                    // }
                    // if (this.documents != "") {
                    //   this.documents_details = this.documents;
                    //   console.log("djfhdy", this.documents_details);
                    //   // this.finance_documents = this.documents.document_categories;
                    // }
                    if (response.data.eduvanz_response !== null) {
                        this.documents_details =
                            response.data.eduvanz_response.doc_data;
                        // console.log("djfhdy", this.documents_details);
                        // this.finance_documents = this.documents.document_categories;
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        refreshData() {
            // console.log("resfrdsr");
            this.EduData;
        },

        getMobileColor(mob) {
            let color = "";
            if (mob.invalid === 1) {
                color = "danger";
            } else if (mob.pri_mary === 1) {
                color = "success";
            } else {
                color = "warning";
            }
            return color;
        },
        getEmailColor(mob) {
            let color = "";
            if (mob.invalid === 1) {
                color = "danger";
            } else if (mob.pri_mary === 1) {
                color = "success";
            } else {
                color = "warning";
            }
            return color;
        },
        async mobilePopup(mobile) {
            axios
                .get(
                    `${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    this.EmitEvent("open-mobile-options-popup", [
                        response.data,
                        this.userdata.mwb,
                        mobile,
                    ]);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        async emailPopup(email) {
            axios
                .get(
                    `${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    this.EmitEvent("open-email-options-popup", [
                        response.data,
                        this.userdata.mwb,
                        email,
                    ]);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        remove(item) {
            this.select1.splice(this.select1.indexOf(item), 1);
        },
        EmitEvent(emit, payload) {
            if (payload === "") {
                EventBus.$emit(emit);
            } else {
                EventBus.$emit(emit, payload);
            }
        },
        Profession1() {
                    //some changes
                    
            this.$vs.loading({
              type: 'default',
            })
            this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
            this.email = this.getEmailForCandidate(this.userdata.emails);
            this.applicant_city = this.userdata.mwb.city;
            this.getClientLocationId(this.applicant_city);
            let data_transform = {
                three_months: 3,
                six_months: 6,
                self: 1,
                supported: 2,
                others: 3,
                student_gender_male: 1,
                student_gender_female: 2,
                gender_male: 1,
                gender_female: 2,
                married: 1,
                unmarried: 2,
                1: 1,
                2: 2,
                true: 2,
                false: 3,
            };
            let profession_data = {
                "Working professional": 1,
                "Student": 2,
            };
            // console.log(data_transform);
            if (this.loanCheckType === "self") {
                this.fullName = this.full_name;
                this.genders = this.radio3;
            } else if (this.loanCheckType === "supported") {
                this.fullName = this.student_full_name;
                this.genders = this.radio4;
            }
            if (
                // !this.validateName(this.fullName) ||
                // !this.validateNumber(this.mobile) ||
                !this.pinCode(this.pin_code)
            ) {
                return;
            }
            if(this.working_profession === 2) {
            // if(this.applicant_type === "Student") {
                this.student_dob = this.date_of_birth;
                this.student_full_name = this.student_full_name;
                this.student_genders = this.genders;
                this.financial_applicant_profession = 3;
                // let looking_loan_for_message = "2";
                // let looking_loan_for_condition = 2;
                // let type_of_loan_message= "1";
                // let type_of_loan_condition = 1;


            }else {
                this.financial_applicant_profession = 1;
                if(this.profession_details === "Salaried"){
                 this.financial_applicant_profession = 1;
                }
                if(this.profession_details === "Self Employed Profession"){
                    this.financial_applicant_profession = 3;
                }
                if(this.profession_details === "Self Employed NonProfession"){
                    this.financial_applicant_profession = 4;
                }
                this.looking_loan_for = this.looking_for_loan_structure[
                            this.loanCheckType
                ];
            }
            if (this.last_name === "" ) {
                 this.$vs.notify({
                    title: "Last Name Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.course === "" ) {
                 this.$vs.notify({
                    title: "Course Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.radio3 === "" ) {
                this.$vs.notify({
                    title: "Gender Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.date_of_birth === "" ) {
                this.$vs.notify({
                    title: "Date of Birth Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.pan_number === "" ) {
                this.$vs.notify({
                    title: "Pan Number Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.client_location_id === "" ) {
                this.$vs.notify({
                    title: "Location Id Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                this.applicant_city = this.userdata.mwb.city;
                this.getClientLocationId(this.applicant_city);
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.address2 === "") {
                 this.$vs.notify({
                    title: "Land Mark Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.pin_code === "" ) {
                 this.$vs.notify({
                    title: "Pin Code Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.loan_amount === "" ) {
                 this.$vs.notify({
                    title: "Loan Amount Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (
                Object.values(this.billingaddress).join(",") === ",,," ||
                Object.values(this.billingaddress).join(",") === "" ||
                this.billingaddress.street === "" ||
                this.billingaddress.city === ""||
                this.billingaddress.state === ""||
                this.billingaddress.zipcode === ""
            ) {
                this.$vs.notify({
                    title: "Current residential address must be given.",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else {
              // this.$vs.loading.close();
                this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
                this.email = this.getEmailForCandidate(this.userdata.emails);
                this.applicant_city = this.userdata.mwb.city;
                this.getClientLocationId(this.applicant_city);
                this.address = Object.values(this.billingaddress).join(",");
                this.post_API_obj = {
                    meta_data: 1234,
                    notes: this.address,
                    course_details: {
                        loan_amount: this.loan_amount,
                        client_institute_id: this.client_institute_id,
                        client_location_id: this.client_location_id,
                        client_course_id: this.client_course_id,
                        // client_institute_id: 1,
                        // client_location_id: 20,
                        // client_course_id: 123,
                        tenure: data_transform[this.cibil_tenture],
                    },
                    applicant_details: {
                        profession: profession_data[this.applicant_type],
                        financial_applicant_profession: this.financial_applicant_profession,
                        mobile: this.mobile,
                        email: this.email,
                        first_name: this.fullName,
                        student_full_name: this.student_full_name,
                        student_dob: this.student_dob,
                        student_gender: data_transform[this.student_genders],
                        gender: data_transform[this.genders],
                        dob: this.date_of_birth,
                        pan: this.pan_number,
                        current_address_line1: this.address1,
                        current_address_line2: this.address2,
                        pincode: this.pin_code,
                        looking_loan_for: this.looking_for_loan_structure[
                            this.loanCheckType
                        ],
                        is_permanent_pincode_same_as_current_pincode:
                            data_transform[this.pincodeconform],
                        permanent_pincode: this.permanent_pincode,
                        type_of_loan: this.type_of_loan,
                        last_name: this.last_name,
                        marital_status: data_transform[this.marital_status],
                        consent_for_tems_condition: this
                            .consent_for_tems_condition,
                        consent_for_covid_terms_conditions: this
                            .consent_for_covid_terms_conditions,
                    },
                    
                };
                // if(this.working_profession == 4){
                //     this.post_API_obj.applicant_details.profession = 3;
                // }
                console.log("self Data", this.post_API_obj);
                // setTimeout( ()=> {
                //   this.$vs.loading.close()
                // }, 2000);


                axios
                    .post(
                        `https://loans.milesforce.com/api/CheckForLoanEligibility`,
                        this.post_API_obj,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.userAccessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === "error") {
                            this.handleEduvanzError(response.data);
                            EventBus.$emit("reload-bigpopup-data");
                        } else {
                            this.MilesforceRequest(response.data);
                            EventBus.$emit("reload-bigpopup-data");
                        }

                        // this.MilesforceRequest(response.data);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },
        handleEduvanzError(response) {
            this.$vs.loading.close();
            this.$vs.notify({
                title: `Error`,
                text: response.message,
                color: "danger",
                fixed: true,
                click:()=>{},
            });
        },
        MilesforceRequest(response) {
            response.mwb_id = this.userdata.mwb.id;
            // iam adding
            // console.log("mwb_id", response.mwb_id);
            let url = `${constants.SERVER_API}saveEduvanzLoanStatus`;
            axios
                .post(url, response, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    
                    this.handleNotification(response);
                    if (response.data.status == "success") {
                        this.getUserInfor();
                        this.open = false;
                        EventBus.$emit("reload-bigpopup-data");
                    }
                    this.$vs.loading.close();
                    // console.log(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        Profession2() {
            this.$vs.loading({
                type: 'default',
            })
            this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
            this.email = this.getEmailForCandidate(this.userdata.emails);
            this.applicant_city = this.userdata.mwb.city;
            this.getClientLocationId(this.applicant_city);
            let data_transform = {
                three_months: 3,
                six_months: 6,
                self: 1,
                supported: 2,
                others: 3,
                student_gender_male: 1,
                student_gender_female: 2,
                gender_male: 1,
                gender_female: 2,
                married: 1,
                unmarried: 2,
                1: 1,
                2: 2,
                true: 2,
                false: 3,
            };
            let profession_data = {
                "Working professional": 1,
                "Student": 2,
            };
            if(this.applicant_type === "Student") {
                this.student_dob = this.date_of_birth;
                this.student_full_name = this.student_full_name;
                this.student_genders = this.genders;
                // this.financial_applicant_profession = 3;
                // this.looking_loan_for = 1;
                // let looking_loan_for_message = "2";
                // let looking_loan_for_condition = 2;
                // let type_of_loan_message= "1";
                // let type_of_loan_condition = 1;

                
            } 
                // this.financial_applicant_profession = 1;
                if(this.profession_details === "Salaried"){
                 this.financial_applicant_profession = 1;
                }
                if(this.profession_details === "Self Employed Profession"){
                    this.financial_applicant_profession = 3;
                }
                if(this.profession_details === "Self Employed NonProfession"){
                    this.financial_applicant_profession = 4;
                }
                this.looking_loan_for = this.looking_for_loan_structure[
                            this.loanCheckType
                ];
            
            console.log(this.billingaddress);
            if (this.loanCheckType === "self") {
                this.fullName = this.full_name;
                this.genders = this.radio3;
            } else if (this.loanCheckType === "supported") {
                this.fullName = this.student_full_name;
                this.genders = this.radio4;
            }
            if (this.loanCheckType === "self" && this.applicant_type === "Student") {
                this.fullName = this.student_full_name;
                this.genders = this.radio4;
            }
            if (
                // !this.validateName(this.fullName) ||
                // !this.validateNumber(this.mobile) ||
                !this.pinCode(this.pin_code)
            ) {
                return;
            }
            if (this.student_date_of_birth === "" ) {
                 this.$vs.notify({
                    title: "Student Date Of Birth Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.first_name === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant First Name Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.last_name === "" ) {
                this.$vs.notify({
                    title: "Financial Applicant Last Name Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.client_location_id === "" ) {
                this.$vs.notify({
                    title: "Location Id Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                this.applicant_city = this.userdata.mwb.city;
                this.getClientLocationId(this.applicant_city);
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.radio4 === "" ) {
                this.$vs.notify({
                    title: "Financial Applicant Gender Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.date_of_birth === "" ) {
                this.$vs.notify({
                    title: "Financial Applicant Date Of Birth Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.marital_status === "") {
                 this.$vs.notify({
                    title: "Financial Applicant Marital Status Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            }  else if (this.email === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant Email Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.address1 === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant Address Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.address2 === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant Land Mark Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.pan_number === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant Pan Number Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.pin_code === "" ) {
                 this.$vs.notify({
                    title: "Financial Applicant Pincode Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (this.course === "" ) {
                 this.$vs.notify({
                    title: "Course Field Will Not Be Empty",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else if (
                Object.values(this.billingaddress).join(",") === ",,," ||
                Object.values(this.billingaddress).join(",") === "" ||
                this.billingaddress.street === "" ||
                this.billingaddress.city === ""||
                this.billingaddress.state === ""||
                this.billingaddress.zipcode === ""
            ) {
                this.$vs.notify({
                    title: "Current residential address must be given.",
                    color: "danger",
                    position: "top-center",
                });
                setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else {
                // this.mobile = this.getMobileForCandidate(this.userdata.mobiles);
                // this.email = this.getEmailForCandidate(this.userdata.emails);
                // this.applicant_city = this.userdata.mwb.city;
                // this.getClientLocationId(this.applicant_city);
                // this.address = Object.values(this.billingaddress).join(",");
                this.applicant_city = this.userdata.mwb.city;
                this.getClientLocationId(this.applicant_city);
                this.address = Object.values(this.billingaddress).join(",");
                this.post_API_obj = {
                    meta_data: 1234,
                    notes: this.address,
                    course_details: {
                        loan_amount: this.loan_amount,
                        client_institute_id: this.client_institute_id,
                        client_location_id: this.client_location_id,
                        client_course_id: this.client_course_id,
                        // client_institute_id: 1,
                        // client_location_id: 20,
                        // client_course_id: 123,
                        tenure: data_transform[this.cibil_tenture],
                    },
                    applicant_details: {
                        // profession: this.working_profession,
                        profession: profession_data[this.applicant_type],
                        financial_applicant_profession: this.financial_applicant_profession,
                        mobile: this.mobile,
                        email: this.email,
                        student_full_name: this.fullName,
                        gender: data_transform[this.genders],
                        dob: this.date_of_birth,
                        pan: this.pan_number,
                        current_address_line1: this.address1,
                        current_address_line2: this.address2,
                        pincode: this.pin_code,
                        looking_loan_for: this.looking_loan_for,
                        type_of_loan: this.type_of_loan,
                        student_dob: this.student_date_of_birth,
                        is_permanent_pincode_same_as_current_pincode:
                            data_transform[this.pincodeconform],
                        permanent_pincode: this.permanent_pincode,
                        student_gender: data_transform[this.student_gender],
                        first_name: this.first_name,
                        last_name: this.last_name,
                        marital_status: data_transform[this.marital_status],
                        consent_for_tems_condition: this
                            .consent_for_tems_condition,
                        consent_for_covid_terms_conditions: this
                            .consent_for_covid_terms_conditions,
                    },
                };
            
                console.log("supported Data", this.post_API_obj);
                axios
                .post(
                    `https://loans.milesforce.com/api/CheckForLoanEligibility`,
                    this.post_API_obj,
                    {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                    }
                )
                .then((response) => {
                    console.log(response);
                    // if (response.data.eduvanz_response.error.length === 0) {
                    // EventBus.$emit("reload-bigpopup-data");
                    // this.MilesforceRequest(response.data);
                    // } else {
                    // EventBus.$emit("reload-bigpopup-data");
                    // this.handleEduvanzError(response.data);
                    // this.MilesforceRequest(response.data);
                    // }
                    if (response.data.status === "error") {
                        this.handleEduvanzError(response.data);
                        EventBus.$emit("reload-bigpopup-data");
                    } else {
                        this.MilesforceRequest(response.data);
                        EventBus.$emit("reload-bigpopup-data");
                    }
                    // this.MilesforceRequest(response.data);
                    // this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
            }
        },
        LoanCheck() {
            // this.$vs.loading();
            if (this.loanCheckType === "self" && this.applicant_type === 'Working professional') {
                this.Profession1();
            } else {
                this.Profession2();
            }
        },
        pinCode(pincode) {
            if (pincode.length == 6) {
                pincode = pincode.substr(pincode.length - 6);
                if (parseInt(pincode)) {
                    return true;
                } else {
                    this.$vs.notify({
                        title: "Enter a valid Pincode",
                        text: "Enter a valid Pincode to continue",
                        color: "danger",
                        position: "top-center",
                    });
                     setTimeout( ()=> {
                    this.$vs.loading.close()
                    }, 2000);
                    return false;
                }
            } else {
                this.$vs.notify({
                    title: "Pincode should be atleast 6 characters in length",
                    text: "Enter a valid valid",
                    color: "danger",
                    position: "top-center",
                });
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
                return false;
            }
        },
        validateNumber(number) {
            if (number.length >= 8) {
                number = number.substr(number.length - 8);
                if (parseInt(number)) {
                    return true;
                } else {
                    this.$vs.notify({
                        title: "Enter a valid number",
                        text: "Enter a valid number to continue",
                        color: "danger",
                        position: "top-center",
                    });
                     setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
                    return false;
                }
            } else {
                this.$vs.notify({
                    title: "Number should be atleast 8 characters in length",
                    text: "Enter a valid number",
                    color: "danger",
                    position: "top-center",
                });
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
                return false;
            }
        },
        validateName(name) {
            if (this.allLetter(name)) {
                if (name.length >= 3) {
                    return true;
                } else {
                    this.$vs.notify({
                        title: "Enter a valid Name to continue",
                        text: "Please Enter Full Name",
                        color: "danger",
                        position: "top-center",
                    });
                     setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
                    return false;
                }
            } else {
                this.$vs.notify({
                    title: "Enter a valid name",
                    text: "Enter a valid Name to continue",
                    color: "danger",
                    position: "top-center",
                });
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
                return false;
            }
        },
        allLetter(text) {
            var letters = /^[A-Za-z ]+$/;
            if (text.match(letters)) {
                return true;
            } else {
                return false;
            }
        },
        validateEmail(email) {
            if (this.allLetter(email)) {
                if (email.length >= 1) {
                    return true;
                } else {
                    this.$vs.notify({
                        title: "Enter a valid Email to continue",
                        text: "Please Fill With valid Email",
                        color: "danger",
                        position: "top-center",
                    });
                    return false;
                }
            } else {
                this.$vs.notify({
                    title: "Enter a valid Email",
                    text: "Enter a valid Email to continue",
                    color: "danger",
                    position: "top-center",
                });
                return false;
            }
        },
        //level 2 code
        checkbox_1() {
            this.$vs.loading();
            let data_transform = {
                true: 2,
                false: 1,
                Self_Employed_Profession: 3,
                Self_Employed_Non_Profession: 4,
                Salaried: 1,
                Less_Than_six_Months: 1,
                Six_Months_to_One_Year: 2,
                One_Year_to_Two_Years: 3,
                More_Than_Two_years: 4,
                Rented: 1,
                Owned: 0,
                Private: 0,
                Goverment: 1,
            };
            
            if (
                // this.aadhar_number === "" ||
                // this.professionType === "" ||
                // this.CurrentResidenceType === "" ||
                // this.current_monthly_rent === "" ||
                // this.permanent_address_line1 === "" ||
                // this.permanent_address_pincode === "" ||
                // this.permanent_residence_type === "" ||
                // this.permanent_monthly_rent === "" ||
                // this.permanent_duration_of_stay === "" ||
                // this.work_company_name === "" ||
                // this.EmployeType === "" ||
                // this.annual_income === "" ||
                // this.current_employment_duration === "" ||
                // this.work_email_id === "" ||
                // this.work_address_line1 === "" ||
                this.work_pincode === ""
            ) {
                this.$vs.notify({
                    title: "Some fields are missing in the form.",
                    text:
                        "please fill all the require fields in the form to complete personal details form",
                    color: "danger",
                    position: "top-center",
                });
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else {
                if(this.AddressChecking !== 2) {
                    this.permanent_address_line1 = this.DataUser.eduvanz_request.notes;
                    this.permanent_address_pincode = this.DataUser.eduvanz_request.applicant_details.pincode;
                    this.permanent_residence_type = data_transform[this.CurrentResidenceType];
                    this.permanent_duration_of_stay = data_transform[this.current_duration_of_stay];
                    console.log("this.permanent_address_line1", this.permanent_address_line1)
                }
                this.post_API_obj = {
                    lead_id: this.LeadId,
                    user_id: this.responseUserid,
                    applicant_id: this.FinancialId,
                    aadhar_number: this.aadhar_number,
                    // profession: data_transform[this.professionType], //1-salaried, 3-Self Employed Profession, 4-Self Employed NonProfession - it has to be fiancial applicant (no student)
                    current_residence_type:
                        data_transform[this.CurrentResidenceType], //1-Rented, 2-owned
                    current_monthly_rent: this.current_monthly_rent, //if residency type is rented (null if owned property)
                    current_duration_of_stay:
                        data_transform[this.current_duration_of_stay], //1-Less Than 6 Month, 2- 6 Months to 1 Year, 3- 1 Year to 2 Years, 4- More Than 2 years
                    is_permanent_address_same_as_current:this.AddressChecking, // keep 2 if permanant address is same current address
                    permanent_address_line1: this.permanent_address_line1, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_address_line2: this.permanent_address_line2, //optional
                    permanent_address_pincode: this.permanent_address_pincode, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_residence_type: this.permanent_residence_type, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_monthly_rent: this.permanent_monthly_rent, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_duration_of_stay:
                        data_transform[this.permanent_duration_of_stay], //manadate  if is_permanent_address_same_as_current !=2
                    work_company_name: this.work_company_name, //mandate
                    employer_type: data_transform[this.EmployeType], //mandate 0-Private Sector,  1-Goverment Sector
                    annual_income: this.annual_income, //mandate
                    current_employment_duration:
                        data_transform[this.current_employment_duration], //mandate 1- Less Than 6 Month 2- 6 Months to 1 Year, 3- 1 Year to 2 Years, 4-More Than 2 years
                    work_email_id: this.work_email_id, //mandate
                    work_address_line1: this.work_address_line1, //mandate
                    work_address_line2: this.work_address_line2, //optional
                    work_pincode: this.work_pincode, //mandate
                };
            }
            console.log("Data1", this.post_API_obj);
            axios
                .post(
                    `https://loans.milesforce.com/api/updateEduvanzLeadInformation`,
                    this.post_API_obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    if(response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message == "success") {
                        this.open = false;
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            text: `Successfully submitted your details`,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                    } else {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: `Successfully submitted your details`,
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        checkbox_2() {
            this.$vs.loading();
            let data_transform = {
                true: 2,
                false: 1,
                Self_Employed_Profession: 3,
                Self_Employed_Non_Profession: 4,
                Salaried: 1,
                Less_Than_six_Months: 1,
                Six_Months_to_One_Year: 2,
                One_Year_to_Two_Years: 3,
                More_Than_Two_years: 4,
                "": 4,
                Rented: "1",
                Owned: "0",
                Private: 0,
                Goverment: 1,
            };
            if (
                // this.aadhar_number === "" ||
                // this.professionType === "" ||
                // this.work_company_name === "" ||
                // this.EmployeType === "" ||
                // this.annual_income === "" ||
                // this.current_employment_duration === "" ||
                // this.work_email_id === "" ||
                // this.work_address_line1 === "" ||
                this.work_pincode === ""
            ) {
                this.$vs.notify({
                    title: "Some fields are missing in the form.",
                    text:
                        "please fill all the require fields in the form to complete personal details form",
                    color: "danger",
                    position: "top-center",
                });
                 setTimeout( ()=> {
                  this.$vs.loading.close()
                }, 2000);
            } else {
                if(this.AddressChecking !== 2) {
                    this.permanent_address_line1 = this.DataUser.eduvanz_request.notes;
                    this.permanent_address_pincode = this.DataUser.eduvanz_request.applicant_details.pincode;
                    this.permanent_residence_type = data_transform[this.CurrentResidenceType];
                    this.permanent_monthly_rent = "0";
                    this.current_monthly_rent = "0";
                    data_transform[this.permanent_duration_of_stay] = data_transform[this.current_duration_of_stay];
                    console.log("this.permanent_address_line1", this.permanent_address_line1)
                }
                if(this.AddressChecking === 2) {
                    this.permanent_monthly_rent = "0";
                    this.current_monthly_rent = "0";
                    
                }
                this.post_API_obj = {
                    lead_id: this.LeadId,
                    user_id: this.responseUserid,
                    applicant_id: this.FinancialId,
                    aadhar_number: this.aadhar_number,
                    // profession: data_transform[this.professionType], //1-salaried, 3-Self Employed Profession, 4-Self Employed NonProfession - it has to be fiancial applicant (no student)
                    current_residence_type:
                        data_transform[this.CurrentResidenceType], //1-Rented, 2-owned
                    current_monthly_rent: this.current_monthly_rent, //if residency type is rented (null if owned property)
                    current_duration_of_stay:
                        data_transform[this.current_duration_of_stay], //1-Less Than 6 Month, 2- 6 Months to 1 Year, 3- 1 Year to 2 Years, 4- More Than 2 years
                    is_permanent_address_same_as_current:this.AddressChecking, // keep 2 if permanant address is same current address
                    permanent_address_line1: this.permanent_address_line1, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_address_line2: this.permanent_address_line2, //optional
                    permanent_address_pincode: this.permanent_address_pincode, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_residence_type: this.permanent_residence_type, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_monthly_rent: this.permanent_monthly_rent, //manadate  if is_permanent_address_same_as_current !=2
                    permanent_duration_of_stay:
                        data_transform[this.permanent_duration_of_stay], //manadate  if is_permanent_address_same_as_current !=2
                    work_company_name: this.work_company_name, //mandate
                    employer_type: data_transform[this.EmployeType], //mandate 0-Private Sector,  1-Goverment Sector
                    annual_income: this.annual_income, //mandate
                    current_employment_duration:
                        data_transform[this.current_employment_duration], //mandate 1- Less Than 6 Month 2- 6 Months to 1 Year, 3- 1 Year to 2 Years, 4-More Than 2 years
                    work_email_id: this.work_email_id, //mandate
                    work_address_line1: this.work_address_line1, //mandate
                    work_address_line2: this.work_address_line2, //optional
                    work_pincode: this.work_pincode, //mandate
                };
            }
            console.log("Data2", this.post_API_obj);
            axios
                .post(
                    `https://loans.milesforce.com/api/updateEduvanzLeadInformation`,
                    this.post_API_obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    if(response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message == "success") {
                        this.open = false;
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            text: `Successfully submitted your details`,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                    } else {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: `Successfully submitted your details`,
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        submit() {
            if (this.checkBox2 === false) {
                this.checkbox_1();
            } else {
                this.checkbox_2();
            }
        },
        Level2pinCode(pincode) {
            if (pincode.length == 6) {
                pincode = pincode.substr(pincode.length - 6);
                if (parseInt(pincode)) {
                    return true;
                } else {
                    this.$vs.notify({
                        title: "Enter a valid Pincode",
                        text: "Enter a valid Pincode to continue",
                        color: "danger",
                        position: "top-center",
                    });
                    return false;
                }
            } else {
                this.$vs.notify({
                    title: "Pincode should be atleast 6 characters in length",
                    text: "Enter a valid valid",
                    color: "danger",
                    position: "top-center",
                });
                return false;
            }
        },

        ProfileshowImg() {
            axios
                .get(
                    `https://loans.milesforce.com/api/fetchUploadedDocument?mwb_id=${this.DataUser.mwb_id}&document_name=Photo&page_id=1`,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log("data", response);
                    this.imagedata = response;
                    EventBus.$emit("open-ImagePopup", this.imagedata);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        PanshowImg() {
            axios
                .get(
                    `https://loans.milesforce.com/api/fetchUploadedDocument?mwb_id=${this.DataUser.mwb_id}&document_name=Pan_Card&page_id=1`,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log("data", response);
                    this.imagedata = response;
                    EventBus.$emit("open-ImagePopup", this.imagedata);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        KycshowImg() {
            this.DocumentData.forEach((documents) => {
                this.DocumentsList = documents;
                // console.log("shdas", this.DocumentsList);
                if (
                    this.DocumentsList.document_name == "Aadhar_Card" ||
                    this.DocumentsList.document_name == "Driving_License" ||
                    this.DocumentsList.document_name == "Voter_id" ||
                    this.DocumentsList.document_name == "Passport"
                ) {
                    this.DocKycShow = this.DocumentsList.document_name;
                    // console.log("shdas", this.DocKycShow);
                }
            });
            axios
                .get(
                    `https://loans.milesforce.com/api/fetchUploadedDocument?mwb_id=${this.DataUser.mwb_id}&document_name=${this.kyc_documents}&page_id=${this.document_upload}`,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log("data", response);
                    this.imagedata = response;
                    EventBus.$emit("open-ImagePopup", this.imagedata);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        FinanceImg() {
            this.DocumentData.forEach((documents) => {
                this.DocumentsList = documents;
                // console.log("shdas", this.DocumentsList);
                if (
                    this.DocumentsList.document_name ==
                        "Bank Statement - 3 Months" ||
                    this.DocumentsList.document_name ==
                        "Bank Statement - 6 Months" ||
                    this.DocumentsList.document_name == "Cancelled Cheque" ||
                    this.DocumentsList.document_name ==
                        "Bank Passbook copy or Bank Statement with address"
                ) {
                    this.DocfncShow = this.DocumentsList.document_name;
                    console.log("shdas", this.DocKycShow);
                }
            });
            axios
                .get(
                    `https://loans.milesforce.com/api/fetchUploadedDocument?mwb_id=${this.DataUser.mwb_id}&document_name=${this.DocfncShow}&page_id=1`,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log("data", response);
                    this.imagedata = response;
                    EventBus.$emit("open-ImagePopup", this.imagedata);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        FrontSide() {
            this.$vs.loading();
            let formData = new FormData();
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", this.document_id);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", this.document_upload);
            formData.append("document_name", this.kyc_documents);
            formData.append("myfile", this.KycFront);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.KycShowImage = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        BankDocument() {
            this.$vs.loading();
            let formData = new FormData();
            // console.log("drds", this.bank_document_id);
            formData.append("accountType", this.account_type_id);
            formData.append("bank_id", this.bank_document_id);
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", this.bank_document_id);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", 1);
            formData.append("document_name", this.bank_documents);
            formData.append("myfile", this.Bank);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    }
                    else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.BankPic = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        BackSide() {
            this.$vs.loading();
            let formData = new FormData();
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", this.document_id);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", this.document_upload);
            formData.append("document_name", this.kyc_documents);
            formData.append("myfile", this.KycBack);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.KycShowImage = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        BothSide() {
            this.$vs.loading();
            let formData = new FormData();
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", this.document_id);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", this.document_upload);
            formData.append("document_name", this.kyc_documents);
            formData.append("myfile", this.KycBoth);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.KycShowImage = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        ProfilePic() {
            this.$vs.loading();
            let formData = new FormData();
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", 1);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", 1);
            formData.append("document_name", "Photo");
            formData.append("myfile", this.Profile);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.ProfileShowImage = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        PanCard() {
            this.$vs.loading();
            let formData = new FormData();
            formData.append("mwb_id", this.DataUser.mwb_id);
            formData.append("lead_id", this.DataUser.eduvanz_response.lead_id);
            formData.append("user_id", this.UserId);
            formData.append("document_type_id", 2);
            formData.append(
                "applicant_id",
                this.DataUser.eduvanz_response.financial_applicant_id
            );
            formData.append("page_id", 1);
            formData.append("document_name", "Pan_Card");
            formData.append("myfile", this.Pan);
            axios
                .post(
                    "https://loans.milesforce.com/api/uploadEduvanzLeadDocuments",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            // text: ,
                            color: "danger",
                        });
                    } 
                    if (response.data.eduvanz_response.message === "Validation Error") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Fail") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.error[0],
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message !== "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (
                        response.data.eduvanz_response.message ==
                        "The Photo you uploaded is not proper...."
                    ) {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "danger",
                        });
                    } else if (response.data.eduvanz_response.message === "Document has been Successfully Uploaded") {
                        this.$vs.notify({
                            title: response.data.eduvanz_response.message,
                            // text: ,
                            color: "success",
                        });
                        EventBus.$emit("reload-bigpopup-data");
                        this.PanShowImage = true;
                    }
                    
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        AssignResponseDataToImageUrl(data) {
            this.image_url = data;
        },
        onProfileChangeFileUpload() {
            this.Profile = this.$refs.Profilefile.files[0];
            // console.log("data", this.Profile);
        },
        onPanChangeFileUpload() {
            this.Pan = this.$refs.Panfile.files[0];
            // console.log("data", this.Pan);
        },
        onKycFrontChangeFileUpload() {
            this.KycFront = this.$refs.Frontfile.files[0];
            // console.log("data", this.KycFront);
        },
        onKycBackChangeFileUpload() {
            this.KycBack = this.$refs.Backfile.files[0];
            // console.log("data", this.KycBack);
        },
        onKycBothChangeFileUpload() {
            this.KycBoth = this.$refs.Bothfile.files[0];
            // console.log("data", this.KycBoth);
        },
        onBankChangeFileUpload() {
            this.Bank = this.$refs.Bankfile.files[0];
            // console.log("data", this.Bank);
        },
        successUpload(e) {
            this.uploaded = true;
            console.log("success", e);
        },
        errorUpload(e) {
            console.log("error", e);
        },
        changeUpload(e, files) {
            console.log("change", e);
            this.saveFile = files;
            console.log("result", this.saveFile);
        },
        deleteFile(e) {
            this.saveFile.splice(e, 1);
            console.log("delete file", this.saveFile.splice(e, 1));
        },
    },
};
</script>

<style>
.crpopupwidth .vs-popup {
    min-width: 50%;
}
.vhd-input[data-v-25b15922] {
    min-width: 250px;
    padding: 5px;
    border: 1px solid #eee;
    color: #505050;
    font-size: 16px;
    line-height: 9px;
    border-radius: 8px;
    border-color: lightgray;
    outline: none;
}
.vs--searchable .vs__dropdown-toggle {
    height: 38px;
    font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
    font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
    font-size: 13px;
}
</style>
