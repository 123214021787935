<template>
  <vs-popup
    class="holamundo"
    title="Add Student Cma referrance"
    :active.sync="open"
    style="z-index:53001"
  >
     <vs-row class="gap" >
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <p>Search With :</p>
      </vs-col>
        <vs-col vs-w="8">
          <div>
            <span><vs-radio v-model="searchType" vs-name="searchType" vs-value="Name">Name</vs-radio>&nbsp;&nbsp;</span>
            <span>&nbsp;&nbsp;<vs-radio v-model="searchType" vs-name="searchType" vs-value="Identity">Can-ID</vs-radio></span>
          </div>
        </vs-col>

      </vs-row>
    <vs-row style="margin-bottom: 6%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <p >Reference Name:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <!-- <vs-input class="inputx" placeholder="Name" v-model="name" /> -->
        <v-select
          style="width: 200px; z-index:2500; position:fixed"
          :filterable="false"
          :options="mwb_names"
          @search="autoCompleteName"
          v-model="source_details"
        >
          <template slot="no-options"
            >Type name of referred MWB candidate</template
          >

          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.identity }} - {{ option.person_name }} -
              {{ option.level }}
            </div>
          </template>

          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.identity }} - {{ option.person_name }} -
              {{ option.level }}
            </div>
          </template>
        </v-select>
      </vs-col>
    </vs-row>
    <vs-row  style="margin-bottom: 6%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <p >Referrance Date:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <datepicker
          placeholder="Select Date"
          v-model="date"
          style="width:350px; z-index:2000; position:fixed"
        ></datepicker>
      </vs-col>
    </vs-row>
    <vs-row  style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
        <p >Bank Details:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <!-- <v-select
          class="w-full"
          placeholder="Status"
          v-model="status"
          :options="statusoptions"
          label="text"
        ></v-select>-->
        <vs-textarea
          class="inputx"
          placeholder="Bank Details"
          v-model="bankdetails"
        />
      </vs-col>
    </vs-row>
    <!-- <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Bonus:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-checkbox v-model="bonus"></vs-checkbox>
      </vs-col>
    </vs-row>-->
    <vs-row  style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="gradient" @click="addDetails" icon="add"
          >ADD</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    "v-select": vSelect
  },
  data() {
    return {
      searchType:'Name',
      open: false,
      mwb_names: [],
      name: "",
      date: "",
      status: "",
      bonus: "",
      id: "",
      source_details: null,
      bankdetails: "",
      student_reference: "",
      person_id: "",
      mwb_id: "",
      referred_person_id: "",
      referred_mwb_id: "",
      referred_person_name: "",
      identity: ""
      // statusoptions: [
      //   { text: "L1", value: 0 },
      //   { text: "L2", value: 1 },
      //   { text: "L3", value: 2 },
      //   { text: "L4", value: 3 },
      //   { text: "L5", value: 4 },
      //   { text: "L6", value: 5 },
      //   { text: "L7", value: 6 },
      //   { text: "M1", value: 7 },
      //   { text: "M2", value: 8 },
      //   { text: "M3", value: 9 },
      //   { text: "M4", value: 10 },
      //   { text: "M5", value: 11 },
      //   { text: "M6", value: 12 },
      //   { text: "M7", value: 13 }
      // ]
    };
  },
  watch:{
    searchType(val){
      if(val === ""){
      this.mwb_names = []
      this.source_details = ""
      }
    }
  },
  mounted() {
    EventBus.$on("open-add-student-cma-referrance", payload => {
      // this.payload = payload;
      this.person_id = payload.cma.person_id;
      this.mwb_id = payload.cma.mwb_id;
      // (this.referred_person_name =
      //   payload.student_reference.referred_person_name);
      // this.bankdetails = [];
      // this.bankdetails.push(payload.bank_details);
      // this.bankdetails = payload.student_reference.bank_details;
      //   (this.name = "")
      // )),
      //   (this.date = ""),
      //   (this.status = ""),
      //   (this.bonus = false),
      //   (this.id = payload);
      this.open = true;
      // console.log(this.id)
    });
  },
  methods: {
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      // console.log('what are we searching for', search)
      loading(true);
      let searchtype = this.searchType;
      this.fetchMwbs(loading, search,searchtype, this);
    },
    fetchMwbs: _.debounce((loading, search,searchtype, vm) => {
      let url = `${constants.SERVER_API}searchMwbNameForReferenceWEB?name=${escape(
				search
			)}&type=${searchtype}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          vm.mwb_names = response.data;
          loading(false);
        });
    }, 350),
    addDetails() {
      console.log("selected mwb", this.source_details);
      let url = `${constants.SERVER_API}addStudentReference`;
      if (this.bankdetails === "") {
        console.log("df", this.bankdetails);
        this.$vs.notify({
          title: "Bank Details is required",
          text: "Please fill out the details in the bank details section",
          color: "danger"
        });
        return;
      }
      let obj = {
        reference_given_date: new Date(this.date).valueOf() / 1000,
        bank_details: this.bankdetails,
        person_id: this.person_id,
        mwb_id: this.mwb_id,
        referred_person_id: this.source_details.person_id,
        referred_mwb_id: this.source_details.id,
        referred_person_name: this.source_details.person_name
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("edit", response.data);
          this.$emit("reference-added", response.data);
          this.handleNotification(response);
          if (response.data.status === "success") {
            if (this.$route.path === "/queue") {
              // EventBus.$emit("update-queue-addition");
              EventBus.$emit("update-activelead-data");
            } else {
              EventBus.$emit("reload-bigpopup-data");
            }
            this.open = false;
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style></style>
