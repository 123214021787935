<template>
  <vs-popup :active.sync="open">
    <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row mb-6">
          <div class="vx-col">
            <span>Amount</span>
          </div>
          <div class="vx-col">
            <vs-input size="medium" placeholder="Amount" name="amount" v-model="amount" />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col">
            <span>MR.Refence(No)</span>
          </div>
          <div class="vx-col">
            <vs-input size="medium" placeholder="MR-XXXXXXXX" name="mr-refrence" v-model="mr_refrence" />

          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2 float-right"  color="danger" type="gradient" @click.prevent="submitForm">Submit</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
  </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
  mounted() {
    EventBus.$on('open-account-popup', (payload) => {
      this.data = {...payload}
      this.open = true
    })
  },
  data() {
    return {
      data: '',
      open: false,
      amount: '',
      mr_refrence: '',
    }
  },
  methods: {
    submitForm() {
      // console.log(this.amount)
      // console.log(this.mr_refrence)
      this.data.Fee_Paid = this.amount
      this.data.referrance_number = this.mr_refrence
      this.$store.commit('CHANGE_FEES_BOOKS', this.data)
    }
  },
}
</script>