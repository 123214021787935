<template>
  <vs-popup title="Enrollment Approval" :active.sync="open">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b style="color: gray">Approve this lead to enroll?</b>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Candidate</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.person_name }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Idenity</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.identity }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Course</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.course }}</vs-col
      >
    </vs-row>
    <!-- <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Level</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">{{details.Level}}</vs-col>
    </vs-row>-->
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Category</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.category }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Actual Cost</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.actual_cost }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Agreed Cost</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.agreed_cost }}</vs-col
      >
    </vs-row>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Study Material Discount</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.study_material_discount }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Training Discount</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.training_fee_discount }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Exam License Discount</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.exam_license_discount }}</vs-col
      >
    </vs-row>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Reason for Discount</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.reason_for_discount }}</vs-col
      >
    </vs-row>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Mode</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ getMode(details.mode) }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <b style="color: gray">Subjects</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        >{{ details.subjects_chosen }}</vs-col
      >
    </vs-row>
    <vs-row
      style="margin-top: 3%"
      v-if="this.details.admin_approval_status === 'Neutral' && this.details.admin_approval_required === 0"
    >
      <!-- <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <vs-button
          color="success"
          type="gradient"
          @click="approveEnrollment('Approved')"
          >Approve</vs-button
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-button
          color="danger"
          type="gradient"
          @click="approveEnrollment('Rejected')"
          >Reject</vs-button
        >
      </vs-col> -->
    </vs-row>
    <vs-row
      style="margin-top: 3%"
      v-if="this.details.admin_approval_status === 'Neutral' && this.details.admin_approval_required === 1"
    >
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <vs-button
          color="success"
          type="gradient"
          @click="approveEnrollment('Approved')"
          >Approve</vs-button
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-button
          color="danger"
          type="gradient"
          @click="approveEnrollment('Rejected')"
          >Reject</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row
      style="margin-top: 3%"
      v-if="this.details.admin_approval_status === 'Rejected'"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button
          color="success"
          type="gradient"
          @click="approveEnrollment('Approved')"
          >Approve</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("openEmrollmentApprovalPopup", payload => {
      console.log(payload);
      this.details = payload;
      this.open = true;
    });
  },
  data() {
    return {
      details: "",
      open: false
    };
  },
  methods: {
    approveEnrollment(message) {
      let url = `${constants.SERVER_API}approveEnrollment`;
      let obj = {
        enrollment_id: this.details.id,
        admin_approval_status: message,
        person_id: this.details.person_id
      };
      // console.log(obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          if (response.data.status === "success") {
            EventBus.$emit("reloadapprovalsdata");
            this.open = false;
          }
          this.handleNotification(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getMode(mode) {
      switch (mode) {
        case "study_material_training":
          return "Study Material + Training";
        case "study_material_only":
          return "Study Material Only";
        case "only_license":
          return "Only License";
      }
    },
    decision(choice) {
      let obj = {
        identity: this.details.identity,
        engagement_id: this.details.engagement_id,
        visit_approved: ""
      };
      if (choice === "approve") {
        obj.visit_approved = "Yes";
      } else if (choice === "reject") {
        obj.visit_approved = "Rejected";
      }
      // console.log(obj);
      let url = `${constants.SERVER_API}approveVisit`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log(response);
          let color = "success";
          if (response.data.status === "error") {
            color = "danger";
          }
          this.$vs.notify({
            title: response.data.status,
            text: response.data.message,
            color: color
          });
          EventBus.$emit("reload-visits");
          this.open = false;
        })
        .catch(error => {
          // if (error.response) {
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    }
  }
};
</script>

<style></style>
