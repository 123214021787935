<template>
  <vs-popup
    class="holamundo"
    :title="'Add a new purchase for this candidate'"
    :active.sync="open"
    style="z-index:53001"
  >
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <v-select
          label="Courses"
          :options="['CPA', 'CMA', 'Miscellaneous', 'FACS Evaluation Cost', 'IMA Membership Professional', 'IMA Membership Student', 'Shipping Charges For Evaluation', 'CFA']"
          v-model="selectedType"
          style="width: 300px; z-index: 1000"
        ></v-select>
      </vs-col>
    </vs-row>

    <div v-if="selectedType === 'CPA'">
      <vs-divider>BOOKS</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox color="dark" v-model="selectedItems" style="font-size: 15px" vs-value="AUD">
            <b>AUD</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox color="dark" v-model="selectedItems" style="font-size: 15px" vs-value="FAR">
            <b>FAR</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox color="dark" v-model="selectedItems" style="font-size: 15px" vs-value="BEC">
            <b>BEC</b>
          </vs-checkbox>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 1"
          >
            <b>REG 1</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 2"
          >
            <b>REG 2</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"></vs-col>
      </vs-row>
      <vs-divider>VIDEO</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="AUD Video"
          >
            <b>AUD Video</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="FAR Video"
          >
            <b>FAR Video</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="BEC Video"
          >
            <b>BEC Video</b>
          </vs-checkbox>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 1 Video"
          >
            <b>REG 1 Video</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 2 Video"
          >
            <b>REG 2 Video</b>
          </vs-checkbox>
        </vs-col>
      </vs-row>
      <vs-divider>OTB</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="AUD OTB"
          >
            <b>AUD OTB</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="FAR OTB"
          >
            <b>FAR OTB</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="BEC OTB"
          >
            <b>BEC OTB</b>
          </vs-checkbox>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 1 OTB"
          >
            <b>REG 1 OTB</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="REG 2 OTB"
          >
            <b>REG 2 OTB</b>
          </vs-checkbox>
        </vs-col>
      </vs-row>
    </div>

    <div v-if="selectedType === 'CMA'">
      <vs-divider>BOOKS</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 1"
          >
            <b>Part 1</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 2"
          >
            <b>Part 2</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"></vs-col>
      </vs-row>
      <vs-divider>VIDEO</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 1 Video"
          >
            <b>Part 1 Video</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 2 Video"
          >
            <b>Part 2 Video</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"></vs-col>
      </vs-row>
      <vs-divider>OTB</vs-divider>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 1 OTB"
          >
            <b>Part 1 OTB</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-checkbox
            color="dark"
            v-model="selectedItems"
            style="font-size: 15px"
            vs-value="Part 2 OTB"
          >
            <b>Part 2 OTB</b>
          </vs-checkbox>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4"></vs-col>
      </vs-row>
    </div>

    <vs-row class="gap" style="margin-top: 5%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <b style="font-size: 15px">Amount:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input class="inputx" label-placeholder="Fee" v-model="amount" />
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gap" style="margin-top: 5%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <b style="font-size: 15px">Courier Charges:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input
          class="inputx"
          label-placeholder="Charges"
          v-model="courier_charges"
        />
      </vs-col>
    </vs-row>-->
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="font-size: 15px">Comments:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <!-- <vs-textarea v-model="textarea" /> -->
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
        <vs-textarea v-model="comments" />
      </vs-col>
    </vs-row>
    <vs-divider></vs-divider>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="border" @click="submit">Save</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
// import eventbus from "../eventbus.js";
export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      open: false,
      studentInfo: "",
      selectedItems: [],
      estimatedAmount: 0,
      amount: "",
      comments: "",
      courier_charges: "",
      selectedType: "CPA",
    };
  },
  watch: {
    selectedType() {
      this.refresh();
    },
    selectedItems(value) {
      this.calculateAmount(value);
    },
  },
  mounted() {
    EventBus.$on("escalate-popup", (payload) => {
      console.log("studentInfo",this.studentInfo);
      this.studentInfo = payload;
      this.refresh();
      this.open = true;
    });
  },
  methods: {
    getName() {
      if (this.selectedType !== "Miscellaneous") {
        return `${this.selectedType} New Purchase`;
      } else {
        return "Miscellaneous";
      }
    },
    submit() {
      console.log("this.selectedType escalate", this.selectedType);
      console.log("this.purchase_name escalate", this.getName());
      if (this.validates()) {
        let obj = {
          purchase_course: this.selectedType,
          purchase_name: this.getName(),
          mwb_id: this.studentInfo.mwb.id,
          actual_amount: this.estimatedAmount,
          amount: this.amount,
          comments: this.comments,
          admin_approval_required: this.getAdminApprovalStatus(),
          purchases: this.getSelectedPurchases(),
          enrollment_id: null,
          // enrollment_id: this.studentInfo.enrollment.id
        };

        if (obj.purchase_name === "Miscellaneous") {
          obj.actual_amount = obj.amount;
        }
        if (obj.purchase_name === "FACS Evaluation Cost") {
          obj.actual_amount = obj.amount;
        }
        if (obj.purchase_name === "IMA Membership Professional") {
          obj.actual_amount = obj.amount;
        }
        if (obj.purchase_name === "IMA Membership Student") {
          obj.actual_amount = obj.amount;
        }
        console.log(obj);
        // return;
        axios
          .post(`${constants.SERVER_API}addNewPurchase`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.handleNotification(response);
            if (response.data.status === "success") {
              this.open = false;
              // EventBus.$emit("reload-bigpopup-data");
              if (this.$route.path === "/queue") {
                // EventBus.$emit("update-queue-addition");
                EventBus.$emit("update-activelead-data");
              } else {
                EventBus.$emit("reload-bigpopup-data");
              }
              // eventbus.$emit('update_purchases', [this.studentInfo, response.data.purchase, response.data.deliverables])
              this.$store.commit("UPDATE_NEW_PURCHASES", [
                this.studentInfo,
                response.data.purchase,
                response.data.deliverables,
              ]);
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    calculateAmount(purchaseList) {
      this.estimatedAmount = 0;
      let shippingAmount = 0;
      purchaseList.forEach((purchase) => {
        if (this.checkIf(purchase, "video")) {
          this.estimatedAmount = this.estimatedAmount + 5900;
        } else if (this.checkIf(purchase, "otb")) {
          this.estimatedAmount = this.estimatedAmount + 2500;
        } else if (this.checkIf(purchase, "book")) {
          shippingAmount = 500;
          this.estimatedAmount = this.estimatedAmount + 500;
        } else {
          this.estimatedAmount = 0;
        }
      });
      this.estimatedAmount = this.estimatedAmount + shippingAmount;
      this.amount = this.estimatedAmount;
    },
    checkIf(purchase, type) {
      let bool = false;
      switch (type) {
        case "video":
          if (purchase.includes("Video")) {
            bool = true;
          }
          break;
        case "otb":
          if (purchase.includes("OTB")) {
            bool = true;
          }
          break;
        case "book":
          if (!purchase.includes("OTB") || !purchase.includes("VIDEO")) {
            bool = true;
          }
          break;
      }
      return bool;
    },
    getSelectedPurchases() {
      let selectedArr = [];
      this.selectedItems.forEach((item) => {
        let obj = {
          name: item,
          type: "",
          course: "",
          subject: "",
        };

        if (
          item === "AUD" ||
          item === "FAR" ||
          item === "BEC" ||
          item === "REG 1" ||
          item === "REG 2"
        ) {
          obj.course = "CPA";
          obj.type = "book";
          obj.subject = item;
        }

        if (item === "Part 1" || item === "Part 2") {
          obj.course = "CMA";
          obj.type = "book";
          obj.subject = item;
        }

        if (
          item === "AUD Video" ||
          item === "FAR Video" ||
          item === "BEC Video" ||
          item === "REG 1 Video" ||
          item === "REG 2 Video"
        ) {
          obj.course = "CPA";
          obj.type = "video_access";
          obj.subject = item.replace("Video", "");
        }

        if (item === "Part 1 Video" || item === "Part 2 Video") {
          obj.course = "CMA";
          obj.type = "video_access";
          obj.subject = item.replace("Video", "");
        }

        if (
          item === "AUD OTB" ||
          item === "FAR OTB" ||
          item === "BEC OTB" ||
          item === "REG 1 OTB" ||
          item === "REG 2 OTB"
        ) {
          obj.course = "CPA";
          obj.type = "otb";
          obj.subject = item.replace("OTB", "");
        }

        if (item === "Part 1 OTB" || item === "Part 2 OTB") {
          obj.course = "CMA";
          obj.type = "otb";
          obj.subject = item.replace("OTB", "");
        }

        selectedArr.push(obj);
      });
      return selectedArr;
    },
    getAdminApprovalStatus() {
      if (this.amount < this.estimatedAmount) {
        return 1;
      } else {
        return 0;
      }
    },
    validates() {
      let bool = true;

      if (
        this.selectedItems.length === 0 &&
        this.selectedType !== "Miscellaneous" &&
        this.selectedType !== "FACS Evaluation Cost" &&
        this.selectedType !== "IMA Membership Professional" &&
        this.selectedType !== "IMA Membership Student"&&
        this.selectedType !== "Shipping Charges For Evaluation"&&
        this.selectedType !== "CFA"
      ) {
        this.$vs.notify({
          title: "Select atleast one purchase",
          text: "Atleast one purchase is required to use this feature",
          color: "danger",
        });
        bool = false;
      }

      if (this.amount === "") {
        this.$vs.notify({
          title: "Enter a valid amount",
          text: "A valid amount is required to use this feature",
          color: "danger",
        });
        bool = false;
      }

      return bool;
    },
    refresh() {
      this.selectedItems = [];
      this.amount = "";
      this.estimatedAmount = 0;
      this.comments = "";
      this.courier_charges = "";
    },
  },
};
</script>

<style>
.gap {
  margin-bottom: 2%;
}
</style>
