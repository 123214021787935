<template>
  <vs-popup
    class="holamundo"
    title="Edit IIML Enrollment Fees"
    :active.sync="open"
  >
    <div>
      <vs-row class="gaps" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px;"
        >Old Agreed Fees</vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          {{ this.old_agreed_fees }}
        </vs-col>
      </vs-row>
      <vs-row class="gaps" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px;"
        >New Agreed Fees</vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-input class="w-full" type="number" placeholder="Amount" v-model="new_agreed_fees" />
        </vs-col>
      </vs-row>
      <vs-row class="gaps">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            class="float-center"
            color="primary"
            type="gradient"
            @click="ChangeAgreedFees()"
          >Submit</vs-button>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
export default {
  data() {
    return {
      open: false,
      new_agreed_fees: "",
      enrollment_id: "",
      old_agreed_fees: ""
    };
  },
  mounted() {
    
    EventBus.$on("open-EditIimlEnrollmentPopup", (payload) => {
      console.log("Edit popup",payload);
      this.AgreedData = payload;
      this.enrollment_id = payload.id;
      this.old_agreed_fees = payload.agreed_cost;
      this.new_agreed_fees = "";
      this.open = true;
    });
  },
  methods: {
    ChangeAgreedFees() {
      let obj = {
        enrollment_id: this.enrollment_id,
        old_agreed_cost: this.old_agreed_fees,
        new_agreed_cost: this.new_agreed_fees
      }
      console.log("data", obj)
    }
  },
  watch: { 
  },
};
</script>

<style></style>