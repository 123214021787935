<template>
  <vs-popup class="holamundo" title="Add a referrance" :active.sync="open">
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Name:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" placeholder="Name" v-model="name" />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Department:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" placeholder="Department" v-model="department" />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Status:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <v-select
          class="w-full"
          v-model="status"
          :options="statusoptions"
          placeholder="status"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">City:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Email:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" placeholder="Email" v-model="email" />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">Phone:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" placeholder="Phone" v-model="phone" />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <p style="font-size: 20px">SPOC:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <v-select
          class="w-full"
          v-model="select1"
          :options="options1"
          placeholder="SPOC"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="gradient" @click="addDetails" icon="add">ADD</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      name: "",
      department: "",
      phone: "",
      status: "",
      email: "",
      open: false,
      data: [],
      select1: "",
      options1: [
        { text: "Millie", value: 0 },
        { text: "Jonathan", value: 1 },
        { text: "Chris Corner", value: 2 },
        { text: "Ellie", value: 3 },
        { text: "Joel", value: 4 }
      ],
      statusoptions: [
        { text: "L1", value: 0 },
        { text: "L2", value: 1 },
        { text: "L3", value: 2 },
        { text: "L4", value: 3 },
        { text: "L5", value: 4 },
        { text: "L6", value: 5 },
        { text: "L7", value: 6 },
        { text: "M1", value: 7 },
        { text: "M2", value: 8 },
        { text: "M3", value: 9 },
        { text: "M4", value: 10 },
        { text: "M5", value: 11 },
        { text: "M6", value: 12 },
        { text: "M7", value: 13 }
      ]
    };
  },
  mounted() {
    EventBus.$on("open-badge-details", payload => {
      (this.name = ""),
        (this.phone = ""),
        (this.status = ""),
        (this.email = ""),
        (this.department = ""),
        (this.open = true);
      this.data = payload;
    });
  },
  methods: {
    addDetails() {
      if (
        this.name === "" ||
        this.phone === "" ||
        this.status === "" ||
        this.email === "" ||
        this.department === ""
      ) {
        this.$vs.notify({
          title: "Fill All the details",
          text: "Please fill all the details",
          color: "danger"
        });
      } else {
        let obj = {
          name: this.name,
          department: this.department,
          status: this.status,
          email: this.email,
          phone: this.phone
        };
        this.open = false;
        this.$store.commit("ADD_" + this.data[0], [obj, this.data[1]]);
      }
    }
  }
};
</script>

<style>
</style>
