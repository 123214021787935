<template>
  <div>
    <div class="boxed">
      <!-- <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="dark" type="border" @click="sendPaymentlink()"
            >Send Payment link</vs-button
          >
        </vs-col>
      </vs-row> -->
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Enrollment Date:</b
          >
          <vs-chip color="dark">
            <b>{{ getDate() }}</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <b style="color: gray; font-size: 12px; margin-right: 3%">Batch:</b>
          <vs-chip color="dark">
            <b>{{ getBatch() }}</b>
          </vs-chip>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="2"
          v-if="getrole('cm4')"
        >
          <vs-button
            radius
            color="warning"
            type="border"
            icon="chat"
            @click="editBatch()"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <div >
      <!-- <poa-section :studentDetails="studentDetails"></poa-section> -->
      <new-poa-section :student_details="studentDetails" :course="poa_section_course"></new-poa-section>
    </div>
    <!-- BRIDGE COURSE LEVEL -->
    <div class="boxed">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="10">
          <p class="heading">BRIDGE COURSE LEVEL - <b> {{this.level}}</b></p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="add"
            @click="openBridgeCoursePopup(level)"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-popup
        class="holamundo"
        title="Add Bridge Course Level"
        :active.sync="add_bridge_course_level"
        id="add_bridge_course_level"
      >
        <vs-row>
          <vs-col vs-w="6">
            <vs-select
              label="Bridge Course Level"
              v-model="bridge_course_level"
              class="padding"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in bridge_level_options"
              />
            </vs-select>
          </vs-col>
          <vs-col
            vs-w="6"
            v-if="
              bridge_course_level === 'B3' ||
              bridge_course_level === 'B4' ||
              bridge_course_level === 'B5'
            "
          >
            <vs-input
              class="padding"
              label="College Enrollment ID"
              placeholder="Enter Enrollment Id"
              v-model="college_enrollment_id"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="12" class="bridge_update_button"
            ><vs-button
              class="update_bridge_levels_button"
              color="dark"
              type="gradient"
              @click="updateBridgeCourseLevel"
              >UPDATE</vs-button
            ></vs-col
          >
        </vs-row>
      </vs-popup>
    </div>

    <!-- STUDENT REFERENCE -->
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="10"
        >
          <p class="heading">STUDENT REFERENCE</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="dark"
            type="gradient"
            icon="add"
            @click="add_student_referrance"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Can-ID</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Candidate Name</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Reference Date</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 12px">Current Status</b>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Bonus</b>
        </vs-col>-->
      </vs-row>
      <div v-for="users in studentDetails.student_reference" :key="users.index">
        <!-- <vs-row
          v-for="fetch in studentDetails.engagements"
          :key="fetch.key"
          style="height: fit-content"
        >-->
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_identity }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ users.referred_person_name }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">
              {{ convertTimestampToDate(users.reference_given_date) }}
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">{{ users.bonus_status }}</b>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
              <vs-checkbox v-model="student_bonus" :vs-value="users.student_name"></vs-checkbox>
          </vs-col>-->
        </vs-row>
      </div>
    </div>
    <div
      class="boxed"
      v-if="
        checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('ac2')
      "
    >
      <accadsexam :mwbs_id="studentDetails.mwb.id"></accadsexam>
    </div>
    <div
      class="boxed"
      v-if="
        checkIfRoleis('ac1') || checkIfRoleis('cm4') || checkIfRoleis('ac2')
      "
    >
      <accadslicense :mwb_id="studentDetails.mwb.id"></accadslicense>
    </div>
    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="12"
        >
          <div class="boxed" style="width: 50%; margin-right: 2%">
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Billing Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="billingaddress"
                  :disabled="billingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.billingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editBillAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.billingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    saveAdd('mailing_address', getEnrolId(), editBillAdd())
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
          <div class="boxed" style="width: 50%">
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Shipping Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="shippingaddress"
                  :disabled="shippingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.shippingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editShipAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.shippingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    shiipingSaveAdd(
                      'shipping_address',
                      getEnrolId(),
                      editBillAdd()
                    )
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
// import POA_Section from "../../EWB/CPA/Candidates/Details_Popup/POA_Section.vue";
import NewPoaSection from "../../EWB/CPA/Candidates/Details_Popup/NewPoaSection.vue";
import AccadsExam from "../InfoSection/AccadsEvaluationExam.vue";
import AccadsLicense from "../InfoSection/AccadsEvaluationLicense.vue";
import constants from "../../../../constants.json";
import axios from "axios";
import EventBus from "../../eventbus";


export default {
  components: {
    // "poa-section": POA_Section,
    "new-poa-section": NewPoaSection,
    accadsexam: AccadsExam,
    accadslicense: AccadsLicense,
  },
  props: {
    studentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      new_batch_code: "",
      billingaddress: "",
      shippingaddress: "",
      disInput: true,
      enrollId: null,
      addressType: "",
      billingAddressDisable: true,
      shippingAddressDisable: true,
      add_bridge_course_level: false,
      course:"",
      bridge_course_level: "",
      current_level:"",
      level:"",
      college_enrollment_id: "",
      bridge_level_options: [
        { text: "B0 - Not required	 ", value: "B0" },
        { text: "B1 - Not yet enrolled for any course	", value: "B1" },
        { text: "B2 - Enrolled for some other course	", value: "B2" },
        { text: "B3 - Enrolled for JU - M.Com", value: "B3" },
        { text: "B4 - Enrolled for JU - MBA ", value: "B4" },
        { text: "B5 - Enrolled for AU - PGDPA ", value: "B5" },
      ],
      poa_section_course: "CPA"
    };
  },
  mounted() {
    // console.log("CAP", this.studentDetails);
    this.getBillAddress();
    this.getShipAddress();
    console.log("student details",this.studentDetails);
    this.current_level = this.studentDetails.bridge_course.b_level;
     
  },
  watch:{
    current_level:function(val){
        if(val === 'B0'){
          this.level = 'B0 - Not required';
        } 
        else if(val=== 'B1'){
          this.level = 'B1 - Not yet enrolled for any course';
        }
        else if(val === 'B2'){
          this.level = 'B2 - Enrolled for some other course';
        }
       else if(val === 'B3'){
          this.level = 'B3 - Enrolled for JU - M.Com';
        }
        else if(val === 'B4'){
          this.level = 'B4 - Enrolled for JU - MBA';
        }
        else if(val === 'B5'){
          this.level = 'B5 -Enrolled for AU - PGDPA';
        }
    }
  },
  methods: {
    openBridgeCoursePopup(level) {
      document.getElementById(
        "add_bridge_course_level"
      ).children[1].style.width = "32%";
      this.add_bridge_course_level = true;
      this.bridge_course_level = level;
    },
    updateBridgeCourseLevel() {
     
       let url = `${constants.SERVER_API}addBridgeCourse`;
        let params = {
        course:"CPA",
        previous_level:this.studentDetails.mwb.level,
        person_id:this.studentDetails.mwb.person_id,
        bridge_course_level: this.bridge_course_level,
        college_enrollment_id:this.college_enrollment_id
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
         
          if(response.data.status === 'success'){
             this.handleNotification(response);
              this.current_level = response.data.data.b_level;
              this.add_bridge_course_level = false;
          }else if(response.data.status === 'error'){
             this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editBillAdd() {
      this.billingAddressDisable = false;
    },
    getEnrolId() {
      this.studentDetails.mwb.enrollments.forEach((en) => {
        if (en.course === "CPA") {
          this.enrollId = en.id;
        }
      });
      return this.enrollId;
    },
    editShipAdd() {
      this.shippingAddressDisable = false;
    },
    getBillAddress() {
      this.billingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((bill) => {
        console.log("mail", bill);
        if (bill.course === "CPA") {
          this.billingaddress = bill.address;
          console.log("mail", this.billingaddress);
        }
      });
      return this.billingaddress;
    },
    getShipAddress() {
      this.shippingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((ship) => {
        if (ship.course === "CPA") {
          this.shippingaddress = ship.shipping_address;
        }
      });
      return this.shippingaddress;
    },
    shiipingSaveAdd(address_type, id) {
      this.billingAddressDisable = true;
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.shippingaddress,
      };
      console.log("data", params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.shippingAddressDisable = true;
          this.handleNotification(response);
          //chaitanya completed design
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    saveAdd(address_type, id) {
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.billingaddress,
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.handleNotification(response);
          this.billingAddressDisable = true;
          // this.shippingAddressDisable = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editBatch() {
      EventBus.$emit("open-edit-batch-code", this.getEnrollment());
      console.log("editing");
      // this.open = true;
    },
    sendPaymentlink() {
      let url = `${constants.SERVER_API}sendPaymentLinkEmail`;
      let params = {
        purchase_id: this.getPurchaseID(),
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getPurchaseID() {
      let purchase_id = null;
      this.studentDetails.purchases.forEach((purchase) => {
        if (purchase.name === "CPA") {
          purchase_id = purchase.id;
        }
      });
      return purchase_id;
    },
    add_student_referrance() {
      // console.log("emitted");
      EventBus.$emit("open-add-student-referrance", this.studentDetails);
      // this.EmitEvent('open-add-student-referrance', this.studentDetails.id)
    },
    getDate() {
      let date = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CPA") {
          date = enrollment.enrollment_date;
        }
      });
      return this.unixTimestampConvert(date);
    },
    getBatch() {
      let batch = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CPA") {
          batch = enrollment.batch;
        }
      });
      return batch;
    },
    getEnrollment() {
      let data = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.course === "CPA") {
          data = enrollment;
        }
      });
      return data;
    },
  },
};
</script>

<style>
.update_bridge_levels_button {
  margin-top: 4px;
}
.bridge_update_button {
  direction: rtl;
}
.padding {
  padding: 5px;
}
</style>
