<template>
    <vs-popup title="USP Re-Enrollment Approval" :active.sync="open">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b style="color: gray">Approve this lead to enroll?</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b style="color: gray">Candidate</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >{{ details.person_name }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b style="color: gray">Idenity</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >{{ details.identity }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b style="color: gray">Course</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >{{ details.course }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b style="color: gray">Agreed Cost</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >{{ details.agreed_cost }}</vs-col
        >
      </vs-row>
      <vs-row
        style="margin-top: 3%"
        v-if="this.details.approval_status === 'pending'"
      >
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button
            color="success"
            type="gradient"
            @click="approveEnrollment('Approved')"
            >Approve</vs-button
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button
            color="danger"
            type="gradient"
            @click="approveEnrollment('Rejected')"
            >Reject</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row
        style="margin-top: 3%"
        v-if="this.details.approval_status === 'rejected'"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="success"
            type="gradient"
            @click="approveEnrollment('Approved')"
            >Approve</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </template>
  
  <script>
  import axios from "axios";
  import constants from "../../../constants.json";
  import EventBus from "../eventbus.js";
  export default {
    mounted() {
      EventBus.$on("open-enrollment-approvala-popup", payload => {
        console.log(payload);
        this.details = payload;
        this.open = true;
      });
    },
    data() {
      return {
        details: "",
        open: false
      };
    },
    methods: {
      approveEnrollment(message) {
        let url = `${constants.SERVER_API}approveOrRejectUSPReEnrollments`
        let obj = {
          mwb_id: this.details.mwb_id,
          approval_status: message,
        };
        axios
          .post(url, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            if (response.data.status === "success") {
              EventBus.$emit("reloadapprovalsdata");
              this.open = false;
            }
            this.handleNotification(response);
          })
          .catch(error => {
            this.handleError(error);
          });
      },
    }
  };
  </script>
  
  <style></style>
  