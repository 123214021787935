<!-- =========================================================================================
    File Name: TheNavbar.vue
    Description: Navbar component
    Component Name: TheNavbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar">
        </feather-icon>

        <template v-if="breakpoint != 'md'">
          <!-- STARRED PAGES - FIRST 10 -->
          <ul class="vx-navbar__starred-pages">
            <draggable v-model="starredPagesLimited" :group="{ name: 'pinList' }" class="flex cursor-move">
              <li class="starred-page" v-for="page in starredPagesLimited" :key="page.url">
                <vx-tooltip :text="page.label" position="bottom" delay=".3s">
                  <feather-icon svgClasses="h-6 w-6" class="p-2 cursor-pointer" :icon="page.labelIcon"
                    @click="$router.push(page.url)"></feather-icon>
                </vx-tooltip>
              </li>
            </draggable>
          </ul>

          <!-- STARRED PAGES MORE -->
          <div class="vx-navbar__starred-pages--more-dropdown" v-if="starredPagesMore.length">
            <vs-dropdown vs-custom-content vs-trigger-click>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="cursor-pointer p-2"></feather-icon>
              <vs-dropdown-menu>
                <ul class="vx-navbar__starred-pages-more--list">
                  <draggable v-model="starredPagesMore" :group="{ name: 'pinList' }" class="cursor-move">
                    <li class="starred-page--more flex items-center cursor-pointer" v-for="page in starredPagesMore"
                      :key="page.url" @click="$router.push(page.url)">
                      <feather-icon svgClasses="h-5 w-5" class="ml-2 mr-1" :icon="page.labelIcon"></feather-icon>
                      <span class="px-2 pt-2 pb-1">{{ page.label }}</span>
                    </li>
                  </draggable>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- <div class="bookmark-container">
                    <feather-icon icon="StarIcon" :svgClasses="['stoke-current text-warning', {'text-white': navbarColor != '#fff'}]" class="cursor-pointer p-2" @click.stop="showBookmarkPagesDropdown = !showBookmarkPagesDropdown" />
                    <div v-click-outside="outside" class="absolute bookmark-list w-1/3 xl:w-1/4 mt-4" v-if="showBookmarkPagesDropdown">
                    <vx-auto-suggest :autoFocus="true" :data="navbarSearchAndPinList" @selected="selected" @actionClicked="actionClicked" inputClassses="w-full" show-action show-pinned background-overlay></vx-auto-suggest>
                    </div>
          </div>-->
        </template>

        <!-- <vs-navbar-item index="0">
          <a class="nav_span" href="#">
            Score
            <span class="badge_span" style="background:green">100%</span>
          </a>
        </vs-navbar-item>-->
        <vs-navbar-item index="0" v-if="getrole('cm1') || getrole('exed2') || getrole('ac1')">
          <a class="nav_span" href="#">
            Effort
            <span class="badge_span" style="background:orange; width: max-content">{{ effort }}</span>
          </a>
        </vs-navbar-item>
        <vs-navbar-item>
          <div v-if="team_notifications.length != 0" align="center" justify="center">
            <vs-row v-for="(item, index) in team_notifications" :key="index">
              <vs-col align="left">{{ item.message }}</vs-col>
            </vs-row>
          </div>
        </vs-navbar-item>
        <!-- <vs-navbar-item index="0" style="margin-left: 3%">
          <a class="nav_span" href="#">
            MWB
            <span class="badge_span" style="background:red">333</span>
          </a>
        </vs-navbar-item>
        <vs-navbar-item index="0">
          <a class="nav_span" href="#">
            EWB
            <span class="badge_span" style="background:red">333</span>
          </a>
        </vs-navbar-item>-->
        <vs-spacer></vs-spacer>
        <vs-chip v-if="MWBtitle" :color="'dark'">
          <b>{{ MWBtitleName }}</b>
        </vs-chip>
        <vs-spacer></vs-spacer>
        <!-- <vs-dropdown vs-custom-content vs-trigger-click>
          <a class="a-icon" href.prevent @click="showTreeBox">
            Levels
            <i class="material-icons">expand_more</i>
          </a>
          <vs-dropdown-menu class="customDropDown">
            <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
          </vs-dropdown-menu>
        </vs-dropdown>-->
        <vs-button color="dark" type="filled" style="margin-right: 1%" @click="goToLeadSearch()" icon="search">Lead</vs-button>
        <vs-button color="dark" type="filled" style="margin-right: 1%" @click="openConfirmForwork('paused')">Take a
          break</vs-button>
        <vs-button color="dark" type="filled" style="margin-right: 1%" @click="openConfirmForwork('stopped')">Leave for
          the day</vs-button>
        <treeselect v-if="loadfilter" :multiple="true" :options="treeDataLevels" v-model="dashboardFilter"
          style="width: 400px" />

        <!-- <vs-dropdown v-if="loadfilter" vs-custom-content vs-trigger-click>
          <feather-icon icon="FilterIcon" class="cursor-pointer ml-4"></feather-icon>
          <vs-dropdown-menu class="customDropDown">
            <treeselect :multiple="true" :options="treeDataLevels" v-model="dashboardFilter" />
          </vs-dropdown-menu>
        </vs-dropdown>-->

        <!-- plus icon -->

        <!-- <vs-dropdown
          v-if="unloadplus === false"
          vs-custom-content
          vs-trigger-click
        > -->
        <vs-dropdown vs-custom-content vs-trigger-click>
          <feather-icon icon="InfoIcon" class="cursor-pointer ml-4"></feather-icon>
          <vs-dropdown-menu>
            <vs-row style="width:250px">
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CPA.pdf">
                  CPA
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CMA.pdf">
                  CMA
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CFA.pdf">
                  CFA
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/FRM.pdf">
                  FRM
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/USP.pdf">
                  USP
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CPA.pdf">
                  CPA
                </a>
              </vs-col>
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-BA.pdf"
              >IIML-BA</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-FA.pdf"
              >IIML-FA</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-HR.pdf"
              >IIML-HR</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-PM.pdf"
              >IIML-PM</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-SF.pdf"
              >IIML-SF</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITR-BF.pdf"
              >IITR-BF</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4"  class="grid">
                <a  target="_blank"  style="color:#ffffff"
              href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITR-DB.pdf"
              >IITR-DB</a
            >
              </vs-col> -->
              <!-- <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMI-BA.pdf">IIMI-BA</a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-FT.pdf">IIML-FT</a>
              </vs-col> -->
            </vs-row>

            <!-- <vs-row style="width:250px">
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/AICPA.pdf">
                  AICPA
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/XLRI-HR.pdf">
                  XLRI-HR
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/XLRI-SH.pdf">
                  XLRI-SH
                </a>
              </vs-col>
              <vs-col vs-w="4" class="grid">
                <a target="_blank" style="color:#ffffff"
                  href="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMI-AA.pdf">
                  IIMI-AA
                </a>
              </vs-col>
            </vs-row> -->
          </vs-dropdown-menu>
        </vs-dropdown>
        <vs-dropdown v-if="
          getrole('cm1') ||
          getrole('exed2') ||
          getrole('ac1') ||
          getrole('cm4')
        " vs-custom-content vs-trigger-click>
          <feather-icon icon="PlusCircleIcon" class="cursor-pointer ml-4"></feather-icon>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="addLead">B2C</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <!-- calender icon -->
        <!-- <feather-icon icon="CalendarIcon" class="cursor-pointer ml-4"></feather-icon> -->
        <!-- <feather-icon icon="BellIcon"  class="cursor-pointer navbar-fuzzy-search ml-4"></feather-icon> -->
        <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer ml-4"
            :badge="unreadNotifications.length"
          ></feather-icon>
          <vs-dropdown-menu class="notification-dropdown dropdown-custom">
            <div class="notification-top text-center p-5 bg-primary text-white">
              <h3 class="text-white">{{ unreadNotifications.length }} New</h3>
              <p class="opacity-75">App Notifications</p>
            </div>

            <VuePerfectScrollbar
              ref="mainSidebarPs"
              class="scroll-area--nofications-dropdown p-0 mb-10"
              :settings="settings"
            >
              <ul class="bordered-items">
                <li
                  v-for="ntf in unreadNotifications"
                  :key="ntf.index"
                  class="flex justify-between px-4 py-4 notification cursor-pointer"
                >
                  <div class="flex items-start">
                    <feather-icon
                      :icon="ntf.icon"
                      :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"
                    ></feather-icon>
                    <div class="mx-2">
                      <span
                        class="font-medium block notification-title"
                        :class="[`text-${ntf.category}`]"
                      >{{ ntf.title }}</span>
                      <small>{{ ntf.msg }}</small>
                    </div>
                  </div>
                  <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.time) }}</small>
                </li>
              </ul>
            </VuePerfectScrollbar>
            <div
              class="checkout-footer fixed pin-b rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid border-grey-light cursor-pointer"
            >
              <span>View All Notifications</span>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>-->
        <!-- <feather-icon icon="MailIcon" class="cursor-pointer ml-4"></feather-icon> -->

        <!-- USER META -->
        <div class="the-navbar__user-meta username flex items-center">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ searchIn.name }} {{ versionId }}</p>
            <!-- <small>Available</small> -->
          </div>
          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="con-img ml-3"></div>
            <vs-dropdown-menu>
              <ul style="min-width: 9rem">
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/pages/profile')">
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Profile</span>
                </li>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/email')">
                  <feather-icon icon="MailIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Inbox</span>
                </li>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/todo')">
                  <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Tasks</span>
                </li>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/chat')">
                  <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Chat</span>
                </li>
                <vs-divider class="m-1"></vs-divider>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/pages/login')">
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Logout</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div id="mat" style="display:none">{{ access_token }}</div>
      </vs-navbar>
    </div>
    <nav-bar-popup :nav_bar_popup="nav_bar_popup" :users_data="users_data" @close-nav-ne-popup="nav_bar_popup = false">
    </nav-bar-popup>
  </div>
</template>

<script>
import EventBus from "../../components/eventbus.js";
// import firebase from "firebase/app";
// import "firebase/auth";
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import axios from "axios";
import constants from "../../../constants.json";
// import { VTree, VSelectTree } from "vue-tree-halower";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import "vue-select/dist/vue-select.css";
import router from "@/router";
import NavBarPopup from "../../components/NavBarPopup/NavBarPopup.vue";

export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  mounted() {

    this.access_token = localStorage.getItem('userAccessToken');
    this.team = localStorage.getItem("team");
    // this.getTeamNotification(this.team);
    this.loggedInSpoc();
    if(localStorage.getItem('team') == 'CM'){
        setInterval(() => {
            this.checkActiveTabForWebSocketConnection();
        }, 3000);
        setInterval(() => {
            this.getNetEnquiry();
        }, 900000);
    }
    EventBus.$on("load-filter", () => {
      this.loadfilter = true;
    });
    EventBus.$on("close-ne-popup", () => {
      this.nav_bar_popup = false;
      this.getNetEnquiry();
    });
    EventBus.$on("unload-filter", () => {
      this.loadfilter = false;
    });
    EventBus.$on("update-navbar-effort", (payload, version) => {
      console.log("payload", version)
      this.versionId = version;
      this.effort = `${payload.call_utilization} - ${payload.no_of_calls
        } Calls`;
    });
    EventBus.$on("unloadplus", (payload) => {

      this.unloadplus = payload;
    });
    EventBus.$on("MWBtitleshow", (payload) => {

      this.MWBtitle = true;
      this.MWBtitleName = payload;
    });
    EventBus.$on("MWBtitlehide", () => {
      this.MWBtitle = false;
      this.MWBtitleName = "";
    });
  },
  data() {
    return {
      users_data: [],
      nav_bar_popup: false,
      web_socket: null,
      access_token: "",
      team_data: [],
      team: "",
      versionId: "",
      MWBtitle: false,
      MWBtitleName: "",
      dashboardFilter: [],
      loadfilter: false,
      unloadplus: false,
      team_notifications: [],
      treeDataLevels: [
        {
          id: "MWB",
          label: "MWB",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2",
                },
                {
                  id: "L3",
                  label: "L3",
                },
                {
                  id: "L4",
                  label: "L4",
                },
                {
                  id: "L6",
                  label: "L6",
                },
                {
                  id: "L5",
                  label: "L5",
                },
                {
                  id: "L3+",
                  label: "L3+",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                {
                  id: "L4-",
                  label: "L4-",
                },
                {
                  id: "L1",
                  label: "L1",
                },
              ],
            },
            {
              id: "N/A",
              label: "N/A",
            },
          ],
        },
        {
          id: "MHP",
          label: "MHP",
          children: [
            {
              id: "Net Enquiry",
              label: "Net Enquiry",
            },
            {
              id: "University",
              label: "University",
            },
            {
              id: "Corporate",
              label: "Corporate",
            },
            {
              id: "Loan",
              label: "Loan",
            },
            {
              id: "Eligibility",
              label: "Eligibility",
            },
            {
              id: "Visitors",
              label: "Visitors",
            },
            {
              id: "Reference",
              label: "Reference",
            },
          ],
        },
        {
          id: "CPA-EWB",
          label: "CPA-EWB",
          children: [],
        },
        {
          id: "CMA-EWB",
          label: "CMA-EWB",
          children: [],
        },
      ],
      effort: "",
      searchIn: {
        id: "",
        name: "",
      },
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: "Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)",
          category: "primary",
        },
        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: "Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)",
          category: "success",
        },
        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: "Thu Jan 31 2019 07:45:23 GMT+0000 (GMT)",
          category: "danger",
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: "Fri Feb 01 2019 07:45:23 GMT+0000 (GMT)",
          category: "primary",
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: "Fri Feb 02 2019 07:45:23 GMT+0000 (GMT)",
          category: "warning",
        },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
    };
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
    },
    dashboardFilter() {
      this.sortAndUpdateDashboard();
    },
  },
  computed: {
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    breakpoint() {
      return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == "default") return "navbar-default";
      else if (this.sidebarWidth == "reduced") return "navbar-reduced";
      else if (this.sidebarWidth) return "navbar-full";
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      },
    },

    // PROFILE
    user_displayName() {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    activeUserImg() {
      return (
        JSON.parse(localStorage.getItem("userInfo")).photoURL ||
        this.$store.state.AppActiveUser.img
      );
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    // getTeamNotification(team) {
    //   console.log("Team getTeamNotification", team);
    //   let url = `${constants.SERVER_API}getNotificationsWithTeam?team=${team}`;
    //   axios
    //     .get(url, {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.userAccessToken}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("getNotificationsWithTeam", response);
    //       if (response.status == 200) {
    //         this.team_notifications = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    openConfirmForwork(status) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: `Are you sure you want to ${this.getText(status)}?`,
        accept: () => {
          this.updateUserWorkingStatus(status);
        },
      });
    },
    goToLeadSearch(){
      this.$router.push("/LeadSearch");
    },
    getText(status) {
      let name = "";
      if (status == "paused") {
        name = "pause";
      } else if (status == "stopped") {
        name = "stop";
      }
      return name;
    },
    updateUserWorkingStatus(status) {
      let url = `${constants.SERVER_API}updateWorkingStatus`;
      let params = {
        status: status,
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          // this.handleNotification(response);
          if (response.data.status === "success") {
            if (status === "stopped") {
              router.push("/logout");
            } else if (status === "paused") {
              router.push("/resumeWork");
            }
          } else {
            this.handleNotification(response);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sortAndUpdateDashboard() {
      let finalLevels = [];
      this.dashboardFilter.forEach((filter) => {
        if (filter === "MWB") {
          this.treeDataLevels.forEach((treedata) => {
            if (treedata.id === "MWB") {
              treedata.children.forEach((child) => {
                if (child.id === "N/A") {
                  finalLevels.push(child.id);
                } else {
                  child.children.forEach((child2) => {
                    finalLevels.push(child2.id);
                  });
                }
              });
            }
          });
        } else if (filter === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((p1level) => {
            finalLevels.push(p1level.id);
          });
        } else if (filter === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((p1level) => {
            finalLevels.push(p1level.id);
          });
        } else if (filter === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((p1level) => {
            finalLevels.push(p1level.id);
          });
        } else if (filter === "MHP") {
          this.treeDataLevels[1].children.forEach((mhp) => {
            finalLevels.push(mhp.id);
          });
        } else {
          finalLevels.push(filter);
        }
      });
      // console.log(finalLevels);
      let returnobj = this.sortByMHP(finalLevels);
      EventBus.$emit("filter-dashboard", returnobj);
    },
    sortByMHP(finalLevels) {
      let indexarr = [];
      let mhpArr = [];
      finalLevels.forEach((level, index) => {
        this.treeDataLevels[1].children.forEach((mhp) => {
          if (level === mhp.id) {
            indexarr.push(index);
            mhpArr.push(level);
          }
        });
      });
      // console.log(indexarr);
      // console.log(finalLevels);
      for (let i = indexarr.length - 1; i >= 0; i--) {
        finalLevels.splice(indexarr[i], 1);
      }
      let request = {
        "Net Enquiry": "",
        University: "",
        Corporate: "",
        Loan: "",
        Eligibility: "",
        Visitors: "",
        Reference: "",
        levels: finalLevels.join(),
      };
      mhpArr.forEach((mhp) => {
        for (var key in request) {
          if (request.hasOwnProperty(key)) {
            if (key === mhp) {
              request[key] = "Yes";
            }
          }
        }
      });
      let sendObj = {
        net_enquiry: request["Net Enquiry"],
        university: request.University,
        corporate: request.Corporate,
        loan: request.Loan,
        eligibility: request.Eligibility,
        visitors: request.Visitors,
        reference: request.Reference,
        levels: request.levels,
      };
      console.log(sendObj);
      return sendObj;
    },
    loggedInSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          console.log("navbar", response)
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          localStorage.setItem("spoc_id", this.searchIn.id);
          localStorage.setItem("spoc_name", this.searchIn.name);
          localStorage.setItem("email_id", response.data.spoc_email);
          localStorage.setItem("google_meet_link", response.data.google_meet_link);
          localStorage.setItem("spoc_city",response.data.spoc_city);
        })
        .catch((error) => {
          // if (error.response) {
          //   // console.log('error', error.response.status)
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    addLead() {
      EventBus.$emit("open-add-lead-popup");
    },
    addLeadCR() {
      EventBus.$emit("open-AddLeadCRPopup");
    },
    addLeadIR() {
      EventBus.$emit("open-AddLeadIRPopup");
    },
    // // visitPopup() {
    // //   EventBus.$emit('open-visit-popup')
    // // },
    // enrollmentPopup() {
    //     EventBus.$emit('open-enrollment-popup')
    // },
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction,
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : "just now");
      }

      return "Just Now";
    },
    logout() {
      // if user is logged in via auth0
      if (this.$auth.profile) this.$auth.logOut();

      // if user is looged in via firebase
      // const firebaseCurrentUser = firebase.auth().currentUser;

      // if (firebaseCurrentUser) {
      //   firebase
      //     .auth()
      //     .signOut()
      //     .then(() => {
      //       this.$router.push("/pages/login");
      //       localStorage.removeItem("userInfo");
      //     });
      // }
      // Change role on logout. Same value as initialRole of acj.js
      //this.$acl.change("admin");
      localStorage.removeItem("userRole");
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },

    // CART DROPDOWN
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    checkActiveTabForWebSocketConnection() {
      // this.getPopupDetails(83)
      if (document.hidden == true && this.web_socket != null) {
        this.disconnectWebSocket();
      }
      if (document.hidden == false && this.web_socket == null) {
        this.connectWebSocket()
      }
    },
    disconnectWebSocket() {
      console.log("API SOCKET DISCONNECTED")
      this.web_socket.close();
      this.web_socket.destroy();
      this.web_socket = null;
    },
    connectWebSocket() {
      //try catch
      let access_token = localStorage.getItem('userAccessToken');

      // let url = "wss://nt0crq7iw0.execute-api.ap-south-1.amazonaws.com/development/?auth_token=" + encodeURI(access_token);

      let url = "wss://nt0crq7iw0.execute-api.ap-south-1.amazonaws.com/production/?auth_token=" + encodeURI(access_token);

      this.web_socket = new WebSocket(url);

      this.web_socket.onopen = function (e) {
        console.log("API SOCKET CONNECTED", e)
      };

      this.web_socket.onmessage = (evt) =>{
        console.log("API SOCKET DATA", evt)
        console.log("API SOCKET MESSAGE", evt.data)
        localStorage.setItem("ne_popup_permission_to_call",true);
        this.getPopupDetails(evt.data)
      };
    },
    getPopupDetails(ne_id) {
      console.log("CALLING API", ne_id)

      let url = `${constants.MILES_GOOGLE_INVITE}getNetEnquiryDetails?ne_id=${ne_id}`

      axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("response ne", response);
          if(response.data){
            this.nav_bar_popup = true;
            this.users_data = response.data
          }
          console.log("New NE", response);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });

    },
    getNetEnquiry(){
      let url = `${constants.MILES_GOOGLE_INVITE}getNetEnquiry`

      axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("response ne", response);
          if(response.data){
            this.nav_bar_popup = true;
            this.users_data = response.data
          }
          console.log("NE", response);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });

    }
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  components: {
    "nav-bar-popup": NavBarPopup,
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
    // VTree,
    // VSelectTree,
    Treeselect,
  },
};
</script>
<style>
.chip {
  background: white;
  font-weight: bold;
  border: 2px solid;
}

.chip_value {
  font-weight: bold;
  font-size: 13px;
}

.username {
  padding-left: 20px;
}

/* .mwb_ewb{
    border: 2px solid;
    font-weight: bold;
} */
.badge_span {
  position: absolute;
  /* right: 5px; */
  /* background: #5b3cc4; */
  color: #fff;
  font-size: 10px;
  font-weight: lighter !important;
  border-radius: 5px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 15px;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
  text-transform: capitalize;
  left: 48px;
}

.nav_span {
  font-weight: bold;
  font-size: 13px;
  margin-right: 20px;
}

.grid {
  text-align: center;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid #ffffff;
}

/* .vs-dropdown--custom.vs-dropdown--menu{
width: 265px;
} */
</style>
