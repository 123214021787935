<template>
  <div>
    <div class="boxed">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%">Course:</b>
          <vs-chip color="dark">
            <b>IIML-BA</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%">Level:</b>
          <vs-chip color="dark">
            <b>Application Received</b>
          </vs-chip>
        </vs-col>
      </vs-row>
    </div>

    <div class="boxed">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <p class="heading">APPLICATION TRACKER</p>
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Application Recvd Date:</b
          >
          <vs-chip color="dark">
            <b>August 2019</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Exam status:</b
          >
          <vs-chip color="dark">
            <b>N/A</b>
          </vs-chip>
        </vs-col>
      </vs-row>

      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Application Recvd Date:</b
          >
          <vs-chip color="dark">
            <b>August 2019</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Exam status:</b
          >
          <vs-chip color="dark">
            <b>N/A</b>
          </vs-chip>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
// import EventBus from "../../eventbus";
export default {
  components: {},
  props: {},
  mounted() {},
  methods: {}
};
</script>

<style></style>
