<template>
  <div>
    <vs-popup class="assignspocpopupmultiplewidth"  :id="'assignlead'" :title="'Assign Leads'" :active.sync="open">
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">Search</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
          <vs-chip color="warning" v-for="(chip, index) in search" :key="index">{{chip}}</vs-chip>
        </vs-col>
      </vs-row>-->
      <div>
        <br/>
        <vs-row>
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>City </b></vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="8"
          >
            <v-select
              :options="cities"
              v-model="cityselected"
              placeholder="Select City"
              style="width:24%; position: fixed; z-index:1100"
            />
          </vs-col>
        </vs-row>
        <br/>
        <vs-row style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>User Tag </b></vs-col>
          <vs-col  vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <v-select
              style="width:24%; position: fixed; z-index:1000"
              v-model="selected_user_tag"
              :options="selected_city_usertags"
              placeholder="Select User Tag"
            ></v-select>
          </vs-col>
        </vs-row>
        <br/>
        <vs-row>
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <b>Spoc </b>
          </vs-col>
          <vs-col
            style="padding-left: 2%"
            vs-justify="center"
            vs-align="center"
            vs-w="8"
          >
            <!-- <v-select
              label="full_name"
              :multiple="true"
              :options="cityspoc"
              v-model="value1"
              style="width:24%; position: fixed; z-index:900"
            /> -->
            
              <treeselect openDirection='bottom' value-format="object" :multiple="true"  style="width:24%;position:fixed; z-index:999"   :options="selected_usertag_spocs_tree_data" placeholder="Select Spoc" v-model="value1" />
               <!-- <treeselect-value :value="value1" /> -->
            
          </vs-col>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-w="2"
          >
          <vs-button size='small'  color="dark" type="filled" @click="assignAPI">Assign</vs-button> 
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      selected_usertag_spocs_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      selected_user_tag: "",
      open: false,
      decidebool: false,
      cityspoc: [],
      title: "Example",
      spoc_names: [],
      totalleadcount: "",
      value1: [],
      cities: [],
      cityselected: "",
      selected: "",
      deselected: "",
      allselect: "",
      sendobj: "",
      search: "",
      rawBdeSR: [],
      mergespocs: [],
      selected_city_usertags: [],
    };
  },
  components: {
    vSelect,
    "v-select": vSelect,
    Treeselect
  },
  mounted() {
    EventBus.$on(
      "assign-lead-multiple",
      (payload, payload2, selectall, sendobj, totalleadcount) => {
        // console.log(payload);
        // console.log(payload2);
        // console.log(selectall);
        // console.log("multiple", sendobj);
        // console.log(totalleadcount);
        this.cities = [];
        this.getSpoc();
        this.getSRSpocs();
        this.decidebool = false;
        this.value1 = [];
        this.totalleadcount = totalleadcount;
        this.selected = payload;
        this.deselected = payload2;
        let lengthstring = 0;
        this.allselect = selectall;
        this.sendobj = sendobj;
        this.filtersearch();
        if (selectall === true) {
          this.decidebool = true;
          lengthstring = totalleadcount - payload2.length;
          let title = "";
          title = `Assigning ${lengthstring} leads`;
          this.title = title;
        } else {
          lengthstring = payload.length;
          this.title = `Assigning ${lengthstring} lead`;
        }
        if (lengthstring > 1) {
          this.decidebool = true;
        }
        this.open = true;
      }
    );
    console.log();
  },
  watch: {
    cityselected(value) {
      this.filterspoc(value);
    },
    selected_user_tag(value){
      this.filterUserTag(value);
    },
    decidebool(val){
      console.log("decidebool",val);
    }
  },
  methods: {
    filterspoc(city) {
      console.log("1",city);
      this.selected_user_tag = "";
      this.value1 = [];
      this.selected_city_usertags = [];
      this.mergespocs = Array.from(new Set(this.mergespocs));
      this.mergespocs.forEach((spoc) => {
        if(localStorage.getItem("team") == "GM"){
          if (spoc.city === city && spoc.reporting_to == localStorage.getItem("spoc_id")) {
            if (spoc.user_tag !== undefined) {
              this.selected_city_usertags.push(spoc);
            }
          }
        }
        if(localStorage.getItem("team") != "GM"){
          if (spoc.city === city) {
            if (spoc.user_tag !== undefined) {
              this.selected_city_usertags.push(spoc);
            }
          }
        }
      });
      console.log("this.selected_city_usertags2",this.selected_city_usertags);
      this.selected_city_usertags = [...new Set(this.selected_city_usertags.map((item) => item.user_tag)),
      ];
      console.log("assign spoc multiple selected_city_usertags",this.selected_city_usertags);
    },
    filtersearch() {
      let course = [];
      if (this.sendobj.courses !== "") {
        course = this.sendobj.courses.split(",");
      }
      let levels = [];
      if (this.sendobj.levels !== "") {
        levels = this.sendobj.levels.split(",");
      }
      let bde = [];
      if (this.sendobj.spoc_ids !== "") {
        bde = this.sendobj.spoc_ids.split(",");
      }
      // console.log(course);
      // console.log(levels);
      // console.log(bde);
      this.mergespocs = Array.from(new Set(this.mergespocs));
      console.log(this.mergespocs)
      let bdenames = [];
      bde.forEach((id) => {
        // console.log(this.spoc_names);
        this.mergespocs.forEach((spoc) => {
          if (parseInt(id) === spoc.id) {
            bdenames.push(spoc.full_name);
          }
        });
      });
      // console.log(bdenames);
      this.search = course.concat(levels, bdenames);
      // console.log(this.search);
    },
    filterUserTag(user_tag) {
      this.value1 = [];
      this.cityspoc = [];
      this.mergespocs = Array.from(new Set(this.mergespocs));
      let gm_spocs = [];
      gm_spocs = this.mergespocs;
      this.mergespocs = [];
      for(let i = 0; i < gm_spocs.length; i++) {
        const element = gm_spocs[i];
        if(localStorage.getItem("team") != "GM"){
          this.mergespocs.push(element);
        }
        if(localStorage.getItem("team") == "GM"){
          if(localStorage.getItem("spoc_id") == element.reporting_to){ 
            this.mergespocs.push(element);
          }
        }
      }
      this.mergespocs.forEach((spoc) => {
        // console.log("data", spoc)
        if (spoc.user_tag === user_tag && spoc.city == this.cityselected) {
          this.cityspoc.push(spoc);
        }
      });
      this.selected_usertag_spocs_tree_data = [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ];
      this.cityspoc.forEach((spoc) => {
        this.selected_usertag_spocs_tree_data.forEach((bde) => {
          var obj = {
            id: spoc.id,
            label: spoc.full_name,
          };
          bde.children.push(obj);
        });
      });
    },
    assignAPI() {
      console.log(this.value1, "Checking spoc count");
      // this.$vs.loading({
      //   type: "default",
      // }); 
      if (this.value1.length === 0) {
        this.$vs.notify({
          title: "Select a Spoc",
          text: "Select a Spoc to assign the selected leads",
          color: "danger",
        });
      } else {
        let selected = [];
        let deselected = [];
        if (this.allselect === true) {
          selected = [];
          deselected = [];
          this.deselected.forEach((each) => {
            deselected.push(each.id);
          });
        } else {
          selected = [];
          this.selected.forEach((each) => {
            selected.push(each.id);
          });
          deselected = [];
        }
        let allselect = "";
        if (this.allselect == true) {
          allselect = "yes";
        } else {
          allselect = "no";
        }
        this.sendobj.selected_lead_ids = selected.join();
        if (this.sendobj.selected_lead_ids === "") {
          this.sendobj.selected_lead_ids = null;
        }
        this.sendobj.excluded_lead_ids = deselected.join();
        if (this.sendobj.excluded_lead_ids === "") {
          this.sendobj.excluded_lead_ids = null;
        }
        // this.sendobj.spoc_name = this.value1.first_name;
        // let ids = [];
        // if (this.decidebool === false) {
        //   this.sendobj.spoc_id = this.value1.id;
        // } else {
        //     if(this.value1[0].id != 'All'){
        //       this.value1.forEach((value) => {
        //         ids.push(value.id);
        //       });
        //     }
        //     if(this.value1[0].id == 'All'){
        //       this.value1[0].children.forEach((value) => {
        //         ids.push(value.id);
        //       });
        //     }
        //   this.sendobj.spoc_id = ids.join();
        // }

        let names = [];
        if (this.decidebool === false) {
          this.sendobj.spoc_name = this.value1.label;
          
        } else {
          if(this.value1[0].id != 'All'){
            this.value1.forEach((value) => {
              // console.log("dast", value);
              names.push(value.label);
            });
          }
          if(this.value1[0].id == 'All'){
            this.value1[0].children.forEach((value) => {
              // console.log("dast", value);
              names.push(value.label);
            });
          }
          this.sendobj.spoc_name = names.join();
          // console.log("dast", this.sendobj.spoc_name);
        }

        let spocsNew = [];
        if (this.decidebool === false) {
          this.sendobj.spocs = [
            { spoc_name: this.value1.label, spoc_id: this.value1.id },
          ];
        } else {
          if(this.value1[0].id != 'All'){
            this.value1.forEach((value) => {
              let obj = {
                spoc_name: value.label,
                spoc_id: value.id,
              };
              spocsNew.push(obj);
            });
          }
          if(this.value1[0].id == 'All'){
            this.value1[0].children.forEach((value) => {
              let obj = {
                spoc_name: value.label,
                spoc_id: value.id,
              };
              spocsNew.push(obj);
            });
          }
          this.sendobj.spocs = spocsNew;
        }

        this.sendobj.select_all_leads_state = allselect;
        let url = `${constants.SERVER_API}assignLeads`;
        // if (this.sendobj.my_focus) {
        //   this.sendobj.my_focus = this.sendobj.my_focus.toLowerCase();
        // }
        this.sendobj.count = 0;
        // if (this.sendobj.my_focus === "untapped without level") {
        //   this.sendobj.my_focus = "untapped_without_level";
        // } else if (this.sendobj.my_focus === "untapped with level") {
        //   this.sendobj.my_focus = "untapped_with_level";
        // }
        console.log("Assign multiple spocs obj",this.sendobj, url);
        if(this.sendobj.spoc_ids === ""){
          this.$vs.notify({
          title: "Please select from SPOC",
          text: "",
          color: "danger",
        });
        } else {
          this.$vs.loading({
          type: "default",
          }); 
        axios
          .post(url, this.sendobj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              EventBus.$emit("reload-assignments");
              this.open = false;
            }
            this.$vs.loading.close();
            // console.log(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
        }
      }
    },
    getSpoc() {
      this.mergespocs = [];
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          
          this.spoc_names.forEach((spoc) => {
            this.mergespocs.push(spoc);
            
          });

          this.getSRSpocs();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.rawBdeSR = response.data.spocs;
          // console.log("this.mergespocs", this.rawBdeSR)
          this.rawBdeSR.forEach((spoc) => {
            this.mergespocs.push(spoc);
          });
          this.getMergeSpocs();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getMergeSpocs() {
      let filtered_spocs = [];
      this.mergespocs.forEach((spoc) => {
        if(localStorage.getItem("team") != "GM"){
          filtered_spocs.push(spoc);
        }
        if(localStorage.getItem("team") == "GM"){
          if(localStorage.getItem("spoc_id") == spoc.reporting_to){ 
            filtered_spocs.push(spoc);
          }
        }
        
      });
      var unique = filtered_spocs
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = Array.from(new Set(this.cities));
      this.cities = this.sortArrayAlphabetically(this.cities);
      let ids = this.mergespocs.map((o) => o.id);
      
      this.mergespocs = this.mergespocs.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      // console.log("this.mergespocs", this.mergespocs);
    },
  },
};
</script>
<style>
.assignspocpopupmultiplewidth .vs-popup {
  width: 40% !important;
}
</style>