<template>
  <div>
    <vs-popup
      class="holamundo"
      title="Work Status"
      :button-close-hidden="true"
      :active.sync="open"
    >
      <vs-row vs-w="12" style="margin-top: 0%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h3>
            <b>{{ text }}</b>
          </h3>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 5%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <vs-button
            color="dark"
            type="filled"
            @click="updateUserWorkingStatus()"
            >{{ buttonText }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
// import moment from "moment";
import constants from "../../../constants.json";
export default {
  mounted() {
    eventbus.$on("notify-user-to-start", (payload) => {
      this.status = payload;
      if (payload === "stopped") {
        this.text = "Please start the work for the day";
        this.buttonText = "Start";
      } else if (payload === "paused") {
        this.text = "Please resume the work";
        this.buttonText = "Resume";
      }
      this.open = true;
    });
  },
  data() {
    return {
      status: "",
      text: "",
      buttonText: "",
      open: false,
    };
  },
  methods: {
    updateUserWorkingStatus() {
      let url = `${constants.SERVER_API}updateWorkingStatus`;
      let params = {
        status: this.getStatus(),
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            location.reload();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getStatus() {
      let returnstatus = "";
      if (this.status === "paused") {
        returnstatus = "resumed";
      } else if (this.status === "stopped") {
        returnstatus = "started ";
      }
      return returnstatus;
    },
  },
};
</script>

<style></style>
