<template>
  <div>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
        style="height: 10%"
      >
        <vs-navbar
          text-color="black" color="#ffffff" active-text-color="#FFFFFF"
          class="myNavbar"
          v-model="ActiveNavBarTab"
        > <vs-navbar-item index="Placement Drive">
            <a>Placement Drive</a>
          </vs-navbar-item>
          <vs-navbar-item index="Accelerator Sessions">
            <a>Accelerator Sessions</a>
          </vs-navbar-item>
          <vs-navbar-item index="Net Enquiries">
            <a>Net Enquiries</a>
          </vs-navbar-item>
          <vs-navbar-item index="Transfer History">
            <a>Transfer History</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-show="ActiveNavBarTab === 'Placement Drive'" style="width: 100%">
      <PlacementDriveTab v-if="ActiveNavBarTab === 'Placement Drive'"
        :studentDetails="studentDetails"></PlacementDriveTab>
    </div>
    <div v-show="ActiveNavBarTab === 'Accelerator Sessions'" style="width: 100%">
      <AcceleratorSessionTab v-if="ActiveNavBarTab === 'Accelerator Sessions'"
        :studentDetails="studentDetails"></AcceleratorSessionTab>
    </div>
    <div v-show="ActiveNavBarTab === 'Net Enquiries'" style="width: 100%">
      <contact-points
        v-if="ActiveNavBarTab === 'Net Enquiries'"
        :studentDetails="studentDetails"
      ></contact-points>
    </div>
    <div v-show="ActiveNavBarTab === 'Transfer History'" style="width: 100%">
      <transfer-history
        v-if="ActiveNavBarTab === 'Transfer History'"
        :studentDetails="studentDetails"
      ></transfer-history>
    </div>
    <!-- </vs-card> -->
  </div>
</template>
<script>
import AcceleratorSessionTab from "./AcceleratorSessionTab.vue";
import ContactPoints from "./ContactPoints.vue";
import PlacementDriveTab from "./PlacementDriveTab.vue";
import TransferHistory from "./TransferHistory.vue";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  components: {
    "contact-points": ContactPoints,
    "transfer-history": TransferHistory,
    AcceleratorSessionTab,
    PlacementDriveTab
},
  data() {
    return {
      ActiveNavBarTab: "Net Enquiries",
    };
  },
  mounted() {},
  methods: {},
};
</script>