<template>
  <vs-card style="height: 840px; overflow-y: auto">
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
        style="height:100px"
      >
        <vs-navbar
          text-color="black"
          active-text-color="black"
          class="myNavbar"
          v-model="indexActive"
        >
          <!-- <div slot="title">
            <vs-navbar-title>{{ navTitleName }}</vs-navbar-title>
          </div>-->

          <vs-navbar-item index="0">
            <a href="#" @click="navSettings('All')">ALL</a>
          </vs-navbar-item>
          <vs-navbar-item index="1">
            <a href="#" @click="navSettings('Call')">CALL</a>
          </vs-navbar-item>
          <vs-navbar-item index="2">
            <a href="#" @click="navSettings('Visit')">VISIT</a>
          </vs-navbar-item>
          <vs-navbar-item index="3">
            <a href="#" @click="navSettings('Email')">EMAIL</a>
          </vs-navbar-item>
          <vs-navbar-item index="4">
            <a href="#" @click="navSettings('Whatsapp')">WHATSAPP</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
      <div v-show="allpage === true" style="width: 100%">
        <vs-row style="margin-left: 2%">
          <div v-for="(badge, index) in arr" :key="index">
            <vs-avatar
              v-show="badge.value !== 0"
              size="32px"
              color="danger"
              badge-color="black"
              :badge="badge.value"
              :text="badge.name"
            />
          </div>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-card>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-icon
                    style="margin-right:1%"
                    :icon="fetchIcon(payload.mwb.type)"
                  ></vs-icon>
                  <b style="margin-right: 2%">{{ payload.mwb.created_at }}</b>
                  - {{ payload.mwb.engagement_details }}
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-w="12"
          >
            <b>- {{ payload.mwb.spoc_name }}</b>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col>
            <vs-card>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                >
                  <span
                    class="contact_buttons_span"
                    @click="navSettings('Call')"
                  >
                    <vs-icon
                      class="contact_buttons"
                      icon="local_phone"
                      color="white"
                      bg="#d76c2f"
                      round
                    ></vs-icon>
                  </span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                >
                  <span
                    class="contact_buttons_span"
                    @click="navSettings('Visit')"
                  >
                    <vs-icon
                      class="contact_buttons"
                      icon="place"
                      color="white"
                      bg="#d76c2f"
                      round
                    ></vs-icon>
                  </span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                >
                  <span
                    class="contact_buttons_span"
                    @click="navSettings('Email')"
                  >
                    <vs-icon
                      class="contact_buttons"
                      icon="mail"
                      color="white"
                      bg="#d76c2f"
                      round
                    ></vs-icon>
                  </span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="3"
                >
                  <span
                    class="contact_buttons_span"
                    @click="navSettings('Whatsapp')"
                  >
                    <vs-icon
                      class="contact_buttons"
                      icon="message"
                      color="white"
                      bg="#d76c2f"
                      round
                    ></vs-icon>
                  </span>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
      <div v-show="emailpage === true" style="width: 100%">
        <vs-col
          style="margin-bottom: 3%"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="12"
        >
          <vs-button
            color="danger"
            type="gradient"
            icon="email"
            @click="emailform"
            >Send Email</vs-button
          >
        </vs-col>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Engagement:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <vs-textarea label="ENGAGEMENT" v-model="textAre" />
          </vs-col>
        </vs-row>
        <vs-divider class="my-6"></vs-divider>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <h6 style="color: gray">Level:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <div>
              <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.text"
                label="text"
              ></v-select>-->
              <vs-select class="w-full" label="Levels" v-model="levels">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in labelOptions"
                />
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <h6 style="color: gray">Course:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="CPA"
              >CPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="CMA"
              >CMA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="DA"
              >DA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="RPA"
              >RPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="FOF(O)"
              >FOF(O)</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="AICPA"
              >AICPA</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Next follow up date:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <datepicker
              placeholder="Select Date"
              :format="customFormatter"
              v-model="dates"
            ></datepicker>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-w="12"
          >
            <vs-button
              color="danger"
              type="gradient"
              icon="done_outline"
              @click="API_post_add_engagement"
              >ADD</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
      <div v-show="visitpage === true" style="width: 100%">
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Engagement:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <vs-textarea label="ENGAGEMENT" v-model="textAre" />
          </vs-col>
        </vs-row>
        <vs-divider class="my-6"></vs-divider>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <h6 style="color: gray">Enrollment:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <!-- <h6 style="color: gray">Enrollment:</h6> -->
            <vs-checkbox color="dark" v-model="enrollment"></vs-checkbox>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          ></vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <h6 style="color: gray">Level:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <!-- <div v-if="connected === true">
              <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptionsvisit"
                :reduce="text => text.text"
                label="text"
              ></v-select>
            </div>
            <div v-else>
              <v-select
                disabled
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptionsvisit"
                label="text"
              ></v-select>
            </div>-->
            <div v-if="connected === true">
              <vs-select class="w-full" label="Levels" v-model="levels">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in labelOptionsvisit"
                />
              </vs-select>
            </div>
            <div v-else>
              <vs-select
                disabled
                class="w-full"
                label="Levels"
                v-model="levels"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in labelOptionsvisit"
                />
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <h6 style="color: gray">Course:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox
              :disabled="disable"
              color="dark"
              v-model="course"
              vs-value="CPA"
              >CPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox
              :disabled="disable"
              color="dark"
              v-model="course"
              vs-value="CMA"
              >CMA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox
              :disabled="disable"
              color="dark"
              v-model="course"
              vs-value="DA"
              >DA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox
              :disabled="disable"
              color="dark"
              v-model="course"
              vs-value="RPA"
              >RPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox
              :disabled="disable"
              color="dark"
              v-model="course"
              vs-value="FOF(O)"
              >FOF(O)</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Next follow up date:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <datepicker
              placeholder="Select Date"
              :format="customFormatter"
              v-model="dates"
            ></datepicker>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-w="12"
          >
            <vs-button
              color="danger"
              type="gradient"
              icon="done_outline"
              @click="API_post_add_engagement"
              >ADD</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
      <div v-show="callpage === true" style="width: 100%">
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <vs-card>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                >
                  <p>
                    <strong>PREVIOUS ENGAGEMENT -</strong>
                    {{ payload.mwb.engagement_details }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
            style="font-size: 15px"
          >
            <h6 style="color: gray">Connection Status:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
            style="font-size: 15px"
          >
            <!-- <v-select
              placeholder="Status"
              class="w-full"
              v-model="select1"
              :options="options1"
              label="text"
            ></v-select>-->
            <vs-select class="w-full" label="status" v-model="select1">
              <vs-select-item
                :key="index"
                :value="item.text"
                :text="item.text"
                v-for="(item, index) in options1"
              />
            </vs-select>
          </vs-col>
        </vs-row>
        <div v-if="show === true">
          <vs-divider class="my-6"></vs-divider>
          <div v-if="connected === true">
            <vs-row style="margin-bottom: 3%">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="4"
                style="font-size: 15px"
              >
                <h6 style="color: gray">Response:</h6>
              </vs-col>
              <vs-col vs-justify="center" vs-align="flex-start" vs-w="4">
                <vs-radio
                  color="dark"
                  v-model="responseSelection"
                  vs-value="sameResponse"
                >
                  <p style="font-size:12px">SAME RESPONSE</p>
                </vs-radio>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-w="4"
              >
                <vs-radio
                  color="dark"
                  id="new"
                  v-model="responseSelection"
                  vs-value="new"
                >
                  <p style="font-size:12px">NEW</p>
                </vs-radio>
              </vs-col>
            </vs-row>
          </div>
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="4"
            >
              <h6 style="color: gray">Engagement:</h6>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="8"
            >
              <vs-textarea label="ENGAGEMENT" v-model="textAre" />
            </vs-col>
          </vs-row>
          <vs-divider class="my-6"></vs-divider>
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <h6 style="color: gray">Level:</h6>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="8"
            >
              <div v-if="connected === true">
                <!-- <v-select
                  placeholder="Levels"
                  class="w-full"
                  v-model="levels"
                  :options="labelOptions"
                  label="text"
                ></v-select>-->
                <vs-select class="w-full" label="Levels" v-model="levels">
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in labelOptions"
                  />
                </vs-select>
              </div>
              <div v-else>
                <!-- <v-select
                  disabled
                  placeholder="Levels"
                  class="w-full"
                  v-model="levels"
                  :options="labelOptions"
                  label="text"
                ></v-select>-->
                <vs-select
                  disabled
                  class="w-full"
                  label="Levels"
                  v-model="levels"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in labelOptions"
                  />
                </vs-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <h6 style="color: gray">Course:</h6>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="CPA"
                >CPA</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="CMA"
                >CMA</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="DA"
                >DA</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="RPA"
                >RPA</vs-checkbox
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="2"
            >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="FOF(O)"
                >FOF(O)</vs-checkbox
              >
            </vs-col>
          </vs-row>
          <vs-row style="margin-bottom: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="4"
            >
              <h6 style="color: gray">Next follow up date:</h6>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="8"
            >
              <datepicker
                :disabledDates="disabledDates"
                placeholder="Select Date"
                :format="customFormatter"
                v-model="dates"
              ></datepicker>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-start"
              vs-w="12"
            >
              <vs-button
                color="danger"
                type="gradient"
                icon="done_outline"
                @click="API_post_add_engagement"
                >ADD</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </div>
      <!-- <div v-show="callpage === true" style="padding-bottom: 300px; margin-bottom:2%"></div> -->
      <div v-show="whatsapp_page === true" style="width: 100%">
        <vs-col
          style="margin-bottom: 3%"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="12"
        >
          <vs-button color="danger" type="gradient" icon="email"
            >Send Whatsapp</vs-button
          >
        </vs-col>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Engagement:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <vs-textarea label="ENGAGEMENT" v-model="textAre" />
          </vs-col>
        </vs-row>
        <vs-divider class="my-6"></vs-divider>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <h6 style="color: gray">Level:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <div>
              <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.value"
                label="text"
              ></v-select>-->
              <vs-select class="w-full" label="Levels" v-model="levels">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in labelOptions"
                />
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <h6 style="color: gray">Course:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="CPA"
              >CPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="CMA"
              >CMA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="DA"
              >DA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="RPA"
              >RPA</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="2"
          >
            <vs-checkbox color="dark" v-model="course" vs-value="FOF(O)"
              >FOF(O)</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="4"
          >
            <h6 style="color: gray">Next follow up date:</h6>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="8"
          >
            <datepicker
              placeholder="Select Date"
              :format="customFormatter"
              v-model="dates"
            ></datepicker>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-w="12"
          >
            <vs-button
              color="danger"
              type="gradient"
              icon="done_outline"
              @click="API_post_add_engagement"
              >ADD</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-row>
  </vs-card>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import EventBus from "../eventbus.js";
import moment from "moment";
import vSelect from "vue-select";
export default {
  props: {
    payload: {
      required: true,
      // type: Object
    },
    selected: {
      required: true,
    },
  },
  mounted() {
    console.log("engage", this.payload);
    if (
      this.payload.mwb.level === "M4-" ||
      this.payload.mwb.level === "M4" ||
      this.payload.mwb.level === "M5" ||
      this.payload.mwb.level === "M6" ||
      this.payload.mwb.level === "L4-" ||
      this.payload.mwb.level === "L4" ||
      this.payload.mwb.level === "L5" ||
      this.payload.mwb.level === "L6"
    ) {
      this.labelOptions = this.labelOptionsvisit;
    }

    if (this.payload.mwb.level.indexOf("M7") !== -1) {
      this.connected = false;
    }
    this.course = [];
    this.course = this.payload.mwb.courses.split(",");
    this.levels = this.payload.mwb.level;
    this.navSettings(this.selected);
    // EventBus.$on("mwb-cards", payload => {
    //   this.course = [];
    //   this.select1 = "";
    //   this.course.push(payload.courses);
    //   this.levels = payload.level;
    //   // this.navSettings("All");
    //   // console.log(this.payload)
    // });
    console.log("this is called");
    this.generateEngagementInfoBadges();
    EventBus.$on("netEnquiryDetails", (payload) => {
      this.netEnquiryDetails = payload;
      console.log("updated");
    });
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {
    levels: function(value) {
      console.log(value);
      let limit = 180;
      let suggested = 180;
      const nfds = {
        M6: {
          limit: 14,
          suggested: 7,
        },
        M5: {
          limit: 14,
          suggested: 7,
        },
        M4: {
          limit: 180,
          suggested: 90,
        },
        "M4-": {
          limit: 180,
          suggested: 120,
        },
        "M3++": {
          limit: 14,
          suggested: 1,
        },
        "M3+": {
          limit: 14,
          suggested: 7,
        },
        M3: {
          limit: 30,
          suggested: 14,
        },
        M2: {
          limit: 180,
          suggested: 90,
        },
        M1: {
          limit: 180,
          suggested: 120,
        },
        M7: {
          limit: null,
          suggested: null,
        },
        L6: {
          limit: 30,
          suggested: 14,
        },
        L5: {
          limit: 60,
          suggested: 15,
        },
        L4: {
          limit: 120,
          suggested: 30,
        },
        "L4-": {
          limit: 180,
          suggested: 120,
        },
        "L3+": {
          limit: 90,
          suggested: 30,
        },
        L3: {
          limit: 90,
          suggested: 30,
        },
        L2: {
          limit: 120,
          suggested: 30,
        },
        L1: {
          limit: 180,
          suggested: 120,
        },
      };
      limit = nfds[value].limit;
      suggested = nfds[value].suggested;
      this.disabledDates.from = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + limit
      );
      this.disabledDates.to = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.dates = new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + suggested
        )
      );
      if (value === "M7") {
        this.dates = "";
      }
    },
    select1: function(value) {
      console.log(value);
      let valuetext = value;
      if (valuetext === "Connected / Discussed") {
        this.disable = false;
        if (this.payload.mwb.level.indexOf("M7") !== -1) {
          this.connected = false;
        } else {
          this.connected = true;
        }
        this.connection_status = true;
        this.responseSelection = "new";
        this.textAre = "";
        this.show = true;
      } else if (valuetext === "") {
        this.show = false;
      } else if (valuetext === "Connected / Never call back") {
        this.connection_status = true;
        this.textAre = valuetext;
        this.disable = true;
        this.connected = false;
        this.show = true;
      } else {
        this.disable = true;
        this.connected = false;
        this.generateEngagementText(valuetext);
        this.show = true;
      }
    },
    responseSelection: function(val) {
      switch (val) {
        case "sameResponse":
          this.textAre = this.payload.mwb.engagement_details;
          break;
        case "noResponse":
          this.textAre = "Not Reachable";
          break;
        case "new":
          this.textAre = "";
          break;
      }
    },
  },
  data() {
    return {
      engagement: [],
      netEnquiryDetails: "",
      arr: [
        {
          name: "Connected, Busy",
          value: 0,
          form: "CB",
        },
        {
          name: "Connected, Never call back",
          value: 0,
          form: "CN",
        },
        {
          name: "Connected, Wrong Number",
          value: 0,
          form: "CW",
        },
        {
          name: "Busy",
          value: 0,
          form: "B",
        },
        {
          name: "Not Lifting",
          value: 0,
          form: "NL",
        },
        {
          name: "Not Reachable",
          value: 0,
          form: "NR",
        },
        {
          name: "Disconnected",
          value: 0,
          form: "D",
        },
        {
          name: "Invalid Number",
          value: 0,
          form: "IN",
        },
        {
          name: "Switched Off",
          value: 0,
          form: "SO",
        },
      ],
      indexActive: 0,
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 5),
        from: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 26),
      },
      connected: false,
      show: false,
      disable: true,
      options1: [
        { text: "", value: 1 },
        { text: "Connected / Discussed", value: 2 },
        { text: "Connected / Busy", value: 3 },
        { text: "Connected / Never call back", value: 4 },
        { text: "Connected / Wrong Number", value: 4 },
        { text: "Busy", value: 5 },
        { text: "Not Lifting", value: 6 },
        { text: "Not reachable", value: 7 },
        { text: "Disconnected", value: 8 },
        { text: "Invalid Number", value: 9 },
        { text: "Switched Off", value: 10 },
      ],
      dates: "",
      course: [],
      levels: "",
      textAre: "",
      phone_number_toupdate: "",
      responseSelection: "",
      select1: "",
      latestEngagement: "",
      navTitleName: "",
      allpage: true,
      visitpage: false,
      callpage: false,
      whatsapp_page: false,
      emailpage: false,
      lastengagement: "",
      details: "",
      labelOptionsvisit: [
        { text: "", value: "" },
        { text: "M9- Alumnus", value: "M9" },
        { text: "M8 - Cleared some exams", value: "M8" },
        { text: "M7 - Enrolled", value: "M7" },
        { text: "M6 - Visited & Ready to Enroll", value: "M6" },
        { text: "M5 - Visited & Positive", value: "M5" },
        { text: "M4 - Visited but Postponed", value: "M4" },
        { text: "M4- - Visited but not interested", value: "M4-" },
      ],
      labelOptions: [
        { text: "", value: "" },
        // { text: "M7 - Enrolled", value: "M7" },
        // { text: "M6 - Visited & Ready to Enroll", value: "M6" },
        // { text: "M5 - Visited & Positive", value: "M5" },
        // { text: "M4 - Visited but Postponed", value: "M4" },
        // { text: "M4- - Visited but not interested", value: "M4-" },
        { text: "M3++ - Ready to enroll - Not visited", value: "M3++" },
        { text: "M3+ - Called & Coming", value: "M3+" },
        { text: "M3 - Called & Positive", value: "M3" },
        { text: "M2 - Did not Visit & Postponed", value: "M2" },
        { text: "M1 - Did not Visit & not intersted", value: "M1" },
      ],
      typeSelction: "",
      courseSelection: "",
      connection_status: false,
      latestDate: "",
      enrollment: false,
    };
  },
  methods: {
    generateEngagementInfoBadges() {
      if (this.payload.incommunicado_status !== 0) {
        for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
          if (this.payload.engagements[x].connection_status === "CD") {
            break;
          } else {
            this.arr.forEach((ar) => {
              if (this.payload.engagements[x].connection_status === ar.form) {
                ar.value = ar.value + 1;
              }
            });
          }
        }
      }
      // console.log(this.arr);
    },
    generateEngagementText(value) {
      let engagementString = "";
      if (this.payload.mwb.engagement_details === null) {
        console.log(this.payload);
        this.textAre = value;
        this.latestEngagement = value;
        this.connection_status = false;
      } else {
        if (this.payload.mwb.engagement_details.includes("|")) {
          let index = this.payload.mwb.engagement_details.indexOf("|");
          index = index + 1;
          engagementString = `${this.getData()} - ${value}, | ${this.payload.mwb.engagement_details.substring(
            index
          )}`;
        } else {
          engagementString = `${this.getData()} - ${value}, | ${
            this.payload.mwb.engagement_details
          }`;
        }
        this.textAre = value;
        this.latestDate = engagementString;
        this.connection_status = false;
      }
    },
    getData() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    customFormatter(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    emailform() {
      EventBus.$emit("open-email-engagement", this.payload);
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    async API_post_add_engagement() {
      if (this.textAre === "" || this.date === "") {
        this.$vs.notify({
          title: "Fill out the form",
          text: "Please fill out the details in the engagement form",
          color: "danger",
        });
      } else {
        const engagementType = this.navTitleName.toLowerCase();
        if (this.connection_status === false && engagementType === "call") {
          this.textAre = this.latestDate;
        }
        let timestmp = "";
        if (this.payload.mwb.level !== "M7") {
          timestmp = new Date(this.dates).getTime() / 1000;
        }
        let connect_status = "";
        switch (this.select1) {
          case "Connected / Discussed":
            connect_status = "cd";
            break;
          case "Connected / Busy":
            connect_status = "cb";
            break;
          case "Connected / Never call back":
            connect_status = "cn";
            break;
          case "Connected / Wrong Number":
            connect_status = "cw";
            break;
          case "Busy":
            connect_status = "b";
            break;
          case "Not Lifting":
            connect_status = "nl";
            break;
          case "Not reachable":
            connect_status = "nr";
            break;
          case "Disconnected":
            connect_status = "d";
            break;
          case "Invalid Number":
            connect_status = "in";
            break;
          case "Switched Off":
            connect_status = "so";
            break;
        }
        if (this.navTitleName.toLowerCase() === "visit") {
          connect_status = "cd";
        }
        let copyenrollment = "";
        if (this.enrollment) {
          copyenrollment = "Yes";
        } else {
          copyenrollment = "No";
        }
        // console.log(this.enrollment);
        let co = this.course.join();
        let url = `${constants.SERVER_API}addEngagement`;
        let obj = {
          can_id: this.payload.mwb.can_id,
          person_id: this.payload.mwb.person_id,
          person_name: this.payload.mwb.person_name,
          level: this.levels,
          courses: co,
          details: this.textAre,
          type: this.navTitleName.toLowerCase(),
          acads: 0,
          next_call: timestmp,
          update_call_log: "Yes",
          connection_status: connect_status.toUpperCase(),
          enrollment: copyenrollment,
        };
        console.log(obj);
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.$store.commit("UPDATE_ENGAGEMENT", [
              this.payload,
              this.textAre,
              this.getData(),
              connect_status,
            ]);
            this.$store.commit("UPDATE_ENGAGEMENT_AND_LEVEL", [
              this.payload,
              response.data.engagement,
            ]);
            this.$vs.notify({
              title: "Engagement Added",
              text: "Engagement is successfully added",
              color: "success",
            });
            if (this.$route.path === "/netEnquiryTable") {
              console.log(" ");
              EventBus.$emit("updateCompleteNetEnquiry");
              EventBus.$emit("close-MWB-popup");
              EventBus.$emit("close-popup-redirect");
            } else {
              EventBus.$emit("close-MWB-popup");
              EventBus.$emit("close-popup-redirect");
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    navSettings(text) {
      this.navTitleName = text;
      // console.log("change nav");
      switch (text) {
        case "All":
          this.indexActive = 0;
          this.allpage = true;
          this.callpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Call":
          this.indexActive = 1;
          this.textAre = "";
          this.select1 = "";
          this.levels = this.payload.mwb.level;
          this.enrollment = false;
          this.callpage = true;
          this.allpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Visit":
          this.indexActive = 2;
          this.textAre = "";
          this.enrollment = false;
          this.visitpage = true;
          this.disable = false;
          this.connected = true;
          this.connection_status = true;
          this.allpage = false;
          this.callpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          if (
            !this.payload.mwb.level.includes("4") &&
            !this.payload.mwb.level.includes("5") &&
            !this.payload.mwb.level.includes("6") &&
            !this.payload.mwb.level.includes("7")
          ) {
            this.levels = "M5";
          } else {
            this.levels = this.payload.mwb.level;
          }
          break;
        case "Email":
          this.indexActive = 3;
          this.emailpage = true;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          this.whatsapp_page = false;
          break;
        case "Whatsapp":
          this.indexActive = 4;
          this.whatsapp_page = true;
          this.emailpage = false;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          break;
      }
    },
  },
};
</script>

<style>
.contact_buttons {
  font-size: 50px;
  width: 100px !important;
  height: 100px !important;
  padding-top: 25px;
}
</style>
