<template>
  <div>
    <vs-popup class="holamundo" fullscreen title="BIG 4 Meet & Greet" :active.sync="open_popup" @close="closePopup">
      <vs-row vs-w="12" style="font-size: larger; color: black">
        <vs-col vs-w="6">
          <div style="border: 0.5px solid gray; border-radius: 15px; padding: 30px">
            <p>
              Hello Team KPMG,<br /><br />
              Greetings of the day!<br /><br />
              We are glad to inform you that we have identified an interesting candidate
              who can be proven to be a perfect fit for your company. We would like to
              request you allow us 15 minutes of your valuable time to interact with our
              candidate. Please find below the details of the candidate for your perusal.
            </p>
            <br />
            <div>{{ payload.mwb.person_name }},</div>
            <div>Email : {{ payload.emails[0].email }}</div>
            <div>Professional Qualification: {{ qualification.join() }}</div>
            <div>Company: {{ payload.mwb.company }}</div>
            <div>Years of Exp: {{ experience }}</div>
            <div>Current Location: {{ payload.google_location }}</div>
            <div>Current CTC: {{ current_ctc }}</div>
            <br />
            <p>
              Would you be free on {{ getDate(dates) }} in the
              {{ time_slot.toLowerCase() }} to get on a call for 15 mins with this
              candidate?
            </p>
            <br />
            <p>Look forward to hearing from you!</p>
            <br />
            <p>Best regards,</p>
            <p>{{ payload.mwb.spoc_name }}</p>
            <p>Miles Education</p>
          </div>
          <!-- <vs-textarea v-model="email_template"  style="height:80vh"/> -->
          <!-- <vs-input disabled class="inputx" label="Name" v-model="name"/>
                    <vs-input disabled class="inputx" label="Email" v-model="email"/> -->
          <!-- </vs-card> -->
        </vs-col>
        <vs-col vs-w="6">
          <div v-if="current_ctc_alert" class="alert_message">
            {{ current_ctc_alert_message }}
          </div>
          <div v-if="experience_alert" class="alert_message">
            {{ experience_alert_message }}
          </div>
          <!-- <vs-input class="inputx" label="Professional Qualification" v-model="payload.mwb.education" />
                     -->
          <p style="color: gray; font-size: 15px;margin-inline: 1.5%;">Professional Qualification:</p>
          <!-- <v-select placeholder="Qualification" style="z-index: 1010 !important;margin-top: 4%; margin: 2px" class="w-full"
            v-model="qualification" multiple :options="qualifications"></v-select> -->
           <treeselect v-model="qualification" :multiple="true" :options="options1"
          style="z-index:1700; margin-inline: 1.5%;" />
          <vs-row>
            <vs-col vs-w="6">
              <vs-input class="inputx" label="Company" v-model="payload.mwb.company" />
            </vs-col>

            <vs-col vs-w="6">
              <vs-input class="inputx" label="Years of Experience" v-model="experience" />
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-w="6">
              <vs-input class="inputx" label="Current CTC per annum in Lakhs" v-model="current_ctc" />
            </vs-col>

            <vs-col vs-w="6">
              <div style="font-size: 12px; color: grey">Current Location</div>
              <vue-google-autocomplete ref="addr" id="location" placeholder="Please type your address"
                v-on:placechanged="getAddressData" types="(cities)" style="position: relative; width: 100%; height: 63%"
                v-model="candidate_google_location">
              </vue-google-autocomplete>
            </vs-col>
          </vs-row>

          <!-- <vs-input class="inputx" label="Current Location" v-model="payload.google_location" /> -->

          <!-- <div style="margin-top:3%;"> Select Time slot:  -->
          <!-- <vs-row vs-w="12" style="margin-top:3%;">
                        <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
                            Select Time Slot:
                        </vs-col>
                        <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
                            <div style="display:flex;">
                                <datepicker :format="customFormatter" v-model="dates" placeholder="Select Date">
                                </datepicker>
                                &nbsp;
                                <div style="width:250px;">
                                    <v-select v-model="time_slot" placeholder="Select Slot" :options="slots"></v-select>
                                </div>
                            </div>

                        </vs-col>
                    </vs-row> -->
          <!-- </div> -->
          <div class="mentoring-container">
            <vs-row>
              <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="popup-head mb-4">
                <h5>ONE-ON-ONE MENTORING @MILES</h5>
              </vs-col>
            </vs-row>
            <vs-row class="mt-4 pl-16">
              <vs-col vs-offset="0.5" vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Preferred Mentor</p>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Block the Date</p>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" class="mb-4 mt-2">
                <v-select placeholder="Select Mentor" style="z-index: 1003; width: 225px" v-model="preferred_mentor"
                  :options="mentors" :disabled="disabled_mentor"></v-select>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" class="mb-4 mt-2">
                <datepicker :format="customFormatter" v-model="dates" placeholder="Select Date"
                  :disabledDates="disabledDates" style="z-index: 1002; width: 225px"></datepicker>
              </vs-col>

              <vs-col vs-offset="0.5" vs-w="10" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Available Time Slots</p>
              </vs-col>

              <vs-col vs-offset="0.5" vs-w="10" class="mb-4 mt-2">
                <v-select placeholder="Select Slot" style="z-index: 1001; width: 225px" v-model="available_slot"
                  :options="available_slots"></v-select>
              </vs-col>
              <!-- <vs-col vs-offset="0.5" vs-w="11.5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                                class="select-head">
                                <p>Slot Duration</p>
                            </vs-col>
                            <vs-col vs-offset="0.5" vs-w="5" class="mb-4 mt-2">
                                <v-select placeholder="Select Slot" style="z-index: 1002;width: 225px;"
                                    v-model="slot_duration" :options="slots"></v-select>
                            </vs-col> -->

              <!-- <vs-col vs-offset="0.5" vs-w="11.5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                                class="select-head">
                                <p>Enter Question ID or Reference Page No.</p>
                            </vs-col>
                            <vs-col vs-offset="0.5" vs-w="10" class="inp mb-2 mt-2">
                                <vs-input size="default" v-model="value2" />
                            </vs-col> -->
              <vs-col vs-offset="0.5" vs-w="11.5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head mt-4">
                <p>Agenda For The Session</p>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="11.5" class="inp2 mt-2">
                <vs-button radius color="white" type="border" icon="refresh" class="button2" @click="refreshTextarea()"
                  v-if="agenda_for_the_session !== ''"></vs-button>
                <vs-textarea v-model="agenda_for_the_session" class="textarea2" />
              </vs-col>
              <!-- <vs-col vs-offset="0.5" vs-w="10" vs-type="flex" vs-justify="flex-start" vs-align="center"
                                class="textarea-desc">
                                <p>Please explain in brief your points of discussion. In case of an academic
                                    query.be
                                    sure to mention the
                                    quetion ID
                                    or the page number from the Miles Textbook that you plan to discuss.
                                </p>
                            </vs-col> -->
              <vs-col vs-offset="0.5" vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center" class="mt-4">
                <vs-button color="#78AE7E" type="filled" @click="submitData" :disabled="disabled"
                  id="button-with-loading" class="vs-con-loading__container">SCHEDULE APPOINTMENT
                </vs-button>
              </vs-col>
            </vs-row>
          </div>


          <!-- <div style="display: flex; justify-content:flex-end; margin-top:5%;">
                        <vs-button @click="submitData" :disabled="disabled" id="button-with-loading"
                            class="vs-con-loading__container">Submit</vs-button>
                    </div> -->
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import ApiServices from "../../../ApiServices.js";
import vSelect from "vue-select";
import moment from "moment";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import constants from "../../../../constants.json";
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "vue-select/dist/vue-select.css";
export default {
  props: ["meet_and_greet_popup", "payload"],
  components: {
    Datepicker,
    VueTimepicker,
    "v-select": vSelect,
    "vue-google-autocomplete": VueGoogleAutocomplete,
    Treeselect
  },
  data() {
    return {
      options1:[],
      disabled_mentor: true,
      current_time: moment(),
      spoc_city: "",
      disabledDates: {
        to: new Date(new Date().getTime() - 8640000),
      },
      open_popup: false,
      schedule_session: "",
      preferred_mentor: "",
      slot_duration: "",
      available_slot: null,
      agenda_for_the_session: '',
      value2: "",
      sessions: [
        "Academic Queries - AUD",
        "Academic Queries - FRM",
        "Academic Queries - CPA",
        "Academic Queries - CMA",
      ],
      mentors: ["Anitha Satish"],
      slots: [
        "15 Minutes",
        "20 Minutes",
        "30 Minutes",
        "40 Minutes",
        "45 Minutes",
        "50 Minutes",
        "60 Minutes",
      ],
      available_slots: [],
      all_slots: [
        "10:30:00",
        "11:00:00",
        "11:30:00",
        "12:00:00",
        "12:30:00",
        "13:00:00",
        "13:30:00",
        "14:00:00",
        "14:30:00",
        "15:00:00",
        "15:30:00",
        "16:00:00",
        "16:30:00",
        "17:00:00",
        "17:30:00",
        "18:00:00",
        "18:30:00",
      ],
      disabled: false,
      backgroundLoading: "primary",
      colorLoading: "#fff",
      current_ctc_alert: false,
      current_ctc_alert_message: "",
      experience_alert: false,
      experience_alert_message: "",
      dates: new Date(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      ),
      // slots: [
      //     "Morning",
      //     "Afternoon",
      //     "Evening",
      // ],
      time_slot: "Morning",
      current_ctc: null,
      preferred_time_slot: "",
      preferred_date_time: "",
      qualification: [],
      qualifications: [
        "PUC",
        "INTER",
        "B.Com",
        "B.Com(P)",
        "M.Com",
        "M.Com(P)",
        "MBA",
        "MBA(P)",
        "CA",
        "CWA",
        "CS",
        "BMS",
        "BMS(P)",
        "BBA",
        "BBA(P)",
        "PGD",
        "PGD(P)",
        "LLB",
        "LLB(P)",
        "B.Tech",
        "B.Tech(P)",
        "B.Sc",
        "B.Sc(P)",
        "BA",
        "BA(P)",
        "CA-Inter",
        "CWA-Inter",
        "CS-Inter",
        "PG(P)",
        "CA Final",
        "CWA Final",
        "CS Final",
        "IGNOU - B.Com (CA)",
        "IGNOU - B.Com (CWA)",
        "IGNOU - B.Com (CS)",
        "MCA",
        "PGDPA",
        "PGDSF",
        "MA",
        "PGDM",
        "BBM",
        "BCA",
        "SCESA",
        "CFA",
        "FRM",
        // "MAcc",
        "USP",
        "CFA(P)",
        "MFA",
        "MFA(P)",
        "CMA(US)",
        "CPA(US)",
        "BAF",
        // { text: "", value: 0 },
        // { text: "B.Com", value: 0 },
        // { text: "B.Com(P)", value: 18 },
        // { text: "M.Com", value: 1 },
        // { text: "M.Com(P)", value: 19 },
        // { text: "MBA", value: 2 },
        // { text: "MBA(P)", value: 20 },
        // { text: "CA", value: 3 },
        // { text: "CWA", value: 4 },
        // { text: "CS", value: 5 },
        // { text: "BMS", value: 6 },
        // { text: "BMS(P)", value: 21 },
        // { text: "BBA", value: 7 },
        // { text: "BBA(P)", value: 17 },
        // { text: "PGD", value: 8 },
        // { text: "PGD(P)", value: 22 },
        // { text: "LLB", value: 9 },
        // { text: "LLB(P)", value: 23 },
        // { text: "B.Tech", value: 10 },
        // { text: "B.Tech(P)", value: 24 },
        // { text: "B.Sc", value: 11 },
        // { text: "B.Sc(P)", value: 25 },
        // { text: "BA", value: 12 },
        // { text: "BA(P)", value: 26 },
        // { text: "CA-Inter", value: 13 },
        // { text: "CWA-Inter", value: 14 },
        // { text: "CS-Inter", value: 15 },
        // { text: "PG(P)", value: 16 },
        // { text: "CA Final", value: 16 },
        // { text: "CWA Final", value: 16 },
        // { text: "CS Final", value: 16 },
        // // { text: "IGNOU", value: 16 },
        // { text: "IGNOU - B.Com (CA)", value: 16 },
        // { text: "IGNOU - B.Com (CWA)", value: 16 },
        // { text: "IGNOU - B.Com (CS)", value: 16 },
        // { text: "MCA", value: 16 },
        // { text: "PGDPA", value: 16 },
        // { text: "PGDSF", value: 16 },
        // { text: "PGDM", value: 16 },
        // { text: "BBM", value: 16 },
        // { text: "SCESA", value: 16 }
      ],
      experience: null,
      candidate_google_location: "",
    };
  },
  mounted() {

    this.getSpocCity()
    if (this.preferred_mentor == '' || this.preferred_mentor == null) {
      this.disabled = true
    }
    this.getPreviousDay(new Date())
    // this.getAvailableSlots();
    this.getEducationTags();
    const start = moment();
    const remainder = 15 - (start.minute() % 30);
    this.current_time = moment(start).add(remainder, "minutes").format("HH:mm:00");
    this.getLatestTimeSlot();
    this.$refs.addr.focus();
    console.log("payload", this.payload);
    this.experience = this.payload.mwb.experience_int;
    this.current_ctc = this.payload.mwb.current_ctc;
    if (this.payload.mwb.education_tags !== null) {
      this.qualification = this.payload.mwb.education_tags.split(",");
    } else {
      this.qualification = [];
    }
    // this.current_ctc_alert = false;
    // this.experience_alert = false;
    this.candidate_google_location = this.payload.google_location;
    // this.getTimeSlot();
  },
  watch: {
    meet_and_greet_popup: function (val) {
      this.open_popup = val;
      console.log("popup value", val);
      if (val == true) {
        this.current_ctc_alert = false;
        this.experience_alert = false;
      }
    },
    current_ctc: function (val) {
      console.log("ctc", val);
      let float_ctc = parseFloat(val);
      if (float_ctc > 100) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be above 100";
        this.disabled = true
      } else if (isNaN(float_ctc) && val != '' && val != null) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be a string";
        this.disabled = true

      } else if (val == "") {
        this.current_ctc = null;
        this.current_ctc_alert = false;
        this.disabled = false


      }
      else {
        this.current_ctc_alert = false;
        this.disabled = false


      }
    },
    experience: function (val) {
      console.log("experience", val);
      let float_exp = parseFloat(val);

      if (isNaN(float_exp) && val != '' && val != null) {
        this.experience_alert = true;
        this.experience_alert_message = "Experience cannot be a string";
        this.disabled = true

      } else if (val == "") {
        this.experience = null;
        this.experience_alert = false;
        this.disabled = false

      } else {
        this.experience_alert = false;
        this.disabled = false


      }
    },
    preferred_mentor: function (val) {
      if (val !== "" && val !== null) {
        // this.getAvailableSlots();
      } else {
        this.disabled = true
        this.available_slots = [];
      }
    },
    dates: function () {
      console.log("dateshjughuy",this.dates);
      if (this.preferred_mentor !== "" && this.preferred_mentor !== null) {
        // this.getAvailableSlots();
        this.getLatestTimeSlot();

      } else {
        this.available_slots = [];
        this.getLatestTimeSlot();
      }
    },
  },
  methods: {
    getPreviousDay(date = new Date()) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);
      this.disabledDates.to = previous
    },
    getLatestTimeSlot() {
      let present_date = moment(this.dates).format("YYYY-MM-DD");
      let selected_date = moment(new Date()).format("YYYY-MM-DD");
      if (present_date == selected_date) {
        let newTimeSlots = this.all_slots.filter(element => element > this.current_time);
        this.available_slots = []
        this.available_slots = newTimeSlots
      } else {
        this.available_slots = this.all_slots
      }
    },
    getSpocCity() {
      ApiServices.getSpocCity().then((response) => {
        console.log("not available slots", response.data);
        this.spoc_city = response.data;
        this.defaultSpoc();
      })
        .catch((error) => {
          this.showCustomNotification(error, error, "danger", 2000);
        });
    },
    defaultSpoc() {
      if (['Ernakulam', 'Chennai', 'Bangalore'].includes(this.spoc_city)) {
        this.preferred_mentor = 'Anitha Satish'
      } else if (['Kolkata', 'Delhi', 'Hyderabad'].includes(this.spoc_city)) {
        this.preferred_mentor = 'Beena Patel'
      } else if (['Ahmedabad', 'Pune', 'Mumbai'].includes(this.spoc_city)) {
        this.preferred_mentor = 'Aruna Mary'
      }
      else {
        this.preferred_mentor = '';
      }
    },
    getTimeSlot() {
      const date = new Date();
      const h = date.getHours();
      const m = date.getMinutes();
      const slot_time = [];
      const now_time = h + ":" + m + ":" + "00";
      console.log("date", now_time, this.all_slots);
      for (let i = 0; i < this.all_slots.length; i++) {
        const element = this.all_slots[i];
        if (now_time <= element) {
          slot_time.push(element);
        }
      }
      slot_time.sort();
      this.available_slot = slot_time[0];
    },
    // getAvailableSlots() {
    //   let date = moment(this.dates).format("YYYY-MM-DD");
    //   if (this.preferred_mentor !== "" && this.preferred_mentor !== null) {
    //     ApiServices.bookedSlotsForHr(this.preferred_mentor, date)
    //       .then((response) => {
    //         console.log("not available slots", response.data);
    //         console.log("not available slots", this.available_slots);
    //         let booked_slots = response.data;
    //         let all_slotss = this.available_slots
    //           .filter((x) => !booked_slots.includes(x));
    //         // let all_slotss = this.available_slots
    //         //   .filter((x) => !booked_slots.includes(x)).concat(booked_slots.filter((x) => !this.available_slots.includes(x)));
    //         console.log("not available slots", this.available_slots);
    //         this.available_slots = []
    //         this.available_slots = all_slotss

    //       })
    //       .catch((error) => {
    //         this.showCustomNotification(error, error, "danger", 2000);
    //       });
    //   } else {
    //     this.available_slots = this.all_slots
    //   }
    // },
    refreshTextarea() {
      this.agenda_for_the_session = "";
    },
    closePopup() {
      this.$emit("close-meet-and-greet-popup");
      this.current_ctc_alert = false;
      this.experience_alert = false;
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    getDate(dates) {
      return moment(dates, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
    clearFields() {
      this.available_slot = "";
      this.agenda_for_the_session = ""
      this.closePopup();
    },
    submitData() {
      // if(this.preferred_mentor !== null && this.dates !== null && this.available_slot !== null){

      // }
      console.log("this.preferred_mentor",this.preferred_mentor)
      
      let date = moment(this.dates).format("YYYY-MM-DD");

      if (this.available_slot !== null && this.preferred_mentor != null && this.preferred_mentor != '') {
        this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
        let candidate_details = {
          mwb_id: this.payload.mwb.id,
          professional_qualification: this.qualification.join(),
          company: this.payload.mwb.company,
          years_of_experience: parseFloat(this.experience),
          current_location: this.candidate_google_location,
          current_ctc: parseFloat(this.current_ctc),
          preferred_time_slot: this.time_slot.toLowerCase(),
          date: date,
          hr_name: this.preferred_mentor,
          slot: this.available_slot,
          agenda: this.agenda_for_the_session,
        };
        console.log("date_time", candidate_details);
        ApiServices.storeMeetAndGreet(candidate_details)
          .then((response) => {
            console.log("storeMeetAndGreet", response);
            this.$vs.notify({
              title: response.data.status,
              color: "success",
            });
            this.$vs.loading.close("#button-with-loading");
            this.clearFields();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.showCustomNotification(error, error, "danger", 2000);
            this.clearFields();
            this.$vs.loading.close("#button-with-loading");
          });
        this.current_ctc_alert = false;
        this.experience_alert = false;
      } else {
        // this.$vs.loading.close();
        this.showCustomNotification(
          "Field Required",
          "Timeslot & mentor is required",
          "danger",
          4000
        );
        // this.clearFields();
      }
    },
    getAddressData(addressData) {
      this.candidate_google_location =
        addressData.locality +
        "," +
        addressData.administrative_area_level_1 +
        "," +
        addressData.country;
      console.log("address", this.candidate_google_location);
    },
    checkAllFeilds() {
      if (
        this.candidate_google_location !== null &&
        this.preferred_mentor !== null &&
        this.preferred_mentor !== '' &&
        this.available_slot !== null &&
        this.current_ctc !== null
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkForValidations() {
      console.log("alert",this.experience_alert, this.current_ctc_alert)
      if (this.experience_alert == false && this.current_ctc_alert == false) {
        return false
      } else {
        return true
      }
    },
     getEducationTags() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)
          if(response.data.length > 0){
            for (let i = 0; i < response.data.length; i++) {
              this.options1.push({
                id : response.data[i],
                label : response.data[i]
              })
              
            }
              
          }
          // this.options1 = response.data
        })
        .catch((error) => {
          this.handleError(error);
        });
    }
  },
};
</script>

<style scoped>
.vl {
  position: absolute;
  border-left: 6px solid gray;
  height: 90%;
}

.pac-container {
  background-color: #fff;
  position: relative;
  z-index: 100000000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.alert_message {
  background: #e8b4b487;
  color: rgba(var(--vs-danger), 1);
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  /* border: 2px solid; */
  border-radius: 5px;
}

.popup-head>h5 {
  color: rgba(0, 0, 0, 0.822);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
}

.select-head {
  color: rgba(0, 0, 0, 0.822);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin: 2px 0 3px 0;
}

.textarea-desc {
  color: rgba(128, 128, 128, 0.493);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.meet-and-greet-popup .vs-popup {
  height: 685px;
}

.second-row {
  margin-top: 2%;
}

.inp>.vs-input>.vs-con-input {
  border: none;
  border-bottom: 2px solid #62d158;
  outline: none;
  border-radius: 5px;
}

.inp>.vs-input {
  width: 93%;
}

.inp2>.vs-con-textarea>.vs-textarea {
  border: none;
  border-bottom: 2px solid #ff4757;
  outline: none;
  border-radius: 5px;
  height: 85px;
}

.inp2 {
  position: relative;
  width: 78% !important;
}

.button2>.vs-icon {
  font-size: 1.6rem;
}

.button2 {
  background-color: #15a884 !important;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 2px solid #15a884 !important;
  z-index: 1001;
}

.textarea2 {
  position: relative;
  z-index: 1000;
}

.mentoring-container {
  margin-top: 2%;
}
</style>
