<template>
  <vs-popup class="holamundo" :title=title :active.sync="open" :id="'utilization_long'" style="z-index:52999">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Number</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Count</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Duration</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>Name</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Level</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Type</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>Date & Time</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Call Type</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Recording</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <b>Info</b>
      </vs-col>
    </vs-row>
    <div style="overflow-y: scroll;height: 500px;">
      <div v-for="(items, index) in newcalls" :key="index">
        <vs-divider v-if="items === 'br'" />
        <vs-row style="margin-top: 2%" v-else>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            v-if="items.call_duration === ''"
          ></vs-col>
          <vs-col
            v-else
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ lastdigitfunc(items.phone_number) }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.count }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
            v-if="items.call_duration === ''"
          ></vs-col>
          <vs-col
            v-else
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ secondsToHms(items.call_duration) }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            v-if="items.call_duration === ''"
          ></vs-col>
          <vs-col
            v-else
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
            >{{ items.person_name }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{items.level}}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ items.directory }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
            >{{ unixTimestampConvert(items.time / 1000) }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
            >{{ sortname(items.contact_type) }}</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
            <play-button
              v-if="items.call_recording_found !== 0"
              :call_log="items"
            ></play-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
            <BigPopupInfoButtonVue
              :mwb_id="items.mwb_id"
              :person_id="items.person_id"
            ></BigPopupInfoButtonVue>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import moment from "moment";
import playButton from "./callLogsPlayButton.vue";
import BigPopupInfoButtonVue from "./BigPopupInfoButton.vue";
export default {
  components: {
    playButton,
    BigPopupInfoButtonVue,
  },
  data() {
    return {
      open: false,
      calls: [],
      newcalls: [],
      title: ""
    };
  },
  mounted() {
    document.getElementById("utilization_long").children[1].style.width = "90%";
    EventBus.$on("utilization-long", payload => {
      console.log(payload, "this is the payload");
      this.title = "Long Duration Calls- " + payload[1] + " - " + payload[2];
      this.calls = JSON.parse(JSON.stringify(payload[0]));
      this.calls.forEach(call => {
        call.count = 1;
        call.phone_number = call.phone_number.substr(
          call.phone_number.length - 10
        );
      });
      this.calculatecount();
      this.open = true;
    });
  },
  methods: {
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    calculatecount() {
      // console.log(this.calls);
      var unique = this.calls
        .map(name => {
          return {
            count: 1,
            name: name.phone_number
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let clonearr = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (unique[key] === 1) {
            this.calls.forEach(call => {
              if (call.phone_number === key) {
                clonearr.push(call);
                clonearr.push("br");
              }
            });
          } else {
            let tempArr = [];
            this.calls.forEach(call => {
              if (call.phone_number === key) {
                tempArr.push(call);
              }
            });
            tempArr.forEach((temp, index) => {
              if (index > 0) {
                temp.count = "";
                tempArr[0].call_duration =
                  tempArr[0].call_duration + temp.call_duration;
                temp.call_duration = "";
                // temp.level = "";
              } else {
                temp.count = unique[key];
              }
              clonearr.push(temp);
            });
            clonearr.push("br");
          }
        }
      }
      this.newcalls = clonearr;
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      var date = new Date(value * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return `${dateString} - ${formattedTime}`;
      }
    },
    sortname(providedname) {
      if (providedname === "miles_employee") {
        return "Miles Employee";
      } else {
        return providedname;
      }
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var formatteddate =
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2);

      // console.log(formattedTime);
      return `${formatteddate}`;
    }
  }
};
</script>

<style>
</style>
