import { render, staticRenderFns } from "./ResendEmail.vue?vue&type=template&id=0ce8aa90&"
import script from "./ResendEmail.vue?vue&type=script&lang=js&"
export * from "./ResendEmail.vue?vue&type=script&lang=js&"
import style0 from "./ResendEmail.vue?vue&type=style&index=0&id=0ce8aa90&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports