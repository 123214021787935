<template>
  <vs-popup
    v-if="open"
    :id="'MissedCallPopup'"
    title="Assign this number"
    :active.sync="open"
  >
    <vx-card>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <!-- <b class="styling">{{ userdata.number }}</b> -->
        </vs-col>
      </vs-row>
      <!-- <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
          <vs-radio color="dark" v-model="trackTo" vs-value="b2c">B2C</vs-radio>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
          <vs-radio color="dark" v-model="trackTo" vs-value="b2bir">B2B(IR)</vs-radio>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
          <vs-radio color="dark" v-model="trackTo" vs-value="b2bcr">B2B(CR)</vs-radio>
        </vs-col>
      </vs-row> -->
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="trackTo" vs-value="Personal"
            >Personal</vs-radio
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="trackTo" vs-value="Miles Employee"
            >Employee</vs-radio
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="trackTo" vs-value="Spam"
            >Spam</vs-radio
          >
        </vs-col>
      </vs-row>
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="trackTo" vs-value="b2c">B2C</vs-radio>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="4"
        >
          <vs-radio color="dark" v-model="trackTo" vs-value="Vendor"
            >Vendor</vs-radio
          >
        </vs-col>
        <vs-col vs-justify="center" vs-align="center" vs-w="4">
          <vs-radio color="dark" v-model="trackTo" vs-value="add_a_lead"
            >Add a lead</vs-radio
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
        ></vs-col>
      </vs-row>
      <div
        v-if="trackTo === 'b2c' || trackTo === 'b2bir' || trackTo === 'b2bcr'"
      >
        <vs-row class="gap">
          <vs-col vs-w="4">
            <p>
              <b style="color: gray">Search With : </b>
            </p>
          </vs-col>
          <vs-col vs-w="8">
            <div>
              <span
                ><vs-radio
                  v-model="searchType"
                  vs-name="searchType"
                  vs-value="Name"
                  >Name</vs-radio
                >&nbsp;&nbsp;</span
              >
              <span
                >&nbsp;&nbsp;<vs-radio
                  v-model="searchType"
                  vs-name="searchType"
                  vs-value="Identity"
                  >Can-ID</vs-radio
                ></span
              >
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p>
              <b style="color: gray">Search ({{ sortNameb(trackTo) }})</b>
            </p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
            <v-select
              :clearable="false"
              :filterable="false"
              :options="nameAutocomplete"
              @search="autoCompleteName"
              label="showLabel"
              v-model="candidatesearch"
              style="width: 300px; z-index: 1000; position: fixed"
            />
            <!-- <p style="margin-top:2%; margin-right:2%">
              <b style="color: gray">Search ({{ sortNameb(trackTo) }})</b>
            </p>
            <vs-input
              icon-after="true"
              label-placeholder="icon-after"
              icon="search"
              color="dark"
              v-model="candidatesearch"
              placeholder="Enter a can ID"
            /> -->
            <!-- <vs-input icon="add" label-placeholder="Label-placeholder" v-model="value3"/> -->
          </vs-col>
        </vs-row>
        <div v-if="searchedQuery === true">
          <vs-row
            style="
              margin-top: 5%;
              border-radius: 25px;
              border: 0.5px solid gray;
              padding: 10px;
              width: auto;
              height: fit-content;
            "
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
            >
              <p>
                <b style="color: gray">Engagement</b>
                : {{ candidatesearch.engagement_details }}
              </p>
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-button
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container"
                size="medium"
                color="dark"
                type="border"
                icon
                @click="existingPersonAdd"
                >Save & update</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
        <vs-row class="gap" v-if="addalead === true">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"
            >oops, we couldn't find the person you searched for.</vs-col
          >
        </vs-row>
      </div>
      <div v-if="trackTo === 'add_a_lead'">
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <v-select
              :options="lead_popup"
              v-model="add_a_lead_option"
              placeholder="Category"
              style="width: 20%; z-index: 1000; position: fixed"
            />
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="gap" v-if="trackTo === 'Personal'">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 15px">Name:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-end"
          vs-w="9"
        >
          <vs-input class="inputx" placeholder="Name" v-model="personalName" />
        </vs-col>
      </vs-row>
      <vs-row class="gap" v-if="trackTo === 'Personal'">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <b style="color: gray; font-size: 15px">Relationship:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-end"
          vs-w="6"
        >
          <vs-input class="inputx" v-model="personalRelationship" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
          <vs-button color="dark" type="border" icon="check" @click="personal"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row class="gap" v-if="trackTo === 'Miles Employee'">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input
            class="inputx"
            placeholder="Name"
            v-model="mmilesemployee"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="dark" type="border" icon="check" @click="employee"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row class="gap" v-if="trackTo === 'Spam'">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input class="inputx" placeholder="Remark" v-model="spamentry" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="dark" type="border" icon="check" @click="spam"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row class="gap" v-if="trackTo === 'Vendor'">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input class="inputx" placeholder="name" v-model="vendorentry" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="dark" type="border" icon="check" @click="addVendor"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </vs-popup>
</template>

<script>
import _ from "lodash";
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  mounted() {
    EventBus.$on("open-untracked-details-popup", (payload) => {
      this.trackTo = "";
      this.trackedTo = "";
      this.userdata = payload;
      this.candidateData = [];
      this.$store.state.allCandidatesRaw.forEach((candid) => {
        let obj = {
          text: candid.candidate_name,
          value: candid.id,
        };
        this.candidateData.push(obj);
      });
      this.open = true;
    });
    document.getElementById("MissedCallPopup").children[1].style.marginBottom =
      "25%";
  },
  data() {
    return {
      searchType: "Name",
      // lead_popup: ["B2C", "B2B(IR)", "B2B(CR)"],
      lead_popup: ["B2C"],
      add_a_lead_option: "",
      mmilesemployee: "",
      nameAutocomplete: [],
      searchedQuery: false,
      error: false,
      addalead: false,
      spamentry: "",
      vendorentry: "",
      selectedEngagement: "",
      personalRelationship: "",
      personalName: "",
      options1: [],
      selectedSearch: "",
      candidatesearch: "",
      userdata: "",
      open: false,
      trackTo: "",
      trackedTo: "",
      candidateData: [],
    };
  },
  watch: {
    open(value) {
      if (value === false) {
        if (
          this.$route.path === "/" ||
          this.$route.path === "/acadsDashboard" ||
          this.$route.path === "/iimlDashboard" ||
          this.$route.path === "/workDashboard" ||
          this.$route.path === "/GMWorkDashboard"
        ) {
          EventBus.$emit("update-dashboard");
        }
      }
    },
    add_a_lead_option: function () {
      this.open = false;
      if (this.add_a_lead_option === "B2C") {
        EventBus.$emit("open-add-lead-popup");
        EventBus.$emit("pre-filled-b2c", {
          mobile_number: this.userdata.phone_number,
        });
      } else if (this.add_a_lead_option === "B2B(IR)") {
        EventBus.$emit("open-AddLeadIRPopup");
        EventBus.$emit("pre-filled-b2bir", {
          mobile_number: this.userdata.phone_number,
          // url: `${constants.SERVER_API}saveUntrackedToNewLead`
        });
      } else if (this.add_a_lead_option === "B2B(CR)") {
        EventBus.$emit("open-AddLeadCRPopup");
        EventBus.$emit("pre-filled-b2bcr", {
          mobile_number: this.userdata.phone_number,
          // url: `${constants.SERVER_API}saveUntrackedToNewLead`
        });
      }
    },
    candidatesearch: function () {
      this.addalead = false;
      this.searchedQuery = true;
    },
    trackTo: function () {
      this.selectedEngagement = "";
      this.candidatesearch = "";
      this.vendorentry = "";
      this.spamentry = "";
      this.personalRelationship = "";
      this.personalName = "";
      this.mmilesemployee = "";
      this.addalead = false;
      this.searchedQuery = false;
      this.add_a_lead_option = "";
    },
    selectedSearch: function (value) {
      this.options1.forEach((option) => {
        if (option.can_id === value) {
          this.selectedEngagement = option.engagement_details;
        }
      });
    },
    searchType(val) {
      if (val === "") {
        this.nameAutocomplete = [];
        this.candidatesearch = "";
      }
    },
  },
  methods: {
    sortNameb(givenName) {
      switch (givenName) {
        case "b2c":
          return "B2C";
        case "b2bir":
          return "B2BIR";
        case "b2bcr":
          return "B2BCR";
      }
    },
    personal() {
      if (this.personalName === "" || this.personalRelationship === "") {
        this.$vs.notify({
          title: "Please fill the details",
          text: "fill all the details to continue",
          color: "danger",
        });
      } else {
        this.$vs.loading();
        let obj = {
          relationship: this.personalRelationship,
          name: this.personalName,
          phone_number: this.userdata.phone_number,
        };
        axios
          .post(`${constants.SERVER_API}saveAsPersonalCall`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // this.$vs.loading.close();
            console.log(response);
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Response",
                text: response.data.message,
                color: "success",
              });
            }
            this.open = false;
            if (this.$route.path === "/") {
              EventBus.$emit("update-dashboard");
            }

            if (this.$route.path === "/queue") {
              EventBus.$emit("update-queue");
            }
          })
          .catch((error) => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          })
          .then(function () {
            // always executed
            setTimeout(() => {
              this.$vs.loading.close();
            }, 1000);
          });
      }
    },
    employee() {
      if (this.mmilesemployee === "") {
        this.$vs.notify({
          title: "Please enter a name",
          text: "Enter a name to assign this number",
          color: "danger",
        });
      } else {
        this.$vs.loading();
        let obj = {
          phone_number: this.userdata.phone_number,
          name: this.mmilesemployee,
        };
        console.log(this.userdata);
        console.log(obj);
        axios
          .post(`${constants.SERVER_API}saveAsMilesEmployee`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // this.$vs.loading.close();
            console.log(response);

            if (this.$route.path === "/queue") {
              EventBus.$emit("update-queue");
            }
            EventBus.$emit("update-dashboard");
            this.open = false;
          })
          .catch((error) => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          })
          .then(function () {
            // always executed
            setTimeout(() => {
              this.$vs.loading.close();
            }, 1000);
          });
      }
    },
    spam() {
      this.$vs.loading();
      let obj = {
        phone_number: this.userdata.phone_number,
        info: this.spamentry,
      };
      console.log(this.userdata);
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}saveAsSpamCall`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: "Success",
            text: response.data.message,
            color: "success",
          });

          if (this.$route.path === "/queue") {
            EventBus.$emit("update-queue");
          }
          EventBus.$emit("update-dashboard");
          this.open = false;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        })
        .then(function () {
          // always executed
          setTimeout(() => {
            this.$vs.loading.close();
          }, 1000);
        });
    },
    addVendor() {
      this.$vs.loading();
      let obj = {
        name: this.vendorentry,
        phone_number: this.userdata.phone_number,
      };
      axios
        .post(`${constants.SERVER_API}saveAsVendor`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: response.data.message,
            color: "success",
          });

          if (this.$route.path === "/queue") {
            EventBus.$emit("update-queue");
          }
          EventBus.$emit("update-dashboard");
          this.open = false;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        })
        .then(function () {
          // always executed
          setTimeout(() => {
            this.$vs.loading.close();
          }, 1000);
        });
    },
    existingPersonAdd() {
      this.nameAutocomplete.forEach(async (addedlead) => {
        if (this.candidatesearch.person_name === addedlead.person_name) {
          this.$vs.loading();
          let obj = {
            phone_number: this.userdata.phone_number,
            type: this.trackTo.toUpperCase(),
            can_id: addedlead.can_id,
            person_id: addedlead.person_id,
            person_name: addedlead.person_name,
          };
          axios
            .post(`${constants.SERVER_API}saveUntrackedToExistingPerson`, obj, {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            })
            .then((response) => {
              // this.$vs.loading.close();
              console.log("response adding ", response);
              if (response.data.status === "success") {
                this.$vs.notify({
                  title: response.data.status,
                  text: response.data.message,
                  color: "success",
                });

                if (this.$route.path === "/queue") {
                  EventBus.$emit("update-queue");
                }
                EventBus.$emit("update-dashboard");
                this.open = false;
                let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${addedlead.can_id}&person_id=${addedlead.person_id}`;
                axios
                  .get(url, {
                    headers: {
                      Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                  })
                  .then(() => {
                    addedlead.mobile = [];
                    addedlead.emails = [];
                    addedlead.engagements = [];
                    this.$store.commit("MOUNT_MWB_LEADS", [addedlead]);
                    EventBus.$emit("update-dashboard");
                  })
                  .catch((error) => {
                    // if (error.response) {
                    //   console.log("error", error.response.status);
                    //   if (error.response.status === 401) {
                    //     this.ReDirect_to_login();
                    //   }
                    // }
                    this.handleError(error);
                  })
                  .then(function () {
                    // always executed
                    setTimeout(() => {
                      this.$vs.loading.close();
                    }, 1000);
                  });
              } else {
                this.$vs.notify({
                  title: response.data.status,
                  text: response.data.message,
                  color: "danger",
                });
              }
              setTimeout(() => {
                this.$vs.loading.close();
              }, 1000);
            })
            .catch((error) => {
              // if (error.response) {
              //   console.log("error", error.response.status);
              //   if (error.response.status === 401) {
              //     this.ReDirect_to_login();
              //   }
              // }
              this.handleError(error);
            })
            .then(function () {
              // always executed
              setTimeout(() => {
                this.$vs.loading.close();
              }, 1000);
            });
        }
      });
    },
    // autoCompleteName(value) {
    //   console.log("calling");
    //   let x = document.getElementById(value);
    //   let textfactor = x
    //     .getElementsByTagName("div")[0]
    //     .getElementsByTagName("input")[0].value;
    //   this.count = value;
    //   if (textfactor.length === 0) {
    //     this.nameAutocomplete = [];
    //   }
    //   if (textfactor.length >= 3) {
    //     this.count = 0;
    //     let url = `${
    //       constants.SERVER_API
    //     }searchMwbNameByType?name=${textfactor}&type=${this.trackTo.toUpperCase()}`;
    //     axios
    //       .get(url, {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.userAccessToken}`
    //         }
    //       })
    //       .then(response => {
    //         console.log(response);
    //         this.nameAutocomplete = response.data;
    //       });
    //   }
    // },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let searchtype = this.searchType;
      this.fetchMwbs(loading, search, searchtype, this);
    },
    fetchMwbs: _.debounce((loading, search, searchtype, vm) => {
      // let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      let url = `${
        constants.SERVER_API
      }searchMwbNameForReferenceWEB?name=${escape(search)}&type=${searchtype}`;
      // console.log('searchtype',searchtype)
      // console.log('url',url)

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          response.data.forEach((each) => {
            each.showLabel = `${each.identity} - ${each.person_name} - ${each.level}`;
          });
          vm.nameAutocomplete = response.data.map((lead) => lead);
          loading(false);
        });
    }, 350),
    async searchCandidate() {
      this.$store.commit("FLUSH_UNWANTED_LEADS");
      if (this.candidatesearch === "") {
        this.$vs.notify({
          title: "",
          text: "The input cannot be empty",
          color: "danger",
        });
      } else {
        axios
          .get(
            `${constants.SERVER_API}searchMwbName?name=${
              this.candidatesearch
            }&type=${this.trackTo.toUpperCase()}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.length === 0) {
              this.addalead = true;
              this.searchedQuery = false;
            } else {
              this.options1 = [];
              response.data.forEach((lead) => {
                lead.mobile = [];
                lead.engagements = [];
                lead.emails = [];
                lead.category_type = "temporary_storage";
                this.options1.push(lead);
              });
              this.$store.commit("PUSH_TO_MWB_LEADS", response.data);
              this.selectedSearch = `${this.options1[0].can_id}`;
              this.selectedEngagement = this.options1[0].engagement_details;
              this.addalead = false;
              this.searchedQuery = true;
            }
          })
          .catch((error) => {
            this.handleError(error);
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
          });
      }
    },
    functionality() {
      switch (this.trackTo) {
        case "b2bir":
          EventBus.$emit("open-AddLeadIRPopup");
          break;
        case "b2bcr":
          EventBus.$emit("open-AddLeadCRPopup");
          break;
      }
      this.open = false;
    },
    openEngagementPopup() {
      this.open = false;
      let emit = "";
      let usedata = "";
      this.$store.state.allCandidates.forEach((candid) => {
        if (candid.id === this.trackedTo) {
          usedata = candid;
          if (candid.level === "M7") {
            emit = "open-student-report-popup";
          } else {
            emit = "open-student-details-popup";
          }
        }
      });
      EventBus.$emit(emit, usedata);
    },
    // addlead() {
    //   this.open = false;
    // switch (this.trackTo.toUpperCase()) {
    //   case "B2C":
    //     EventBus.$emit("open-add-lead-popup");
    //     EventBus.$emit("pre-filled-b2c", {
    //       mobile_number: this.userdata.phone_number
    //     });
    //     break;
    //   case "B2BIR":
    //     EventBus.$emit("open-AddLeadIRPopup");
    //     EventBus.$emit("pre-filled-b2bir", {
    //       mobile_number: this.userdata.phone_number
    //       // url: `${constants.SERVER_API}saveUntrackedToNewLead`
    //     });
    //     break;
    //   case "B2BCR":
    //     EventBus.$emit("open-AddLeadCRPopup");
    //     EventBus.$emit("pre-filled-b2bcr", {
    //       mobile_number: this.userdata.phone_number
    //       // url: `${constants.SERVER_API}saveUntrackedToNewLead`
    //     });
    //     break;
    // }
    // },
    submitPersonal() {
      if (this.personalRelationship === "" || this.personalName === "") {
        this.$vs.notify({
          text: "Please fill out the details to continue",
          color: "danger",
          position: "bottom-center",
        });
      } else {
        this.open = false;
      }
    },
  },
};
</script>

<style>
.styling {
  color: gray;
  font-size: 20px;
}
.gap {
  margin-top: 3%;
}
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
  .rcorners2 {
    border-radius: 25px;
    border: 2px solid green;
    padding: 20px;
    width: 200px;
    height: 150px;
  }
  .removemargin {
    margin: 0px;
  }
}
</style>
