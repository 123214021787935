<template>
  <div>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-button
          icon="chat"
          color="success"
          type="filled"
          @click="send_whatsapp_message"
        >
          Send WhatsApp Message
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-input
      class="inputx"
      id="candidate_phone_number"
      v-model="phone_number"
      style="visibility: hidden"
    />
    <vs-textarea
      style="visibility: hidden"
      id="whatsapp_message"
      v-model="whatsapp_message"
    />
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      required: true,
      type: Object
    }
  },
  mounted() {
    // console.log(this.payload);
  },
  data() {
    return {
      phone_number: `91${this.payload.mobiles[0].last_ten_digits}`,
      whatsapp_message:
        "Be an IIM Lucknow MDP Alumni as you upskill in Business & Data Analytics. Register for Intro Webinar on IIM Lucknow & Wiley - Executive Education Program in Business and Data Analytics, 6 months, Part-time, Online plus 6 days immersions at IIML campus. Date: Mar 28, 2020, at 5 PM IST. Registration link: https://bit.ly/3dD0GXg Cheers Miles Team MilesEducation.com"
    };
  },
  methods: {
    send_whatsapp_message() {
      console.log(this.phone_number);
      console.log(this.whatsapp_message);
    }
  }
};
</script>

<style></style>
