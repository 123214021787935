<template>
    <div>
      <vs-card
        style="background: #7e8f90; margin-top: 2%"
        v-for="(enquiry, index) in accelerator_session_data"
        :key="index"
      >
        <div>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
              style="color: white"
            >
            <b>{{ enquiry.live_webinar_topic }} - {{ enquiry.city_name }} - {{ enquiry.first_name }} {{enquiry.last_name }} - {{ enquiry.team }} - {{ enquiry.city_classification }}
            </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
              style="color: white"
            >
              <b>{{ enquiry.start_date }}</b>&nbsp;&nbsp;
              <b>{{ enquiry.start_time }}</b>
            </vs-col>
          </vs-row>
          <vs-divider color="rgb(255, 255, 255)" />
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
              style="font-size: 13px; color: white"
            >
               <span v-if="enquiry.attended == 0">Registered</span> 
               <span v-if="enquiry.attended == 1">Attended</span> 
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
              style="font-size: 13px; color: white"
            >
               {{ enquiry.source }}
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </div>
  </template>
  
  <script>
  import constants from "../../../../constants.json";
  import axios from "axios";

  export default {
    
    props: {
      studentDetails: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        accelerator_session_data: [],
      };
    },
    mounted(){
      this.acceleratorSessionData();
    },
    methods: {
      acceleratorSessionData() {
            this.$vs.loading();
            let url = `${constants.SERVER_API}getAcceleratorSessionDataForCandidate?mwb_id=${this.studentDetails.mwb.id}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    this.$vs.loading.close();
                    console.log("response", response.data);
                    this.accelerator_session_data = response.data;
                })
                .catch((error) => {
                    this.handleError(error);
                    this.$vs.loading.close();
                });
        },
    },
  };
  </script>
  
  <style></style>
  