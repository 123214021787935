<template>
  <vs-popup :title="'Search a lead'" id="netenquiryseach" :active.sync="open">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <v-select
          :filterable="false"
          :options="mwb_names"
          @search="autoCompleteName"
          v-model="value1"
          :label="'showLabel'"
          style="width:25%; margin-top: 0.5%; z-index: 1000; position: fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 10%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button type="border" color="dark" @click="openPopupEmit()">Search Lead</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import _ from "lodash";
import vSelect from "vue-select";
import constants from "../../../constants.json";
export default {
  // prop: ["userdata"],
  mounted() {
    EventBus.$on("search-net-enquiry-lead", payload => {
      this.value1 = "";
      this.userdata = payload;
      this.open = true;
    });
  },
  components: {
    vSelect
  },
  data() {
    return {
      userdata: "",
      open: false,
      hidden: true,
      url: "",
      value1: "",
      mwb_names: []
    };
  },

  methods: {
    generateName(can_id) {
      console.log(can_id);
      return "nothing";
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          response.data.forEach(each => {
            each.showLabel = `${each.identity} - ${each.person_name} - ${each.level}`;
          });
          vm.mwb_names = response.data;
          console.log(vm.mwb_names);
          loading(false);
        });
    }, 350),
    openPopupEmit() {
      if (this.value1 === "") {
        return;
      }
      console.log(this.value1);
      axios
        .get(`${constants.SERVER_API}getMwbLead?mwb_id=${this.value1.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log(response);
          response.data.data.engagements = [];
          response.data.data.mobile = [];
          response.data.data.emails = [];
          // console.log("before");
          this.$store.commit("MOUNT_MWB_LEADS", [response.data.data]);
          // console.log("after");
          axios
            .get(
              `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.data.can_id}&person_id=${response.data.data.person_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`
                }
              }
            )
            .then(response2 => {
              this.$store.commit("UPDATE_NEW_DATA", [
                response.data.data,
                response2.data.engagements,
                response2.data.mobile,
                response2.data.emails
              ]);
              EventBus.$emit(
                "open-student-details-popup",
                this.$store.getters.getObjectMwbLead(response.data.data)
              );
              this.open = false;
              // EventBus.$emit("netEnquiryDetails", this.value1);
              this.$vs.loading.close();
            })
            .catch(error => {
              this.handleError(error);
            });
          // console.log(this.$store.getters.getObjectMwbLead(response.data.data));
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style>
</style>
