<template>
  <vs-popup
    class="holamundo"
    title="Edit Plan Of Action"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">Evaluation:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="Phone" v-model="classes_attended" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="evaluation_options"
          v-model="evaluation"
          style="width:300px; z-index:50000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">NTS:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="Phone" v-model="OTB_attended" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="nts_options"
          v-model="nts"
          style="width:300px; z-index:45000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">Transcripts:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="Phone" v-model="OTB_attended" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="transcript_options"
          v-model="transcript"
          style="width:300px; z-index:45000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">AUD:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <v-select
          searchable
          clearable
          label="text"
          :options="aud_options"
          v-model="AUD"
          style="width:300px; z-index:35000; position:fixed"
        />
        <!-- <vs-input class="inputx" placeholder="AUD" v-model="AUD" /> -->
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">FAR:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="FAR" v-model="FAR" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="aud_options"
          v-model="FAR"
          style="width:300px; z-index:30000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">BEC:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="BEC" v-model="BEC" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="aud_options"
          v-model="BEC"
          style="width:300px; z-index:20000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-bottom: 1%">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        style="width: 35%;"
      >
        <p style="font-size: 20px">REG:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <!-- <vs-input class="inputx" placeholder="REG" v-model="REG" /> -->
        <v-select
          searchable
          clearable
          label="text"
          :options="aud_options"
          v-model="REG"
          style="width:300px; z-index:15000; position:fixed"
        />
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" style="margin-top: 2%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        style="width: 70%;"
      >
        <vs-button color="dark" type="gradient" @click="addDetails()"
          >UPDATE</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
// import moment from "moment";
import constants from "../../../constants.json";
export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      AUD: "",
      FAR: "",
      BEC: "",
      REG: "",
      evaluation: "",
      nts: "",
      transcript: "",
      evaluation_options: [
        "NA",
        "Not yet initiated",
        "Done",
        "WIP",
        "Awaiting Bridge Course"
      ],
      nts_options: [
        "NA",
        "Not yet initiated",
        "Done",
        "WIP"
      ],
      open: false,
      data: [],
      aud_options: [
        "NA",
        "Drop-Out",
        "Undecided",
        "Exam Given - Result Awaited",
        "Re-Attempt",
        "Cleared",
        "Q1-2022",
        "Q2-2022",
        "Q3-2022",
        "Q4-2022",
        "Q1-2023",
        "Q2-2023",
        "Q3-2023",
        "Q4-2023",
        "Q1-2024",
        "Q2-2024",
        "Q3-2024",
        "Q4-2024",
        "Q1-2025",
        "Q2-2025",
        "Q3-2025",
        "Q4-2025",
      ],
      bec_options: [
        "NA",
        "Drop-Out",
        "Undecided",
        "Exam Given - Result Awaited",
        "Re-Attempt",
        "Cleared",
        "Q1-2022",
        "Q2-2022",
        "Q3-2022",
        "Q4-2022",
        "Q1-2023",
        "Q2-2023",
        "Q3-2023",
        "Q4-2023",
        "Q1-2024",
        "Q2-2024",
        "Q3-2024",
        "Q4-2024",
        "Q1-2025",
        "Q2-2025",
        "Q3-2025",
        "Q4-2025",
      ],
      far_options: [
        "NA",
        "Drop-Out",
        "Undecided",
        "Exam Given - Result Awaited",
        "Re-Attempt",
        "Cleared",
        "Q1-2022",
        "Q2-2022",
        "Q3-2022",
        "Q4-2022",
        "Q1-2023",
        "Q2-2023",
        "Q3-2023",
        "Q4-2023",
        "Q1-2024",
        "Q2-2024",
        "Q3-2024",
        "Q4-2024",
        "Q1-2025",
        "Q2-2025",
        "Q3-2025",
        "Q4-2025",
      ],
      reg_options: [
        "NA",
        "Drop-Out",
        "Undecided",
        "Exam Given - Result Awaited",
        "Re-Attempt",
        "Cleared",
        "Q1-2022",
        "Q2-2022",
        "Q3-2022",
        "Q4-2022",
        "Q1-2023",
        "Q2-2023",
        "Q3-2023",
        "Q4-2023",
        "Q1-2024",
        "Q2-2024",
        "Q3-2024",
        "Q4-2024",
        "Q1-2025",
        "Q2-2025",
        "Q3-2025",
        "Q4-2025",
      ],
      transcript_options: [
        "Yet to Apply",
        "Applied",
        "Received"
      ],
      statusoptions: [
        { text: "L1", value: 0 },
        { text: "L2", value: 1 },
        { text: "L3", value: 2 },
        { text: "L4", value: 3 },
        { text: "L5", value: 4 },
        { text: "L6", value: 5 },
        { text: "L7", value: 6 },
        { text: "M1", value: 7 },
        { text: "M2", value: 8 },
        { text: "M3", value: 9 },
        { text: "M4", value: 10 },
        { text: "M5", value: 11 },
        { text: "M6", value: 12 },
        { text: "M7", value: 13 },
      ],
    };
  },
  mounted() {
    EventBus.$on("open-edit-POA", (payload) => {
      console.log("pao", payload);
      this.open = true;
      this.AUD = payload.cpa.aud;
      this.FAR = payload.cpa.far;
      this.BEC = payload.cpa.bec;
      this.REG = payload.cpa.reg;
      this.evaluation = payload.cpa.evaluation;
      this.nts = payload.cpa.nts;
      this.id = payload.cpa.id;
      this.transcript = payload.cpa.transcript;
    });
  },
  methods: {
    addDetails(payload) {
      console.log('payload',payload);
      let url = `${constants.SERVER_API}addCPAPlanOfAction`;
      let obj = {
        cpa_id: this.id,
        aud: this.AUD,
        far: this.FAR,
        bec: this.BEC,
        reg: this.REG,
        evaluation: this.evaluation,
        nts: this.nts,
        transcript: this.transcript
      };
      // console.log("edit poa", obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log("edit", response.data.cpa);
          // this.$store.commit("UPDATE_CPA_DATA", [response.data.cpa]);
          // this.$emit('poa-updated', response.data.cpa);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            EventBus.$emit("reload-bigpopup-data");
          }
          // if (response.data.status === `success`) {
          //   let poa = {
          //     aud: response.data.cpa.aud,
          //     far: response.data.cpa.far,
          //     bec: response.data.cpa.bec,
          //     reg: response.data.cpa.reg,
          //     evaluation: response.data.cpa.evaluation,
          //     nts: response.data.cpa.nts
          //   };
          //   this.$store.commit("UPDATE_CPA_DATA", [
          //     this.AUD,
          //     this.FAR,
          //     this.BEC,
          //     this.REG,
          //     this.evaluation,
          //     this.nts,
          //     poa
          //   ]);
          //   this.open = false;
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: response.data.message,
          //     color: "danger",
          //     time: 10000
          //   });
          // }
          // if (response.data.cpa.id === this.id) {
          //   this.$store.commit("UPDATE_CPA_DATA", [response.data.cpa]);
          //   // this.companyeditfunc();
          //   this.$vs.notify({
          //     title: "Success",
          //     text: "POA details successfully updated",
          //     color: "success"
          //   });
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "An Error occured while editing the POA of this lead",
          //     color: "danger"
          //   });
          // }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
