<template>
  <div>
    <vs-card style="margin-bottom: 2%">
      <!-- NE Header -->
      <div slot="header" v-if="userdata.type != 'mhp'">
        <vs-row style="margin-bottom: 2%">
          <vs-col style="font-size: 14px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
            <b>{{ userdata.name }}</b>
          </vs-col>
          <vs-col style="font-size: 15px" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
            <vs-button color="warning" style="margin-right: 5px;" type="gradient" icon="local_phone"
              @click="calluntracked"></vs-button>
            <vs-button color="warning" type="gradient" @click="openPopUp" icon="create"
              v-if="this.userdata.matched !== 1"></vs-button>
            <vs-button color="warning" type="gradient" @click="openPopUpMatched" icon="info"
              v-if="this.userdata.matched === 1"></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <!-- MHP Header -->

      <div slot="header" v-else>
        <vs-row style="margin-bottom: 2%">
          <vs-col style="font-size: 15px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
            <b>{{ userdata.identity }} - {{ userdata.person_name }} </b> 
            <!-- <b>{{ userdata.company }}</b> -->
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
            <svg v-if="userdata.type == 'Net Enquiry'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24"
              height="24" viewBox="0 0 172 172" style=" fill:#000000; margin-right: 4%">
              <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                font-family="none" font-weight="none" font-size="none" text-anchor="none"
                style="mix-blend-mode: normal">
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#3498db">
                  <path
                    d="M35.088,167.184c-0.688,0 -1.376,-0.344 -2.064,-0.688c-1.032,-0.688 -1.72,-2.408 -1.376,-3.784l15.136,-56.416l-45.408,-36.808c-1.376,-0.688 -1.72,-2.408 -1.376,-3.784c0.344,-1.376 1.72,-2.408 3.096,-2.408l58.48,-3.096l20.984,-54.696c0.688,-1.032 2.064,-2.064 3.44,-2.064c1.376,0 2.752,1.032 3.096,2.064l20.984,54.696l58.48,3.096c1.376,0 2.752,1.032 3.096,2.408c0.344,1.376 0,2.752 -1.032,3.784l-45.408,36.808l15.136,56.416c0.344,1.376 0,2.752 -1.376,3.784c-1.032,0.688 -2.752,1.032 -3.784,0l-49.192,-31.648l-49.192,31.648c-0.688,0.688 -1.032,0.688 -1.72,0.688z">
                  </path>
                </g>
              </g>
            </svg>
            <vs-button color="warning" style="margin-right: 5px;" type="gradient" icon="local_phone"
              @click="calluntracked"></vs-button>
            <vs-button color="warning" type="gradient" @click="openPopUpMhp" icon="info"></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <vs-row>
        <vs-col vs-w="6">
          <div>
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Enquiry Course:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.course
              }}</vs-col>
            </vs-row>
           
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Enquiry
                Date:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.enquiry_date
              }}</vs-col>
            </vs-row>

            <!-- <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Enquiry Type:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.net_enquiry_type
              }}</vs-col>
            </vs-row> -->
            <!-- <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Source:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.source
              }}</vs-col>
            </vs-row> -->
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Enquiry Time:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  getEnquiryTime(
                    userdata.enquiry_date_unix
                    )
              }}</vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">City:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.city
              }}</vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5">Details:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">{{
                  userdata.details
              }}</vs-col>
            </vs-row>
          </div>
        </vs-col>
        <vs-col vs-w="6">
          <div v-if="userdata.type == 'mhp'">
            <!-- <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-align="flex-start" vs-w="4">MHP Type
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                  userdata.type
              }}</vs-col>
            </vs-row> -->
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="4">Level:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                  userdata.level
              }}</vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-align="flex-start" vs-w="4">Company:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                  userdata.company
              }}</vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%">
              <vs-col style="font-size: 13px" vs-type="flex" vs-align="flex-start" vs-w="4">Experience:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8"
                v-if="userdata.experience != null">{{ userdata.experience }}</vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" v-else>0
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col style="font-size: 13px" vs-type="flex" vs-align="flex-start" vs-w="4">Designation:
              </vs-col>
              <vs-col style="font-size: 13px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">{{
                  userdata.designation
              }}</vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import moment from 'moment';

export default {
  props: {
    userdata: {},
  },
  mounted() {
    console.log("userdata", this.userdata)
  },
  methods: {
    getEnquiryTime(date){
      return moment(date*1000).format("h:mm a")
    },
    async openPopUpMhp() {
      this.openBigPopup(this.userdata.mwb_id);
      // this.$emit('close-navbar-net-enquiries');
    },
    async calluntracked() {
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        let ne_popup_permission_to_call = localStorage.getItem('ne_popup_permission_to_call');
        if (!ne_popup_permission_to_call) {
          if (
            this.$route.path !== "/queue" &&
            this.$route.path !== "/logout" &&
            this.$route.path !== "/UtilizationReport" &&
            this.$route.path !== "/receipts"
          ) {
            if (response === "no") {
              this.$vs.notify({
                title: "Permission denied",
                text: "Please contact admin!!!",
                color: "danger",
                icon: "error",
              });
              return;
            }
          }
        }

      }
      let params = {};
      params.phone_number = this.userdata.mobile;
      params.email = this.userdata.email;
      axios
        .get(constants.SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then(() => {
          this.open = false;
          if (this.userdata.matched !== 1) {
            EventBus.$emit("open-netEnquiries-details-popup", this.userdata);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    openPopUpMatched() {
      // this.$emit('close-navbar-net-enquiries');
      this.openBigPopup(this.userdata.mwb_id);
    },
    openPopUp() {
      EventBus.$emit("open-netEnquiries-details-popup", this.userdata);
    },
    getcalltypeicon(type) {
      switch (type) {
        case "OUTGOING":
          return "https://img.icons8.com/color/20/000000/outgoing-call.png";
        case "INCOMING":
          return "https://img.icons8.com/color/20/000000/callback.png";
        case "MISSED":
          return "https://img.icons8.com/office/20/000000/missed-call.png";
      }
    },
    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
  },
};
</script>

<style>

</style>
