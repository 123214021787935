<template>
  <vs-popup
    vs-type="flex"
    :id="'popup'"
    class="size"
    fullscreen
    :title="
      studentDetails.identity +
        ' - ' +
        studentDetails.person_name +
        ' - ' +
        studentDetails.level +
        ' - ' +
        studentDetails.iiml_level +
        ' - ' +
        unixTimestampConvert(studentDetails.last_call)
    "
    :active.sync="open"
  >
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
      ></vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-start"
        vs-w="6"
      ></vs-col>
    </vs-row>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-row>
          <vs-card>
            <card1 :studentDetails="studentDetails" />
          </vs-card>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <card3 :payload="studentDetails" />
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import Card1 from "./AboutEnrollment.vue";
import Card3 from "./StudentEnrollmentDetails.vue";
import moment from "moment";

export default {
  components: {
    Card1,
    Card3
  },
  mounted() {
    EventBus.$on("open-enrollment-popup", payload => {
      this.open = true;
      this.studentDetails = payload;
      EventBus.$emit("enrollment-opened", payload);
    });
    EventBus.$on("close-enrollment", () => {
      this.open = false;
    });
  },
  data() {
    return {
      savedashboard: false,
      open: false,
      studentDetails: "",
      vuexIndex: 0
    };
  },
  methods: {
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    EmitEvent(emit, payload) {
      EventBus.$emit(emit, payload);
    }
  },
  watch: {
    open: function(value) {
      if (value === false) {
        EventBus.$emit("renderOff");
        if (this.savedashboard === true) {
          console.log("updating dashboard");
          EventBus.$emit("update-dashboard");
        }
      }
    }
  }
};
</script>
