<template>
  <vs-popup title="Warehouse Receipts" id="warehouseaccountpopup" :active.sync="open">
    <vx-card actionable class="cardx">
      <div>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5"></vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
            style="padding: 3px; padding-left: 45px;"
          >
            <vs-button
              size="small"
              color="success"
              @click="request('approved', enrollment_id)"
              type="filled"
              v-model="approval_status"
              style="margin: 3px; font-size: 11px; font-weight: bold;"
            >Accept</vs-button>
            <vs-button
              size="small"
              color="danger"
              @click="request('rejected', enrollment_id)"
              v-model="reject_status"
              type="filled"
              style="margin: 3px; font-size: 11px; font-weight: bold;"
            >Reject</vs-button>
          </vs-col>
        </vs-row>
        <vs-table max-items="10" pagination :data="warehousesingle">
          <template slot="thead" class="exc">
            <vs-th>Can-ID</vs-th>
            <vs-th>Person Name</vs-th>
            <vs-th>Mode</vs-th>
            <vs-th>Subjects</vs-th>
            <vs-th>Agreed Cost</vs-th>
            <vs-th>Amount Pending</vs-th>
            <vs-th>City</vs-th>
            <vs-th>Batch</vs-th>
            <vs-th>Amount Paid</vs-th>
            <vs-th>Course</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{tr.identity}}</vs-td>
              <vs-td>{{tr.person_name}}</vs-td>
              <vs-td>{{sortText(tr.mode)}}</vs-td>
              <vs-td>{{tr.subjects_chosen}}</vs-td>
              <vs-td>{{tr.agreed_cost}}</vs-td>
              <vs-td>{{tr.amount_pending}}</vs-td>
              <vs-td>{{tr.city}}</vs-td>
              <vs-td>{{tr.batch}}</vs-td>
              <vs-td>{{tr.amount_paid}}</vs-td>
              <vs-td>{{(tr.course)}}</vs-td>
              <template class="expand-user" slot="expand">
                <vs-row>
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    <!-- <div v-for="(item, index) in " :key="index"> -->
                    <ware :item="tr.receipts"></ware>
                    <!-- </div> -->
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import axios from "axios";
import ware from "./WarehouseReceipt.vue";
// import vSelect from "vue-select";
export default {
  components: {
    // Datepicker,
    ware
  },
  data() {
    return {
      open: false,
      warehousesingle: [],
      items: [],
      approval_status: "approved",
      reject_status: "rejected",
      enrollment_id: ""
    };
  },
  mounted() {
    EventBus.$on("open-warehouseAccountPopup", response => {
      this.warehousesingle = [];
      this.warehousesingle.push(response.enrollment);
      this.enrollment_id = response.enrollment.id;
      // this.items = [];
      // this.items.push(response.receipts);
      this.open = true;
    });
    document.getElementById("warehouseaccountpopup").children[1].style.width =
      "100%";
  },
  watch: {},
  methods: {
    request(status, enrollment_id) {
      let url = `${constants.SERVER_API}approveOrRejectWarehouse`;
      let params = { approval_status: status, enrollment_id: enrollment_id };
      axios
        .post(url, params, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status !== "error") {
            EventBus.$emit("reload-approvals");
            this.open = false;
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>