<template>
  <div>
    <vs-popup :title="getPopUpTitle()" :active.sync="open">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b v-if="this.details.level1_approval == 'pending'" style="color: gray">Approve this Refund?</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-offset="3" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray">Candidate</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >: &nbsp;&nbsp; {{ details.name }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-offset="3" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray">Can ID</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >: &nbsp;&nbsp;{{ details.can_id }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-offset="3" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray">Course</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >: &nbsp;&nbsp;{{ details.course }}</vs-col
        >
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-offset="3" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b style="color: gray">Refund fee</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          >: &nbsp;&nbsp;{{ details.refund_amount }}</vs-col
        >
      </vs-row>
      <vs-row
        style="margin-top: 3%"
      >
        <vs-col vs-type="flex" vs-offset="3" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-button
            v-if="(this.details.level1_approval === 'pending' && (login_spoc_id == 139 || login_spoc_id == 3154)) || (this.details.level2_approval === 'pending' && login_spoc_id == 1757)"
            color="success"
            type="gradient"
            @click="approveRefundStatus('approved')"
            >Approve</vs-button
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button
            v-if="(this.details.level1_approval === 'pending' && (login_spoc_id == 139 || login_spoc_id == 3154)) || (this.details.level2_approval === 'pending' && login_spoc_id == 1757)"
            color="danger"
            type="gradient"
            @click="refundRejection"
            >Reject</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup title="Reason for rejection" :active.sync="rejectionPopup">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-textarea label="Comment" v-model="reason_for_rejection" />
        </vs-col>
      </vs-row>
      <vs-row
        style="margin-top: 3%"
      >
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button
            color="danger"
            type="gradient"
            @click="rejectionPopup = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button
            color="success"
            type="gradient"
            @click="approveRefundStatus('rejected')"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import constants from "../../../constants.json";
  import EventBus from "../eventbus.js";
  export default {
    mounted() {
        EventBus.$on("open-refund-approval-popup", payload => {
          console.log('open-refund', payload);
          this.details = payload.data;
          this.open = true;
          this.login_spoc_id = payload.login_spoc_id;
        });
    },
    data() {
      return {
        details: "",
        open: false,
        login_spoc_id: '',
        rejectionPopup: false,
        reason_for_rejection: '',
      };
    },
    methods: {
      getPopUpTitle(){
        switch (this.details.level1_approval) {
        case "pending":
          return "Refund Approval";
        case "approved":
          return "Refund Request Approved";
        case "rejected":
          return "Refund Request Rejected";
      }
      },
      refundRejection(){
        this.reason_for_rejection = ''
        this.rejectionPopup = true;
        this.open = false;
      },
      approveRefundStatus(message) {
        let obj = {
            can_id: this.details.can_id,
            mwb_id: this.details.mwb_id,
            person_id: this.details.person_id,
            ats_refund_id: this.details.id,
        };
        let url = '';
        if(this.login_spoc_id == 139 || this.login_spoc_id == 3154){
          obj.level1_approval = message;
          obj.action = 'defaulter_added';
          obj.level1_rejection_reason = this.reason_for_rejection;
          url = `${constants.SERVER_API}updateLevel1ApprovalStatus`;
        }else if(this.login_spoc_id == 1757){
          obj.level2_approval = message;
          obj.level2_rejection_reason = this.reason_for_rejection;
          url = `${constants.SERVER_API}updateLevel2ApprovalStatus`;
        }
        console.log("payload", obj, url);
        if(message == 'rejected' && this.reason_for_rejection == ''){
          this.$vs.notify({
            text: "Please enter the reason for rejection",
            color: "danger",
          });
        }else{
          this.$vs.loading();
          axios
            .post(url, obj, {
              headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
            })
            .then(response => {
              if (response.data.status === "success") {
                EventBus.$emit("reload-approbval-data");
                this.open = false;
                this.rejectionPopup = false;
                // this.$vs.notify({
                //   title: `Success`,
                //   text: response.data.message,
                //   color: response.data.status,
                // });
              }
              this.handleNotification(response);
              this.$vs.loading.close();
            })
            .catch(error => {
              this.handleError(error);
              this.$vs.loading.close();
            });
        }
      },
    }
  };
  </script>
  
  <style></style>
  