<template>
  <div>
    <vx-card actionable class="cardx" title="EMI Options">
      <vs-row type="flex" style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <span>Select Your Bank</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
          <v-select
            placeholder="Select Your Bank"
            style="z-index: 10000"
            class="w-full"
            v-model="repayment_bank"
            :options="RepaymentList"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <span>Select Account Type</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
          <v-select
            placeholder="Select Account Type"
            style="z-index: 8000"
            class="w-full"
            v-model="repyament_type"
            :options="repyament_account"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <span>Account No.</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
          <vs-input
            type="text"
            class="w-full"
            placeholder="Account Number"
            v-model="account_num"
          ></vs-input>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <span>IFSC Code</span>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
          <vs-input
            type="text"
            class="w-full"
            placeholder="IFSC Code"
            v-model="ifsc_code"
          ></vs-input>
        </vs-col>
      </vs-row>
      <vs-row type="flex" style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        ></vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-button
            :disabled="isDisabled"
            color="primary"
            type="gradient"
            @click="repay_sub()"
            >SUBMIT</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        ></vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus";
import banks from "./banklist.vue";
export default {
  props: {
    repay: {
      type: Object,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getDocumentsInfo();
    this.BankListData();
    this.getUserInfor();

    console.log("repayment", this.repay);
    console.log("payment", this.payment.eduvanz_response.lead_id);
  },
  data() {
    return {
      KYC: [],
      allDoc: [],
      mandidate: [],
      repyament_type: "",
      repayment_bank: "",
      repyament_account: [
        "SAVINGS ACCOUNT",
        "CURRENT ACCOUNT",
        "CREDIT CARD",
        "SAVING-NRE",
        "SAVING-NRO",
        "OTHER",
        "OVER DRAFT",
      ],
      account_num: "",
      bank_list: [],
      ifsc_code: "",
      UserId: "",
      DocumentData: [],
    };
  },
  components: {
    "v-select": vSelect,
    banks,
  },
  computed: {
    isDisabled: function () {
      return !this.ifsc_code;
    },
    RepaymentList: function () {
      let Bankarr = [];
      this.bank_list.forEach((banks) => {
        var banklist = {
          id: banks.id,
          label: banks.bank_name,
        };
        Bankarr.push(banklist);
      });
      return Bankarr;
    },
  },
  watch: {
    repayment_bank: function (value) {
      if (value.label === value.label) {
        this.repayment_id = value.id;
        this.bank_list_name = value.label;
        console.log("bank_namd", this.bank_document_id, this.bank_list_name);
      }
    },
    repyament_type: function (value) {
      console.log("account type", value);
      if (this.repyament_type === "SAVINGS ACCOUNT") {
        this.repayment_type_id = 1;
      } else if (this.repyament_type === "CURRENT ACCOUNT") {
        this.repayment_type_id = 2;
      } else if (this.repyament_type === "CREDIT CARD") {
        this.repayment_type_id = 3;
      } else if (this.repyament_type === "SAVING-NRE") {
        this.repayment_type_id = 4;
      } else if (this.repyament_type === "SAVING-NRO") {
        this.repayment_type_id = 5;
      } else if (this.repyament_type === "OTHER") {
        this.repayment_type_id = 6;
      } else if (this.repyament_type === "OVER DRAFT") {
        this.repayment_type_id = 7;
      }
    },
  },
  methods: {
    getDocumentsInfo() {
      this.DocumentData = [];
      axios
        .get(
          `https://loans.milesforce.com/api/getLeadSubmissionDocumentsList?lead_id=${this.payment.eduvanz_response.lead_id}`,
          {
            headers: {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            },
          }
        )
        .then((response) => {
          this.DocumentData = response.data;
          console.log("message submit", this.DocumentData);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getUserInfor() {
      const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=${this.repay.mwb.id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.UserId = response.data.id;
          // console.log("mwb2 data", this.UserId);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    BankListData() {
      axios
        .get(`https://loans.milesforce.com/api/getEduvanzBanklist`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log('bank_list',response);
          this.bank_list = response.data.eduvanz_response.bank_master;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    repay_sub() {
      this.$vs.loading();
      let repay = {
        lead_id: this.payment.eduvanz_response.lead_id,
        bank_id: this.repayment_id,
        account_number: this.account_num,
        ifsc: this.ifsc_code,
        account_type_id: this.repayment_type_id,
        user_id: this.UserId,
      };
      console.log("repayment", repay);
      axios
        .post(`https://loans.milesforce.com/api/updateRepaymentBankDetails`, repay, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("repayment", response.data);
          this.$vs.loading.close();
          if (response.data.status == "error") {
            this.open = false;
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          } else if (response.data.eduvanz_response.message == "Success") {
            this.open = false;
            this.$vs.notify({
              title: response.data.eduvanz_response.message,
              text: `Successfully submitted your details`,
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: response.data.eduvanz_response.error[0].message,
              // text: `Successfully submitted your details`,
              color: "danger",
            });
          }
          EventBus.$emit("reload-bigpopup-data");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
