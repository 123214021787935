<template>
  <vs-popup
    id="receiptforEnrollment"
    class="holamundo"
    title="Add Receipt to Enrollment"
    :active.sync="open"
  >
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          style="margin-top: 1%"
          class="w-full"
          placeholder="Enrollments"
          v-model="transaction_type"
          :options="list_of_transactions"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-input
          v-if="showNEFTSection"
          v-model="transaction_id"
          type="number"
          label-placeholder="Transaction ID"
        />
        <vs-input
          v-if="showChequeSection"
          v-model="cheque_no"
          type="number"
          label-placeholder="Cheque No"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 4%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-input v-model="amount" type="number" label-placeholder="Amount" />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <datepicker
          v-if="showChequeSection"
          id="datepicker"
          class="datepicker"
          style="z-index: 1000
                  "
          placeholder="PDC Date"
          v-model="pdc_date"
        ></datepicker>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 4%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="border" @click="addReceiptToEnrollment()">Add Receipt</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    Datepicker
  },
  watch: {
    transaction_type(value) {
      switch (value) {
        case "NEFT":
          this.transaction_id = "";
          this.showNEFTSection = true;
          this.showChequeSection = false;
          break;
        case "CHEQUE":
          this.pdc_date = "";
          this.cheque_no = "";
          this.showChequeSection = true;
          this.showNEFTSection = false;
      }
    }
  },
  mounted() {
    // document.getElementById("receiptforEnrollment").children[1].style.height = "40%";
    eventbus.$on("add-a-receipt-to-enrollment", payload => {
      console.log(payload);
      this.enrollmentData = payload;
      this.open = true;
    });
  },
  data() {
    return {
      list_of_transactions: ["NEFT", "CASH", "CHEQUE"],
      transaction_type: "",
      transaction_id: "",
      cheque_no: "",
      pdc_date: "",
      cc_machine_tid_no: "",
      amount: "",
      enrollmentData: "",
      open: false,
      showNEFTSection: false,
      showChequeSection: false
    };
  },
  methods: {
    addReceiptToEnrollment() {
      // if (this.checkParameters()) {
      console.log("proceed the call");
      let obj = {
        transaction_type: this.transaction_type,
        transaction_id: this.transaction_id,
        cheque_no: this.cheque_no,
        pdc_date: this.pdc_date,
        cc_machine_tid_no: this.cc_machine_tid_no,
        amount: this.amount,
        batch: "",
        identity: this.enrollmentData.identity,
        city: this.enrollmentData.city,
        person_id: this.enrollmentData.person_id,
        person_name: this.enrollmentData.person_name,
        enrollment_id: this.enrollmentData.id,
        course: this.enrollmentData.course
      };
      axios
        .post(`${constants.SERVER_API}addReceipt`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          this.handleError(error);
        });
      // }
    },
    checkParameters() {
      if (this.amount === "") {
        this.$vs.notify({
          title: "Enter an Amount",
          text: "Enter an Amount to continue",
          color: "warning"
        });
        return false;
      }

      if (this.transaction_type === "CASH" || this.amount !== "") {
        return true;
      }

      if (this.transaction_type === "") {
        this.$vs.notify({
          title: "Select a Transaction type",
          text: "Select a Transaction type to continue",
          color: "warning"
        });
        return false;
      }

      if (this.transaction_type === "NEFT" && this.transaction_id === "") {
        this.$vs.notify({
          title: "Enter a Transaction ID",
          text: "Enter a Transaction ID to continue",
          color: "warning"
        });
        return false;
      }

      if (this.transaction_type === "CHEQUE") {
        if (this.pdc_date === "" || this.cheque_no === "") {
          this.$vs.notify({
            title: "Enter all the Cheque details",
            text: "Enter all the Cheque details to continue",
            color: "warning"
          });
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<style>
</style>