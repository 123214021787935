<template>
  <!-- <vs-card style="height: 840px; overflow-y: auto"> -->
  <div>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
        style="height: 100px"
      >
        <vs-navbar
          text-color="black"
          color="#ffffff"
          active-text-color="#FFFFFF"
          class="myNavbar"
          v-model="ActiveNavBarTab"
        >
          <vs-navbar-item index="Apply Loan Application" class="nav_item">
            <a>Loan Application</a>
          </vs-navbar-item>
          <vs-navbar-item index="ckyc" class="nav_item">
            <a>Created Applications</a>
          </vs-navbar-item>
          <!-- <vs-navbar-item index="Check CKyc">
            <a>Check CKyc</a>
          </vs-navbar-item> -->
          <vs-navbar-item index="Upload Documents" class="nav_item">
            <a>Upload Documents</a>
          </vs-navbar-item>
          <vs-navbar-item index="Loan status" class="nav_item">
            <a>Loan Status</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div
      v-show="ActiveNavBarTab === 'Apply Loan Application'"
      style="width: 100%"
    >
      <apply-loan-application
        v-if="ActiveNavBarTab === 'Apply Loan Application'"
        :studentDetails="studentDetails"
      ></apply-loan-application>
    </div>
    <div v-show="ActiveNavBarTab === 'ckyc'" style="width: 100%">
      <created-loan-application
        v-if="ActiveNavBarTab === 'ckyc'"
        :studentDetails="studentDetails"
      ></created-loan-application>
    </div>
    <div v-show="ActiveNavBarTab === 'Upload Documents'" style="width: 100%">
      <upload-loan-documents
        v-if="ActiveNavBarTab === 'Upload Documents'"
        :studentDetails="studentDetails"
      ></upload-loan-documents>
    </div>
    <!-- <div v-show="ActiveNavBarTab === 'Check CKyc'" style="width: 100%">
      <check-ckyc
        v-if="ActiveNavBarTab === 'Check CKyc'"
        :studentDetails="studentDetails"
      ></check-ckyc>
    </div> -->
    <div v-show="ActiveNavBarTab === 'Loan status'" style="width: 100%">
      <loan-status
        v-if="ActiveNavBarTab === 'Loan status'"
        :studentDetails="studentDetails"
      ></loan-status>
    </div>
  </div>
  <!-- </vs-card> -->
</template>

<script>
import ApplyLoanApplication from "./ApplyLoanApplication.vue";
import UploadLoanDocuments from "./UploadLoanDocuments.vue";
import CreatedLoanApplication from "./CreatedLoanApplication.vue";
import LoanStatus from "./LoanStatus.vue";
// import CheckCKycComponent from "./CheckCKycComponent.vue";
// import axios from 'axios';
// import _ from "lodash";

export default {
  components: {
    "created-loan-application": CreatedLoanApplication,
    "apply-loan-application": ApplyLoanApplication,
    "upload-loan-documents": UploadLoanDocuments,
    "loan-status": LoanStatus,
    // "check-ckyc": CheckCKycComponent,
  },
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      ActiveNavBarTab: "Apply Loan Application",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
