<template>
  <div>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <p class="heading">POA</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
        <!-- <vs-button
          size="small"
          color="danger"
          type="gradient"
          @click="poaEscalation()"
        >
          <b>SR Connect Request</b>
        </vs-button> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <vs-button radius color="warning" type="border" icon="chat" @click="editPOA()"></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gap" v-if="Object.keys(otb_exam_dates).length > 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <b style="color: gray; font-size: 12px">Evaluation</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
        v-for="(Otb_exam_date,index) in otb_exam_dates" :key="index">
        <b style="color: gray; font-size: 12px">{{subjectId(Otb_exam_date.subject_id)}}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap" v-if="Object.keys(otb_exam_dates).length > 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <b style="color: gray; font-size: 12px">Date </b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
        v-for="(Otb_exam_date,index) in otb_exam_dates" :key="index">
        <b style="color: gray; font-size: 12px">{{ Otb_exam_date.date_of_exam }}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <b style="color: gray; font-size: 12px">Transcripts : {{ studentDetails.cpa.transcript }}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap" v-if="Object.keys(lms_dates).length > 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Classes Attended:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
        v-for="(Otb_exam_date,index) in otb_exam_dates" :key="index">
        <vs-chip color="dark">
          <b
            @click="lmsCount(getClassAttend(Otb_exam_date.subject_id))">{{getClassAttend(Otb_exam_date.subject_id)}}</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <vs-row class="gap" v-if="Object.keys(Otb_attempts).length > 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">OTB Attempt:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
        v-for="(Otb_exam_date,index) in otb_exam_dates" :key="index">
        <vs-chip color="dark">
          <b>{{getAttendedeData(Otb_exam_date.subject_id)}}</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <POAEdit v-on:poa-updated="udatePOA" />
    <vs-popup class="holamundo popup-inside" title="Classes Attended" :active.sync="open">
      <vs-table v-if="open" :data="attended">
        <!-- <vs-table> -->
        <template slot="thead">
          <vs-th>
            Webinar Id
          </vs-th>
          <vs-th>
            Topic
          </vs-th>
          <vs-th>
            Batch Id
          </vs-th>
          <vs-th>
            Subject Id
          </vs-th>
          <vs-th>
            Vendor
          </vs-th>
          <vs-th>
            Atteandance
          </vs-th>
          <vs-th>
            Webinar Date
          </vs-th>
          <vs-th>
            Type
          </vs-th>
          <vs-th>
            Start Time
          </vs-th>
          <vs-th>
            Time Duration
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              {{ tr.webinar_id }}
            </vs-td>
            <vs-td>
              {{ tr.topic }}
            </vs-td>
            <vs-td>
              {{ tr.batch_id }}
            </vs-td>
            <vs-td>
              {{ tr.subject_id }}
            </vs-td>
            <vs-td>
              {{ tr.vendor }}
            </vs-td>
            <vs-td>
              {{ tr.atteandance }}
            </vs-td>
            <vs-td>
              {{ tr.webinar_date }}
            </vs-td>
            <vs-td>
              {{ tr.type }}
            </vs-td>
            <vs-td>
              {{ tr.start_time }}
            </vs-td>
            <vs-td>
              {{ tr.duration_in_time }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import axios from 'axios';
import EventBus from "../../../../eventbus";
import POAEdit from "../../../../pagesComponents/POAEdit";
export default {
  data() {
    return {
      otb_exam_dates: [],
      Otb_attempts: [],
      lms_dates: [],
      attended: [],
      open: false,
    }
  },
  props: {
    studentDetails: {
      required: true,
      type: Object
    }
  },
  components: {
    POAEdit
  },
  mounted() {
    console.log("studentDetails", this.studentDetails);
    this.getOtbExam();
    this.getOtbAttempt();
    this.getAttendLMS();
  },
  methods: {
    getOtbExam() {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 1000)
      axios.get(`https://miles-sr.2x2.ninja/api/otbExamDate`, { params: { mwb_id: this.studentDetails.mwb.id }, }).then((response) => {
        console.log("otbExamDate", response)
        this.otb_exam_dates = response.data
      })
        .catch((error) => {
          this.$vs.notify({
            title: 'error',
            text: error,
            color: "danger",
          });
        });
    },
    getOtbAttempt() {
      axios.get(`https://miles-sr.2x2.ninja/api/otbAttempt`, { params: { mwb_id: this.studentDetails.mwb.id }, }).then((response) => {
        console.log("otbAttempt", response)
        this.Otb_attempts = response.data
      })
        .catch((error) => {
          this.$vs.notify({
            title: 'error',
            text: error,
            color: "danger",
          });
        });
    },
    getAttendLMS() {
      var course = this.studentDetails.mwb.courses
      if (course.includes("CPA")) {
        course = "CPA"
      }
      if (course.includes("CMA")) {
        course = "CMA"
      }
      axios.get(`https://miles-sr.2x2.ninja/api/connectToLMS`, { params: { 'course': course, 'mwb_id': this.studentDetails.mwb.id } }).then((response) => {
          console.log("connectToLMS", response.data)
        if (response.data.message !== "Candidate Not Found") {
          this.lms_dates = response.data.subject_counts.original
          this.attended = response.data.attended
        }
        // else{
        //   this.$vs.notify({
        //     title: 'error',
        //     text: response.data.message,
        //     color: "danger",
        //   });
        // }
      })
        .catch((error) => {
          this.$vs.notify({
            title: 'error',
            text: error,
            color: "danger",
          });
        });
    },
    getClassAttend(val) {
      return this.lms_dates['subject_' + val]
    },
    getAttemptedData(val, cc) {
      return val[cc]
    },
    poaEscalation() {
      EventBus.$emit("poa-escalation", this.studentDetails);
    },
    editPOA() {
      EventBus.$emit("open-edit-POA", this.studentDetails);
    },
    lmsCount(val) {
      if (val != 0) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    udatePOA(cpa) {
      this.studentDetails.cpa = cpa;
    },
    getDate() {
      let date = "";
      this.studentDetails.mwb.enrollments.forEach(enrollment => {
        if (enrollment.course === "CPA") {
          date = enrollment.enrollment_date;
        }
      });
      return this.unixTimestampConvert(date);
    },
    getAttendedeData(id) {
      if (id == 103) { return this.Otb_attempts['FAR'] }
      if (id == 104) { return this.Otb_attempts['REG'] }
      if (id == 101) { return this.Otb_attempts['AUD'] }
      if (id == 102) { return this.Otb_attempts['BEC'] }
    },
    subjectId(id) {

      if (id == 103) { return "FAR" }
      if (id == 104) { return "REG" }
      if (id == 101) { return 'AUD' }
      if (id == 102) { return "BEC" }
    },
    getBatch() {
      let batch = "";
      this.studentDetails.mwb.enrollments.forEach(enrollment => {
        if (enrollment.course === "CPA") {
          batch = enrollment.batch;
        }
      });
      return batch;
    }
  }
};
</script>

<style lang="scss">
.popup-inside {
  .vs-popup {
    width: auto !important
  }
}

.popup-inside {
  z-index: 53002
}
</style>
