<template>
  <div >
    <vs-popup class="holamundo navbar-popup" title="" :active.sync="nav_bar_popup" @close="closePopup"
      style="z-index:52000">
      <vs-row>
        <vs-col vs-w="12" class="navbar-popup-header mb-4">
         <h4> &nbsp;New {{ getTitle(users_data.type) }}</h4>
        </vs-col>
      </vs-row>
      <div class="nav-bar-tab">
        <NetEnquiries :userdata="users_data" @close-navbar-net-enquiries="closePopup" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import NetEnquiries from "./NavbarNetEnquiries.vue";

export default {
  props: ["users_data", "nav_bar_popup"],
  components: {
    NetEnquiries
  },
  methods: {
    closePopup() {
      this.$emit('close-nav-ne-popup')
      localStorage.setItem("ne_popup_permission_to_call",false);
    },
    getTitle(type) {
      if (type != 'mhp') {
        return 'Net Enquiry'  
      } else {
        return 'MHP'
      }
    }
  }
}
</script>

<style>
.nav-bar-tab {
  overflow-y: scroll;
  max-height: 900px;
}

.navbar-popup .vs-popup {
  width: 50% !important;
  height: auto !important;
  border-radius: 10px;
  padding: 1%;
}

.navbar-popup .vs-popup .vs-popup--title {
  display: none;
}

.navbar-popup .vs-popup .vs-popup--header {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
}

.navbar-popup .vs-popup .vs-popup--content {
  background-color: #0044ba;
  border-radius: 12px;
  height:auto
}

.navbar-popup-header {
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccccdd;
  overflow: auto;
  border-radius: 15px;
  background-color: white;
}

@media (max-width: 1650px) {
  .navbar-popup .vs-popup {
    width: 55% !important;
  }
}
@media (max-width: 1500px) {
  .navbar-popup .vs-popup {
    width: 62% !important;
  }
}
@media (max-width: 1425px) {
  .navbar-popup .vs-popup {
    width: 65% !important;
  }
}
</style>