<template>
  <div>
    <vs-row class="mt-5 mb-2">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
        <p style="font-weight: 600;font-size: 14px;line-height: 17.7px;color: #000000;">Course</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <p style="font-weight: 600;font-size: 14px;line-height: 17.7px;color: #000000;">WhatsApp Message</p>
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
        <p style="font-weight: 600;font-size: 14px;line-height: 17.7px;color: #000000;">Course :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <v-select  autocomplete class=" invc-input"
                  v-model="select_course" style="border: 1px solid #0044ba; border-radius: 5px;width:375px;"
                  :options="course_options">
        </v-select>
      </vs-col> -->
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
        <div>
          <v-select autocomplete class=" invc-inputt mt-2" v-model="selected_course_for_message"
            style="border: 1px solid #0044ba; border-radius: 5px;width:8vw;" :options="course_options">
          </v-select>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <vs-textarea class="mytext_area"
          style="font-weight: 500 !important;font-size: 10px !important;border-radius: 15px;font-family: montserrat;min-height: 200px;"
          id="whatsapp_message" v-model="whatsapp_message" />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
        <!-- <vs-select class="w-full" label="WhatsApp Message" v-model="selected_whatsapp_message">
              <vs-select-item :key="index" :value="item.id" :text="item.message"
                v-for="(item, index) in messages" />
            </vs-select> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="8">
        <loading-button data-tooltip="Google Invite" type="icon" id="meet-now-button" :loading="meet_now_loading"
          :classn="'bttn-save'" :typeoff="meetbutton === true &&
          connection_status === true &&
          connected_value != 'Connected / Never call back'" @clicked="meetNowClicked" v-if="meetbutton === true"></loading-button>
        &nbsp;
        <loading-button data-tooltip="Save" type="icon" id="save-button" :loading="save_loading" :classn="'bttn-save'"
          @clicked="addEngagement('save only')"></loading-button>
        &nbsp;&nbsp;

        <loading-button data-tooltip="Save+WA message+Email" type="icon" id="save-and-whatsapp-button"
          :loading="save_and_whatsapp_loading" :classn="'bttn-save2'"
          @clicked="addEngagement('save and whatsapp', whatsapp_message)"></loading-button>
        &nbsp; &nbsp;
        <loading-button data-tooltip="Save+WA message+Email+ Webinar Invite" type="icon" id="all-button"
          :loading="all_loading" :classn="'bttn-save3'" @clicked="addEngagement('all', whatsapp_message)" ></loading-button>
        &nbsp;
      </vs-col>
    </vs-row>
    <!-- Whatsapp Message -->

    <!-- hidden phone number -->
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
        <vs-input class="inputx" id="candidate_phone_number" :value="phone_number" style="visibility: hidden" />
        <vs-button id="send_whatsapp_message_btn" style="visibility: hidden">Send</vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import LoadingButton from "../../utilities/LoadingButton";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../../constants.json";
import EventBus from "../../eventbus.js";
export default {
  props: [
    "mwb_courses",
    "connection_status",
    "phone_number",
    "selected_courses",
    "person_name",
    "connected_value",
    "save_loading",
    "save_and_whatsapp_loading",
    "all_loading",
    "not_connected",
    "meetbutton",
    "whatsapp_button_status",
    "pathway",
    "persona",
    "macc_level",
    "payload"
  ],
  components: {
    LoadingButton,
    "v-select": vSelect,
  },
  data: function () {
    return {
      meet_now_loading: false,
      store_whatsapp_messages: [],
      selected_whatsapp_message: '',
      meet_now_icons: [{ name: "video_call", color: "white" }],
      save_icons: [{ name: "save", color: "white" }],
      save_and_whatsapp_icons: [
        { name: "save", color: "white" },
        { name: "chat", color: "white" },
        { name: "email", color: "white" },
      ],
      all_icons: [
        { name: "save", color: "white" },
        { name: "chat", color: "white" },
        { name: "email", color: "white" },
        { name: "duo", color: "white" },
      ],
      // course_options:[
      //   "CPA",--
      //   "CPA-AA",
      //   "CMA",--
      //   "CFA",--
      //   "FRM",--
      //   "AICPA",--
      //   "MAcc",--
      //   "IIMI-BA",--
      //   "XLRI-HR",--
      //   "IIML-FT"--
      // ],
      course_priority: [],
      course_options: [],
      selected_course_for_message: "",
      whatsapp_message: "",

      whatsapp_messages: {
        MeetNow: `Hi {{Name}},

          Please click here to connect on Google Meet - {{Google Meet link}}

          Cheers,
          {{SPOC Name}}
          Miles Education`,
      },
      messages: [],
      selected_message: '',
      placement_drive_message: '',
    };
  },
  watch: {
    selected_whatsapp_message(val) {
      for (let i = 0; i < this.store_whatsapp_messages.length; i++) {
        const element = this.store_whatsapp_messages[i];
        if (val == element.id) {
          this.whatsapp_message = element.whatsapp_message
        }
      }
    },
    macc_level: function () {
      // const macc_levels = ["U0--","U0","U1","U1+","U2","U3-","U3+","U3++","U3"];
      // if(!macc_levels.includes(val)){
      //   let length = this.mwb_courses.length;
      //   this.selected_course_for_message = this.mwb_courses[length-1];
      //   } else {
      //   this.getWaMessageBasedOnUlevel();
      // }
      this.setWhatsAppMessageBasedOnConnectionStatus();
    },
    connection_status: function () {
      // this.setWhatsAppMessageBasedOnConnectionStatus();
      if (this.pathway != null && this.pathway != "") {
        // this.setWhatsAppMessageBasedOnPathway();
      } else {
        console.log("1")
        // this.setWhatsAppMessage();
      }
    },
    pathway: function () {
      // this.setWhatsAppMessageBasedOnPathway();
    },
    persona: function () {
      // this.setWhatsAppMessageBasedOnPathway();
    },
    selected_course_for_message: function (val) {
      // if(val == 'Placement Drive'){
      //   this.placement_drive_message = this.placement_drive_message.replace(
      //     "{{Name}}",
      //     this.person_name
      //     );
      //     this.placement_drive_message = this.placement_drive_message.replace(
      //       "{{SPOC Name}}",
      //       localStorage.getItem("spoc_name")
      //     );
      //   this.whatsapp_message = this.placement_drive_message;
      // }else{
      if (val != null && val != "") {
        this.setWhatsAppMessageBasedOnSelectedCourse();
      } else if ((val == null || val == "") && (this.pathway != null || this.pathway != "")) {
        // this.setWhatsAppMessageBasedOnPathway();
      } else {
        this.whatsapp_message = '';
      }
    // }

    },
    selected_courses: function () {
      if (this.pathway != null && this.pathway != "") {
        // this.setWhatsAppMessageBasedOnPathway();
      } else {
        console.log("2")
        // this.setWhatsAppMessage();
      }

    },
    connected_value: function () {
      // if(this.connected_value == "Connected / Discussed"){
      //   this.selected_course_for_message = "Placement Drive"
      // }else{
      //   this.selected_course_for_message = '';
      //   this.whatsapp_message = '';
      // }
      // this.getWaMessageBasedOnUlevel();
      this.setWhatsAppMessageBasedOnConnectionStatus();

        },
  },
  mounted() {
    EventBus.$on('select-orientation-session', () => {
      this.selected_course_for_message = 'Miles US Pathway - Orientation Session';
    })
    this.fetchAllMessages();
    // this.getWhatsappMessages();
    console.log("Courses in save engagement",this.selected_courses, this.mwb_courses);
  },
  //change into miles cm back
  methods: {
    // getWhatsappMessages() {
    //   let url = `https://mfcomms.2x2.ninja/api/getSrWhatsappMessages`;
    //   axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {

    //     this.messages = [];
    //     this.store_whatsapp_messages = response.data
    //     for (let i = 0; i < response.data.length; i++) {
    //       const element = response.data[i];
    //       const msg = element.whatsapp_message.slice(0, 20) + "..."
    //       var messages = {
    //         "id": element.id,
    //         "message": msg
    //       }
    //       this.messages.push(messages)
    //     }
    //     console.log("messages", this.messages)
    //     console.log("data", response.data);
    //   })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },
    //  getWhatsappMessages() {
    //    this.store_whatsapp_messages = localStorage.getItem('watsapp_message')
    //    let wa_message = localStorage.getItem('watsapp_message')
    //    let whatsapp_array = [];
    //    whatsapp_array = wa_message
    //    console.log("whatsapp_array", whatsapp_array);
    //    this.messages = [];
    //     for (let i = 0; i < whatsapp_array.length; i++) {
    //       const element = whatsapp_array[i];
    //         const msg = element.whatsapp_message.slice(0, 20) + "..."
    //         console.log("msg", msg);
    //         var messages = {
    //           "id":element.id,
    //           "message":msg
    //         }
    //         this.messages.push(messages)
    //     }
    //    console.log("this.messages", this.messages);

    //     },
    fetchAllMessages() {
      // this.$vs.loading();
      axios
        .get(`${constants.SERVER_API}getWhatsAppMessages`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          }
          const server_data = response.data.whats_app_messages;
          for (let i = 0; i < server_data.length; i++) {
            const course_data = server_data[i];
            if(course_data.course == "Placement Drive"){
              this.placement_drive_message = course_data.connected_message;
            }
            this.whatsapp_messages[course_data.course] = {};
            this.whatsapp_messages[course_data.course].connected =
              course_data.connected_message;
            this.whatsapp_messages[course_data.course].not_connected =
              course_data.not_connected_message;
            let not_included_course = ['Pathway 1 - No work ex - Graduate', 'Pathway 1 - Working Professional', 'Pathway 1 - No work ex - College Student', 'Pathway 2/3/4/5']
            if (!not_included_course.includes(course_data.course)) {
                let obj = {
                  lable: course_data.course,
                  priority: course_data.priority
                }
                this.course_priority.push(obj)
                this.course_options.push(course_data.course);
            }

          }
          if (this.pathway != null && this.pathway != "") {
            console.log("pathway is there")
            // this.setWhatsAppMessageBasedOnPathway();
          } else {
            console.log("3")
          }
          if(!this.getTeam('ExEd'))
          {
            this.setWhatsAppMessageBasedOnConnectionStatus();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getWaMessageBasedOnUlevel(){
      if(this.macc_level == '' || this.macc_level == null ){
            let course = (this.payload.mwb.courses).split(',')
            let last_ele = course.slice(-1).pop()
             console.log("last element", last_ele);
            if(this.course_options.includes(last_ele)){
              this.selected_course_for_message = last_ele;
            }else{
              this.selected_course_for_message = '';
              this.whatsapp_message = "";
            }
          }else{
          if(this.course_options.includes(this.macc_level)){
            this.selected_course_for_message = this.macc_level;
          }else{
            this.selected_course_for_message = '';
            this.whatsapp_message = "";
          }
        }
    },
    meetNowClicked() {
      console.log(
        "meetNowClicked",
        this.whatsapp_message,
        this.whatsapp_messages
      );
      this.meet_now_loading = true;
      this.whatsapp_message = this.whatsapp_messages["MeetNow"].replace(
        "{{Name}}",
        this.person_name
      );
      this.whatsapp_message = this.whatsapp_message.replace(
        "{{SPOC Name}}",
        localStorage.getItem("spoc_name")
      );
      this.whatsapp_message = this.whatsapp_message.replace(
        "{{Google Meet link}}",
        localStorage.getItem("google_meet_link")
      );
      setTimeout(() => {
        let whatsappButton = document.getElementById(
          "send_whatsapp_message_btn"
        );
        whatsappButton.click();
        this.meet_now_loading = false;
        // whatsapp_button_status for stop loading of google invite button at top
        this.whatsapp_button_status();
      }, 1000);
    },

    setWhatsAppMessage() {
      console.log("setWhatsAppMessage --", this.selected_courses, this.selected_course_for_message)

      let cs = "not_connected";
      console.log("fd", cs);
      let course = "";
      if (this.connection_status === true) {
        cs = "connected";
      }

      if (this.selected_courses.length === 0) {
        return;
      }

      this.whatsapp_message = "";

      course = this.getWhatsappEnabledCourseString();

      if (this.course_options.includes(course)) {
        this.selected_course_for_message = course
      }
      // console.log("selected_course", this.selected_course_for_message)


      if (course === "") {
        return;
      }

      console.log("course", course);
      console.log("whatsapp_messages", this.whatsapp_messages);
      console.log("CS", cs);

      // console.log("Message", this.whatsapp_messages[course][cs]);
      this.whatsapp_message = this.whatsapp_messages[course][cs];

      this.whatsapp_message = this.whatsapp_message.replace(
        "{{Name}}",
        this.person_name
      );
      this.whatsapp_message = this.whatsapp_message.replace(
        "{{SPOC Name}}",
        localStorage.getItem("spoc_name")
      );
    },

    setWhatsAppMessageBasedOnPathway() {
      // console.log("setWhatsAppMessageBasedOnPathway --", this.pathway, this.persona)
      // console.log("whatsapp_messages --", this.whatsapp_messages)


      let cs = "not_connected";
      if (this.connection_status === true) {
        cs = "connected";
      }

      if (this.pathway != null && this.pathway != "") {

        let persona_for_pathway_1 = ['No work ex - College Student', 'No work ex - Graduate', 'Working Professional']
        let pathways = ['Pathway 2 - US', 'Pathway 3 - Canada', 'Pathway 4 - US or Canada', 'Pathway 5 - India/US/Canada']

        let course_with_pathway = "";

        if (this.pathway == "Pathway 1 - India" && persona_for_pathway_1.includes(this.persona)) {

          course_with_pathway = "Pathway 1" + " - " + this.persona

          console.log("course_with_pathway 1", course_with_pathway)

          if (this.whatsapp_messages[course_with_pathway] != undefined) {
            this.whatsapp_message = this.whatsapp_messages[course_with_pathway][cs];
            this.selected_course_for_message = ""
          }


        } else if (pathways.includes(this.pathway)) {

          course_with_pathway = "Pathway 2/3/4/5"

          // console.log("course_with_pathway 2", course_with_pathway)


          if (this.whatsapp_messages[course_with_pathway] != undefined) {
            this.whatsapp_message = this.whatsapp_messages[course_with_pathway][cs];
            this.selected_course_for_message = ""
          }
        } else {
          // this.whatsapp_message = ""
          this.selected_course_for_message = ""
          this.setWhatsAppMessage();

        }
      } else {
        this.setWhatsAppMessage();
        // this.whatsapp_message = ""
      }
    },

    whatsappMessageForSpecificLevels(){
      let common_courses = this.course_options.filter(element => this.mwb_courses.includes(element));
          console.log('common_courses2', common_courses);
          const macc_levels = ["U3","U3+","U3++","U5","U6"];
          if(macc_levels.includes(this.macc_level) && this.mwb_courses.includes('USP')){
            this.selected_course_for_message = 'USP';
          }
          else{
            let filtered_common_courses = [];
            common_courses.forEach(ele => {
              if(ele != 'USP'){
                filtered_common_courses.push(ele);
              }
            });
            if(filtered_common_courses.length > 1){
              let filtered_courses = [];
              this.course_priority.forEach(element => {
                if(filtered_common_courses.includes(element.lable)){
                  filtered_courses.push(element);
                }
              });
              const leastPriorityElement = filtered_courses.reduce((min, current) => {
              return min.priority < current.priority ? min : current;
              });
              if(filtered_common_courses.includes(leastPriorityElement.lable)){
                this.selected_course_for_message = leastPriorityElement.lable;
              }
            }
            else if(filtered_common_courses.length == 1){
              this.selected_course_for_message = filtered_common_courses[0];
            }
            else if(filtered_common_courses.length == 0){
              const leastPriorityElement = this.course_priority.reduce((min, current) => {
              return min.priority < current.priority ? min : current;
              });
              this.selected_course_for_message = leastPriorityElement.lable;
            }
          }
    },
    setWhatsAppMessageBasedOnConnectionStatus() {
      this.whatsappMessageForSpecificLevels();
      console.log("setWhatsAppMessageBasedOnConnectionStatus --", this.selected_course_for_message)

      let cs = "";
      let course = "";
      if (this.connected_value == 'Connected / Discussed' || this.connected_value == 'Visit Confirmed' || this.connected_value == 'Visit Completed' || this.connected_value == 'Visit Rescheduled' || this.connected_value == 'Visit Cancelled' || this.connected_value == 'Booked Visit By Mistake') {
        cs = "connected";
      }else if(this.connected_value == 'Not Connected(NL/SO/NR)'){
        cs = "not_connected";
      }else{
        this.whatsapp_message = "";
        this.selected_course_for_message = '';
      }

      // if (this.selected_courses.length === 0) {
      //   return;
      // }

      this.whatsapp_message = "";

      course = this.selected_course_for_message;


      if (course === "") {
        return;
      }

      console.log("course", course);
      console.log("whatsapp_messages", this.whatsapp_messages);
      console.log("CS", cs);

      // console.log("Message", this.whatsapp_messages[course][cs]);
      this.whatsapp_message = this.whatsapp_messages[course][cs];

      this.whatsapp_message = this.whatsapp_message.replace(
        "{{Name}}",
        this.person_name
      );
      this.whatsapp_message = this.whatsapp_message.replace(
        "{{SPOC Name}}",
        localStorage.getItem("spoc_name")
      );
    },

    setWhatsAppMessageBasedOnSelectedCourse() {
      console.log("setWhatsAppMessageBasedOnSelectedCourse --", this.selected_course_for_message)

      let cs = "";
      let course = "";
      if (this.connected_value == 'Connected / Discussed' || this.connected_value == 'Visit Confirmed' || this.connected_value == 'Visit Completed' || this.connected_value == 'Visit Rescheduled' || this.connected_value == 'Visit Cancelled' || this.connected_value == 'Booked Visit By Mistake') {
        cs = "connected";
      }else if(this.connected_value == 'Not Connected(NL/SO/NR)'){
        cs = "not_connected";
      }else{
        cs = "connected";
      }
      console.log("fd", cs);

      // if (this.selected_courses.length === 0) {
      //   return;
      // }

      this.whatsapp_message = "";

      course = this.selected_course_for_message;


      if (course === "") {
        return;
      }

      console.log("course", course);
      console.log("whatsapp_messages", this.whatsapp_messages);
      console.log("CS", cs);

      // console.log("Message", this.whatsapp_messages[course][cs]);
      this.whatsapp_message = this.whatsapp_messages[course][cs];

      this.whatsapp_message = this.whatsapp_message.replace(
        "{{Name}}",
        this.person_name
      );
      this.whatsapp_message = this.whatsapp_message.replace(
        "{{SPOC Name}}",
        localStorage.getItem("spoc_name")
      );
    },


    // getWhatsappEnabledCourseString() {
    //   if (
    //     this.select_course.includes("CPA") &&
    //     this.select_course.includes("CMA")
    //   ) {
    //     return "CPA&CMA";
    //   } else if (this.select_course.includes("CPA")) {
    //     return "CPA";
    //   } else if (this.select_course.includes("CIA")) {
    //     return "CIA";
    //   } else if (this.select_course.includes("CMA")) {
    //     return "CMA";
    //   } else if (this.select_course.includes("CFA")) {
    //     return "CFA";
    //   } else if (this.select_course.includes("FRM")) {
    //     return "FRM";
    //   } else if (this.select_course.includes("MAcc")) {
    //     return "MAcc";
    //   } else if (this.select_course.includes("CISA")) {
    //     return "CISA";
    //   } else if (this.select_course.includes("EA")) {
    //     return "EA";
    //   } else if (this.select_course.includes("CPA-AA")) {
    //     return "CPA-AA";
    //   } else if (this.select_course.includes("IIML-FA")) {
    //     return "IIML-FA";
    //   } else if (this.select_course.includes("IITR-DB")) {
    //     return "IITR-DB";
    //   } else if (this.select_course.includes("IITM-AA")) {
    //     return "IITM-AA";
    //   } else if (this.select_course.includes("IIMK-CX")) {
    //     return "IIMK-CX";
    //   } else if (this.select_course.includes("IITM-FS")) {
    //     return "IITM-FS";
    //   } else if (this.select_course.includes("IITR-CC")) {
    //     return "IITR-CC";
    //   } else if (this.select_course.includes("IIMK-FT")) {
    //     return "IIMK-FT";
    //   } else if (this.select_course.includes("IIML-AB")) {
    //     return "IIML-AB";
    //   } else if (this.select_course.includes("IIML-SH")) {
    //     return "IIML-SH";
    //   } else if (this.select_course.includes("IITJ-DE")) {
    //     return "IITJ-DE";
    //   } else if (this.select_course.includes("XLRI-HR")) {
    //     return "XLRI-HR";
    //   } else if (this.select_course.includes("IIMI-BA")) {
    //     return "IIMI-BA";
    //   } else if (this.select_course.includes("XLRI-DM")) {
    //     return "XLRI-DM";
    //   } else if (this.select_course.includes("IIML-SF")) {
    //     return "IIML-SF";
    //   } else if (this.select_course.includes("IIML-FT")) {
    //     return "IIML-FT";
    //   } else if (this.select_course.includes("IIML-PM")) {
    //     return "IIML-PM";
    //   } else if (this.select_course.includes("IIML-HR")) {
    //     return "IIML-HR";
    //   } else if (this.select_course.includes("IITR-BF")) {
    //     return "IITR-BF";
    //   }  else if (this.select_course.includes("RPO")) {
    //     return "RPO";
    //   } else {
    //     return "";
    //   }
    // },
    getWhatsappEnabledCourseString() {
      console.log("selected_course", this.selected_courses)
      if (
        this.selected_courses.includes("CPA") &&
        this.selected_courses.includes("CMA")
      ) {
        return "CPA&CMA";
      } else if (this.selected_courses.includes("CPA")) {
        return "CPA";
      } else if (this.selected_courses.includes("CMA")) {
        return "CMA";
      } else if (this.selected_courses.includes("FRM")) {
        return "FRM";
      } else if (this.selected_courses.includes("USP")) {
        return "USP";
      } else if (this.selected_courses.includes("CPA-AA")) {
        return "CPA-AA";
      } else if (this.selected_courses.includes("CPA-US")) {
        return "CPA-US";
      }else if (this.selected_courses.includes("CFA")) {
        return "CFA";
      } else if (this.selected_courses.includes("XLRI-HR")) {
        return "XLRI-HR";
      } else if (this.selected_courses.includes("XLRI-SH")) {
        return "XLRI-SH";
      }else if (this.selected_courses.includes("IIML-FT")) {
        return "IIML-FT";
      } else if (this.selected_courses.includes("IIMI-BA")) {
        return "IIMI-BA";
      } else if (this.selected_courses.includes("IIMI-AA")) {
        return "IIMI-AA";
      }else if (this.selected_courses.includes("AICPA")) {
        return "AICPA";
      } else {
        return "";
      }
    },

    addEngagement(mode, whatsapp_message) {
      this.$emit("add-engagement", mode, whatsapp_message);
    },
    // sendWhatsappMessage(whatsapp_message) {
    //   this.$emit("send-whatsapp-message", whatsapp_message);
    // }

  },
};
</script>

<style >
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}
.invc-inputt>.vs__dropdown-menu{
  width:225%;
}

.mytext_area .vs-textarea {
  font-size: 10px !important;
}

.mytext_area .vs-textarea {
  height: 191px;
}

.bttn-save:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding: 5px !important;
}

.bttn-save2:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding: 5px !important;
}

.bttn-save3:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding: 5px !important;
}

.bttn-save {
  height: 34.57px;
  width: 40.37px;
  background: #FFEBD8;
}

.bttn-save2 {
  height: 34.57px;
  width: 128.7px;
  background: #d77fa140;
}

.bttn-save3 {
  height: 34.57px;
  width: 168.7px;
  background: #d8f4facc;
}

.bttn-save,
.bttn-save2,
.bttn-save3 {
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
</style>
