<template>
    <vs-tabs>
            <vs-tab v-show="cpaOnbaord" id="cpaboarddata" label="CPA">
                <div class="con-tab-ejemplo">
                    <cpaboard :cpaData="this.cpa_on_boarditem" :cpaEnrollment="this.cpa_on_enrollment"></cpaboard>
                </div>
            </vs-tab>
            <vs-tab v-show="cmaOnbaord" id="cmaboarddata" label="CMA">
                <div class="con-tab-ejemplo">
                    <cmaon-section :cmaData="this.cma_on_boarditem" :cmaEnrollment="this.cma_on_enrollment"></cmaon-section>
                </div>
            </vs-tab>
        </vs-tabs>
</template>

<script>
import CpaOnboard from "../../onboard/cpaonbaord";
import CmaOnboard from "../../onboard/cmaonboard";
import axios from "axios";
import constants from "../../../../constants.json";
// import EventBus from "../eventbus.js";
export default {
    props: {
        payload: {
            type: Object,
            required: true,
        },
    },
    components: {
        cpaboard: CpaOnboard,
        "cmaon-section": CmaOnboard,
    },
    data() {
        return {
            ActiveNavBarTab: "ABOUT",
            cpaOndata: "",
            cmaOndata: "",
            on_boarditem: null,
            cpa_on_boarditem: {},
            cma_on_boarditem: {},
            cpa_on_enrollment: {},
            cma_on_enrollment: {},
            cmaOnbaord: false,
            cpaOnbaord: false,
        };
    },
    mounted() {
        this.candata = this.payload.mwb.id;
        console.log(this.candata);
        this.getOnbaordData();
    },
    methods: {
        getOnbaordData() {
            let obj = {
                mwb_id: this.candata,
            };
            // console.log(obj);
            let url = `${constants.SERVER_API}getOnBoardDetails`;
            axios
                .get(url, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("onbaord",response);
                    let Onbarddata = response.data.onboard_data;
                    console.log("data", Onbarddata);
                    let enrollmentData = response.data.enrollment_data;
                    console.log("data", enrollmentData);
                    Onbarddata.forEach((element) => {
                        
                        // this.on_boarditem = element
                        if (element.course == "CPA") {
                            // document.getElementById("cpaboarddata").style.display = 'inline';
                            // document.getElementById("cmaboarddata").style.display = 'none';
                            this.cpaOnbaord = true;
                            this.cpa_on_boarditem = element;
                            this.$on("reload-cpaonboard");
                            console.log("CPA data", this.cpa_on_boarditem);
                        }
                        if (element.course == "CMA") {
                            // document.getElementById("cmaboarddata").style.display = 'inline';
                            // document.getElementById("cpaboarddata").style.display = 'none';
                            
                            this.cmaOnbaord = true;
                            this.cma_on_boarditem = element;
                            this.$on("reload-cmaonboard");
                            console.log("CMAdata", this.cma_on_boarditem);
                        }
                    });
                    
                    enrollmentData.forEach((element) => {
                        
                        // this.on_boarditem = element
                        if (element.course == "CPA") {
                            // document.getElementById("cpaboarddata").style.display = 'inline';
                            // document.getElementById("cmaboarddata").style.display = 'none';
                            this.cpa_on_enrollment = element;
                            this.$on("reload-cpaonboard");
                            console.log("CPA data", this.cpa_on_enrollment);
                        }
                        if (element.course == "CMA") {
                            // document.getElementById("cmaboarddata").style.display = 'inline';
                            // document.getElementById("cpaboarddata").style.display = 'none';
                            
                            this.cmaOnbaord = true;
                            this.cma_on_enrollment = element;
                            this.$on("reload-cmaonboard");
                            console.log("CMAdata", this.cma_on_enrollment);
                        }
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
    },
};
</script>