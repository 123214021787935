<template>
  <div>
    <!-- <vs-row style="margin-bottom: 5%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <p class="visit-type">Visit Type :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8.5">
        <v-select
          class="w-full visit-input"
          placeholder="Select"
          v-model="visit_selected"
          :options="visit_types"
        >
        </v-select>
      </vs-col>
    </vs-row> -->
    <vs-row style="margin-bottom: 3%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="2.9" >
          <p class="visit-type">Engagement :</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8.5">
          <vs-textarea class="text-area-visit" v-model="textAre" placeholder="Engagement"/>
        </vs-col>
      </vs-row>

    <!-- <vs-row v-if="checkIfRoleis('cm1')">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 style="color: gray">Escalation:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox color="dark" v-model="escalation_boolean"></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-select
          v-if="escalation_boolean === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="escalation_acads_member"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in escaltionOptions"
          />
        </vs-select>
        <vs-select
          v-if="escalation_boolean === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="escalation_acads_member"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in escaltionOptions"
          />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row v-if="checkIfRoleis('cm1')">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 style="color: gray">Second Level:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox color="dark" v-model="second_visit"></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <vs-select
          v-if="second_visit === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="second_visit_select"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in secondvisitOptions"
          />
        </vs-select>
        <vs-select
          v-if="second_visit === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="second_visit_select"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in secondvisitOptions"
          />
        </vs-select>
      </vs-col>
    </vs-row> -->


    <vs-row style="margin-bottom: 5%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <p class="visit-type">Level :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8.5">
        <v-select
          class="w-full visit-input"
          placeholder="Select"
          v-model="levels"
          :options="labelOptionsvisit"
          :reduce="(option) => option.id"
          :disabled="!(connected === true)"
        >
        </v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9" class="mt-4">
        <p class="visit-type">USP Level :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8.5" class="mt-4">
        <v-select autocomplete class="visit-input" v-model="selected_macc_level" :options="macc_levels" :reduce="(option) => option.id" :disabled="!(connected === true)"></v-select>
      </vs-col>
    </vs-row>

    <vs-row class="mt-10">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <p class="visit-type">Course :</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" v-model="course" color="#0044BA" vs-value="CPA" class="checkboxInput">CPA
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="CPA-AA"
              class="checkboxInput">CPA-AA
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" v-model="course" color="#0044BA" vs-value="CMA" class="checkboxInput">CMA
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="CFA" class="checkboxInput">CFA
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="FRM" class="checkboxInput">FRM
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

      <vs-row class="mt-10">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <!-- <p class="visit-type">Course :</p> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="CPA-US" class="checkboxInput">CPA-US
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row class="mt-4">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="3" vs-w="9">
        <vs-row vs-w="12">
          <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2.4">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="AICPA" class="checkboxInput">AICPA
            </vs-checkbox>
          </vs-col> -->
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="USP" class="checkboxInpSut">USP
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="IIMI-BA" class="checkboxInput">IIMI-BA
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="XLRI-HR" class="checkboxInput">XLRI-HR
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="XLRI-SH" class="checkboxInput">XLRI-SH
            </vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="IIML-FT" class="checkboxInput">IIML-FT
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
     <vs-row class="mt-10">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <!-- <p class="visit-type">Course :</p> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="9">
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox v-if="
              checkIfRoleis('cm1') ||
              checkIfRoleis('ac1') ||
              checkIfRoleis('cm4') ||
              checkIfRoleis('exed2')
            " :disabled="disable" color="#0044BA" v-model="course" vs-value="IIMI-AA" class="checkboxInput">IIMI-AA
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">

      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="2.9">
        <h6 class="visit-type">Follow up Date:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
        <datepicker
          class="visit-date"
          :disabledDates="disabledDates"
          placeholder="Select Date"
          :format="customFormatter"
          v-model="dates"
        ></datepicker>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mt-10">
        <vs-button
          color="#0044ba"
          @click="API_post_add_engagement"
          class="visit-bttn"
          >Submit</vs-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import EventBus from "../../eventbus";
import constants from "../../../../constants.json";
import axios from "axios";
import moment from "moment";
export default {
  props: {
    payload: {
      required: true,
      type: Object,
    },
  },
  components: {
    Datepicker,
    "v-select": vSelect,
    // chatInterface
  },
  mounted() {
    this.connected = true;
    if (
      this.payload.mwb.level === "M4-" ||
      this.payload.mwb.level === "M4" ||
      this.payload.mwb.level === "M5" ||
      this.payload.mwb.level === "M6" ||
      this.payload.mwb.level === "L4-" ||
      this.payload.mwb.level === "L4" ||
      this.payload.mwb.level === "L5" ||
      this.payload.mwb.level === "L6"
    ) {
      this.labelOptions = this.labelOptionsvisit;
    }

    if (this.payload.mwb.level.indexOf("M7") !== -1) {
      this.connected = false;
    }
    if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null) {
      this.changedULevel(this.payload.mwb.iiml_level);
    }else{
      this.selected_macc_level = 'U5';
    }
    this.course = [];
    this.course = this.payload.mwb.courses.split(",");
    this.levels = this.payload.mwb.level;

    // if (
    //   this.payload.mwb.level.includes("1") &&
    //   this.payload.mwb.level.includes("2") &&
    //   this.payload.mwb.level.includes("3") &&
    //   this.payload.mwb.level.includes("4") &&
    //   this.payload.mwb.level.includes("5") &&
    //   this.payload.mwb.level.includes("6") &&
    //   this.payload.mwb.level.includes("7")
    // ) {
    //   if (this.payload.mwb.level.includes("L")) {
    //       this.levels = "M5";
    //     } else {
    //       this.levels = this.payload.mwb.level;
    //     }
    //     // 1;
    //     // this.levels = "M5";
    // }
    // // else {

    // // }
    if(
        this.payload.mwb.level === 'M1' ||
        this.payload.mwb.level === 'M2' ||
        this.payload.mwb.level === 'M3' ||
        this.payload.mwb.level === 'M3++' ||
        this.payload.mwb.level === 'M3+' ||
        this.payload.mwb.level === 'M4' ||
        this.payload.mwb.level === 'M4-' ||
        this.payload.mwb.level === 'M5' ||
        this.payload.mwb.level === 'M6'
    ){
      this.connected = true;
      this.levels = "M5";
    }else {
      if (this.payload.mwb.level.includes("L")) {
          this.connected = true;
          this.levels = "M5";
        } else {
          this.connected = false;
          this.levels = this.payload.mwb.level;
        }
    }
    console.log("levels",this.payload.mwb.level);
    this.disable = false;
    this.connection_status = true;
    this.enrollment = false;
  },
  watch: {
    visit_escalation: function (value) {
      this.escalation_visit = value;
    },
    levels: function (value) {
      console.log(value);
      let limit = 180;
      let suggested = 180;
      const nfds = {
        M6: {
          limit: 14,
          suggested: 7,
        },
        M5: {
          limit: 14,
          suggested: 7,
        },
        M4: {
          limit: 180,
          suggested: 90,
        },
        "M4-": {
          limit: 180,
          suggested: 120,
        },
        "M3++": {
          limit: 14,
          suggested: 1,
        },
        "M3+": {
          limit: 14,
          suggested: 7,
        },
        M3: {
          limit: 30,
          suggested: 14,
        },
        M2: {
          limit: 180,
          suggested: 90,
        },
        M1: {
          limit: 180,
          suggested: 120,
        },
        M7: {
          limit: 90,
          suggested: 90,
        },
        L6: {
          limit: 30,
          suggested: 14,
        },
        L5: {
          limit: 60,
          suggested: 15,
        },
        L4: {
          limit: 120,
          suggested: 30,
        },
        "L4-": {
          limit: 180,
          suggested: 120,
        },
        "L3+": {
          limit: 90,
          suggested: 30,
        },
        L3: {
          limit: 90,
          suggested: 30,
        },
        L2: {
          limit: 120,
          suggested: 30,
        },
        L1: {
          limit: 180,
          suggested: 120,
        },
      };
      limit = nfds[value].limit;
      suggested = nfds[value].suggested;
      this.disabledDates.from = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + limit
      );
      this.disabledDates.to = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.dates = new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + suggested
        )
      );
      // if (value === "M7") {
      //   this.dates = "";
      // }
    },
    select1: function (value) {
      console.log(value);
      let valuetext = value;
      if (valuetext === "Connected / Discussed") {
        this.disable = false;
        if (this.payload.mwb.level.indexOf("M7") !== -1) {
          this.connected = false;
        } else {
          this.connected = true;
        }
        this.connection_status = true;
        this.responseSelection = "new";
        this.textAre = "";
        this.show = true;
      } else if (valuetext === "") {
        this.show = false;
      } else if (valuetext === "Connected / Never call back") {
        this.connection_status = true;
        this.textAre = valuetext;
        this.disable = true;
        this.connected = false;
        this.show = true;
      } else {
        this.disable = true;
        this.connected = false;
        this.generateEngagementText(valuetext);
        this.show = true;
      }
    },
    responseSelection: function (val) {
      switch (val) {
        case "sameResponse":
          this.textAre = this.payload.mwb.engagement_details;
          break;
        case "noResponse":
          this.textAre = "Not Reachable";
          break;
        case "new":
          this.textAre = "";
          break;
      }
    },
    selected_macc_level(val){
      let level_val = 0
      this.usp_level_status = false
     this.macc_levels.forEach(level => {
      if (level.id == this.payload.mwb.iiml_level) {
        level_val = level.level_priority
      }
     });
     this.macc_levels.forEach(level => {
      if (level.id == val && level_val <= level.level_priority) {
        this.usp_level_status = true
        return
      }
     });
    }
  },
  data() {
    return {
      usp_level_status:false,
      visit_selected: "",
      visit_types:["Phone","Office","Video"],
      checkboxdisable: false,
      tagVisible: true,
      netEnquiryDetails: "",
      arr: [
        {
          name: "Connected, Busy",
          value: 0,
          form: "CB",
        },
        {
          name: "Connected, Never call back",
          value: 0,
          form: "CN",
        },
        {
          name: "Connected, Wrong Number",
          value: 0,
          form: "CW",
        },
        {
          name: "Busy",
          value: 0,
          form: "B",
        },
        {
          name: "Not Lifting",
          value: 0,
          form: "NL",
        },
        {
          name: "Not Reachable",
          value: 0,
          form: "NR",
        },
        {
          name: "Disconnected",
          value: 0,
          form: "D",
        },
        {
          name: "Invalid Number",
          value: 0,
          form: "IN",
        },
        {
          name: "Switched Off",
          value: 0,
          form: "SO",
        },
      ],
      indexActive: 0,
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 5),
        from: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 26),
      },
      connected: false,
      escalation_connect: false,
      escalations_visit: false,
      secondvisit_connect: false,
      show: false,
      disable: true,
      options1: [
        { text: "", value: 1 },
        { text: "Connected / Discussed", value: 2 },
        { text: "Connected / Busy", value: 3 },
        { text: "Connected / Never call back", value: 4 },
        { text: "Connected / Wrong Number", value: 4 },
        { text: "Busy", value: 5 },
        { text: "Not Lifting", value: 6 },
        { text: "Not Reachable", value: 7 },
        { text: "Disconnected", value: 8 },
        { text: "Invalid Number", value: 9 },
        { text: "Switched Off", value: 10 },
      ],
      dates: "",
      course: [],
      levels: "",
      textAre: "",
      phone_number_toupdate: "",
      responseSelection: "",
      select1: "",
      latestEngagement: "",
      navTitleName: "",
      allpage: true,
      visitpage: false,
      callpage: false,
      whatsapp_page: false,
      emailpage: false,
      lastengagement: "",
      details: "",
      labelOptionsvisit: [
        { label: "", value: "" },
        // { text: "M9- Alumnus", value: "M9" },
        // { text: "M8 - Cleared some exams", value: "M8" },
        { label: "M7 - Enrolled", id: "M7" },
        { label: "M6 - Visited & Ready to Enroll", id: "M6" },
        { label: "M5 - Visited & Positive", id: "M5" },
        { label: "M4 - Visited but Postponed", id: "M4" },
        { label: "M4- - Visited but not interested", id: "M4-" },
      ],
      labelOptions: [
        { text: "", value: "" },
        // { text: "M7 - Enrolled", value: "M7" },
        // { text: "M6 - Visited & Ready to Enroll", value: "M6" },
        // { text: "M5 - Visited & Positive", value: "M5" },
        // { text: "M4 - Visited but Postponed", value: "M4" },
        // { text: "M4- - Visited but not interested", value: "M4-" },
        { text: "M3++ - Ready to enroll - Not visited", value: "M3++" },
        { text: "M3+ - Called & Coming", value: "M3+" },
        { text: "M3 - Called & Positive", value: "M3" },
        { text: "M2 - Did not Visit & Postponed", value: "M2" },
        { text: "M1 - Did not Visit & not intersted", value: "M1" },
      ],
      escalation_boolean: false,
      escalation_acads_member: "",
      escaltionOptions: [
        { text: "", value: "" },
        { text: "Samiya Zafar", value: 114 },
        // { text: "Seet Mumbai", value: 170 },
        { text: "Etisha", value: 70 },
        { text: "Namitha", value: 82 },
        { text: "Pallavi", value: 87 },
      ],
      second_visit: false,
      second_visit_select: "",
      secondvisitOptions: [
        { text: "", value: "" },
        { text: "Samiya Zafar", value: 114 },
        { text: "Seet Mumbai", value: 170 },
        { text: "Etisha", value: 70 },
        { text: "Namitha", value: 82 },
        { text: "Pallavi", value: 87 },
      ],
      visit_escalation: "",
      escalation_visit: false,
      escaltionvisitOptions: [
        { text: "", value: "" },
        { text: "Esccalation_1", value: "Esccalation_1" },
        { text: "Esccalation_2", value: "Esccalation_2" },
        { text: "Esccalation_3", value: "Esccalation_3" },
        { text: "Esccalation_4", value: "Esccalation_4" },
      ],
      u5_macc_levels: [
        { id: "U5", label: "U5 : Visited and Very Interested", level_priority: 2 },
        { id: "U6", label: "U6 : Visited and Ready to Enroll", level_priority: 2 },
        { id: "U7-", label: "U7- : Application initiated, dropped out", level_priority: 3 },
        { id: "U7R", label: "U7R : Refunded 20k", level_priority: 3 },
        { id: "U7", label: "U7 : Enrolled (20k)", level_priority: 3 },
        { id: "U7+", label: "U7+ : Application Initiated", level_priority: 3 },
        { id: "U8", label: "U8 : Application done, I-20 yet to be received", level_priority: 4 },
        { id: "U8+", label: "U8+ : Conditional Offer Received", level_priority: 4 },
        { id: "U9-", label: "U9- : I-20 received, Dropped Out", level_priority: 4 },
        { id: "U9", label: "U9 : I 20 Rec. Yet to apply for Visa", level_priority: 4 },
        { id: "U9+", label: "U9+ : I 20 Rec. Applied for Visa", level_priority: 4 },
        { id: "U10-", label: "U10- : Visa Received But Dropped out", level_priority: 4 },
        { id: "U10", label: "U10 : Visa received", level_priority: 4 },
        { id: "U11", label: "U11 : IIM Indore", level_priority: 4 },
        { id: "U11+", label: "U11+ : Started program in USA", level_priority: 4 },
        { id: "U12", label: "U12 : Completed program in USA", level_priority: 4 },
      ],
      macc_levels: [
        { id: "U0--", label: "U0-- : Not Eligible Lead", level_priority: 1 },
        { id: "U0", label: "U0 : Not interested in US/Canda", level_priority: -1 },
        { id: "U1", label: "U1 : Interest expressed, dropped out", level_priority: 0 },
        { id: "U1+", label: "U1+ : Interest expressed, yet to graduate", level_priority: 1 },
        { id: "U2", label: "U2 : Interest expressed, in pipeline (warm)", level_priority: 2 },
        { id: "U3-", label: "U3- : Postponed", level_priority: 3 },
        { id: "U3", label: "U3 : Interest expressed, in pipeline (hot)", level_priority: 4 },
        { id: "U3+", label: "U3+ : Zoom Attended(Very Hot)", level_priority: 5 },
        { id: "U3++", label: "U3++ : Ready to Enroll", level_priority: 6 },
        { id: "U4", label: "U4 : Visited but Postpone/Not Interested", level_priority: 7 },
        { id: "U5", label: "U5 : Visited and Very Interested", level_priority: 8 },
        { id: "U6", label: "U6 : Visited and Ready to Enroll", level_priority: 9 },
        { id: "U7-", label: "U7- : Application initiated, dropped out", level_priority: 10 },
        { id: "U7R", label: "U7R : Refunded 20k", level_priority: 11 },
        { id: "U7", label: "U7 : Enrolled (20k)", level_priority: 12 },
        { id: "U7+", label: "U7+ : Application Initiated", level_priority: 13 },
        { id: "U8", label: "U8 : Application done, I-20 yet to be received", level_priority: 14 },
        { id: "U8+", label: "U8+ : Conditional Offer Received", level_priority: 15 },
        { id: "U9-", label: "U9- : I-20 received, Dropped Out", level_priority: 16 },
        { id: "U9", label: "U9 : I 20 Rec. Yet to apply for Visa", level_priority: 17 },
        { id: "U9+", label: "U9+ : I 20 Rec. Applied for Visa", level_priority: 18 },
        { id: "U10-", label: "U10- : Visa Received But Dropped out", level_priority: 19 },
        { id: "U10", label: "U10 : Visa received", level_priority: 20 },
        { id: "U11", label: "U11 : IIM Indore", level_priority: 21 },
        { id: "U11+", label: "U11+ : Started program in USA", level_priority: 22 },
        { id: "U12", label: "U12 : Completed program in USA", level_priority: 23 },
      ],
      selected_macc_level: null,
      typeSelction: "",
      courseSelection: "",
      connection_status: false,
      latestDate: "",
      enrollment: false,
    };
  },
  methods: {
    changedULevel(val){
     let u_levels = ['U0--','U0','U1',"U1+",'U2','U3-','U3','U3+','U3++','U4'];
      if(u_levels.includes(val)){
        this.macc_levels = this.u5_macc_levels
        this.selected_macc_level = 'U5';
      }else{
        this.selected_macc_level = this.payload.mwb.iiml_level;
      }
    },
    checkIfRoleis(role) {
      if (localStorage.getItem("role") === role) {
        return true;
      } else {
        return false;
      }
    },
    getNextDateStatus(payload) {
      console.log(payload.next_call);
      if (payload.next_call === null) {
        this.tagVisible = false;
        return;
      }
      this.tagVisible = true;
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `Waiting - ${this.convertTimestampToDate(payload.next_call)}`;
      } else if (nextCall < today) {
        return `Delay - ${this.convertTimestampToDate(payload.next_call)}`;
      } else {
        return `Today - ${this.convertTimestampToDate(payload.next_call)}`;
      }
    },
    getNextDateColor(payload) {
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `success`;
      } else if (nextCall < today) {
        return `danger`;
      } else {
        return `warning`;
      }
    },
    generateEngagementInfoBadges() {
      if (this.payload.incommunicado_status !== 0) {
        for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
          if (this.payload.engagements[x].connection_status === "CD") {
            break;
          } else {
            this.arr.forEach((ar) => {
              if (this.payload.engagements[x].connection_status === ar.form) {
                ar.value = ar.value + 1;
              }
            });
          }
        }
      }
      // console.log(this.arr);
    },
    generateEngagementText(value) {
      let engagementString = "";
      // console.log(this.payload);
      if (
        this.payload.mwb.engagement_details === null ||
        this.payload.mwb.engagement_details === ""
      ) {
        this.textAre = value;
        this.latestDate = `${this.getData()} - ${value}, |`;
        this.connection_status = false;
      } else {
        if (this.payload.mwb.engagement_details.includes("|")) {
          // console.log("in the if block");
          let index = this.payload.mwb.engagement_details.indexOf("|");
          index = index + 1;
          engagementString = `${this.getData()} - ${value}, | ${this.payload.mwb.engagement_details.substring(
            index
          )}`;
        } else {
          // console.log("in the else block");
          engagementString = `${this.getData()} - ${value}, | ${
            this.payload.mwb.engagement_details
          }`;
        }
        this.textAre = value;
        this.latestDate = engagementString;
        this.connection_status = false;
      }
      console.log(this.latestDate);
    },
    getData() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    customFormatter(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    emailform() {
      EventBus.$emit("open-email-engagement", this.payload);
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    getEscalationStatus() {
      if (this.escalation_boolean) {
        return 1;
      } else {
        return 0;
      }
    },
    getStatus(bool) {
      if (bool) {
        return 1;
      } else {
        return 0;
      }
    },
    async API_post_add_engagement() {
      this.textAre = this.textAre.trim();
      if (this.textAre === "" || this.date === "") {
        this.$vs.notify({
          title: "Fill out the form",
          text: "Please fill out the details in the engagement form",
          color: "danger",
        });
      } else {
        const engagementType = "visit";
        if (this.connection_status === false && engagementType === "call") {
          this.textAre = this.latestDate;
        }
        let timestmp = "";
        // if (this.payload.mwb.level !== "M7") {
        timestmp = new Date(this.dates).getTime() / 1000;
        // }
        // if (timestmp === "" || isNaN(timestmp)) {
        //   this.$vs.notify({
        //     title: "Next call date is required",
        //     text: "Please select a next call date for adding an engagement",
        //     color: "danger"
        //   });
        //   return;
        // }
        let connect_status = "";
        switch (this.select1) {
          case "Connected / Discussed":
            connect_status = "cd";
            break;
          case "Connected / Busy":
            connect_status = "cb";
            break;
          case "Connected / Never call back":
            connect_status = "cn";
            break;
          case "Connected / Wrong Number":
            connect_status = "cw";
            break;
          case "Busy":
            connect_status = "b";
            break;
          case "Not Lifting":
            connect_status = "nl";
            break;
          case "Not Reachable":
            connect_status = "nr";
            break;
          case "Disconnected":
            connect_status = "d";
            break;
          case "Invalid Number":
            connect_status = "in";
            break;
          case "Switched Off":
            connect_status = "so";
            break;
        }
        if (engagementType === "visit") {
          connect_status = "cd";
        }
        // let copyenrollment = "";
        // if (this.enrollment) {
        //   copyenrollment = "Yes";
        // } else {
        //   copyenrollment = "No";
        // }

        let copyenrollment = "";
        if (this.levels === "M7" && this.payload.mwb.level !== "M7") {
          copyenrollment = "Yes";
        } else {
          copyenrollment = "No";
        }

        // console.log(this.enrollment);
        // this.course.forEach((subject, index) => {
        //   if (subject === "None") {
        //     this.course.splice(index, 1);
        //   }
        // });
        let co = this.course.join();
        let url = `${constants.SERVER_API}addEngagement`;
        let obj = {
          can_id: this.payload.mwb.can_id,
          person_id: this.payload.mwb.person_id,
          person_name: this.payload.mwb.person_name,
          escalation_status: this.getStatus(this.escalation_boolean),
          escalated_to: this.escalation_acads_member,
          second_visit_status: this.getStatus(this.second_visit),
          second_visit_to: this.second_visit_select,
          level: this.levels,
          iiml_level: this.selected_macc_level,
          courses: co,
          details: this.textAre,
          type: "visit",
          acads: 0,
          next_call: timestmp,
          update_call_log: "Yes",
          connection_status: connect_status.toUpperCase(),
          enrollment: copyenrollment,
        };
        if (obj.iiml_level == null){
          obj.iiml_level = this.payload.mwb.iiml_level
        }
        console.log(obj);
        if (this.usp_level_status) {
         this.$vs.loading();
        // return;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.$vs.loading.close();
            console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              if (this.$route.path === "/queue") {
                EventBus.$emit("update-queue");
              }
              EventBus.$emit("close-big-popup");
            }
          })
          .catch((error) => {
             this.$vs.loading.close();
            this.handleError(error);
          });
        }
        else{
          this.$vs.notify({
          title: "Not allowed ",
          text: "Sorry you can't downgrade the level",
          color: "danger",
        });
        }
      }
    },
    navSettings(text) {
      this.navTitleName = text;
      this.escalation_boolean = false;
      this.escalation_acads_member = "";
      this.second_visit = false;
      this.second_visit_select = "";
      switch (text) {
        case "All":
          this.indexActive = 0;
          this.allpage = true;
          this.callpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Call":
          this.indexActive = 1;
          this.textAre = "";
          this.select1 = "";
          this.levels = this.payload.level;
          this.enrollment = false;
          this.callpage = true;
          this.allpage = false;
          this.visitpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          break;
        case "Visit":
          this.indexActive = 2;
          this.textAre = "";
          this.enrollment = false;
          this.visitpage = true;
          this.disable = false;
          this.connected = true;
          this.connection_status = true;
          this.allpage = false;
          this.callpage = false;
          this.emailpage = false;
          this.whatsapp_page = false;
          if (
            !this.payload.level.includes("4") &&
            !this.payload.level.includes("5") &&
            !this.payload.level.includes("6") &&
            !this.payload.level.includes("7")
          ) {
            1;
            this.levels = "M5";
          } else {
            if (this.payload.level.includes("L")) {
              this.levels = "M5";
            } else {
              this.levels = this.payload.level;
            }
          }
          break;
        case "Email":
          this.indexActive = 3;
          this.emailpage = true;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          this.whatsapp_page = false;
          break;
        case "Whatsapp":
          this.indexActive = 4;
          this.whatsapp_page = true;
          this.emailpage = false;
          this.visitpage = false;
          this.allpage = false;
          this.callpage = false;
          break;
      }
    },
  },
};
</script>

<style>
.text-area-visit{
  width: 498.13px;
  height: 121.46px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.visit-type {
  font-family: "montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.7px;
  color: #000000;
}
.visit-input > .vs__dropdown-toggle{
  border: 1px solid #0044BA;
  border-radius: 10px;
  width:225px
}
.visit-input .vs-input--input:focus{
  border: 1px solid #cccccc !important
}
.visit-input>.vs__dropdown-menu{
  border: 1px solid #cccccc !important;
  /* width:150% */
}
.visit-input>.vs__dropdown-menu .vs__dropdown-option{
  font-size: 1rem;
}
.visit-input .vs__dropdown-toggle .vs__selected-options .vs__selected{
  font-size: 12px !important;
}
.visit-bttn{
  width: 129.65px;
  height: 36.76px;
  border-radius: 10px;
}
.visit-date input{
  border-radius: 10px !important;
}
</style>
