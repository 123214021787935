<template>
  <vs-popup id="receiptpopup" class="holamundo" title="Add Receipt" :active.sync="open">
    <div v-if="open">
      <vs-row style="margin-bottom: 5%" v-if="showReceiptAdding">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <b style="color: gray; margin-right: 2%">
            Adding receipt for {{ enrollmentData.person_name }} whose Can ID is
            {{ enrollmentData.identity }} for {{ enrollmentData.type }} {{ enrollmentData.name }}.
          </b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 5%" v-if="showReceiptAdding">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <v-select
            style="width: 240px; z-index: 1000"
            placeholder="Type"
            v-model="transaction_type"
            :options="list_of_transactions"
          ></v-select>
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 5%" v-if="showNEFTSection">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            style="width: 244px; margin-top: -3%"
            v-model="transaction_id"
            placeholder="Transaction ID"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%" v-if="showReceiptDate">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            onkeypress="if(this.value.length==6) return false;"
            min="0"
            v-model="amount"
            type="number"
            placeholder="Amount"
            style="width: 244px; margin-top: -3%"
          />
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-bottom:3%" v-if="showReceiptDate">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input v-model="ReceiptNumber" placeholder="Receipt Number" style="width: 244px;" />
        </vs-col>
      </vs-row> -->
      <vs-row style="margin-bottom: 3%" v-if="showOtherSection">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            v-model="onlineId"
            placeholder="Online Transaction ID"
            style="width: 244px"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%" v-if="showChequeSection || showpdcSection">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-input
            v-if="showChequeSection"
            v-model="cheque_no"
            placeholder="Cheque No"
            style="width: 244px"
          />
          <vs-input
            v-if="showpdcSection"
            v-model="pdcChequeNo"
            placeholder="Cheque No"
            style="width: 244px"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom: 3%" v-if="showReceiptDate">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <datepicker
            v-if="showChequeSection"
            id="datepickered"
            class="datepicker datedot"
            placeholder="Date"
            :disabled-dates="disabledDatesCheque"
            v-model="pdc_date"
            style="width: 244px; z-index: 1000"
          ></datepicker>
          <datepicker
            v-if="showpdcSection"
            id="datepickered"
            class="datepicker datedot"
            width="280"
            style="width: 244px; z-index: 1000"
            :disabled-dates="disabledDatesPdcCheque"
            placeholder="PDC Date"
            v-model="pdcActualDate"
          ></datepicker>
        </vs-col>
      </vs-row>
      <vs-row v-if="showReceiptDate">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <vs-button
            style="margin-left: 2%; width: auto; float: right; left: 200px"
            color="dark"
            size="medium"
            type="border"
            @click="addReceiptToEnrollment()"
            >Add Receipt</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-top: 2%" v-if="showError === true">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b style="color: gray">Couldn't find any enrollments for this candidate ID.</b>
      </vs-col>
      </vs-row>-->
      <!-- <vs-row
      style="margin-top: 2%"
      v-if="showError === false && addReceipt === false && searched === true"
      ></vs-row>-->
    </div>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  components: {
    vSelect,
    Datepicker,
  },
  watch: {
    transaction_type(value) {
      switch (value) {
        case "":
          this.refresh();
          this.showReceiptDate = false;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "CASH":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "ADJ-":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "LOAN":
          this.refresh();
          this.showReceiptDate = true;
          this.transaction_id = "";
          this.showNEFTSection = true;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "SUBVENTION":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "ADJ+":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "TDS":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;

        case "REFUND":
          this.refresh();
          this.showReceiptDate = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;

        case "NEFT":
          this.refresh();
          this.showReceiptDate = true;
          this.transaction_id = "";
          this.showNEFTSection = true;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "CHEQUE":
          this.refresh();
          this.pdc_date = "";
          this.cheque_no = "";
          this.showChequeSection = true;
          this.showNEFTSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          this.showReceiptDate = true;
          break;
        case "ONLINE (CC)":
          this.refresh();
          this.otherType = "";
          this.otherid = "";
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = true;
          this.showpdcSection = false;
          this.showReceiptDate = true;
          break;
        case "PDC":
          this.refresh();
          this.otherType = "";
          this.otherid = "";
          this.showpdcSection = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showReceiptDate = true;
      }
    },
  },
  mounted() {
    EventBus.$on("open-AddEnrollment", (payload) => {
      console.log("open-AddEnrollment", payload);
      this.enrollmentData = payload;
      // console.log(payload);
      this.transaction_type = "NEFT";
      this.refresh();
      this.open = true;
    });
    // document.getElementById("receiptpopup").children[1].style.width = "80%";
    this.showReceiptAdding = true;
    if (localStorage.getItem("role") === "cm1") {
      this.list_of_transactions = ["NEFT", "CASH", "CHEQUE", "ONLINE (CC)", "PDC"];
    }
  },
  data() {
    return {
      open: false,
      disabledDatesCheque: {
        to: "",
        from: "",
      },
      disabledDatesPdcCheque: {
        to: "",
        from: "",
      },
      selectedCourse: "",
      enrollments: [],
      amount_paid: "",
      pending_amount: "",
      popupwidth: "40%",
      rawdata: [],
      canid: "",
      otherType: "",
      otherid: "",
      ReceiptNumber: "",
      showpdcSection: false,
      showOtherSection: false,
      selectedData: [],
      searched: false,
      identityCityCode: "",
      onlineId: "",
      pdcChequeNo: "",
      pdcActualDate: "",
      registeredSpoc: 0,
      showError: false,
      addReceipt: false,
      list_of_transactions: [
        "NEFT",
        "CASH",
        "CHEQUE",
        "ONLINE (CC)",
        "PDC",
        "REFUND",
        "TDS",
        "ADJ-",
        "ADJ+",
        "LOAN",
        "SUBVENTION",
      ],
      transaction_type: "",
      transaction_id: "",
      cheque_no: "",
      pdc_date: "",
      cc_machine_tid_no: "",
      amount: "",
      enrollmentData: "",
      showReceiptAdding: false,
      showNEFTSection: false,
      showChequeSection: false,
      showReceiptDate: false,
      showLoanSection: false,
      showSubventionSection: false,
    };
  },
  methods: {
    refresh() {
      this.transaction_id = "";
      this.cheque_no = "";
      this.pdc_date = "";
      this.pdcChequeNo = "";
      this.amount = "";
      this.ReceiptNumber = "";
      this.cc_machine_tid_no = "";
    },
    /* addReceiptToEnrollment() {
      if (this.checkParameters()) {
        let obj = {
          transaction_type: this.transaction_type,
          transaction_id: this.transaction_id,
          cheque_no: this.cheque_no,
          cheque_date: this.datatoTimestamp(this.pdc_date),
          pdc_no: this.pdcChequeNo,
          // pdc_date: this.datatoTimestamp(this.pdcActualDate),
          cc_machine_tid_no: this.cc_machine_tid_no,
          amount: this.amount,
          receipt_no: this.ReceiptNumber,
          batch: this.enrollmentData.batch,
          identity: this.enrollmentData.identity,
          city: this.enrollmentData.city,
          person_id: this.enrollmentData.person_id,
          person_name: this.enrollmentData.person_name,
          enrollment_id: this.enrollmentData.id,
          course: this.enrollmentData.course
        };
        if (this.transaction_type === "ONLINE (CC)") {
          obj.transaction_type = "ONLINE";
          obj.transaction_id = this.onlineId;
        } else if (this.transaction_type === "PDC") {
          obj.cheque_date = this.datatoTimestamp(this.pdcActualDate);
        } else if (this.transaction_type === "CHEQUE") {
          obj.cheque_date = this.datatoTimestamp(this.pdc_date);
        } else if (
          this.transaction_type === "CANCL" ||
          this.transaction_type === "REFUND" ||
          this.transaction_type === "ADJ-"
        ) {
          obj.amount = -1 * obj.amount;
        }
        console.log(obj);
        axios
          .post(`${constants.SERVER_API}addReceipt`, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            console.log(response);
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Successfully added a receipt",
                text: "Receipt's been added to this enrollment",
                color: "success"
              });
              EventBus.$emit("reload-receipts");
              this.open = false;
            } else {
              this.$vs.notify({
                title: response.data.status,
                text: response.data.message,
                color: "danger"
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    }, */
    addReceiptToEnrollment() {
      if (this.checkParameters()) {
        let obj = {
          transaction_type: this.transaction_type,
          transaction_id: this.transaction_id,
          cheque_no: this.cheque_no,
          cheque_date: this.datatoTimestamp(this.pdc_date),
          pdc_no: this.pdcChequeNo,
          // pdc_date: this.datatoTimestamp(this.pdcActualDate),
          cc_machine_tid_no: this.cc_machine_tid_no,
          amount: this.amount,
          receipt_no: this.ReceiptNumber,
          batch: this.enrollmentData.batch,
          identity: this.enrollmentData.identity,
          city: this.enrollmentData.city,
          person_id: this.enrollmentData.person_id,
          person_name: this.enrollmentData.person_name,
          enrollment_id: this.enrollmentData.enrollment_id,
          purchase_id: this.enrollmentData.id,
          course: this.enrollmentData.name,
        };
        if (this.transaction_type === "ONLINE (CC)") {
          obj.transaction_type = "ONLINE";
          obj.transaction_id = this.onlineId;
        } else if (this.transaction_type === "PDC") {
          obj.cheque_date = this.datatoTimestamp(this.pdcActualDate);
        } else if (this.transaction_type === "CHEQUE") {
          obj.cheque_date = this.datatoTimestamp(this.pdc_date);
        } else if (
          this.transaction_type === "CANCL" ||
          this.transaction_type === "REFUND" ||
          this.transaction_type === "ADJ-"
          // this.transaction_type === "SUBVENTION"
        ) {
          obj.amount = -1 * obj.amount;
        }
        // if (this.transaction_type === "SUBVENTION") {
        //   axios
        //     .post(
        //       `https://accounts.milesforce.com/api/addSubventionAmountCreditNote?mwb_id=${this.enrollmentData.mwb_id}&subvention_amount=${this.amount}`,
        //       {}
        //     )
        //     .then((response) => {
        //       console.log(response);
        //     })
        //     .catch((error) => {
        //       this.handleError(error);
        //     });
        // }

        console.log(obj);
        this.$vs.loading();
        axios
          .post(`${constants.SERVER_API}addReceiptForPurchase`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Successfully added a receipt",
                text: "Receipt's been added to this enrollment",
                color: "success",
              });
              EventBus.$emit("reload-receipts");
              this.open = false;
            } else {
              this.$vs.notify({
                title: response.data.status,
                text: response.data.message,
                color: "danger",
              });
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.handleError(error);
          });
      }
    },
    checkZeroValue() {
      // let bool = false;
      // let receipt_number = this.ReceiptNumber;
      // receipt_number = receipt_number.replace(/[^0]/g, "").length;
      // if (receipt_number === this.ReceiptNumber.length) {
      //   bool = true;
      // }
      // console.log(bool);
      return false;
    },
    checkParameters() {
      // if (this.checkZeroValue()) {
      //   // return true;
      //   this.$vs.notify({
      //     title: "Enter a valid receipt number",
      //     text: "A valid receipt number is required for adding a receipt",
      //     color: "danger",
      //   });
      //   return false;
      // }
      if (
        this.transaction_type === "CASH" ||
        this.transaction_type === "TDS" ||
        this.transaction_type === "REFUND" ||
        this.transaction_type === "ADJ-" ||
        this.transaction_type === "ADJ+" ||
        this.transaction_type === "SUBVENTION"
      ) {
        if (this.amount !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter an amount",
            text: "Enter an amount to continue",
            color: "danger",
          });
          return false;
        }
      } else if (this.transaction_type === "NEFT" || this.transaction_type === "LOAN") {
        if (this.amount !== "" && this.transaction_id !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger",
          });
          return false;
        }
      } else if (this.transaction_type === "CHEQUE") {
        if (this.amount !== "" && this.cheque_no !== "" && this.pdc_date !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger",
          });
          return false;
        }
      } else if (this.transaction_type === "ONLINE (CC)") {
        if (this.amount !== "" && this.onlineId !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger",
          });
          return false;
        }
      } else if (this.transaction_type === "PDC") {
        if (this.amount !== "" && this.pdcChequeNo !== "" && this.pdcActualDate !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger",
          });
          return false;
        }
      }
      return false;
    },
    enrollmentReceiptAdding(item) {
      this.enrollmentData = item;
      this.showReceiptAdding = true;
    },
    receiptadding() {
      if (this.rawdata.length === 0) {
        this.$vs.notify({
          title: "Search for a lead",
          text: "Search for a lead to a receipt",
          color: "warning",
        });
        return;
      } else {
        this.addReceipt = true;
      }
    },
    searchCanid(payload) {
      this.popupwidth = "80%";
      this.searched = true;
      this.addReceipt = false;
      let canid = payload;
      let url = `${constants.SERVER_API}searchEnrollmentWithCanId`;
      let params = {
        identity: canid,
      };
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.length === 0) {
            this.showError = true;
          } else {
            this.showError = false;
            this.rawdata = [];
            this.enrollments = [];
            this.selectedCourse = "";
            response.data.forEach((enrollment) => {
              this.amount_paid = enrollment.amount_paid;
              this.pending_amount = enrollment.amount_pending;
              this.rawdata.push(enrollment);
              this.enrollments.push(enrollment.course);
            });
            this.rawdata.forEach((enrollment) => {
              if (enrollment.course === "CPA") {
                this.selectedData = enrollment.receipts;
              }
            });
            this.selectedCourse = this.enrollments[0];
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.registeredSpoc = response.data.logged_in_user_id;
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.registeredSpoc) {
              this.identityCityCode = spoc.city.charAt(0);
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
#datepickered {
  width: 244px;
  right: 0px;
}
.divied {
  /* width: 100%; */
  /* position: relative; */
  /* display: block; */
  margin: 7px 0;
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
</style>
