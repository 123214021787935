<template>
  <div>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
        style="height: 100px"
      >
        <vs-navbar
          text-color="black"
          color="#ffffff"
          active-text-color="#FFFFFF"
          class="myNavbar"
          v-model="ActiveNavBarTab"
        >
          <vs-navbar-item index="Application Status" class="nav_item">
            <a>Application Status</a>
          </vs-navbar-item>
          <vs-navbar-item index="Application Responses" class="nav_item">
            <a>Application Responses</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-show="ActiveNavBarTab === 'Application Status'" style="width: 100%">
      <loan-application-status
        v-if="ActiveNavBarTab === 'Application Status'"
        :studentDetails="studentDetails"
      ></loan-application-status>
    </div>
    <div
      v-show="ActiveNavBarTab === 'Application Responses'"
      style="width: 100%"
    >
      <loan-application-failures
        v-if="ActiveNavBarTab === 'Application Responses'"
        :studentDetails="studentDetails"
      ></loan-application-failures>
    </div>
  </div>
</template>

<script>
import LoanApplicationFailures from "./LoanApplicationFailures.vue";
import LoanApplicationStatus from "./LoanApplicationStatus.vue";
export default {
  components: {
    "loan-application-failures": LoanApplicationFailures,
    "loan-application-status": LoanApplicationStatus,
  },
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      ActiveNavBarTab: "Application Status",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
