<template>
  <div>
    <!-- <div v-show="play_audio" style="position:fixed;z-index:20000;top:30%;left:39%;">
        <div id="mydiv">
        <div id="mydivheader">
          <vs-row class="py-1">
            <vs-col vs-type="flex" vs-w="11" vs-align="center" style="padding:0px;">
               <p class="ml-2" style="font-size: 1.2rem;">{{Person_name}} - {{contact_type}} - {{directory}}</p>
            </vs-col>
            <vs-col vs-type="flex" vs-w="1" vs-align="center" vs-justify="flex-end" style="padding:0px;">
              <vs-button color="primary" size="small" type="border" @click="play_audio = false" icon="cancel"></vs-button>
            </vs-col>
          </vs-row>
        </div>
        <div style="margin: 10px;">
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
                {{ call_time }}
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
              <b>
                {{ contact_number }} (
                {{ Person_name }} )
              </b>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
              <audio id="my-audio" controls >
                <source :src="audiolink" type="audio/mpeg"  />
              </audio>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="12" style="text-align:center">
              <vs-button size="small" color="transparent" @click="backward()">
                <vs-icon icon="replay_10" color="black" size="medium"></vs-icon>
              </vs-button>
              <vs-button size="small" color="transparent" @click="forward()">
                <vs-icon icon="forward_10" color="black" size="medium"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div> -->
    <!-- <audio id="my-audio">
      <source :src="audioLink" type="audio/mpeg" />
    </audio> -->
    <vx-card actionable class="cardx" title="Call Recordings">
      <vs-table max-items="10" :data="users">
        <template slot="thead">
          <!-- <vs-th>Person Id</vs-th> -->
          <vs-th>Spoc</vs-th>
          <vs-th>Team</vs-th>
          <vs-th>City</vs-th>
          <vs-th>Time</vs-th>
          <vs-th>Directory</vs-th>
          <vs-th>Call Duration</vs-th>
          <vs-th>Phone Number</vs-th>
          <vs-th>Playback</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td>{{tr.person_id}}</vs-td> -->

            <vs-td>{{ tr.spoc_name }}</vs-td>
            <vs-td>{{ tr.team }}</vs-td>
            <vs-td>{{ tr.city_classification }}</vs-td>
            <vs-td>{{ getMomentDateFromTimestamp(tr.time) }}</vs-td>

            <vs-td>{{ tr.directory }}</vs-td>

            <vs-td>{{ timeConvert(tr.call_duration) }}</vs-td>

            <vs-td>{{ hidePhoneNumber(tr.phone_number) }}</vs-td>

            <vs-td>
              <vs-row>
                <div v-if="tr.call_recording_found !== 0" @click="fetchRecording(tr)">
                  <vs-icon
                    class="clickableIcon"
                    icon="play_arrow"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row style="margin-top: 1%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
          <vs-pagination :total="last_page" v-model="selected_link"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>

    <!-- <vs-popup class="holamundo" :title="selectedPayload.contact_type" :active.sync="open">
      <vs-alert :active="errorshow" color="danger" icon="new_releases" v-if="open">
        <span>{{ info }}</span>
      </vs-alert>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="4">
          <b> Spoc - </b>&nbsp; {{ this.selectedPayload.spoc_name }}
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="4">
          <b>
            {{ getMomentDateFromTimestamp(this.selectedPayload.time) }}
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="4">
          <b>
            {{ this.selectedPayload.directory }}
          </b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <b>
            {{selectedPayload.phone_number}}
            ({{ this.selectedPayload.person_name }} )
          </b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <audio id="my-audio" controls>
            <source :src="audiolink" type="audio/mpeg" />
          </audio>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <vs-button size="small" color="transparent" @click="backward()">
            <vs-icon icon="replay_10" color="black" size="medium"></vs-icon>
          </vs-button>
          <vs-button size="small" color="transparent" @click="forward()">
            <vs-icon icon="forward_10" color="black" size="medium"></vs-icon>
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup> -->
  </div>
</template>

<script>
import constants from "../../../../constants.json";
import axios from "axios";
import EventBus from "../../eventbus";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      selectedPayload: {},
      audiolink: "",
      errorshow: false,
      info: "",
      date: {
        startDate: "",
        endDate: "",
      },
      directory:"",
      phone_number: "",
      selected_link: 1,
      pause: false,
      last_page: 0,
      user_id:"",
      audio: "",
      selectedSpoc: [],
      loadSpoc: [],
      spocList: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      users: [],
      fromUtil: false,
      searchIn: {},
      rawcities: [],
      identity: "",
      person_id:""
    };
  },
  components: {
    Datepicker,
    DateRangePicker,
    vSelect,
    Treeselect,
  },
  watch: {
    selectedSpoc: function () {
      this.analizePagination();
    },
    selected_link: function () {
      this.generateTableData();
    },
    open: function (val) {
      if (val === false) {
        let audioplayer = document.getElementById("my-audio");
        audioplayer.pause();
        audioplayer.currentTime = 0;
      }
    },
    selectedPayload(val) {
      val.phone_number = this.hidePhoneNumber(val.phone_number);
    }
  },
  mounted() {
    // this.cm2Redirect();
    // this.adminRedirect();
    // this.getSpoc();
    // this.identity = this.studentDetails.mwb.identity;
    this.dragElement();
    this.person_id = this.studentDetails.mwb.person_id;
    this.analizePagination();
    // this.getSpoctree();
    this.date.startDate = new Date().setDate(new Date().getDate() - 1);
    this.date.endDate = new Date().setDate(new Date().getDate() - 1);
  },
  methods: {
    hidePhoneNumber(value){
    //  console.log("val",value)
     var number = value;
     var mobile = ''
     if(number.slice(0,1)=='+'){
       mobile = number.substring(3)
       var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
       return phone;
     }else{
       return number.replace(number.substring(0,6), 'XXXXXX');
     }
    },
    forward() {
      console.log("forward");
      var aud = document.getElementById("my-audio");
      // console.log("aud", vid);
      aud.currentTime += 10;
    },
    backward() {
      console.log("backward");
      var aud = document.getElementById("my-audio");
      aud.currentTime -= 10;
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value / 1000).format("DD-MMM-YYYY HH:mm");
      return dateString;
    },
    getmaxDate() {
      let newdate = new Date(new Date().setDate(new Date().getDate() - 1));
      // newdate = moment.unix(newdate / 1000).format("DD-MM-YYYY");
      // newdate = Date(newdate);
      // console.log(newdate);
      return newdate;
    },
    async buttonVisibility(data) {
      // return true;
      let connected = false;
      let url = `https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/${data.user_id}_${data.time}.mp3`;
      try {
        let response = await axios({ url: url, method: "get" });
        if (response.status === 200) {
          console.log(response.status);
          connected = true;
        }
      } catch (err) {
        console.log(err);
        connected = false;
      }
      return connected;
      // const promise = new Promise((resolve, reject) => {
      //   axios
      //     .get(url)
      //     .then(response => {
      //       console.log("call log", response);
      //       resolve(true);
      //     })
      //     .catch(error => {
      //       console.log("got an error", error);
      //       reject(false);
      //     });
      // });
      // return promise;
      // axios
      //   .get(url)
      //   .then(response => {
      //     console.log(response);
      //     return true;
      //   })
      //   .catch(error => {
      //     return false;
      //   });
    },
    fetchRecording(call_log) {
      // console.log("fetchRecording",call_log);
      this.$vs.loading();
      let obj = {
        call_log_id: call_log.id,
      };
      let url = `${constants.MILES_CRM_DEV}fetchPreSignedUrl`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("fetch call_log", response);
          if (response.data.data !== null) {
            this.playAudio(response.data.data, call_log);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    playAudio(audiofile, call_log) {
      this.audio = new Audio(audiofile);
      this.audio.onerror = function (error) {
        console.log(error);
        this.info = "Cannot find the call";
        this.errorshow = true;
        setTimeout(() => {
          this.open = false;
          this.errorshow = false;
        }, 1000);
      };
      EventBus.$emit("open-call-recording-popup", audiofile, call_log);
      console.log("opening");
      let audioplayer = document.getElementById("my-audio");
      audioplayer.load();
      this.open = true;
      this.$vs.loading.close();
    },
    analizePagination() {
      if (this.selected_link === 1) {
        this.identity = this.studentDetails.mwb.identity;
        this.generateTableData();
      } else {
        this.selected_link = 1;
      }
    },
    dragElement() {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      var elmnt = document.getElementById("mydiv")
      if (document.getElementById(elmnt.id + "header")) {
      document.getElementById(elmnt.id + "header").onmousedown = (e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
        // call a function whenever the cursor moves:
        document.onmousemove = (e)=>{
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        };
          };
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      document.getElementById("mydiv").onmousedown =(e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
      };
    }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    generateTableData() {
      this.$vs.loading();
      let params = {
        person_id: this.person_id,
      };
      // console.log(params);
      let url = `${constants.SERVER_API}getAllCallLogsByPersonId?page=${this.selected_link}`;
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "success") {
            this.handleNotification(response);
            this.$vs.loading.close();
            return;
          }
          response.data.data.data.forEach((each) => {
            each.pause = false;
          });
          this.users = response.data.data.data;
          this.last_page = response.data.data.last_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          // if (error.response) {
          //   // console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
  },
};
</script>

<style>
#div-with-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.vs-radar-text {
  color: darkgray;
  position: relative;
  bottom: 70px;
  left: 35px;
  font-size: larger;
}
.radial_quarter {
  position: relative;
  height: 260px;
}
.lineAreaChartSpline {
  position: relative;
  height: 260px;
}
.vs-radar-thisquarter {
  color: darkgray;
  position: relative;
  bottom: 70px;
  font-size: larger;
  text-align: center;
}
.input-search {
  position: relative;
  right: 10px;
}
.table_heading {
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr {
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
}
.datepicker .vdp-datepicker__calendar {
  z-index: 2000;
}
#spocselect {
  z-index: 2000;
}
.datepicker {
  position: relative;
  /* top: 10px; */
}
.clickableIcon {
  cursor: pointer;
}
</style>
