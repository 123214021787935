<template>
  <div>
    <!-- <vs-row
      class="gaps"
      style="margin-top: 2%"
      v-if="studentDetails.mwb.courses.includes('IIML-PM')"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button color="dark" type="relief" @click="sendIIMLPAInvite()"
          >Send IIML PM Intro Email</vs-button
        >
      </vs-col>
    </vs-row> -->
    <!-- <ne-journey-table></ne-journey-table> -->
      <vs-row class="py-3 ml-2 added_by mb-3">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <p class="text-data" >Lead Added By :</p>
          <p style="margin-left:10px" class="added_data"> {{ studentDetails.mwb.added_by_name != null ? studentDetails.mwb.added_by_name : '' }}</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
          <p  class="text-data" >Added Date:</p>
          <p style="margin-left:10px;" class="added_data"> {{ getCreateAtDate(studentDetails.mwb.created_at) }}</p>
        </vs-col>
      </vs-row>
      <div v-if="studentDetails.upcoming_visits.length >0">
        <div class="py-3 ml-2 added_by mb-3" v-for="(upcoming_visit, i) in studentDetails.upcoming_visits" :key="i" style="background-color : gold;">

          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
              <p class="text-data" >Upcoming Visit :</p>
              <p class="visits_data">{{getEnrolledDate(upcoming_visit.scheduled_date,'time')}}</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
              <p  class="text-data" >Scheduled By :</p>
              <p class="visits_data">{{ upcoming_visit.scheduled_by}}</p>
              <!-- <p class="visits_data" v-else>&nbsp;CLEVERTAP</p> -->

            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
                <div class="custom-tooltip"><vs-button @click="schedule_call_popup=true;schedule_visit_id = upcoming_visit.id" radius color="warning" type="filled" icon="event_note"></vs-button>
                  <span class="tooltiptext">
                    Re-Schedule Visit
                  </span>
                </div>
              </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
              <div class="custom-tooltip"><vs-button @click="open_delete_popup=true;schedule_visit_id = upcoming_visit.id" radius color="danger" type="filled" icon="event_busy"></vs-button>
                  <span class="tooltiptext">
                    Cancel Visit
                  </span>
                </div>
            </vs-col>
          </vs-row>
        </div>
      </div>

      <div class="py-3 ml-2 added_by mb-3" v-if="show_engagements_data">
        <vs-row>
          <vs-col
          v-for="(engament, i) in studentDetails.engagements" :key="i"
            vs-type="flex"
            vs-justify="space-around"
            vs-align="center"
            vs-w="12"
          >
          <vs-row v-if="engament.type == 'visit' && engament.visit_approved == 'Yes'">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <p class="text-data"> Visited Date : <span style="color: #6B6E67; margin-left:10px;">{{ getCreateAtDate(engament.created_at) }}</span></p>

            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
          <p class="text-data">SPOC: <span style="color: #6B6E67; margin-left:10px;">{{ engament.assigned_spoc_name }}</span></p>
            </vs-col>
          </vs-row>
          </vs-col>
        </vs-row>
      </div>

      <div class="py-3 ml-2 added_by mb-3" v-if="show_enrollments_data">
        <vs-row v-if="studentDetails.mwb.enrollments.length > 0">
          <vs-col
            v-for="(data, index) in studentDetails.mwb.enrollments"
            :key="index"
            vs-type="flex"
            vs-justify="space-around"
            vs-align="center"
            vs-w="12"
          >
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <p class="text-data">{{ data.course }} Enrolled By : <span style="color: #6B6E67; margin-left:10px;">{{ data.assigned_to_name }}</span></p>

            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style=" border-left: 2px solid rgba(0, 0, 0, 0.25);">
          <p class="text-data">Enrolled Date: <span style="color: #6B6E67; margin-left:10px;" v-if="data.enrollment_date != null && data.enrollment_date != undefined">{{ getEnrolledDate(data.enrollment_date,'date')}}</span></p>
            </vs-col>
          </vs-row>
          </vs-col>
        </vs-row>
      </div>
    <!-- <enquiries-aging-sources v-if="studentDetails.ne_data.length != 0" :student_ne_data="studentDetails.ne_data">
    </enquiries-aging-sources> -->


    <vs-row style="text-align: center; display : block" class="my-3">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button @click="openConfirm()" color="#6161DC" class="payment_btn" style="width:202px">Send Payment Link</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <!-- <vs-button style="margin-top: 2%"
              v-if="(this.eligibility_report_link != null && this.eligibility_report_link != '') && (this.eligibility_report_link_cpa_us != null && this.eligibility_report_link_cpa_us != '')"
              color="#6161DC" class="payment_btn" @click="sendUSPEligibilityLink()">Send USP Eligibility Link</vs-button>
            <vs-button style="margin-top:2%" v-else color="#6161DC" class="payment_btn"
              @click="sendUSPEligibilityLink()">Send USP Eligibility Link</vs-button> -->
          </vs-col>
        </vs-row>

      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <a v-if="this.eligibility_report_link != null && this.eligibility_report_link != '' && (this.eligibility_report_link_cpa_us == '' || this.eligibility_report_link_cpa_us == null)" style="margin-left: 5%"
              :href="this.eligibility_report_link" target="_blank" rel="noopener noreferrer">
              <vs-button color="#dc6161" class="payment_btn">CPA Eligibility Report
              </vs-button>
            </a>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <a v-if="this.eligibility_report_link_cpa_us != null && this.eligibility_report_link_cpa_us != ''"
              style="margin: 2% 0 0 5%" :href="this.eligibility_report_link_cpa_us" target="_blank"
              rel="noopener noreferrer">
              <vs-button color="#dc6161" class="payment_btn">USP Eligibility Report
              </vs-button>
            </a>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- <div class="boxed"> -->
    <!-- <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b style="font-size: 15px; color: gray">Previous Payment Links</b>
        </vs-col>
      </vs-row> -->
    <vs-row :class="(paymentTable)?'payment-links':'cost_tablee'" vs-w="11.7" v-show="paymentTable" >
      <!-- v-show="paymentTable" -->
      <!-- <vs-table :data="this.PaymentLinks" style="margin-left: 15%">
        <template slot="thead">
          <vs-th> Course </vs-th>
          <vs-th>Agreed Cost</vs-th>
         <vs-th>Payment Links</vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{ data }">
          <tbody ref="tbody">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div>
                  {{ tr.course }}
                </div>
              </vs-td>
              <vs-td>
                <div>{{ tr.agreed_cost }}</div>
              </vs-td>
            <vs-td style="">
                    <div :id="tr.course" :title="tr.payment_link">
                      {{ tr.agreed_cost }}
                    </div>
              </vs-td>
              <vs-td>
                <vs-button size="small" color="primary" @click="resendLink(tr)">Resend Payment Link</vs-button>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table> -->

      <!-- style="
          margin-block: 7px;
          border-left: 1px solid rgba(0, 0, 0, 0.25);
          border-right: 1px solid rgba(0, 0, 0, 0.25);
        " -->
      <vs-row class="mt-3">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p class="table-heading">Course</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p class="table-heading">Agreed Cost</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        </vs-col>
      </vs-row>
      
      <vs-row v-for="(item, index) in PaymentLinks" :key="index">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p style="
              font-weight: 500;
              font-size: 12px;
              line-height: 35px;
              text-align: center;
            ">
            {{ item.course }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" style="
            margin-block: 7px;
            border-left: 1px solid rgba(0, 0, 0, 0.25);
            border-right: 1px solid rgba(0, 0, 0, 0.25);
          ">
          <p style="
              font-weight: 500;
              font-size: 12px;
              line-height: 35px;
              text-align: center;
            ">
            {{ item.agreed_cost }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-button size="small" color="#0044BA" type="border" style="font-size: 10px" @click="resendLink(item)">Resend
            Payment Link</vs-button>
            <vs-button color="success" type="border" id="resend_payment_link" style="visibility: hidden">Link
            </vs-button>
        </vs-col>
      </vs-row>
      <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"
      >
        <div v-if="item.course">
          <p class="table-heading"
          >course:</p>
          <p style="font-weight: 500; font-size: 12px; line-height: 35px; text-align:center">
            {{item.course}}
          </p>
        </div>

      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="4"
        v-for="(item,index) in PaymentLinks" :key="index"
        style="
          margin-block: 7px;
          border-left: 1px solid rgba(0, 0, 0, 0.25);
          border-right: 1px solid rgba(0, 0, 0, 0.25);
        "
      >
      <div v-if="item.agreed_cost">
            <p class="table-heading">Agreed Cost</p>
            <p style="font-weight: 500; font-size: 12px; line-height: 35px; text-align:center">
            {{item.agreed_cost}}
          </p>
          </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <div vs-type="flex" vs-justify="center" vs-align="center"
         v-for="(item,index) in PaymentLinks" :key="index">
          <vs-button size="small" color="#0044BA" type="border" style="font-size: 10px;" @click="resendLink(tr)"
            >Resend Payment Link</vs-button
          >
        </div>
      </vs-col> -->
    </vs-row>
    <vs-row class="gap" v-show="paymentERROR">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        {{ this.errorpayment }}
      </vs-col>
    </vs-row>
    <!-- </div> -->
    <vs-row class="mt-4 pt-4">
        <vs-col  vs-w="4">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="cpa_eligibility"
          >CPA Eligibility</vs-checkbox
        >
        </vs-col>
        <vs-col vs-w="4">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="usp_eligibility"
          >USP Eligibility</vs-checkbox
        >
        </vs-col>
        <vs-col vs-w="4">
          <vs-checkbox
          color="dark"
          class="inline-flex"
          disabled="true"
          v-model="bridge_course_eligibility"
          >Bridge Course Eligibility</vs-checkbox
        >
        </vs-col>
    </vs-row>
    <div class="px-2">
    <vs-collapse class="collapse_card mt-4" v-if='bridge_course_eligibility'>
    <vs-collapse-item>
      <div slot="header">
        Bridge Course Recommended : {{  studentDetails.bridge_course_recommended }}
      </div>
      <vs-row>
        <vs-col vs-w="3" style="padding-left: 0;">
          Electives Choosen : 
        </vs-col>
        <vs-col vs-w="6">
              <div v-for="(elective, i) in studentDetails.recommend_arka_elective_ids" :key="i">
                    <vs-checkbox
                    style="font-size: 12px;"
                    color="dark"
                    class="inline-flex"
                    disabled="true"
                    v-model="electives"
                    >{{ elective }}</vs-checkbox
                  >
                  </div>
        </vs-col>
      </vs-row>
    </vs-collapse-item>
    </vs-collapse>
    </div>
    
    <vs-row>
      <p class="details-title">Details</p>
    </vs-row>

    <vs-row class="gaps" style="margin-top: 3%">
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="11" class="details-title">Details</vs-col> -->
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Name :</h6>
      </vs-col>
      <vs-col v-if="nameEdit === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">
        {{ studentDetails.mwb.person_name }}
      </vs-col>
      <vs-col v-else-if="nameEdit === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">
        <vs-input icon="edit" v-model="editName" />
      </vs-col>
      <vs-col v-if="nameEditbutton === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1"
        class="details-data">
        <vs-button type="flat" style="color: #999999" icon="create" @click="nameEditFunc"></vs-button>
      </vs-col>
      <vs-col v-if="nameEditbutton === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1"
        class="details-data">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editNameAPI"></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" v-if="show_source">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Source :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" class="details-data">{{
    studentDetails.mwb.source
}}
        <span v-if="
  studentDetails.mwb.source != 'N-DB' &&
  studentDetails.mwb.source != 'BTL'
">- {{ studentDetails.mwb.source_details }}</span></vs-col>
    </vs-row>
    <vs-row v-if="studentDetails.business_partner != ''" class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Business Partner :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <h6 class="details-data">{{studentDetails.business_partner}}</h6>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" v-if="getSubTeam('INT')">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Country :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" class="details-data">{{
    studentDetails.mwb.country
}}</vs-col>
    </vs-row>
    <vs-row class="gaps" v-if="getSubTeam('INT')">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">City :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" style="font-size: 15px; color: gray">{{
    studentDetails.mwb.international_city
}}</vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Mobile :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <div v-for="(mob, index) in studentDetails.mobiles" :key="index" style="margin-left: 1%"
          :data-tooltip="mob.masked_number">
          <!-- <span class="tooltiptext" style="left: 100%;">
            <b>{{ mob.masked_number }}</b>
            :type="mob.invalid === 0 ? 'gradient' : 'filled'"
          </span> -->
          <vs-button :color="getMobileColor(mob)" icon="local_phone" type="gradient" @click="mobilePopup(mob)">
          </vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" icon="add_call" style="color: #999999"
          @click="EmitEvent('add-mobile-number', studentDetails.mwb)"></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Email :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <div v-for="(email, indexemail) in studentDetails.emails" :key="indexemail" style="margin-left: 1%"
          :data-tooltip="email.masked_email">
          <!-- <span class="tooltiptext" style="left: -30%;">
            <b>{{ email.masked_email }}</b>
          </span> -->
          <vs-button style="margin-left: 1%" :color="getEmailColor(email)" type="gradient" icon="mail"
            @click="emailPopup(email)"></vs-button>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button style="color: #999999" type="flat" icon="mail" size="large"
          @click="EmitEvent('add-email', studentDetails.mwb)"></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" v-show="show_finance_course">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Finance Courses :</h6>
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start">
        <vs-row style="margin-left: -8px">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" class="mb-3"
            v-for="(item, index) in finance_course_data" :key="index">
            <div class="course-boxing" v-if="
                  checkIfRoleis('cm1') ||
                  checkIfRoleis('ac1') ||
                  checkIfRoleis('cm4')
                ">
              {{ item }}
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" v-show="show_exed_course">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">ExEd Courses :</h6>
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <vs-row style="margin-left: -8px">
          <vs-col vs-type="flex"  vs-w="3" class="mb-3" v-for="(item, index) in exed_course_data"
            :key="index" >
            <div class="course-boxing" v-if="
                checkIfRoleis('cm1') ||
                checkIfRoleis('ac1') ||
                checkIfRoleis('cm4')
              ">
              {{ item }}
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row v-if="studentDetails.ne_data.length != 0" class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Courses Enquired :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <!--<h6
          class="enquired-courses"
          v-for="chip in ne_courses"
          :key="chip.index"
        >
          {{ chip }}
        </h6>  -->
        <vs-row style="margin-left: -8px">
          <vs-col vs-w="3" vs-type="flex" class="mb-3"
            v-for="chip in ne_courses" :key="chip.index">
            <p class="course-boxing">
              {{ chip }}
            </p>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Company :</h6>
      </vs-col>
      <vs-col v-if="companyEdit === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">
        {{ studentDetails.mwb.company }}
      </vs-col>
      <!-- <vs-col v-else-if="companyEdit === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <vs-input icon="edit" v-model="editCompany" />
      </vs-col>
      <vs-col v-if="companyEditbutton === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="create" @click="companyeditfunc"></vs-button>
      </vs-col>
      <vs-col v-if="companyEditbutton === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editCompanyAPI"></vs-button>
      </vs-col> -->
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Experience :</h6>
      </vs-col>
      <vs-col vs-type="flex" class="details-data" vs-justify="flex-start" vs-align="center" vs-w="7">
       {{ studentDetails.mwb.experience_int }}
      </vs-col>
      <!-- <vs-col v-if="exEdit === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7" class="details-data"> -->

      <!-- </vs-col> -->
      <!-- <vs-col v-else-if="exEdit === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <vs-input icon="edit" v-model="editExperience" />
      </vs-col> -->
      <!-- <vs-col v-if="exEditbutton === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="create" @click="exeditfunc"></vs-button>
      </vs-col>
      <vs-col v-if="exEditbutton === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editExperienceAPI"></vs-button>
      </vs-col> -->
      <!-- <vs-col vs-w="7.2">
        <div v-if="experience_alert" class="alert_message">
          {{ experience_alert_message }}
        </div>
      </vs-col> -->
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Graduation Year :</h6>
      </vs-col>
      <vs-col v-if="studentDetails.persona_details" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">
        {{ studentDetails.persona_details.graduation_year }}
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">College :</h6>
      </vs-col>
      <vs-col v-if="collegeEdit === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7" class="details-data">
        {{ studentDetails.mwb.college }}
      </vs-col>
      <vs-col v-else-if="collegeEdit === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <vs-input icon="edit" v-model="edit_college" />
      </vs-col>
       <vs-col v-if="collegeEditbutton === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="create" @click="collegeEditFunc"></vs-button>
      </vs-col>
      <vs-col v-if="collegeEditbutton === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editCollegeAPI"></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Designation :</h6>
      </vs-col>
      <vs-col v-if="designationEdit === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">
        {{ studentDetails.mwb.designation }}
      </vs-col>
      <vs-col v-else-if="designationEdit === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <vs-input icon="edit" v-model="editDesignation" />
      </vs-col>
      <vs-col v-if="designationEditbutton === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="create" @click="designationeditfunc"></vs-button>
      </vs-col>
      <vs-col v-if="designationEditbutton === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editDesignationAPI"></vs-button>
      </vs-col>

    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">CTC :</h6>
      </vs-col>
      <vs-col v-if="show_edit_ctc === false" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7"
        class="details-data">{{ studentDetails.mwb.current_ctc }} &nbsp;&nbsp; Lakhs Per Annum
      </vs-col>
      <vs-col v-else-if="show_edit_ctc === true" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <vs-input icon="edit" v-model="edit_ctc" />
      </vs-col>
      <vs-col v-if="ctc_edit_button === true" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
        <vs-button type="flat" style="color: #999999" icon="create" @click="ctcEditFunction"></vs-button>
      </vs-col>
      <vs-col v-if="ctc_edit_button === false" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1"
        style="font-size: 15px; color: gray">
        <vs-button type="flat" style="color: #999999" icon="done_outline" @click="editCTC"></vs-button>
      </vs-col>
      <vs-col vs-w="7.2">
        <div v-if="current_ctc_alert" class="alert_message">
          {{ current_ctc_alert_message }}
        </div>
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Arcived Education :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8" class="details-data">
        {{ studentDetails.mwb.education }}
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Google Location :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8" class="details-data">
        {{ studentDetails.google_location }}
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 class="details-subhead">Numverify Location :</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8" class="details-data">
        {{ studentDetails.num_verify_location }}
      </vs-col>
    </vs-row>
    <vs-row class="gaps" v-if="studentDetails.ne_data.length != 0 && ne_cities.length != 0">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p class="details-subhead">NE Location:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8" class="details-data">
        <span v-for="ne_city in ne_cities" :key="ne_city">
          {{ ne_city }},
        </span>
      </vs-col>
    </vs-row> -->
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4" class="details-subhead">Educational Tags
        :</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
        <!-- <v-select
          placeholder="Badges"
          multiple
          style="
            z-index: 1000;
            margin: 10px;
            border: 1px solid #0044ba;
            border-radius: 5px;
          "
          class="w-full Education_select"
          v-model="select1"
          :options="options1"
        ></v-select> -->
        <!-- <treeselect v-model="select1" :multiple="true" :options="options1"
          style="border: 1px solid #0044ba;border-radius: 5px;" /> -->
        <!-- <vs-chip
          @click="remove(chip)"
          closable
          style=" width: 79.28px;
          color:black;
          height: 22.65px;
          background: #FFFFFF;
          border: 1px solid #0044BA;
          border-radius: 5px;"
          v-for="chip in select1"
          :key="chip.index"
        >
          <b>{{ chip }}</b>
        </vs-chip> -->
        <!-- <vs-row style="margin-left: -8px"> -->
          <vs-row>
            <vs-col vs-w="12" style="padding:0px;">
             <span v-for="(item, index) in select1" :key="index" >
               <vs-button disabled size="small"  color="rgba(0, 68, 186, 0.5)" class="education_tags"> {{ item }}</vs-button>&nbsp;
             </span>
            </vs-col>
          </vs-row>
          <!-- <div>
             <span class="education_tags" v-for="(item, index) in select1" :key="index"> {{ item }}</span>
          </div> -->
          <!-- <span v-for="(item, index) in select1" :key="index">
              {{ item }}
          </span> -->
        <!-- </vs-row> -->
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button size="small" color="#0044BA" @click="updateCandidatesEducationTags">
          Update
        </vs-button>
      </vs-col> -->
    </vs-row>
    <!-- <vs-row style="margin-top: -5%;">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <v-select
          placeholder="Badges"
          multiple
          style="z-index: 1000;margin: 10px; border: 1px solid #0044BA; border-radius: 5px;"
          class="w-full"
          v-model="select1"
          :options="options1"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="#0044BA"
          @click="updateEducation"
        >
         Update
        </vs-button>
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Eligibility Submitted Documents:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxdisable"
          v-model="submitted_documents"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submit === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="eligibility"
          :options="eligibilityOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="submit === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="eligibility"
          :options="eligibilityOptions"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="eligibility !== ''"
          @click="addEligibility"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row>-->
    <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" class="details-subhead">CPA Eligibility Code
        :</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px; color: gray">{{
    studentDetails.eligibility_code
}}</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1"></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"></vs-col>
    </vs-row>
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" class="details-subhead">USP Eligibility Code
        :</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px; color: gray">{{
    studentDetails.eligibility_code_cpa_us
}}</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1"></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"></vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" class="details-subhead">Eligibility
        Status :</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px; color: gray">
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1"></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"></vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" class="details-subhead">
        Placement Assistance :
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px; color: gray">
        <vs-checkbox :disabled="placement_disable" color="primary" v-model="placement_assistance"></vs-checkbox>
      </vs-col>
    </vs-row> -->
    <!-- <vs-row class="gaps">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" class="details-subhead">
        Big 4 Meet & Greet :
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px; color: gray">
        <vs-checkbox :disabled="meet_and_greet" color="primary" v-model="studentDetails.meet_and_greet"></vs-checkbox>
      </vs-col>
    </vs-row> -->
    <!-- hidden Whatsapp Message -->
    <vs-textarea label="Msg" v-model="payment_link_message" id="payment_link_message" v-show="text_msg" />
    <!-- hidden phone number -->
    <vs-input class="inputx" id="phone_number" :value="phone_number" style="visibility: hidden" />

    <!-- <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Requested For Loan Assistance</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxloandisable"
          v-model="applied_for_loan"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          v-if="submitLoan === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="loan"
          :options="loanOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="submitLoan === true"
          placeholder
          style="margin-top: 4%; margin: 10px"
          class="w-full"
          v-model="loan"
          :options="loanOptions"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-button
          size="small"
          color="danger"
          type="gradient"
          v-if="loan !== ''"
          @click="addLoan"
        >
          <b>Update</b>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Loan Code:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >{{ loanCode }}</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="1"
      ></vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      ></vs-col>
    </vs-row>-->
    <vs-popup id="paymentid" title="Send Payment Link" style="z-index: 53010" :active.sync="activePrompt">
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-radio color="dark" v-model="radios1" vs-value="course"><b>Course Purchase</b></vs-radio>
        </vs-col>

        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-radio color="dark" v-model="radios1" vs-value="Additional Purchase"><b>Additional Purchase</b></vs-radio>
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-radio color="dark" v-model="radios1" vs-value="Miscellaneous"><b>Miscellaneous Purchase</b>&nbsp;&nbsp;<b>(Processing Fee 2500)</b></vs-radio>
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-top: 0%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3"
          style="margin-left: 3%; margin-top: -1%">
          <b>(Processing Fee 2500)</b>
        </vs-col>
      </vs-row> -->
      <vs-row class="gap" style="margin-top: 4%" v-if="this.radios1 === 'course'">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>Course</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-stary" vs-align="center" vs-w="6">
          <v-select placeholder="Courses" style="z-index: 1000; margin-top: 4%; margin: 10px; margin-left: 0%"
            class="w-full" v-model="purchase_courses" :options="purchaseCourseList"></v-select>
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%" v-if="this.radios1 === 'course' && this.cfa_levels == true">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>CFA Level</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-stary" vs-align="center" vs-w="6">
          <v-select placeholder="Select CFA Level" style="z-index: 900; margin-top: 4%; margin: 10px; margin-left: 0%"
            class="w-full" v-model="selected_cfa_level" :options="cfa_levels_list"></v-select>
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%" v-if="this.radios1 === 'course' && this.frm_parts == true">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>FRM Part</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-stary" vs-align="center" vs-w="6">
          <v-select placeholder="Select FRM Part" style="z-index: 900; margin-top: 4%; margin: 10px; margin-left: 0%"
            class="w-full" v-model="selected_frm_part" :options="frm_parts_list"></v-select>
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%" v-if="this.radios1 === 'Additional Purchase'">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>Additional Amount:</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-input class="inputx" placeholder="Additional Amount" v-model="additional_cost" style="width: 97%" />
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%" v-if="this.radios1 === 'course'">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>Agreed Cost</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-input class="inputx" placeholder="Agreed Cost" v-model="purchase_agreedcost" style="width: 97%" />
        </vs-col>
      </vs-row>
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>Primary Mobile:</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="font-size: 15px; color: gray">
          <b>{{ lastdigitfunc(this.primary_mobile) }}</b>
        </vs-col>
      </vs-row>
      <!-- <vs-row class="gap" style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>Primary Email:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <b>{{ this.primary_email }}</b>
        </vs-col>
      </vs-row> -->
      <vs-row class="gap" style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="success" type="border" @click="sendPaymentlink">Accept</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button color="danger" @click="activePrompt = false" type="border">Cancel</vs-button>
        </vs-col>
      </vs-row>
      <vs-row v-if="this.Areusure === true">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="8">
            <div style="font-size: 16px">
              <b>Are you sure you want to send
                {{ this.PurchaseCourse }} Purchase link for Rs:{{
    this.AGreedCost
}}
                to {{ this.PersonName }} with contact details Mobile:
                {{ this.primary_mobile }} and Email:
                {{ this.primary_email }}?</b>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row style="margin-top: 3%">
          <!-- <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
          </vs-col> -->
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button color="success" type="border" style="margin-left: 20%;" @click="sendLink">Yes</vs-button>
            <vs-button color="success" type="border" id="send_payment_link" style="visibility: hidden">Send Payment link
            </vs-button>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
          </vs-col> -->
        </vs-row>
      </vs-row>
    </vs-popup>
    <vs-popup class="holamundo reschedule_popup" title="Reschedule Call" :active.sync="schedule_call_popup" style="border-radius: 15px !important">
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents"><vs-icon icon="calendar_today"></vs-icon>&nbsp;<span class="schedule_title">&nbsp; Select Date :</span></p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <datepicker v-if="!getrole('exed2')" :disabledDates="disabledDates" placeholder="Select Date" 
            :format="customFormatter" v-model="dates" style="position: fixed;z-index: 2000;border: 1px solid #0044ba;border-radius: 5px;width: 205px;"></datepicker>
          <datepicker v-else placeholder="Select Date" :disabledDates="disabledDates" :format="customFormatter" v-model="dates" style="position: fixed;z-index: 2000;border: 1px solid #0044ba;border-radius: 5px;width: 205px;"></datepicker>
        </vs-col>
      </vs-row>
      <vs-row style="margin: 7% 0 5% 0">
        <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <p style="display: contents">
            <vs-icon icon="schedule"></vs-icon>&nbsp;
            <span class="schedule_title">&nbsp; Select Time :</span>
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vue-timepicker :minute-range="[0, 6, [10, 30], 42, 50]" :minute-interval="30" format="HH:mm"
            v-model="schedule_time" class="myTimePicker" style="position: fixed;z-index: 1500;border: 1px solid #0044ba;border-radius: 3px;width: 205px;">
          </vue-timepicker>
        </vs-col>
      </vs-row>

      <div style="display: flex; justify-content: center; margin-top: 2%">
        <vs-button color="#0044ba" @click="reScheduleCall">Okay</vs-button>
      </div>
    </vs-popup>
    <vs-popup class="holamundo"  title="Cancel Visit" :active.sync="open_delete_popup">
      <vs-row>Are you sure, do you want to cancel this visit ?</vs-row><br/>
      <vs-row>
        <vs-col vs-type='flex' vs-align='flex-end' vs-justify='flex-end' vs-w='12'>
          <vs-button @click='open_delete_popup = false'>No</vs-button>&nbsp;
          <vs-button color="danger" @click="cancelVisit()">Yes</vs-button>
        </vs-col>
      </vs-row>	
    </vs-popup>
  </div>
</template>

<script>
// import EventBus from "../eventbus.js";
import constants from "../../../../constants.json";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import EventBus from "../../eventbus";
import VueTimepicker from "vue2-timepicker";
import datetime from "vuejs-datetimepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import EnquiriesAgingSources from "./EnquiriesAgingSources.vue";
import Treeselect from "@riophae/vue-treeselect";
import "vue-select/dist/vue-select.css";
// import swal from "sweetalert2/src/sweetalert2.js";
// import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    "v-select": vSelect,
    EnquiriesAgingSources,
    Treeselect,
    Datepicker,
    DateRangePicker,
    datetime,
    VueTimepicker,
},
  props: {
    studentDetails: {
      required: true,
    },
  },
  data() {
    return {
      show_source: true,
      electives: true,
      open_delete_popup:false,
      schedule_visit_id:null,
      schedule_call_popup: false,
      dates: "",
      disabledDates: {
        to: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 5),
        from: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 26),
      },
      schedule_time: {
        HH: null,
        mm: null,
      },
      show_enrollments_data:false,
      show_engagements_data:false,
      current_ctc_alert: false,
      experience_alert: false,
      current_ctc_alert_message: "",
      experience_alert_message: "",
      show_finance_course: false,
      show_exed_course : false,
      finance_courses_list: [
        "CPA",
        "CMA",
        "CFA",
        "FRM",
        "AICPA",
        "USP",
        "CPA-AA",
        "CFA-L1",
        "CFA-L2",
        "CFA-L3",
        "FRM-P1",
        "FRM-P2",
        "USP",
      ],
      finance_course_data:[],
      exed_courses_list: [
        "IIMI-BA",
        "XLRI-HR",
        "IIML-FT",
      ],
      exed_course_data:[],
      placement_disable: false,
      placement_assistance: false,
      ne_courses: [],
      eligibility_report_link: "",
      eligibility_report_link_cpa_us: "",
      Areusure: false,
      PersonName: "",
      AGreedCost: "",
      PurchaseCourse: "",
      additional_cost: "",
      paymentTable: false,
      paymentERROR: false,
      errorpayment: "",
      PaymentLinks: [],
      primary_mobile: "",
      primary_email: "",
      purchase_agreedcost: "",
      purchase_courses: "",
      selected_cfa_level: null,
      selected_frm_part: null,
      purchaseCourseList: [
        // "AICPA",
        "CPA",
        // "CIA",
        "CPA-AA",
        "USP",
        "CMA",
        "CFA",
        "FRM",
        "Bridge Course – Public Accounting",
        // "IIML-FT",
        // "IIMI-BA",
        // "IIMI-AA",
        // "RPO",
        // "XLRI-HR",
        // "XLRI-SH",
        // "CISA",
        // "EA",
        // "IIML-FA",
        // "IIML-HR",
        // "IIML-PM",
        // "IIML-BA",
        // "IITR-BF",
        // "IIML-SF",
        // "IITR-DB",
        // "IITM-AA",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
        // "IIMK-FT",
        // "IIML-AB",
        // "IIML-SH",
        // "IITJ-DE",
        // "XLRI-HR",
        // "XLRI-DM"
      ],
      cfa_levels_list: ["Level 1", "Level 2", "Level 3"],
      frm_parts_list: ["Part 1", "Part 2"],
      radios1: "",
      activePrompt: false,
      loanCode: "",
      eligibilityCode: "",
      applied_for_loan: "",
      checkboxloandisable: false,
      navTitleName: "",
      options1 : [],
      // options1: [
      //   {
      //     id: 'PUC',
      //     label: 'PUC',
      //   },
      //   {
      //     id: 'INTER',
      //     label: 'INTER',
      //   },
      //   {
      //     id: 'B.Com',
      //     label: 'B.Com',
      //   },
      //   {
      //     id: 'B.Com(P)',
      //     label: 'B.Com(P)',
      //   },
      //   {
      //     id: 'M.Com',
      //     label: 'M.Com',
      //   },
      //   {
      //     id: 'M.Com(P)',
      //     label: 'M.Com(P)',
      //   },
      //   {
      //     id: 'MBA',
      //     label: 'MBA',
      //   },
      //   {
      //     id: 'MBA(P)',
      //     label: 'MBA(P)',
      //   },
      //   {
      //     id: 'CA',
      //     label: 'CA',
      //   },
      //   {
      //     id: 'CWA',
      //     label: 'CWA',
      //   },
      //   {
      //     id: 'CS',
      //     label: 'CS',
      //   },
      //   {
      //     id: 'BMS',
      //     label: 'BMS',
      //   },
      //   {
      //     id: 'BMS(P)',
      //     label: 'BMS(P)',
      //   },
      //   {
      //     id: 'BBA',
      //     label: 'BBA',
      //   },
      //   {
      //     id: 'BBA(P)',
      //     label: 'BBA(P)',
      //   },
      //   {
      //     id: 'PGD',
      //     label: 'PGD',
      //   },
      //   {
      //     id: 'PGD(P)',
      //     label: 'PGD(P)',
      //   },
      //   {
      //     id: 'LLB',
      //     label: 'LLB',
      //   },
      //   {
      //     id: 'LLB(P)',
      //     label: 'LLB(P)',
      //   },
      //   {
      //     id: 'B.Tech',
      //     label: 'B.Tech',
      //   },
      //   {
      //     id: 'B.Tech(P)',
      //     label: 'B.Tech(P)',
      //   },
      //   {
      //     id: 'B.Sc',
      //     label: 'B.Sc',
      //   },
      //   {
      //     id: 'B.Sc(P)',
      //     label: 'B.Sc(P)',
      //   },
      //   {
      //     id: 'BA',
      //     label: 'BA',
      //   },
      //   {
      //     id: 'BA(P)',
      //     label: 'BA(P)',
      //   },
      //   {
      //     id: 'CA-Inter',
      //     label: 'CA-Inter',
      //   },
      //   {
      //     id: 'CWA-Inter',
      //     label: 'CWA-Inter',
      //   },
      //   {
      //     id: 'CS-Inter',
      //     label: 'CS-Inter',
      //   },
      //   {
      //     id: 'PG(P)',
      //     label: 'PG(P)',
      //   },
      //   {
      //     id: 'CA Final',
      //     label: 'CA Final',
      //   },
      //   {
      //     id: 'CWA Final',
      //     label: 'CWA Final',
      //   },
      //   {
      //     id: 'CS Final',
      //     label: 'CS Final',
      //   },
      //   {
      //     id: 'IGNOU - B.Com (CA)',
      //     label: 'IGNOU - B.Com (CA)',
      //   },
      //   {
      //     id: 'IGNOU - B.Com (CWA)',
      //     label: 'IGNOU - B.Com (CWA)',
      //   },
      //   {
      //     id: 'IGNOU - B.Com (CS)',
      //     label: 'IGNOU - B.Com (CS)',
      //   },
      //   {
      //     id: 'MCA',
      //     label: 'MCA',
      //   },
      //   {
      //     id: 'PGDPA',
      //     label: 'PGDPA',
      //   },
      //   {
      //     id: 'PGDSF',
      //     label: 'PGDSF',
      //   },
      //   {
      //     id: 'MA',
      //     label: 'MA',
      //   },
      //   {
      //     id: 'PGDM',
      //     label: 'PGDM',
      //   },
      //   {
      //     id: 'BBM',
      //     label: 'BBM',
      //   },
      //   {
      //     id: 'BCA',
      //     label: 'BCA',
      //   },
      //   {
      //     id: 'SCESA',
      //     label: 'SCESA',
      //   },
      //   {
      //     id: 'CFA',
      //     label: 'CFA',
      //   },
      //   {
      //     id: 'FRM',
      //     label: 'FRM',
      //   },
      //   {
      //     id: 'MAcc',
      //     label: 'MAcc',
      //   },
      //   {
      //     id: 'CFA(P)',
      //     label: 'CFA(P)',
      //   },
      //   {
      //     id: 'MFA',
      //     label: 'MFA',
      //   },
      //   {
      //     id: 'MFA(P)',
      //     label: 'MFA(P)',
      //   },
      //   {
      //     id: 'CMA(US)',
      //     label: 'CMA(US)',
      //   },
      //   {
      //     id: 'CPA(US)',
      //     label: 'CPA(US)',
      //   },
      //   {
      //     id: 'BAF',
      //     label: 'BAF',
      //   },
      //   {
      //     id: 'Others',
      //     label: 'Others',
      //   },
      // ],
      // options1: [
      //   "PUC",
      //   "INTER",
      //   "B.Com",
      //   "B.Com(P)",
      //   "M.Com",
      //   "M.Com(P)",
      //   "MBA",
      //   "MBA(P)",
      //   "CA",
      //   "CWA",
      //   "CS",
      //   "BMS",
      //   "BMS(P)",
      //   "BBA",
      //   "BBA(P)",
      //   "PGD",
      //   "PGD(P)",
      //   "LLB",
      //   "LLB(P)",
      //   "B.Tech",
      //   "B.Tech(P)",
      //   "B.Sc",
      //   "B.Sc(P)",
      //   "BA",
      //   "BA(P)",
      //   "CA-Inter",
      //   "CWA-Inter",
      //   "CS-Inter",
      //   "PG(P)",
      //   "CA Final",
      //   "CWA Final",
      //   "CS Final",
      //   "IGNOU - B.Com (CA)",
      //   "IGNOU - B.Com (CWA)",
      //   "IGNOU - B.Com (CS)",
      //   "MCA",
      //   "PGDPA",
      //   "PGDSF",
      //   "MA",
      //   "PGDM",
      //   "BBM",
      //   "BCA",
      //   "SCESA",
      //   "CFA",
      //   "FRM",
      //   "MAcc",
      //   "CFA(P)",
      //   "MFA",
      //   "MFA(P)",
      //   "CMA(US)",
      //   "CPA(US)",
      //   "BAF",
      // { text: "", value: 0 },
      // { text: "B.Com", value: 0 },
      // { text: "B.Com(P)", value: 18 },
      // { text: "M.Com", value: 1 },
      // { text: "M.Com(P)", value: 19 },
      // { text: "MBA", value: 2 },
      // { text: "MBA(P)", value: 20 },
      // { text: "CA", value: 3 },
      // { text: "CWA", value: 4 },
      // { text: "CS", value: 5 },
      // { text: "BMS", value: 6 },
      // { text: "BMS(P)", value: 21 },
      // { text: "BBA", value: 7 },
      // { text: "BBA(P)", value: 17 },
      // { text: "PGD", value: 8 },
      // { text: "PGD(P)", value: 22 },
      // { text: "LLB", value: 9 },
      // { text: "LLB(P)", value: 23 },
      // { text: "B.Tech", value: 10 },
      // { text: "B.Tech(P)", value: 24 },
      // { text: "B.Sc", value: 11 },
      // { text: "B.Sc(P)", value: 25 },
      // { text: "BA", value: 12 },
      // { text: "BA(P)", value: 26 },
      // { text: "CA-Inter", value: 13 },
      // { text: "CWA-Inter", value: 14 },
      // { text: "CS-Inter", value: 15 },
      // { text: "PG(P)", value: 16 },
      // { text: "CA Final", value: 16 },
      // { text: "CWA Final", value: 16 },
      // { text: "CS Final", value: 16 },
      // // { text: "IGNOU", value: 16 },
      // { text: "IGNOU - B.Com (CA)", value: 16 },
      // { text: "IGNOU - B.Com (CWA)", value: 16 },
      // { text: "IGNOU - B.Com (CS)", value: 16 },
      // { text: "MCA", value: 16 },
      // { text: "PGDPA", value: 16 },
      // { text: "PGDSF", value: 16 },
      // { text: "PGDM", value: 16 },
      // { text: "BBM", value: 16 },
      // { text: "SCESA", value: 16 }
      // ],
      cityOptions: ["HYD", "BNG", "BOM"],
      eligibilityOptions: ["Pending", "Clear", "Check", "PGDPA"],
      loanOptions: [
        "WIP",
        "Not Interested",
        "Not Eligible",
        "Postponed",
        "Rejected",
        "Processed",
        "Disbursed",
        "SELF",
      ],
      editCompany: this.studentDetails.mwb.company,
      edit_ctc: this.studentDetails.mwb.current_ctc,
      editDesignation: this.studentDetails.mwb.designation,
      edit_college: this.studentDetails.mwb.college,
      editExperience: this.studentDetails.mwb.experience_int,
      companyEdit: false,
      show_edit_ctc: false,
      companyEditbutton: true,
      ctc_edit_button: true,
      nameEdit: false,
      nameEditbutton: true,
      editName: this.studentDetails.mwb.person_name,
      designationEdit: false,
      collegeEdit: false,
      designationEditbutton: true,
      collegeEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      additionalInfo: "",
      eligibility: "",
      loan: "",
      select1: [],
      submitted_documents: "",
      engagementsload: [],
      getMaskedNumber: [],
      course: [],
      submit: false,
      submitLoan: false,
      enablebutton: false,
      enablebuttonloan: false,
      checkboxdisable: false,
      phone_number: "",
      link: "",
      payment_link_message: "",
      text_msg: false,
      ne_cities: [],
      meet_and_greet: true,
      primary_mobile_number :"",
      cfa_levels: false,
      frm_parts: false,
      cpa_eligibility: '',
      usp_eligibility: '',
      bridge_course_eligibility: '',
    };
  },
  watch: {
    purchase_courses(val){
      if (val === 'CFA') {
        this.selected_cfa_level = null;
        this.cfa_levels = true;
        this.frm_parts = false;
      }else if (val === 'FRM') {
        this.selected_frm_part = null;
        this.cfa_levels = false;
        this.frm_parts = true;
      }else{
        this.cfa_levels = false;
        this.frm_parts = false;
      }
    },
    placement_assistance(val) {
      console.log("", val);
      if (val == true) {
        this.savePlacementAssistance(val);
        this.placement_disable = true;
      }
    },
    eligibility: function () {
      this.enablebutton = true;
    },
    // submit: function(value) {
    //   switch (value) {
    //     case true:
    //       this.eligibility = "Pending";
    //       break;
    //     case false:
    //       this.eligibility = "";
    //       this.enablebutton = false;
    //       break;
    //   }
    // },
    submitted_documents: function (value) {
      this.submit = value;
    },
    applied_for_loan: function (value) {
      this.submitLoan = value;
    },
    edit_ctc: function (val) {
      let float_ctc = parseFloat(val);
      if (float_ctc > 100) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be above 100";
      } else if (isNaN(float_ctc) && val != '' && val != null) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be a string";
      } else if (val == "") {
        this.current_ctc = null;
        this.current_ctc_alert = false;
      } else {
        this.current_ctc_alert = false;
      }
    },
    editExperience: function (val) {
      let float_exp = parseFloat(val);
      if (isNaN(float_exp) && val != '' && val != null) {
        this.experience_alert = true;
        this.experience_alert_message = "Experience cannot be a string";
      } else if (val == "") {
        this.editExperience = null;
        this.experience_alert = false;
      }
      else {
        this.experience_alert = false;
      }
    },
    // submitLoan: function(value) {
    //   switch (value) {
    //     case true:
    //       if (this.studentDetails.loan_status === "") {
    //         this.loan = "WIP";
    //       } else {
    //         this.loan = this.studentDetails.loan_status;
    //       }
    //       this.enablebuttonloan = true;
    //       break;
    //     case false:
    //       this.loan = "";
    //       this.enablebuttonloan = false;
    //   }
    // }
  },
  mounted() {
    let login_team = localStorage.getItem("team");
    if(login_team == 'CM'){
      this.show_source = false;
    }
    this.disabledDates.from = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 15
      );
      this.disabledDates.to = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    this.dates = new Date(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() +1
              )
            );
    this.cpa_eligibility = this.studentDetails.cpa_eligibility
    this.usp_eligibility = this.studentDetails.usp_eligibility
    this.bridge_course_eligibility = this.studentDetails.bridge_course_eligibility

    this.show_enrollments_data = false
    this.studentDetails.mwb.enrollments.forEach(element => {
      if (element.enrollment_date != null && element.enrollment_date != undefined) {
        this.show_enrollments_data = true
      }
    });
    this.show_engagements_data = false
    this.studentDetails.engagements.forEach(element => {
      if (element.type == 'visit' && element.visit_approved == 'Yes') {
        this.show_engagements_data = true
      }
    });
    // this.getPlacementAssistance();
    // this.getEligibilityReport();
    //to get courses from net nenquiries array of objects
    let courses_list = [];
    for (let i = 0; i < this.studentDetails.ne_data.length; i++) {
      const element = this.studentDetails.ne_data[i];
      courses_list.push(element.course);
    }
    //To remove duplicates from courses
    courses_list.forEach((e) => {
      if (
        this.ne_courses.findIndex(
          (r) => r.toLowerCase() === e.toLowerCase()
        ) === -1
      )
        this.ne_courses.push(e);
    });
    this.getPurchaseLinks();
    this.eligibility_report_link = this.studentDetails.eligibility_report_link;
    this.eligibility_report_link_cpa_us = this.studentDetails.eligibility_report_link_cpa_us;
    this.course = [];
    this.course = this.studentDetails.mwb.courses.split(",");
    this.finance_course_data =[];
    this.exed_course_data =[];
    if(this.course.length > 0){
      this.course.forEach((item)=>{
        if(this.finance_courses_list.includes(item) == true){
          this.show_finance_course = true;
          this.finance_course_data.push(item)
        }else{
          this.show_exed_course = true;
          this.exed_course_data.push(item);
        }
      })
    }
    console.log("object", this.course,this.exed_course_data);
    // if (this.studentDetails.mwb.education_tags !== null) {
    //   this.select1 = this.studentDetails.mwb.education_tags.split(",");
    // } else {
    //   this.select1 = [];
    // }
    // this.getEducationTags();
    this.getCandidatesEducationTags(this.studentDetails.mwb.id);
    if (this.studentDetails.mwb.submitted_documents !== "No") {
      this.submitted_documents = true;
      this.checkboxdisable = true;
      this.eligibility = this.studentDetails.mwb.eligibility;
    }
    if (this.studentDetails.mwb.applied_for_loan === 1) {
      this.applied_for_loan = true;
      this.checkboxloandisable = true;
      this.loan = this.studentDetails.mwb.loan_status;
    }
    if (this.studentDetails.eligibility_code) {
      this.eligibilityCode = this.studentDetails.eligibility_code;
    }
    if (this.studentDetails.loan_code) {
      this.loanCode = this.studentDetails.loan_code;
    }
    this.getNECity(this.studentDetails.ne_data);
  },
  methods: {
    sendUSPEligibilityLink(){

      var emails_array = this.studentDetails.emails
      var primary_email_found = false;
      var primary_email = "";
      for (let i = 0; i < emails_array.length; i++) {
        const element = emails_array[i];
        if(element.pri_mary == 1){
          primary_email_found = true;
          primary_email = element.email;
        }
      }
      console.log("priary email found", this.studentDetails, primary_email, primary_email_found);

      if(primary_email_found == true){
        console.log("priary email found", this.studentDetails);
        let obj = {
          to_mail      : primary_email,
          person_name  : this.studentDetails.mwb.person_name,
          user_id      : localStorage.getItem("user_id")
        }
        console.log("sendUSPEligibilityEmail request obj", obj);

        let url = `${constants.SERVER_API}sendUSPEligibilityEmail`;
        axios
          .post(url, obj,{
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("sendUSPEligibilityEmail", response);
            this.$vs.notify({
              text: response.data,
              color: "success",
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else{
        this.$vs.notify({
          text: "Atleast one Email should be primary",
          color: "danger",
        });
      }
    },
    editCollegeAPI(){
       axios
        .post(
          `${constants.SERVER_API}editCollege`,
          {
            mwb_id: this.studentDetails.mwb.id,
            person_id: this.studentDetails.mwb.person_id,
            edited_college: this.edit_college,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("editCollege response",response);
          if (response.data.status === "success") {
            EventBus.$emit("reload-bigpopup-data");
            this.collegeEditFunc();
            this.$vs.notify({
              title: "Success",
              text: "College details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the College of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    savePlacementAssistance() {
      let placement_assis_obj = {
        mwb_id: this.studentDetails.mwb.id,
      };
      let url = `${constants.SERVER_API}savePlacementAssitance`;
      axios
        .post(url, placement_assis_obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("save Placement Assitance", response);
          if (
            response.data.message == "Placement assistance saved succefully"
          ) {
            this.$vs.notify({
              title: response.data.message,
              color: "success",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // getPlacementAssistance() {
    //   let get_param = {
    //     mwb_id: this.studentDetails.mwb.id,
    //   };
    //   let url = `${constants.SERVER_API}getPlacementAssitance`;
    //   axios
    //     .get(url, {
    //       params: get_param,
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log(
    //         "get Placement Assitance",
    //         response.data.placement_assistance
    //       );
    //       if (response.data.placement_assistance == 1) {
    //         console.log(response.data.placement_assistance);
    //         this.placement_assistance = true;
    //       }
    //       if (response.data.placement_assistance == 0) {
    //         console.log(response.data.placement_assistance);
    //         this.placement_assistance = false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    // getEligibilityReport() {
    //   let obj = {
    //     mwb_id: this.studentDetails.mwb.id,
    //   };
    //   console.log("data", obj);
    //   axios
    //     .get(`${constants.SERVER_API}getEligibilityReport`, {
    //       params: obj,
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("Links", response.data);
    //       if(response.data.status === "success") {
    //         this.PaymentLinks = response.data.purchase_links;
    //       } else if(response.data.status === "error") {
    //         this.handleNotification(response);
    //       }
    //       // response.data.purchase_links.forEach(element => {
    //       //   console.log("data", element)
    //       // });
    //       // this.handleNotification(response);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    openConfirm() {
      if (!this.primaryExists(this.studentDetails.emails)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary email is required to start the enrollment process",
          "danger"
        );
        return;
      } else if (!this.primaryExists(this.studentDetails.mobiles)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary mobile number is required to start the enrollment process",
          "danger"
        );
        return;
      }
      document.getElementById("paymentid").children[1].style.width = "65%";
      this.radios1 = "";
      this.purchase_courses = "";
      this.purchase_agreedcost = "";
      this.additional_cost = "";
      this.PersonName = this.studentDetails.mwb.person_name;
      this.activePrompt = true;
      this.Areusure = false;
      this.studentDetails.mobiles.forEach((mobile) => {
        if (mobile.pri_mary === 1) {
          this.primary_mobile = mobile.phone_number;
          this.phone_number = mobile.phone_number;
        }
      });
      this.studentDetails.emails.forEach((email) => {
        if (email.pri_mary === 1) {
          this.primary_email = email.email;
        }
      });
    },
    getCreateAtDate(date){
      return moment(date).format("DD-MMM-YYYY");
    },
    reScheduleCall() {
      if (this.schedule_time.HH != null && this.schedule_time.mm != null && this.schedule_time.HH != "" && this.schedule_time.mm != "") {
        let schedule_date = moment(this.dates, "DD-MM-YYYY").format("DD-MMM-YYYY");
        let hours_minutes = this.schedule_time.HH.concat(":",this.schedule_time.mm);
        var str = Date.parse(schedule_date + " " + hours_minutes);
        str = str.toString();
        str = str.slice(0, -3);
        this.date_time = parseInt(str);
        this.$vs.loading();
        let obj = {
                next_call_timestamp:this.date_time,
                id:this.schedule_visit_id
              }
      axios.post(`${constants.SERVER_API}visitReschedule`, obj, {headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },})
        .then((response) => {
          console.log('shashi',response);
          this.$vs.loading.close();
          this.schedule_call_popup = false
          this.$vs.notify({
            title: "Rescheduled Visit",
            color: "success",
          });
          setTimeout(() => {
            EventBus.$emit("visit-rescheduled");
          }, 750);
        })
        .catch((error) => {
          this.schedule_call_popup = false
          this.$vs.loading.close();
          this.handleError(error);
        });
      }
    },
    cancelVisit(){
      this.$vs.loading();
      let obj = {
        id: this.schedule_visit_id,
      };
      axios.post(`${constants.SERVER_API}cancelSchedule`, obj, {headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },})
        .then((response) => {
          console.log('shashi',response);
          this.$vs.loading.close();
          this.open_delete_popup = false
          this.$vs.notify({
            title: "Visit Canceled",
            color: "danger",
          });
          setTimeout(() => {
            EventBus.$emit("visit-canceled");
          }, 750);
        })
        .catch((error) => {
          this.open_delete_popup = false
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    copyText(id) {
      // console.log("id", id);
      var copyText = document.getElementById(id).title;
      // console.log("Copied: ", document.getElementById(id).title);
      document.addEventListener(
        "copy",
        function (event) {
          event.clipboardData.setData("text/plain", copyText);
          event.preventDefault();
        },
        true
      );
      document.execCommand("copy");
      this.$vs.notify({
        title: "Link Copied",
        color: "success",
      });
    },
    resendLink(tr) {
      this.studentDetails.mobiles.forEach((num)=>{
        if(num.pri_mary == 1){
          this.primary_mobile_number = num.phone_number
          this.phone_number = num.phone_number;
        }
      })

      let obj = {
        purchase_id: tr.purchase_id,
      };
      axios
        .get(`${constants.SERVER_API}resendPaymentLink`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          // EventBus.$emit("reload-bigpopup-data");
          if(response.data.status == "success"){
            this.$vs.notify({
            title: response.data.message + " " + `and mobile number at ${this.primary_mobile_number}`,
            color: "success",
          });
            this.link = encodeURI(response.data.payment_link.payment_link);
this.payment_link_message = `Hi ${this.studentDetails.mwb.person_name
  },
Please click here to make payment -
${this.link}
Cheers,
${localStorage.getItem("spoc_name")},
Miles Education`;
          document.getElementById("resend_payment_link").click();
          }

          this.getPurchaseLinks();
          EventBus.$emit("reload-bigpopup-data");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    sendPaymentlink() {
      if (this.radios1 === "Miscellaneous") {
        this.AGreedCost = "2500";
        this.PurchaseCourse = "Miscellaneous";
      } else if (this.radios1 === "Additional Purchase") {
        this.AGreedCost = this.additional_cost;
        this.PurchaseCourse = "Additional Purchase";
      } else {
        this.AGreedCost = this.purchase_agreedcost;
        this.PurchaseCourse = this.purchase_courses;
      }
      if(this.selected_cfa_level == null && this.cfa_levels == true){
        this.$vs.notify({
                title: 'Please select CFA Level',
                color: "danger",
        });
      }
      else if(this.selected_frm_part == null && this.frm_parts == true){
        this.$vs.notify({
                title: 'Please select FRM Part',
                color: "danger",
        });
      }else{
        this.Areusure = true;
      }
    },
    async sendLink() {
      if(this.selected_cfa_level == null && this.cfa_levels == true){
        this.$vs.notify({
                title: 'Please select CFA Level',
                color: "danger",
        });
      }
      else if(this.selected_frm_part == null && this.frm_parts == true){
        this.$vs.notify({
                title: 'Please select FRM Part',
                color: "danger",
        });
      }else{
      let link_data = await this.areYouSureLink();
      if (link_data) {
        console.log("data available ");
        document.getElementById("send_payment_link").click();
      } else {
        console.log("no data available");
      }
    }
    },
    areYouSureLink() {
      console.log("send msg");
      this.$vs.loading();
      let params = {
        mwb_id: this.studentDetails.mwb.id,
        course: this.PurchaseCourse,
        type: this.radios1,
        amount: this.AGreedCost,
      };
      if(this.selected_cfa_level != null && this.cfa_levels == true){
        params.cfa_selected_level = this.selected_cfa_level;
      }
      if(this.selected_frm_part != null && this.frm_parts == true){
        params.frm_selected_part = this.selected_frm_part;
      }
      return new Promise(async (resolve, reject) => {
        // console.log("axios params", params);
        let url = `${constants.SERVER_API}sendPaymentLinkEmail`;
        this.studentDetails.mobiles.forEach((num)=>{
        if(num.pri_mary == 1){
          this.primary_mobile_number = num.phone_number
        }
      })
      console.log("obj", params);
        axios
          .post(url, params, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            this.activePrompt = false;
            if (response.data.status == "success") {
              this.link = encodeURI(response.data.payment_link.payment_link);
              this.payment_link_message = `Hi ${this.studentDetails.mwb.person_name
                },
Please click here to make payment -
${this.link}
Cheers,
${localStorage.getItem("spoc_name")},
Miles Education`;
              // console.log("payment_link_message", this.payment_link_message);
              this.$vs.notify({
                title: response.data.message + " " + `and mobile number at ${this.primary_mobile_number}`,
                color: "success",
              });
              this.getPurchaseLinks();
              EventBus.$emit("reload-bigpopup-data");
            } else {
              this.$vs.notify({
                title: response.data.message,
                color: "danger",
              });
            }
            this.$vs.loading.close();
            resolve(response);
          })
          .catch((error) => {
            this.handleError(error);
            reject(error);
          });
      });
    },
    getPurchaseLinks() {
      let obj = {
        person_id: this.studentDetails.mwb.person_id,
      };
      // console.log("data", obj);
      axios
        .get(`${constants.SERVER_API}getPurchaseLinks`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log("Links", response.data);
          if (response.data.status === "success") {
            this.PaymentLinks = response.data.purchase_links;
            this.paymentTable = true;
            this.paymentERROR = false;
          } else if (response.data.status === "error") {
            this.paymentTable = false;
            this.paymentERROR = false;
            this.errorpayment = response.data.message;
          }
          // response.data.purchase_links.forEach(element => {
          //   console.log("data", element)
          // });
          // this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    primaryExists(PurchaseData) {
      // console.log("dim", PurchaseData);
      let bool = false;
      PurchaseData.forEach((item) => {
        if (item.pri_mary === 1) {
          bool = true;
        }
      });
      return bool;
    },
    sendIIMLPAInvite() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
      };
      axios
        .get(`${constants.SERVER_API}sentIIMLPAIntorEmail`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
          2;
        });
    },
    sendZoomInvite() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
      };
      axios
        .post(`${constants.SERVER_API}sendZoomInvite`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getMobileColor(mob) {
      let color = "";
      if (mob.invalid === 1) {
        color = "danger";
      } else if (mob.pri_mary === 1) {
        color = "success";
      } else {
        color = "primary";
      }
      return color;
    },
    getEmailColor(mob) {
      let color = "";
      if (mob.invalid === 1) {
        color = "danger";
      } else if (mob.pri_mary === 1) {
        color = "success";
      } else {
        color = "primary";
      }
      return color;
    },
    async addLoan() {
      let sendValue = "";
      // console.log(this.requested_loan)
      switch (this.applied_for_loan) {
        case true:
          sendValue = "Yes";
          break;
        case false:
          sendValue = "No";
          break;
      }
      // console.log(sendValue)
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        applied_for_loan: sendValue,
        loan_status: this.loan,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addAppliedForLoan`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.checkboxloandisable = true;
          console.log(response);
          if (response.data.loan_code) {
            this.loanCode = response.data.loan_code;
          }
          console.log(this.loanCode);
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Updated",
              text: "Successfully updated",
              color: "success",
              position: "top-center",
            });
            EventBus.$emit("reload-bigpopup-data");
            this.$store.commit("UPDATE_LOAN", [
              this.studentDetails,
              sendValue,
              this.loan,
            ]);
          } else {
            this.$vs.notify({
              title: "Not Updated",
              text: response.data.message,
              color: "danger",
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async addEligibility() {
      let submitvalue = "";
      switch (this.submitted_documents) {
        case true:
          submitvalue = "Yes";
          break;
        case false:
          submitvalue = "No";
          break;
      }
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        eligibility: this.eligibility,
        submitted_documents: submitvalue,
      };
      console.log(obj);
      axios
        .post(`${constants.SERVER_API}addEligibility`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if (response.data.eligibility_code) {
            this.eligibilityCode = response.data.eligibility_code;
          }
          this.checkboxdisable = true;
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Updated",
              text: "Successfully updated",
              color: "success",
              position: "top-center",
            });
            EventBus.$emit("reload-bigpopup-data");
            this.$store.commit("UPDATE_ELIGIBILITY", [
              this.studentDetails,
              submitvalue,
              this.eligibility,
            ]);
          } else {
            this.$vs.notify({
              title: "Not Updated",
              text: "An Error occured while updating, please try again",
              color: "danger",
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
      // console.log(postreq)
    },
    async mobilePopup(mobile) {
      axios
        .get(
          `${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
            this.EmitEvent("open-mobile-options-popup", [
              response.data,
              this.studentDetails.mwb,
              mobile,
            ]);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
        });
    },
    updateEducation() {
      // console.log(this.select1.join());
      // console.log(this.studentDetails);
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        education_tags: this.select1.join(),
      };
      axios
        .post(`${constants.SERVER_API}editEducation`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.$vs.notify({
            title: response.data.status,
            text: response.data.message,
            color: "success",
          });
          EventBus.$emit("reload-bigpopup-data");
          // console.log(response);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
        });
    },
    async emailPopup(email) {
      axios
        .get(
          `${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          this.EmitEvent("open-email-options-popup", [
            response.data,
            this.studentDetails.mwb,
            email,
          ]);
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    remove(item) {
      this.select1.splice(this.select1.indexOf(item), 1);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    nameEditFunc() {
      this.nameEdit = !this.nameEdit;
      this.nameEditbutton = !this.nameEditbutton;
    },
    ctcEditFunction() {
      this.show_edit_ctc = !this.show_edit_ctc;
      this.ctc_edit_button = !this.ctc_edit_button;
    },
    editNameAPI() {
      // this.nameEditFunc();
      let obj = {
        person_id: this.studentDetails.mwb.person_id,
        new_person_name: this.editName,
      };
      axios
        .post(`${constants.SERVER_API}editCandidateNameInMwb`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if (response.data.status === "success") {
            // this.$store.commit("UPDATE_NAME", [
            //   this.studentDetails,
            //   this.editName
            // ]);
            EventBus.$emit("reload-bigpopup-data");
            this.nameEditFunc();
            this.handleNotification(response);
          } else if (response.data.status == "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "warning",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the name of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // async editCompanyAPI() {
    //   let obj = {
    //     mwb_id: this.studentDetails.mwb.id,
    //     person_id: this.studentDetails.mwb.person_id,
    //     edited_company: this.editCompany,
    //   };
    //   axios
    //     .post(`${constants.SERVER_API}editCompany`, obj, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log(obj);
    //       console.log(response);
    //       if (response.data.status === "success") {
    //         // this.$store.commit("UPDATE_COMPANY", [
    //         //   this.studentDetails,
    //         //   this.editCompany
    //         // ]);
    //         EventBus.$emit("reload-bigpopup-data");
    //         this.companyeditfunc();
    //         this.$vs.notify({
    //           title: "Success",
    //           text: "Company details successfully updated",
    //           color: "success",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           title: "Error",
    //           text: "An Error occured while editing the company of this lead",
    //           color: "danger",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //       // if (error.response) {
    //       //   console.log("error", error.response.status);
    //       //   if (error.response.status === 401) {
    //       //     this.EmitEvent("close-MWB-popup");
    //       //     this.ReDirect_to_login();
    //       //   }
    //       // } else {
    //       //   this.$vs.notify({
    //       //     title: "Error",
    //       //     text: "Network Error!",
    //       //     color: "danger",
    //       //   });
    //       // }
    //     });
    // },
    async editCompanyAPI() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        edited_company: this.editCompany,
      };
      axios
        .post(`${constants.MILES_GOOGLE_INVITE}editCompany`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(obj);
          console.log(response);
          if (response.data.status === "success") {
            // this.$store.commit("UPDATE_COMPANY", [
            //   this.studentDetails,
            //   this.editCompany
            // ]);
            EventBus.$emit("reload-bigpopup-data");
            this.companyeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Company details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the company of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger",
          //   });
          // }
        });
    },
    editCTC() {
      let obj = {
        mwb_id: this.studentDetails.mwb.id,
        person_id: this.studentDetails.mwb.person_id,
        edited_ctc: this.edit_ctc,
      };
      axios
        .post(`${constants.SERVER_API}editCTC`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            EventBus.$emit("reload-bigpopup-data");
            this.ctcEditFunction();
            this.$vs.notify({
              title: "Success",
              text: "CTC details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the CTC of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getNECity(ne_data) {
      let Get_location = [...new Set(ne_data.map((item) => item.location))];
      let Without_null_location = Get_location.filter(function (e) {
        return e != null;
      });
      this.ne_cities = Without_null_location;
      console.log("ne_data", ne_data, this.ne_cities);
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    collegeEditFunc() {
      this.collegeEdit = !this.collegeEdit;
      this.collegeEditbutton = !this.collegeEditbutton;
    },
    async editDesignationAPI() {
      axios
        .post(
          `${constants.SERVER_API}editDesignation`,
          {
            mwb_id: this.studentDetails.mwb.id,
            person_id: this.studentDetails.mwb.person_id,
            edited_designation: this.editDesignation,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            // this.$store.commit("UPDATE_DESIGNATION", [
            //   this.studentDetails,
            //   this.editDesignation
            // ]);
            EventBus.$emit("reload-bigpopup-data");
            this.designationeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Designation details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while editing the designation of this lead",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger",
          //   });
          // }
        });
      // console.log(postreq)
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    getEnrolledDate(date,type){
      let formattedDate = moment.unix(date).format('DD-MMM-YYYY');
      if(type == 'time'){
        formattedDate = moment.unix(date).format('DD-MMM-YYYY hh:mm A');
      }
      return formattedDate;
    },
    // async editExperienceAPI() {
    //   const obj = {
    //     mwb_id: this.studentDetails.mwb.id,
    //     person_id: this.studentDetails.mwb.person_id,
    //     edited_experience: this.editExperience,
    //   };
    //   axios
    //     .post(`${constants.SERVER_API}editExperience`, obj, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       // console.log(response);
    //       if (response.data.status === "success") {
    //         this.$store.commit("UPDATE_EXPERIENCE", [
    //           this.studentDetails,
    //           this.editExperience,
    //         ]);
    //         EventBus.$emit("reload-bigpopup-data");
    //         this.exeditfunc();
    //         this.$vs.notify({
    //           title: "Success",
    //           text: "Experience details successfully updated",
    //           color: "success",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           title: "Error",
    //           text: response.data.message,
    //           color: "danger",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       // if (error.response) {
    //       //   console.log("error", error.response.status);
    //       //   if (error.response.status === 401) {
    //       //     this.EmitEvent("close-MWB-popup");
    //       //     this.ReDirect_to_login();
    //       //   }
    //       // } else {
    //       //   this.$vs.notify({
    //       //     title: "Error",
    //       //     text: "Network Error!",
    //       //     color: "danger",
    //       //   });
    //       // }
    //       this.handleError(error);
    //     });
    // },

    async editExperienceAPI() {
      const obj = {
        mwb_id: this.studentDetails.mwb.id,
        edited_experience: this.editExperience,
      };
      axios
        .post(`${constants.MILES_GOOGLE_INVITE}editExperience`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status === "success") {
            this.$store.commit("UPDATE_EXPERIENCE", [
              this.studentDetails,
              this.editExperience,
            ]);
            EventBus.$emit("reload-bigpopup-data");
            this.exeditfunc();
            this.$vs.notify({
              title: "Success",
              text: "Experience details successfully updated",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // } else {
          //   this.$vs.notify({
          //     title: "Error",
          //     text: "Network Error!",
          //     color: "danger",
          //   });
          // }
          this.handleError(error);
        });
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
    getCandidatesEducationTags(mwb_id) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getCandidateTags?mwb_id=${mwb_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)
          this.select1 = response.data
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    updateCandidatesEducationTags() {
      if (this.select1 != "") {
        let obj = {
          mwb_id: this.studentDetails.mwb.id,
          education_tags: this.select1.join(),
        };
        console.log("EDUCATION TAGS POST", obj)
        axios
          .post(`${constants.MILES_GOOGLE_INVITE}storeCandidateTags`, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == "success") {
              this.$vs.notify({
                title: response.data.status,
                text: response.data.message,
                color: "success",
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: response.data.message,
                color: "danger",
              });
            }
            this.getCandidatesEducationTags(this.studentDetails.mwb.id)
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Education Tags Can't be empty. Please select any education tag or else select others",
          color: "danger",
        });
      }
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    // getEducationTags() {
    //   axios
    //     .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("EDUCATION TAGS", response)
    //       if(response.data.length > 0){
    //         for (let i = 0; i < response.data.length; i++) {
    //           this.options1.push({
    //             id : response.data[i],
    //             label : response.data[i]
    //           })

    //         }

    //       }
    //       // this.options1 = response.data
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // }
  },
};
</script>

<style>
.gaps {
  margin-bottom: 4%;
  margin-left: 2%;
}
.vs__dropdown-menu {
  border: 1px solid #0044ba;
  border-top-color: transparent;
  border-radius: 5px;
}
.vs-chip--text {
  text-align: start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
.details-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  margin-left: 4%;
  margin-top: 25px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.details-subhead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.table-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  margin-left: 1%;
  margin-right: 1%;
  display: table-cell;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}
.payment-links{
  display: inline-table !important;
  justify-content: center;
  width: 100%;
  margin-left: 1%;
  margin-top: 10px !important;
  box-sizing: border-box;
  min-height: 86.23px;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.cost_tablee {
  display: flex ;
  justify-content: center;
  width: 100%;
  margin-left: 1%;
  margin-top: 10px !important;
  box-sizing: border-box;
  min-height: 86.23px;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.details-data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.payment_btn {
  border-radius: 10px;
  color: white;
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.course-boxing {
  box-sizing: border-box;
  width: 100%;
  background: rgba(0, 68, 186, .6);
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  border-radius: 10px;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  color: #fff;
}
.education-boxing{
  /* box-sizing: border-box; */
  width: 100%;
  background: rgba(0, 68, 186, .6);
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  border-radius: 10px;
  /* text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px; */
  /* line-height: 32px; */
  color: #fff;
}
.course-boxing1 {
  /* box-sizing: border-box; */
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77.62px;
  height: 31.85px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  color: #000;
}
.enquired-courses {
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 15px;
}
.vs-icon {
  color: inherit;
  text-align: center;
  font-size: 18px;
}
/* .vs--searchable .vs__dropdown-toggle {
  min-height: 38px !important;
} */
.vs__dropdown-toggle {
  min-height: 38px !important;
  font-size: 15px;
}
.vs-checkbox-primary .vs-checkbox {
  border: 1px solid #0044ba !important;
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.08));
  border-radius: 5px;
  width: 21.1px;
  height: 20.45px;
}
.education_tags{
  margin-block: 2px;
  border:1px solid rgba(0, 68, 186, 0.7);
  background:rgba(0, 68, 186, 0.5);
  color: #ffffff;
}
.education_tags .vs-button--text{
  position: relative;
  color: inherit;
  border-radius: 15px;
  font-weight: 600;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 10px;
}
.education_tags.vs-button:disabled {
    opacity: 1 !important;
    cursor: default;
    pointer-events: none;
}
.alert_message {
  background: #e8b4b487;
  color: rgba(var(--vs-danger), 1);
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  /* border: 2px solid; */
  border-radius: 5px;
}
.added_by {
  width:98% !important;
  display: flex;
  /* justify-content: start; */
  /* margin-inline: 1%; */
  /* margin-block: 20px; */
  box-sizing: border-box;
  height: fit-content;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.text-data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #000000;
}
.added_data{
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.visits_data{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-left:4px
}
.reschedule_popup .vs-popup--header {
  background: #0044ba;
  border-radius: 15px;
}

.reschedule_popup .vs-popup--title h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  padding-block: 10px;
}

.reschedule_popup .vs-popup--close {
  background: #0044ba !important;
  margin-top: auto;
  margin-right: 18px;
  color: #ffffff;
}

.reschedule_popup .vs-popup {
  border-radius: 15px !important;
}

.myTimePicker .display-time {
  border: 1px solid #ffffff;
  width: 203px !important;
  height: 37px !important;
  padding: 0.3em 0.5em;
  font-size: 1em;
}

.schedule_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.custom-tooltip >.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #0044ba;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}
.collapse_card {
  box-sizing: border-box;
  background: rgba(241, 241, 241, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.vs-collapse .vs-collapse-item--header{
  padding: 0;
  font-size: 14px;
  margin-left: 2%;
}
</style>