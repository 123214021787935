<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
      >
        <p class="heading">ACADS EVALUATION ADVICE LICENSE</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <vs-button
          radius
          color="dark"
          type="gradient"
          icon="edit"
          @click="edit_accads_evaluation_license"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 13px; color: gray"
        >State Board Transfer:
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          :disabled="!editStage"
          v-model="mainObj.is_state_board_transfer"
          :options="board_transfer"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 13px; color: gray"
        >Additional Evaluation for License:
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="!editStage"
          v-model="mainObj.is_additional_evaluation"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <v-select
          :disabled="!editStage"
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.additional_evaluation_license"
          :options="AdditionalEvaluationforLicense"
          label="text"
        ></v-select>
        <!-- <v-select
          v-if="EvaluationLicense === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.additional_evaluation_license"
          :options="AdditionalEvaluationforLicense"
          label="text"
        ></v-select> -->
        <!-- <v-select
          v-if="EvaluationLicense === true"
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.additional_evaluation_license"
          :options="AdditionalEvaluationforLicense"
          label="text"
        ></v-select> -->
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size:  13px; color: gray"
        >Bridge Course for Additional Credits:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="!editStage"
          v-model="mainObj.is_bridge_course"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
        <v-select
          placeholder
          :disabled="!editStage"
          style="margin-top: 4%; margin: 10px;z-index:4000"
          class="w-full"
          v-model="mainObj.bridge_courses"
          :options="license_bridgecourseOptions"
          label="text"
        ></v-select>
        <!-- <v-select
          v-if="licensebridgecourse === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.bridge_courses"
          :options="license_bridgecourseOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="licensebridgecourse === true"
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.bridge_courses"
          :options="license_bridgecourseOptions"
          label="text"
        ></v-select> -->
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
        style="font-size:  13px; color: gray"
        >Experience Requirement:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-checkbox
          :disabled="!editStage"
          v-model="mainObj.is_experience"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <v-select
          :disabled="!editStage"
          placeholder
          style="margin-top: 4%; margin: 10px;z-index:3000"
          class="w-full"
          v-model="mainObj.total_experience"
          :options="experienceOptions"
          label="text"
        ></v-select>
        <!-- <v-select
          v-if="experience === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.total_experience"
          :options="experienceOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="experience === true"
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.total_experience"
          :options="experienceOptions"
          label="text"
        ></v-select> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          :disabled="!editStage"
          v-model="mainObj.experience_verification"
          :options="license_verificationOptions"
          label="text"
        ></v-select>
        <!-- <v-select
          v-if="licenseverification === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.experience_verification"
          :options="license_verificationOptions"
          label="text"
        ></v-select>
        <v-select
          v-if="licenseverification === true"
          placeholder
          style="margin-top: 4%; margin: 10px;"
          class="w-full"
          v-model="mainObj.experience_verification"
          :options="license_verificationOptions"
          label="text"
        ></v-select> -->
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
        >Ethics Exam Requirement:
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;z-index:2000"
          class="w-full"
          :disabled="!editStage"
          v-model="mainObj.is_ethics_exam_require"
          :options="ethics_exam_requirement"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
        >Most Likey State Board:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
      >
        <v-select
          :disabled="!editStage"
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 1000;"
          class="w-full"
          v-model="mainObj.state_board"
          :options="board_transfer_likely"
          label="text"
        ></v-select>
      </vs-col>
      <!-- <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
      >
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 1000;"
          class="w-full"
          v-model="mainObj.state_board"
          :options="board_transfer_likely"
          label="text"
        ></v-select>
      </vs-col> -->
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
        >Comments:</vs-col
      >
      <vs-col
        v-if="!editStage"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
      >
        {{ mainObj.comments }}
      </vs-col>
      <vs-col
        v-if="editStage"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size:  13px; color: gray"
      >
        <vs-textarea icon="edit" v-model="mainObj.comments"></vs-textarea>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="9"
        style="font-size:  13px; color: gray"
      ></vs-col>
      <vs-col
        v-if="editStage"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
        style="font-size:  13px; color: gray"
      >
        <vs-button radius color="dark" type="border" @click="editAccadsAPI"
          >SAVE</vs-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import constants from "../../../../constants.json";
import axios from "axios";
import _ from "lodash";
export default {
  components: {
    "v-select": vSelect
  },
  props: ["mwb_id"],
  data() {
    return {
      mainObj: {
        is_state_board_transfer: "",
        is_additional_evaluation: false,
        additional_evaluation_license: "",
        is_bridge_course: false,
        bridge_courses: "",
        is_experience: false,
        total_experience: "",
        experience_verification: "",
        is_ethics_exam_require: false,
        state_board: "",
        comments: ""
      },
      responseObj: {
        is_state_board_transfer: "",
        is_additional_evaluation: false,
        additional_evaluation_license: "",
        is_bridge_course: false,
        bridge_courses: "",
        is_experience: false,
        total_experience: "",
        experience_verification: "",
        is_ethics_exam_require: false,
        state_board: "",
        comments: ""
      },
      editStage: false,
      license_applied_for_bridgecourse: "",
      state_board_transfer: "",
      license_bridgecourse_credits: "",
      AdditionalEvaluationforLicense_credits: "",
      EvaluationLicense: false,
      licensebridgecourse: false,
      board_transfer: ["Yes", "No"],
      license_bridgecourseOptions: [
        "Amity PGDPA",
        "Amity PGDSF",
        "Other Programs"
      ],
      AdditionalEvaluationforLicense: ["NIES", "WES", "FACS", "JS"],
      licensecheckboxbridgecoursedisable: false,
      AdditionalEvaluationLicensedisable: false,
      AdditionalEvaluationLicensebox: "",
      licensecheckboxexperiencedisable: false,
      license_applied_for_experience: "",
      experience: false,
      experience_credits: "",
      experienceOptions: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        " 13",
        "16"
      ],
      licenseverification: false,
      license_verification_credits: "",
      license_verificationOptions: [
        "Worked under CPA",
        "Did not Work under CPA"
      ],
      ethics_exam: "",
      ethics_exam_requirement: ["Yes", "No"],
      state_likely_board_transfer: "",
      board_transfer_likely: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
      ],
      AccadsEditbutton: false,
      AccadsEdit: false,
      createObj: false
    };
  },
  watch: {
    license_applied_for_bridgecourse: function(value) {
      // console.log("dfgsueye", value);
      this.licensebridgecourse = value;
    },
    AdditionalEvaluationLicensebox: function(value) {
      // console.log("dfgsueyg", value);
      this.EvaluationLicense = value;
    },
    license_applied_for_experience: function(value) {
      this.experience = value;
      this.licenseverification = value;
    }
  },
  mounted() {
    // console.log("id", this.mwb_id);
    this.createObj = false;
    this.AccadsLicesne();
  },
  methods: {
    edit_accads_evaluation_license() {
      this.editStage = true;
    },
    editAccadsAPI() {
      this.reformatValues();
      let url = "";
      if (this.createObj) {
        url = `${constants.SERVER_API}cpa_license_assistance/create`;
      } else {
        url = `${constants.SERVER_API}cpa_license_assistance/${this.mwb_id}`;
      }
      axios
        .post(url, this.mainObj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.editStage = false;
            this.createObj = false;
            this.AccadsLicesne();
          }
          // this.AccadsEdit = false;
          // this.AccadsEditbutton = false;
          // this.applied_for_bridgecourse = false;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    reformatValues() {
      if (this.mainObj.is_state_board_transfer === "Yes") {
        this.mainObj.is_state_board_transfer = 1;
      } else {
        this.mainObj.is_state_board_transfer = 0;
      }

      if (this.mainObj.is_additional_evaluation) {
        this.mainObj.is_additional_evaluation = 1;
      } else {
        this.mainObj.is_additional_evaluation = 0;
      }

      if (this.mainObj.is_bridge_course) {
        this.mainObj.is_bridge_course = 1;
      } else {
        this.mainObj.is_bridge_course = 0;
      }

      if (this.mainObj.is_experience) {
        this.mainObj.is_experience = 1;
      } else {
        this.mainObj.is_experience = 0;
      }

      if (this.mainObj.is_ethics_exam_require) {
        this.mainObj.is_ethics_exam_require = 1;
      } else {
        this.mainObj.is_ethics_exam_require = 0;
      }

      this.mainObj.mwb_id = this.mwb_id;
    },
    refactorResponse(responseData) {
      if (responseData.is_ethics_exam_require === 1) {
        responseData.is_ethics_exam_require = "Yes";
      } else {
        responseData.is_ethics_exam_require = "No";
      }

      if (responseData.is_state_board_transfer === 1) {
        responseData.is_state_board_transfer = "Yes";
      } else {
        responseData.is_state_board_transfer = "No";
      }

      if (responseData.is_additional_evaluation === 1) {
        responseData.is_additional_evaluation = true;
      } else {
        responseData.is_additional_evaluation = false;
      }

      if (responseData.is_bridge_course === 1) {
        responseData.is_bridge_course = true;
      } else {
        responseData.is_bridge_course = false;
      }

      if (responseData.is_experience === 1) {
        responseData.is_experience = true;
      } else {
        responseData.is_experience = false;
      }
      // this.mainObj.mwb_id = this.mwb_id;
    },
    AccadsLicesne() {
      let mwb_id = this.mwb_id;
      axios
        .get(`${constants.SERVER_API}cpa_license_assistance/${mwb_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("Exams Data", response);
          this.processResponse(response.data.data);
        })
        .catch(error => {
          // console.log(error.response);
          if (error.response.data.status !== "fail") {
            console.log("This alert is generated from license page");
            this.handleError(error);
          } else {
            this.createObj = true;
          }
        });
    },
    processResponse(data) {
      this.refactorResponse(data);
      this.mainObj = _.cloneDeep(data);
    }
  }
};
</script>

<style></style>
