<template>
  <vs-popup class="holamundo"  title="Rating" :active.sync="open">
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <star-rating :read-only="true" v-model="customrating"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-textarea label="feedback" v-model="textfeed"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="gradient" @click="saveRating">Save</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
import StarRating from 'vue-star-rating'
export default {
  mounted() {
    EventBus.$on('open-rating-popup', (payload) => {
      this.customrating = payload
      this.open = true
    })
  },
  components: {
    StarRating
    // Rating
    // rate
  },
  data() {
    return {
      open: false,
      textfeed: '',
      customrating: 0
    }
  },
  methods: {
    saveRating() {
      this.open = false
      EventBus.$emit('update-feedback', this.textfeed)
    }
  }

}
</script>

<style>
.gap {
  margin-bottom: 2%
}
</style>
