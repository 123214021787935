<template>
  <vs-popup
    id="addreceiptpopup"
    class="holamundo"
    title="Add Receipt"
    :active.sync="open"
  >
    <vs-row style="margin-bottom: 1%" v-if="showReceiptAdding">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <b style="color: gray; margin-right: 2%"
          >Adding receipt for {{ enrollmentData.person_name }} whose candidateID
          is {{ enrollmentData.identity }}</b
        >
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col
        v-if="showReceiptAdding"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      >
        <v-select
          style="z-index: 1000"
          class="w-full"
          placeholder="Type"
          v-model="transaction_type"
          :options="list_of_transactions"
        ></v-select>
      </vs-col>
      <vs-col
        v-if="showNEFTSection"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      >
        <vs-input
          style="margin-left: 6%; width: 280px"
          v-model="transaction_id"
          type="number"
          placeholder="Transaction ID"
        />
      </vs-col>
      <vs-col
        v-if="showOtherSection"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      >
        <vs-input
          style="margin-left: 6%; width: 280px"
          v-model="onlineId"
          placeholder="Online Transaction ID"
        />
      </vs-col>
      <vs-col
        v-if="showChequeSection || showpdcSection"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
      >
        <vs-input
          v-if="showChequeSection"
          v-model="cheque_no"
          type="number"
          style="margin-left: 6%; width: 280px"
          placeholder="Cheque No"
        />
        <vs-input
          v-if="showpdcSection"
          v-model="pdcChequeNo"
          type="number"
          style="margin-left: 6%; width: 280px"
          placeholder="Cheque No"
        />
      </vs-col>
      <vs-col
        v-if="showReceiptAdding"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="5"
      >
        <datepicker
          v-if="showChequeSection"
          id="datepicker"
          class="datepicker"
          style="z-index: 1000"
          placeholder="Date"
          :disabled-dates="disabledDatesCheque"
          v-model="pdc_date"
        ></datepicker>
        <datepicker
          v-if="showpdcSection"
          id="datepicker"
          class="datepicker"
          style="z-index: 1000"
          :disabled-dates="disabledDatesPdcCheque"
          placeholder="PDC Date"
          v-model="pdcActualDate"
        ></datepicker>
        <!-- <vs-input
          v-model="amount"
          type="number"
          placeholder="Amount"
          style="margin-left: 2%;left:24px;width: 160px;
    right: 0px;"
        /> -->
        <vs-input
          v-model="ReceiptNumber"
          placeholder="Receipt Number"
          style="margin-left: 2%;left:24px;width: 160px;
    right: 0px;"
        />
        <vs-button
          style="margin-left: 2%;width: inherit;float: right;left: 100px;"
          color="dark"
          size="medium"
          type="border"
          @click="addReceiptToEnrollment()"
          >Add Receipt</vs-button
        >
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 2%" v-if="showError === true">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b style="color: gray"
          >Couldn't find any enrollments for this candidate ID.</b
        >
      </vs-col>
    </vs-row>
    <vs-row
      style="margin-top: 2%"
      v-if="showError === false && addReceipt === false && searched === true"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-table :data="rawdata">
          <template slot="header">
            <h3>Enrollments</h3>
          </template>
          <template slot="thead" class="exc">
            <vs-th>Can-ID</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Subjects Chosen</vs-th>
            <vs-th>City</vs-th>
            <vs-th>Course</vs-th>
            <vs-th>Mode</vs-th>
            <vs-th>Mode of Study</vs-th>
            <vs-th>Category</vs-th>
            <vs-th>Agreed Cost</vs-th>
            <vs-th>Actual Cost</vs-th>
            <vs-th>Batch Start Date</vs-th>
            <vs-th>Date of Enrollment</vs-th>
            <vs-th>Add Receipt</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="item.spoc_name" v-for="item in data">
              <vs-td :data="item.spoc_name">
                <div>{{ item.identity }}</div>
              </vs-td>

              <vs-td :data="item.call_utilization">
                <div>{{ item.person_name }}</div>
              </vs-td>

              <vs-td :data="item.visitor_utilization">
                <div>{{ item.subjects_chosen }}</div>
              </vs-td>

              <vs-td :data="item.training_utilization">
                <div>{{ item.city }}</div>
              </vs-td>

              <vs-td :data="item.total_utilization">
                <div>{{ item.course }}</div>
              </vs-td>

              <vs-td :data="item.total_calls">
                <div>{{ sortText(item.mode) }}</div>
              </vs-td>

              <vs-td :data="item.mwb_call_count">
                <div>{{ sortText(item.mode_of_study) }}</div>
              </vs-td>

              <vs-td :data="item.ewb_call_count">
                <div>{{ item.category }}</div>
              </vs-td>

              <vs-td :data="item.untracked_in_calls_count">
                <div>{{ item.agreed_cost }}</div>
              </vs-td>

              <vs-td :data="item.missed_call_count">
                <div>{{ item.actual_cost }}</div>
              </vs-td>

              <vs-td :data="item.call_30_60">
                <div>{{ unixTimestampConvert(item.batch_start_date) }}</div>
              </vs-td>

              <vs-td :data="item.nuc_count">
                <div>{{ unixTimestampConvert(item.enrollment_date) }}</div>
              </vs-td>
              <vs-td :data="item.nuc_count">
                <div>
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="add"
                    @click="enrollmentReceiptAdding(item)"
                  ></vs-button>
                </div>
              </vs-td>
              <template class="expand-user" slot="expand">
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="12"
                  >
                    <div style="width: 100%">
                      <vs-table :data="item.receipts" pagination max-item="5">
                        <template slot="header">
                          <vs-row>
                            <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              vs-w="12"
                              style="margin-bottom: 2%"
                            >
                              <b
                                style="color: gray; font-size:20px; margin-right: 15%"
                                >Receipts</b
                              >
                              <b style="color: gray">Amount Paid -</b>
                              <vs-chip color="warning">
                                <b>{{ amount_paid }}</b>
                              </vs-chip>
                              <b style="color: gray; margin-left: 5%"
                                >Pending Amount -</b
                              >
                              <vs-chip color="danger">
                                <b>{{ pending_amount }}</b>
                              </vs-chip>
                            </vs-col>
                          </vs-row>
                        </template>
                        <template slot="thead">
                          <vs-th>Can-ID</vs-th>
                          <vs-th>Person Name</vs-th>
                          <vs-th>Transaction Type</vs-th>
                          <vs-th>Cheque No</vs-th>
                          <vs-th>PDC Date</vs-th>
                          <vs-th>Transaction Id</vs-th>
                          <vs-th>TID</vs-th>
                          <vs-th>City</vs-th>
                          <vs-th>Batch</vs-th>
                          <vs-th>Amount</vs-th>
                          <vs-th>Receipt Date</vs-th>
                          <vs-th>MR Number</vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>{{ tr.identity }}</vs-td>
                            <vs-td>{{ tr.person_name }}</vs-td>
                            <vs-td>{{ tr.transaction_type }}</vs-td>
                            <vs-td>{{ tr.cheque_no }}</vs-td>
                            <vs-td>{{ tr.pde_date }}</vs-td>
                            <vs-td>{{ tr.transaction_id }}</vs-td>
                            <vs-td>{{ tr.cc_machine_tid_no }}</vs-td>
                            <vs-td>{{ tr.city }}</vs-td>
                            <vs-td>{{ tr.batch }}</vs-td>
                            <vs-td>{{ tr.amount }}</vs-td>
                            <vs-td>{{
                              unixTimestampConvert(tr.receipt_date)
                            }}</vs-td>
                            <vs-td>{{ tr.mr_number }}</vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 5%" v-if="addReceipt === true">
      <vs-col vs-type="flex" vs-justify vs-align="center" vs-w="12">
        <b style="color: gray"
          >Add a receipt to {{ rawdata[0].person_name }} for
          {{ selectedCourse }}</b
        >
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%" v-if="addReceipt === true">
      <vs-col vs-type="flex" vs-justify vs-align="center" vs-w="12"></vs-col>
      <vs-col vs-type="flex" vs-justify vs-align="center" vs-w="12"></vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    Datepicker
  },
  watch: {
    open(value) {
      if (value === false) {
        eventbus.$emit("reload-receipts");
      }
    },
    canid() {
      this.showError = false;
    },
    selectedCourse() {
      this.rawdata.forEach(enrollment => {
        if (enrollment.course === this.selectedCourse) {
          this.selectedData = enrollment.receipts;
        }
      });
    },
    transaction_type(value) {
      switch (value) {
        case "CASH":
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "NEFT":
          this.transaction_id = "";
          this.showNEFTSection = true;
          this.showChequeSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "CHEQUE":
          this.pdc_date = "";
          this.cheque_no = "";
          this.showChequeSection = true;
          this.showNEFTSection = false;
          this.showOtherSection = false;
          this.showpdcSection = false;
          break;
        case "ONLINE (CC)":
          this.otherType = "";
          this.otherid = "";
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = true;
          this.showpdcSection = false;
          break;
        case "PDC":
          this.otherType = "";
          this.otherid = "";
          this.showpdcSection = true;
          this.showNEFTSection = false;
          this.showChequeSection = false;
          this.showOtherSection = false;
      }
    }
  },
  mounted() {
    document.getElementById("addreceiptpopup").children[1].style.width = "80%";
    eventbus.$on("open-add-receipt-popup", payload => {
      let year = new Date().getFullYear();
      let month = new Date().getMonth();
      let date = new Date().getDate();
      this.disabledDatesCheque = {
        to: new Date(year, month, date),
        from: new Date(year, month, date + 4)
      };
      this.disabledDatesPdcCheque = {
        to: new Date(year, month, date + 4)
        // from: new Date(year, month, date + 4)
      };
      console.log("this is being called");
      this.searched = false;
      this.showError = false;
      this.addReceipt = false;
      this.showReceiptAdding = false;
      this.showNEFTSection = false;
      this.canid = "";
      this.otherType = "";
      this.otherid = "";
      this.transaction_type = "";
      this.transaction_id = "";
      this.cheque_no = "";
      this.pdc_date = "";
      this.cc_machine_tid_no = "";
      this.amount = "";
      this.amount_paid = "";
      this.pending_amount = "";
      this.pdcChequeNo = "";
      this.amount = "";
      this.ReceiptNumber = "";
      this.pdcActualDate = "";
      this.onlineId = "";
      this.showChequeSection = false;
      // this.getSpoc();
      this.searchCanid(payload);
      this.transaction_type = "NEFT";
      this.open = true;
    });
  },
  data() {
    return {
      disabledDatesCheque: {
        to: "",
        from: ""
      },
      disabledDatesPdcCheque: {
        to: "",
        from: ""
      },
      selectedCourse: "",
      enrollments: [],
      amount_paid: "",
      pending_amount: "",
      popupwidth: "40%",
      rawdata: [],
      canid: "",
      otherType: "",
      otherid: "",
      showpdcSection: false,
      showOtherSection: false,
      selectedData: [],
      searched: false,
      open: false,
      identityCityCode: "",
      onlineId: "",
      pdcChequeNo: "",
      pdcActualDate: "",
      ReceiptNumber: "",
      registeredSpoc: 0,
      showError: false,
      addReceipt: false,
      list_of_transactions: ["NEFT", "CASH", "CHEQUE", "ONLINE (CC)", "PDC"],
      transaction_type: "",
      transaction_id: "",
      cheque_no: "",
      pdc_date: "",
      cc_machine_tid_no: "",
      amount: "",
      enrollmentData: "",
      showReceiptAdding: false,
      showNEFTSection: false,
      showChequeSection: false
    };
  },
  methods: {
    addReceiptToEnrollment() {
      if (this.checkParameters()) {
        let obj = {
          transaction_type: this.transaction_type,
          transaction_id: this.transaction_id,
          cheque_no: this.cheque_no,
          cheque_date: this.datatoTimestamp(this.pdc_date),
          pdc_no: this.pdcChequeNo,
          // pdc_date: this.datatoTimestamp(this.pdcActualDate),
          cc_machine_tid_no: this.cc_machine_tid_no,
          amount: this.amount,
          // receiptNumber: this.ReceiptNumber,
          batch: this.enrollmentData.batch,
          identity: this.enrollmentData.identity,
          city: this.enrollmentData.city,
          person_id: this.enrollmentData.person_id,
          person_name: this.enrollmentData.person_name,
          enrollment_id: this.enrollmentData.id,
          course: this.enrollmentData.course
        };
        if (this.transaction_type === "ONLINE (CC)") {
          obj.transaction_type = "ONLINE";
          obj.transaction_id = this.onlineId;
        } else if (this.transaction_type === "PDC") {
          obj.cheque_date = this.datatoTimestamp(this.pdcActualDate);
        } else if (this.transaction_type === "CHEQUE") {
          obj.cheque_date = this.datatoTimestamp(this.pdc_date);
        }
        console.log(obj);
        axios
          .post(`${constants.SERVER_API}addReceipt`, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            console.log(response);
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Successfully added a receipt",
                text: "Receipt's been added to this enrollment",
                color: "success"
              });
              this.rawdata.forEach(data => {
                if (data.id === this.enrollmentData.id) {
                  data.receipts.splice(0, 0, response.data.receipt_data);
                  this.transaction_type = "";
                  this.transaction_id = "";
                  this.cheque_no = "";
                  this.pdcActualDate = "";
                  this.onlineId = "";
                  this.pdcChequeNo = "";
                  this.pdc_date = "";
                  this.cc_machine_tid_no = "";
                  this.amount = "";
                  this.showNEFTSection = false;
                  this.showChequeSection = false;
                  this.otherType = "";
                  this.otherid = "";
                  this.showOtherSection = false;
                }
              });
            } else {
              this.$vs.notify({
                title: response.data.status,
                text: response.data.message,
                color: "danger"
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    },
    checkParameters() {
      if (this.transaction_type === "CASH") {
        if (this.amount !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter an amount",
            text: "Enter an amount to continue",
            color: "danger"
          });
          return false;
        }
      } else if (this.transaction_type === "NEFT") {
        if (this.amount !== "" && this.transaction_id !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger"
          });
          return false;
        }
      } else if (this.transaction_type === "CHEQUE") {
        if (
          this.amount !== "" &&
          this.cheque_no !== "" &&
          this.pdc_date !== ""
        ) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger"
          });
          return false;
        }
      } else if (this.transaction_type === "ONLINE (CC)") {
        if (this.amount !== "" && this.onlineId !== "") {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger"
          });
          return false;
        }
      } else if (this.transaction_type === "PDC") {
        if (
          this.amount !== "" &&
          this.pdcChequeNo !== "" &&
          this.pdcActualDate !== ""
        ) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter all the details",
            text: "Enter all the required details",
            color: "danger"
          });
          return false;
        }
      }
      return false;
    },
    enrollmentReceiptAdding(item) {
      this.enrollmentData = item;
      this.showReceiptAdding = true;
    },
    receiptadding() {
      if (this.rawdata.length === 0) {
        this.$vs.notify({
          title: "Search for a lead",
          text: "Search for a lead to a receipt",
          color: "warning"
        });
        return;
      } else {
        this.addReceipt = true;
      }
    },
    searchCanid(payload) {
      this.popupwidth = "80%";
      this.searched = true;
      this.addReceipt = false;
      let canid = payload;
      let url = `${constants.SERVER_API}searchEnrollmentWithCanId`;
      let params = {
        identity: canid
      };
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.length === 0) {
            this.showError = true;
          } else {
            this.showError = false;
            this.rawdata = [];
            this.enrollments = [];
            this.selectedCourse = "";
            response.data.forEach(enrollment => {
              this.amount_paid = enrollment.amount_paid;
              this.pending_amount = enrollment.amount_pending;
              this.rawdata.push(enrollment);
              this.enrollments.push(enrollment.course);
            });
            this.rawdata.forEach(enrollment => {
              if (enrollment.course === "CPA") {
                this.selectedData = enrollment.receipts;
              }
            });
            this.selectedCourse = this.enrollments[0];
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.registeredSpoc = response.data.logged_in_user_id;
          response.data.spocs.forEach(spoc => {
            if (spoc.id === this.registeredSpoc) {
              this.identityCityCode = spoc.city.charAt(0);
            }
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style>
#datepicker {
  width: 160px;
  right: 0px;
}
</style>
