<template>
  <div id="centerView">
    <vs-popup class="holamundo" fullscreen title="USP Meet & Greet" :active.sync="open_popup" @close="closePopup">
      <vs-row vs-w="12" style="font-size: larger; color: black">
        <vs-col vs-w="6">
          <div
            style="border: 1px solid rgba(131, 158, 254, 0.7); border-radius: 15px; padding: 30px;background-color: #f2f5ff;">
            <p>Hello {{ payload.mwb.person_name }}</p><br />

            <p>Greetings of the day!</p><br />

            <p>We are glad to inform you that a one-on-one mentoring session has been
              scheduled with our alumni,{{ preferred_mentor }}, from Case Western Reserve
              University. {{ preferred_mentor }} will be able to answer any of your questions
              or concerns about living in the US.</p><br />

            <p>Would you be available on {{ getDate(dates) }} at {{ available_slot }} to get on a video call
              for 15 minutes with our alumni?</p><br />

            <p>Looking forward to seeing you there!"</p><br />

            <p>Kindly let me know if this suffices or if you require anything more.</p><br />

            <p>Thanks and regards,</p>
          </div>
        </vs-col>

        <vs-col vs-w="6">
          <div class="personna-container mb-5">
            <div class="m-6">
              <vs-row class="mt-5">
                <vs-col class="details_heading" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.9"
                  vs-offset="0.3">
                  <p class="details_sub_heading">Pathway Profiling</p>
                </vs-col>
              </vs-row>
              <div class="persona_details_contant" style="min-height:107px;">
                <vs-row class="my-5">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5.7" vs-offset="0.3"
                    style="padding: 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading mb-1" style="color: #1900af">Are you interested in working in the US for 3
                        years?</p>
                      <v-select style="z-index: 1600;width: 100%;
                border: 1px solid #1900af;
                border-radius: 6px;
              " class="pathway_select" v-model="select_pathway_value" :options="pathway_value_option"></v-select>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5.7" vs-offset="0.3"
                    style="padding: 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading mb-1" style="color: #1900af">Are you looking at job change in India right
                        now ?</p>
                      <v-select style="z-index: 1600;width: 100%;
                border: 1px solid #1900af;
                border-radius: 6px;
              " class="pathway_select" v-model="selected_placement_assistance"
                        :options="placement_assistance_options"></v-select>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
              <vs-row class="mt-5">
                <vs-col class="details_heading" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3.4"
                  vs-offset="0.3">
                  <p class="details_sub_heading">Work Ex Profiling</p>
                </vs-col>
              </vs-row>
              <div class="persona_details_contant" style=" display: flex;height:105.45px;">
                <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px">
                    <div style="width: 100%">
                      <p class="sub_heading mb-1" style="color: #820e0e">Work Ex Profiling</p>
                      <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  " class="work_contant">
                        <p class="sub_heading" style="color: #820e0e;">{{ selected_persona }}</p>
                      </div>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading mb-1" style="color: #370873">Company Name *</p>
                      <vs-input class="company_input" v-model="company" />
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px 1.5% 0px 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading mb-1" style="color: #3e4f0d">
                        No of Years of Work Experience *
                      </p>
                      <vs-input class="experience_input" v-model="experience" />
                    </div>
                  </vs-col>
                </vs-row>
              </div>



              <vs-row class="mt-5">
                <vs-col class="details_heading" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4.8"
                  vs-offset="0.3">
                  <p class="details_sub_heading">Professional Qualification Profiling</p>
                </vs-col>
              </vs-row>
              <div class="persona_details_contant" style="min-height:107px">
                <div class="my-5">
                  <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5.7" vs-offset="0.2"
                      style="padding: 0px">
                      <div style="width: 100%;">
                        <p class="sub_heading mb-1" style="color: #084b71">
                          Indian Professional Qualification *
                        </p>
                        <treeselect placeholder=" " class="pg_select" v-model="selected_indian_prof" :multiple="true"
                          openDirection="bottom" :options="indian_professional_qualifications" style="
                z-index: 2000;
                border: 1px solid #084b71;
                border-radius: 6px;
                min-height: 38px;
                color: #084b71;
              " />
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5.7" vs-offset="0.2"
                      style="padding: 0px 1.5% 0px 0px">
                      <div style="width: 100%;">
                        <p class="sub_heading mb-1" style="color: #3E4F0D">
                          Global Professional Qualification *
                        </p>
                        <treeselect placeholder=" " class="pg_select" v-model="selected_global_prof" :multiple="true"
                          openDirection="bottom" :options="global_professional_qualifications" style="
                z-index: 2000;
                border: 1px solid #084b71;
                border-radius: 6px;
                min-height: 38px;
                color: #3E4F0D;
              " />
                      </div>
                    </vs-col>
                  </vs-row>

                </div>
              </div>

              <vs-row class="mt-5">
                <vs-col class="details_heading" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
                  vs-offset="0.3">
                  <p class="details_sub_heading">Others</p>
                </vs-col>
              </vs-row>
              <div class="persona_details_contant" style="min-height:107px">
                <vs-row vs-type="flex" vs-justify="center" vs-align="center" class="mt-6 mb-5">
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading" style="color: rgba(182, 0, 110, 0.8)">
                        UG Graduate Qualification *
                      </p>
                      <treeselect placeholder="" v-model="selected_ug" :multiple="true" :options="ug_options"
                        class="ug_select" openDirection="bottom" style="
                z-index: 1800;
                border: 1px solid rgba(182, 0, 110, 0.8);
                border-radius: 5px;
                color: rgba(182, 0, 110, 0.8);
              " />
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px 1.5% 0px 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading" style="color: #217874">PG Qualification</p>
                      <treeselect placeholder=" " class="pg_select" v-model="selected_pg" :multiple="true"
                        :options="pg_options" openDirection="bottom" style="
                z-index: 1800;
                border: 1px solid #217874;
                border-radius: 6px;
                min-height: 38px;
                color: #217874;
              " />
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.7" vs-offset="0.2"
                    style="padding: 0px 1.5% 0px 0px">
                    <div style="width: 100%;">
                      <p class="sub_heading mb-1" style="color: rgba(95, 19, 155, 0.8)">
                        Current Location *
                      </p>
                      <vue-google-autocomplete class="current_location" ref="alumniAddress1" id="alumni-map1" placeholder=""
                        v-on:placechanged="getAddressDetails" types="(regions)" style="
                position: relative;
                z-index: 1000;
                width: 100%;
                height: 38px;
                padding-inline-start:7px;
                border: 1px solid rgba(95, 19, 155, 0.8);
                border-radius: 6px;
                color: rgba(95, 19, 155, 0.8);" v-model="current_location">
                      </vue-google-autocomplete>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>


          <div class="alumni-mentoring-container">
            <vs-row>
              <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="popup-head mb-4">
                <h5>USP Alumni Connect || Miles Education</h5>
              </vs-col>
            </vs-row>
            <vs-row class="mt-4 pl-16">
              <vs-col vs-offset="0.5" vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Preferred Mentor</p>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Block the Date</p>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" class="mb-4 mt-2">
                <div id="scroll" v-on:click="select($event)">
                <v-select placeholder="Select Mentor" style="z-index: 1003; width: 225px" v-model="preferred_mentor"
                  :options="mentors" :disabled="disabled_mentor"></v-select>
                </div>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="5" class="mb-4 mt-2">
                <div id="scroll" v-on:click="select($event)">
                <datepicker :format="customFormatter" v-model="dates" placeholder="Select Date"
                  :disabledDates="disabledDates" style="z-index: 1002; width: 225px"></datepicker>
                </div>
              </vs-col>

              <vs-col vs-offset="0.5" vs-w="10" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="select-head">
                <p>Available Time Slots</p>
              </vs-col>

              <vs-col vs-offset="0.5" vs-w="10" class="mb-4 mt-2">
                <div id="scroll" v-on:click="select($event)">
                <v-select placeholder="Select Slot" style="z-index: 1001; width: 225px" v-model="available_slot" 
                  :options="available_slots"></v-select>
                </div>
              </vs-col>
              <vs-col vs-offset="0.5" vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center" class="mt-4">
                <vs-button color="#78AE7E" type="filled" @click="submitData" :disabled="disabled" id="button-with-loading"
                  class="vs-con-loading__container">SCHEDULE APPOINTMENT
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import ApiServices from "../../../ApiServices.js";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import constants from "../../../../constants.json";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import EventBus from "../../eventbus";
export default {
  props: ["macc_popup", "payload"],
  components: {
    Datepicker,
    VueTimepicker,
    "v-select": vSelect,
    "vue-google-autocomplete": VueGoogleAutocomplete,
    Treeselect
  },
  data() {
    return {
      selected_placement_assistance: "",
      // persona_details :{},
      selected_persona: "",
      show_details: false,
      select_pathway_value: "",
      persona_option: [
        "No work ex - College Student",
        "No work ex - Graduate",
        "Working Professional",
        "Prior work-ex - but currently not working",
      ],
      company: "",
      experience: "",
      current_location: "",
      select_pathway: "",
      pathway_value_option: [
        "Yes",
        "Yes but graduating in 2025 or after",
        "No",
        "Maybe",
      ],
      placement_assistance_options: [
        "Yes",
        "No",
        "Maybe"
      ],
      ug_options: [],
      pg_options: [],
      indian_professional_qualifications: [],
      global_professional_qualifications: [],
      selected_indian_prof: [],
      selected_global_prof: [],
      selected_ug: [],
      selected_pg: [],
      emit_pathway: false,
      persona_details: {},
      persona: '',
      pathway: '',
      disabled_mentor: false,
      current_time: moment(),
      spoc_city: "",
      disabledDates: {
        to: new Date(moment(new Date()).format('YYYY, MM ,DD')),
      },
      options1: [],
      open_popup: false,
      schedule_session: "",
      preferred_mentor: "",
      slot_duration: "",
      available_slot: null,
      agenda_for_the_session: '',
      value2: "",
      sessions: [
        "Academic Queries - AUD",
        "Academic Queries - FRM",
        "Academic Queries - CPA",
        "Academic Queries - CMA",
      ],
      mentors: ["Pooja Fulia", "Ujwal Avinash Kashyap", "Sai Teja", "Disha Hegde", "Pratibha Gupta", "Reepal Patel"],
      slots: [
        "15 Minutes",
        "20 Minutes",
        "30 Minutes",
        "40 Minutes",
        "45 Minutes",
        "50 Minutes",
        "60 Minutes",
      ],
      available_slots: [],
      all_slots: [
        "15:30:00",
        "15:50:00",
        "16:10:00",
        "16:30:00",
        "16:50:00",
        "17:10:00",
        "17:50:00",
        "18:10:00",
        "18:30:00",
        "18:50:00",
        "19:10:00",
      ],
      disabled: false,
      backgroundLoading: "primary",
      colorLoading: "#fff",
      current_ctc_alert: false,
      current_ctc_alert_message: "",
      experience_alert: false,
      experience_alert_message: "",
      dates: new Date(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      ),
      // slots: [
      //     "Morning",
      //     "Afternoon",
      //     "Evening",
      // ],
      time_slot: "Morning",
      current_ctc: null,
      preferred_time_slot: "",
      preferred_date_time: "",
      qualification: [],
      qualifications: [
        "PUC",
        "INTER",
        "B.Com",
        "B.Com(P)",
        "M.Com",
        "M.Com(P)",
        "MBA",
        "MBA(P)",
        "CA",
        "CWA",
        "CS",
        "BMS",
        "BMS(P)",
        "BBA",
        "BBA(P)",
        "PGD",
        "PGD(P)",
        "LLB",
        "LLB(P)",
        "B.Tech",
        "B.Tech(P)",
        "B.Sc",
        "B.Sc(P)",
        "BA",
        "BA(P)",
        "CA-Inter",
        "CWA-Inter",
        "CS-Inter",
        "PG(P)",
        "CA Final",
        "CWA Final",
        "CS Final",
        "IGNOU - B.Com (CA)",
        "IGNOU - B.Com (CWA)",
        "IGNOU - B.Com (CS)",
        "MCA",
        "PGDPA",
        "PGDSF",
        "MA",
        "PGDM",
        "BBM",
        "BCA",
        "SCESA",
        "CFA",
        "FRM",
        // "MAcc",
        "USP",
        "CFA(P)",
        "MFA",
        "MFA(P)",
        "CMA(US)",
        "CPA(US)",
        "BAF",
        // { text: "", value: 0 },
        // { text: "B.Com", value: 0 },
        // { text: "B.Com(P)", value: 18 },
        // { text: "M.Com", value: 1 },
        // { text: "M.Com(P)", value: 19 },
        // { text: "MBA", value: 2 },
        // { text: "MBA(P)", value: 20 },
        // { text: "CA", value: 3 },
        // { text: "CWA", value: 4 },
        // { text: "CS", value: 5 },
        // { text: "BMS", value: 6 },
        // { text: "BMS(P)", value: 21 },
        // { text: "BBA", value: 7 },
        // { text: "BBA(P)", value: 17 },
        // { text: "PGD", value: 8 },
        // { text: "PGD(P)", value: 22 },
        // { text: "LLB", value: 9 },
        // { text: "LLB(P)", value: 23 },
        // { text: "B.Tech", value: 10 },
        // { text: "B.Tech(P)", value: 24 },
        // { text: "B.Sc", value: 11 },
        // { text: "B.Sc(P)", value: 25 },
        // { text: "BA", value: 12 },
        // { text: "BA(P)", value: 26 },
        // { text: "CA-Inter", value: 13 },
        // { text: "CWA-Inter", value: 14 },
        // { text: "CS-Inter", value: 15 },
        // { text: "PG(P)", value: 16 },
        // { text: "CA Final", value: 16 },
        // { text: "CWA Final", value: 16 },
        // { text: "CS Final", value: 16 },
        // // { text: "IGNOU", value: 16 },
        // { text: "IGNOU - B.Com (CA)", value: 16 },
        // { text: "IGNOU - B.Com (CWA)", value: 16 },
        // { text: "IGNOU - B.Com (CS)", value: 16 },
        // { text: "MCA", value: 16 },
        // { text: "PGDPA", value: 16 },
        // { text: "PGDSF", value: 16 },
        // { text: "PGDM", value: 16 },
        // { text: "BBM", value: 16 },
        // { text: "SCESA", value: 16 }
      ],
      candidate_google_location: "",
      iiml_level: 'U2',
    };
  },
  mounted() {
    this.getAllQualifications();
    this.getCandidatePersonaDetails();
    this.candidate_google_location = this.payload.google_location;
    this.iiml_level = this.payload.mwb.iiml_level

    this.preferred_mentor = 'Sai Teja'
    if (this.preferred_mentor == '' || this.preferred_mentor == null) {
      this.disabled = true
    }
    this.$refs.alumniAddress1.focus();
    // this.getAvailableSlots();
    this.getPreviousDay(new Date())
    const start = moment();
    const remainder = 15 - (start.minute() % 30);
    this.current_time = moment(start).add(remainder, "minutes").format("HH:mm:00");
    this.getLatestTimeSlot();
    console.log("payload11", this.payload);
    this.experience = this.payload.mwb.experience_int;
    this.current_ctc = this.payload.mwb.current_ctc;
    if (this.payload.mwb.education_tags !== null) {
      this.qualification = this.payload.mwb.education_tags.split(",");
    } else {
      this.qualification = [];
    }
    this.getEducationTags();
    this.available_slot = this.all_slots[0]

    // this.getTimeSlot();
  },
  watch: {
    persona_details: function (val) {
      this.persona_details = val;
    },
    company: function () {
      this.emitData();
      this.getdeterminePersonaRealtime(this.experience);
    },
    current_location: function () {
      this.emitData();
    },
    selected_placement_assistance: function () {
      this.emitData();
    },
    selected_pg: function () {
      this.emitData();
      this.getdeterminePersonaRealtime(this.experience);
    },
    selected_indian_prof: function () {
      this.emitData();
    },
    selected_global_prof: function () {
      this.emitData();
    },
    selected_ug: function () {
      this.emitData();
      this.getdeterminePersonaRealtime(this.experience);
    },
    experience: function (val) {
      this.emitData();
      if (val.toString().length != 0) {
        this.getdeterminePersonaRealtime(val);
      }
    },
    select_pathway_value: function (val) {
      var level = "U2";
      if (val == "No") {
        this.select_pathway = "Pathway 1 - India";
        level = "U0"
      } else if (val == "Yes") {
        this.select_pathway = "Pathway 2 - US";
        level = "U3"
      } else if (val == "Maybe") {
        this.select_pathway = "Pathway 3 - Canada";
        level = "U2"
      } else if (val == "Yes but graduating in 2025 or after") {
        this.select_pathway = "Pathway 2 - US";
        level = "U1+"
      }
      this.iiml_level = level
      this.emitData();
    },
    select_pathway: function () {
      this.emitData();
    },
    selected_persona: function () {
      // EventBus.$emit("alumnii-persona-changed", val); 007
    },
    macc_popup: function (val) {
      this.open_popup = val;
      if (val == true) {
        this.current_ctc_alert = false;
        this.experience_alert = false;
      }
    },
    current_ctc: function (val) {
      let float_ctc = parseFloat(val);
      if (float_ctc > 100) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be above 100";
      } else if (isNaN(float_ctc) && val != '' && val != null) {
        this.current_ctc_alert = true;
        this.current_ctc_alert_message = "CTC cannot be a string";

      } else if (val == "") {
        this.current_ctc = null;
        this.current_ctc_alert = false;


      }
      else {
        this.current_ctc_alert = false;


      }
    },
    preferred_mentor: function (val) {
      this.dates = new Date(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      );
      this.getMentorSlots(val)


    },
    dates: function () {
      // this.available_slot = null
      if (this.preferred_mentor !== "" && this.preferred_mentor !== null) {
        this.getMentorSlots(this.preferred_mentor)
      } else {
        this.available_slots = [];
        this.getLatestTimeSlot();
      }
    },
    available_slot: function (val) {
      console.log(val);
    }
  },
  methods: {
    getMentorSlots(val) {
      let working_hours = [{ name: 'Pooja Fulia', start_hour: 19, start_mins: 30, end_hour: 23, end_mins: 30, not_available: false, change_in_schedule: false },
      { name: 'Pratibha Gupta', start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30, not_available: false, change_in_schedule: false },
      { name: 'Ujwal Avinash Kashyap', start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30, not_available: false, change_in_schedule: true, change_in_schedule_dates: [{ date: "2023-05-09" , start_hour: 14, start_mins: 30, end_hour: 17, end_mins: 0 },] },
      { name: 'Sai Teja', start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30, not_available: false, change_in_schedule: false },
      { name: 'Disha Hegde', start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30, not_available: false, change_in_schedule: false },
      { name: 'Reepal Patel', start_hour: 17, start_mins: 30, end_hour: 19, end_mins: 30, not_available: false, change_in_schedule: true, change_in_schedule_dates: [{ date: "2023-05-11", start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30 }, { date: "2023-05-12", start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30 }, { date: "2023-05-13", start_hour: 15, start_mins: 30, end_hour: 19, end_mins: 30 }] },]
      working_hours.forEach(m => {
        if (m.name == val) {
          this.getTimeSlots(new Date(1970, 0, 1, m.start_hour, m.start_mins, 0), new Date(1970, 0, 1, m.end_hour, m.end_mins, 0))
          if (m.change_in_schedule) {
            m.change_in_schedule_dates.forEach(change => {
              if (change.date == moment(this.dates).format("YYYY-MM-DD")) {
                this.getTimeSlots(new Date(1970, 0, 1, change.start_hour, change.start_mins, 0), new Date(1970, 0, 1, change.end_hour, change.end_mins, 0))
              }
            });
          }
          if (m.not_available) {
            this.disabledDates = {
              to: new Date(moment(new Date()).format('YYYY, MM ,DD')),
              dates: [new Date(m.year, m.month, m.date)]
            }
          } else {
            this.disabledDates = {
              to: new Date(moment(new Date()).format('YYYY, MM ,DD')),
            }
          }
        }
      });
      this.available_slot = null
      if (val !== "" && val !== null) {
        this.disabled = false
        // new line added
        // this.getAvailableSlots();
        this.getLatestTimeSlot();
      } else {
        this.disabled = true
        this.available_slots = [];
        this.getLatestTimeSlot();
      }
    },
    select: function (event) {
      let targetId = event.currentTarget.id;
      let element = document.getElementById("scroll");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      console.log(targetId);
    },
    getTimeSlots(from, to) {
      const startTime = from;
      const endTime = to;
      const interval = 20; // in minutes
      const twoHours = 2 * 60 * 60 * 1000; // in milliseconds
      let currentTime = startTime;
      const times = [];

      while (currentTime <= endTime) {
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const seconds = currentTime.getSeconds().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        times.push(formattedTime);


        currentTime = new Date(currentTime.getTime() + interval * 60 * 1000);

        if (currentTime.getTime() - startTime.getTime() === twoHours) {
          currentTime = new Date(currentTime.getTime() + 20 * 60 * 1000);
        }
      }

      console.log('shash', times);
      this.all_slots = times
    },
    getdeterminePersonaRealtime(experience) {
      this.experience = experience;
      let selected_pg = "";
      if (this.selected_pg.length != 0 && this.selected_pg != null) {
        selected_pg = this.selected_pg.join();
      } else {
        selected_pg = 'NA'
      }
      let selected_ug = "";
      if (this.selected_ug != null && this.selected_ug.length != 0) {
        selected_ug = this.selected_ug.join();
      } else {
        selected_ug = 'NA'
      }
      let company_value = false;
      let onWorkExCollageStudent = false;
      let onWorkExGraduate = false;
      let workingProfessional = false;
      let exWorkingProfessional = false;
      let NA = false;
      if (this.company != null) {
        if (this.company.startsWith('EX-') || this.company.startsWith('ex-') || this.company.startsWith('Ex-') || this.company.startsWith('eX-')) {
          company_value = true;
        }
      }
      if (this.experience == 0 && selected_pg == 'NA' && selected_ug == 'NA') {
        onWorkExCollageStudent = true;
      } else if (this.experience == 0 && (selected_pg != 'NA' || selected_ug != 'NA')) {
        onWorkExGraduate = true;
      } else if (this.experience >= 1 && !company_value && (company_value != "NA" || company_value != '' || company_value != null)) {
        workingProfessional = true;
      } else if (this.experience >= 1 && company_value && (company_value != "NA" || company_value != '' || company_value != null || selected_pg == 'NA' || selected_pg == 'NA')) {
        exWorkingProfessional = true;
      } else {
        NA = true;
      }

      if (onWorkExCollageStudent) {
        this.selected_persona = "No work ex - College Student";
      }
      if (onWorkExGraduate) {
        this.selected_persona = "No work ex - Graduate";
      }

      if (workingProfessional) {
        this.selected_persona = "Working Professional";
      }

      if (exWorkingProfessional) {
        this.selected_persona = "Prior work-ex - but currently not working";
      }
      if (NA) {
        this.selected_persona = "NA (Not Available) Non-define Data";
      }
    },
    emitData() {
      let params = {
        mwb_id: this.mwb_id,
        company: this.company,
        location: this.current_location,
        pathway: this.select_pathway,
        pathway_value: this.select_pathway_value,
        // persona: this.selected_persona,
        placement_assistance: this.selected_placement_assistance,
        pg_qualification: this.selected_pg.join(),
        indian_professional_qualification: this.selected_indian_prof.join(),
        global_professional_qualification: this.selected_global_prof.join(),
        ug_qualification: this.selected_ug.join(),
        years_of_experience: this.experience,
      }
      // console.log("EMITING UPDATED Data",params)

      this.checkPersonaData(params);

    },
    getCandidatePersonaDetails() {
      this.persona_details = {};
      let mwb_id = this.payload.mwb.id;
      let url = `https://mfcomms.2x2.ninja/api/getCandidatePersonaDetails?mwb_id=${mwb_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.persona_details = response.data;
          this.personna_data = true;
          this.checkPersonaData(this.persona_details)

          console.log("PERSONA ffffff", this.persona_details)
          this.selected_ug = [];
          this.selected_pg = [];
          this.selected_ug = [];
          this.selected_pg = [];
          this.current_location = "";
          this.company = "";
          this.select_pathway = "";
          this.selected_indian_prof = [];
          this.selected_global_prof = [];
          this.selected_persona = "";
          this.company = "";
          this.experience = "";

          if (Object.keys(this.persona_details).length > 3) {
            if (this.persona_details.ug_qualification != "NA") {
              this.selected_ug = this.persona_details.ug_qualification.split(",");
            }

            if (this.persona_details.pg_qualification != "NA") {
              this.selected_pg = this.persona_details.pg_qualification.split(",");
            }

            if (this.persona_details.years_of_experience != "NA" || this.persona_details.years_of_experience != null) {
              this.experience = this.persona_details.years_of_experience
            }

            if (this.persona_details.pathway_value != "NA") {
              this.select_pathway_value = this.persona_details.pathway_value
            }

            if (
              this.persona_details.indian_professional_qualification != "NA"
            ) {
              this.selected_indian_prof = this.persona_details.indian_professional_qualification.split(',');
            }

            if (
              this.persona_details.global_professional_qualification != "NA"
            ) {
              this.selected_global_prof = this.persona_details.global_professional_qualification.split(',');

            }

            if (this.persona_details.persona != "NA" && this.persona_details.persona != 'NA (Not Available) Non-define Data') {
              this.selected_persona = this.persona_details.persona
            }

            if (this.persona_details.company != "NA") {
              this.company = this.persona_details.company;
            }

            if (this.persona_details.placement_assistance != "NA") {
              this.selected_placement_assistance = this.persona_details.placement_assistance;
            }

            this.current_location = this.persona_details.location;

          } else {

            if (this.persona_details.company != "NA") {
              this.company = this.persona_details.company;
            }

            this.current_location = this.persona_details.location;
            if (this.persona_details.years_of_experience == "NA" || this.persona_details.years_of_experience == null || this.persona_details.years_of_experience == "") {
              this.experience = '';
            } else {
              this.experience = this.persona_details.years_of_experience
            }

          }
        })
        .catch((error) => {
          this.handleError(error);
          this.personna_data = true;
          this.$vs.loading.close();
        });
    },
    getAllQualifications() {
      this.$vs.loading();
      let url = `https://mfcomms.2x2.ninja/api/getAllQualifications`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Qualification", response.data);
          for (let i = 0; i < response.data.ug.length; i++) {
            const element = response.data.ug[i];
            const obj = {
              id: element,
              label: element,
            };
            this.ug_options.push(obj);
          }
          for (let i = 0; i < response.data.pg.length; i++) {
            const element = response.data.pg[i];
            const obj = {
              id: element,
              label: element,
            };
            this.pg_options.push(obj);
          }
          for (
            let i = 0;
            i < response.data.indian_professional_qualification.length;
            i++
          ) {
            const element = response.data.indian_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.indian_professional_qualifications.push(obj);
          }

          for (
            let i = 0;
            i < response.data.global_professional_qualification.length;
            i++
          ) {
            const element = response.data.global_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.global_professional_qualifications.push(obj);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    getAddressDetails: function (addressData) {
      this.current_location =
        addressData.locality +
        "," +
        addressData.administrative_area_level_1 +
        "," +
        addressData.country;
    },
    checkPersonaData(new_data) {
      this.persona_details = new_data
    },
    // getAvailableSlots() {
    //   let date = moment(this.dates).format("YYYY-MM-DD");
    //   if (this.preferred_mentor !== "" && this.preferred_mentor !== null) {
    //     ApiServices.bookedSlotsForHrMacc(this.preferred_mentor, date)
    //       .then((response) => {
    //         let booked_slots = response.data;
    //         let all_slotss = this.available_slots
    //           .filter((x) => !booked_slots.includes(x));
    //         // let all_slotss = this.available_slots
    //         //   .filter((x) => !booked_slots.includes(x)).concat(booked_slots.filter((x) => !this.available_slots.includes(x)));
    //         console.log("not available slots", this.available_slots);
    //         this.available_slots = []
    //         this.available_slots = all_slotss

    //       })
    //       .catch((error) => {
    //         this.showCustomNotification(error, error, "danger", 2000);
    //       });
    //   } else {
    //     this.available_slots = this.all_slots
    //   }
    // },
    getPreviousDay(date = new Date()) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);
      this.disabledDates.to = previous
    },
    getLatestTimeSlot() {
      let present_date = moment(this.dates).format("YYYY-MM-DD");
      let selected_date = moment(new Date()).format("YYYY-MM-DD");
      let newTimeSlots = [];
      let curr_time = moment(new Date()).format("HH:mm:ss");
      console.log("curr time", curr_time);
      if (present_date == selected_date) {
        for (let i = 0; i < this.all_slots.length; i++) {
          let element = this.all_slots[i]
          // if (element < this.current_time) {
          if (element > curr_time) {
            newTimeSlots.push(element)
          }

          // }
        }
        this.available_slots = []
        this.available_slots = newTimeSlots
      } else {
        this.available_slots = this.all_slots
      }
    },
    getTimeSlot() {
      const date = new Date();
      const h = date.getHours();
      const m = date.getMinutes();
      const slot_time = [];
      const now_time = h + ":" + m + ":" + "00";
      console.log("date", now_time, this.all_slots);
      for (let i = 0; i < this.all_slots.length; i++) {
        const element = this.all_slots[i];
        if (now_time <= element) {
          slot_time.push(element);
        }
      }
      slot_time.sort();
      this.available_slot = slot_time[0];
    },
    refreshTextarea() {
      this.agenda_for_the_session = "";
    },
    closePopup() {
      this.available_slot = "";
      this.preferred_mentor = "";
      this.$emit("close-macc-popup");
      this.current_ctc_alert = false;
      this.experience_alert = false;
    },
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    getDate(dates) {
      return moment(dates, "DD-MM-YYYY").format("DD-MMM-YYYY");
    },
    clearFields() {
      this.available_slot = "";
      this.agenda_for_the_session = ""
      this.closePopup();
    },
    submitData() {
      let date = moment(this.dates).format("YYYY-MM-DD");
      if (this.available_slot == '' || this.available_slot == null) {
        this.showCustomNotification(
          "Field Required",
          "select time slot",
          "danger",
          2000
        );
        return
      } else if (isNaN(Number(this.persona_details.years_of_experience))) {
        this.$vs.notify({
          text: "Experience Cannot Be String in Persona",
          color: "danger",
        });
        return
      }
      else if ((document.getElementById("alumni-map1").value == null || document.getElementById("alumni-map1").value == "")) {
        this.$vs.notify({
          text: "Location cannot be empty",
          color: "danger",
        });
        return
      } else if (this.available_slot !== null && this.preferred_mentor != null && this.preferred_mentor != '') {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-with-loading",
          scale: 0.45,
        });
        let candidate_details = {
          mwb_id: this.payload.mwb.id,
          // professional_qualification: this.qualification.join(),
          // company: this.payload.mwb.company,
          // years_of_experience: parseFloat(this.experience),
          // current_location: document.getElementById("map1").value,
          // current_ctc: parseFloat(this.current_ctc),map1
          preferred_time_slot: this.time_slot.toLowerCase(),
          date: date,
          hr_name: this.preferred_mentor,
          slot: this.available_slot,
          agenda: this.agenda_for_the_session,
        };
        console.log('candidate_details', candidate_details);
        ApiServices.maccBookHrSlot(candidate_details)
          .then((response) => {
            console.log("storeMeetAndGreet", response);
            this.$vs.notify({
              title: 'USP Meet&Greet scheduled successfully',
              color: "success",
            });
            this.updatePersonDetails();
            this.$vs.loading.close("#button-with-loading");
            this.clearFields();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.showCustomNotification(error, error, "danger", 2000);
            this.clearFields();
            this.$vs.loading.close("#button-with-loading");
          });
        this.current_ctc_alert = false;
        this.experience_alert = false;
      } else {
        // this.$vs.loading.close();
        this.showCustomNotification(
          "Field Required",
          "Timeslot & mentor is required",
          "danger",
          4000
        );
        // this.clearFields();
      }
    },
    updatePersonDetails() {
      this.$vs.loading();
      let obj = {
        mwb_id: this.payload.mwb.id,
        company: this.company,
        location: document.getElementById("alumni-map1").value,
        pathway: this.select_pathway,
        pathway_value: this.select_pathway_value,
        pg_qualification: this.selected_pg.join(),
        indian_professional_qualification: this.selected_indian_prof.join(),
        global_professional_qualification: this.selected_global_prof.join(),
        ug_qualification: this.selected_ug.join(),
        years_of_experience: this.experience,
      };
      if (this.iiml_level != '') {
        obj['iiml_level'] = this.iiml_level
      }
      console.log("obj", obj);
      let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Personna Data", response.data);
          if (response.data.status == "success") {
            EventBus.$emit("persona-candidate-updated-data", (obj))
            this.$vs.notify({
              title: "Success",
              text: "Persona Details Updated Successfully",
              color: "success",
            });
            // console.log("API AT EMIT",this.existing_persona_data ,obj)

          } else {
            this.$vs.notify({
              title: "Error",
              text: "Something went wrong",
              color: "danger",
            });
          }
          this.$vs.loading.close();
          this.persona_details = obj

        })
        .catch((error) => {
          this.handleError(error);
          this.getCandidatePersonaDetails();
          this.$vs.loading.close();
        });
    },
    getAddressData(addressData) {
      this.candidate_google_location =
        addressData.locality +
        "," +
        addressData.administrative_area_level_1 +
        "," +
        addressData.country;
      console.log("address", this.candidate_google_location);
    },
    checkAllFeilds() {
      if (
        this.candidate_google_location !== null &&
        this.preferred_mentor !== null &&
        this.preferred_mentor !== '' &&
        this.available_slot !== null &&
        this.current_ctc !== null
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkForValidations() {
      if (this.experience_alert == false && this.current_ctc_alert == false) {
        return false
      } else {
        return true
      }
    },
    getEducationTags() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getEducationTags`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("EDUCATION TAGS", response)
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              this.options1.push({
                id: response.data[i],
                label: response.data[i]
              })

            }

          }
          // this.options1 = response.data
        })
        .catch((error) => {
          this.handleError(error);
        });
    }
  },
};
</script>

<style scoped>
.vl {
  position: absolute;
  border-left: 6px solid gray;
  height: 90%;
}

.pac-container {
  background-color: #fff;
  position: relative;
  z-index: 100000000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.alert_message {
  background: #e8b4b487;
  color: rgba(var(--vs-danger), 1);
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  /* border: 2px solid; */
  border-radius: 5px;
}

.popup-head>h5 {
  color: rgba(0, 0, 0, 0.822);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
}

.select-head {
  color: rgba(0, 0, 0, 0.822);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin: 2px 0 3px 0;
}

.textarea-desc {
  color: rgba(128, 128, 128, 0.493);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.meet-and-greet-popup .vs-popup {
  height: 685px;
}

.second-row {
  margin-top: 2%;
}

.inp>.vs-input>.vs-con-input {
  border: none;
  border-bottom: 2px solid #62d158;
  outline: none;
  border-radius: 5px;
}

.inp>.vs-input {
  width: 93%;
}

.inp2>.vs-con-textarea>.vs-textarea {
  border: none;
  border-bottom: 2px solid #ff4757;
  outline: none;
  border-radius: 5px;
  height: 85px;
}

.inp2 {
  position: relative;
  width: 78% !important;
}

.button2>.vs-icon {
  font-size: 1.6rem;
}

.button2 {
  background-color: #15a884 !important;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 2px solid #15a884 !important;
  z-index: 1001;
}

.textarea2 {
  position: relative;
  z-index: 1000;
}

.alumni-mentoring-container {
  border: 1px solid rgba(131, 158, 254, 0.7);
  background-color: #f2f5ff;
  border-radius: 15px;
  margin-top: 2%;
  padding: 2% 0 2% 0;
}

.personna-container {
  background: rgba(166, 185, 254, 0.15);
  border: 1px solid rgba(131, 158, 254, 0.7);
  border-radius: 15px;
  margin-left: 5px;
}

.personna_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-left: 3px;
  color: #000000;
}

.persona-details#wp {
  border: 1px solid #820e0e;
  border-radius: 10px;
}

.company_input {
  border: 1px solid #370873;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  color: #370873;
  /* padding-left: 5px; */
}

.experience_input {
  border: 1px solid #3e4f0d;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  color: #3e4f0d;
  /* margin-left: 5px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.sub_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-left: 3px;
}

.persona_data {
  font-family: "Montserrat";
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 35px;
  text-align: center;
}

.personaa_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #0044bf;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.qualification_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #084b71;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.pathway_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #1900af;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.ug_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: rgba(182, 0, 110, 0.8);
  margin-left: 7px;
}

.pg_select.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
  color: #217874;
  margin-left: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.company_input.vs-con-input.vs-input--input.normal {
  padding-left: 14px !important;
}

.experience_input.vs-con-input.vs-input--input.normal {
  padding-left: 14px !important;
}

.details_heading {
  min-height: 35.93px;
  background: #ffffff;
  border: 1px solid rgba(131, 158, 254, 0.25);
  border-radius: 10px 10px 0px 0px;
}

.details_sub_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  color: #000000;
}

.persona_details_contant {

  background: #ffffff;
  border: 1px solid rgba(131, 158, 254, 0.25);
  border-radius: 15px;
}

.work_contant {
  min-height: 38px;
  padding: 3px 0px 3px 0px;
  border: 1px solid #820e0e;
  border-radius: 6px;
}
</style>
