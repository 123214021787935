<template>
    <div>
        <!-- <vs-row class="gap">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="10"
            >
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="2"
            >
                <vs-button
                    radius
                    color="dark"
                    type="gradient"
                    icon="edit"
                    @click="edit_accads_evaluation_exam"
                ></vs-button>
            </vs-col>
        </vs-row> -->
        <vs-row class="gap">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Agreed Cost:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <b> {{ this.agreed_cost }}</b>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Amount Paid:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <b> {{ this.amount_paid }}</b>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Amount Pending:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <b> {{ this.amount_pending }}</b>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Amount In Process:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <b> {{ this.amount_in_process }}</b>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Enrollment Date:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <b> {{ convertTimestampToDate(this.enrollment_date) }}</b>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Whatsapp Group:</vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input
                        type="radio"
                        :value="1"
                        v-model="whatsapp_group"
                    />Yes
                </label>
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="0" v-model="whatsapp_group" />No
                </label>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >LMS Access:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="1" v-model="lms_access" />Yes
                </label>
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="0" v-model="lms_access" />No
                </label>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Books:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="1" v-model="wiley_books" />Yes
                </label>
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="0" v-model="wiley_books" />No
                </label>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Evaluation:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="1" v-model="evaluation" />Yes
                </label>
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="0" v-model="evaluation" />No
                </label>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >Action:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <!-- <vs-textarea
                    v-if="bridgecourse === false"
                    disabled
                    v-model="action"
                ></vs-textarea> -->
                <vs-textarea v-model="action"></vs-textarea>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >CR Connect:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="1" v-model="cr_connect" />Yes
                </label>
            </vs-col>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
                <label>
                    <input type="radio" :value="0" v-model="cr_connect" />No
                </label>
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%" v-if="this.cr_connect === 1">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >CR Name:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <!-- <vs-input
                    v-if="bridgecourse === false"
                    disabled
                    class="w-full"
                    v-model="cr_name"
                /> -->
                <vs-input class="w-full" v-model="cr_name" />
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%" v-if="this.cr_connect === 1">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >CR Mobile:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <!-- <vs-input
                    v-if="bridgecourse === false"
                    disabled
                    class="w-full"
                    v-model="cr_mobile"
                /> -->
                <vs-input class="w-full" v-model="cr_mobile" />
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%" v-if="this.cr_connect === 1">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >CR Email:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <!-- <vs-input
                    v-if="bridgecourse === false"
                    disabled
                    class="w-full"
                    v-model="cr_email"
                /> -->
                <vs-input class="w-full" v-model="cr_email" />
            </vs-col>
        </vs-row>
        <vs-row class="gap" style="margin-top: 4%" v-if="this.cr_connect === 1">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
                >CR Company:</vs-col
            >
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 15px; color: gray"
            >
                <!-- <vs-input
                    v-if="bridgecourse === false"
                    disabled
                    v-model="cr_company"
                    class="w-full"
                /> -->
                <vs-input class="w-full" v-model="cr_company" />
            </vs-col>
        </vs-row>
        <!-- <referral></referral> -->
        <vs-row style="margin-top: 3%">
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="9"
                style="font-size: 15px; color: gray"
            ></vs-col>
            <!-- <vs-col
                v-if="bridgecourse === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray"
            >
            </vs-col> -->
            <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="3"
                style="font-size: 15px; color: gray; margin-top: 3%"
            >
                <vs-button color="dark" type="border" @click="editOnboardAPI"
                    >SAVE</vs-button
                >
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import vSelect from "vue-select";
import constants from "../../../constants.json";
import axios from "axios";
import Referral from "./referalboard";
export default {
    props: ["cpaData", "cpaEnrollment"],

    components: {
        "v-select": vSelect,
        referral: Referral,
    },
    data() {
        return {
            bridgecourse: false,
            whatsappOptions: ["Yes", "No"],
            whatsapp_group: "",
            cm_action: "",
            evaluation: "",
            wiley_books: "",
            lms_access: "",
            wiley_otb: "",
            LmsOptions: ["Yes", "No"],
            WileyOptions: ["Yes", "No"],
            EvalOptions: ["Yes", "No"],
            telegram_study_group_p1: "",
            telegramStudyGroupP1: ["Yes", "No"],
            telegram_study_group_p2: "",
            telegramStudyGroupP2: ["Yes", "No"],
            ima_membership_id: "",
            // IMAembershipid: ["Yes", "No"],
            ima_scholarship: "",
            IMAscholarship: ["Yes", "No"],
            action: "",
            cr_connect: "",
            crConnect: ["Yes", "No"],
            cr_name: "",
            cr_mobile: "",
            cr_email: "",
            cr_company: "",
            agreed_cost: "",
            amount_paid: "",
            amount_pending: "",
            amount_in_process: "",
            enrollment_date: "",
        };
    },
    watch: {
        whatsapp_group(val) {
            console.log("call u", val);
            if (val === 0) {
                this.wa_group = 0;
            } else if (val === 1) {
                this.wa_group = 1;
            }
        },
        lms_access(val) {
            console.log("val", val);
            if (val === 0) {
                this.lms_access = 0;
            } else if (val === 1) {
                this.lms_access = 1;
            }
        },
        wiley_books(val) {
            console.log("val", val);
            if (val === 0) {
                this.books = 0;
            } else if (val === 1) {
                this.books = 1;
            }
        },
        evaluation(val) {
            console.log("val", val);
            if (val === 0) {
                this.evaluation = 0;
            } else if (val === 1) {
                this.evaluation = 1;
            }
        },
        telegram_study_group_p1(val) {
            console.log("val", val);
            if (val === 0) {
                this.telegram_study_group_p1 = 0;
            } else if (val === 1) {
                this.telegram_study_group_p1 = 1;
            }
        },
        telegram_study_group_p2(val) {
            console.log("val", val);
            if (val === 0) {
                this.telegram_study_group_p2 = 0;
            } else if (val === 1) {
                this.telegram_study_group_p2 = 1;
            }
        },
        ima_scholarship(val) {
            console.log("val", val);
            if (val === 0) {
                this.ima_scholarship = 0;
            } else if (val === 1) {
                this.ima_scholarship = 1;
            }
        },
        cr_connect(val) {
            console.log("val", val);
            if (val === 0) {
                this.cr_connect = 0;
            } else if (val === 1) {
                this.cr_connect = 1;
            }
        },
    },
    mounted() {
        this.clear();
        console.log("cpaData", this.cpaEnrollment);
        setTimeout(() => {
            this.fillingData();
        }, 1000);
        // // this.fillingData();
    },
    methods: {
        clear() {
            this.OnboardData = "";
            this.agreed_cost = "";
            this.amount_paid = "";
            this.amount_pending = "";
            this.amount_in_process = "";
            this.enrollment_date = "";
            this.lms_access = "";

            this.whatsapp_group = "";

            this.wiley_books = "";

            this.evaluation = "";

            this.cr_connect = "";

            this.action = "";

            this.cr_name = "";

            this.cr_mobile = "";

            this.cr_email = "";

            this.cr_company = "";
            this.ima_membership_id = "";
            this.ima_scholarship = "";
            this.telegram_study_group_p2 = "";
            this.telegram_study_group_p1 = "";
            this.wiley_otb = "";
        },
        fillingData() {
            this.OnboardData = this.cpaData;
            this.agreed_cost = this.cpaEnrollment.agreed_cost;
            this.amount_paid = this.cpaEnrollment.amount_paid;
            this.amount_pending = this.cpaEnrollment.amount_pending;
            this.amount_in_process = this.cpaEnrollment.amount_in_process;
            this.enrollment_date = this.cpaEnrollment.enrollment_date;
            this.lms_access = this.cpaData.lms_access;

            this.whatsapp_group = this.cpaData.wa_group;

            this.wiley_books = this.cpaData.books;

            this.evaluation = this.cpaData.evaluation;

            this.cr_connect = this.cpaData.cr_connect;

            this.action = this.cpaData.action;

            this.cr_name = this.cpaData.cr_name;

            this.cr_mobile = this.cpaData.cr_mobile;

            this.cr_email = this.cpaData.cr_email;

            this.cr_company = this.cpaData.cr_company;
            this.ima_membership_id = this.cpaData.ima_membership_id;
            this.ima_scholarship = this.cpaData.ima_scholarship;
            this.telegram_study_group_p2 = this.cpaData.telegram_study_group_p2;
            this.telegram_study_group_p1 = this.cpaData.telegram_study_group_p1;
            this.wiley_otb = this.cpaData.wiley_otb;
        },
        
        edit_accads_evaluation_exam() {
            this.bridgecourse = true;
        },
        editOnboardAPI() {
            let onboard_obj = {
                id: this.cpaData.id,
                wa_group: this.wa_group,
                lms_access: this.lms_access,
                books: this.books,
                evaluation: this.evaluation,
                action: this.action,
                cr_connect: this.cr_connect,
                cr_name: this.cr_name,
                cr_mobile: this.cr_mobile,
                cr_email: this.cr_email,
                cr_company: this.cr_company,
                ima_membership_id: this.ima_membership_id,
                ima_scholarship: this.ima_scholarship,
                telegram_study_group_p2: this.telegram_study_group_p2,
                telegram_study_group_p1: this.telegram_study_group_p1,
                wiley_otb: this.wiley_otb,
            };
            console.log("data");

            console.log("obj", onboard_obj);

            let url = `${constants.SERVER_API}updateOnBoardDetails`;

            axios
                .post(url, onboard_obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("responce ", response);
                    this.bridgecourse = false;
                    this.$emit("reload-cmaonboard");
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
    },
};
</script>
