<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
      >
        <p class="heading">PURCHASES</p>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">Amount Paid</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">Amount Pending</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">Total Amount</b>
      </vs-col>
    </vs-row>
    <vs-row
      class="gap"
      v-for="(purchase, index) in studentDetails.purchases"
      :key="index"
    >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b style="color: gray; font-size: 12px">{{ purchase.name }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">
          <vs-chip color="success">
            {{ studentDetails.enrollment.amount_paid }}
          </vs-chip></b
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">
          <vs-chip color="danger">
            {{ studentDetails.enrollment.amount_pending }}
          </vs-chip></b
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <b style="color: gray; font-size: 12px">
          <vs-chip color="dark">
            {{ studentDetails.enrollment.agreed_cost }}
          </vs-chip></b
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    studentDetails: {
      type: Object,
      required: true
    }
  },
  mounted() {
    console.log("purchases");
    console.log(this.studentDetails);
  },
  data() {
    return {};
  }
};
</script>

<style></style>
