import { render, staticRenderFns } from "./AssignSpocPopupMultiple.vue?vue&type=template&id=fc992240&"
import script from "./AssignSpocPopupMultiple.vue?vue&type=script&lang=js&"
export * from "./AssignSpocPopupMultiple.vue?vue&type=script&lang=js&"
import style0 from "./AssignSpocPopupMultiple.vue?vue&type=style&index=0&id=fc992240&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports