<template>
  <div>
    <!-- <vs-row style="margin-left: 2%">
      <div v-for="(badge, index) in arr" :key="index">
        <vs-avatar
          v-show="badge.value !== 0"
          size="32px"
          color="danger"
          badge-color="black"
          :badge="badge.value"
          :text="badge.name"
        />
      </div>
    </vs-row> -->
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="1.5" vs-w="9"
        class="all-border-box mb-10">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5.8" class="date-border-box "
            v-if="candidate_details.mwb.engagement_added_on !== null">
            <p>Date - {{ getEngagementDate(candidate_details.mwb.engagement_added_on) }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="2.4" vs-w="3.8"
            :class="['desc-border-box', getNextDateColor(candidate_details.mwb)]" v-if="tagVisible">
            <p>CM-{{ getNextDateStatus(candidate_details.mwb) }}</p>
          </vs-col>



          <vs-col vs-type="flex" vs-justify="center" vs-align="flex-end" vs-w="12"
            class="comments-border-box mt-4  mb-2">
            <p>Comments :</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="flex-end" vs-w="12" class="comments">
            <p> - {{ candidate_details.mwb.engagement_details }}</p>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3.8"
            :class="['desc-border-box', getExedNextDateColor(candidate_details.mwb), 'desc-border-box1']"
            v-if="tagVisibleIIML">
            <p>EXED-{{ getExedNextDateStatus(candidate_details.mwb) }}</p>
          </vs-col> -->
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="8.2" vs-w="3.8"
            :class="['desc-border-box', getSRNextDateColor(candidate_details.mwb), 'desc-border-box2']"
            v-if="tagVisibleSR && tagVisibleIIML">
            <p>SR-{{ getSRNextDateStatus(candidate_details.mwb) }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="8.2" vs-w="3.8"
            :class="['desc-border-box', getSRNextDateColor(candidate_details.mwb), 'desc-border-box2']"
            v-if="tagVisibleSR && !tagVisibleIIML">
            <p>SR-{{ getSRNextDateStatus(candidate_details.mwb) }}</p>
          </vs-col>
        </vs-row>
        <!-- <vs-card style="margin-bottom: 0%">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
            >
              <vs-icon
                style="margin-right:1%"
                :icon="fetchIcon(candidate_details.mwb.type)"
              ></vs-icon>
              <b style="margin-right: 2%">
                {{ candidate_details.mwb.engagement_added_on }}
              </b>
              - {{ candidate_details.mwb.engagement_details }}
            </vs-col>
          </vs-row>
        </vs-card> -->
      </vs-col>
    </vs-row>
    <!-- <vs-row style="margin-bottom: 2%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="4">
        <vs-button :color="getNextDateColor(candidate_details.mwb)" type="filled" v-if="tagVisible" size="small">
          <div style="font-size: 10px;">
            <b>CM-</b>
            <b>{{ getNextDateStatus(candidate_details.mwb) }}</b>
          </div>
        </vs-button>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="4">
        <vs-button :color="getExedNextDateColor(candidate_details.mwb)" type="filled" v-if="tagVisibleIIML"
          size="small">
          <div style="font-size: 10px;">
            <b>EXED-</b>
            <b>{{ getExedNextDateStatus(candidate_details.mwb) }}</b>
          </div>
        </vs-button>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-" vs-align="flex-start" vs-w="4">
        <vs-button :color="getSRNextDateColor(candidate_details.mwb)" type="filled" v-if="tagVisibleSR" size="small">
          <div style="font-size: 10px;">
            <b>SR-</b>
            <b>{{ getSRNextDateStatus(candidate_details.mwb) }}</b>
          </div>
        </vs-button>
      </vs-col>
    </vs-row> -->
    <vs-row>
      <vs-col>
        <!-- <vs-card> -->
        <vs-row vs-type="flex" vs-justify="space-between" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="icons-border-box">
            <span @click="navSettings('CALL')">
              <vs-icon class="contact_buttons1" icon="local_phone" color="white" round></vs-icon>
            </span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="icons-border-box">
            <span @click="navSettings('EMAIL')">
              <vs-icon class="contact_buttons1" icon="mail" color="white" round></vs-icon>
            </span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="icons-border-box">
            <span @click="navSettings('VISIT')">
              <vs-icon class="contact_buttons1" icon="place" color="white" round></vs-icon>
            </span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="icons-border-box">
            <span @click="navSettings('WHATSAPP')">
              <vs-icon class="contact_buttons1" icon="message" color="white" round></vs-icon>
            </span>
          </vs-col>
        </vs-row>
        <!-- </vs-card> -->
        <vs-navbar-item index="onboard form">
          <a>ONBOARDING</a>
        </vs-navbar-item>
        <vs-card style="background: #dc6161" v-if="candidate_details.mwb.mhp_flag === 1">
          <div>
            <vs-row style="margin-top: 3%; margin-bottom: 3%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8"
                style="font-size: 20px; color: white">
                <b>MISSED HOT POCKET</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4"
                style="font-size: 15px; color: white">
                <b>Date:</b>
                <b style="margin-left:2%">
                  {{ convertTimestampToDate(candidate_details.mwb.mhp_date) }}
                </b>
              </vs-col>
            </vs-row>
            <vs-divider color="rgb(255, 255, 255)" />
            <vs-row v-if="show_mhp_source" style="margin-top: 3%; margin-bottom: 3%">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                style="font-size: 15px; color: white">
                <b>Type:</b>
                <b style="margin-left:2%">
                  {{ candidate_details.mwb.mhp_type }}{{ candidate_details.mhp_details }}
                </b>
              </vs-col>
            </vs-row>
            <vs-row v-if="show_mhp_source">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"
                style="font-size: 15px; color: white">
                <b>
                    {{ displayText(missed_hot_pocket.source) }} |
                    {{ displayText(missed_hot_pocket.campaign) }} |
                    {{ displayText(missed_hot_pocket.net_enquiry_type) }} |
                    {{ displayText(missed_hot_pocket.city) }} -
                    {{ displayText(missed_hot_pocket.location) }} |
                    {{ displayText(missed_hot_pocket.course) }}
                  </b>
              </vs-col>
            </vs-row>
            <vs-row v-if="show_mhp_source" style="margin-top: 1%; margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                  style="font-size: 13px; color: white"
                >
                  {{ displayText(missed_hot_pocket.details) }}&nbsp; <span v-if="missed_hot_pocket.webinar_topic != '' && missed_hot_pocket.webinar_topic != null"> | {{ missed_hot_pocket.webinar_topic }} </span>
                </vs-col>
              </vs-row>
          </div>
        </vs-card>
        <vs-card style="background: #28c76f" v-if="wa_campaign_data !== null">
         <p class="campaign_details">Campaign : {{ wa_campaign_data }}</p>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    candidate_details: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    let login_team = localStorage.getItem("team")
    if(login_team == 'CM'){
      this.show_mhp_source = false;
    }
    this.missed_hot_pocket = this.candidate_details.ne_data[0];
    console.log("missed_hot_pocket", this.missed_hot_pocket);
    this.getCompaignDetails();
    // this.generateEngagementInfoBadges();
    // this.getNextDateStatus();
    // this.getExedNextDateStatus();
  },
  data() {
    return {
      show_mhp_source: true,
      missed_hot_pocket: '',
      wa_campaign_data:null,
      // arr: [
      //   {
      //     name: "Connected, Busy",
      //     value: 0,
      //     form: "CB",
      //   },
      //   {
      //     name: "Connected, Never call back",
      //     value: 0,
      //     form: "CN",
      //   },
      //   {
      //     name: "Connected, Wrong Number",
      //     value: 0,
      //     form: "CW",
      //   },
      //   {
      //     name: "Busy",
      //     value: 0,
      //     form: "B",
      //   },
      //   {
      //     name: "Not Lifting",
      //     value: 0,
      //     form: "NL",
      //   },
      //   {
      //     name: "Not Reachable",
      //     value: 0,
      //     form: "NR",
      //   },
      //   {
      //     name: "Disconnected",
      //     value: 0,
      //     form: "D",
      //   },
      //   {
      //     name: "Invalid Number",
      //     value: 0,
      //     form: "IN",
      //   },
      //   {
      //     name: "Switched Off",
      //     value: 0,
      //     form: "SO",
      //   },
      // ],
      tagVisible: true,
      tagVisibleIIML: true,
      tagVisibleSR: true,
    };
  },
  methods: {
    displayText(text) {
      if (text === null || text === "" || text === undefined) {
        return "N/A";
      } else {
        return text;
      }
    },
    getEngagementDate(d) {
      var myDate = new Date(d);
      return moment(myDate).format('DD MMMM YYYY')
    },
    getCompaignDetails(){
      this.candidate_details.ne_data.forEach(element => {
        if (element.campaign == 'WA - One on One counselling Placement Drive') {
          this.wa_campaign_data = element.campaign;
          // Stop the loop once the condition is satisfied
          return;
        }
      });
    },
    // generateEngagementInfoBadges() {
    //   if (this.candidate_details.mwb.incommunicado_status !== 0) {
    //     for (
    //       let x = 0;
    //       x <= this.candidate_details.engagements.length - 1;
    //       x++
    //     ) {
    //       if (
    //         this.candidate_details.engagements[x].connection_status === "CD"
    //       ) {
    //         break;
    //       } else {
    //         this.arr.forEach((ar) => {
    //           if (
    //             this.candidate_details.engagements[x].connection_status ===
    //             ar.form
    //           ) {
    //             ar.value = ar.value + 1;
    //           }
    //         });
    //       }
    //     }
    //   }
    // },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    getNextDateStatus(payload) {
      if (payload.next_call === null) {
        this.tagVisible = false;
        return;
      }
      this.tagVisible = true;
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      let today_start_time = today.setHours(0, 0, 0, 0);
      let today_end_time = today.setHours(23, 59, 59, 59);
      if (nextCall > today_end_time) {
        return `Waiting - ${this.convertTimestampToDate(payload.next_call)}`;
      } else if (nextCall < today_start_time) {
        return `Delay - ${this.convertTimestampToDate(payload.next_call)}`;
      } else {
        return `Today - ${this.convertTimestampToDate(payload.next_call)}`;
      }
    },
    getNextDateColor(payload) {
      let nextCall = new Date(payload.next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `success`;
      } else if (nextCall < today) {
        return `danger`;
      } else {
        return `warning`;
      }
    },
    // getExedNextDateStatus(payload) {
    //   if (payload.iiml_next_call === null) {
    //     this.tagVisibleIIML = false;
    //     return;
    //   }
    //   this.tagVisibleIIML = true;
    //   let nextCall = new Date(payload.iiml_next_call * 1000);
    //   let today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   if (nextCall > today) {
    //     return `Waiting - ${this.convertTimestampToDate(
    //       payload.iiml_next_call
    //     )}`;
    //   } else if (nextCall < today) {
    //     return `Delay - ${this.convertTimestampToDate(payload.iiml_next_call)}`;
    //   } else {
    //     return `Today - ${this.convertTimestampToDate(payload.iiml_next_call)}`;
    //   }
    // },
    // getExedNextDateColor(payload) {
    //   let nextCall = new Date(payload.iiml_next_call * 1000);
    //   let today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   if (nextCall > today) {
    //     return `success`;
    //   } else if (nextCall < today) {
    //     return `danger`;
    //   } else {
    //     return `warning`;
    //   }
    // },
    getSRNextDateStatus(payload) {
      if (payload.sr_next_call === null) {
        this.tagVisibleSR = false;
        return;
      }
      this.tagVisibleSR = true;
      let nextCall = new Date(payload.sr_next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `Waiting - ${this.convertTimestampToDate(payload.sr_next_call)}`;
      } else if (nextCall < today) {
        return `Delay - ${this.convertTimestampToDate(payload.sr_next_call)}`;
      } else {
        return `Today - ${this.convertTimestampToDate(payload.sr_next_call)}`;
      }
    },
    getSRNextDateColor(payload) {
      let nextCall = new Date(payload.sr_next_call * 1000);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (nextCall > today) {
        return `success`;
      } else if (nextCall < today) {
        return `danger`;
      } else {
        return `warning`;
      }
    },
    navSettings(option) {
      this.$emit("change-navBar-options", option);
    },
  },
};
</script>

<style scoped>
.campaign_details{
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
}
.contact_buttons1 {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0044BA;
  border-radius: 50%;
  width: 69.83px;
  height: 69.83px;
  border: 2px solid #0044BA;
  ;
}

.desc-border-box {
  width: auto;
  height: 22.58px;
  margin-left: 1%;
}

.warning {
  background: #ffa41bc2;
}

.success {
  background: #28C76F;

}

.danger {
  background: #c74828;
  border-radius: 15px 0px;
}

.desc-border-box {
  border-radius: 0px 15px;
}

.desc-border-box1 {
  border-radius: 0px 15px;
  margin-top: 45px;
}

.desc-border-box2 {
  border-radius: 15px 0px;
  margin-top: 45px;
}

.all-border-box {
  padding: 0px !important;
  width: 773.56px;
  height: auto !important;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.date-border-box {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0044BA;
  margin-top: 10.5px;
}

.desc-border-box p {
  position: relative;
  width: auto;
  height: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #FFFFFF;
  /* transform: matrix(-2, 0, 0, 2, 0, 0); */

}

.comments-border-box {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.comments {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
}

.icons-border-box {
  width: 16% !important;
  height: 130.81px;
  background: #FFFFFF;
  border: 1px solid #0044BA;
  box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

@media screen and (max-width: 1820px) {
  .icons-border-box {
    width: 18% !important;
  }
}

@media screen and (max-width: 1620px) {
  .icons-border-box {
    width: 20% !important;
  }

  .desc-border-box p {
    font-size: 9px !important;
  }

}

@media screen and (max-width: 1450px) {
  .icons-border-box {
    width: 22% !important;
  }

  .desc-border-box p {
    font-size: 8.5px !important;
  }
}

@media screen and (max-width: 1390px) {
  .desc-border-box p {
    font-size: 7px !important;
  }
}
</style>
