<template>
  <vs-popup title="Add Receipts" :active.sync="open">
    <div class="vx-row mb-6" :data="this.$store.state.feesbooks">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker :minimumView="'day'" :maximumView="'day'" v-model='myDate'></datepicker>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select label="bacth" :options="batch_name2"></v-select>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Amount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="input3" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Ref.No(MR)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" type="" v-model="input4" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-2/3 w-full ml-auto">
        <vs-button class="mr-3 mb-2">Submit</vs-button>
      </div>
    </div>
  </vs-popup>
</template>
<script>
import EventBus from '../eventbus.js'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
export default {
  components: {
    Datepicker,
    'v-select': vSelect,
  },
  mounted() {
    EventBus.$on('open-fees-books-popup', () => {
      this.batch_name.forEach((name) => {
        let string = name.name + ' - ' + name.batch
        this.batch_name2.push(string)
      })
      this.open = true
    })
  },
  data() {
    return {
      myDate : new Date().toISOString().slice(0,10),
      open: false,
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      batch_name2: [],
      batch_name: 
        [
              {
                batch: "CPA-M7-02",
                name: "chaitanya",
              },
              {
                batch: "CPA-M6-03",
                name: "rafi",
              },
              {
                batch: "CPA-M5-01",
                name: "chaitanya krishna",
              },
              {
                batch: "CPA-M4-01",
                name: "Sahil Kapasi",
              },
              {
                batch: "CPA-M3-01",
                name: "Amie Summers",
              },
              {
                batch: "CPA-M5-01",
                name: "Lachlan Lawrence",
              },
              {
                batch: "CPA-M4-01",
                name: "Cassie Noble",
              },
              {
                batch: "CPA-M2-01",
                name: "Linda Padilla",
              },
              {
                batch: "CPA-M4-01",
                name: "Jodie Stuart",
              },
              {
                batch: "CPA-M7-01",
                name: "Cara Allen",
              }
            ]
    }
  }
}
</script>
        