<template>
  <!-- style="height: auto; margin-top: 100px" -->
  <vs-popup title="Email" fullscreen :active.sync="open" @close="closePopup" style="z-index:53002">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
        <vs-card>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
              <vs-select class="selectExample" label="Select Templates" v-model="templates">
                <vs-select-item
                  :key="index"
                  :value="item.select"
                  :text="item.select"
                  v-for="(item,index) in types"
                />
              </vs-select>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">To:</vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <vs-input name="mailTo" v-model="mailTo" class="w-full mb-6" />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">Subject:</vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <!-- <vs-input class="w-full" v-model="textarea"></vs-input> -->
              <vs-input name="mailSubject" v-model="mailSubject" class="w-full mb-6" />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">CC:</vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <vs-input name="mailCC" v-model="mailCC" class="w-full mb-6" />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">BCC:</vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <vs-input name="mailBCC" v-model="mailBCC" class="w-full mb-6" />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">Message:</vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <quill-editor v-model="content"></quill-editor>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <vs-upload
                v-if="open && templates != 'CPA CMA Roadmaps'"
                multiple
                text="Attachments"
                action="http://13.232.242.239:5000/fileupload/"
                @on-error="errorUpload"
                @change="changeUpload"
                @on-delete="deleteFile"
                @on-success="successUpload"
                :data="attachmentKey"
              />
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <vs-row style="margin-bottom: 3%">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
              <vs-button color="dark" type="border" @click="sendMail">Send</vs-button>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
      <!--Changes in preveiew thing -->
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
        <vs-card>
          <vs-row>
            <vs-col
              style="margin-bottom: 5%"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <h2>PREVIEW</h2>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <div id="previewMail"></div>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <div id="previewSubject"></div>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <div id="previewCc"></div>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <div id="previewBcc"></div>
            </vs-col>
          </vs-row>
          <br />
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <div
                id="preview2"
                style="box-sizing: border-box;line-height: 1.42;height: 100%;outline: none;overflow-y: auto;padding: 12px 15px;-o-tab-size: 4;tab-size: 4;-moz-tab-size: 4;text-align: left;white-space: pre-wrap;word-wrap: break-word;"
              ></div>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>



<script>
import EventBus from "../eventbus.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import constants from "../../../constants.json";

import { quillEditor } from "vue-quill-editor";

export default {
  watch: {
    mailTo: function() {
      this.previewMail();
      // this.emitPreview()
    },
    mailCC: function() {
      this.previewCc();
      // this.emitPreview()
    },
    mailBCC: function() {
      this.previewBcc();
      // this.emitPreview()
    },
    content: function() {
      this.preview2();
      // this.emitPreview()
    },
    mailSubject: function() {
      this.previewSubject();
      // this.emitPreview()
    },
    templates: function(type) {
      if (type === "custom") {
        this.mailSubject = "";
        this.content = "";
        this.mailTo = "";
        this.mailBCC = "";
        this.mailCC = "";
      } else {
        this.types.forEach(tems => {
          if (tems.select === type) {
            this.mailSubject = tems.text.subject;
            this.content = tems.text.message;
            this.mailTo = tems.text.mail;
            this.mailBCC = tems.text.bcc;
            this.mailCC = tems.text.cc;
          }
        });
      }
      if (type === "CPA CMA Roadmaps") {
        this.attachmentKey.key = "roadmaps";
      }
    }
  },
  mounted() {
    EventBus.$on("stop-preview-emit", () => {
      this.emito = false;
    });
    EventBus.$on("open-email-engagement", payload => {
      console.log(payload);
      if (payload.length !== 0) {
        if (this.$route.path !== "/netEnquiryTable") {
          this.getEmailIds(payload.person_id);
        }
        this.mailMessage = "";
        this.mailTo = payload.email;
        this.mailSubject = "";
        this.mailCC = "";
        this.mailBCC = "";
        this.saveFile = [];
        this.getAttachmentKey();
        this.open = true;
        this.templates = "custom";
      } else {
        this.$vs.notify({
          title: "Select leads to continue",
          text: "Mass Email require multiple leads to continue",
          color: "warning"
        });
      }
      // if (this.attachmentKey.key === "CPA CMA Roadmaps") {
      //   this.attachmentKey.key = "roadmaps";
      // }
    });
  },
  components: {
    quillEditor
  },
  data() {
    return {
      selectionNumber: 0,
      srcimage: "",
      json: "",
      html: "",
      emito: "false",
      templates: "custom",
      open: false,
      saveFile: [],
      mailTo: "",
      mailSubject: "",
      mailCC: "",
      mailBCC: "",
      mailMessage: "",
      content: ``,
      types: [
        {
          select: "custom",
          text: {
            mail: "",
            bcc: "",
            cc: "",
            subject: "",
            message: ""
          }
        },
        {
          select: "CPA CMA Roadmaps",
          text: {
            mail: "",
            bcc: "",
            cc: "",
            subject:
              'Miles Education "Hyderabad": Roadmap to CPA [12 months] and CMA [6 months]',
            message: ''
          }
        }
      ],
      attachmentKey: null,
      sendall: false
    };
  },
  methods: {
    previewMail() {
      document.getElementById("previewMail").innerHTML =
        "<h6>To</h6><br>" + this.mailTo;
    },
    previewSubject() {
      document.getElementById("previewSubject").innerHTML =
        "<h6>Subject</h6><br>" + this.mailSubject;
    },
    previewBcc() {
      document.getElementById("previewBcc").innerHTML =
        "<h6>BCC</h6><br>" + this.mailBCC;
    },
    previewCc() {
      document.getElementById("previewCc").innerHTML =
        "<h6>CC</h6><br>" + this.mailCC;
    },
    preview2() {
      document.getElementById("preview2").innerHTML =
        "<h6>Message</h6><br>" + this.content;
    },
    emitPreview(content) {
      EventBus.$emit("open-preview-engagement", content);
      // EventBus.$emit('preview-info', this.textarea2)
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      // console.log("files", this.saveFile.push(files))
      // this.saveFile.push(e)
      this.saveFile = files;
      console.log("result", this.saveFile);
    },
    deleteFile(e) {
      console.log("delete file", this.saveFile.splice(e, 1));
    },
    successUpload(e) {
      console.log("success", e);
    },
    clearFields() {
      this.mailMessage = "";
    },
    async getAttachmentKey() {
      const url = `${constants.SERVER_API}setKey`;
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          if (this.templates !== "CPA CMA Roadmaps") {
            this.attachmentKey = { key: response.data };
          } else {
            this.attachmentKey = { key: "roadmaps" };
          }
        })
        .catch(error => {
          this.handleError(error);
          // if (error.response) {
          //   // invalid accesss token, redirect to login
          //   this.ReDirect_to_login();
          // }
        });
    },
    async sendMail() {
      const url = `${constants.SERVER_API}sendEmail`;
      const { key } = this.attachmentKey;
      console.log("key? ", key);
      const data = {
        attachments_folder: key,
        subject: this.mailSubject,
        to: this.mailTo,
        bcc: this.mailBCC,
        cc: this.mailCC,
        html: this.content
      };
      this.attachmentKey = await axios
        .post(url, data, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("send email response");
          console.log(response.data);
          this.open = false;
        })
        .catch(error => {
          this.handleError(error);
          // if (error.response) {
          //   // invalid accesss token, redirect to login
          //   this.ReDirect_to_login();
          // }
        });
    },
    async getEmailIds(person_id) {
      const url = `${constants.SERVER_API}getEmailIds?person_id=${person_id}`;
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.mailTo = response.data.join(",");
        })
        .catch(error => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    closePopup() {
      // console.log('popup has been closed ' + this.open)
      // this.open = false;
    }
  },
  beforeDestroy() {
    // this.editor.destroy()
  }
};
</script>

<style lang="scss" scoped>
.actions {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.export {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.pre {
  padding: 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  background: black;
  color: black;
}
.code {
  display: block;
  white-space: pre-wrap;
}
.menubar__button {
  margin: 10px;
  background: white;
  border: none;
}
.menubar__button.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
</style>
