import "material-icons/iconfont/material-icons.css";
export default [{
        url: "/",
        name: "DashBoard",
        slug: "dashboard",
        icon: "HomeIcon",
        meta: {
            rule: "cm1",
        },
    },
    // {
    //     url: "/adminDashboard",
    //     name: "Admin Dashboard",
    //     slug: "Admin Dashboard",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/CmDashboard",
    //     name: "CM Dashboard",
    //     slug: "CM Dashboard",
    //     icon: "HomeIcon",
    // },
    {
        url: "/NewSpocDashboard",
        name: "Spoc Dashboard",
        slug: "Spoc Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/GmDashboard",
        name: "GM Dashboard",
        slug: "GM Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/NewSrSpocDashboard",
        name: "SR Spoc Dashboard",
        slug: "SR Spoc Dashboard",
        icon: "HomeIcon",
    },
    // {
    //     url: "/StudentConnect",
    //     name: "SR Dashboard",
    //     slug: "SR Dashboard",
    //     icon: "HomeIcon",
    // },
    {
        url: "/acadsAdminDashboard",
        name: "Acads Admin Dashboard",
        slug: "Acads Admin Dashboard",
        icon: "HomeIcon",
    },
    // {
    //     url: "/WebinarDashboard",
    //     name: "Webinar Dashboard",
    //     slug: "Webinar Dashboard",
    //     icon: "HomeIcon",
    // },
    {
        url: "/acadsDashboard",
        name: "Acads Dashboard",
        slug: "Acads Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/workDashboard",
        name: "SR Work Dashboard",
        slug: "SR Work Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/GMWorkDashboard",
        name: "GM Work Dashboard",
        slug: "GM Work Dashboard",
        icon: "HomeIcon",
    },
    // {
    //     url: "/F2fRegistrant",
    //     name: "F2F Registrants",
    //     slug: "F2F Registrants",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/meetAndGreet",
    //     name: "Big 4 MeetAndGreet",
    //     slug: "Big 4 MeetAndGreet",
    //     icon: "HomeIcon",
    // },
    {
        url: "/oldWebinar",
        name: "Old Roadmap Webinar",
        slug: "Old  Webinar",
        icon: "HomeIcon",
    },
    // {
    //     url: "/gmDashboardFeedback",
    //     name: "GM Dashboard Feedback",
    //     slug: "GM Dashboard Feedback",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/acceleratorSession",
    //     name: "Accelerator Session",
    //     slug: "Accelerator Session",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/roadmapWebinar",
    //     name: "Webinar Registrations",
    //     slug: "Webinar Registrations",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/WebinarNewDashboard",
    //     name: "Webinar Dashboard",
    //     slug: "Webinar Dashboard",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/placementDrive",
    //     name: "Placement Drive",
    //     slug: "Placement Drive",
    //     icon: "HomeIcon",
    // },
    // {
    //     url: "/visitStatus",
    //     name: "Visit Status",
    //     slug: "Visit Status",
    //     icon: "HomeIcon",
    // },
    {
        url: "/CorporateDashboard",
        name: "Corporate Dashboard",
        slug: "Corporate Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/CollectionDashboard",
        name: "Collection Dashboard",
        slug: "Collection Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/iimlDashboard",
        name: "ExEd Dashboard",
        slug: "ExEd Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/queue",
        name: "Queue",
        slug: "Queue",
        icon: "LayersIcon",
        meta: {
            rule: "cm1",
        },
    },
    {
        url: null,
        name: "Dashboards",
        icon: "BookOpenIcon",
        i18n: "MenuLevels",
        submenu: [
            {
                url: "/acceleratorSession",
                name: "Accelerator Session",
                i18n: "Accelerator Session",
                icon: "ClipboardIcon",
            },
            {
                url: "/roadmapWebinar",
                name: "Webinar Registrations",
                i18n: "Webinar Registrations",
                icon: "ClipboardIcon",
            },
            {
                url: "/WebinarNewDashboard",
                name: "Webinar Dashboard",
                i18n: "Webinar Dashboard",
                icon: "ClipboardIcon",
            },
            {
                url: "/placementDrive",
                name: "Placement Drive",
                i18n: "Placement Drive",
                icon: "ClipboardIcon",
            },
            {
                url: "/visitStatus",
                name: "Visit Status",
                i18n: "Visit Status",
                icon: "ClipboardIcon",
            },
            {
                url: "/MAccStats",
                name: "USP Stats",
                i18n: "MAccStats",
                icon: "ClipboardIcon",
            }
            
            
        ],
    },
    {
        url: null,
        name: "Reports",
        icon: "BookOpenIcon",
        i18n: "MenuLevels",
        submenu: [
            {
                url: "/scheduledVisits",
                name: "Scheduled Visits",
                i18n: "scheduledVisits",
                icon: "ClipboardIcon",
            },
            {
                url: "/GmQueueReport",
                name: "GM Queue Report",
                i18n: "GM Queue Report",
                icon: "ClipboardIcon",
            },
            {
                url: "/DelaysByLevel",
                name: "Delays (Level CM)",
                i18n: "delaysbylevel",
                icon: "ClipboardIcon",
            },
            {
                url: "/DelaysBySRLevel",
                name: "Delays (Level SR)",
                i18n: "delaysBySRLevel",
                icon: "ClipboardIcon",
            },
            
        ],
    },
    {
        url: null,
        name: "MWB",
        icon: "BookOpenIcon",
        i18n: "MenuLevels",
        submenu: [{
                url: "/coverage",
                name: "Coverage",
                i18n: "Coverage",
            },
            // {
            //     url : "/Pathway",
            //     name: "Pathway (Level UM)",
            //     i18n: "Pathway",
            // },
            // {
            //     url: "/scheduledVisits",
            //     name: "Scheduled Visits",
            //     i18n: "scheduledVisits",
            // },
            // {
            //     url: "/GmQueueReport",
            //     name: "GM Queue Report",
            //     i18n: "GmQueueReport",
            // },
            // {
            //     url: "/DelaysByLevel",
            //     name: "Delays (Level CM)",
            //     i18n: "delaysbylevel",
            // },
            // {
            //     url: "/DelaysBySubTeams",
            //     name: "Delays (Level ExEd)",
            //     i18n: "delaysbysubteams",
            // },
            // {
            //     url: "/DelaysBySRLevel",
            //     name: "Delays (Level SR)",
            //     i18n: "delaysBySRLevel",
            // },
            // {
            //     url: "/DelaysByMAccLevels",
            //     name: "Delays (Level USP)",
            //     i18n: "delaysByMAccLevel",
            // },
            // {
            //     url: "/DelaysEligibility",
            //     name: "Delays (Eligibility)",
            //     i18n: "DelaysEligibility",
            // },
            // {
            //     url: "/DelaysByMHP",
            //     name: "Delays (Hot Pockets)",
            //     i18n: "delaysbylevel",
            // },
            // {
            //     url: "/MAccStats",
            //     name: "USP Stats",
            //     i18n: "MAccStats",
            // },
            // {
            //   url: "/netEnquiryTable",
            //   name: "New Net Enquiries",
            //   i18n: "NewNetEnquiry"
            // },
            {
                url: "/NewBdeLeads",
                name: "All Leads",
                i18n: "NewBdeLeads",
            },
            {
                url: null,
                name: "Hot Pockets",
                i18n: "Hot Pockets",
                submenu: [
                    // {
                    //     url: "/myLeads",
                    //     name: "CPA,CMA Leads (Old)",
                    //     i18n: "myleads",
                    // },
                    // {
                    //     url: "/LeadSearch",
                    //     name: "Lead Search",
                    //     i18n: "LeadSearch",
                    // },
                    // {
                    //     url: "/NewBdeLeads",
                    //     name: "CPA,CMA Leads",
                    //     i18n: "NewBdeLeads",
                    // },
                    // {
                    //   url: "/IIMLFALeads",
                    //   name: "IIML FA Leads",
                    //   i18n: "iimlfaleads"
                    // },
                    // {
                    //     url: "/mwbcorporate",
                    //     name: "Corporate",
                    //     i18n: "corporates",
                    // },
                    {
                        url: "/macReferrals",
                        name: "Referrals",
                        i18n: "referrals",
                    },
                    {
                        url: "/mwbeligibility",
                        name: "Eligibility",
                        i18n: "eligibility",
                    },
                    // {
                    //     url: "/mwbloan",
                    //     name: "Loans",
                    //     i18n: "Loans",
                    // },
                    // {
                    //     url: "/mwbeduvanzloan",
                    //     name: "Eduvanz Loans",
                    //     i18n: "mwbeduvanzloan",
                    // },
                    {
                        url: "/neweduvanzloan",
                        name: "Loans",
                        i18n: "neweduvanzloan",
                    },
                    {
                        url: "/mwbnetenquiries",
                        name: "Net Enquiries",
                        i18n: "netEnquires",
                    },
                    // {
                    //     url: "/mwbreference",
                    //     name: "Reference",
                    //     i18n: "reference",
                    // },
                    // {
                    //     url: "/mwbuniversity",
                    //     name: "University",
                    //     i18n: "university",
                    // },
                    // {
                    //     url: "/mwbvisits",
                    //     name: "Visitors",
                    //     i18n: "Visitors",
                    // },
                    {
                        url: "/VisitorsByDate",
                        name: "Visitors",
                        i18n: "visitors",
                    },
                    {
                        url: "/defaulter",
                        name: "Defaulter",
                        i18n: "defaulter",
                    },
                ],
            },

            // {
            //     url: null,
            //     name: "ExEd",
            //     i18n: "ExEd",
            //     submenu: [{
            //             url: "/IIMLBussinessLeads",
            //             name: "ExEd Leads",
            //             i18n: "iimlbaleads",
            //         },
            //         {
            //             url: "/IIMLApplicants",
            //             name: "ExEd Applicants",
            //             i18n: "iimlapplicants",
            //         },
            //         {
            //             url: "/IIMLEnrollments",
            //             name: "ExEd Enrollments",
            //             i18n: "iimlenrollments",
            //         },
            //         {
            //             url: "/MasterDashBoard",
            //             name: "Master DashBoard",
            //             i18n: "Master DashBoard",
            //         },
            //         {
            //             url: "/ApplicationTracker",
            //             name: "Application Tracker",
            //             i18n: "Application Tracker",
            //         },
            //         // {
            //         //     url: "/Wileyapplicationtracker",
            //         //     name: "Wiley Application Tracker",
            //         //     i18n: "Wiley Application Tracker",
            //         // }
            //     ],
            // },
        ],
    },
    {
        url: null,
        name: "EWB",
        icon: "BookOpenIcon",
        i18n: "MenuLevels",
        submenu: [
            {
                url: "/onBoardTable",
                name: "On Board Table",
                i18n: "On Board Table",
                icon: "ClipboardIcon",
            },
            {
                url: "/bridgeCourseList",
                name: "Bridge Course Table",
                i18n: "Bridge Course Table",
                icon: "ClipboardIcon",
            },
            {
                url: null,
                name: "CPA",
                i18n: "CPA",
                icon: "ClipboardIcon",
                submenu: [
                    {
                        url: "/candidates",
                        name: "Candidates",
                        i18n: "cnadidates",
                    },
                    // {
                    //   url: "/fees&books",
                    //   name: "Fees&Books",
                    //   i18n: "FeesBooks"
                    // },
                    {
                        url: "/attendance",
                        name: "Attendance",
                        i18n: "Attendance",
                    },
                ],
            },
            {
                url: null,
                name: "CMA",
                i18n: "CMA",
                icon: "ClipboardIcon",
                submenu: [
                    {
                        url: "/CandidatesCma",
                        name: "Candidates",
                        i18n: "cnadidates",
                    },
                    // {
                    //   url: "/FeesBooksCma",
                    //   name: "Fees & Books",
                    //   i18n: "books"
                    // },
                    {
                        url: "/AttendenceCma",
                        name: "Attendance",
                        i18n: "Attendance",
                    },
                ],
            },
            
        ],
    },
    // {
    //     url: "/LeadIIMLAssignment",
    //     name: "ExEd Assignment",
    //     i18n: "assignment",
    //     icon: "LayersIcon",
    //     meta: {
    //         rule: "exed2",
    //     },
    // },
    {
        url: "/NetEnquiriesTransfer",
        name: "Net Enquiries Transfer",
        i18n: "Net Enquiries Transfer",
        icon: "LayersIcon",
        meta: {
            rule: "exed2",
        },
    },
    
    
    // {
    //     url: "/TeamAttendance",
    //     name: "My Team Attendance",
    //     i18n: "My Team Attendance",
    //     icon: "BookIcon",
    // },
    {
        url: null,
        name: "Admin Reports",
        icon: "BookIcon",
        i18n: "MenuLevels",
        meta: {
            rule: "cm4",
        },
        submenu: [
            {
                url: "/AdminSpocDashboard",
                name: "Admin SPOC Dashboard",
                i18n: "assignment",
            },
            {
                url: "/AdminUtilizationReport",
                name: "Admin Utilization Dashboard",
                i18n: "assignment",
            },
            {
                url: "/CallLogs",
                name: "Call Logs",
                i18n: "calllogs",
            },
            {
                url: "/enrollmentApproval",
                name: "Enrollment Approval",
                i18n: "enrollmentApproval",
            },
            {
                url: "/uspreenrollmentapproval",
                name: "USP ReEnrollment Approval",
                i18n: "uspreenrollmentApproval",
            },
            // {
            //     url: "/TabulatorNewAdminDashboard",
            //     name: "New Admin Dashboard",
            //     i18n: "New Admin Dashboard",
            // },
            {
                url: "/TabulatorMasterPerformance",
                name: "New Master Performance",
                i18n: "TabulatorMasterPerformance",
            },
            {
                url: "/marketingEmails",
                name: "Marketing Emails",
                i18n: "Marketing Emails",
            },
            {
                url: "/marketingBudgets",
                name: "Marketing Budgets",
                i18n: "Marketing Budgets",
            },
            {
                url: "/SpocsEligibility",
                name: "NE Spoc Eligibility",
                i18n: "NE Spoc Eligibility",
            },
            {
                url: "/srSpocManagement",
                name: "Onboarding Management",
                slug: "Onboarding Management",
                icon: "HomeIcon",
            },
            {
                url: "/queueLevelPriority",
                name: "Queue Level Priority",
                i18n: "queueLevelPriority",
            },
            {
                url: "/visitapproval",
                name: "Visit Approval",
                i18n: "visitapproval",
            },
            {
                url: "/gmeetapproval",
                name: "G Meet Approval",
                i18n: "gmeetapproval",
            },
        ],
    },
    {
        url: null,
        name: "Admin Reportss",
        icon: "BookIcon",
        i18n: "MenuLevels",
        meta: {
            rule: "cm4",
        },
        submenu: [
            {
                url: "/AdminSpocDashboard",
                name: "Admin SPOC Dashboard",
                i18n: "assignment",
            },
            {
                url: "/AdminUtilizationReport",
                name: "Admin Utilization Dashboard",
                i18n: "assignment",
            },
            // {
            //     url: "/AnonymousPurchase",
            //     name: "Anonymous Purchase",
            //     i18n: "Anonymous Purchase",
            // },
            // {
            //     url: "/ARReconciliation",
            //     name: "AR Reconciliation",
            //     i18n: "AR Reconciliation",
            // },
            // {
            //     url: "/LeadAssignment",
            //     name: "Assignment",
            //     i18n: "assignment",
            // },
            {
                url: "/CallLogs",
                name: "Call Logs",
                i18n: "calllogs",
            },
            // {
            //     url: "/callTrends",
            //     name: "Call Trends",
            //     i18n: "Call Trends",
            // },
            // {
            //     url: "/getCreditNoteNumber",
            //     name: "Credit Note Number Generation",
            //     i18n: "Credit Note Number Generation",
            // },
            // {
            //     url: "/EditMobile",
            //     name: "Edit Mobile Number",
            //     i18n: "Edit Mobile Number",
            // },
            {
                url: "/enrollmentApproval",
                name: "Enrollment Approval",
                i18n: "enrollmentApproval",
            },
            {
                url: "/uspreenrollmentapproval",
                name: "USP ReEnrollment Approval",
                i18n: "uspreenrollmentApproval",
            },
            // {
            //     url: "/TurnAroundTime",
            //     name: "Enrollments Turn Around Time",
            //     i18n: "Enrollments Turn Around Time",
            // },
            // {
            //     url: "/LeadIIMLAssignment",
            //     name: "ExEd Assignment",
            //     i18n: "assignment",
            // },
            {
                url: "/IIMLManagementcalllogs",
                name: "ExEd Calllogs",
                i18n: "iilmcalllogs",
            },
            
            // {
            //     url: "/GMNET",
            //     name: "GM Net Enquiries Transfer",
            //     il8n: "GM NET"
            // },
            {
                url: "/HdfcProcessedReport",
                name: "HDFC Processed Report",
                i18n: "HdfcProcessedReport",
            },
            {
                url: "/internationalPurchases",
                name: "International Purchases",
                i18n: "International Purchases",
                icon: "DollarSignIcon",
            },
            {
                url: "/getInvoiceNumber",
                name: "Invoice Number Generation",
                i18n: "Invoice Number Generation",
            },
            {
                url: "/LoanTenures",
                name: "Loan Tenures",
                i18n: "Loan Tenures",
            },
            {
                url: "/ManagerSpocDashboard",
                name: "Manager SpocDashboard",
                i18n: "Teamholidates",
            },
            {
                url: "/ManagerUtilization",
                name: "Manager Utilization",
                i18n: "Manager Utilziation",
                icon: "PercentIcon",
            },
            {
                url: "/marketingEmails",
                name: "Marketing Emails",
                i18n: "Marketing Emails",
            },
            {
                url: "/marketingBudgets",
                name: "Marketing Budgets",
                i18n: "Marketing Budgets",
            },
            {
                url: "/masterPerformance",
                name: "Master Performance",
                i18n: "masterPerformance",
            },
            // {
            //     url: "/mergeLeadInterface",
            //     name: "Merge Leads",
            //     i18n: "Merge Leads",
            // },
            {
                url: "/MrReco",
                name: "MR Reco",
                i18n: "MRReco",
            },
            {
                url: "/HdfcExcellUpload",
                name: "MR Upload",
                i18n: "MR Upload",
            },
            {
                url: "/MRChangeFileName",
                name: "MR Change File Name",
                i18n: "MR Change File Name",
            },
            {
                url: "/TabulatorNewAdminDashboard",
                name: "New Admin Dashboard",
                i18n: "New Admin Dashboard",
            },
            // {
            //     url: '/newMacReferrals',
            //     name: "New Mac Referrals",
            //     i18n: "newMacReferrals",
            // },
            {
                url: "/TabulatorMasterPerformance",
                name: "New Master Performance",
                i18n: "TabulatorMasterPerformance",
            },
            {
                url: "/NetEnquiryMovementReport",
                name: "Net Enquiries Report",
                i18n: "Net Enquiries Report",
            },
            {
                url: "/NetEnquiriesTransfer",
                name: "Net Enquiries Transfer",
                i18n: "Net Enquiries Transfer",
            },
            {
                url: "/netEnquiryTable",
                name: "Net Enquiry Table",
                i18n: "Net Enquiry Table",
            },
            {
                url: "/notificationManagement",
                name: "Notification Management",
                i18n: "Notification Management",
            },
            {
                url: "/SpocsEligibility",
                name: "NE Spoc Eligibility",
                i18n: "NE Spoc Eligibility",
            },
            {
                url: "/srSpocManagement",
                name: "Onboarding Management",
                slug: "Onboarding Management",
                icon: "HomeIcon",
            },
            {
                url: "/LeadChanges",
                name: "Operations",
                i18n: "Operations",
            },
            // {
            //     url: "/permissions",
            //     name: "Permissions",
            //     i18n: "Permissions",
            // },
            {
                url: "/processingInvoice",
                name: "Processing Invoice/Credit Note",
                i18n: "Processing Invoice/Credit Note",
            },
            {
                url: "/purchaseApproval",
                name: "Purchases Approval",
                i18n: "purchasesApproval",
            },
            {
                url: "/qBInvoiceGeneration",
                name: "QB Invoice Generation",
                i18n: "QB Invoice Generation",
            },
            {
                url: "/queueLevelPriority",
                name: "Queue Level Priority",
                i18n: "queueLevelPriority",
            },
            {
                url: "/receipts",
                name: "Receipts",
                i18n: "Receipts",
            },
            // {
            //     url: "/roadmapbroucher",
            //     name: "Road Map Broucher's",
            //     i18n: "roadmapbroucher",
            // },
            {
                url: "/spamNumbers",
                name: "Spam Numbers",
                i18n: "Spam Numbers",
            },
            // {
            //     url: "/SpocDashboardCCF",
            //     name: "SPOC Dashboard CCF",
            //     i18n: "SPOC Dashboard CCF",
            // },
           
            // {
            //     url: "/SRAssignment",
            //     name: "SR Assignment",
            //     i18n: "assignment",
            // },
            {
                url: "/tallyReceipts",
                name: "Tally Receipts",
                i18n: "Tally Receipts",
            },
            // {
            //     url: "/IdentityTransfer",
            //     name: "Transfer Lead",
            //     i18n: "Transfer Lead",
            // },
            // {
            //     url: "/UnauthorizedCalls",
            //     name: "Unauthorized Calls",
            //     i18n: "unauthorized calls",
            // },
            {
                url: "/updateAgreedCost",
                name: "Update Agreed Cost",
                i18n: "updateAgreedCost",
              },
              {
                url: "/visitapproval",
                name: "Visit Approval",
                i18n: "visitapproval",
            },
            {
                url: "/gmeetapproval",
                name: "G Meet Approval",
                i18n: "gmeetapproval",
            },
            {
                url: "/Warehouseaccount",
                name: "Warehouse OLD",
                i18n: "warehouse",
            },
            // {
            //     url: "/waMessages",
            //     name: "Whatsapp Messages",
            //     i18n: "warehouse",
            // },
        ],
    },
    {
        url: null,
        name: "Accounts",
        icon: "BookIcon",
        i18n: "MenuLevels",
        meta: {
            rule: "cm4",
        },
        submenu: [
            {
                url: "/AnonymousPurchase",
                name: "Anonymous Purchase",
                i18n: "Anonymous Purchase",
            },
            {
                url: "/ARReconciliation",
                name: "AR Reconciliation",
                i18n: "AR Reconciliation",
            },
            {
                url: "/getCreditNoteNumber",
                name: "Credit Note Number Generation",
                i18n: "Credit Note Number Generation",
            },
            {
                url: "/HdfcProcessedReport",
                name: "HDFC Processed Report",
                i18n: "HdfcProcessedReport",
            },
            {
                url: "/internationalPurchases",
                name: "International Purchases",
                i18n: "International Purchases",
                icon: "DollarSignIcon",
            },
            {
                url: "/getInvoiceNumber",
                name: "Invoice Number Generation",
                i18n: "Invoice Number Generation",
            },
            {
                url: "/LoanTenures",
                name: "Loan Tenures",
                i18n: "Loan Tenures",
            },
            {
                url: "/MrReco",
                name: "MR Reco",
                i18n: "MRReco",
            },
            {
                url: "/HdfcExcellUpload",
                name: "MR Upload",
                i18n: "MR Upload",
            },
            {
                url: "/MRChangeFileName",
                name: "MR Change File Name",
                i18n: "MR Change File Name",
            },
            
            {
                url: "/processingInvoice",
                name: "Processing Invoice/Credit Note",
                i18n: "Processing Invoice/Credit Note",
            },
            {
                url: "/purchaseApproval",
                name: "Purchases Approval",
                i18n: "purchasesApproval",
            },
            {
                url: "/qBInvoiceGeneration",
                name: "QB Invoice Generation",
                i18n: "QB Invoice Generation",
            },
            {
                url: "/receipts",
                name: "Receipts",
                i18n: "Receipts",
            },
            {
                url: "/tallyReceipts",
                name: "Tally Receipts",
                i18n: "Tally Receipts",
            },
            {
                url: "/updateAgreedCost",
                name: "Update Agreed Cost",
                i18n: "updateAgreedCost",
            },
        ],
    },
    {
        url: null,
        name: "Operations",
        icon: "BookIcon",
        i18n: "MenuLevels",
        meta: {
            rule: "cm4",
        },
        submenu: [
            {
                url: "/LeadChanges",
                name: "Operations",
                i18n: "Operations",
            },
            {
                url: "/EditMobile",
                name: "Edit Mobile Number",
                i18n: "Edit Mobile Number",
            },
            {
                url: "/roadmapbroucher",
                name: "Road Map Broucher's",
                i18n: "roadmapbroucher",
            },
            {
                url: "/IdentityTransfer",
                name: "Transfer Lead",
                i18n: "Transfer Lead",
            },
            {
                url: "/mergeLeadInterface",
                name: "Merge Leads",
                i18n: "Merge Leads",
            },
            {
                url: "/permissions",
                name: "Permissions",
                i18n: "Permissions",
            },
            {
                url: "/GMNET",
                name: "GM Net Enquiries Transfer",
                il8n: "GM NET"
            },
            {
                url: "/NetEnquiriesTransfer",
                name: "Net Enquiries Transfer",
                i18n: "Net Enquiries Transfer",
            },
            {
                url: "/NetEnquiryMovementReport",
                name: "Net Enquiries Report",
                i18n: "Net Enquiries Report",
            },
            {
                url: "/LeadAssignment",
                name: "Assignment",
                i18n: "assignment",
            },
            {
                url: "/LeadIIMLAssignment",
                name: "ExEd Assignment",
                i18n: "assignment",
                icon: "LayersIcon",
                meta: {
                    rule: "exed2",
                },
            },
            {
                url: "/SRAssignment",
                name: "SR Assignment",
                i18n: "assignment",
            },
            {
                url: "/UnauthorizedCalls",
                name: "Unauthorized Calls",
                i18n: "unauthorized calls",
            },
            {
                url: "/waMessages",
                name: "Whatsapp Messages",
                i18n: "warehouse",
            },
        ],
    },
    {
        url: null,
        name: "Warehouse Inventory",
        i18n: "inventory",
        icon: "BookIcon",
        submenu: [{
            
                url: "/Inventory",
                name: "Inventory",
                i18n: "Inventory",
            },
            {
                url: "/cityWarehouse",
                name: "City Inventory",
                i18n: "City Inventory",
            },
            {
                url: "/sku",
                name: "Sku",
                i18n: "Sku",
            },
            {
                url: "/DispatchDetails",
                name: "Dispatch Management",
                i18n: "DispatcheManagement",
            },
            {
                url: "/ProcessBulkUpload",
                name: "Bulk Upload",
                i18n: "BulkUpload",
            },
        ],
    },
    {
        url: "/refundsDashboard",
        name: "Refunds Dashboard",
        il8n: "refundsDashboard",
        icon: "BookIcon",
    },
    {
        url: null,
        name: "Warehouse Management",
        i18n: "Warehouse Management",
        icon: "BookIcon",
        submenu: [{
            url: "/warehouse",
            name: "Warehouse CPA",
            i18n: "warehouse",
        },
        {
            url: "/WarehouseCMA",
            name: "Warehouse CMA",
            i18n: "warehouse",
        },
        {
            url: "/WarehouseCFA",
            name: "Warehouse CFA",
            i18n: "warehouse",
        },
        {
            url: "/WarehouseFRM",
            name: "Warehouse FRM",
            i18n: "warehouse",
        },
        {
            url: "/CPAMiscellaneous",
            name: "CPA Misc warehouse",
            i18n: "warehouse",
        },
        {
            url: "/CMAMiscellaneous",
            name: "CMA Misc warehouse",
            i18n: "warehouse",
        },
        ],
    },
    // {
    //     url: null,
    //     name: "Net Enquiry Source",
    //     i18n: "netEnquirySource",
    //     icon: "BookIcon",
    //     submenu: [{
    //         url: "/Netenquirysource",
    //         name: "Net Enquiry Source",
    //         i18n: "NetEnquirySource",
    //     }, ],
    // },

    {
        url: null,
        name: "Whatsapp Broadcast",
        icon: "MessageSquareIcon",
        i18n: "Whatsapp Broadcast",
        // meta: {
        //   rule: "cm4",
        // },
        submenu: [
            {
                url: "/WABroadcastsList",
                name: "Old Broadcast List",
                i18n: "Old Broadcast List",
            },
            {
                url: "/NewWhatsappBroadcastsList",
                name: "New Broadcast List",
                i18n: "New Broadcast List",
            },
            {
                url: "/ManageWhatsappBroadcastMessages",
                name: "Whatsapp Broadcast Messages",
                i18n: "Whatsapp Broadcast Messages",
            },
        ],
    },

    {
        url: null,
        name: "HR Reports",
        icon: "BookIcon",
        i18n: "MenuLevels",
        meta: {
            rule: "cm4",
        },
        submenu: [{
                url: "/attendanceEdit",
                name: "Call Team Attendance",
                i18n: "calllogs",
            },
            {
                url: "/HrAttendance",
                name: "HR Attendance",
                i18n: "hrattendance",
            },
            {
                url: "/HrUtilizationReport",
                name: "HR Utilization Report",
                i18n: "hrutilizationreport",
            },
            // {
            //   url: "/ApplicationTracker",
            //   name: "Application Tracker",
            //   i18n: "Application Tracker",
            // },
            {
                url: "/MonthWiseLeaves",
                name: "Leave Tracker",
                i18n: "leavetrackers",
            },
            {
                url: "/getUserCalendar",
                name: "Users' Attendance",
                i18n: "usersattenadnce",
                icon: "BookIcon",
            },
            {
                url: "/Reporting",
                name: "Reporting",
                i18n: "Reporting",
            },
            {
                url: "/UserWorkStatus",
                name: "Team Work Status",
                i18n: "TeamWorkStatus",
            },
            {
                url: "/UserManagement",
                name: "User Management",
                i18n: "usermanagement",
            },
            // {
            //   url: "/GetUserCalendar",
            //   name: "Users' Attendance",
            //   i18n: "usersattenadnce",
            // },
            {
                url: "/holidays",
                name: "Holiday Manager",
                i18n: "HolidayManager",
            },
            {
                url: "/FuturenseLoginCredsForm",
                name: "Futurense Login Creds",
                i18n: "Futurense Login Creds",
            },
        ],
    },

    {
        url: "/SrUtActivity",
        name: "SR UT Activity",
        slug: "SR UT Activity",
        icon: "BookIcon",
    },
    // {
    //     url: "/AlumniMeet",
    //     name: "USP Alumni Meet",
    //     slug: "USP Alumni Meet",
    //     icon: "BookIcon",
    // },
    {
        url: "/IVRDashboard",
        name: "IVR Dashboard",
        il8n: "IVRDashboard",
        icon: "BookIcon",
    },
    {
        url: "/IvrSpocManagement",
        name: "IVR Spoc Management",
        il8n: "IvrSpocManagement",
        icon: "BookIcon",
    },
    // {
    //     url: "/LeaveRequests",
    //     name: "Leave Requests",
    //     i18n: "leaverequests",
    //     icon: "BookIcon",
    // },
    {
        url: "/TeamAttendance",
        name: "My Team Attendance",
        i18n: "My Team Attendance",
        icon: "BookIcon",
    },
    // {
    //     url: "/summaryOfEnrollments",
    //     name: "Enrollments Summary",
    //     i18n: "My Team Attendance",
    //     icon: "BookIcon",
    // },
    {
        url: "/enrollments",
        name: "Enrollments",
        i18n: "enrollments",
        icon: "AwardIcon",
    },
    // {
    //     url: "/oldEnrollments",
    //     name: "Enrollments Old",
    //     i18n: "oldEnrollments",
    //     icon: "AwardIcon",
    // },
    {
        url: "/receipts",
        name: "Receipts",
        i18n: "Receipts",
        icon: "DollarSignIcon",
    },
    
    {
        url: "/DuesSummary",
        name: "Dues Summary",
        i18n: "Dues Summary",
        icon: "BookOpenIcon",
    },
    {
        url: "/CFAandFRMDueSummary",
        name: "Dues Summary CFA/FRM",
        i18n: "Dues Summary CFA/FRM",
        icon: "BookOpenIcon",
    },
    // {
    //     url: "/ExedDueSummary",
    //     name: "Exed Dues Summary",
    //     i18n: "Exed Dues Summary",
    //     icon: "BookOpenIcon",
    // },
    {
        url: "/DueSummarySumCountRaw",
        name: "Dues Summary Sum Count Raw",
        i18n: "Dues Summary Sum Count Raw",
        icon: "BookOpenIcon",
    },
    {
        url: "/tallyReceipts",
        name: "Tally Receipts",
        i18n: "Tally Receipts",
        icon: "DollarSignIcon",
    },

    // {
    //     url: "/AcadsUtilization",
    //     name: "Acads Utilization",
    //     i18n: "Acads Utilziation",
    //     icon: "PercentIcon",
    // },
    {
        url: "/IIMLAdminDashboard",
        name: "ExEd SPOC Dashboard",
        i18n: "ExEd SPOC Dashboard",
        icon: "HomeIcon",
    },
    {
        url: "/ExedUtilization",
        name: "ExEd Utilization",
        i18n: "ExEd Utilziation",
        icon: "PercentIcon",
    },
    {
        url: "/CCF",
        name: "Utilization Report CCF",
        i18n: "CCF Utilziation",
        icon: "PercentIcon",
    },
    {
      url: "/Exed2IIMLBAUtilization",
      name: "RPO2 Utilization",
      i18n: "RPO2 Utilziation",
      icon: "PercentIcon",
    },
    {
        url: "/UtilizationNew",
        name: "Utilization Report New",
        i18n: "Utilization Report New",
        icon: "PercentIcon",
    },
    {
        url: "/UtilizationReport",
        name: "Utilization Report",
        i18n: "Utilization Report",
        icon: "PercentIcon",
    },
    
    // {
    //     url: "/srUtilization",
    //     name: "SR Utilization",
    //     i18n: "SR Utilization",
    //     icon: "PercentIcon",
    // },
    // {
    //     url: "/gmUtilization",
    //     name: "GM Utilization",
    //     i18n: "GM Utilization",
    //     icon: "PercentIcon",
    // },
    // {
    //     url: "/CollectionUtilization",
    //     name: "Collection Utilization",
    //     i18n: "Collection Utilization",
    //     icon: "PercentIcon",
    // },
    // {
    //     url: "/CorporateUtilization",
    //     name: "Corporate Utilization",
    //     i18n: "Corporate Utilization",
    //     icon: "PercentIcon",
    // },
    {
        url: "/GoogleMeet",
        name: "Google Meet",
        i18n: "Google Meet",
        icon: "BookIcon",
    },
    {
        url: "/SpocAttendance",
        name: "Attendance",
        i18n: "attendance",
        icon: "BookIcon",
    },
    {
        url: "/warehouse",
        name: "WareHouse(WC)",
        i18n: "warehouse",
    },
    // {
    //   url: "/Warehouseaccount",
    //   name: "WH Account(WA)",
    //   i18n: ""
    // },
    {
        url: "/cm2Form",
        name: "Net Enquiry Form",
        i18n: "netenquiryform",
        icon: "BookIcon",
    },
    {
        url: "/excelUpload",
        name: "Excel Upload",
        i18n: "excelUpload",
        icon: "BookIcon",
    },
    {
        url: "/netEnquiryTable",
        name: "Net Enquiry Table",
        i18n: "Net Enquiry Table",
        icon: "BookIcon",
    },
    {
        url: "/receiptsTable",
        name: "Receipts Table",
        i18n: "Receipts Table",
        icon: "DollarSignIcon",
    },
    {
        url: "/NetEnquiryMovementReport",
        name: "Net Enquiries Report",
        i18n: "Net Enquiries Report",
        icon: "BookIcon",
    },
    {
        url: "/TurnAroundTime",
        name: "Enrollments Turn Around Time",
        i18n: "Enrollments Turn Around Time",
        icon: "BookIcon",
    },
    {
        url: "/logout",
        name: "Log Out",
        slug: "logout",
        icon: "PowerIcon",
    },
];