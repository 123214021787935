<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="10"
      >
        <p class="heading">ACADS EVALUATION ADVICE EXAM</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <vs-button
          radius
          color="dark"
          type="gradient"
          icon="edit"
          @click="edit_accads_evaluation_exam"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Documents Verified:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        {{ examsaccads.document_verified }}
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="documents_verified" />
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >No. of Credits:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        {{ examsaccads.number_of_credits }}
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="Credits" />
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Accounting Credits Total:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        {{ examsaccads.accounting_credits }}/{{ examsaccads.upper_division }}
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="accountsDivision" />
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Upper Division:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="upperDivision" />
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Business Credits - Total:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        {{ examsaccads.business_credits }}
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="businesscredits" />
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Bridge Course for Additional Credits:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
        <vs-checkbox
          :disabled="checkboxbridgecoursedisable"
          v-model="applied_for_bridgecourse"
        ></vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <v-select
          v-if="bridgecourse === false"
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 3000;"
          class="w-full"
          v-model="bridgecourse_credits"
          :options="bridgecourseOptions"
          label="text"
        ></v-select>
        <v-select
          v-else
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 3000;"
          class="w-full"
          v-model="bridgecourse_credits"
          :options="bridgecourseOptions"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Evaluation Agency:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <v-select
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 2000;"
          class="w-full"
          v-model="evalauation_agency"
          :options="evalauation_agency_options"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 2000;"
          class="w-full"
          v-model="evalauation_agency"
          :options="evalauation_agency_options"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >State Board:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <v-select
          disabled
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 1000;"
          class="w-full"
          v-model="state_board_transfer"
          :options="board_transfer"
          label="text"
        ></v-select>
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <v-select
          placeholder
          style="margin-top: 4%; margin: 10px;z-index: 1000;"
          class="w-full"
          v-model="state_board_transfer"
          :options="board_transfer"
          label="text"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Comments:</vs-col
      >
      <vs-col
        v-if="AccadsEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        {{ examsaccads.comments }}
      </vs-col>
      <vs-col
        v-if="AccadsEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-textarea icon="edit" v-model="Comments"></vs-textarea>
      </vs-col>
    </vs-row>
    <vs-row class="gaps" style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="9"
        style="font-size: 15px; color: gray"
      ></vs-col>
      <vs-col
        v-if="AccadsEditbutton === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
        style="font-size: 15px; color: gray"
      >
      </vs-col>
      <vs-col
        v-if="AccadsEditbutton === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3"
        style="font-size: 15px; color: gray"
      >
        <vs-button radius color="primary" type="border" @click="editAccadsAPI"
          >SAVE</vs-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
// import _ from "lodash";
import vSelect from "vue-select";
import constants from "../../../../constants.json";
import axios from "axios";
export default {
  components: {
    "v-select": vSelect
  },
  props: ["mwbs_id"],
  data() {
    return {
      Credits: "",
      accountsDivision: "",
      businesscredits: "",
      Comments: "",
      applied_for_bridgecourse: "",
      bridgecourse_credits: "",
      bridgecourse: false,
      state_board_transfer: "",
      board_transfer: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
      ],
      evalauation_agency: "",
      evalauation_agency_options: ["NIES", "WES", "FACS", "JS"],
      bridgecourseOptions: ["Amity PGDPA", "Amity PGDSF", "Other Programs"],
      checkboxbridgecoursedisable: false,
      AccadsEdit: false,
      documents_verified: "",
      AccadsEditbutton: false,
      upperDivision: "",
      examsaccads: ""
      /* mainObj: {
        checkboxbridgecoursedisable: false,
        Credits: "",
        accountsDivision: "",
        businesscredits: "",
        Comments: "",
        applied_for_bridgecourse: "",
        bridgecourse_credits: "",
        state_board_transfer: "",
        evalauation_agency: "",
        documents_verified: "",
        upperDivision: ""
      },
      examsaccads: {
        checkboxbridgecoursedisable: false,
        Credits: "",
        accountsDivision: "",
        businesscredits: "",
        Comments: "",
        applied_for_bridgecourse: "",
        bridgecourse_credits: "",
        state_board_transfer: "",
        evalauation_agency: "",
        documents_verified: "",
        upperDivision: ""
      } */
    };
  },
  watch: {
    applied_for_bridgecourse: function(value) {
      this.bridgecourse = value;
      console.log("dgfsua", this.bridgecourse);
      if (this.bridgecourse === 1) {
        this.bridgecourse = false;
        this.checkboxbridgecoursedisable = true;
      } else if (this.bridgecourse === 0) {
        this.bridgecourse = false;
        this.checkboxbridgecoursedisable = true;
      }
    }
  },
  mounted() {
    this.AccadsExams();
  },
  methods: {
    edit_accads_evaluation_exam() {
      this.AccadsEdit = true;
      this.AccadsEditbutton = true;
      if (this.applied_for_bridgecourse === 1) {
        this.applied_for_bridgecourse = true;
      } else if (this.applied_for_bridgecourse === 0) {
        this.applied_for_bridgecourse = false;
      }
      // this.applied_for_bridgecourse = true;
      this.checkboxbridgecoursedisable = false;
    },
    editAccadsAPI() {
      if (this.applied_for_bridgecourse === true) {
        this.applied_for_bridgecourse = 1;
      } else if (this.applied_for_bridgecourse === false) {
        this.applied_for_bridgecourse = 0;
      }
      let obj = {
        mwb_id: this.mwbs_id,
        document_verified: this.documents_verified,
        number_of_credits: this.Credits,
        accounting_credits: this.accountsDivision,
        upper_division: this.upperDivision,
        business_credits: this.businesscredits,
        is_bridge_course: this.applied_for_bridgecourse,
        bridge_courses: this.bridgecourse_credits,
        evalulation_agency: this.evalauation_agency,
        state_board: this.state_board_transfer,
        comments: this.Comments
      };
      console.log("data", obj);
      let url = "";
      if (this.examsaccads === "") {
        url = `${constants.SERVER_API}cpa_exam_assistance/create`;
      } else {
        url = `${constants.SERVER_API}cpa_exam_assistance/${this.mwbs_id}`;
      }
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.AccadsEdit = false;
            this.AccadsEditbutton = false;
            this.applied_for_bridgecourse = false;
            this.checkboxbridgecoursedisable = true;
            this.AccadsExams();
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    AccadsExams() {
      axios
        .get(`${constants.SERVER_API}cpa_exam_assistance/${this.mwbs_id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("Exams Data", response);
          this.examsaccads = response.data.data;
          this.state_board_transfer = response.data.data.state_board;
          this.evalauation_agency = response.data.data.evalulation_agency;
          this.bridgecourse_credits = response.data.data.bridge_courses;
          this.applied_for_bridgecourse = response.data.data.is_bridge_course;
          this.documents_verified = response.data.data.document_verified;
          this.Credits = response.data.data.number_of_credits;
          this.accountsDivision = response.data.data.accounting_credits;
          this.upperDivision = response.data.data.upper_division;
          this.businesscredits = response.data.data.business_credits;
          this.Comments = response.data.data.comments;
        })
        .catch(error => {
          if (error.response.data.status !== "fail") {
            console.log("This alert is generated from license page");
            this.handleError(error);
          }
        });
    }
  }
};
</script>

<style></style>
