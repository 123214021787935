<template>
  <vs-card style="height: 840px; overflow-y: scroll">
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-navbar
          text-color="black"
          active-text-color="black"
          class="myNavbar"
          v-model="indexActive"
        >
          <!-- <div slot="title">
              <vs-navbar-title>
                {{ navTitleName }}
              </vs-navbar-title>
          </div>-->

          <vs-navbar-item index="0">
            <a href="#" @click="navSettings('ABOUT')">About</a>
          </vs-navbar-item>
          <vs-navbar-item index="1">
            <a href="#" @click="navSettings('HISTORY')">History</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <vs-row
      style="margin-top: 1%; height: auto; overflow-y: auto"
      v-if="navTitleName === 'HISTORY'"
    >
      <vs-row
        v-for="fetch in studentDetails.engagements"
        :key="fetch.key"
        style="height: fit-content"
      >
        <vs-row style="margin-bottom: 1%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              color="primary"
              type="filled"
              :icon="fetchIcon(fetch.type)"
            >
              <b>{{ formatDate(fetch.created_at) }}</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col>
            <vs-card>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                  >{{ fetch.details }}</vs-col
                >
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="12"
          >
            <b>- {{ fetch.added_by_name }}</b>
          </vs-col>
        </vs-row>
      </vs-row>
    </vs-row>
    <div v-if="navTitleName === 'ABOUT'">
      <about :studentDetails="studentDetails" />
    </div>
  </vs-card>
</template>

<script>
import EventBus from "../eventbus.js";
import about from "./AboutComponent.vue";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true
    }
  },
  components: {
    about
  },
  data() {
    return {
      indexActive: 0,
      navTitleName: "",
      options1: [
        { text: "B.Com", value: 0 },
        { text: "M.Com", value: 1 },
        { text: "MBA", value: 2 },
        { text: "CA", value: 3 },
        { text: "CWA", value: 4 },
        { text: "CS", value: 5 },
        { text: "BMS", value: 6 },
        { text: "BBA", value: 7 },
        { text: "PGD", value: 8 },
        { text: "LLB", value: 9 },
        { text: "B.Tech", value: 10 },
        { text: "B.Sc", value: 11 },
        { text: "BA", value: 12 },
        { text: "CA-Inter", value: 13 },
        { text: "CWA-Inter", value: 14 },
        { text: "CS-Inter", value: 15 },
        { text: "PG-Pursuing", value: 16 },
        { text: "BBA-Pursuing", value: 17 }
      ],
      cityOptions: ["HYD", "BNG", "BOM"],
      eligibilityOptions: [
        { text: "Clear", value: 1 },
        { text: "Check", value: 2 },
        { text: "PGDPA", value: 3 },
        { text: "Eligibility", value: 4 }
      ],
      companyEdit: false,
      companyEditbutton: true,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      additionalInfo: "",
      eligibility: "",
      select1: [],
      engagementsload: []
    };
  },
  mounted() {
    // console.log("change to about");
    this.navSettings("ABOUT");
    EventBus.$on("mwb-cards", payload => {
      this.engagementsload = [];
      payload.engagements.forEach(engagement => {
        this.engagementsload.push(engagement);
      });
      this.navTitleName = "ABOUT";
    });
    // EventBus.$on("reload-data", () => {
    //   console.log("got it");
    //   this.navSettings("HISTORY");
    //   setTimeout(function() {
    //     this.navTitleName = "ABOUT";
    //   }, 1000);
    //   //
    // });
  },
  methods: {
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY");
      return formttedDate;
    },
    remove(item) {
      this.select1.splice(this.select1.indexOf(item), 1);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      // console.log(type);
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach(engagement => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    }
  }
};
</script>

<style>

</style>
