<template>
  <vs-table max-items="10" pagination :data="item" style="width: 100%">
    <template slot="thead" class="exc">
      <vs-th>Can-ID</vs-th>
      <vs-th>Receipt No.</vs-th>
      <vs-th>Receipt Date</vs-th>
      <vs-th>Transaction Type</vs-th>
      <vs-th>Amount</vs-th>
      <vs-th>MR Number</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td>{{tr.identity}}</vs-td>
        <vs-td>{{tr.receipt_no}}</vs-td>
        <vs-td>{{unixTimestampConvert(tr.receipt_date)}}</vs-td>
        <vs-td>{{tr.transaction_type}}</vs-td>
        <vs-td>{{tr.amount}}</vs-td>
        <vs-td>{{tr.mr_number}}</vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Array
    }
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.item);
  }
};
</script>