<template>
  <vs-popup
    v-if="open"
    class="holamundo"
    :title="`Resend CPA email to ${tr.mwb.person_name} (${tr.mwb.identity})`"
    :active.sync="open"
    style="z-index:53002"
  >
    <vs-row>
      <vs-input
        class="w-full"
        label="Resend Email"
        placeholder="Email Address"
        v-model="resend_email"
      />
    </vs-row>
    <vs-row style="padding-top:3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="5"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="border" @click="sent_mail()"
          >Send</vs-button
        >
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="5"
      ></vs-col>
    </vs-row>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      open: false,
      resend_email: "",
      enrollment_id: "",
      tr: ""
    };
  },
  mounted() {
    EventBus.$on("open-CpaEmail", tr => {
      // console.log(tr);
      this.tr = tr;
      this.open = true;
      this.enrollment_id = tr.enrollment.id;
      this.resend_email = "";
      // console.log("gummy data", this.enrollment_id);
    });
  },
  methods: {
    sent_mail() {
      this.url = `${constants.SERVER_API}resendAgreementEmail`;
      this.post_API_obj = {
        enrollment_id: this.enrollment_id,
        email_id: this.resend_email
      };
      console.log(this.post_API_obj);
      axios
        .post(this.url, this.post_API_obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.open = false;
          this.handleNotification(response);
          // this.$vs.notify({
          //   time: 20000,
          //   title: "Response",
          //   text: response.data.message,
          //   color: "success",
          //   position: "top-center"
          // });
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>
