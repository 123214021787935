<template>
  <vs-popup :title="'Mark this Net Enquiry as irrelevant?'" :active.sync="open">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-textarea label="Comment " v-model="comments" />
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button
          v-if="netenquiry.invalid_net_enquiry !== 1"
          color="dark"
          type="border"
          @click="markIrrelevant()"
        >Apply</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  mounted() {
    eventbus.$on("mark-irrelevant-net-enquiry", payload => {
      this.netenquiry = payload;
      this.comments = "";
      if (payload.invalid_net_enquiry === 1) {
        this.comments = payload.comments;
      }
      this.open = true;
    });
  },
  data() {
    return {
      comments: "",
      open: false,
      netenquiry: ""
    };
  },
  methods: {
    markIrrelevant() {
      if (this.comments === "") {
        return;
      }
      let obj = {
        comments: this.comments,
        net_enquiry_id: this.netenquiry.id
      };

      axios
        .post(`${constants.SERVER_API}invalidNetEnquiry`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            eventbus.$emit("updateCompleteNetEnquiry");
            this.open = false;
          }
          console.log(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  }
};
</script>

<style>
</style>