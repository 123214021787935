<template>
  <vs-card style="height: 840px; overflow-y: scroll">
    <vs-row style="margin-bottom: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-navbar
          text-color="black"
          active-text-color="black"
          class="myNavbar"
          v-model="activeItem"
        >
          <div slot="title">
            <vs-navbar-title>{{ navTitleName }}</vs-navbar-title>
          </div>
          <vs-navbar-item index="0">
            <a href="#" @click="navSettings('PURCHASES')" id="purchase"
              >PURCHASES</a
            >
          </vs-navbar-item>
          <vs-navbar-item index="1">
            <a href="#" @click="navSettings('DELIVERABLES')" id="deliverables"
              >DELIVERABLES</a
            >
          </vs-navbar-item>
          <vs-navbar-item index="2">
            <a href="#" @click="navSettings('STUDENT RELATION')" id="sr">SR</a>
          </vs-navbar-item>
          <vs-navbar-item index="3">
            <a href="#" @click="navSettings('ABOUT')" id="about">About</a>
          </vs-navbar-item>
          <vs-navbar-item index="4">
            <a href="#" @click="navSettings('HISTORY')" id="history">History</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-if="navTitleName === 'PURCHASES'">
      <div class="boxed">
        <purchase :studentDetails="studentDetails"></purchase>
      </div>
    </div>
    <div v-if="navTitleName === 'DELIVERABLES'">
      <upselling-section :studentDetails="studentDetails"></upselling-section>
    </div>
    <div v-if="navTitleName === 'STUDENT RELATION'">
      <div class="boxed">
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
            <p class="heading">POA</p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="9"
          >
            <vs-button
              size="small"
              color="danger"
              type="gradient"
              @click="poaEscalation()"
            >
              <b>Escalate</b>
            </vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="2"
          >
            <vs-button
              radius
              color="warning"
              type="border"
              icon="chat"
              @click="editPOA"
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px">Exam Target-1</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px">Exam Target-2</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px">{{
              studentDetails.cma.part_1_exam_target
            }}</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px">{{
              studentDetails.cma.part_2_exam_target
            }}</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b style="color: gray; font-size: 12px"></b>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="color: gray; font-size: 15px">Classes Attended:</p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <vs-chip color="dark">
              <b>{{ studentDetails.classes_attended }}</b>
            </vs-chip>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <vs-chip color="dark">
              <b>{{ studentDetails.classes_attended }}</b>
            </vs-chip>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.classes_attended}}</b>
        </vs-chip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.classes_attended}}</b>
        </vs-chip>
          </vs-col>-->
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="color: gray; font-size: 15px">OTB Attempt:</p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <vs-chip color="dark">
              <b>{{ studentDetails.OTB_attended }}</b>
            </vs-chip>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <vs-chip color="dark">
              <b>{{ studentDetails.OTB_attended }}</b>
            </vs-chip>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.OTB_attended}}</b>
        </vs-chip>
      </vs-col>
       <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-chip color="dark">
          <b>{{studentDetails.OTB_attended}}</b>
        </vs-chip>
          </vs-col>-->
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="color: gray; font-size: 15px">IMA Schloarship Details:</p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b>{{ studentDetails.name_of_college }}</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b>{{ studentDetails.schloarship_status }}</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <b>{{ studentDetails.schloarship_comments }}</b>
          </vs-col>
        </vs-row>
      </div>
      <!-- <vs-divider class="mt-6"></vs-divider> -->
      <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">UPSELLING</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Exam Target-1</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Exam Target-2</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Classes</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p>25</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>30</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.classes, 'CLASSES')"
            >
              <b>AddOn</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Videos</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">54</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p :style="{color :  upsellingcolor(false)}">85</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.videos, 'VIDEOS')"
            >
              <b>Buy</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">OTB</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">85</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(true)"></vs-icon>
            <p :style="{color :  upsellingcolor(true)}">98</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.OTB, 'OTB')"
            >
              <b>Extend</b>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <b style="color: gray; font-size: 12px">Books</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>74</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-icon size="medium" :icon="generateicon(false)"></vs-icon>
            <p>89</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-button
              color="danger"
              type="gradient"
              size="small"
              @click="escalate(studentDetails.Books, 'BOOKS')"
            >
              <b>Buy</b>
            </vs-button>
          </vs-col>
        </vs-row>
      </div>-->
      <!-- <vs-divider class="mt-6"></vs-divider> -->
      <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <p style="color: gray; font-size: 15px">Educational Tags:</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
            <v-select
              placeholder="Badges"
              multiple
              style="z-index: 1000;  margin-top: 4%; margin: 10px;"
              class="w-full"
              v-model="select1"
              :options="options1"
            ></v-select>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            <vs-chip
              @click="remove(chip)"
              closable
              color="primary"
              v-for="chip in select1"
              :key="chip.index"
            >
              <b>{{ chip }}</b>
            </vs-chip>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
            <p style="color: gray; font-size: 15px">Need For Bridge Course:</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="yes">YES</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="no">NO</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="na">N/A</vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
            <vs-radio v-model="radios1" vs-value="taken">TAKEN</vs-radio>
          </vs-col>
        </vs-row>
      </div>
      <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">CORPORATES REFFERANCE</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Name</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Designation</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Yrs</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Referral</b>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Apple</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Programmer</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">6</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">--</b>
          </vs-col>
        </vs-row>
        <vs-collapse :type="'margin'">
          <vs-collapse-item>
            <div slot="header">Miles Referrance</div>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Name</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Department</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Status</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                <vs-button
                  color="dark"
                  type="gradient"
                  @click="emitaddDetails('MILES_REFERRANCE')"
                  icon="add"
                ></vs-button>
              </vs-col>
            </vs-row>
            <div v-for="users in miles_referrance" :key="users.index">
              <vs-row class="gap">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.name}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.department}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.status}}</b>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
          <vs-collapse-item>
            <div slot="header">CareerGraph Referrance</div>

            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Name</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Department</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <b style="color: gray; font-size: 12px">Status</b>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                <vs-button
                  color="dark"
                  type="gradient"
                  @click="emitaddDetails('CAREERGRAPH_REFERRANCE')"
                  icon="add"
                ></vs-button>
              </vs-col>
            </vs-row>
            <div v-for="users in careergraph_referrance" :key="users.index">
              <vs-row class="gap">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.name}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.department}}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                  <b style="color: gray; font-size: 12px">{{users.status}}</b>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>-->
      <!-- <vs-di/vider class="mt-6"></vs-divider> -->
      <div class="boxed">
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="10"
          >
            <p class="heading">STUDENT REFERENCE</p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="2"
          >
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="add"
              @click="add_student_cma_referrance"
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Student Name</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Referrence Date</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Current Status</b>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="color: gray; font-size: 12px">Bonus</b>
          </vs-col>-->
        </vs-row>
        <div
          v-for="users in studentDetails.student_reference"
          :key="users.index"
        >
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <b style="color: gray; font-size: 12px">{{
                users.referred_person_name
              }}</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <b style="color: gray; font-size: 12px">{{
                convertTimestampToDate(users.reference_given_date)
              }}</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <b style="color: gray; font-size: 12px">{{
                users.bonus_status
              }}</b>
            </vs-col>
            <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
              <vs-checkbox v-model="student_bonus" :vs-value="users.student_name"></vs-checkbox>
            </vs-col>-->
          </vs-row>
        </div>
      </div>
      <!-- <vs-divider class="mt-6"></vs-divider> -->
      <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            City: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8" style="font-size: 15px; color: gray">
            <vs-select class="selectExample" label="Cities" v-model="city">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in cityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="font-size: 15px; color: gray">
            Eligibility: 
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
            <vs-select class="selectExample" label="Eligibility" v-model="eligibility">
              <vs-select-item :key="index" :value="item.text" :text="item.text" v-for="(item,index) in eligibilityOptions"/>
            </vs-select>
          </vs-col>
        </vs-row>
        
      </div>-->
      <!-- <vs-divider class="mt-6"></vs-divider> -->
      <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">OTHER COURSES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>CPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>DA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>RPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-checkbox>FOF(O)</vs-checkbox>
          </vs-col>
        </vs-row>
      </div>-->
      <!-- <vs-divider class="mt-6"></vs-divider> -->
      <!-- <div class="boxed">
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <p class="heading">FEEDBACK ON MILES</p>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On Call:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
            
            <star-rating :star-size="20" v-model="customrating" @rating-selected="setRating" />
            
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
            
            {{ratingFeedback}}
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3"
            style="font-size: 15px; color: gray"
          >On App:</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
            
          </vs-col>
        </vs-row>
      </div>-->
    </div>

    <div v-if="navTitleName === 'ABOUT'">
      <about :studentDetails="studentDetails" />
    </div>
    <div v-if="navTitleName === 'HISTORY'">
      <vs-row style="margin-top: 1%; height: auto; overflow-y: auto">
        <vs-row
          v-for="fetch in studentDetails.engagements"
          :key="fetch.key"
          style="height: fit-content"
        >
          <vs-row style="margin-bottom: 1%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
            >
              <vs-button
                color="primary"
                type="filled"
                :icon="fetchIcon(fetch.type)"
              >
                <b>{{ fetch.created_at }}</b>
              </vs-button>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col>
              <vs-card>
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="12"
                    >{{ fetch.details }}</vs-col
                  >
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="12"
            >
              <b>- {{ fetch.added_by_name }}</b>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-row>
    </div>
    <CMAPOAEdit v-on:poa-updated="udatePOA" />
    <AddCmaStudentReference v-on:reference-added="updateStudentReferences" />
  </vs-card>
</template>

<script>
import EventBus from "../eventbus.js";
import StarRating from "vue-star-rating";
import about from "../EWB/CPA/Candidates/Details_Popup/EwbCandidatesAboutCMA.vue";
import vSelect from "vue-select";
// import StudentCmaReferrance from "../pagesComponents/AddCmaStudentReference";
import CMAPOAEdit from "./CMAPOAEdit.vue";
// import AddStudentCmaReferrance from "../pagesComponents/AddCmaStudentReference";
import AddCmaStudentReference from "./AddCmaStudentReference";
import Upselling_Section from "../EWB/CPA/Candidates/Details_Popup/Upselling_Section.vue";
import Purchases from "../EWB/CPA/Candidates/Details_Popup/Purchases.vue";
export default {
  props: {
    studentDetails: {
      required: true
    }
  },
  components: {
    StarRating,
    about,
    "v-select": vSelect,
    AddCmaStudentReference: AddCmaStudentReference,
    purchase: Purchases,
    CMAPOAEdit,
    "upselling-section": Upselling_Section
  },
  data() {
    return {
      activeItem: 0,
      radios1: "",
      navTitleName: "",
      eligibility: "",
      miles_referrance: [],
      careergraph_referrance: [],
      student_referrance: [],
      ratingFeedback: "",
      feedback: "",
      customrating: 0,
      bridge_course: [],
      yes: false,
      no: false,
      taken: false,
      na: false,
      select1: [],
      options1: [
        "PUC",
        "INTER",
        "B.Com",
        "M.Com",
        "MBA",
        "CA",
        "CWA",
        "CS",
        "BMS",
        "BBA",
        "PGD",
        "LLB",
        "B.Tech",
        "B.Sc",
        "BA",
        "CA-Inter",
        "CWA-Inter",
        "CS-Inter",
        "PG-Pursuing",
        "BBA-Pursuing",
        "CFA",
        "FRM",
        "CFA(P)",
        "MFA",
        "MFA(P)",
        "CMA(US)",
        "CPA(US)"
        // { text: "B.Com", value: 0 },
        // { text: "M.Com", value: 1 },
        // { text: "MBA", value: 2 },
        // { text: "CA", value: 3 },
        // { text: "CWA", value: 4 },
        // { text: "CS", value: 5 },
        // { text: "BMS", value: 6 },
        // { text: "BBA", value: 7 },
        // { text: "PGD", value: 8 },
        // { text: "LLB", value: 9 },
        // { text: "B.Tech", value: 10 },
        // { text: "B.Sc", value: 11 },
        // { text: "BA", value: 12 },
        // { text: "CA-Inter", value: 13 },
        // { text: "CWA-Inter", value: 14 },
        // { text: "CS-Inter", value: 15 },
        // { text: "PG-Pursuing", value: 16 },
        // { text: "BBA-Pursuing", value: 17 }
      ],
      city: "BOM",
      cityOptions: [
        { text: "HYD", value: 1 },
        { text: "BNG", value: 2 },
        { text: "BOM", value: 3 }
      ],
      eligibilityOptions: [
        { text: "Clear", value: 1 },
        { text: "Check", value: 2 },
        { text: "PGDPA", value: 3 },
        { text: "Eligibility", value: 4 }
      ],
      courseSelection: this.studentDetails.course,
      companyEdit: false,
      companyEditbutton: true,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      LDData: [
        {
          name: "Monroe",
          department: "HR",
          status: "M7",
          email: "monroe@gmail.com",
          phone: 9283949939
        }
      ],
      student_bonus: [],
      submitted_documents: "",
      engagementsload: []
    };
  },
  mounted() {
    this.navSettings("PURCHASES");
    /*     EventBus.$on("open-cards", payload => {
      this.select1 = [];
      this.engagementsload = [];
      this.engagementStorefetch(payload.id);
      this.navTitleName = "STUDENT RELATION";
      payload.educational_tags.forEach(tag => {
        this.select1.push(tag);
      });
      this.miles_referrance = [];
      this.$store.state.miles_referrance.forEach(referrance => {
        if (referrance.candidate_id === payload.id) {
          this.miles_referrance.push(referrance);
        }
      });
      this.careergraph_referrance = [];
      this.$store.state.careergraph_referrance.forEach(referrance => {
        if (referrance.candidate_id === payload.id) {
          this.careergraph_referrance.push(referrance);
        }
      });
      this.student_referrance = [];
      this.$store.state.student_referrance.forEach(referrance => {
        if (referrance.candidate_id === payload.id) {
          this.student_referrance.push(referrance);
          if (referrance.bonus === true) {
            this.student_bonus.push(referrance.student_name);
          }
        }
      });
      this.submitted_documents = payload.submitted_documents;
      this.bridge_course = [];
      this.bridge_course.push(payload.bridge_course);
      this.customrating = payload.rating;
      this.eligibility = payload.eligibility;
    }); */
    EventBus.$on("update-bonus", () => {
      this.studentDetails.student_referrance.forEach(student => {
        if (student.bonus === true) {
          this.student_bonus.push(student.student_name);
        }
      });
    });
    EventBus.$on("update-feedback", payload => {
      this.ratingFeedback = payload;
    });
  },
  watch: {
    navTitleName: function(value) {
      if (value === "ABOUT") {
        // EventBus.$emit('update-city', this.studentDetails)
      }
    }
  },
  methods: {
    add_student_cma_referrance() {
      console.log("emitted");
      EventBus.$emit("open-add-student-cma-referrance", this.studentDetails);
      // this.EmitEvent('open-add-student-referrance', this.studentDetails.id)
    },
    poaEscalation() {
      EventBus.$emit("poa-escalation");
    },
    upsellingcolor(number) {
      number = parseInt(number);
      if (number < 30 || number < 31) {
        return "red";
      } else {
        return "";
      }
    },
    escalate(payload, section) {
      EventBus.$emit("escalate-popup", [payload, section]);
    },
    generateicon(bool) {
      switch (bool) {
        case true:
          return "done";
        case false:
          return "error_outline";
      }
    },
    setRating: function(rating) {
      this.customrating = rating;
      EventBus.$emit("open-rating-popup", rating);
    },
    editPOA() {
      this.EmitEvent("open-edit-cma-POA", this.studentDetails);
    },
    emitaddDetails(commit) {
      EventBus.$emit("open-badge-details", [commit, this.studentDetails.id]);
    },
    remove(item) {
      this.select1.splice(this.select1.indexOf(item), 1);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach(engagement => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
    udatePOA(cma) {
      this.studentDetails.cma = cma;
    },
    updateStudentReferences(refernces) {
      this.studentDetails.student_reference = refernces.student_reference;
    }
  }
};
</script>

<style>
.gap {
  margin-bottom: 1%;
}
</style>
