<template>
    <div>
      <!-- <vs-popup
      class="holamundo"
      :title="getSpocName(selectedPayload.user_id) + ' - ' + selectedPayload.contact_type + ' - ' + selectedPayload.directory"
      :active.sync="open"
    >
      <vs-alert :active="errorshow" color="danger" icon="new_releases" v-if="open">
        <span>{{ info }}</span>
      </vs-alert>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
            {{ getMomentDateFromTimestamp(selectedPayload.time) }}
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <b>
            {{ hidePhoneNumber(selectedPayload.phone_number) }} (
            {{ this.selectedPayload.person_name }} )
          </b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <audio id="myAudio" controls>
            <source :src="audiolink" type="audio/mpeg" />
          </audio>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
          <vs-button size="small" color="transparent" @click="backward()">
            <vs-icon icon="replay_10" color="black" size="medium"></vs-icon>
          </vs-button>
          <vs-button size="small" color="transparent" @click="forward()">
            <vs-icon icon="forward_10" color="black" size="medium"></vs-icon>
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12" >
          <vs-textarea v-if="selectedPayload.audit_done == null"   style="overflow: hidden;" label="Comment" v-model="audit_comments" />
          <vs-textarea v-if="selectedPayload.audit_done == 1" disabled="true"  style="overflow: hidden;" label="Comment" v-model="audit_comments" />
        </vs-col>
      </vs-row>
      <div v-if="selectedPayload.audit_done == 1">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="5" >
            <b>Audit done by </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" >
           :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5" >
            {{selectedPayload.auditor_first_name}} {{selectedPayload.auditor_last_name}}
          </vs-col>
        </vs-row>
         <vs-row >
          <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="5" >
            <b>Audit done at</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">:</vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5" >
            {{customFormatter(selectedPayload.audit_done_at)}} 
          </vs-col>
        </vs-row>
      </div>
      <br/>
      <vs-row v-if="selectedPayload.audit_done == null">
        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
          <vs-button @click="submitComment()">Submit</vs-button>
        </vs-col>
      </vs-row>
      </vs-popup> -->
      <div v-show="play_audio_popup">
        <div id="mydiv">
        <div id="mydivheader">
          <vs-row class="py-1">
            <vs-col vs-type="flex" vs-w="11" vs-align="center" style="padding:0px;">
               <p class="ml-2" style="font-size: 1.2rem;">{{getSpocName(selectedPayload.user_id)}} - {{selectedPayload.contact_type }} - {{selectedPayload.directory}}</p> 
            </vs-col>
            <vs-col vs-type="flex" vs-w="1" vs-align="center" vs-justify="flex-end" style="padding:0px;">
              <vs-button color="primary" size="small" type="border" @click="play_audio_popup = false" icon="cancel"></vs-button>
            </vs-col>
          </vs-row>
          <vs-alert :active="errorshow" color="danger" icon="new_releases" v-if="open">
        <span>{{ info }}</span>
      </vs-alert>
        </div>
        <div style="margin: 10px;">
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
                {{ getMomentDateFromTimestamp(selectedPayload.time) }}
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
              <b>
                {{ hidePhoneNumber(selectedPayload.phone_number)  }} (
                {{ selectedPayload.person_name }} )
              </b>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12">
              <audio id="my-Audio" controls >
                <source :src="audio_link" type="audio/mpeg"  />
                <!-- <source src="https://seet-miles-cm-call-recordings.s3.ap-south-1.amazonaws.com/1593_1674012863000.mp3" /> -->
              </audio>
            </vs-col>
          </vs-row>
          <vs-row style="margin-top: 1%">
            <vs-col vs-w="12" style="text-align:center">
              <vs-button size="small" color="transparent" @click="backward()">
                <vs-icon icon="replay_10" color="black" size="medium"></vs-icon>
              </vs-button>
              <vs-button size="small" color="transparent" @click="forward()">
                <vs-icon icon="forward_10" color="black" size="medium"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-row>
          <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="12" style="width: 517px;">
            <vs-textarea v-if="selectedPayload.audit_done == null"   style="overflow: hidden;" label="Comment" v-model="audit_comments" />
            <vs-textarea v-if="selectedPayload.audit_done == 1" disabled="true"  style="overflow: hidden;" label="Comment" v-model="audit_comments" />
          </vs-col>
        </vs-row>
        <div v-if="selectedPayload.audit_done == 1">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="5" >
              <b>Audit done by </b>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" >
            :
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5" >
              {{selectedPayload.auditor_first_name}} {{selectedPayload.auditor_last_name}}
            </vs-col>
          </vs-row>
          <vs-row >
            <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="5" >
              <b>Audit done at</b>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">:</vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="flex-start" vs-w="5" >
              {{selectedPayload.audit_done_at}} 
            </vs-col>
          </vs-row>
          
        </div>
        <br/>
          <vs-row v-if="selectedPayload.audit_done == null" class="mb-5">
            <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
              <vs-button @click="submitComment()">Submit</vs-button>
            </vs-col>
          </vs-row>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import moment from "moment";
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import axios from "axios";
export default {
  data() {
    return {
      draggable: "",
      audit_comments: "",
      open: false,
      errorshow: false,
      info: "",
      audio_link: "",
      selectedPayload: {},
      rawbde_data: [],
      play_audio_popup: false,
    };
  },
  mounted() {
    
    EventBus.$on("error-finding-calllog", () => {
      this.info = "Cannot find the call";
      this.errorshow = true;
      setTimeout(() => {
        this.open = false;
        this.errorshow = false;
      }, 1000);
    });
    EventBus.$on("open-recording-popup", (payload, payload2) => {
      this.play_audio_popup = false;
      this.selectedPayload = payload2;
      if(this.selectedPayload.audit_done == 1){
        this.audit_comments = "";
        this.audit_comments =  this.selectedPayload.audit_comments;
      }
      if(this.selectedPayload.audit_done != 1){
        this.audit_comments = "";
      }
      this.audio_link = payload;
      // let audioplayer = document.getElementById("myAudio");
      // audioplayer.load();
      document.getElementById("my-Audio").setAttribute('src',  this.audio_link);
      console.log("audio_link",this.audio_link)
      this.open = true;
      this.play_audio_popup = true;
      this.dragElement();
    });
    EventBus.$on("open-recording-popup-rawBde", (rawBde) => {
      this.rawbde_data = rawBde;
    });
    
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY HH:mm");
    },
    hidePhoneNumber(value){
     var number = value
     var mobile = ''
     if(number.slice(0,1)=='+'){
       mobile = number.substring(3)
       var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
       return phone;
     }else{
       return number.replace(number.substring(0,6), 'XXXXXX');
     }
    },
    submitComment(){
      let obj = {
        call_id: this.selectedPayload.id,
        comments: this.audit_comments
      };
      let url = `${constants.SERVER_API}saveCallAudit`;
      axios
        .post(url,obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if(response.data.status == 'success'){
            this.$vs.notify({
              title: response.data.message,
              color: "success",
            });
            this.selectedPayload.audit_comments = response.data.audit_comments;
            this.selectedPayload.auditor_first_name = response.data.auditor_first_name;
            this.selectedPayload.auditor_last_name = response.data.auditor_last_name;
            this.selectedPayload.audit_done_at = response.data.audit_done_at.date;
            this.selectedPayload.audit_done = response.data.audit_done;
            let call_id = response.data.call_id;
            if (this.$route.path === "/CallLogs") {
              EventBus.$emit("update-audit", call_id);
            }
          }
          this.open = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    
    getSpocName(spoc_id) {
      let name = "";
      // console.log(this.rawBde);
      this.rawbde_data.forEach((spoc) => {
        if (spoc_id === spoc.id) {
          name = spoc.full_name;
        }
      });
      return name;
    },
    getMomentDateFromTimestamp(value) {
      var dateString = moment.unix(value / 1000).format("DD-MMM-YYYY HH:mm");
      return dateString;
    },
    forward() {
      console.log("forward");
      var aud = document.getElementById("my-Audio");
      // console.log("aud", aud);
      aud.currentTime += 10;
    },
    backward() {
      console.log("backward");
      var aud = document.getElementById("my-Audio");
      aud.currentTime -= 10;
    },
     dragElement() {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      var elmnt = document.getElementById("mydiv")
      if (document.getElementById(elmnt.id + "header")) {
      document.getElementById(elmnt.id + "header").onmousedown = (e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
        // call a function whenever the cursor moves:
        document.onmousemove = (e)=>{
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        };
          };
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      document.getElementById("mydiv").onmousedown =(e)=>{
            e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = ()=>{
          document.onmouseup = null;
           document.onmousemove = null;
        };
      };
    }
    },
  },
  // watch: {
  //   play_audio_popup: function (val) {
  //     if (val === false) {
  //       let audioplayer = document.getElementById("myAudio");
  //       audioplayer.pause();
  //       audioplayer.currentTime = 0;
  //     }
  //   },
  // },
};
</script>
<style>
  .vs-component.con-vs-popup.holamundo.vs-popup-primary {
    z-index: 60000;
  }
  #mydiv {
  position: absolute;
  z-index: 2000000;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  top: 17%;
  left: 23%;
  border-radius: 10px;
}
#mydivheader {
  /* padding: 10px; */
  cursor: move;
  z-index: 2000000;
  background: rgba(0,0,0,.05);
  color: rgb(26, 21, 21);
}
</style>
