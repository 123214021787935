<template>
  <div>
    <vx-card actionable class="cardx" title="Loan Application Check">
      <!-- <vs-row type="flex">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button color="primary" @click="CheckCybil()"
            >Check Cibil Status</vs-button
          >
        </vs-col>
      </vs-row> -->
      <div style="margin-top: 2%">
        <vs-table :data="studentDetails.loan_status">
          <template slot="thead">
            <!-- <vs-th>Loan Type</vs-th>
            <vs-th>Application ID</vs-th>
            <vs-th>Date</vs-th> -->
            <vs-th>Application Id</vs-th>
            <vs-th>Message</vs-th>
            <vs-th>Course</vs-th>
            <vs-th>Tenure</vs-th>
            <vs-th>Cibil Status</vs-th>
          </template>

          <template slot-scope="">
            <tbody ref="tbody">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in studentDetails.loan_status"
              >
                <!-- <vs-td>
                  {{ getLoanType(tr) }}
                </vs-td>
                <vs-td>
                  {{ getApplicationID(tr) }}
                </vs-td>
                <vs-td>
                  {{ formattedCreatedAt(tr.created_at) }}
                </vs-td> -->
                <vs-td>
                  {{ returnJson(tr.payload) }}
                </vs-td>
                <vs-td>
                  {{ returnTextJson(tr.payload) }}
                </vs-td>
                <vs-td>
                  {{ courseShowing(tr.payload) }}
                </vs-td>
                <vs-td>
                  {{ tenureShowing(tr.payload) }}
                </vs-td>
                <vs-td>
                  <div>
                    <vs-row>
                      <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="6"
                      >
                        <vs-icon
                          :icon="getLoanStatusValue('icon', tr)"
                          size="small"
                          :color="getLoanStatusValue('color', tr)"
                        ></vs-icon>
                      </vs-col>
                      <!-- <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="6"
                      > -->
                      <!-- </vs-col> -->
                    </vs-row>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <div style="margin-top: 2%">
        <vs-table :data="trackingdata">
          <template slot="thead">
            <!-- <vs-th>Loan Type</vs-th>
            <vs-th>Application ID</vs-th>
            <vs-th>Date</vs-th> -->
            <vs-th>Application ID</vs-th>
            <vs-th>Current Stage</vs-th>
            <vs-th>Date</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data">
                
                <!-- <vs-td>
                  {{ getLoanType(tr) }}
                </vs-td>
                
                <vs-td>
                  {{ formattedCreatedAt(tr.created_at) }}
                </vs-td> -->
                <vs-td>
                  {{ getApplicationID(tr) }}
                </vs-td>
                <vs-td>
                  {{ tr.current_stage_name }}
                </vs-td>
                <vs-td>
                  {{ formatDate(tr.created_at) }}
                </vs-td>
                <!-- <vs-td>
                  <div>
                    <vs-row>
                      <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="6"
                      >
                        <vs-icon
                          :icon="getLoanStatusValue('icon', tr)"
                          size="small"
                          :color="getLoanStatusValue('color', tr)"
                        ></vs-icon>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-td> -->
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <loan-check-popup  :popup.sync="popup" :studentDetails="studentDetails" :LoanData="LoanData"></loan-check-popup>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../../constants.json";
// import EventBus from "../../eventbus";
import LoanCheckPopup from "../../pagesComponents/LoanCheckPopup";
import moment from "moment";
export default {
  mounted() { 
    // this.getData();
    this.getUserInfor();
    this.popup = false;
  },
  props: {
    studentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trackingdata: [],
      candidate_uuid: "",
      popup: false,
      LoanData:[],
    };
  },
  components: {
    LoanCheckPopup
  },
  watch: {},
  methods: {
    
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY");
      return formttedDate;
    },
    getUserInfor() {
      const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=${this.studentDetails.mwb.id}`;
      // const url = `${constants.SERVER_API}getLoanApplicationDetails?mwb_id=469671`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getLoanApplicationDetails", response);
          this.LoanData = response.data;
          this.uuidData = response.data.candidate_uuid;
          if(response.data != ""){
            this.getData();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    CheckCybil() {
      this.getUserInfor();
      this.popup = true;
    },
    getData() {
      let uuid = this.uuidData;
      const url = `https://loans.milesforce.com/api/loanApplicationStatus?uuid=${uuid}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          
          this.trackingdata = response.data.response;
          
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    returnJson(tr) {
      
      let responseData = null;
      let response = JSON.parse(tr);
      
        if(response.eduvanz_response.cibil_status === 0 || response.eduvanz_response.cibil_status === 2) {
          responseData = response.eduvanz_response.application_id;
        } else {
          responseData = response.eduvanz_response.application_id;
        }
      return responseData;
    },
    courseShowing(tr) {
      let responseData = null;
        let response = JSON.parse(tr);
  
          if(response.eduvanz_request.course_details.client_course_id === 825) {
            responseData = "CPA";
          }
           if(response.eduvanz_request.course_details.client_course_id === 828) {
            responseData = "CMA";
          }
        return responseData;
    },
    tenureShowing(tr) {
      // let responseData = null;
        let response = JSON.parse(tr);
        return response.eduvanz_request.course_details.tenure;
    },
    returnTextJson(tr) {
      let responseValue = null;
      let response = JSON.parse(tr);
      if(response.eduvanz_response.error[0] !== "" && response.eduvanz_response.error.length === 1) {
        responseValue = response.eduvanz_response.error[0];
      } else {
        if(response.eduvanz_response.cibil_status === 0 || response.eduvanz_response.cibil_status === 2) {
          responseValue = "Cibil is not good. Please share your apllication id with rahul."
        } else {
          responseValue = "Cibil is good. Please continue with further steps."
        }
      } 
      return responseValue;
    },
    getLoanStatusValue(value, tr) {
      let response = JSON.parse(tr.payload);
      let returnValue = null;
      let mainStructure = null;
      let Iconstructure = {
        1: "check_circle_outline",
        0: "cancel",
        2: "help"
      };
      let colorStructure = {
        1: "success",
        0: "danger",
        2: "warning"
      };
      if (value === "color") {
        mainStructure = colorStructure;
      } else {
        mainStructure = Iconstructure;
      }
      returnValue = mainStructure[response.eduvanz_response.cibil_status];
      return returnValue;
    },
    formattedCreatedAt(date) {
      // return date.split(" ")[0];
      return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD-MMM-YYYY");
    },
    checkLoanStatus(value, tr) {
      let response = JSON.parse(tr.payload);
      let returnvalue = false;
      let structure = {
        success: 1,
        error: 0,
      };
      if (response.eduvanz_response.stp_status === structure[value]) {
        returnvalue = true;
      }
      return returnvalue;
    },
    // getLoanStatus(tr) {
    //   let response = JSON.parse(tr.payload);
    //   // return response;
    //   return structure[response.eduvanz_request.applicant_details.profession];
    // },
    getApplicationID(tr) {
      let response = tr;
      return response.application_id;
    },
    getLoanType(tr) {
      let response = JSON.parse(tr.payload);
      // return response;
      let structure = {
        1: "Self",
        2: "Student",
      };
      return structure[response.eduvanz_request.applicant_details.profession];
    },
    
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
</style>