<template>
  <div>
    <div v-for="(item, index) in this.merged_array" :key="item.application_id">
      <vs-card>
        <div slot="header">
          <h5 >{{ item.can_name }} : {{item.application_id }}</h5>
        </div>
        <div>
          <!--Pan Card-->
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
              <span>Pan Card *</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <label>
                <input
                  type="file"
                  id="Panfile"
                  ref="Panfile"
                  v-on:change="onPanChangeFileUpload(index)"
                />
              </label>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
              <vs-button
                size="small"
                @click="PanCard(item)"
                style="margin-top: 10%; margin-top: 0%"
                >Upload
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
              v-if="item.remarks.includes('PAN Card')"
            >
              <span>
                <vs-icon
                  icon="check_circle"
                  size="small"
                  color="success"
                  style="margin-top: 1%; margin-left: -3%"
                >
                </vs-icon>
              </span>
            </vs-col>
          </vs-row>
          <!-- Bank Statements -->
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
              <span>Salary Account Bank Statements/Passbook*</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <label>
                <input
                  type="file"
                  id="BankStatementsFile"
                  ref="BankStatementsFile"
                  v-on:change="onBankStatementsChangeFileUpload(index)"
                />
              </label>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
              <vs-button
                size="small"
                @click="bankStatements(item)"
                style="margin-top: 10%; margin-top: 0%"
                >Upload
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
               v-if="item.remarks.includes('Bank Statement')"
            >
              <span>
                <vs-icon
                  icon="check_circle"
                  size="small"
                  color="success"
                  style="margin-top: 1%; margin-left: -3%"
                >
                </vs-icon>
              </span>
            </vs-col>
          </vs-row>
          <!--Adhaar Card -->
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
              <span>Adhaar Card</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <label>
                <input
                  type="file"
                  id="Adhaarfile"
                  ref="Adhaarfile"
                  v-on:change="onAdhaarChangeFileUpload(index)"
                />
              </label>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
              <vs-button
                size="small"
                @click="adhaarCard(item)"
                style="margin-top: 10%; margin-top: 0%"
                >Upload
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
               v-if="item.remarks.includes('Aadhaar Card')"
            >
              <span>
                <vs-icon
                  icon="check_circle"
                  size="small"
                  color="success"
                  style="margin-top: 1%; margin-left: -3%"
                >
                </vs-icon>
              </span>
            </vs-col>
          </vs-row>
          
          <!-- Pay Slips -->
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
              <span>Salary slips</span>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
              <label>
                <input
                  type="file"
                  id="SalarySlipfile"
                  ref="SalarySlipfile"
                  v-on:change="onSalarySlipChangeFileUpload(index)"
                />
              </label>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
              <vs-button
                size="small"
                @click="salarySlips(item)"
                style="margin-top: 10%; margin-top: 0%"
                >Upload
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="1"
              v-if="item.remarks.includes('Income Proof')"
            >
              <span>
                <vs-icon
                  icon="check_circle"
                  size="small"
                  color="success"
                  style="margin-top: 1%; margin-left: -3%"
                >
                </vs-icon>
              </span>
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import _ from "lodash";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      merged_array: [],
      created_application_data: [],
      // pan_show_image: false,
      // adhaar_show_image: false,
      // bank_statements_show_image: false,
      // pay_slips_show_image: false,
    };
  },
  mounted() {
    console.log(this.studentDetails, "documents");
    this.checkLoanApplication();
    
  },
  methods: {
    checkLoanApplication() {
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        identity: this.studentDetails.mwb.identity,
      };
      // let url = `https://liquiloans.milesforce.com/api/checkLiveLoanApplication`;
      let url = `https://liquiloans.milesforce.com/api/checkLoanApplicationMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("created_application_data", response);
          if (response.data.message === "Application created") {
            this.show_created_application_data = true;
            this.created_application_data = response.data.data;
            this.getUploadedDocumentData();
            // this.getCkycData(this.created_application_data.mwb_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getUploadedDocumentData() {
      this.merged_array = [];
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        identity: this.studentDetails.mwb.identity,
      };
      // let url = `https://liquiloans.milesforce.com/api/getLiveUploadedDocumentData`;
      let url = `https://liquiloans.milesforce.com/api/getUploadedDocumentDataMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getUploadedDocumentData", response);
          if (response.data.status === "success") {
            let uploaded_documents_list = response.data.data;
            console.log("single array",_.flatten(uploaded_documents_list));
            let single_array = _.flatten(uploaded_documents_list);
            for (let i = 0; i < this.created_application_data.length; i++) {
              const element = this.created_application_data[i];
              // let merge = {};
              let remarks = [];
              for (let i = 0; i < single_array.length; i++) {
                const item = single_array[i];
                // console.log("merge objects",element, item );
                if(element.application_id == item.application_id){
                  // merge = Object.assign( {}, element, item );
                  remarks.push(item.remarks);
                }
              }
              Object.assign(element, {remarks: [...new Set(remarks)]});
            }
            this.merged_array = this.created_application_data;
            // this.showUploadedDocumentsData(uploaded_documents_list);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // showUploadedDocumentsData(uploaded_documents) {
    //   console.log("uploaded_documents dfdgd", uploaded_documents);
    //   for (let i = 0; i < uploaded_documents.length; i++) {
    //     const element = uploaded_documents[i];
    //     if (element.remarks === "PAN Card") {
    //       this.pan_show_image = true;
    //     }
    //     if (element.remarks === "Bank Statement") {
    //       this.bank_statements_show_image = true;
    //     }
    //     if (element.remarks === "Aadhaar Card") {
    //       this.adhaar_show_image = true;
    //     }
    //     if (element.remarks === "Income Proof") {
    //       this.pay_slips_show_image = true;
    //     }
    //   }
    // },
    onPanChangeFileUpload(index) {
      this.Pan = this.$refs.Panfile[index].files[0];
    },
    onAdhaarChangeFileUpload(index) {
      // this.Adhaar = this.$refs.Adhaarfile.files[0];
      this.Adhaar = this.$refs.Adhaarfile[index].files[0];
    },
    onBankStatementsChangeFileUpload(index) {
      // this.BankStatements = this.$refs.BankStatementsFile.files[0];
      this.BankStatements = this.$refs.BankStatementsFile[index].files[0];
    },
    onSalarySlipChangeFileUpload(index) {
      // this.SalarySlips = this.$refs.SalarySlipfile.files[0];
      this.SalarySlips = this.$refs.SalarySlipfile[index].files[0];
    },
    PanCard(item) {
      if(this.Pan != undefined){
      this.$vs.loading();
      let formData = new FormData();
      formData.append("application_id", item.application_id);
      formData.append("identity", this.studentDetails.mwb.identity);
      formData.append("mwb_id", this.studentDetails.mwb.id);
      formData.append("DocumentType", "PAN Card");
      formData.append("Files", this.Pan);
      axios
        // .post("https://liquiloans.milesforce.com/api/uploadLiveDocumentLiquiLoans", formData, {
          .post("https://liquiloans.milesforce.com/api/uploadDocumentLiquiLoansMultiple", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.$vs.loading.close();
          if (response.data.status === "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          }
          if (response.data.status === "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getUploadedDocumentData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.$vs.notify({
              text: error,
              color: "danger",
            });
        });
      }
      else{
        this.$vs.notify({
          text: "Please select Document",
          color: "danger",
        });
      }
    },
    adhaarCard(item) {
      if(this.Adhaar != undefined){
      this.$vs.loading();
      let formData = new FormData();
      formData.append("application_id", item.application_id);
      formData.append("identity", this.studentDetails.mwb.identity);
      formData.append("mwb_id", this.studentDetails.mwb.id);
      formData.append("DocumentType", "Aadhaar Card");
      formData.append("Files", this.Adhaar);
      axios
        // .post("https://liquiloans.milesforce.com/api/uploadLiveDocumentLiquiLoans", formData, {
          .post("https://liquiloans.milesforce.com/api/uploadDocumentLiquiLoansMultiple", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log(response);
          if (response.data.status === "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          }
          if (response.data.status === "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getUploadedDocumentData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.$vs.notify({
              text: error,
              color: "danger",
            });
        });
      }
      else{
        this.$vs.notify({
          text: "Plese select document",
          color: "danger",
        });
      }
    },
    bankStatements(item) {
      if(this.BankStatements != undefined){
      this.$vs.loading();
      let formData = new FormData();
      formData.append("application_id", item.application_id);
      formData.append("identity", this.studentDetails.mwb.identity);
      formData.append("mwb_id", this.studentDetails.mwb.id);
      formData.append("DocumentType", "Bank Statement");
      formData.append("Files", this.BankStatements);
      axios
        // .post("https://liquiloans.milesforce.com/api/uploadLiveDocumentLiquiLoans", formData, {
          .post("https://liquiloans.milesforce.com/api/uploadDocumentLiquiLoansMultiple", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log(response);
          if (response.data.status === "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          }
          if (response.data.status === "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getUploadedDocumentData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.$vs.notify({
              text: error,
              color: "danger",
            });
        });
      }
      else{
        this.$vs.notify({
          text: "Please upload Documents",
          color: "danger",
        });
      }
    },
    salarySlips(item) {
      if(this.SalarySlips != undefined){
      this.$vs.loading();
      let formData = new FormData();
      formData.append("application_id", item.application_id);
      formData.append("identity", this.studentDetails.mwb.identity);
      formData.append("mwb_id", this.studentDetails.mwb.id);
      formData.append("DocumentType", "Income Proof");
      formData.append("Files", this.SalarySlips);
      axios
        // .post("https://liquiloans.milesforce.com/api/uploadLiveDocumentLiquiLoans", formData, {
          .post("https://liquiloans.milesforce.com/api/uploadDocumentLiquiLoansMultiple", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log(response);
          if (response.data.status === "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          }
          if (response.data.status === "error") {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getUploadedDocumentData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
           this.$vs.notify({
              text: error,
              color: "danger",
            });
        });
      }
      else{
        this.$vs.notify({
          text: "Please Upload Document",
          color: "danger",
        });
      }
    },
  },
};
</script>

<style></style>
