<template>
  <div class="layout--main" :class="[navbarClasses, footerClasses, { 'app-page': isAppPage }]">
    <!-- <a style="display:hidden" id="goolge_meet_link" :href="google_meet_url" target="_newtab"></a> -->
    <vs-popup :id="'googleid'" style="
        color: rgb(255, 255, 255);
        margin-bottom: 0%;
        margin-left: 74%;
        height: 25%;
        margin-top: 34%;
        width: 26%;
      " background-color="rgba(255,255,255,.1)" :styleHeader="colorx" title="Google Meet Details"
      :active.sync="popupActivo5">
      <h6><b>You have a Google meet with </b></h6>
      <h5 style="margin-top: 2%">
        <strong>{{ identity }} {{ personName }} at {{ meetDate }}</strong>
        <!-- <strong>B-78702 Akshay Sharma at 28-Sep-2020 1:42:02 PM</strong> -->
      </h5>
      <p style="margin-top: 2%; color: black">
        <b>Click Here To Attend the meeting</b>
      </p>
      <a :href="google_meet_url" target="_newtab">
        <vs-button style="margin-top: 1.5%" color="primary" text-color="rgb(255, 255, 255)">Ok</vs-button>
      </a>
    </vs-popup>
    <vx-sidebar :sidebarItems="sidebarItems" :logo="require('@/assets/images/logo/logo.png')" title="Vuesax"
      parent=".layout--main" />
    <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
      <div id="content-overlay"></div>
      <div class="content-wrapper">
        <the-navbar :navbarColor="navbarColor" :class="[
          { 'text-white': isNavbarDark && !isThemeDark },
          { 'text-base': !isNavbarDark && isThemeDark },
        ]" />
        <div class="router-view">
          <div class="router-content" :class="{ 'mt-0': navbarType == 'hidden' }">
            <transition :name="routerTransition">
              <div class="router-header flex flex-wrap items-center mb-6"
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                <div class="content-area__heading" :class="{
                  'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light':
                    $route.meta.breadcrumb,
                }">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" />
                <vs-dropdown class="ml-auto md:block hidden cursor-pointer" vs-trigger-click>
                  <vs-button radius icon="icon-settings" icon-pack="feather"></vs-button>

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <router-link class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </router-link>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <router-link class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </router-link>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <router-link class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </router-link>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </transition>
            <div class="content-area__content">
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <EscalatePopup />
      <EnrollmentPopup />
      <Emailleads />
      <AttendanceFillingForm />
      <warehouseReportPopup />
      <AccountPopup />
      <PoaEscalation />
      <PreviewPopup />
      <AddEngagementWhatsAppForm />
      <add-engagement-email-form />
      <studentlistpopup />
      <AttendancePopupForm />
      <rating-popup />

      <!-- <add-student-cma-referrance /> -->
      <fees-books-popup />
      <badge-details />
      <exam-edit-popup />
      <fees-edit-popup />
      <untracked-popup />
      <missed-call-popup />
      <add-engagement-popup />
      <add-email-popup />
      <email-options-popup />
      <add-mobile-popup />
      <mobile-options-popup />
      <student-details-popup />
      <student-report-popup />
      <AddLeadCRPopup />
      <AddLeadIRPopup />
      <StudentReportCmaPopup />
      <approvalPopup />
      <DelaysSubTeamPopup />
      <PurchaseApprovalPopup />
      <CallAudioPopup />
      <UtilizationEWBpopup />
      <UtilizationLongDurationpopup />
      <UtilizationMissedCallpopup />
      <UtilizationMWBpopup />
      <UtilizationNUCpopup />
      <UtilizationUntrackedpopup />
      <NetEnquirySearchPopup />
      <AssignSpocPopup />
      <AssignSpocPopupMultiple />
      <AssignIIMLSpocPopupMultiple />
      <AssignSRSpocPopupMultiple />
      <AssignSpocPopupCount />
      <AssignSRSpocPopupCount />
      <AssignIIMLSpocPopupCount />
      <ReceiptforEnrollmentpopup />
      <CoverageDetails />
      <PathwayByLevelsPopup />
      <addMrNumber />
      <MapthisEnquiryPopup />
      <AddReceiptPopup />
      <enrollmentApproval />
      <UspReEnrollmentApprovalPopup />
      <RefundApprovalPopup />
      <warehouseAccountPopup />
      <NetEnquiryIrrelevantPopup />
      <DashboardNetEnquiriesPopup />
      <AddEnrollment />
      <resend-email />
      <CpaEmail />
      <EmailCma />
      <UtilizationEnrollment />
      <IMMLEnrollmentPopup />
      <!-- BIG POPUP COMPONENTS -->
      <AddCmaStudentReference />
      <AddStudentReferrance />
      <BigPopup />
      <ChangeBatchCodePopup />
      <AdjustReceiptPopup />
      <add-lead-popup />
      <couriernumberPopup />
      <TimeTrackingNotification />
      <LoanCheckPopup />
      <EditIimlEnrollmentPopup />
      <ImagePopup />
      <Showattendance />
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import BigPopup from "../../components/BigPopup/BigPopup.vue";
import AdjustReceiptPopup from "../../components/pagesComponents/AdjustReceipt";
import MapthisEnquiryPopup from "../../components/pagesComponents/MapthisEnquiryPopup.vue";
import AddStudentReferrance from "../../components/pagesComponents/AddStudentReferrance";
import AddCmaStudentReference from "../../components/pagesComponents/AddCmaStudentReference.vue";
import VxSidebar from "@/layouts/components/vx-sidebar/VxSidebar.vue";
import TheNavbar from "../components/TheNavbar.vue";
import TheFooter from "../components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js";
import StudentDetailsPopup from "../../components/pagesComponents/StudentDetailsPopup.vue";
import AddEngagementPopup from "../../components/pagesComponents/AddEngagementPopup.vue";
import AddEmailPopup from "../../components/pagesComponents/AddEmailPopup.vue";
import EmailOptionsPopup from "../../components/pagesComponents/EmailOptionsPopup.vue";
import AddMobilePopup from "../../components/pagesComponents/AddMobilePopup.vue";
import MobileOptionsPopup from "../../components/pagesComponents/MobileOptionsPopup.vue";
import MissedCallPopup from "../../components/pagesComponents/MissedCallPopup.vue";
import UntrackedPopup from "../../components/pagesComponents/UntrackedPopup.vue";
import DashboardNetEnquiriesPopup from "../../components/pagesComponents/DashboardNetEnquiriesPopup.vue";
import FeesEditPopup from "../../components/pagesComponents/FeesEditPopup.vue";
import AddLeadPopup from "../../components/pagesComponents/AddLeadPopup.vue";
import StudentReportPopup from "../../components/EWB/CPA/Candidates/Details_Popup/StudentReportPopup.vue";
import ExamEditPopup from "../../components/pagesComponents/ExamEdit.vue";
import BadgeDetails from "../../components/pagesComponents/BadgeDetails.vue";
// import AddStudentReferrance from "../../components/pagesComponents/AddStudentReferrance.vue";
import FeesBooksPopup from "../../components/pagesComponents/FeesBooksPopup.vue";
// import POAEdit from "../../components/pagesComponents/POAEdit.vue";
// import CMAPOAEdit from "../../components/pagesComponents/CMAPOAEdit.vue";
import RatingPopup from "../../components/pagesComponents/RatingPopup.vue";
import AttendancePopupForm from "../../components/pagesComponents/AttendancePopupForm.vue";
import studentlistpopup from "../../components/pagesComponents/student_list_popup.vue";
import AddEngagementEmailForm from "../../components/pagesComponents/AddEngagementEmailform.vue";
import AddEngagementWhatsAppForm from "../../components/pagesComponents/AddEgagementWhatsAppForm.vue";
import PreviewPopup from "../../components/pagesComponents/PreviewPopup.vue";
import EnrollmentPopup from "../../components/pagesComponents/EnrollmentPopup.vue";
import EscalatePopup from "../../components/pagesComponents/EscalatePopup.vue";
import PoaEscalation from "../../components/pagesComponents/PoaEscalation.vue";
import AccountPopup from "../../components/pagesComponents/AccountPopup.vue";
import AddLeadCRPopup from "../../components/pagesComponents/AddLeadCRPopup.vue";
import AddLeadIRPopup from "../../components/pagesComponents/AddLeadIRPopup.vue";
import warehouseReportPopup from "../../components/pagesComponents/warehouseReportPopup.vue";
import AttendanceFillingForm from "../../components/pagesComponents/AttendanceFillingForm.vue";
import StudentReportCmaPopup from "../../components/pagesComponents/StudentReportCmaPopup.vue";
import Emailleads from "../../components/pagesComponents/Emailleads.vue";
import EventBus from "../../components/eventbus.js";
import constants from "../../../constants.json";
import axios from "axios";
import VueSocketio from "vue-socket.io";
import SocketIO from "socket.io-client";
import approvalPopup from "../../components/pagesComponents/approvalPopup.vue";
import PurchaseApprovalPopup from "../../components/pagesComponents/PurchaseApprovalPopup.vue";
import CallAudioPopup from "../../components/pagesComponents/CallAudioPopup.vue";
import AssignSpocPopup from "../../components/pagesComponents/AssignSpocPopup.vue";
import AssignSpocPopupMultiple from "../../components/pagesComponents/AssignSpocPopupMultiple.vue";
import AssignSRSpocPopupMultiple from "../../components/pagesComponents/AssignSRSpocPopupMultiple.vue";
import AssignIIMLSpocPopupMultiple from "../../components/pagesComponents/AssignIIMLSpocPopupMultiple.vue";
import AssignSpocPopupCount from "../../components/pagesComponents/AssignSpocPopupCount.vue";
import AssignIIMLSpocPopupCount from "../../components/pagesComponents/AssignIIMLSpocPopupCount.vue";
import AssignSRSpocPopupCount from "../../components/pagesComponents/AssignSRSpocPopupCount.vue";
import UtilizationEWBpopup from "../../components/pagesComponents/UtilizationEWBpopup.vue";
import UtilizationLongDurationpopup from "../../components/pagesComponents/UtilizationLongDurationpopup.vue";
import UtilizationMissedCallpopup from "../../components/pagesComponents/UtilizationMissedCallpopup.vue";
import UtilizationMWBpopup from "../../components/pagesComponents/UtilizationMWBpopup.vue";
import UtilizationNUCpopup from "../../components/pagesComponents/UtilizationNUCpopup.vue";
import UtilizationUntrackedpopup from "../../components/pagesComponents/UtilizationUntrackedpopup.vue";
import UtilizationEnrollment from "../../components/pagesComponents/UtilizationEnrollments.vue";
import NetEnquirySearchPopup from "../../components/pagesComponents/NetEnquirySearchPopup.vue";
import CoverageDetails from "../../components/pagesComponents/CoverageDetailedPopup.vue";
import PathwayByLevelsPopup from '../../components/pagesComponents/PathwayByLevelsPopup.vue';
import DelaysSubTeamPopup from "../../components/pagesComponents/DelaysSubTeamPopup.vue";
import enrollmentApproval from "../../components/pagesComponents/EnrollmentApprovalPopup.vue";
import UspReEnrollmentApprovalPopup from "../../components/pagesComponents/UspReEnrollmentApprovalPopup.vue";
import AddReceiptPopup from "../../components/pagesComponents/AddReceiptpopup.vue";
import ReceiptforEnrollmentpopup from "../../components/pagesComponents/ReceiptForEnrollmentpopup.vue";
import addMrNumber from "../../components/pagesComponents/addMrNumber.vue";
import NetEnquiryIrrelevantPopup from "../../components/pagesComponents/NetEnquiryIrrelevantPopup.vue";
import warehouseAccountPopup from "../../components/pagesComponents/warehouseAccountPopup.vue";
import AddEnrollment from "../../components/pagesComponents/AddReceipt.vue";
import ResendEmail from "../../components/pagesComponents/ResendEmail.vue";
import CpaEmail from "../../components/pagesComponents/ResendEmailCpa.vue";
import EmailCma from "../../components/pagesComponents/ResendEmailCma.vue";
import ChangeBatchCodePopup from "../../components/pagesComponents/ChangeBatchCodePopup.vue";
import TimeTrackingNotification from "../../components/pagesComponents/TimeTrackingNotification.vue";
import couriernumberPopup from "../../components/pagesComponents/WarehouseCourierNumber.vue";
import IMMLEnrollmentPopup from "../../components/pagesComponents/IIMLEnrollmentPopup.vue";
import LoanCheckPopup from "../../components/pagesComponents/LoanCheckPopup.vue";
import EditIimlEnrollmentPopup from "../../components/pagesComponents/EditIimlEnrollmentPopup.vue";
import ImagePopup from "../../components/pagesComponents/ImagePopup.vue";
import Showattendance from "../../components/pagesComponents/Showattendance.vue";
import router from "@/router";
import moment from "moment";
import Vue from "vue";
// import moment from "moment";

import "izitoast/dist/css/iziToast.css"
import iziToast from "izitoast/dist/js/iziToast.min.js"
import RefundApprovalPopup from "../../components/pagesComponents/RefundApprovalPopup.vue";
Vue.use(
  new VueSocketio({
    debug: true,
    connection: SocketIO("https://hr.milesforce.com"), //options object is Optional
  })
);

export default {
  data() {
    return {
      call_log_update_count: 0,
      colorx: "#5b3cc4",
      popupActivo5: false,
      identity: "",
      personName: "",
      meetDate: "",
      google_meet_url: "",
      queue: false,
      SpocID: "",
      navbarType: themeConfig.navbarType || "floating",
      navbarColor: themeConfig.navbarColor || "#fff",
      footerType: themeConfig.footerType || "static",
      routerTransition: themeConfig.routerTransition || "none",
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      sidebarItems: sidebarItems,
      disableCustomizer: themeConfig.disableCustomizer,
      windowWidth: window.innerWidth, //width of windows
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableThemeTour: themeConfig.disableThemeTour,
      steps: [
        {
          target: "#btnSidebarToggler",
          content: "Toggle Collapse Sidebar.",
        },
        {
          target: ".vx-navbar__starred-pages",
          content:
            "Create your own bookmarks. You can also re-arrange them using drag & drop.",
        },
        {
          target: ".i18n-locale",
          content: "You can change language from here.",
        },
        {
          target: ".navbar-fuzzy-search",
          content: "Try fuzzy search to visit pages in flash.",
        },
        {
          target: ".customizer-btn",
          content: "Customize template based your preference",
          params: {
            placement: "left",
          },
        },
      ],
      fixednotification: true,
      iconClose: "close"
    };
  },
  mounted() {
    // this.popupActivo5 = true;
    // EventBus.$emit("open-big-popup", candidate_data);
    // EventBus.$on("open-big-popup", (candidate_details) => {
    //   console.log("candidate details", candidate_details);
    // });
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "lnptap59wm");

    document.addEventListener("click", this.onDocumentClick);
    // let role = localStorage.getItem('role')
    // if(role == 'cm1' || role == 'exed2' || role == 'ac1'){
     this.executeUtilizationLoop();
    // }
    EventBus.$on("queue-disable", (value) => {
      this.queue = value;
    });
    EventBus.$on("change-navbar-config", (value) => {
      this.updateNavbar(value);
    });
    document.getElementById(
      "googleid"
    ).children[1].children[0].style.backgroundColor = "#5b3cc4";
    document.getElementById(
      "googleid"
    ).children[1].children[0].children[0].children[0].style.color = "white";
    // document.getElementById("googleid").children[1].children[0].children[1].style.backgroundColor = "red";
    setInterval(() => {
      if (this.call_log_update_count > 1) {
        EventBus.$emit("update-dashboard");
        this.call_log_update_count = 0;
      }
    }, 3000);
    this.getScheduledCallDetails();
    setInterval(() => {
         console.log("calling getScheduledCallDetails")
            this.getScheduledCallDetails();
    }, 300000);
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
      // this.checkPermission();
    },
    isThemeDark(val) {
      if (this.navbarColor == "#fff" && val) {
        this.updateNavbarColor("#10163a");
      } else {
        this.updateNavbarColor("#fff");
      }
    },
  },
  computed: {
    isAppPage() {
      if (this.$route.path.includes("/apps/")) return true;
      else return false;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.sidebarWidth == "default") return "content-area-default";
      else if (this.sidebarWidth == "reduced") return "content-area-reduced";
      else if (this.sidebarWidth) return "content-area-full";
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },
  },
  sockets: {
    connect() {
      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "exed2" ||
        localStorage.getItem("role") === "cm4"
      ) {
        // this.getSpocId();
        let header = "Bearer " + localStorage.userAccessToken;
        axios
          .get(`${constants.SERVER_API}giveMeMyId`, {
            headers: { Authorization: header },
          })
          .then((response) => {
            this.SpocId = response.data.user_id;
            this.$socket.emit("register", response.data.user_id);
            localStorage.setItem("user_id",response.data.user_id)
            console.log(`REGISTERING WITH SPOC ID - ${response.data.user_id}`);
            this.sockets.subscribe("call_event", async (data) => {
              // console.log("this will runn");
              if (this.queue === false) {
                console.log("This is the data");
                console.log(data);
                if (data !== null) {
                  this.openBigPopup(data.id);
                }
                // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${data.can_id}&person_id=${data.person_id}`;
                // let getReq = await axios.get(url, {
                //   headers: {
                //     Authorization: `Bearer ${localStorage.userAccessToken}`
                //   }
                // });
                // data.engagements = getReq.data.engagements;
                // data.mobile = getReq.data.mobile;
                // data.emails = getReq.data.emails;
                // EventBus.$emit("open-student-details-popup", data);
                // EventBus.$on("open-student-details-popup-call", () => {
                //   this.navSettings("Call");
                // });
              }
            });
            this.sockets.subscribe("call_log_for_user", () => {
              this.call_log_update_count++;
              /* console.log('Increased call_log_update_count', this.call_log_update_count);
              if (this.call_log_update_count++ < 4) {
                EventBus.$emit("update-dashboard");
              } */
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    customEmit: function (data) {
      console.log("custom event");
      console.log(data);
    },
  },
  methods: {
    // async getSpocId() {
    //   let header = "Bearer " + localStorage.userAccessToken;
    //   axios
    //     .get(`${constants.SERVER_API}giveMeMyId`, {
    //       headers: { Authorization: header }
    //     })
    //     .then(response => {
    //       // console.log("response get spoc id", response);
    //       this.SpocId = response.data.user_id;
    //     })
    //     .catch(error => {
    //       // console.log(error)
    //       // if (error.response) {
    //       //   // console.log("error", error.response.status);
    //       //   if (error.response.status === 401) {
    //       //     this.ReDirect_to_login();
    //       //   }
    //       // }
    //       this.handleError(error);
    //     });
    // }, */
    // onDocumentClick() {
    //    console.log("testing");
    //     this.fixednotification = false;
    // },
    getScheduledCallDetails() {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}getScheduledCallDetails`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("google meet", response);
          if (response.data.status == "success") {
            this.displayNotification(response.data.meet_details);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    displayNotification(meet_details) {
      console.log("meet details",meet_details)
      let meet_time = moment.unix(meet_details.scheduled_date).format("HH:mm A");
      if (meet_details.call_type == 'video') {
        iziToast.show({
          title: `You have a meeting with ${meet_details.candidate_name} at ${meet_time}`,
          message: `Click here to attend the meeting`,
          close: true,
          backgroundColor: '#7367F0',
          timeout: 0,
          theme: 'dark',
          layout: 2,
          maxWidth: "400px",
          buttons: [
            ['<button>Join Meeting</button>', function (instance, toast) {
              window.open(`${meet_details.meet_link}`, "_newtab")
              instance.hide({
                transitionOut: 'fadeOutUp',
              }, toast, 'buttonName');
            }, true],
          ],
        });

      } else {
        iziToast.show({
          title: `You have a meeting with ${meet_details.candidate_name} at ${meet_time}`,
          message: 'Click here to view candidate details.',
          close: true,
          backgroundColor: '#7367F0',
          timeout: 0,
          theme: 'dark',
          layout: 2,
          maxWidth: "400px",
          buttons: [
            ['<button>View Candidate Details</button>', (instance, toast) => {
              this.openBigPopup(meet_details.mwb_id)
              instance.hide({
                transitionOut: 'fadeOutUp',
              }, toast, 'buttonName');
            }, true], ['<button>Call</button>', (instance, toast) => {
              this.call(meet_details)
              instance.hide({
                transitionOut: 'fadeOutUp',
              }, toast, 'buttonName');
            }, true]
          ],
        });
      }
    },
    async call(meet_details) {
      this.$vs.loading({
        container: "#click-to-call",
        scale: 0.45,
      });
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error",
            });
            this.$vs.loading.close("#click-to-call > .con-vs-loading");
            return;
          }
        }
      }
      axios
        .get(
          `${constants.SERVER_API}clickToCallPusher?mobile_id=${meet_details.mobile_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$vs.loading.close("#click-to-call > .con-vs-loading");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // googleMeetInterval() {
    //   let obj = {
    //     current_time: moment().unix(),
    //     // current_time : 1600984080
    //   };
    //   axios
    //     .get(`${constants.MILES_CM_BACK}googleEventsAfterFiveMinutes`, {
    //       params: obj,
    //       headers: {
    //         Authorization: `Bearer ${localStorage.userAccessToken}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("google meet", response);
    //       // let responsedata = response;
    //       this.google_meet_url = response.data.event.hangouts_link;
    //       this.identity = response.data.event.identity;
    //       this.personName = response.data.event.person_name;
    //       this.meetDate = moment
    //         .unix(response.data.event.start_time)
    //         .format("DD-MMM-YYYY h:mm:ss a");
    //       if (response.data.event_found === "yes") {
    //         this.popupActivo5 = true;
    //         // this.$vs.notify({
    //         //   title: 'You have a meeting with '+response.data.event.identity+'&nbsp;'+response.data.event.person_name+' at '+ moment.unix(response.data.event.start_time).format("DD-MMM-YYYY h:mm:ss a"),
    //         //   text: 'Click Here To Attend the meeting: '+response.data.event.hangouts_link,
    //         //   color: 'primary',
    //         //   position: 'top-right',
    //         //   fixed: this.fixednotification,
    //         //   icon: this.iconClose,
    //         //   // click:()=>{
    //         //   //     window.open(this.google_meet_url, "_newtab")
    //         //   // },
    //         //   // iconClose:()=>{

    //         //   // },
    //         // })

    //         // this.getMwbnames(responsedata);
    //       }
    //       setTimeout(() => {
    //         this.googleMeetInterval();
    //       }, 300000);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },

    // getMwbnames(responsedata) {
    //   let url = `${constants.SERVER_API}getCandidateDetails?mwb_id=${responsedata.data.event.mwb_id}`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
    //     })
    //     .then(response => {
    //       console.log(response);
    //       this.mwb_name = response.data.data.mwb.person_name;
    //       // this.$store.commit("MOUNT_SPOC_DATA", response.data);
    //     })
    //     .catch(error => {
    //       this.handleError(error);
    //     });
    // },
    checkPermission() {
      axios
        .get(`${constants.SERVER_API}isQueueRelaxed`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // response.data.relax_queue_status = 'yes';
          console.log(response, "Checking for permission");
          if (localStorage.getItem("team") === "CM" || (localStorage.getItem("team") === "SR" && localStorage.getItem("sub_team") === "SR")) {
            console.log(this.$route.path);
            if (
              this.$route.path !== "/queue" &&
              this.$route.path !== "/logout" &&
              this.$route.path !== "/UtilizationReport" &&
              this.$route.path !== "/receipts"
            ) {
              console.log(response);
              if (response.data.relax_queue_status === "no") {
                this.$vs.notify({
                  title: "Permission denied",
                  text: "Please contact admin!!!",
                  color: "danger",
                  icon: "error",
                });
                router.push("/queue");
              }
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    executeUtilizationLoop() {
      if (localStorage.getItem("userAccessToken") !== "") {
        //version number changing to next level with latest change by chaitanya//by changes commit hdue

        const version = "20.65.8"

        axios
          .get(`${constants.SERVER_API}getSpocUtilization?version=${version}`, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            if (response.data === "incorrect_version") {
              alert(
                "You are using an older version of MilesForce. Please Download the correct version by pressing the following keys together: Control + Shift + r"
              );
            }
            EventBus.$emit("update-navbar-effort", response.data, version);
            // if (localStorage.getItem("role") !== "cm4") {
            //   if (
            //     this.$route.path !== "/queue" &&
            //     this.$route.path !== "/logout" &&
            //     this.$route.path !== "/UtilizationReport" &&
            //     this.$route.path !== "/receipts"
            //   ) {
            //     if (response.data.relax_queue_status === "no") {
            //       this.$vs.notify({
            //         title: "Permission denied",
            //         text: "Please contact admin!!!",
            //         color: "danger",
            //         icon: "error"
            //       });
            //       router.push("/queue");
            //     }
            //   }
            // }
            setTimeout(() => {
              this.executeUtilizationLoop();
            }, 300000);
            // this.checkQueueValidity(response.data.user)
            // setTimeout(() => {
            //   this.executeUtilizationLoop();
            // }, 300000);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    routeFunction(url) {
      this.$router.push(url);
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
        this.$store.dispatch("updateSidebarWidth", "no-sidebar");
        this.disableThemeTour = true;
      } else if (this.windowWidth < 1200) {
        this.$store.dispatch("updateSidebarWidth", "reduced");
      } else {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
  components: {
    DelaysSubTeamPopup,
    TimeTrackingNotification,
    BigPopup,
    ChangeBatchCodePopup,
    AddStudentReferrance,
    AddCmaStudentReference,
    VxSidebar,
    TheNavbar,
    TheFooter,
    StudentDetailsPopup,
    MobileOptionsPopup,
    AddMobilePopup,
    EmailOptionsPopup,
    AddEmailPopup,
    AddEngagementPopup,
    MapthisEnquiryPopup,
    MissedCallPopup,
    UntrackedPopup,
    FeesEditPopup,
    AddLeadPopup,
    StudentReportPopup,
    UtilizationEnrollment,
    ExamEditPopup,
    BadgeDetails,
    // AddStudentReferrance,
    FeesBooksPopup,
    // POAEdit,
    // CMAPOAEdit,
    RatingPopup,
    AttendancePopupForm,
    studentlistpopup,
    AddEngagementEmailForm,
    AddEngagementWhatsAppForm,
    PreviewPopup,
    EscalatePopup,
    PoaEscalation,
    AccountPopup,
    AddLeadCRPopup,
    AddLeadIRPopup,
    warehouseReportPopup,
    AttendanceFillingForm,
    StudentReportCmaPopup,
    Emailleads,
    approvalPopup,
    PurchaseApprovalPopup,
    EnrollmentPopup,
    CallAudioPopup,
    AssignSpocPopup,
    AssignSpocPopupMultiple,
    AssignIIMLSpocPopupMultiple,
    AssignSRSpocPopupMultiple,
    AssignSpocPopupCount,
    AssignSRSpocPopupCount,
    AssignIIMLSpocPopupCount,
    UtilizationEWBpopup,
    UtilizationLongDurationpopup,
    UtilizationMissedCallpopup,
    UtilizationMWBpopup,
    UtilizationNUCpopup,
    UtilizationUntrackedpopup,
    NetEnquirySearchPopup,
    enrollmentApproval,
    UspReEnrollmentApprovalPopup,
    RefundApprovalPopup,
    CoverageDetails,
    PathwayByLevelsPopup,
    AddReceiptPopup,
    addMrNumber,
    NetEnquiryIrrelevantPopup,
    ReceiptforEnrollmentpopup,
    warehouseAccountPopup,
    AddEnrollment,
    DashboardNetEnquiriesPopup,
    ResendEmail,
    EmailCma,
    CpaEmail,
    AdjustReceiptPopup,
    couriernumberPopup,
    IMMLEnrollmentPopup,
    LoanCheckPopup,
    EditIimlEnrollmentPopup,
    ImagePopup,
    Showattendance,
  },
  created() {
    this.setSidebarWidth();
    if (this.navbarColor == "#fff" && this.isThemeDark) {
      this.updateNavbarColor("#10163a");
    } else {
      this.updateNavbarColor(this.navbarColor);
    }
  },
};
</script>
<style>
/* .vs-popup--title h3{
    color:white
} */
</style>
