<template>
  <vs-popup title="Edit" :active.sync="open">
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
        <b style="color: gray">NAME:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        {{this.payload.name}}
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
        <b style="color: gray">RECEIVED:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        {{alreadyReceived}}
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
        <b style="color: gray">AMOUNT RECEIVED NOW:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-input class="inputx" placeholder="Enter Amount" v-model="receivedNow" />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="5">
        <b style="color: gray">REFFERENCE:</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-textarea />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button  color="dark" type="gradient" @click="open = false">Save & update</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
  mounted() {
    EventBus.$on('open-fees-edit-popup', (payload) => {
      this.alreadyReceived = payload.Received 
      this.payload = payload
      this.open = true
    })
  },
  data() {
    return {
      alreadyReceived: '',
      payload: '',
      open: false,
      receivedNow: '',
    }
  },
  watch: {
    // receivedNow: function(val) {
    //   console.log(val)
    //   let rupeeSymbol = this.alreadyReceived.charAt(0)
    //   let number = parseFloat(this.alreadyReceived.substr(1).replace(/,/g, ""))
    //   console.log(number)
    //   if (Number.isInteger(parseInt(val))) {
    //     // console.log('it is a nunmber')
    //     this.alreadyReceived = rupeeSymbol + (number + parseInt(val))
    //   }
    // }
  }
}
</script>
<style>
  .gap {
    margin-bottom: 3% 
  }
</style>