<template>
  <div>
    <!-- <vs-dialog></vs-dialog> -->
    <vs-popup class="holamundo" title="SPAM" :active.sync="open" style="position:fixed;z-index:80010">
      <p>
        This number is marked as spam. Would you like to remove it from spam?
      </p>
      <vs-row style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>{{ this.payload }}</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="danger" type="filled" @click="removeFromSpam()"
            >Remove from SPAM</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      open: false,
      payload: "",
    };
  },
  mounted() {
    eventbus.$on("open-spam-popup", (payload) => {
      //   this.openDialog(payload);
      this.payload = payload;
      this.open = true;
    });
  },
  methods: {
    removeFromSpam() {
      let url = `${constants.SERVER_API}removeFromSpamVendor`;
      let obj = {
        phone_number: this.getLastTenDigits(this.payload.toString()),
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.open = false;
          }
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
