<template>
    <div>
        <vs-row style="margin-bottom: 3%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                <vs-card>
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            <p>
                                <strong>PREVIOUS ENGAGEMENT -</strong>
                                {{ payload.mwb.engagement_details }}
                            </p>
                        </vs-col>
                    </vs-row>
                </vs-card>
            </vs-col>
        </vs-row>
        <vs-row style="margin-bottom: 3%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" style="font-size: 15px">
                <h6 style="color: gray">Connection Status:</h6>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4" style="font-size: 15px">
                <vs-select class="w-full" label="status" v-model="select1">
                    <vs-select-item :key="index" :value="item.text" :text="item.text"
                        v-for="(item, index) in options1" />
                </vs-select>
            </vs-col>
        </vs-row>

        <div v-if="personna_data == true && connected_status.includes(select1)" class="mb-5">
            <personna-details :persona_details="persona_details" :mwb_id="payload.mwb.id"></personna-details>
        </div>
        <div v-if="!connected_status.includes(select1) && personna_data == true" class="mb-5">
            <personna-hide-details :persona_details="persona_details"></personna-hide-details>
        </div>
        <div v-if="show === true">
            <vs-divider class="my-6"></vs-divider>
            <div v-if="connected === true">
                <vs-row style="margin-bottom: 3%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4"
                        style="font-size: 15px">
                        <h6 style="color: gray">Response:</h6>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="flex-start" vs-w="4">
                        <vs-radio color="dark" v-model="responseSelection" vs-value="sameResponse">
                            <p style="font-size: 12px">SAME RESPONSE</p>
                        </vs-radio>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                        <vs-radio color="dark" id="new" v-model="responseSelection" vs-value="new">
                            <p style="font-size: 12px">NEW</p>
                        </vs-radio>
                    </vs-col>
                </vs-row>
            </div>

            <vs-row style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray">Engagement:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                    <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                </vs-col>
            </vs-row>
            <vs-divider class="my-6"></vs-divider>
            <vs-row style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color: gray">Escalation:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <vs-checkbox color="dark" v-model="escalation_boolean"></vs-checkbox>
                    <vs-select :disabled="!escalation_boolean" placeholder style="margin-top: 4%; margin: 10px"
                        class="w-full" v-model="selected_team">
                        <vs-select-item value text />
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in teams" />
                    </vs-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <vs-select :disabled="!escalation_boolean" placeholder style="margin-top: 4%; margin: 10px"
                        class="w-full" v-model="selected_escalation_member">
                        <vs-select-item value text />
                        <vs-select-item :key="index" :value="item.id" :text="item.full_name"
                            v-for="(item, index) in visible_members" />
                    </vs-select>
                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%" v-if="escalation_boolean">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                    <vs-textarea label="Comments" v-model="escalation_comments" />
                    <!-- <h6 style="color: gray;">Escalation:</h6> -->
                    <!-- <vs-checkbox color="dark" v-model="escalation_boolean"></vs-checkbox> -->
                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%" v-if="escalation_boolean">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
                    <vs-button @click="escalateThisLead()" color="danger" type="border">Escalate</vs-button>
                    <!-- <h6 style="color: gray;">Escalation:</h6> -->
                    <!-- <vs-checkbox color="dark" v-model="escalation_boolean"></vs-checkbox> -->
                </vs-col>
            </vs-row>
            <vs-divider class="my-6"></vs-divider>
            <vs-row style="margin-bottom: 2%" >
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color: gray">Level:</h6>
                </vs-col>
                <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <div v-if="connected === true">
                        <vs-select autocomplete class="w-full" label="Levels" v-model="levels" :disabled="!hide_select">
                            <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item, index) in labelOptions" />
                        </vs-select>
                    </div>
                    <div v-else>
                        <vs-select disabled class="w-full" label="Levels" v-model="levels">
                            <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item, index) in labelOptions" />
                        </vs-select>
                    </div>
                </vs-col>


            </vs-row>
            <vs-row style="margin-bottom: 2%" v-if="!hide_select">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color: gray">Exed Level:</h6>
                </vs-col>
                <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <vs-select class="w-full" label="Exed Levels" v-model="exed_level">

                        <vs-select-item value text />
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in exed_levels" />
                    </vs-select>

                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%" v-if="false">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color: gray">RPO Levels:</h6>
                </vs-col>
                <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <div v-if="iimlConnected || exceptionOption">
                        <vs-select style="width: 100%" autocomplete class="w-full" label="RPO Levels"
                            v-model="iimlLevel">
                            <vs-select-item value text />
                            <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item, index) in iimlLevels" />
                        </vs-select>
                    </div>
                    <div v-else>
                        <vs-select autocomplete class="w-full" label="RPO Levels" v-model="iimlLevel" disabled>
                            <vs-select-item value text />
                            <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item, index) in iimlLevels" />
                        </vs-select>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 2%" v-if="hide_select">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color: gray">USP Levels:</h6>
                </vs-col>
                <vs-col vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <!-- <vs-select class="w-full" label="MAcc Levels" v-model="selected_macc_level" >
                        <vs-select-item value text />
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in macc_levels" />
                    </vs-select> -->
                    <v-select autocomplete class=" invc-input" v-model="selected_macc_level"
                style="border: 1px solid #0044ba; border-radius: 5px; width:375px;" :options="macc_levels"
                :reduce="(option) => option.id">
              </v-select>
                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray">Course:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') || checkIfRoleis('exed2')
                    " :disabled="disable" color="dark" v-model="course" vs-value="CPA" style="margin-right: 1%">CPA
                    </vs-checkbox>
                    <!-- <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CIA"
                        style="margin-right: 1%"
                        >CIA</vs-checkbox
                    > -->
                    <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CPA-AA"
                        style="margin-right: 1%"
                        >CPA-AA</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4') || checkIfRoleis('exed2')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CMA"
                        style="margin-right: 1%"
                        >CMA</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4') || checkIfRoleis('exed2')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CFA"
                        style="margin-right: 1%"
                        >CFA</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4') || checkIfRoleis('exed2')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="FRM"
                        style="margin-right: 1%"
                        >FRM</vs-checkbox
                    >
                    <!-- <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4') || checkIfRoleis('exed2')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIMI-YLP"
                        style="margin-right: 1%"
                        >IIMI-YLP</vs-checkbox
                    > -->
                    <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4') || checkIfRoleis('exed2')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="USP"
                        style="margin-right: 1%"
                        >USP</vs-checkbox
                    >
                    <!-- <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CISA"
                        style="margin-right: 1%"
                        >CISA</vs-checkbox
                    > -->
                    <!-- <vs-checkbox
                        v-if="
                            checkIfRoleis('cm1') ||
                            checkIfRoleis('ac1') ||
                            checkIfRoleis('cm4')
                        "
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="EA"
                        style="margin-right: 1%"
                        >EA</vs-checkbox
                    > -->
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') ||
                        checkIfRoleis('exed')
                    " :disabled="disable" color="dark" v-model="course" vs-value="IIML-FA"
                        style="margin-right: 1%">IIML-FA</vs-checkbox>
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') ||
                        checkIfRoleis('exed')
                    " :disabled="disable" color="dark" v-model="course" vs-value="IIML-SF"
                        style="margin-right: 1%">IIML-SF</vs-checkbox>
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') ||
                        checkIfRoleis('exed')
                    " :disabled="disable" color="dark" v-model="course" vs-value="IIML-FT"
                        style="margin-right: 1%">IIML-FT</vs-checkbox>
                </vs-col>
            </vs-row>
            <vs-row style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') || checkIfRoleis('exed2')
                    " :disabled="disable" color="dark" v-model="course" vs-value="AICPA" style="margin-right: 1%">AICPA
                    </vs-checkbox>
                    <vs-checkbox v-if="
                        checkIfRoleis('cm1') ||
                        checkIfRoleis('ac1') ||
                        checkIfRoleis('cm4') || checkIfRoleis('exed2')
                    " :disabled="disable" color="dark" v-model="course" vs-value="CPA-US" style="margin-right: 1%">CPA-US
                    </vs-checkbox>
                </vs-col>
            </vs-row>
            <vs-row v-if="show_cfa_dropdown === true" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray">Select CFA Course levels:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <vs-select class="w-full" label="" style="width: 100%" v-model="selected_cfa_level">
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in cfa_levels" />
                    </vs-select>
                </vs-col>
            </vs-row>
            <vs-row v-if="show_frm_dropdown === true" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray">Select FRM Course parts:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                    <vs-select class="w-full" label="" style="width: 100%" v-model="selected_frm_parts">
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in frm_parts" />
                    </vs-select>
                </vs-col>
            </vs-row>
            <!-- <vs-row style="margin-bottom: 3%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    vs-w="2"
                >
                    <h6 style="color: gray"></h6>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    vs-w="10"
                >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-BA"
                        style="margin-right: 1%"
                        >IIML-BA</vs-checkbox
                    >

                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-PM"
                        style="margin-right: 1%"
                        >IIML-PM</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-HR"
                        style="margin-right: 1%"
                        >IIML-HR</vs-checkbox
                    >

                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITR-BF"
                        style="margin-right: 1%"
                        >IITR-BF</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITR-DB"
                        style="margin-right: 1%"
                        >IITR-DB</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITM-AA"
                        style="margin-right: 1%"
                        >IITM-AA</vs-checkbox
                    >
                </vs-col>
            </vs-row> -->
            <!-- <vs-row style="margin-bottom: 3%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    vs-w="2"
                >
                    <h6 style="color: gray"></h6>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    vs-w="10"
                >

                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIMK-CX"
                        style="margin-right: 1%"
                        >IIMK-CX</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITM-FS"
                        style="margin-right: 1%"
                        >IITM-FS</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITR-CC"
                        style="margin-right: 1%"
                        >IITR-CC</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIMK-FT"
                        style="margin-right: 1%"
                        >IIMK-FT</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-AB"
                        style="margin-right: 1%"
                        >IIML-AB</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-SH"
                        style="margin-right: 1%"
                        >IIML-SH</vs-checkbox
                    >
                </vs-col>
            </vs-row> -->
            <vs-row style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray"></h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">

                    <!-- <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IITJ-DE"
                        style="margin-right: 1%"
                        >IITJ-DE</vs-checkbox
                    > -->
                     <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="CPA-AA"
                        style="margin-right: 1%"
                        >CPA-AA</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="XLRI-HR"
                        style="margin-right: 1%"
                        >XLRI-HR</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="XLRI-SH"
                        style="margin-right: 1%"
                        >XLRI-SH</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIMI-BA"
                        style="margin-right: 1%"
                        >IIMI-BA</vs-checkbox
                    >
                     <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIMI-AA"
                        style="margin-right: 1%"
                        >IIMI-AA</vs-checkbox
                    >
                    <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="IIML-FT"
                        style="margin-right: 1%"
                        >IIML-FT</vs-checkbox
                    >
                    <!-- <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="RPO"
                        style="margin-right: 1%"
                        >RPO</vs-checkbox
                    > -->
                    <!-- <vs-checkbox
                        v-if="checkIfRoleis('exed2')"
                        :disabled="disable"
                        color="dark"
                        v-model="course"
                        vs-value="XLRI-DM"
                        style="margin-right: 1%"
                        >XLRI-DM</vs-checkbox
                    > -->
                </vs-col>
            </vs-row>
            <!-- Next Followup date -->
            <vs-row style="margin-bottom: 3%" v-if="checkLevel()">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <h6 style="color: gray">Next follow up date:</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
                    <datepicker :disabledDates="disabledDates" placeholder="Select Date" :format="customFormatter"
                        v-model="dates"></datepicker>
                </vs-col>
                <vs-col v-if="getSubTeam('IITM-AA') || getSubTeam('IITR-CC')" vs-w="4" vs-type="flex"
                    vs-justify="flex-start" vs-align="center">
                    <vs-button @click="openWileyPopup()" type="gradient" color="dark" style="margin-left: -10%;">
                        <small>Send Wiley Application</small></vs-button>
                </vs-col>
            </vs-row>

            <!-- Whatsapp -->
            <!-- <vs-row class="gaps" style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <h6 style="color: gray;">Send Intro Email,SMS and Whatsapp Invite:</h6>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="lex-start" vs-align="center" vs-w="4">
          <v-select
            :filterable="false"
            :options="send_intro_options"
            placeholder="Select a course"
            v-model="sendIntrocourse"
            style="width:180px"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <vs-button
            id="send_whatsapp_message_btn"
            color="dark"
            type="relief"
            @click="sendWA()"
          >Send</vs-button>
        </vs-col>
      </vs-row> -->

            <!-- Webinar -->
            <!-- <vs-row class="gaps" style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <h6 style="color: gray;">Webinar:</h6>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
          <v-select
            :filterable="false"
            :options="webinar_options"
            placeholder="Select a course"
            v-model="webinarIntro"
            style="width:180px"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start " vs-align="center" vs-w="4">
          <vs-button color="dark" type="relief" @click="sendZoomInvite">Send</vs-button>
        </vs-col>
      </vs-row> -->

            <!-- Add Engagement -->
            <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">

          <vs-button
            color="danger"
            type="gradient"
            icon="done_outline"
            @click="API_post_add_engagement"
          >ADD</vs-button>
        </vs-col>
      </vs-row> -->

            <!-- Whatsapp Message -->
            <!-- <vs-row style="margin-top: 2%;">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
          <vs-textarea
            rows="5"
            label="WhatsApp message"
            disabled
            id="whatsapp_message"
            v-model="whatsapp_message"
          />
        </vs-col>
      </vs-row> -->

            <!-- Hidden Button -->
            <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
          <vs-input
            class="inputx"
            id="candidate_phone_number"
            v-model="phone_number"
            style="visibility: hidden;"
          />
        </vs-col>
      </vs-row> -->
            <save-engagement :selected_courses="course" :phone_number="phone_number"
                :connection_status="connection_status" :person_name="payload.mwb.person_name" :connected_value="select1"
                @add-engagement="addEngagement" :save_loading="save_loading"
                :save_and_whatsapp_loading="save_and_whatsapp_loading" :all_loading="all_loading" :pathway="pathway" :persona="persona"></save-engagement>
        </div>
        <vs-popup class="holamundo" title="Are you sure you want to send application ?" :active.sync="popupOpen">
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <p>Please confirm that you have taken <b>{{ this.WlieyPersonName }}</b> 's consent to registering
                        him/her to submit application for <b>{{ this.SelectedCOurse }}</b> with these contact details
                        <b>{{ this.PrimaryMobile }}</b> and <b>{{ this.PrimaryEmail }}</b></p>
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                    <vs-button color="danger" type="border" @click="popupOpen = false">Cancel</vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <vs-button color="success" type="border" @click="sendWileyApplication()">Yes</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import EventBus from "../../eventbus";
import constants from "../../../../constants.json";
import axios from "axios";
import moment from "moment";
import SaveEngagement from "./SaveEngagement";
import PersonnaDetails from "./PersonnaDetails.vue"
import PersonnaHideDetails from "./PersonnaHideDetails"

export default {
    props: {
        payload: {
            required: true,
            type: Object,
        },
    },
    components: {
        Datepicker,
        "v-select": vSelect,
        // chatInterface
        SaveEngagement,
        PersonnaDetails,
        PersonnaHideDetails
    },
    mounted() {
        let team = localStorage.getItem('sub_team')
        if (team == 'MAcc') {
            this.hide_select = true
        }
        if (this.payload.mwb.iiml_level != '') {
         if (this.macc_levels.find((item) => item.id === this.payload.mwb.iiml_level)) {
          this.selected_macc_level = this.payload.mwb.iiml_level;
         }
        }
        this.levels = this.payload.mwb.level;
        // if (
        //     this.payload.mwb.level === "M4-" ||
        //     this.payload.mwb.level === "M4" ||
        //     this.payload.mwb.level === "M5" ||
        //     this.payload.mwb.level === "M6" ||
        //     this.payload.mwb.level === "L4-" ||
        //     this.payload.mwb.level === "L4" ||
        //     this.payload.mwb.level === "L5" ||
        //     this.payload.mwb.level === "L6" ||
        //     this.payload.mwb.level === "M7"
        //     // this.payload.mwb.level === "M7-" ||
        //     // this.payload.mwb.level === "M8" ||
        //     // this.payload.mwb.level === "M8-" ||
        //     // this.payload.mwb.level === "M9" ||
        //     // this.payload.mwb.level === "M9-" ||
        //     // this.payload.mwb.level === "M10"
        // ) {
        //     this.labelOptions = this.labelOptionsvisit;
        // }

        // if (this.payload.mwb.level.indexOf("M10") !== -1) {
        //     this.connected = false;
        // }
        this.course = [];
        this.course = this.payload.mwb.courses.split(",");
        this.iimlLevel = this.payload.mwb.iiml_level;
        if(this.getTeam('ExEd')){
            if(this.payload.mwb.iiml_level != ''){
                this.exed_level = this.payload.mwb.iiml_level;
            }
            if((this.payload.mwb.iiml_level == '' || this.payload.mwb.iiml_level == null) && localStorage.getItem("sub_team") != "MAcc"){
                this.exed_level = "Net Enquiry";
            }
            if((this.payload.mwb.iiml_level == '' || this.payload.mwb.iiml_level == null) && localStorage.getItem("sub_team") == "MAcc"){
                this.selected_macc_level = "U2";
            }
        }
        let template_couse = null;

        if (localStorage.getItem("sub_team") == "IIML-HR") {
            template_couse = "IIML-HR";
        } else if (localStorage.getItem("sub_team") == "IITR-BF") {
            template_couse = "IITR-BF";
        } else if (localStorage.getItem("sub_team") == "IITR-DB") {
            template_couse = "IITR-DB";
        } else if (localStorage.getItem("sub_team") == "IITM-AA") {
            template_couse = "IITM-AA";
        } else if (localStorage.getItem("sub_team") == "IIMK-CX") {
            template_couse = "IIMK-CX";
        } else if (localStorage.getItem("sub_team") == "IITM-FS") {
            template_couse = "IITM-FS";
        } else if (localStorage.getItem("sub_team") == "IITR-CC") {
            template_couse = "IITR-CC";
        } else if (localStorage.getItem("sub_team") == "IIMK-FT") {
            template_couse = "IIMK-FT";
        } else if (localStorage.getItem("sub_team") == "IIML-AB") {
            template_couse = "IIML-AB";
        } else if (localStorage.getItem("sub_team") == "IIML-SH") {
            template_couse = "IIML-SH";
        } else if (localStorage.getItem("sub_team") == "IITJ-DE") {
            template_couse = "IITJ-DE";
        } else if (localStorage.getItem("sub_team") == "XLRI-HR") {
            template_couse = "XLRI-HR";
        } else if (localStorage.getItem("sub_team") == "XLRI-SH") {
            template_couse = "XLRI-SH";
        }else if (localStorage.getItem("sub_team") == "IIMI-BA") {
            template_couse = "IIMI-BA";
        } else if (localStorage.getItem("sub_team") == "IIMI-AA") {
            template_couse = "IIMI-AA";
        }else if (localStorage.getItem("sub_team") == "XLRI-DM") {
            template_couse = "XLRI-DM";
        } else {
            template_couse = "IIML-PM";
        }

        this.payload.whats_app_messages.forEach((template) => {
            if (template.course === template_couse) {
                this.selected_template = template;
                this.whatsapp_message = template.message;
            }
        });
        this.wileyCourseArray = this.payload.mwb.courses.split(",");
        this.wileyCourseArray.forEach((wileyCourse) => {
            if (wileyCourse === 'IITM-FS') {
                this.wiley_course = "IITM-FS";
            }
            console.log("wiley_course", this.wiley_course)
        });

        EventBus.$on('update-persona-details', () => {
        // console.log("LISTENING TO update-persona-details")
        this.getPersonnaUpdatedData();

        }),

        EventBus.$on('path-way-select', (pathway) => {
            this.pathway = pathway;

            // this.determineMaccLevel();

        }),

        EventBus.$on('persona-changed', (persona) => {
            // console.log("Received persona on emit", persona)

            this.persona = persona
        }),
        EventBus.$on('changed-macc-level', (macc_level) => {
            this.selected_macc_level = macc_level;
        }),
        EventBus.$on('check-persona-data', (new_data) => {
            // console.log("API ON EMIT", new_data)
            this.checkPersonaData(new_data);

        }),
        this.whatsapp_information = this.payload.whats_app_messages;
        this.send_intro_options = this.payload.mwb.courses.split(",");
        // this.fetchEscalationsMembers();
        this.getCandidatePersonaDetails();
    },
    watch: {
        selected_cfa_level(val) {
            for (let i = 0; i < this.cfa_levels.length; i++) {
                const element = this.cfa_levels[i].value;
                if (this.course.indexOf(element) != -1) {
                    this.course.splice(this.course.indexOf(element), 1);
                }
            }
            if (this.course.indexOf(val) == -1) {
                this.course.push(val);
            }
        },
        selected_frm_parts(val) {
            for (let i = 0; i < this.frm_parts.length; i++) {
                const element = this.frm_parts[i].value;
                if (this.course.indexOf(element) != -1) {
                    this.course.splice(this.course.indexOf(element), 1);
                }
            }
            if (this.course.indexOf(val) == -1) {
                this.course.push(val);
            }
        },
        sendIntrocourse(value) {
            console.log(this.whatsapp_information);
            this.whatsapp_information.forEach((message_template) => {
                if (message_template.course === value) {
                    let custom_wam = message_template.message;
                    custom_wam = custom_wam.replace(
                        "{{candidate_name}}",
                        this.payload.mwb.person_name
                    );
                    console.log("custom_wam IIML", custom_wam);
                    this.whatsapp_message = custom_wam;
                }
            });
        },
        selected_team(value) {
            this.changeSpocVisibility(value);
        },
        visit_escalation: function (value) {
            this.escalation_visit = value;
        },
        levels: function (value) {
            // console.log(value);
            let limit = 180;
            let suggested = 180;
            const nfds = {
                M6: {
                    limit: 14,
                    suggested: 7,
                },
                M5: {
                    limit: 14,
                    suggested: 7,
                },
                // M4: {
                //     limit: 180,
                //     suggested: 90,
                // },
                // "M4-": {
                //     limit: 180,
                //     suggested: 120,
                // },
                "M3+": {
                    limit: 14,
                    suggested: 7,
                },
                "M3++": {
                    limit: 14,
                    suggested: 1,
                },
                M3: {
                    limit: 30,
                    suggested: 14,
                },
                // M2: {
                //     limit: 180,
                //     suggested: 90,
                // },
                // M1: {
                //     limit: 180,
                //     suggested: 120,
                // },
                M7: {
                    limit: 90,
                    suggested: 90,
                },
                "M7-": {
                    limit: 90,
                    suggested: 90,
                },
                M8: {
                    limit: 90,
                    suggested: 90,
                },
                "M8-": {
                    limit: 90,
                    suggested: 90,
                },
                M9: {
                    limit: 90,
                    suggested: 90,
                },
                "M9-": {
                    limit: 90,
                    suggested: 90,
                },
                M10: {
                    limit: 90,
                    suggested: 90,
                },
                L6: {
                    limit: 30,
                    suggested: 14,
                },
                L5: {
                    limit: 60,
                    suggested: 15,
                },
                L4: {
                    limit: 120,
                    suggested: 30,
                },
                "L4-": {
                    limit: 180,
                    suggested: 120,
                },
                "L3+": {
                    limit: 90,
                    suggested: 30,
                },
                L3: {
                    limit: 90,
                    suggested: 30,
                },
                L2: {
                    limit: 120,
                    suggested: 30,
                },
                L1: {
                    limit: 180,
                    suggested: 120,
                },
            };
            if(nfds[value] !== undefined){
                limit = nfds[value].limit;
                console.log("limit", limit)
                suggested = nfds[value].suggested;
                this.disabledDates.from = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + limit
                );
                this.disabledDates.to = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                );
                this.dates = new Date(
                    new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() + suggested
                    )
                );
            }
            // if (value === "M7") {
            //   this.dates = "";
            // }
            if (value === "M7" || value === "M4-" || value === "M1" || value === "M4" || value === "M2") {
                this.dates = "";
            }
            if (value === "M4-" || value === "M1" || value == "M4" || value == "M2") {
                this.disabledDates.from = "";
                this.disabledDates.to = "";
            }
        },
        exed_level: function (value) {
            console.log("exed_level", value);

            let limit = 180;
            let suggested = 180;
            const nfds = {
                // "IIMI-YLP-M7": {
                //     limit: 7,
                //     suggested: 7,
                // },
                // "RPO-M7": {
                //     limit: 7,
                //     suggested: 7,
                // },
                "IIML-FA-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-SF-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-FT-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-BA-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-PM-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-HR-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITR-BF-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITR-DB-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITM-AA-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIMK-CX-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITM-FS-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITR-CC-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIMK-FT-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-AB-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIML-SH-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IITJ-DE-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "XLRI-HR-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "XLRI-SH-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "IIMI-BA-M7": {
                    limit: 7,
                    suggested: 7,
                },
                 "IIMI-AA-M7": {
                    limit: 7,
                    suggested: 7,
                },
                "XLRI-DM-M7": {
                    limit: 7,
                    suggested: 7,
                },
                Cold: {
                    limit: 0,
                    suggested: 0,
                },
                "Exam-Pass-Dropout": {
                    limit: 7,
                    suggested: 7,
                },
                "Net Enquiry": {
                    limit: 1,
                    suggested: 1,
                },
                "Busy/NL": {
                    limit: 5,
                    suggested: 2,
                },
                "Application Approved": {
                    limit: 5,
                    suggested: 2,
                },
                "Enrolled": {
                    limit: 365,
                    suggested: 7,
                },
                "Final payment Received": {
                    limit: 5,
                    suggested: 2,
                },
                "Application Received": {
                    limit: 5,
                    suggested: 2,
                },
                "NA": {
                    limit: 0,
                    suggested: 0,
                },
                "Exam-Pass": {
                    limit: 7,
                    suggested: 7,
                },
                "Very Hot": {
                    limit: 3,
                    suggested: 2,
                },
                "Warm": {
                    limit: 7,
                    suggested: 7,
                },
                "Hot": {
                    limit: 3,
                    suggested: 2,
                },
                "Exam-Fail": {
                    limit: 7,
                    suggested: 7,
                },
                "Enquiry": {
                    limit: 7,
                    suggested: 7,
                },
                // "CM-Yet-To-Connect": {
                //     limit: 7,
                //     suggested: 7,
                // },
                "Exam-Fail-Dropout": {
                    limit: 7,
                    suggested: 7,
                },
                "Exam-Noshow-Dropout": {
                    limit: 7,
                    suggested: 7,
                },
                "Lead-DB": {
                    limit: 7,
                    suggested: 7,
                },
                "Lead-IR": {
                    limit: 7,
                    suggested: 7,
                },
            };
            limit = nfds[value].limit;
            suggested = nfds[value].suggested;
            this.disabledDates.from = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + limit
            );
            this.disabledDates.to = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            );
            this.dates = new Date(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + suggested
                )
            );
            console.log("manual dates", this.dates);
            if (value == "NA" || value == "Cold" || value == 'Enrolled') {
                this.showDatepicker = false;
                this.dates = '';
            } else {
                this.showDatepicker = true;
            }
        },
        selected_macc_level: function(value){
            console.log("Macc_level", value);

            let limit = 180;
            let suggested = 180;

            const macc_nfds = {
                "U0": {
                    limit: 365,
                    suggested: 7,
                },
                "U1": {
                    limit: 365,
                    suggested: 7,
                },
                "U1+": {
                    limit: 365,
                    suggested: 7,
                },
                "U2": {
                    limit: 30,
                    suggested: 7,
                },
                "U3-": {
                    limit: 365,
                    suggested: 7,
                },
                "U3": {
                    limit: 5,
                    suggested: 3,
                },
                "U3+": {
                    limit: 3,
                    suggested: 1,
                },
                "U3++": {
                    limit: 3,
                    suggested: 1,
                },
                "U4-": {
                    limit: 15,
                    suggested: 1,
                },
                "U4": {
                    limit: 3,
                    suggested: 1,
                },
                "U4R": {
                    limit: 3,
                    suggested: 1,
                },
                "U5": {
                    limit: 20,
                    suggested: 7,
                },
                "U5+": {
                    limit: 5,
                    suggested: 2,
                },
                "U6-": {
                    limit: 15,
                    suggested: 2,
                },
                "U6": {
                    limit: 30,
                    suggested: 7,
                },
                "U6+": {
                    limit: 30,
                    suggested: 7,
                },
                "U7-": {
                    limit: 5,
                    suggested: 1,
                },
                "U7": {
                    limit: 3,
                    suggested: 1,
                },
                "U8": {
                    limit: 3,
                    suggested: 1,
                },
                "U9": {
                    limit: 30,
                    suggested: 7,
                },
                "U10": {
                    limit: 90,
                    suggested: 30,
                },
            }


            // if(localStorage.getItem("sub_team") == 'MAcc'){
                limit = macc_nfds[value].limit;
                suggested = macc_nfds[value].suggested;
            // }

            this.disabledDates.from = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + limit
            );
            this.disabledDates.to = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            );
            this.dates = new Date(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + suggested
                )
            );
            console.log("manual dates", this.dates);

            // if(value == 'U0') {
            //     this.showDatepicker = false;
            //     this.dates = '';
            // } else {
                this.showDatepicker = true;
            // }
            var pathway_value = "Yes";
            if(value == "U2" || value == "U1" || value == "U3-" ){
                pathway_value = "Maybe";
            }else if (value == "U0" ){
                pathway_value = "No";
            }else if(value == "U1+"){
                pathway_value = "Yes but graduating in 2025 or after"
            }
            EventBus.$emit("changed-pathway-value", pathway_value);
        },
        select1: function (value) {
            //   console.log(value);
            this.iimlConnected = true;
            this.exceptionOption = false;
            let valuetext = value;
            if (valuetext === "Connected / Discussed") {
                this.disable = false;
                // this.iimlConnected = true;
                if (this.getTeam('SR')) {
                    if (this.payload.mwb.level.indexOf("M10") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                } else {
                    if (this.payload.mwb.level.indexOf("M7") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                }
                this.connection_status = true;
                this.responseSelection = "new";
                this.textAre = "";
                this.show = true;
                console.log("iimlLevel", this.payload.mwb.courses)
            } else if (valuetext === "") {
                this.show = false;
            } else if (valuetext === "Connected / Never call back") {
                this.connection_status = true;
                this.textAre = valuetext;
                this.disable = false;
                this.connected = false;
                // this.iimlConnected = false;
                this.show = true;
            } else {
                this.disable = false;
                this.connected = false;
                // this.iimlConnected = false;
                this.generateEngagementText(valuetext);
                this.show = true;
            }

            let exceptionOptions = {
                "Cold": [
                    { text: "Cold", value: "Cold" },
                    { text: "Hot", value: "Hot" },
                ],
                "Hot": [
                    { text: "Hot", value: "Hot" },
                    { text: "Warm", value: "Warm" },
                ],
                "Warm": [
                    { text: "Warm", value: "Warm" },
                    { text: "Very Hot", value: "Very Hot" },
                ],
                "Very Hot": [
                    { text: "Very Hot", value: "Very Hot" },
                    // { text: "", value: "" },
                    {
                        text: "Application Received",
                        value: "Application Received",
                    },
                ],
                "Application Received": [
                    {
                        text: "Application Received",
                        value: "Application Received",
                    },
                    { text: "Exam-Pass", value: "Exam-Pass" },
                ],
                "Exam-Pass": [
                    { text: "Exam-Pass", value: "Exam-Pass" },

                    { text: "Exam-Fail", value: "Exam-Fail" },
                ],
                "Exam-Fail": [
                    { text: "Exam-Fail", value: "Exam-Fail" },
                    { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                ],
                "Exam-Pass-Dropout": [
                    { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                    { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                ],
                "Exam-Fail-Dropout": [
                    { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                    {
                        text: "Exam-Noshow-Dropout",
                        value: "Exam-Noshow-Dropout",
                    },
                ],
                "Exam-Noshow-Dropout": [
                    {
                        text: "Exam-Noshow-Dropout",
                        value: "Exam-Noshow-Dropout",
                    },

                    // { text: "Enquiry", value: "Enquiry" },
                ],
                // Enquiry: [
                //   { text: "Enquiry", value: "Enquiry" },
                // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
                // ],
                // "IIML-FA-M7": [{ text: "IIML-FA-M7", value: "IIML-FA-M7" }],
                // "IIMI-YLP-M7": [{ text: "IIMI-YLP-M7", value: "IIMI-YLP-M7" }],
                // "RPO-M7": [{ text: "RPO-M7", value: "RPO-M7" }],
                // "IIML-SF-M7": [{ text: "IIML-SF-M7", value: "IIML-SF-M7" }],
                "IIML-FT-M7": [{ text: "IIML-FT-M7", value: "IIML-FT-M7" }],
                "XLRI-HR-M7": [{ text: "XLRI-HR-M7", value: "XLRI-HR-M7" }],
                "XLRI-SH-M7": [{ text: "XLRI-SH-M7", value: "XLRI-SH-M7" }],
                // "IIML-BA-M7": [{ text: "IIML-BA-M7", value: "IIML-BA-M7" }],
                // "IIML-HR-M7": [{ text: "IIML-HR-M7", value: "IIML-HR-M7" }],
                // "IITR-BF-M7": [{ text: "IITR-BF-M7", value: "IITR-BF-M7" }],
                // "IITR-DB-M7": [{ text: "IITR-DB-M7", value: "IITR-DB-M7" }],
                // "IITM-AA-M7": [{ text: "IITM-AA-M7", value: "IITM-AA-M7" }],
                // "IIMK-CX-M7": [{ text: "IIMK-CX-M7", value: "IIMK-CX-M7" }],
                // "IITM-FS-M7": [{ text: "IITM-FS-M7", value: "IITM-FS-M7" }],
                // "IITR-CC-M7": [{ text: "IITR-CC-M7", value: "IITR-CC-M7" }],
                // "IIMK-FT-M7": [{ text: "IIMK-FT-M7", value: "IIMK-FT-M7" }],
                // "IIML-AB-M7": [{ text: "IIML-AB-M7", value: "IIML-AB-M7" }],
                // "IIML-SH-M7": [{ text: "IIML-SH-M7", value: "IIML-SH-M7" }],
                // "IITJ-DE-M7": [{ text: "IITJ-DE-M7", value: "IITJ-DE-M7" }],
                // "XLRI-HR-M7": [{ text: "XLRI-HR-M7", value: "XLRI-HR-M7" }],
                "IIMI-BA-M7": [{ text: "IIMI-BA-M7", value: "IIMI-BA-M7" }],
                "IIMI-AA-M7": [{ text: "IIMI-AA-M7", value: "IIMI-AA-M7" }],
                // "XLRI-DM-M7": [{ text: "XLRI-DM-M7", value: "XLRI-DM-M7" }],
                // "IIML-PM-M7": [{ text: "IIML-PM-M7", value: "IIML-PM-M7" }],
                // "CM-Yet-To-Connect": [
                //     { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
                // ],
            };
            console.log("exceptionOptions", exceptionOptions)
            let exceptions = [
                "Not Lifting",
                "Connected / Never call back",
                "Connected / Wrong Number",
                "Switched Off",
                "Connected / Not Interested",

                //new code
                // "Connected / Busy",
                // "Connected / Never call back",
                // "Connected / Wrong Number",
                // "Busy",
                // "Not Lifting",
                // "Not Reachable",
                // "Disconnected",
                // "Invalid Number",
                // "Switched Off",
                // "Connected / Not Interested",
            ];
            console.log("exceptions", exceptions.indexOf(value))
            if (exceptions.indexOf(value) !== -1) {

                this.exceptionOption = true;
                // this.iimlLevels = exceptionOptions[this.payload.mwb.iiml_level];
                console.log("exceptionOptions", this.iimlLevels)
            } else {
                if (this.payload.mwb.iiml_level === "" || this.payload.mwb.iiml_level === null) {
                    this.exceptionOption = false;
                    console.log("exceptionOptions", this.payload.mwb.iiml_level)
                    this.iimlLevel = "R2-"
                    // this.iimlLevel = "Warm";
                } else {
                    this.iimlLevels = [
                        { text: "R1 : Not Interested", value: "R1" },
                        { text: "R2: Interested but Postponed", value: "R2" },
                        { text: "R2- : Untapped Not Lifting", value: "R2-" },
                        { text: "R3- : Net Enquiry Not Lifting", value: "R3-" },
                        { text: "R3 : Interested", value: "R3" },
                        { text: "R3+ : Received CV", value: "R3+" },
                        { text: "R4 : Submitted documents and Postponed", value: "R4" },
                        { text: "R5 : Shortlisted", value: "R5" },
                        { text: "R5- : Profile Rejected", value: "R5-" },
                        { text: "R6 : Assessment/Interview Cleared", value: "R6" },
                        { text: "R6- : Assessment/Interview Rejected", value: "R6-" },
                        { text: "R7 : Offer letter received", value: "R7" },
                        { text: "R7+ : Documents BGV initiated", value: "R7+" },
                        { text: "R7- : Backout", value: "R7-" },
                        { text: "R8 : Placed/On boarded", value: "R8" },
                        { text: "R8+ : Due to Bill", value: "R8+" },
                        { text: "R8- : Replacement", value: "R8-" },
                        { text: "RX : Never to Call", value: "RX" },
                        // { text: "Cold", value: "Cold" },
                        // { text: "Hot", value: "Hot" },
                        // { text: "Warm", value: "Warm" },
                        // { text: "Very Hot", value: "Very Hot" },
                        // // { text: "", value: "" },
                        // {
                        //     text: "Application Received",
                        //     value: "Application Received",
                        // },
                        // { text: "Exam-Pass", value: "Exam-Pass" },

                        // { text: "Exam-Fail", value: "Exam-Fail" },
                        // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                        // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                        // {
                        //     text: "Exam-Noshow-Dropout",
                        //     value: "Exam-Noshow-Dropout",
                        // },

                        // // { text: "Enquiry", value: "Enquiry" },
                        // { text: "IIMI-YLP-M7", value: "IIMI-YLP-M7" },
                        // { text: "RPO-M7", value: "RPO-M7" },
                        // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
                        // { text: "IIML-SF-M7", value: "IIML-SF-M7" },
                        // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
                        // { text: "IIML-BA-M7", value: "IIML-BA-M7" },
                        // { text: "IIML-PM-M7", value: "IIML-PM-M7" },
                        // { text: "IIML-HR-M7", value: "IIML-HR-M7" },
                        // { text: "IITR-BF-M7", value: "IITR-BF-M7" },
                        // { text: "IITR-DB-M7", value: "IITR-DB-M7" },
                        // { text: "IITM-AA-M7", value: "IITM-AA-M7" },
                        // { text: "IIMK-CX-M7", value: "IIMK-CX-M7" },
                        // { text: "IITM-FS-M7", value: "IITM-FS-M7" },
                        // { text: "IITR-CC-M7", value: "IITR-CC-M7" },
                        // { text: "IIMK-FT-M7", value: "IIMK-FT-M7" },
                        // { text: "IIML-AB-M7", value: "IIML-AB-M7" },
                        // { text: "IIML-SH-M7", value: "IIML-SH-M7" },
                        // { text: "IITJ-DE-M7", value: "IITJ-DE-M7" },
                        // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
                        // { text: "XLRI-DM-M7", value: "XLRI-DM-M7" },
                        // { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
                    ];
                    console.log("exceptionOptions", this.iimlLevels)
                }
            }

            // if (
            //     valuetext === "Connected / Never call back" ||
            //     valuetext === "Connected / Wrong Number" ||
            //     valuetext === "Invalid Number" ||
            //     valuetext === "Connected / Busy"
            // ) {
            //     this.showDatepicker = false;
            //     this.dates = null;
            // } else {
            //     this.showDatepicker = true;
            // }
        },
        responseSelection: function (val) {
            switch (val) {
                case "sameResponse":
                    this.textAre = this.payload.mwb.engagement_details;
                    break;
                case "noResponse":
                    this.textAre = "Not Reachable";
                    break;
                case "new":
                    this.textAre = "";
                    break;
            }
        },
        escalation_boolean() {
            this.selected_team = "";
            this.selected_escalation_member = "";
            this.escalation_comments = "";
        },
        course: function (val) {
            if (val.includes('CFA')) {
                this.show_cfa_dropdown = true;
            }
            if (!val.includes('CFA')) {
                this.show_cfa_dropdown = false;
                for (let i = 0; i < this.course.length; i++) {
                    const element = this.course[i];
                    for (let i = 0; i < this.cfa_levels.length; i++) {
                        const level = this.cfa_levels[i].value;
                        if (level === element) {
                            this.course.splice(this.course.indexOf(element), 1);
                        }
                    }
                }
            }
            if (val.includes('FRM')) {
                this.show_frm_dropdown = true;
            }
            if (!val.includes('FRM')) {
                this.show_frm_dropdown = false;
                for (let i = 0; i < this.course.length; i++) {
                    const element = this.course[i];
                    for (let i = 0; i < this.frm_parts.length; i++) {
                        const level = this.frm_parts[i].value;
                        if (level === element) {
                            this.course.splice(this.course.indexOf(element), 1);
                        }
                    }
                }
            }
            this.send_intro_options = val;
            this.webinar_options = val;
        },
    },
    data() {
        return {
            connected_status: ["Connected / Discussed"],
            new_persona_data:{},
            hide_select:false,
            selected_macc_level: "U2",
            // macc_levels: [
            //     // { value: "",  text: ""},
            //     { value: "U0",  text: "U0 : Not interested in US/Canda"},
            //     { value: "U1",  text: "U1 : Interest expressed, dropped out"},
            //     { value: "U2",  text: "U2 : Interest expressed, in pipeline (warm)"},
            //     { value: "U3-",  text: "U3- : Postponed"},
            //     { value: "U3",  text: "U3 : Interest expressed, in pipeline (hot)"},
            //     { value: "U3+",  text: "U3+ : Zoom Attended(Very Hot)"},
            //     { value: "U4-",  text: "U4- : Application initiated, dropped out"},
            //     { value: "U4",  text: "U4 : Application Initiated"},
            //     { value: "U5",  text: "U5 : Application done, I-20 yet to be received"},
            //     { value: "U5+",  text: "U5+ : Conditional Offer Received"},
            //     { value: "U6-",  text: "U6- : I-20 received, Dropped Out"},
            //     { value: "U6",  text: "U6 : I-20 received, Yet to apply for Visa"},
            //     { value: "U6+",  text: "U6+ : I-20 received, Applied for Visa"},
            //     { value: "U7-",  text: "U7- : Visa Received But Dropped out"},
            //     { value: "U7",  text: "U7 : Visa received"},
            //     { value: "U8",  text: "U8 : IIM Indore"},
            //     { value: "U9",  text: "U9 : Started program in USA"},
            //     { value: "U10",  text: "U10 : Completed program in USA"},
            // ],
                macc_levels: [
                    // { value: "",  text: ""},
                    { id: "U0--", label: "U0-- : Not Eligible Lead", level_priority: 1 },
                    { id: "U0", label: "U0 : Not interested in US/Canda", level_priority: 1 },
                    { id: "U1", label: "U1 : Interest expressed, dropped out", level_priority: 1 },
                    { id: "U1+", label: "U1+ : Interest expressed, yet to graduate", level_priority: 1 },
                    { id: "U2", label: "U2 : Interest expressed, in pipeline (warm)", level_priority: 1 },
                    { id: "U3-", label: "U3- : Postponed", level_priority: 1 },
                    { id: "U3", label: "U3 : Interest expressed, in pipeline (hot)", level_priority: 1 },
                    { id: "U3+", label: "U3+ : Zoom Attended(Very Hot)", level_priority: 1 },
                    { id: "U3++", label: "U3++ : Confirm Prospect for USP", level_priority: 1 },
                    { id: "U4-", label: "U4- : Application initiated, dropped out", level_priority: 2 },
                    { id: "U4", label: "U4 : Application Initiated", level_priority: 3 },
                    { id: "U4R", label: "U4R : Refunded 20k", level_priority: 3 },
                    { id: "U5", label: "U5 : Application done, I-20 yet to be received", level_priority: 3 },
                    { id: "U5+", label: "U5+ : Conditional Offer Received", level_priority: 3 },
                    { id: "U6-", label: "U6- : I-20 received, Dropped Out", level_priority: 3 },
                    { id: "U6", label: "U6 : I 20 Rec. Yet to apply for Visa", level_priority: 3 },
                    { id: "U6+", label: "U6+ : I 20 Rec. Applied for Visa", level_priority: 3 },
                    { id: "U7-", label: "U7- : Visa Received But Dropped out", level_priority: 4 },
                    { id: "U7", label: "U7 : Visa received", level_priority: 4 },
                    { id: "U8", label: "U8 : IIM Indore", level_priority: 5 },
                    { id: "U9", label: "U9 : Started program in USA", level_priority: 6 },
                    { id: "U10", label: "U10 : Completed program in USA", level_priority: 7 },
                ],
            show_cfa_dropdown: false,
            show_frm_dropdown: false,
            selected_cfa_level: "",
            selected_frm_parts: "",
            cfa_levels: [
                { text: "CFA-L1", value: "CFA-L1" },
                { text: "CFA-L2", value: "CFA-L2" },
                { text: "CFA-L3", value: "CFA-L3" },
            ],
            frm_parts: [
                { text: "FRM-P1", value: "FRM-P1" },
                { text: "FRM-P2", value: "FRM-P2" },
            ],
            PrimaryMobile: "",
            PrimaryEmail: "",
            WlieyPersonName: "",
            SelectedCOurse: "",
            popupOpen: false,
            text: "",
            wileyCourseArray: [],
            wiley_course: "",
            registeringforwebinar: "",
            whatsapp_information: [],
            webinar_options: [],
            webinarIntro: "",
            sendIntrocourse: "",
            send_intro_options: [],
            webinarCourse: "IIML-BA",
            exceptionOption: false,
            /* For Escalations */
            teams: [
                { text: "CM Team", value: "cm_team" },
                { text: "Acads Team", value: "acads_team" },
                { text: "IIML Team", value: "iiml_team" },
            ],
            selected_team: "",
            visible_members: [],
            selected_escalation_member: "",
            escalationsObj: {},
            escalation_comments: "",
            /* Ends Here */
            phone_number: `91${this.payload.mobiles[0].last_ten_digits}`,
            whatsapp_message: "",
            iimlLevel: "",
            iimlLevels: [
                { text: "R1 : Not Interested", value: "R1" },
                { text: "R2 : Interested but Postponed", value: "R2" },
                { text: "R2- : Untapped Not Lifting", value: "R2-" },
                { text: "R3- : Net Enquiry Not Lifting", value: "R3-" },
                { text: "R3 : Interested", value: "R3" },
                { text: "R3+ : Received CV", value: "R3+" },
                { text: "R4 : Submitted documents and Postponed", value: "R4" },
                { text: "R5 : Shortlisted", value: "R5" },
                { text: "R5- : Profile Rejected", value: "R5-" },
                { text: "R6 : Assessment/Interview Cleared", value: "R6" },
                { text: "R6- : Assessment/Interview Rejected", value: "R6-" },
                { text: "R7 : Offer letter received", value: "R7" },
                { text: "R7+ : Documents BGV initiated", value: "R7+" },
                { text: "R7- : Backout", value: "R7-" },
                { text: "R8 : Placed/On boarded", value: "R8" },
                { text: "R8+ : Due to Bill", value: "R8+" },
                { text: "R8- : Replacement", value: "R8-" },
                { text: "RX : Never to Call", value: "RX" },
                // { text: "Cold", value: "Cold" },
                // { text: "Hot", value: "Hot" },
                // { text: "Warm", value: "Warm" },
                // { text: "Very Hot", value: "Very Hot" },
                // // { text: "", value: "" },
                // { text: "Application Received", value: "Application Received" },
                // { text: "Exam-Pass", value: "Exam-Pass" },

                // { text: "Exam-Fail", value: "Exam-Fail" },
                // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                // { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },

                // // { text: "Enquiry", value: "Enquiry" },
                // { text: "IIMI-YLP-M7", value: "IIMI-YLP-M7" },
                // { text: "RPO-M7", value: "RPO-M7" },
                // { text: "IIML-FA-M7", value: "IIML-FA-M7" },
                // { text: "IIML-SF-M7", value: "IIML-SF-M7" },
                // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
                // { text: "IIML-BA-M7", value: "IIML-BA-M7" },
                // { text: "IIML-PM-M7", value: "IIML-PM-M7" },
                // { text: "IIML-HR-M7", value: "IIML-HR-M7" },
                // { text: "IITR-BF-M7", value: "IITR-BF-M7" },
                // { text: "IITR-DB-M7", value: "IITR-DB-M7" },
                // { text: "IITM-AA-M7", value: "IITM-AA-M7" },
                // { text: "IIMK-CX-M7", value: "IIMK-CX-M7" },
                // { text: "IITM-FS-M7", value: "IITM-FS-M7" },
                // { text: "IITR-CC-M7", value: "IITR-CC-M7" },
                // { text: "IIMK-FT-M7", value: "IIMK-FT-M7" },
                // { text: "IIML-AB-M7", value: "IIML-AB-M7" },
                // { text: "IIML-SH-M7", value: "IIML-SH-M7" },
                // { text: "IITJ-DE-M7", value: "IITJ-DE-M7" },
                // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
                // { text: "XLRI-DM-M7", value: "XLRI-DM-M7" },
                // { text: "CM-Yet-To-Connect", value: "CM-Yet-To-Connect" },
            ],
            exed_levels: [
                { text: "Cold", value: "Cold" },
                { text: "Hot", value: "Hot" },
                { text: "Warm", value: "Warm" },
                { text: "Very Hot", value: "Very Hot" },
                { text: "Application Received", value: "Application Received" },
                // { text: "Exam-Pass", value: "Exam-Pass" },
                // { text: "Exam-Fail", value: "Exam-Fail" },
                // { text: "Exam-Pass-Dropout", value: "Exam-Pass-Dropout" },
                // { text: "Exam-Fail-Dropout", value: "Exam-Fail-Dropout" },
                // { text: "Exam-Noshow-Dropout", value: "Exam-Noshow-Dropout" },
                // { text: "IIML-FT-M7", value: "IIML-FT-M7" },
                // { text: "IIMI-BA-M7", value: "IIMI-BA-M7" },
                // { text: "XLRI-HR-M7", value: "XLRI-HR-M7" },
                { text: "Net Enquiry", value: "Net Enquiry" },
                { text: "Busy/NL", value: "Busy/NL" },
                { text: "Application Approved", value: "Application Approved" },
                { text: "Enrolled", value: "Enrolled" },
                { text: "Final payment Received", value: "Final payment Received" },
                { text: "NA", value: "NA" },
            ],
            exed_level: "",
            checkboxdisable: false,
            tagVisible: true,
            netEnquiryDetails: "",
            arr: [
                {
                    name: "Connected, Busy",
                    value: 0,
                    form: "CB",
                },
                {
                    name: "Connected, Never call back",
                    value: 0,
                    form: "CN",
                },
                {
                    name: "Connected, Wrong Number",
                    value: 0,
                    form: "CW",
                },
                {
                    name: "Busy",
                    value: 0,
                    form: "B",
                },
                {
                    name: "Not Lifting",
                    value: 0,
                    form: "NL",
                },
                {
                    name: "Not Reachable",
                    value: 0,
                    form: "NR",
                },
                {
                    name: "Disconnected",
                    value: 0,
                    form: "D",
                },
                {
                    name: "Invalid Number",
                    value: 0,
                    form: "IN",
                },
                {
                    name: "Switched Off",
                    value: 0,
                    form: "SO",
                },
                {
                    name: "Connected, Not Interested",
                    value: 0,
                    form: "CNI",
                },
            ],
            indexActive: 0,
            disabledDates: {
                to: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 2,
                    5
                ),
                from: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    26
                ),
            },
            connected: false,
            iimlConnected: false,
            escalation_connect: false,
            escalations_visit: false,
            secondvisit_connect: false,
            show: false,
            disable: true,
            options1: [
                { text: "", value: 1 },
                { text: "Connected / Discussed", value: 2 },
                { text: "Connected / Busy", value: 3 },
                { text: "Connected / Never call back", value: 4 },
                { text: "Connected / Wrong Number", value: 4 },
                { text: "Connected / Not Interested", value: 11 },
                { text: "Busy", value: 5 },
                { text: "Not Lifting", value: 6 },
                { text: "Not Reachable", value: 7 },
                { text: "Disconnected", value: 8 },
                { text: "Invalid Number", value: 9 },
                { text: "Switched Off", value: 10 },
            ],
            dates: "",
            showDatepicker: true,
            course: [],
            levels: "",
            textAre: "",
            phone_number_toupdate: "",
            responseSelection: "",
            select1: "",
            latestEngagement: "",
            navTitleName: "",
            allpage: true,
            visitpage: false,
            selected_template: {},
            callpage: false,
            whatsapp_page: false,
            emailpage: false,
            lastengagement: "",
            details: "",
            labelOptionsvisit: [
                { text: "", value: "" },
                { text: "M10 - Alumini (30 days)", value: "M10" },
                { text: "M9 - One exam cleared (30 days)", value: "M9" },
                {
                    text:
                        "M9- - Drop-out (after clearing an exam) (once every 3 months)",
                    value: "M9-",
                },
                { text: "M8 - Evaluation (30 days)", value: "M8" },
                {
                    text:
                        "M8- - Drop-out (after evaluation) (once every 3 months)",
                    value: "M8-",
                },
                { text: "M7- - Drop-out (30 days)", value: "M7-" },
                { text: "M7 - Enrolled", value: "M7" },
                { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                { text: "M5 - Visited & Positive", value: "M5" },
                { text: "M4 - Visited but Postponed", value: "M4" },
                { text: "M4- - Visited but not interested", value: "M4-" },
            ],
            labelOptions: [
                { text: "", value: "" },
                { text: "M7 - Enrolled", value: "M7" },
                { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                { text: "M5 - Visited & Positive", value: "M5" },
                { text: "M4 - Visited but Postponed", value: "M4" },
                { text: "M4- - Visited but not interested", value: "M4-" },
                { text: "M3++ - Called & Coming", value: "M3++" },
                { text: "M3+ - Called & Coming", value: "M3+" },
                { text: "M3 - Called & Positive", value: "M3" },
                { text: "M2 - Did not Visit & Postponed", value: "M2" },
                { text: "M1 - Did not Visit & not intersted", value: "M1" },
            ],
            escalation_boolean: false,
            escalation_acads_member: "",
            escaltionOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
                { text: "Seet Mumbai", value: 170 },
                { text: "Etisha", value: 70 },
                { text: "Namitha", value: 82 },
                { text: "Pallavi", value: 87 },
                // { text: "Seet Mumbai", value: 170 }
            ],
            second_visit: false,
            second_visit_select: "",
            secondvisitOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
                // { text: "Seet Mumbai", value: 170 },
                { text: "Etisha", value: 70 },
                { text: "Namitha", value: 82 },
                { text: "Pallavi", value: 87 },
            ],
            visit_escalation: "",
            escalation_visit: false,
            escaltionvisitOptions: [
                { text: "", value: "" },
                { text: "Esccalation_1", value: "Esccalation_1" },
                { text: "Esccalation_2", value: "Esccalation_2" },
                { text: "Esccalation_3", value: "Esccalation_3" },
                { text: "Esccalation_4", value: "Esccalation_4" },
            ],
            typeSelction: "",
            courseSelection: "",
            connection_status: false,
            latestDate: "",
            enrollment: false,
            save_loading: false,
            save_and_whatsapp_loading: false,
            all_loading: false,
            personna_data:false,
            persona_details : {},
            persona: "",
            pathway: "",
        };
    },
    methods: {
        checkLevel() {
            if (this.levels === 'M1' || this.levels === 'M4-' || this.levels === 'M4' || this.levels === 'M2') {
                return false;
            } else {
                return true;
            }
        },
        checkPersonaData(new_data) {
            this.new_persona_data = new_data
        },
        getPersonnaUpdatedData() {
        this.getCandidatePersonaDetails();
        },
        determineMaccLevel() {
        console.log("DETERMINE MACC LEVEL PATHWAY CHANGE", this.pathway, this.course)

        let downgradable_levels = ["U0--","U0", "U1", "U1+", "U2", "U3-", "U3", "U3+","U3++", "U4-"]

        // let hot_level_pathways = ["Pathway 2 - US", "Pathway 3 - Canada", "Pathway 4 - US or Canada"]

        //For Pathway 2,3,4 U level = U3
        // if this.payload.mwb.iiml_level includes (U0, U1)

        // if (hot_level_pathways.includes(this.pathway)) {
        //     if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
        //     this.selected_macc_level = "U3"
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }
        //     } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
        //     this.selected_macc_level = "U3"
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }

        //     } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
        //     this.selected_macc_level = this.payload.mwb.iiml_level
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }

        //     }
        // }

        //For Pathway 5 U Level = U2
        // let warm_level_pathways = ["Pathway 5 - India/US/Canada"]

        // if (warm_level_pathways.includes(this.pathway)) {
        //     if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }
        //     this.selected_macc_level = "U2"

        //     } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
        //     this.selected_macc_level = "U2"
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }

        //     } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
        //     this.selected_macc_level = this.payload.mwb.iiml_level
        //     if (!this.course.includes('MAcc')) {
        //         this.course.push('MAcc');
        //     }

        //     }
        // }

        // For Pathway 1 U Level = U0
        if (this.pathway == "Pathway 1 - India") {
            if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && downgradable_levels.includes(this.payload.mwb.iiml_level)) {
            this.selected_macc_level = "U0"
            if (this.course.includes('MAcc')) {
                this.course.splice(this.course.indexOf('MAcc'), 1);
            }


            } else if (this.payload.mwb.iiml_level != "" && this.payload.mwb.iiml_level != null && !downgradable_levels.includes(this.payload.mwb.iiml_level)) {
            this.selected_macc_level = this.payload.mwb.iiml_level
            this.$vs.notify({
                title: "U-level cannot be downgraded",
                color: "danger",
                time: 5000
            });
            EventBus.$emit('make-pathway-empty');

            } else if (this.payload.mwb.iiml_level == "" || this.payload.mwb.iiml_level == null) {
            this.selected_macc_level = "U0"

            if (this.course.includes('MAcc')) {
                this.course.splice(this.course.indexOf('MAcc'), 1);
            }

            }
        }

        console.log("DETERMINE MACC LEVEL PATHWAY CHANGE 2", this.pathway, this.course)

        },
        getCandidatePersonaDetails() {
        this.persona_details = {};
        // this.$vs.loading();
        let mwb_id = this.payload.mwb.id;
        let url = `https://mfcomms.2x2.ninja/api/getCandidatePersonaDetails?mwb_id=${mwb_id}`;
        axios
            .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
            })
            .then((response) => {
            this.persona_details = response.data;
            this.personna_data = true;
            //  console.log("Personna call Data",  this.persona_details, this.persona_details.length);
            // this.$vs.loading.close();
            EventBus.$emit("persona-candidate-updated-data", this.persona_details)
            })
            .catch((error) => {
            this.handleError(error);
            this.personna_data = true;
            this.$vs.loading.close();
            });
        },
        openWileyPopup() {
            if (this.course.length > 1) {
                this.showCustomNotification(
                    "Please Select only one course",
                    "For this candidate multiple courses are seleted.please select only one course for sending wiley application ",
                    "danger"
                );
                return;
            }
            if (this.course.toString() !== "IITR-CC" && this.course.toString() !== "IITM-AA") {
                this.showCustomNotification(
                    "Please Select only IITR-CC OR IITM-AA courses",
                    "For selecting course " + this.course.toString() + " , ITRAC Application is not created. Please select only IITR-CC OR IITM-AAcourses.",
                    "danger"
                );
                return;
            }
            if (!this.primaryExists(this.payload.emails)) {
                this.showCustomNotification(
                    "Not Valid",
                    "Primary email is required for registered user to send wiley application",
                    "danger"
                );
                return;
            } else if (!this.primaryExists(this.payload.mobiles)) {
                this.showCustomNotification(
                    "Not Valid",
                    "Primary mobile number is required for registered user to send wiley application",
                    "danger"
                );
                return;
            }
            this.text = "Testing";
            this.popupOpen = true;
            console.log("course", this.course.toString())
            this.PrimaryMobile = this.getMobileForCandidate(this.payload.mobiles);
            this.PrimaryEmail = this.getEmailForCandidate(this.payload.emails);
            this.WlieyPersonName = this.payload.mwb.person_name;
            this.SelectedCOurse = this.course.toString();
            // this.course.forEach(element => {
            //   this.SelectedCOurse = element;
            // });
        },
        primaryExists(PurchaseData) {
            console.log("dim", PurchaseData);
            let bool = false;
            PurchaseData.forEach((item) => {
                if (item.pri_mary === 1) {
                    bool = true;
                }
            });
            return bool;
        },
        sendWileyApplication() {
            let obj = {
                mwb_id: this.payload.mwb.id,
                can_name: this.payload.mwb.person_name,
                miles_course: this.SelectedCOurse,
                mobile: this.getMobileForCandidate(this.payload.mobiles),
                email: this.getEmailForCandidate(this.payload.emails)
            };
            console.log(obj)
            let url = `${constants.SERVER_API}createWileyApplication`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "success",
                        });
                        this.popupOpen = false;
                    }
                    if (response.data.status === "failed" || response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getWhatsAppDisplayText() {
            let mobile = this.getMobileForCandidate(this.payload.mobiles);
            let email = this.getEmailForCandidate(this.payload.emails);

            let course = null;
            if (this.getSubTeam("IIML-HR")) {
                course = "IIML-HR";
            } else if (this.getSubTeam("IITR-BF")) {
                course = "IITR-BF";
            } else if (this.getSubTeam("IITR-DB")) {
                course = "IITR-DB";
            } else if (this.getSubTeam("IITM-AA")) {
                course = "IITM-AA";
            } else if (this.getSubTeam("IIMK-CX")) {
                course = "IIMK-CX";
            } else if (this.getSubTeam("IITM-FS")) {
                course = "IITM-FS";
            } else if (this.getSubTeam("IITR-CC")) {
                course = "IITR-CC";
            } else if (this.getSubTeam("IIMK-FT")) {
                course = "IIMK-FT";
            } else if (this.getSubTeam("IIML-AB")) {
                course = "IIML-AB";
            } else if (this.getSubTeam("IIML-SH")) {
                course = "IIML-SH";
            } else if (this.getSubTeam("IITJ-DE")) {
                course = "IITJ-DE";
            } else if (this.getSubTeam("XLRI-HR")) {
                course = "XLRI-HR";
            }else if (this.getSubTeam("XLRI-SH")) {
                course = "XLRI-SH";
            } else if (this.getSubTeam("IIMI-BA")) {
                course = "IIMI-BA";
            }else if (this.getSubTeam("IIMI-AA")) {
                course = "IIMI-AA";
            }else if (this.getSubTeam("XLRI-DM")) {
                course = "XLRI-DM";
            } else {
                course = "IIML-PM";
            }

            let text = `Send Whatsapp and ${course} Intro Email`;

            if (mobile == null) {
                text = `Send ${course} Intro Email`;
            } else if (email == null) {
                text = "Send Whatsapp Message";
            }

            return text;
        },
        sendZoomInvite() {
            // this.$vs.loading();
            console.log(this.payload);
            let mobile = this.getMobileForCandidate(this.payload.mobiles);
            let email = this.getEmailForCandidate(this.payload.emails);

            if (mobile === null || email === null) {
                return;
            }

            this.sendMilesZoomInvite(
                this.payload.mwb,
                // this.webinarCourse,
                this.webinarIntro,
                email,
                mobile,
                this.payload.mwb.assigned_iiml_name
            );

            // let params = {
            //   form_array: [
            //     {
            //       canid: this.payload.mwb.identity,
            //       first_name: this.payload.mwb.person_name,
            //       last_name: "",
            //       email: email,
            //       contact: mobile,
            //       comingFrom: "MilesForce",
            //       course: "IIML-PM",
            //       location: this.payload.mwb.city,
            //       // webinarId: "99678939577",
            //       // webinarId: "97229709724",
            //       webinarId: "91233796915",
            //       city: this.payload.mwb.city,
            //       state: null,
            //       country: null,
            //     },
            //   ],
            // };

            // axios
            //   .post(
            //     `https://website-api.mileseducation.com/zoomWileyRegistration`,
            //     params
            //   )
            //   .then((response) => {
            //     console.log(response);
            //     this.$vs.loading.close();
            //     if (response.data.message === "Webinar Created Successfully.") {
            //       this.$vs.notify({
            //         title: `Success`,
            //         text: response.data.message,
            //         color: "success",
            //       });
            //     } else {
            //       this.$vs.notify({
            //         title: `Error`,
            //         text: response.data.data.message,
            //         color: "danger",
            //       });
            //     }
            //   })
            //   .catch((error) => {
            //     this.handleError(error);
            //   });
        },
        escalateThisLead() {
            let obj = {
                escalated_to_id: this.selected_escalation_member,
                escalated_to_name: "",
                escalated_comments: this.escalation_comments,
                mwb_id: this.payload.mwb.id,
                identity: this.payload.mwb.identity,
                person_id: this.payload.mwb.person_id,
                person_name: this.payload.mwb.person_name,
            };
            this.visible_members.forEach((member) => {
                if (member.id == this.selected_escalation_member) {
                    obj.escalated_to_name = member.full_name;
                }
            });
            console.log(obj);
            let url = `${constants.SERVER_API}addEscalation`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        changeSpocVisibility(value) {
            this.selected_escalation_member = "";
            this.visible_members = this.escalationsObj[value];
        },
        // fetchEscalationsMembers() {
        //     let url = `${constants.SERVER_API}getEscalationMembers`;
        //     axios
        //         .get(url, {
        //             headers: {
        //                 Authorization: `Bearer ${localStorage.userAccessToken}`,
        //             },
        //         })
        //         .then((response) => {
        //             console.log(response);
        //             this.escalationsObj = response.data;
        //             // this.handleNotification(response);
        //         })
        //         .catch((error) => {
        //             this.handleError(error);
        //         });
        // },
        checkIfRoleis(role) {
            if (localStorage.getItem("role") === role) {
                return true;
            } else {
                return false;
            }
        },
        getNextDateStatus(payload) {
            console.log(payload.next_call);
            if (payload.next_call === null) {
                this.tagVisible = false;
                return;
            }
            this.tagVisible = true;
            let nextCall = new Date(payload.next_call * 1000);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (nextCall > today) {
                return `Waiting - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            } else if (nextCall < today) {
                return `Delay - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            } else {
                return `Today - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            }
        },
        getNextDateColor(payload) {
            let nextCall = new Date(payload.next_call * 1000);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (nextCall > today) {
                return `success`;
            } else if (nextCall < today) {
                return `danger`;
            } else {
                return `warning`;
            }
        },
        generateEngagementInfoBadges() {
            if (this.payload.incommunicado_status !== 0) {
                for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
                    if (
                        this.payload.engagements[x].connection_status === "CD"
                    ) {
                        break;
                    } else {
                        this.arr.forEach((ar) => {
                            if (
                                this.payload.engagements[x]
                                    .connection_status === ar.form
                            ) {
                                ar.value = ar.value + 1;
                            }
                        });
                    }
                }
            }
            // console.log(this.arr);
        },
        generateEngagementText(value) {
            let engagementString = "";
            // console.log(this.payload);
            if (
                this.payload.mwb.engagement_details === null ||
                this.payload.mwb.engagement_details === ""
            ) {
                this.textAre = value;
                this.latestDate = `${this.getData()} - ${value}, |`;
                this.connection_status = false;
            } else {
                if (this.payload.mwb.engagement_details.includes("|")) {
                    // console.log("in the if block");
                    let index = this.payload.mwb.engagement_details.indexOf(
                        "|"
                    );
                    index = index + 1;
                    engagementString = `${this.getData()} - ${value}, | ${this.payload.mwb.engagement_details.substring(
                        index
                    )}`;
                } else {
                    // console.log("in the else block");
                    engagementString = `${this.getData()} - ${value}, | ${this.payload.mwb.engagement_details
                        }`;
                }
                this.textAre = value;
                this.latestDate = engagementString;
                this.connection_status = false;
            }
            console.log(this.latestDate);
        },
        getData() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            return today;
        },
        customFormatter(date) {
            return moment(date).format("DD-MMM-YYYY");
        },
        emailform() {
            EventBus.$emit("open-email-engagement", this.payload);
        },
        fetchIcon(type) {
            switch (type) {
                case "call":
                    return "local_phone";
                case "visit":
                    return "place";
                case "email":
                    return "mail";
            }
        },
        getEscalationStatus() {
            if (this.escalation_boolean) {
                return 1;
            } else {
                return 0;
            }
        },
        getStatus(bool) {
            if (bool) {
                return 1;
            } else {
                return 0;
            }
        },
        sendWA() {
            // if (this.getrole("exed") || this.getrole("exed2")) {
            console.log(this.sendIntrocourse);
            let obj = {
                course: this.sendIntrocourse,
            };
            this.sendIIMLSMS(this.payload, obj);
            let email = this.getEmailForCandidate(this.payload.emails);

            if (email !== null) {
                if (this.sendIntrocourse === "IIML-HR") {
                    this.sendIIMLHRInvite();
                } else if (this.sendIntrocourse === "IITR-BF") {
                    this.sendIITRBFInvite();
                    console.log("Send IITR-BF Intro Invite");
                } else if (this.sendIntrocourse === "IITR-DB") {
                    this.sendIITRDBInvite();
                    console.log("Send IITR-DB Intro Invite");
                } else if (this.sendIntrocourse === "IITM-AA") {
                    this.sendIITMAAInvite();
                    console.log("Send IITM-AA Intro Invite");
                } else if (this.sendIntrocourse === "IIMK-CX") {
                    this.sendIIMKCXInvite();
                    console.log("Send IIMK-CX Intro Invite");
                } else if (this.sendIntrocourse === "IITM-FS") {
                    this.sendIITMFSInvite();
                    console.log("Send IITM-FS Intro Invite");
                } else if (this.sendIntrocourse === "IITR-CC") {
                    this.sendIITRCCInvite();
                    console.log("Send IITR-CC Intro Invite");
                } else if (this.sendIntrocourse === "IIMK-FT") {
                    this.sendIIMKFTInvite();
                    console.log("Send IIMK-FT Intro Invite");
                } else if (this.sendIntrocourse === "IIML-AB") {
                    this.sendIIMLABInvite();
                    console.log("Send IIML-AB Intro Invite");
                } else if (this.sendIntrocourse === "IIML-SH") {
                    this.sendIIMLSHInvite();
                    console.log("Send IIML-SH Intro Invite");
                } else if (this.sendIntrocourse === "IITJ-DE") {
                    this.sendIITJDEInvite();
                    console.log("Send IITJ-DE Intro Invite");
                } else if (this.sendIntrocourse === "XLRI-HR") {
                    this.sendXLRIHRInvite();
                    console.log("Send XLRI-HR Intro Invite");
                }else if (this.sendIntrocourse === "XLRI-SH") {
                    this.sendXLRISHInvite();
                    console.log("Send XLRI-SH Intro Invite");
                } else if (this.sendIntrocourse === "XLRI-DM") {
                    this.sendXLRIDMInvite();
                    console.log("Send XLRI-DM Intro Invite");
                } else {
                    this.sendIIMLPAInvite();
                }
            }
        },
        sendIITMAAInvite() {
            console.log("IITM-AA course");
        },
        sendIIMKCXInvite() {
            console.log("IIMK-CX course");
        },
        sendIITMFSInvite() {
            console.log("IITM-FS course");
        },
        sendIITRCCInvite() {
            console.log("IITR-CC course");
        },
        sendIIMKFTInvite() {
            console.log("IIMK-FT course");
        },
        sendIIMLABInvite() {
            console.log("IIML-AB course");
        },
        sendIIMLSHInvite() {
            console.log("IIML-SH course");
        },
        sendIITJDEInvite() {
            console.log("IITJ-DE course");
        },
        sendXLRIHRInvite() {
            console.log("XLRI-HR course");
        },
        sendXLRISHInvite() {
            console.log("XLRI-SH course");
        },
        sendXLRIDMInvite() {
            console.log("XLRI-DM course");
        },
        sendIITRBFInvite() {
            let obj = {
                mwb_id: this.payload.mwb.id,
            };

            axios
                .get(`${constants.SERVER_API}sentIITRBFIntroEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendIIMLPAInvite() {
            let obj = {
                mwb_id: this.payload.mwb.id,
            };

            axios
                .get(`${constants.SERVER_API}sentIIMLPAIntorEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        sendIIMLHRInvite() {
            let obj = {
                mwb_id: this.payload.mwb.id,
            };

            axios
                .get(`${constants.SERVER_API}sentIIMLHRIntroEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                    2;
                });
        },
        sendIITRDBInvite() {
            let obj = {
                mwb_id: this.payload.mwb.id,
            };

            axios
                .get(`${constants.SERVER_API}sentIITRDBIntroEmail`, {
                    params: obj,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        async API_post_add_engagement(mode) {
            console.log("date", this.course.includes('FRM'));
            let courses_list = ["AICPA","CFA", "FRM", "IIML-FT", "USP", "CMA","CPA", "CPA-AA", "CPA-US", "IIMI-BA", "IIMI-AA", "XLRI-HR", "XLRI-SH"];
            let allowed_status_for_persona = ["Connected / Discussed"];
            let cfa_list = ["CFA-L1", "CFA-L2", "CFA-L3"];
            let frm_list = ["FRM-P1", "FRM-P2"];
            let course_array_length = _.intersection(courses_list, this.course);
            let cfa_array_length = _.intersection(cfa_list, this.course);
            let frm_array_length = _.intersection(frm_list, this.course);
            if (mode === "all") {
                this.registeringforwebinar = "yes";
            } else {
                this.registeringforwebinar = "";
            }
            this.textAre = this.textAre.trim();
            if (this.textAre === "") {
                this.$vs.notify({
                    title: "Fill out the form",
                    text: "Please fill out the details in the engagement form",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (
                this.payload.mwb.experience_int === null ||
                this.payload.mwb.experience_int === ""
            ) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please add experience to save engagement",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (course_array_length.length == 0) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select Course",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (this.course.includes('CFA') && cfa_array_length.length == 0) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select CFA level",
                    color: "danger",
                });
                this.resetLoadingButtons();
            } else if (this.course.includes('FRM') && frm_array_length.length == 0) {
                this.resetLoadingButtons();
                this.$vs.notify({
                    title: "Please Select FRM Part",
                    color: "danger",
                });
                this.resetLoadingButtons();
            }
            // else if (isNaN(Number(this.new_persona_data.years_of_experience)) && allowed_status_for_persona.includes(this.select1)) {
            //     this.resetLoadingButtons();
            //     this.$vs.notify({
            //     text: "Experience Cannot Be String in Persona",
            //     color: "danger",
            //     });

            //     this.resetLoadingButtons();
            // }
            // else if ((this.new_persona_data.location == null || this.new_persona_data.location == "") && allowed_status_for_persona.includes(this.select1)) {
            //     this.$vs.notify({
            //     text: "Location cannot be empty",
            //     color: "danger",
            //     });
            //     this.resetLoadingButtons();
            // }
            else {
                const engagementType = "call";
                if (
                    this.connection_status === false &&
                    engagementType === "call"
                ) {
                    this.textAre = this.latestDate;
                }
                let timestmp = "";
                // if (this.payload.mwb.level !== "M7") {
                timestmp = new Date(this.dates).getTime() / 1000;
                // }
                let connect_status = "";
                switch (this.select1) {
                    case "Connected / Discussed":
                        connect_status = "cd";
                        break;
                    case "Connected / Busy":
                        connect_status = "cb";
                        break;
                    case "Connected / Never call back":
                        connect_status = "cn";
                        break;
                    case "Connected / Wrong Number":
                        connect_status = "cw";
                        break;
                    case "Busy":
                        connect_status = "b";
                        break;
                    case "Not Lifting":
                        connect_status = "nl";
                        break;
                    case "Not Reachable":
                        connect_status = "nr";
                        break;
                    case "Disconnected":
                        connect_status = "d";
                        break;
                    case "Invalid Number":
                        connect_status = "in";
                        break;
                    case "Switched Off":
                        connect_status = "so";
                        break;
                    case "Connected / Not Interested":
                        connect_status = "cni";
                        break;
                }
                if (engagementType === "visit") {
                    connect_status = "cd";
                }
                let copyenrollment = "";
                if (this.enrollment) {
                    copyenrollment = "Yes";
                } else {
                    copyenrollment = "No";
                }
                // console.log(this.enrollment);
                // this.course.forEach((subject, index) => {
                //   if (subject === "None") {
                //     this.course.splice(index, 1);
                //   }
                // });
                if (this.select1 == "Connected / Discussed") {
                    if (this.current_location != this.payload.google_location) {
                        this.saveCandidateLocation();
                    }
                }
                if (allowed_status_for_persona.includes(this.select1)) {
                this.storePersona();
                }

                console.log("find valueedjkfjk",timestmp);
                let co = this.course.join();
                let url = `${constants.SERVER_API}addEngagement`;
                if (this.exed_level !== "Cold" && this.exed_level !== "NA" && this.exed_level !== "Enrolled" && localStorage.getItem("sub_team") != "MAcc") {
                    if (timestmp === "" || isNaN(timestmp) || timestmp === 0) {
                        this.$vs.notify({
                            title: "Next call date is required",
                            text:
                                "Please select a next call date for adding an engagement",
                            color: "danger",
                        });
                        this.resetLoadingButtons();
                        return;
                    }
                }
                let obj = {
                    can_id: this.payload.mwb.can_id,
                    person_id: this.payload.mwb.person_id,
                    person_name: this.payload.mwb.person_name,
                    escalation_status: this.getStatus(this.escalation_boolean),
                    escalated_to: this.escalation_acads_member,
                    second_visit_status: this.getStatus(this.second_visit),
                    second_visit_to: this.second_visit_select,
                    level: this.levels,
                    // iiml_level: this.iimlLevel,
                    iiml_level: this.exed_level,
                    courses: co,
                    details: this.textAre,
                    type: "call",
                    acads: 0,
                    next_call: timestmp,
                    update_call_log: "Yes",
                    connection_status: connect_status.toUpperCase(),
                    enrollment: copyenrollment,
                    registering_for_webinar: this.registeringforwebinar,
                };
                if(localStorage.getItem("sub_team") == 'MAcc' && localStorage.getItem("role") == 'exed2'){
                   obj.iiml_level = this.selected_macc_level;

                //    let existing_level_index = this.macc_levels.findIndex(x => x.value === this.payload.mwb.iiml_level);
                //    let requested_level_index = this.macc_levels.findIndex(x => x.value === this.selected_macc_level)
                //    console.log("EX INDEXX--",this.macc_levels.findIndex(x => x.value === this.payload.mwb.iiml_level))
                //    console.log("NEw INDEXX--",this.macc_levels.findIndex(x => x.value === this.selected_macc_level))

                //    if(requested_level_index < existing_level_index){
                //     this.$vs.notify({
                //             title: "Can not downgrade level",
                //             text:
                //                 `As the current level is ${this.payload.mwb.iiml_level}. It can not be downgraded`,
                //             color: "danger",
                //         });
                //         return;
                //    }

                }
                if (obj.next_call === 0) {
                    obj.next_call = null;
                }
                if(this.getTeam('ExEd')){
                    if(this.payload.mwb.iiml_level == '' || obj.iiml_level == null || obj.iiml_level == ''){
                       obj.iiml_level = "Net Enquiry";
                    }
                }
                console.log("Add engagement obj",obj, url);
                // return;
                // if (this.getrole("exed") || this.getrole("exed2")) {
                //   this.sendIIMLSMS(this.payload);
                // }
                // return;

                axios
                    .post(url, obj, {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.handleNotification(response);
                        if (response.data.status === "success") {
                            if (this.$route.path === "/queue") {
                                EventBus.$emit("update-queue");
                            }
                            EventBus.$emit("close-big-popup");
                        }
                        this.resetLoadingButtons();
                    })
                    .catch((error) => {
                        this.resetLoadingButtons();
                        this.handleError(error);
                    });
            }
        },
        storePersona() {
            console.log("STORE PERSONA FROM CALL SECTION", this.new_persona_data)

            let url = `https://mfcomms.2x2.ninja/api/updateCandidatePersonaDetails`;
            axios
                .post(url, this.new_persona_data, {
                headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
                })
                .then((response) => {
                console.log("Personna Data", response.data);

                if (response.data.status == "success") {
                    this.$vs.notify({
                    title: "Success",
                    text: "Persona Details Updated Successfully",
                    color: "success",
                    });
                    this.getCandidatePersonaDetails();

                    // console.log("API AT EMIT",this.existing_persona_data ,obj)

                } else {
                    this.$vs.notify({
                    title: "Error",
                    text: "Something went wrong",
                    color: "danger",
                    });


                }
                })
                .catch((error) => {
                this.handleError(error);
                // this.getCandidatePersonaDetails();
                this.$vs.loading.close();
                });
        },

        saveCandidateLocation() {
        let obj = {
            mwb_id: this.payload.mwb.id,
            candidate_location: this.current_location,
        };
        console.log("location params", obj);
        let url = `${constants.SERVER_API}saveCandidateLocation`;
        axios
            .post(url, obj, {
            headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
            })
            .then((response) => {
            console.log("savelocation", response.data[0]);
            })
            .catch((error) => {
            this.handleError(error);
            });
        },

        resetLoadingButtons() {
            this.all_loading = false;
            this.save_and_whatsapp_loading = false;
            this.save_loading = false;
        },

        addEngagement(mode) {
            console.log("Add engagement mode: ", mode);

            if (this.select1 === "Connected / Discussed") {
                let data = this.new_persona_data
                let fields = ['company','location','pathway_value','indian_professional_qualification','global_professional_qualification','ug_qualification','years_of_experience']
                let headers = {company: 'Company',location:'Location',pathway_value:'Pathway',indian_professional_qualification:'Indian Professional Qualification',global_professional_qualification:'Global Professional Qualification',ug_qualification:'UG Qualification',years_of_experience:'Years Of Experience'}
                let empty_field = ''
                let status = true
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    if (data[field] === '' || data[field] === null) {
                    empty_field = field
                    status = false
                    break;
                    }
                }
                if (!status) {
                  this.$vs.notify({
                      title: headers[empty_field] +' cannot be empty.',
                      color: "danger",
                      time: 1000
                  });
                }else{
                this.addEngagementPassed(mode)
                }
            }else{
                this.addEngagementPassed(mode)
            }
        },

        addEngagementPassed(mode){
            if (mode === "save only") {
                this.API_post_add_engagement(mode);
            } else if (mode === "save and whatsapp") {
                let mobile = this.getMobileForCandidate(this.payload.mobiles);
                let email = this.getEmailForCandidate(this.payload.emails);
                if (mobile === null) {
                    this.resetLoadingButtons();
                    return;
                }
                if (email != null) {
                    this.sendIntroEmails();
                }
                this.clickWhatsappButton();
                this.API_post_add_engagement(mode);
            } else if (mode === "all") {
                let mobile = this.getMobileForCandidate(this.payload.mobiles);
                let email = this.getEmailForCandidate(this.payload.emails);
                if (mobile === null || email === null) {
                    this.resetLoadingButtons();
                    return;
                }
                this.sendIntroEmails();
                this.clickWhatsappButton();
                this.sendWebinarInvite();
                this.API_post_add_engagement(mode);
            }
        },

        sendWebinarInvite() {
            console.log("Sending webinar invite", {
                mwb_id: this.payload.mwb.id,
                courses: this.course.join(),
            });
            const obj = {
                mwb_id: this.payload.mwb.id,
                courses: this.course.join(),
            };
            const url = `${constants.SERVER_API}saveWebinarSentDetails`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("savewebinarresponse:", response.data);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        sendIntroEmails() {
            console.log("Sending intro emails", {
                mwb_id: this.payload.mwb.id,
                courses: this.course.join(),
            });
            const obj = {
                mwb_id: this.payload.mwb.id,
                courses: this.course.join(),
            };
            const url = `${constants.SERVER_API}sendIntroEmailWithCourses`;
            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log("savewebinarresponse:", response.data);
                    this.handleNotification(response);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        clickWhatsappButton() {
            let whatsappButton = document.getElementById(
                "send_whatsapp_message_btn"
            );
            whatsappButton.click();
            console.log("clicking whatsapp button", whatsappButton);
        },

        navSettings(text) {
            this.navTitleName = text;
            this.escalation_boolean = false;
            this.escalation_acads_member = "";
            this.second_visit = false;
            this.second_visit_select = "";
            switch (text) {
                case "All":
                    this.indexActive = 0;
                    this.allpage = true;
                    this.callpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Call":
                    this.indexActive = 1;
                    this.textAre = "";
                    this.select1 = "";
                    this.levels = this.payload.level;
                    this.enrollment = false;
                    this.callpage = true;
                    this.allpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Visit":
                    this.indexActive = 2;
                    this.textAre = "";
                    this.enrollment = false;
                    this.visitpage = true;
                    this.disable = false;
                    this.connected = true;
                    this.connection_status = true;
                    this.allpage = false;
                    this.callpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    if (
                        !this.payload.level.includes("4") &&
                        !this.payload.level.includes("5") &&
                        !this.payload.level.includes("6") &&
                        !this.payload.level.includes("7")
                    ) {
                        1;
                        this.levels = "M5";
                    } else {
                        if (this.payload.level.includes("L")) {
                            this.levels = "M5";
                        } else {
                            this.levels = this.payload.level;
                        }
                    }
                    break;
                case "Email":
                    this.indexActive = 3;
                    this.emailpage = true;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Whatsapp":
                    this.indexActive = 4;
                    this.whatsapp_page = true;
                    this.emailpage = false;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    break;
            }
        },
    },
};
</script>

<style>
</style>
