<template>
    <div>
        <vs-row type="flex" style="margin-bottom: 3%;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <span>Select Your Bank</span>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="7"
        >
          <v-select
            placeholder="Select Document Type"
            style="z-index: 15000;"
            class="w-full"
            v-model="bank_name"
            :options="bankList"
          ></v-select>
        </vs-col>
      </vs-row>
    </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
// import constants from "../../../constants.json";
// import EventBus from "../eventbus";

export default {
  mounted() {
      this.BankListData();
  },
  data() {
    return {
      KYC: [],
      allDoc : [],
      mandidate : [],
      CibilStatus: "",
      account_type: "",
      account_list: [
        "SAVINGS ACCOUNT",
        "CURRENT ACCOUNT",
        "CREDIT CARD",
        "SAVING-NRE",
        "SAVING-NRO",
        "OTHER",
        "OVER DRAFT"
      ],
      bank_name: "",
      bank_list: [],
      
    };
  },
  components: {
    "v-select": vSelect
  },
  computed: {
    isDisabled: function() {
      return !this.work_pincode;
    },
    bankList: function() {
      let Bankarr = [];
      this.bank_list.forEach((banks) => {
          var banklist = {
                id: banks.id,
                label: banks.bank_name,
              };
              Bankarr.push(banklist);
      })
      return Bankarr;
    }
  },
  watch: {
    bank_name: function(value) {
      if(value.label === value.label) {
        this.bank_document_id = value.id;
        this.bank_list_name = value.label;
        console.log("bank_namd", this.bank_document_id,this.bank_list_name)
      }
    },
    // account_type: function(value) {
    //   console.log("account type", value);
    //   if (this.account_type === "SAVINGS ACCOUNT") {
    //     this.account_type_id = 1;
    //   } else if (this.account_type === "CURRENT ACCOUNT") {
    //     this.account_type_id = 2;
    //   } else if (this.account_type === "CREDIT CARD") {
    //     this.account_type_id = 3;
    //   } else if (this.account_type === "SAVING-NRE") {
    //     this.account_type_id = 4;
    //   } else if (this.account_type === "SAVING-NRO") {
    //     this.account_type_id = 5;
    //   } else if (this.account_type === "OTHER") {
    //     this.account_type_id = 6;
    //   } else if (this.account_type === "OVER DRAFT") {
    //     this.account_type_id = 7;
    //   }
    // }
  },
  methods: {
    BankListData() {
      axios
        .get(
          `https://loans.milesforce.com/api/getEduvanzBanklist`,
          {
            headers: {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            },
          }
        )
        .then((response) => {
          // console.log('bank_list',response);
          this.bank_list = response.data.eduvanz_response.bank_master;
        })
        .catch((error) => {
          this.handleError(error);
        });
    }
  }
}
</script>