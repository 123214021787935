<template>
  <div>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Can-ID</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-input
          size="medium"
          class="w-full"
          v-model="selectedcity"
          disabled
        />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <vs-input size="medium" class="w-full" v-model="select2" disabled />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-input size="medium" v-model="candidateId" class="w-full" disabled />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Cities: -</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <b style="font-size: 15px; color: gray">{{ city }}</b>
      </vs-col>
    </vs-row>
    <vs-row class="gap" v-if="render">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-w="4" vs-align="center">
        <vs-upload
          :limit="1"
          text="Profile Pic"
          action="http://13.232.242.239:5055/fileupload/"
          @on-error="errorUpload"
          @change="changeUpload"
          @on-success="successUpload"
          :data="attachmentKey"
        />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-w="4" vs-align="center">
        <vs-upload
          :limit="1"
          text="Address Proof"
          action="http://13.232.242.239:5055/fileupload/"
          @on-error="errorUpload"
          @change="changeUpload"
          @on-success="successUpload"
          :data="attachmentKeyAddress"
        />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-w="4" vs-align="center">
        <vs-upload
          :limit="1"
          text="Identity Proof"
          action="http://13.232.242.239:5055/fileupload/"
          @on-error="errorUpload"
          @change="changeUpload"
          @on-success="successUpload"
          :data="attachmentKeyIdentity"
        />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Mobile:</vs-col
      >
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <div
          v-for="(mobile, index) in studentDetails.mobile"
          :key="index"
          style="margin-left: 1%"
          class="tooltip"
        >
          <span class="tooltiptext">
            <b>{{mobile.masked_number}}</b>
          </span>
          <vs-button
            color="warning"
            type="gradient"
            icon="local_phone"
            @click="mobilePopup(mobile)"
          >
          </vs-button>
        </div>
      </vs-col>-->
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <!-- <vs-input class="w-full" v-model="mobileview" /> -->
        <b style="font-size: 15px; color: gray">{{ mobileview }}</b>
        <!-- <b style="font-size: 15px; color: gray">{{ mobileview }}</b> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
        <!-- <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="local_phone"
          @click="EmitEvent('add-mobile-number', studentDetails)"
        >ADD</vs-button>-->
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Email:</vs-col
      >
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <div
          v-for="(email, indexemail) in studentDetails.emails"
          :key="indexemail"
          style="margin-left: 1%"
          class="tooltip"
        >
          <span class="tooltiptext">
            <b>{{email.masked_email}}</b>
          </span>
          <vs-button
            style="margin-left: 1%"
            color="warning"
            type="gradient"
            icon="email"
            @click="emailPopup(email)"
          ></vs-button>
        </div>
      </vs-col>-->
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <!-- <vs-input class="w-full" v-model="emailview" /> -->
        <b style="font-size: 15px; color: gray">{{ emailview }}</b>
        <!-- <b style="font-size: 15px; color: gray">{{ emailview }}</b> -->
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
        <!-- <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="email"
          @click="EmitEvent('add-email')"
        >ADD</vs-button>-->
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Address Line1:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-textarea v-model="address1" />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Address Line2:</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-textarea v-model="address2" />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray;margin-left: -1.5%;"
        >State :</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-select class="selectExample" placeholder="State" v-model="QBstate">
          <vs-select-item
            :key="index"
            :value="item.code"
            :text="item.name"
            v-for="(item, index) in QBStateList"
          />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >City :</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="city"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Country :</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="country" readonly />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        >Pincode :</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="pincode" />
      </vs-col>
    </vs-row> -->
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        ><h5><b>Billing Address</b></h5></vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Flat, House no., Building, Company, Apartment:</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="billingaddress.street"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Town/City :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="billingaddress.city"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3.82"
        style="font-size: 15px; color: gray"
        >State / Province / Region :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-select class="selectExample" placeholder="State" v-model="billingaddress.state">
          <vs-select-item
            :key="index"
            :value="item.name"
            :text="item.name"
            v-for="(item, index) in QBStateList"
          />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >PIN code :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="billingaddress.zipcode" @input="restrictInput" maxlength="6" pattern="[0-9]{6,}"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        ><h5><b>Shipping Address</b></h5></vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
        ><vs-checkbox @change="copyBilling" id="sSame" v-model="sSame"></vs-checkbox></vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="2"
      ></vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        Shipping same as Billing
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Flat, House no., Building, Company, Apartment :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="shippingaddress.street"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Town/City :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="shippingaddress.city"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="3.82"
        style="font-size: 15px; color: gray"
        >State / Province / Region :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-select class="selectExample" placeholder="State" v-model="shippingaddress.state">
          <vs-select-item
            :key="index"
            :value="item.name"
            :text="item.name"
            v-for="(item, index) in QBStateList"
          />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >PIN code :</vs-col
      >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
        <vs-input v-model="shippingaddress.zipcode" @input="restrictInput" maxlength="6" pattern="[0-9]{6,}"/>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Company:</vs-col
      >
      <!-- <edit-save :studentDetails="studentDetails"/> -->
      <vs-col
        v-if="companyEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ company }}</b>
      </vs-col>
      <vs-col
        v-else-if="companyEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="company" />
      </vs-col>
      <vs-col
        v-if="companyEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <!-- <vs-button radius color="warning" type="border" icon="create" @click="companyeditfunc"></vs-button> -->
      </vs-col>
      <vs-col
        v-if="companyEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="done_outline"
          @click="companyeditfunc"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Experience:</vs-col
      >
      <vs-col
        v-if="exEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b>{{ experience }}</b>
      </vs-col>
      <vs-col
        v-else-if="exEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="experience" />
      </vs-col>
      <vs-col
        v-if="exEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <!-- <vs-button radius color="warning" type="border" icon="create" @click="exeditfunc"></vs-button> -->
      </vs-col>
      <vs-col
        v-if="exEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <!-- <vs-button radius color="warning" type="border" icon="done_outline" @click="exeditfunc"></vs-button> -->
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="font-size: 15px; color: gray"
        >Designation:</vs-col
      >
      <vs-col
        v-if="designationEdit === false"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <b style="margin: 10px">{{ designation }}</b>
      </vs-col>
      <vs-col
        v-else-if="designationEdit === true"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="8"
        style="font-size: 15px; color: gray"
      >
        <vs-input icon="edit" v-model="designation" />
      </vs-col>
      <vs-col
        v-if="designationEditbutton === true"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <!-- <vs-button radius color="warning" type="border" icon="create" @click="designationeditfunc"></vs-button> -->
      </vs-col>
      <vs-col
        v-if="designationEditbutton === false"
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="2"
        style="font-size: 15px; color: gray"
      >
        <vs-button
          radius
          color="warning"
          type="border"
          icon="done_outline"
          @click="designationeditfunc"
        ></vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 15px">Educational Tags:</p>
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <vs-select class="selectExample" v-model="select1">
          <vs-select-item :key="index" :text="item.text" v-for="(item,index) in options1" />
        </vs-select>
      </vs-col>-->
    </vs-row>
    <vs-row class="gap">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-chip
          @click="remove(chip)"
          color="primary"
          v-for="chip in select1"
          :key="chip.index"
        >
          <b>{{ chip }}</b>
        </vs-chip>
      </vs-col>
    </vs-row>
    <!-- <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <p style="color: gray; font-size: 13px">Need For Bridge Course:</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-radio v-model="radios" vs-value="yes">YES</vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-radio v-model="radios" vs-value="no">NO</vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-radio v-model="radios" vs-value="na">N/A</vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <vs-radio v-model="radios" vs-value="taken">TAKEN</vs-radio>
      </vs-col>
    </vs-row>-->
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import axios from "axios";
export default {
  props: {
    studentDetails: {
      required: true,
    },
  },
  components: {
    vSelect,
  },
  watch: {
    cities_selected: function (value) {
      // console.log(value);
      this.titlebatch = value.value;
    },
    QBstate(value){
      console.log("State",value)
    }
  },
  data() {
    return {
      titlebatch: "",
      navTitleName: "",
      selectedcity: "",
      radios: "no",
      QBstate:"",
      country: "India",
      address1:"",
      address2:"",
      pincode:"",
      address: {},
      shipping_address: {},
      selectcity: this.city,
      sSame:false,
      billingaddress : {
        street : "",
        city : "",
        state : "",
        zipcode: ""
      },
      shippingaddress:{
        street : "",
        city : "",
        state : "",
        zipcode: ""
      },
      QBStateList: [
        { name: "ANDAMAN AND NICOBAR ISLANDS", code: 35 },
        { name: "ANDHRA PRADESH", code: 37 },
        { name: "ARUNACHAL_PRADESH", code: 12 },
        { name: "ASSAM", code: "18" },
        { name: "BIHAR", code: "10" },
        { name: "CHANDIGARH", code: "04" },
        { name: "CHHATTISGARH", code: "22" },
        { name: "DADRA_AND_NAGAR_HAVELI", code: "26" },
        { name: "DAMAN_AND_DIU", code: "25" },
        { name: "DELHI", code: "07" },
        { name: "GOA", code: "30" },
        { name: "GUJARAT", code: "24" },
        { name: "HARYANA", code: "06" },
        { name: "HIMACHAL_PRADESH", code: "02" },
        { name: "JAMMU_AND_KASHMIR", code: "01" },
        { name: "JHARKHAND", code: "20" },
        { name: "KARNATAKA", code: "29" },
        { name: "KERALA", code: "32" },
        { name: "LAKSHADWEEP", code: "31" },
        { name: "MADHYA_PRADESH", code: "23" },
        { name: "MAHARASHTRA", code: "27" },
        { name: "MANIPUR", code: "14" },
        { name: "MEGHALAYA", code: "17" },
        { name: "MIZORAM", code: "15" },
        { name: "NAGALAND", code: "13" },
        { name: "ODISHA", code: "21" },
        { name: "PONDICHERRY", code: "34" },
        { name: "PUNJAB", code: "03" },
        { name: "RAJASTHAN", code: "08" },
        { name: "SIKKIM", code: "11" },
        { name: "TAMIL_NADU", code: "33" },
        { name: "TELANGANA", code: "36" },
        { name: "TRIPURA", code: "16" },
        { name: "UTTAR_PRADESH", code: "09" },
        { name: "UTTARAKHAND", code: "05" },
        { name: "WEST_BENGAL", code: "19" },
        { name: "OUTSIDE_INDIA", code: "97" },
      ],
      options1: [
        { text: "", value: 0 },
        { text: "B.Com", value: 0 },
        { text: "M.Com", value: 1 },
        { text: "MBA", value: 2 },
        { text: "CA", value: 3 },
        { text: "CWA", value: 4 },
        { text: "CS", value: 5 },
        { text: "BMS", value: 6 },
        { text: "BBA", value: 7 },
        { text: "PGD", value: 8 },
        { text: "LLB", value: 9 },
        { text: "B.Tech", value: 10 },
        { text: "B.Sc", value: 11 },
        { text: "BA", value: 12 },
        { text: "CA-Inter", value: 13 },
        { text: "CWA-Inter", value: 14 },
        { text: "CS-Inter", value: 15 },
        { text: "PG-Pursuing", value: 16 },
        { text: "BBA-Pursuing", value: 17 },
      ],
      cities: [
        { text: "Mumbai", value: "M" },
        { text: "Hyderbad", value: "H" },
        { text: "Bangalore", value: "B" },
        { text: "Delhi", value: "D" },
        { text: "Pune", value: "P" },
        { text: "Ahmedabad", value: "A" },
        { text: "Chennai", value: "C" },
        { text: "Noida", value: "N" },
      ],
      cities_selected: "H",
      select3: 2,
      course: ["CPA", "CMA", "DA"],
      select2: "CPA",
      candidateId: "",
      eligibilityOptions: [
        { text: "Clear", value: 1 },
        { text: "Check", value: 2 },
        { text: "PGDPA", value: 3 },
        { text: "Eligibility", value: 4 },
      ],
      companyEdit: false,
      companyEditbutton: true,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      company: "",
      experience: "",
      designation: "",
      additionalInfo: "",
      eligibility: "",
      select1: [],
      url: "",
      engagementsload: [],
      mobileview: "",
      uploaded: false,
      sendDatails: "",
      emailview: "",
      attachmentKey: null,
      attachmentKeyAddress: null,
      attachmentKeyIdentity: null,
      saveFile: [],
      render: false,
    };
  },
  destroyed() {
    console.log("closed");
  },
  mounted() {
    EventBus.$on("renderOff", () => {
      this.render = false;
    });
    EventBus.$on("enrollment-opened", (payload) => {
      this.clear();
      this.render = true;
      this.getAttachmentKey();
      this.uploaded = false;
      this.saveFile = [];
      this.address = "";
      this.shipping_address = "";
      this.mobileview = payload.mobileString;
      this.emailview = payload.emailString;
      this.sendDatails = payload;
      this.company = payload.company;
      this.designation = payload.designation;
      this.experience = payload.experience;
      this.selectedcity = payload.city.charAt(0);
      this.city = payload.city;
      this.getSpoc();
      // console.log("clear", this.clear());
      // let loggedin = this.$store.state.Spoc_Data.logged_in_user_id;
      // let city = "";
      // this.$store.state.Spoc_Data.spocs.forEach(spoc => {
      //   if (spoc.id === response.data.logged_in_user_id) {
      //     city = spoc.city;
      //   }
      // });
      // this.$store.state.Spoc_Data.spocs.forEach(spoc => {
      //   if (spoc.id === loggedin) {
      //     city = spoc.city;
      //   }
      // });

      // this.city = city;
      this.selec1 = "";
      if (payload.education_tags !== null) {
        this.select1 = payload.education_tags.split(",");
      }
      this.candidateId = payload.can_id;
    });
    EventBus.$on("changeCourse", (payload) => {
      this.select2 = payload;
    });
    EventBus.$on("submittingForm", () => {
      // console.log("key", key)
      // this.address = {
      //   address1: this.address1,
      //   address2: this.address2,
      //   city: this.city,
      //   QBstate: this.QBstate,
      //   pincode: this.pincode,
      //   country: this.country,
      //   street : null,
      //   city : null,
      //   state : null,
      //   zipcode:null
      // }
      if(this.shippingaddress.state == '' && this.shippingaddress.zipcode == '' ){
        this.$vs.notify({
          title: "Please fill 'State and Pincode'",
          text: "",
          color: "danger",
        });
      }else if(this.shippingaddress.state == ''){
        this.$vs.notify({
          title: "Please select 'State'",
          text: "",
          color: "danger",
        });
      }else if(this.shippingaddress.zipcode == '' ){
        this.$vs.notify({
          title: "Please fill 'Pincode'",
          text: "",
          color: "danger",
        });
      }else if(this.shippingaddress.zipcode.toString().length < 6){
          this.$vs.notify({
          title: "Pincode should be 6 digits",
          text: "",
          color: "danger",
        });
      }else{
      let shipping_state = ''
      let shipping_pincode = ''
      if(Object.values(this.billingaddress).join(",") === ",,," || Object.values(this.billingaddress).join(",") === "" || Object.values(this.shippingaddress).join(",") === ",,," || Object.values(this.shippingaddress).join(",") === "") {
        this.address = "";
        this.shipping_address = "";
        shipping_state = this.shippingaddress.state
        shipping_pincode = this.shippingaddress.zipcode

      } else {
        this.address = Object.values(this.billingaddress).join(",");
        this.shipping_address = Object.values(this.shippingaddress).join(",");
        shipping_state = this.shippingaddress.state
        shipping_pincode = this.shippingaddress.zipcode
      }
      // this.shipping_address = Object.values(this.shippingaddress).join(",");
      let newcanid = `${this.selectedcity}-${this.select2}-${this.candidateId}`;
      let profilePic = this.attachmentKey.key;
      let addressProof = this.attachmentKeyAddress.key;
      let identityproof = this.attachmentKeyIdentity.key;
      let addressbar = this.address;
      let shippingaddressbar = this.shipping_address;
      let needBridge = this.radios;
      if (!this.uploaded) {
        profilePic = "";
      }
      if (addressbar === "") {
      this.$vs.notify({
        title: "Enter an billing address",
        text: "Enter an billing address to continue",
        color: "warning"
      });
      }else if(shippingaddressbar.length > 190){
        console.log("shipping address length", shippingaddressbar );
        this.$vs.notify({
          title: "Shipping address should be less than 190 characters",
          text: "",
          color: "danger",
        });
      }else{
      EventBus.$emit("detailsToSend", [
        this.sendDatails,
        newcanid,
        profilePic,
        addressbar,
        needBridge,
        addressProof,
        identityproof,
        shippingaddressbar,
        shipping_state,
        shipping_pincode,
      ]);
      }
      }
    });
  },
  methods: {
    restrictInput() {
      this.billingaddress.zipcode = this.billingaddress.zipcode.replace(/[^0-9]/g, '');
      this.shippingaddress.zipcode = this.shippingaddress.zipcode.replace(/[^0-9]/g, '');
    },
    clear() {
      this.billingaddress.street = "";
      this.billingaddress.city = "";
      this.billingaddress.state = "";
      this.billingaddress.zipcode = "";
      this.shippingaddress.street = "";
      this.shippingaddress.city = "";
      this.shippingaddress.state = "";
      this.shippingaddress.zipcode = "";
      this.sSame = "";
    },
    copyBilling() {
      if(this.sSame) {
        for(let key in this.billingaddress) {
          this.shippingaddress[key] = this.billingaddress[key];
        }
      }
    },
    getSpoc() {
      // console.log("from add lead");
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.selectSpocCity(response);
          console.log(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    selectSpocCity(response) {
      // console.log(response);
      response.data.spocs.forEach((spoc) => {
        if (spoc.id === response.data.logged_in_user_id) {
          // this.selectedcity = spoc.city.charAt(0);
          this.city = spoc.city;
        }
      });
    },
    getAttachmentKey() {
      const url = `${constants.SERVER_API}setKeysForEnrollment`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.attachmentKey = []
          // console.log(response);
          this.attachmentKey = { key: response.data.pic_key };
          this.attachmentKeyAddress = { key: response.data.address_proof_key };
          this.attachmentKeyIdentity = {
            key: response.data.photo_id_proof_key,
          };
        })
        .catch((error) => {
          // if (error.response) {
          //   this.ReDirect_to_login();
          // }
          this.handleError(error);
        });
    },
    async mobilePopup(mobile) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-mobile-options-popup", [
            response.data,
            this.studentDetails,
            mobile,
          ]);
        })
        .catch((error) => {
          this.handleError(error);
          // if (error.response) {
          //   // console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
        });
    },
    async emailPopup(email) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-email-options-popup", [
            response.data,
            this.studentDetails,
            email,
          ]);
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.EmitEvent("close-MWB-popup");
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    successUpload(e) {
      this.uploaded = true;
      console.log("success", e);
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      this.saveFile = files;
      console.log("result", this.saveFile);
    },
    deleteFile(e) {
      this.saveFile.splice(e, 1);
      console.log("delete file", this.saveFile.splice(e, 1));
    },
    // remove(item) {
    //   this.select1.splice(this.select1.indexOf(item), 1);
    // },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
  },
};
</script>

<style>