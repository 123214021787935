
<template>
    <vs-tabs>
        <vs-tab label="Chat History">
            <div class="con-tab-ejemplo">
                <chat-history :studentDetails="this.chatHistoryData"></chat-history>
            </div>
        </vs-tab>
        <vs-tab label="Journey">
            <div class="con-tab-ejemplo">
                <journey-section :studentDetails="studentDetails"></journey-section>
            </div>
        </vs-tab>
        <vs-tab label="Call History">
            <div class="con-tab-ejemplo">
                <call-history :studentDetails="this.chatHistoryData"></call-history>
            </div>
        </vs-tab>
        <vs-tab label="Play">
            <div class="con-tab-ejemplo">
                <play-section :studentDetails="this.play"></play-section>
            </div>
        </vs-tab>
        <vs-tab label="Lead Transfer History">
            <div class="con-tab-ejemplo">
                <lead-transfer-history :studentDetails="this.leadTransferHistoryData"></lead-transfer-history>
            </div>
        </vs-tab>
    </vs-tabs>
</template>

<script>
import ChatHistory from "../../History/chathistory";
import CallHistory from "../../History/callhistorysection";
import leadTransferHistory from "./LthSection.vue";
import Play from "./play";
import JourneySection from "./JourneySection.vue";
// import axios from "axios";
// import constants from "../../../../constants.json";
// import EventBus from "../eventbus.js";
export default {
    props: {
        studentDetails: {
            required: true,
            type: Object,
        },
    },
    components: {
        "chat-history": ChatHistory,
        "call-history": CallHistory,
        "lead-transfer-history": leadTransferHistory,
        "play-section": Play,
        "journey-section": JourneySection
    },
    data() {
        return {
            ActiveNavBarTab: "ABOUT",
            chatHistoryData: "",
            leadTransferHistoryData: "",
            play: ""
        };
    },
    mounted() {

        this.chatHistoryData = this.studentDetails;
        this.leadTransferHistoryData = this.studentDetails;
        this.play = this.studentDetails;

        console.log(this.chatHistoryData);
    },
    methods: {

    },
};
</script>