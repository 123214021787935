<template>
  <div>
    <vs-row v-for="item in chatHistoryData" :key="item.id" style="height: fit-content">
      <vs-row style="margin-bottom: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="7"
        >
          <vs-button :color="getColor(item)" type="filled">
            <b>{{ formatDate(item.time) }}</b
            ><br />
          </vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
          <b>- {{ item.spoc_name }} - {{ item.team }} - {{ item.city_classification }}</b>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <vs-card>
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
                ><h6>
                  <b
                    >
                    {{
                      hidePhoneNumber(item.phone_number)
                    }}
                    <vs-icon
                      icon="forward"
                      color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"
                    ></vs-icon>
                    {{
                      item.directory
                    }}
                    <vs-icon
                      icon="forward"
                      color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"
                    ></vs-icon>
                    {{ timeConvert(item.call_duration) }}
                  </b>
                </h6>
                </vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-w="5"
              >
              <b>Posted at : {{ formatDate(item.created_at) }}</b>

              </vs-col>
            </vs-row>

          </vs-card>
        </vs-col>
      </vs-row>
    </vs-row>
    <vs-row style="margin-top: 3%">
        <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-w="12"
        >
            <vs-chip
                color="primary"
                style="margin-right: 2%"
            >
                <b>{{ countsleads }}</b>
            </vs-chip>
            <vs-pagination
                :total="tablelinks"
                v-model="currentTablePage"
            ></vs-pagination>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    //   this.analyzePagination(1);
    this.getCallHistory(1);
    console.log("student details", this.studentDetails);
  },
  data() {
    return {
      chatHistoryData: [],
      countsleads: "",
      tablelinks: 0,
      currentTablePage: 1,
    };
  },
  watch: {
    currentTablePage(val) {
        console.log("click", val)
        this.analyzePagination(val);
    },
  },
  methods: {
    hidePhoneNumber(value){
    //  console.log("val",value)
     var number = value;
     var mobile = ''
     if(number.slice(0,1)=='+'){
       mobile = number.substring(3)
       var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
       return phone;
     }else{
       return number.replace(number.substring(0,6), 'XXXXXX');
     }
    },
    analyzePagination(val) {
        if (this.currentTablePage === 1) {
            this.getCallHistory(1);
        } else {
             console.log("current page", val);
            this.getCallHistory(val);
        }
    },
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY h:mm A");
      return formttedDate;
    },
    getColor(fetch) {
      // console.log(fetch);
      // return "primary";
      switch (fetch.added_by_team) {
        case "ac1":
          return "dark";
        default:
          return "primary";
      }
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    getCallHistory(val) {
      let page = "";
      page = val;
      console.log("person_id", val);
      let url = `${constants.SERVER_API}getCallLogHistory?page=${page}&person_id=${this.studentDetails.mwb.person_id}`;
      axios
        .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.userAccessToken}`,
        },
        })
        .then((response) => {
          console.log(response.data.call_logs);
          this.chatHistoryData = response.data.call_logs.data;
          this.currentTablePage = response.data.call_logs.current_page;
          this.tablelinks = response.data.call_logs.last_page;
          this.countsleads = response.data.call_logs.total;
          console.log("lth data", this.chatHistoryData);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
