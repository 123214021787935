<template>
  <vs-popup
    v-if="open"
    class="imagepopupwidth"
    :id="'loanShow'"
    :title="''"
    :active.sync="open"
    style="z-index:53006;"
  >
    <!-- <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"> -->
        <!-- <vs-image :src="image_url" /> -->
        <!-- <div class="embed-responsive embed-responsive-21by9">
          <iframe class="embed-responsive embed-responsive-21by9" :src="image_url"></iframe>
        </div> -->
        <iframe
          :src="image_url"
          allowfullscreen
          scrolling="auto"
          height="100%"
          width="100%"
        ></iframe>
        <!-- <iframe style="margin: auto; width: 70%; padding: 10px;" :src="image_url"></iframe> -->
      <!-- </vs-col>
      <div
        slot="media"
        v-if="image_url.includes('.pdf')"
        style="text-align:center"
      >
        <vs-icon icon="polymer" size="75px" color="#BC1254"></vs-icon>
      </div>
    </vs-row> -->
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
export default {
  // prop: ["userdata"],
  mounted() {
    EventBus.$on("open-ImagePopup", (imagedata) => {
      console.log("image", imagedata);
      this.image_url = imagedata.data;
      this.open = true;
    });
    // document.getElementById("loanShow").children[1].style.marginBottom =
    //   "0%";
    // document.getElementById("loanShow").children[1].style.width = "50%";
  },
  data() {
    return {
      open: false,
      image_url: "",
    };
  },
  methods: {},
};
</script>

<style>
.imagepopupwidth .vs-popup {
  width: 100%;
  height: 80%;
  margin-bottom: 0%;
}
.con-vs-popup .vs-popup--content {
    height: 91%;
}
</style>
