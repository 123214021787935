<template>
  <div>
    <div class="boxed">
        <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <b style="color: gray; font-size: 12px; margin-right: 3%"
            >Enrollment Date:</b
          >
          <vs-chip color="dark">
            <b>{{ getDate() }}</b>
          </vs-chip>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <b style="color: gray; font-size: 12px; margin-right: 3%">Batch:</b>
          <vs-chip color="dark">
            <b>{{ getBatch() }}</b>
          </vs-chip>
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="2"
          v-if="getrole('cm4')"
        >
          <vs-button
            radius
            color="warning"
            type="border"
            icon="chat"
            @click="editBatch()"
          ></vs-button>
        </vs-col> -->
      </vs-row>
    </div>
    <div class="boxed mt-5">
      <vs-row class="gap">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="12"
        >
          <div class="boxed" style="width: 50%; margin-right: 2%">
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Billing Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="billingaddress"
                  :disabled="billingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.billingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editBillAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.billingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    saveAdd('mailing_address', getEnrolId(), editBillAdd())
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
          <div class="boxed" style="width: 50%">
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size: 15px; color: gray"
                ><h5><b>Shipping Address</b></h5></vs-col
              >
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
                ><vs-textarea
                  v-model="shippingaddress"
                  :disabled="shippingAddressDisable"
              /></vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                v-if="this.shippingAddressDisable === true"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="5"
                ><vs-button type="gradient" color="warning" @click="editShipAdd"
                  >Edit</vs-button
                ></vs-col
              >
              <vs-col
                v-if="this.shippingAddressDisable === false"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
                ><vs-button
                  type="gradient"
                  color="success"
                  @click="
                    shiipingSaveAdd(
                      'shipping_address',
                      getEnrolId(),
                      editBillAdd()
                    )
                  "
                  >Save</vs-button
                ></vs-col
              >
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import constants from "../../../../constants.json";
import axios from "axios";
import EventBus from "../../eventbus";
export default {
    props: {
    studentDetails: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getBillAddress();
    this.getShipAddress();
    console.log("student details CPAAA",this.studentDetails);
  },
  data(){
    return {
        shippingaddress: "",
        shippingAddressDisable: true,
        billingAddressDisable: true,
        billingaddress: "",
    }
  },
  methods: {
     getBatch() {
      let batch = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.cfa_selected_level === "Level 2") {
          batch = enrollment.batch;
        }
      });
      return batch;
    },
    getBillAddress() {
      this.billingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((bill) => {
        console.log("mail", bill);
        if (bill.cfa_selected_level === "Level 2") {
          this.billingaddress = bill.address;
          console.log("mail", this.billingaddress);
        }
      });
      return this.billingaddress;
    },
    getDate() {
      let date = "";
      this.studentDetails.mwb.enrollments.forEach((enrollment) => {
        if (enrollment.cfa_selected_level === "Level 2") {
          date = enrollment.enrollment_date;
        }
      });
      return this.unixTimestampConvert(date);
    },
    getEnrolId() {
      this.studentDetails.mwb.enrollments.forEach((en) => {
        if (en.cfa_selected_level === "Level 2") {
          this.enrollId = en.id;
        }
      });
      return this.enrollId;
    },
    editShipAdd() {
      this.shippingAddressDisable = false;
    },
    getShipAddress() {
      this.shippingAddressDisable = true;
      this.studentDetails.mwb.enrollments.forEach((ship) => {
        if (ship.cfa_selected_level === "Level 2") {
          this.shippingaddress = ship.shipping_address;
        }
      });
      return this.shippingaddress;
    },
    editBillAdd() {
      this.billingAddressDisable = false;
    },
    shiipingSaveAdd(address_type, id) {
      this.billingAddressDisable = true;
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.shippingaddress,
      };
      console.log("data", params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.shippingAddressDisable = true;
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    saveAdd(address_type, id) {
      let url = `${constants.SERVER_API}changeAddress`;
      let params = {
        enrollment_id: id,
        address_type: address_type,
        new_address: this.billingaddress,
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          EventBus.$emit("reload-bigpopup-data");
          this.handleNotification(response);
          this.billingAddressDisable = true;
          // this.shippingAddressDisable = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  }
}
</script>

<style>
.update_bridge_levels_button {
  margin-top: 4px;
}
.bridge_update_button {
  direction: rtl;
}
.padding {
  padding: 5px;
}
</style>