<template lang="html">
  <div>
    <p style="padding-left: 4%; font-size: 13px; color: red">
      *In case of Co-applicant Loan, please fill the Co-applicant details
      instead of the Candidate details.
    </p>
  <vs-collapse
    v-for="item in this.liquiloan_data"
    :key="item.application_id"
    type="margin"
  >
      <vs-collapse-item>
        <div slot="header">
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="9"
            >
              <h5 class="header">
                {{ item.can_name }} : {{ item.application_id }}
              </h5>
            </vs-col>
          </vs-row>
        </div>
        <vs-divider></vs-divider>
        <div>
          <vs-row v-if="!item.hasOwnProperty('ckyc_no')">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-button @click="OpenCKycPopup(item)">
                Check CKYC
              </vs-button>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
              vs-w="6"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Applicant ID:</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.applicant_id }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Application ID :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.application_id }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
              vs-w="6"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px">
                    <span>Application Status :</span>
                  </p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.application_status }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Full Name :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.can_name }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Gender :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.gender }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Date of Birth :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">{{ item.dob }}</p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Email :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.email }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Contact Number:</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.contact_number }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Loan Type :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.loan_type }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Amount :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">{{ item.amount }}</p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px">
                    <span>Course :</span>
                  </p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.course }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>PAN :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">{{ item.pan }}</p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Tenure :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.tenure }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Adhaar Number :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.aadhaar_number }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Full Address :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.full_address }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Pin Code :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.pincode }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>City :</span></p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">{{ item.city }}</p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>State :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">{{ item.state }}</p>
              </vs-col>
            </vs-col>
          </vs-row>

          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-row type="flex" style="margin-top: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="5"
                >
                  <p style="font-size: 13px"><span>Occupation :</span></p>
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="7"
                >
                  <p style="font-size: 13px">
                    {{ item.occupation }}
                  </p>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Name Of Comapny :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.name_of_company }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>

          <vs-row vs-w="12" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px"><span>Monthly income :</span></p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.monthly_income }}
                </p>
              </vs-col>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="5"
              >
                <p style="font-size: 13px">
                  <span>Alternate Contact Number :</span>
                </p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="7"
              >
                <p style="font-size: 13px">
                  {{ item.mobile_number }}
                </p>
              </vs-col>
            </vs-col>
          </vs-row>
        </div>
        <vs-popup
          class="holamundo"
          title="Check CKYC"
          :active.sync="open_check_ckyc"
        >
          <!-- <vs-divider></vs-divider> -->
          <!-- <h5 style="padding-left: 3%">Check Ckyc :</h5> -->
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Select ID Proofs:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              <vs-select
                width="200px"
                label="Select ID proof"
                v-model="selected_id_proof"
              >
                <vs-select-item
                  :key="index"
                  :value="item.id_type"
                  :text="item.id_type_name"
                  v-for="(item, index) in id_proofs_list"
                />
              </vs-select>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">ID Number:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="6">
              <vs-input
                type="text"
                :maxlength="10"
                placeholder="ID Number"
                v-model="id_number"
              ></vs-input>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Select auth factor type:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              <vs-select
                width="200px"
                label="Select Auth Factor Type"
                v-model="selected_auth_factor_type"
              >
                <vs-select-item
                  :key="index"
                  :value="item.auth_factor_type"
                  :text="item.auth_factor_type_name"
                  v-for="(item, index) in auth_factor_list"
                />
              </vs-select>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Auth factor value:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="6">
              <vs-input
                :maxlength="10"
                placeholder="Auth Factor"
                v-model="auth_factor"
              ></vs-input>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-button
                color="primary"
                @click="checkCkycStatus()"
                type="filled"
                >Check</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-popup>
        <vs-divider></vs-divider>
        <div v-if="item.hasOwnProperty('ckyc_no')">
          <h6 style="margin-left: 3%">CKYC Details :</h6>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">CKYC Number:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              {{ item.ckyc_no }}
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Selected ID:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              {{ getSelectedtype(item.id_type) }}
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Selected ID Number:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              {{ item.id_no }}
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Selected Auth Type:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              {{ getSelectedtype(item.auth_factor_type) }}
            </vs-col>
          </vs-row>
          <vs-row vs-w="12" style="margin-top: 2%; margin-left: 1%">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <p style="font-size: 13px">Selected Auth Factor:</p>
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" vs-w="8">
              {{ item.auth_factor }}
            </vs-col>
          </vs-row>
        </div>
      </vs-collapse-item>
  </vs-collapse>
  </div>
</template>
<script>
import axios from "axios";
// import _ from "lodash";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
    // show_ckyc_check: false,
    // show_ckyc_data: false,
    // ckyc_data:{
    //     required: true,
    //     type: [Object,Array,String]
    // },
    // show_ckyc_data:{
    //     required: true,
    //     type: Boolean,
    // },
    // show_ckyc_check:{
    //     required: true,
    //     type: Boolean,
    // },
    // created_application_data: {
    //   required: true,
    //   type: [Object, Array, String],
    // },
  },
  data() {
    return {
      open_check_ckyc: false,
      liquiloan_data: [],
      created_application_data: {},
      auth_factor: "",
      selected_auth_factor_type: "03",
      auth_factor_list: [
        { auth_factor_type: "01", auth_factor_type_name: "DOB" },
        {
          auth_factor_type: "02",
          auth_factor_type_name: "Pincode and Year of Birth",
        },
        { auth_factor_type: "03", auth_factor_type_name: "Mobile number" },
      ],
      id_number: "",
      id_proofs_list: [{ id_type: "C", id_type_name: "PAN" }],
      selected_id_proof: "C",
      liqui_loans: {
        pan: "",
        full_name: "",
        gender: "",
        monthly_income: null,
        email: "",
        loan_type: "",
        amount: null,
        tenure: null,
        adhaar_number: null,
        pincode: null,
        contact_number: null,
        dob: "",
        state: "",
        city: "",
        full_address: "",
        name_of_company: "",
        occupation: "",
        mobile_number: null,
        mwb_id: "",
        identity: "",
        scheme_id: "",
        scheme_code: "",
        course: "",
      },
      loader: false,
      ckyc_popup_object: {},
    };
  },
  mounted() {
    // console.log("student deatails in applyloan", this.studentDetails);
    // for (let i = 0; i < this.studentDetails.emails.length; i++) {
    //   const element = this.studentDetails.emails[i];
    //   if (element.pri_mary == 1) {
    //     this.liqui_loans.email = element.email;
    // } else {
    //     this.liqui_loans.email = this.studentDetails.emails[0].email;
    //   }
    // }
    // for (let i = 0; i < this.studentDetails.mobiles.length; i++) {
    //   const element = this.studentDetails.mobiles[i];
    //   if (element.pri_mary == 1) {
    //     this.liqui_loans.mobile_number = element.phone_number;
    //   } else {
    //     this.liqui_loans.mobile_number =
    //       this.studentDetails.mobiles[0].phone_number;
    //   }
    // }
    // this.liqui_loans.city = this.studentDetails.mwb.city;
    this.checkLoanApplication();
  },
  watch: {
    show_ckyc_check(val) {
      console.log("show_ckyc_check23123", val);
    },
    selected_scheme(val) {
      console.log(val, "selected_scheme");
      for (let i = 0; i < this.scheme_data.length; i++) {
        const scheme_item = this.scheme_data[i];
        if (scheme_item.scheme_id == val) {
          console.log("retretr2213", scheme_item.scheme_id, val, scheme_item);
          this.liqui_loans.scheme_id = scheme_item.scheme_id;
          this.liqui_loans.scheme_code = scheme_item.scheme_code;
        }
      }
      console.log(this.liqui_loans, "liqui_loans object");
    },
    loader(val) {
      if (val == true) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    },
  },
  methods: {
    OpenCKycPopup(item){
      this.ckyc_popup_object = {};
      this.open_check_ckyc = true;
      this.ckyc_popup_object = item;
    },
    checkLoanApplication() {
      this.$vs.loading();
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        identity: this.studentDetails.mwb.identity,
      };
      // let url = `https://liquiloans.milesforce.com/api/checkLiveLoanApplication`;
      let url = `https://liquiloans.milesforce.com/api/checkLoanApplicationMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("created_application_data", response);
          if (response.data.message === "Application created") {
            this.show_created_application_data = true;
            this.created_application_data = response.data.data;
            this.getCkycData();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getSelectedtype(value) {
      if (value == "01") {
        return "DOB";
      }
      if (value == "02") {
        return "Pincode and Year of Birth";
      }
      if (value == "03") {
        return "Mobile number";
      }
      if (value == "C") {
        return "PAN";
      }
    },
    checkCkycStatus() {
      this.$vs.loading();
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
        id_type: this.selected_id_proof,
        id_no: this.id_number,
        auth_factor_type: this.selected_auth_factor_type,
        auth_factor: this.auth_factor,
        course: this.ckyc_popup_object.course,
        identity: this.studentDetails.mwb.identity,
        applicant_id: this.ckyc_popup_object.applicant_id
      };
      console.log("object", getParams);
      //   let url = `https://liquiloans.milesforce.com/api/checkLiveCkycStatus`;
      let url = `https://liquiloans.milesforce.com/api/checkLiveCkycStatusMultiple`;
      axios
        .post(url, getParams, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.$vs.loading.close();

          console.log("checkCkycStatus response", response);
          if (response.data.status === true) {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
            let ckyc_no = response.data.data.PERSONAL_DETAILS.CKYC_NO;
            let message = response.data.message;
            this.CKYC_NO = ckyc_no;
            this.StoreCkycDetails(ckyc_no, message);

            // this.show_ckyc_data = true;
            // this.show_ckyc_check = false;
          } else {
            this.$vs.notify({
              title: "Error :",
              text: response.data.message,
              color: "danger",
              fixed: true,
            });
          }
          this.open_check_ckyc = false;
          // if(response.data.status === false){
          //   this.$vs.notify({
          //     text: response.data.message,
          //     color: "danger",
          //   });
          // }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    StoreCkycDetails(CKYC_NO, message) {
      let getParams = {
        mwb_id: this.ckyc_popup_object.mwb_id,
        application_id: this.ckyc_popup_object.application_id,
        id_type: this.selected_id_proof,
        id_no: this.id_number,
        auth_factor_type: this.selected_auth_factor_type,
        auth_factor: this.auth_factor,
        CKYC_id: CKYC_NO,
        message: message,
        miles_application_id: this.ckyc_popup_object.id,
      };

      // let url = `https://liquiloans.milesforce.com/api/saveLiveCkycData`;
      let url = `https://liquiloans.milesforce.com/api/saveLiveCkycDataMultiple`;
      axios
        .post(url, getParams, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // if(response.data == 'Ckyc data stored successfully'){
          this.checkLoanApplication();
          this.$vs.loading.close();
          //  this.show_ckyc_data = true;
          console.log(response);
          // } else {
          // this.$vs.loading.close();
          // this.show_ckyc_data = false;
          // }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCkycData() {
      this.$vs.loading();
      this.liquiloan_data = [];
      let getParams = {
        mwb_id: this.studentDetails.mwb.id,
      };
      // let url = `https://liquiloans.milesforce.com/api/getLiveCkycData`;
      let url = `https://liquiloans.milesforce.com/api/getLiveCkycDataMultiple`;
      axios
        .get(url, {
          params: getParams,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.$vs.loading.close();
          this.ckyc_data = response.data;
          let ckyc = this.ckyc_data;
          this.liquiloan_data = this.created_application_data.map((v) => ({
            ...v,
            ...ckyc.find(
              (sp) => sp.liquiloans_application_id === v.application_id
            ),
          }));
          console.log("merged data", this.liquiloan_data);
          // if(this.ckyc_data.message === "CKyc fetched successfully"){
          //   // this.show_ckyc_data = true;
          // } else{
          //   // this.show_ckyc_data = false;
          //   // this.show_ckyc_check = true;
          // }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
  },
};
</script>