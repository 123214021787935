<template>
  <vs-popup id="warehousedispatchedpopup" class="holamundo" title="Courier Number Update" :active.sync="open">
    <div v-if="open">
      <!-- <vs-row style="margin-bottom: 5%">
         <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="4"></vs-col> 
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="4">
          <b style="color: gray; margin-right: 2%">
            Books
          </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-center" vs-align="center" vs-w="4"></vs-col>
      </vs-row> -->
      <vs-row style="margin-bottom:5%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h4><b style="color: gray; margin-right: 2%">
            {{getBooks()}}
          </b></h4>
        </vs-col>
      </vs-row>
      <vs-divider></vs-divider>
      <vs-row style="margin-bottom:5%">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
              <b style="color: gray; margin-right: 2%">
            Courier Number
          </b>
        </vs-col> 
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-input v-model="courier_number" />
        </vs-col>
      </vs-row>
      <vs-row style="margin-bottom:5%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button @click="UpdateNumber()" size="small" color="primary">Update Courier Number</vs-button>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
// import vSelect from "vue-select";
// import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  components: {
    // vSelect,
    // Datepicker
  },
  watch: {},
  mounted() {
    eventbus.$on("open-couriernumberPopup", (data) => {
      console.log("fds");
      this.mainData = data
      this.courier_number = ''
      this.open = true;
    });
  },
  data() {
    return {
      open: false,
      courier_number: "",
      mainData: {}
    };
  },
  methods: {
    getBooks() {
      let deliverables = this.mainData.purchase_deliverables
       let books = [];
      deliverables.forEach((deliverable) => {
        books.push(deliverable.name);
      });
      books = books.join();
      return books;
    },
  getIDs() {
      let deliverables = this.mainData.purchase_deliverables
       let books = [];
      deliverables.forEach((deliverable) => {
        books.push(deliverable.id);
      });
      books = books.join();
      return books;
    },
    UpdateNumber() {
      let url = `${constants.SERVER_API}updateCourierNumberForDeliverables`;
      let params = {
        deliverable_ids : this.getIDs(),
        courier_number: this.courier_number
      }
      console.log(params)
      // return;
      axios
          .post(url, params, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              this.open = false
              eventbus.$emit("reloadWarehouseDispatches");
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
    }
  }
};
</script>

<style>