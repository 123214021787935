<template>
  <div>
    <table class="schedule-table" id="stats">
      <thead>
        <tr>
          <th>Schedules</th>
          <th>Visit Scheduled On</th>
          <th>Visit Scheduled For</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(schedule, index) in schedules_history" :key="index">
          <td>
            <b>
              <p v-if="schedule.source != '' && schedule.visit_status == null">{{ schedule.scheduled_by }} / Clevertap : {{ checkForMeta(schedule.source) }}</p>
              <p v-if="schedule.source != '' && schedule.visit_status != null">{{ displayText(schedule.visit_status) }} | {{ displayText(schedule.scheduled_by)}}-{{ schedule.spoc_team }}-{{ schedule.spoc_cc }} : {{ checkForMeta(schedule.source) }}</p>
              <p v-if="schedule.source == '' && schedule.visit_status != null">{{ displayText(schedule.visit_status) }} | {{displayText(schedule.scheduled_by)}}-{{ schedule.spoc_team }}-{{ schedule.spoc_cc }}</p>
              <p v-if="schedule.source == '' && schedule.visit_status == null">Visit Scheduled|{{displayText(schedule.scheduled_by)}}-{{ schedule.spoc_team }}-{{ schedule.spoc_cc }}</p>
            </b>
          </td>
          <td>
            <b>{{ formatDate(schedule.created_at) }}</b>
          </td>
          <td>
            <b>{{ formatDate(schedule.scheduled_date) }}</b>
          </td>
        </tr>
        <div v-if="schedules_history.length == 0" class="no-data-found">No Data Found</div>
      </tbody>
    </table>
  </div>
</template>

<script>
import constants from "../../../../constants.json";
import axios from "axios";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      schedules_history: {},
      scheduled_by_scop: null,
    };
  },
  mounted() {
    console.log("student details NetEnquireHistory", this.studentDetails);
    this.SchedulesHistory();
  },
  methods: {
    displayText(text) {
      if (text === null || text === "" || text === undefined) {
        return "N/A";
      } else {
        return text;
      }
    },
    SchedulesHistory() {
      this.$vs.loading();
      let url = `${constants.SERVER_API}getScheduledVisits?mwb_id=${this.studentDetails.mwb.id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log(response.data);
          this.schedules_history = response.data["scheduled_visits"];
          response.data["completed_visits"].forEach(visit => {
            this.schedules_history.push(visit)
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY");
      return formttedDate;
    },
    checkForMeta(source){
      if (source == 'Meta') {
        return 'System';
      } else {
        return source;
      }
    }
  },
};
</script>

<style lang="scss">
.schedule-table {
  height: 100%;
  margin: 10px auto;
  background-color: white;
  position: relative;
  padding-top: 30px;

  width: 100%;
  font-family: "Montserrat";
  margin: 25px auto;
  border-collapse: collapse;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 20px 20px rgba(0, 0, 0, 0.05), 0px 30px 20px rgba(0, 0, 0, 0.05);

  tr {
    &:hover {
      background: #f4f4f4;

      td {
        color: #555;
      }
    }
  }

  th,
  td {
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding: 6px 16px;
    border-collapse: collapse;
  }

  th:first-child,
  td:first-child {
    border-left: 0px solid transparent !important;
  }

  th {
    background: #0044ba;
    color: #fff;
    font-size: 12px;

    &.last {
      border-right: none;
    }
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  tr:last-child th:last-child {
    border-top-right-radius: 10px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

@media only screen and (max-width: 1500px) {
  .schedule-table {
    tr td:last-child {
      font-size: 12px;
    }
  }
}
.no-data-found {
    display: flex;
    justify-content: center;
    align-content: center;
    letter-spacing: 0.3px;
    font-size: 16px;
    color:grey;
    font-weight: 900;
    margin: auto 100%;
    width:100%;
    padding:10px
}
</style>
