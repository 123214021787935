<template>
  <div>
    <!-- <vs-table id="tableattendance" class="gap" :data="studentDetails.ne_data">
      <template slot="thead">
        <vs-th>Enquiry Date</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Mobile</vs-th>
        <vs-th>Email</vs-th>
        <vs-th>Details</vs-th>
        <vs-th>Course</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>NetEnquiryType</vs-th>
        <vs-th>Campaign</vs-th>
        <vs-th>City</vs-th>
        <vs-th>Spoc</vs-th>
        <vs-th>Source</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="tr.spoc_id" v-for="tr in data">
          <vs-td style="font-size: 10px;">{{ tr.enquiry_date }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.name }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.mobile }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.email }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.details }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.course }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.status }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.net_enquiry_type }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.campaign }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.city }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.cm_spoc }}</vs-td>
          <vs-td style="font-size: 10px;">{{ tr.source }}</vs-td>
        </vs-tr>
      </template>
    </vs-table> -->

    <vs-card
      style="background: #7e8f90; margin-top: 2%"
      v-for="(enquiry, index) in studentDetails.ne_data"
      :key="index"
    >
      <div>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            style="color: white"
          >
            <b>
              {{ displayText(enquiry.source) }} |
              {{ displayText(enquiry.campaign) }} |
              {{ displayText(enquiry.net_enquiry_type) }} |
              {{ displayText(enquiry.city) }} -
              {{ displayText(enquiry.location) }} |
              {{ displayText(enquiry.course) }}
            </b>
            <!-- <b>31-Feb-2020</b> -->
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="6"
            style="color: white"
          >
            <b>{{ displayText(enquiry.enquiry_date) }}</b>
            <!-- <b>Facebook Campaign</b> -->
          </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="4"
            style="color: white"
          >
            <b>{{ displayText(enquiry.source) }}</b>
          </vs-col> -->
        </vs-row>
        <vs-divider color="rgb(255, 255, 255)" />
        <!-- <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="12"
            style="font-size: 13px; color: white"
          >
            {{ displayText(enquiry.details) }}&nbsp; <span v-if="enquiry.webinar_topic != '' && enquiry.webinar_topic != null"> | {{ enquiry.webinar_topic }} </span>
          </vs-col>
        </vs-row> -->
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="12"
            style="font-size: 13px; color: white"
          >
            Education Qualification : {{ enquiry.education_qualification }}
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="12"
          >
            <div
              style="cursor: pointer"
              @click="openAdditionalInfoPopup(enquiry)"
            >
              <vs-icon
                icon="info_outline"
                size="small"
                color="#FFFFFF"
              ></vs-icon>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-card>

    <vs-popup
      title="Additional Enquiry Details"
      :active.sync="open"
      style="z-index: 53002"
    >
      <vs-table stripe data="[]" v-if="open">
        <vs-tr>
          <vs-td>Name</vs-td>
          <vs-td>{{ displayText(additionalInfo.name) }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Mobile</vs-td>
          <vs-td>{{ getMobileNo(additionalInfo.mobile) }} </vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>E-Mail</vs-td>
          <vs-td>{{ getEmailAddress(additionalInfo.email) }} </vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Course</vs-td>
          <vs-td>{{ displayText(additionalInfo.course) }}</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-td>Status</vs-td>
          <vs-td>{{ displayText(additionalInfo.status) }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>Type</vs-td>
          <vs-td>{{ displayText(additionalInfo.net_enquiry_type) }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>City</vs-td>
          <vs-td>{{ displayText(additionalInfo.city) }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>Location</vs-td>
          <vs-td>{{ displayText(additionalInfo.location) }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>Details</vs-td>
          <vs-td>{{ additionalInfo.details }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>Education Qualification</vs-td>
          <vs-td>{{ additionalInfo.education_qualification }}</vs-td>
        </vs-tr>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      open: false,
      additionalInfo: {},
    };
  },
  mounted() {
    console.log("this.studentDetails.ne_data", this.studentDetails.mwb.spoc_id,localStorage.getItem("user_id"));
  },
  methods: {
    displayText(text) {
      if (text === null || text === "" || text === undefined) {
        return "N/A";
      } else {
        return text;
      }
    },
    getMobileNo(num) {
      if (localStorage.getItem("user_id") == this.studentDetails.mwb.spoc_id) {
        return num;
      } else {
        if (num === null || num === "" || num === undefined) {
          return "N/A";
        } else {
          const masked_number =
            new Array(num.length - 2).join("x") + num.substr(num.length - 3, 4);
          return masked_number;
        }
      }
    },
    getEmailAddress(email) {
      if (localStorage.getItem("user_id") == this.studentDetails.mwb.spoc_id) {
        return email;
      } else {
        if (email === null || email === "" || email === undefined) {
          return "N/A";
        } else {
          const masked_email =
            new Array(email.length - 12).join("x") +
            email.substr(email.length - 13, 14);
          return masked_email;
        }
      }
    },
    openAdditionalInfoPopup(data) {
      this.additionalInfo = data;
      this.open = true;
    },
  },
};
</script>

<style></style>
