<template>
  <vs-popup
    v-if="open"
    fullscreen
    class="bigpopup_heading"
    style="z-index: 53001"
    :active.sync="open"
    
  >
  <!-- :title="
      candidate_details.mwb.identity +
      ' - ' +
      candidate_details.mwb.person_name +
      ' - ' +
      candidate_details.mwb.level +
      ' - ' +
      unixTimestampConvert(candidate_details.mwb.last_call) +
      this.getIIMLData(candidate_details.mwb) +
      ' - ' +
      spocsNames
    " -->
    <vs-row v-if="open">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-row>
          <info-section
            :candidate_details="candidate_details"
            :spocsNames="spocsNames"
          />
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <engagement-section :candidate_details="candidate_details" />
      </vs-col>
    </vs-row>
    <!-- <div style="display: hidden">CICD statement for testing</div> -->
    <movable-audio-play/>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import EngagementSection from "./EngagementSection/EngagementSection.vue";
import InfoSection from "./InfoSection/InfoSection.vue";
import MovableAudioPlay from "./InfoSection/MovableAudioPlay.vue"
import constants from "../../../constants.json";
import axios from "axios";
export default {
  components: {
    "engagement-section": EngagementSection,
    "info-section": InfoSection,
    "movable-audio-play":MovableAudioPlay,
  },
  mounted() {
    eventbus.$on("open-big-popup", (candidate_details) => {
      // console.log("Opening!!!!");
      this.candidate_details = candidate_details;
      console.log("detalis", candidate_details);
      let cm_spoc = "";
      let IIML_spoc = "";
      let sr_spoc = "";
      if (this.candidate_details.mwb.spoc_name !== null) {
        cm_spoc = " | CM : " + this.candidate_details.mwb.spoc_name;
      }
      if (this.candidate_details.mwb.assigned_iiml_name !== null) {
        IIML_spoc =
          " | USP : " + this.candidate_details.mwb.assigned_iiml_name;
      }
      if (this.candidate_details.mwb.sr_name !== null) {
        sr_spoc = " | SR : " + this.candidate_details.mwb.sr_name;
      }

      this.spocsNames = cm_spoc + IIML_spoc + sr_spoc;
      this.open = true;
    });

    eventbus.$on("change-connection-status", (data, status) => {
      console.log("change-connection-status33", data, status);
      eventbus.$emit("open-big-popup", data)
    });

    eventbus.$on("close-big-popup", () => {
      this.closePopup();
    });

    eventbus.$on("reload-bigpopup-data", () => {
      this.refreshData('');
    });
    eventbus.$on("visit-canceled", () => {
      this.refreshData('Visit Cancelled');
    });
    eventbus.$on("visit-rescheduled", () => {
      this.refreshData('Visit Rescheduled');
    });
  },
  data() {
    return {
      open: false,
      candidate_details: null,
      spocsNames: "",
    };
  },
  methods: {
    getIIMLData(mwb) {
      if (mwb.iiml_level !== null) {
        return ` - ${mwb.iiml_level}`;
      } else {
        return "";
      }
    },
    // sana changes
    closePopup() {
      if (this.$route.path === "/queue") {
        eventbus.$emit("update-queue");
      }
      this.open = false;
    },
    // closePopup() {
    //   this.open = false;
    // },
    refreshData(status) {
      this.$vs.loading();
      let getParams = {
        mwb_id: this.candidate_details.mwb.id,
        // person_id: this.candidate_details.mwb.person_id,
      };
      let url = `${constants.SERVER_API}getCandidateDetails`;
      axios.get(url, {params: getParams,headers: {Authorization: `Bearer ${localStorage.userAccessToken}`}})
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            if(status != ''){
            let candidate_data = response.data.data;
            // candidate_data['visit_status'] = status
            let visit_status = status
            eventbus.$emit("change-connection-status", candidate_data, visit_status);
            }else{
              eventbus.$emit("open-big-popup", response.data.data);
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
      
    },
  },
  watch: {
    open: function (value) {
      if (value === false) {
        if (
          this.$route.path === "/" ||
          this.$route.path === "/acadsDashboard" ||
          this.$route.path === "/iimlDashboard" ||
          this.$route.path === "/workDashboard" ||
          this.$route.path === "/GMWorkDashboard"
        ) {
          eventbus.$emit("update-dashboard");
        }
        if (this.$route.path === "/coverage") {
          eventbus.$emit("refreshCoveragePopup");
        }if (this.$route.path === "/Pathway") {
          eventbus.$emit("refreshPathwayPopup");
        }
        if (this.$route.path === "/myLeads") {
          eventbus.$emit("reload-leads");
        }
        if (this.$route.path === "/IIMLFALeads") {
          eventbus.$emit("reload-leads-IIMLFA");
        }
        if (this.$route.path === "/IIMLBussinessLeads") {
          eventbus.$emit("reload-leads-IIMLBA");
        }
        if (this.$route.path === "/onBoardTable") {
          eventbus.$emit("reload-onboard");
        }
        if (
          this.$route.path === "/candidates" ||
          this.$route.path === "/CandidatesCma"
        ) {
          eventbus.$emit("reload-leads-EWB");
        }
      }
    },
  },
};
</script>
<style >
.bigpopup_heading .vs-popup--title h3 {
  display:none;
}
.bigpopup_heading .vs-popup--header {
  height: 0px;
} 
.bigpopup_heading .vs-popup--close {
  margin-top:23px !important;
}
</style>
