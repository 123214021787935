<template>
  <vs-popup fullscreen id="coverageDetailspopup" :title="title" :active.sync="open">
    <leadsDataTable v-if="searchd_obj.level != 'NE'"/>
    

    <vs-table v-else :data="$store.state.MwbLeads">
      <template slot="thead">
        <vs-th>Enquiry Date</vs-th>
        <vs-th>Name</vs-th>
        <!-- <vs-th v-if="user_id == 132 || user_id == 122 || user_id == 937">Mobile</vs-th> -->
        <!-- <vs-th>Type</vs-th> -->
        <vs-th>Course</vs-th>
        <vs-th>Source</vs-th>
        <vs-th>City</vs-th>
        <vs-th>Campaign</vs-th>
        <vs-th>Uploaded Date</vs-th>
        <vs-th>Action</vs-th>
        <!-- <vs-th>View Info</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.enquiry_date }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.name }}</div>
            </vs-td>
            <!-- <vs-td v-if="user_id == 132 || user_id == 122 || user_id == 937">
              <div>{{ tr.mobile }}</div>
            </vs-td> -->
            <!-- <vs-td>
              <div>{{ tr.net_enquiry_type }}</div>
            </vs-td>-->
            <vs-td>
              <div>{{ tr.course }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.source }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.allocated_city }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.campaign }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.created_at }}</div>
            </vs-td>
            <vs-td>
              <v-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5" style="margin-left: -10%;">
                <div @click="calluntracked(tr)">
                    <vs-icon
                      icon="call"
                      size="small"
                      color="dark"
                    ></vs-icon>
                </div>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                <div @click="openPopupEmit(tr)">
                    <vs-icon
                      icon="edit"
                      size="small"
                      color="dark"
                    ></vs-icon>
                </div>
              </vs-col>
            </v-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-chip color="primary">
          <b>{{ totalCount }}</b>
        </vs-chip>
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import leadsDataTable from "./LeadsDataTable.vue";
import axios from "axios";
import constants from "../../../constants.json";

export default {
  data() {
    return {
      open: false,
      searchd_obj: {},
      url: "",
      response: "",
      currentTablePage: 1,
      tablelinks: 0,
      sendingurl: "",
      title: "",
      totalCount: 0,
      tableData: [],
    };
  },
  components: {
    leadsDataTable
  },
  watch: {
    currentTablePage: function() {
      this.paginationRequests();
    },
    open(value) {
      if (!value) {
        EventBus.$emit("updateCoverage");
      }
    }
  },
  mounted() {
    // document.getElementById("coverageDetailspopup").children[1].style.width =
    //   "80%";
    EventBus.$on("open-coverage-details", payload => {
      console.log("usahsdui", payload);
      this.currentTablePage = 1;
      this.searchd_obj = payload[0];
      this.url = payload[1];
      this.response = payload[2];
      this.sendingurl = payload[3];
      this.tablelinks = this.response.last_page;
      this.title = payload[4];
      this.totalCount = payload[2].total;
      console.log("usahsdui", this.title);
      this.open = true;
    });
    EventBus.$on("refreshCoveragePopup", () => {
      this.paginationRequests();
    });
  },
  methods: {
    paginationRequests() {
      this.$vs.loading();
      if(this.sendingurl == 'getVisitSchedulesContactDetails'){
        let url = `${constants.SERVER_API}${this.sendingurl}`;
        this.searchd_obj.page = this.currentTablePage;
        axios
          .get(url, {params: this.searchd_obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            this.$vs.loading.close();
            response.data.data.forEach(lead => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          })
          .catch(error => {
            this.handleError(error);
          });
      }else{
      let url = `${constants.SERVER_API}${this.sendingurl}`;
      this.searchd_obj.page = this.currentTablePage;
      axios
        .post(url, this.searchd_obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.$vs.loading.close();
          response.data.data.forEach(lead => {
            lead.engagements = [];
            lead.mobile = [];
            lead.email = [];
          });
          this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
        })
        .catch(error => {
          this.handleError(error);
        });
      }
    },
    openPopupEmit(ne_data) {
            console.log(ne_data);
            let obj = ne_data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            EventBus.$emit("open-netEnquiries-details-popup", obj);
    },
    async calluntracked(data) {
            console.log(data);
            let obj = data
            let { person_name: name, phone: mobile, ...rest } = obj;
            obj = { name, mobile, ...rest }
            obj["course"] = "MAcc";
            obj["net_enquiry_type"] = "Webinar-WR";
            let response = await this.checkPermission();
            if (localStorage.getItem("role") !== "cm4") {
                if (
                    this.$route.path !== "/queue" &&
                    this.$route.path !== "/placementDrive" &&
                    this.$route.path !== "/logout" &&
                    this.$route.path !== "/UtilizationReport" &&
                    this.$route.path !== "/receipts"
                ) {
                    if (response === "no") {
                        this.$vs.notify({
                            title: "Permission denied",
                            text: "Please contact admin!!!",
                            color: "danger",
                            icon: "error",
                        });
                        return;
                    }
                }
            }
            let params = {};
            params.phone_number = data.phone;
            params.email = data.email;
            axios
                .get(constants.SERVER_API + "clickToCallUntracked", {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    // this.open = false;
                    // if (this.userdata.matched !== 1) {
                        EventBus.$emit("open-netEnquiries-details-popup", obj);
                    // }
                })
                .catch((error) => {
                    this.handleError(error);
                });

        },
  }
};
</script>

<style></style>
