<template>
  <vs-popup
    v-if="open"
    vs-type="flex"
    :id="'mwbpopup'"
    class="size"
    fullscreen
    :title="
      studentDetails.identity +
        ' - ' +
        studentDetails.person_name +
        ' - ' +
        studentDetails.level +
        ' - ' +
        unixTimestampConvert(studentDetails.last_call)
    "
    :active.sync="open"
    style="z-index:53001"
  >
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
      ></vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-start"
        vs-w="6"
      ></vs-col>
    </vs-row>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-row>
          <card1 v-if="open" :studentDetails="studentDetails" />
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <card3
          v-if="open"
          :payload="studentDetails"
          :selected="sectionselected"
        />
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import Card1 from "./StudentdetailsCard1.vue";
import Card3 from "./latestEngagements.vue";
import avatar from "./ConnectionStatusAvatar.vue";
// import moment from "moment";

export default {
  components: {
    Card1,
    Card3,
    avatar
  },
  mounted() {
    EventBus.$on("open-student-details-popup-call", () => {
      this.sectionselected = "Call";
    });
    EventBus.$on("open-student-details-popup-whatsapp", () => {
      this.sectionselected = "Whatsapp";
    });
    EventBus.$on("open-student-details-popup", payload => {
      if (this.open === true) {
        // this.$vs.notify({
        //   title: "Incoming Call",
        //   text: "You are receiving a call from " + payload.person_name,
        //   color: "warning"
        // });
      } else {
        this.studentDetails = payload;
        console.log("testing")
      }
      EventBus.$emit("mwb-cards", this.studentDetails);
      this.open = true;
    });
    EventBus.$on("reload-dashboard", () => {
      this.savedashboard = true;
    });
    EventBus.$on("close-popup-redirect", () => {
      this.open = false;
    });
  },
  data() {
    return {
      savedashboard: false,
      open: false,
      studentDetails: "",
      vuexIndex: 0,
      sectionselected: "All",
      elementcountmain: ""
    };
  },
  methods: {
    // unixTimestampConvert(value) {
    //   var dateString = moment.unix(value).format("DD/MM/YYYY");
    //   switch (dateString) {
    //     case "01/01/1970":
    //       return "";
    //     default:
    //       return dateString;
    //   }
    // },
    EmitEvent(emit, payload) {
      EventBus.$emit(emit, payload);
    }
  },
  watch: {
    open: function(value) {
      if (value === false) {
        if (
          this.$route.path === "/" ||
          this.$route.path === "/acadsDashboard"
        ) {
          EventBus.$emit("update-dashboard");
        }
        if (this.$route.path === "/coverage") {
          EventBus.$emit("refreshCoveragePopup");
        }
      }
    }
  }
};
</script>
