<template>
  <vs-popup title="BOOK SELECTION" :active.sync="open">
    <vs-card>
      <div class="centerx labelx" style="margin-top: 3%">
        <vs-row
          vs-w="12"
          v-for="(book, index) in bookList"
          :key="index"
          style="margin-bottom: 3%"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-checkbox
              style="position: relative; width: 100px"
              :disabled="book.disabledState"
              v-model="book.selected"
              color="dark"
            >
              <b style="color: gray; font-size: 12px">{{ book.book }}</b>
            </vs-checkbox>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
          >
            <v-select
              :disabled="book.disabledState"
              :options="options1"
              v-model="book.edition"
              label="year"
              style="width: 200px"
            />
          </vs-col>
        </vs-row>
        <vs-divider>Courier Information</vs-divider>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <b style="color: gray; font-size: 15px">Email</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-if="courierDisable === false"
              disabled
              class="inputx"
              v-model="emailID"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="emailID"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <b style="color: gray; font-size: 15px">Phone Number</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-if="courierDisable === false"
              disabled
              class="inputx"
              v-model="phoneNumber"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="phoneNumber"
            />
          </vs-col>
        </vs-row>
        <!-- <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <b style="color: gray; font-size: 15px">Courier Number</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-if="courierDisable === false"
              class="inputx"
              v-model="courier"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="courier"
            />
          </vs-col>
        </vs-row> -->
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <b style="color: gray; font-size: 15px">Courier Company</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
            <vs-input
              v-if="courierDisable === false"
              class="inputx"
              v-model="courier_company"
            />
            <vs-input
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="courier_company"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <b style="color: gray; font-size: 15px">Dispatch Date</b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
            <datepicker
              v-model="dispatch_date"
              v-if="courierDisable === false"
              class="uniquename"
            ></datepicker>
            <datepicker
              v-if="courierDisable === true"
              disabled
              v-model="dispatch_date"
              class="uniquename"
            ></datepicker>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="12"
          >
            <vs-textarea
              label="From Address"
              v-if="courierDisable === false"
              class="inputx"
              v-model="from_address"
              height="200px"
            />
            <vs-textarea
              label="From Address"
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="from_address"
              height="200px"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="12"
          >
            <vs-textarea
              label="To Address"
              v-if="courierDisable === false"
              class="inputx"
              v-model="address"
              height="200px"
            />
            <vs-textarea
              label="To Address"
              v-if="courierDisable === true"
              disabled
              class="inputx"
              v-model="address"
              height="200px"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-divider>
              <vs-button color="warning" type="filled" @click="sendBooks()"
                >Update Books Status</vs-button
              >
            </vs-divider>
          </vs-col>
        </vs-row>
      </div>
    </vs-card>
  </vs-popup>
</template>
<script>
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    "v-select": vSelect,
    Datepicker,
  },
  data() {
    return {
      bookList: [
        // { book: "AUD", edition: "", selected: false, disabledState: false },
        // { book: "FAR", edition: "", selected: false, disabledState: false },
        // { book: "BEC", edition: "", selected: false, disabledState: false },
        // { book: "REG", edition: "", selected: false, disabledState: false }
      ],
      select1Normal: "",
      mainData: {},
      courierNumbers: [],
      courierDisable: false,
      courier: "",
      select1: 1,
      select2: 1,
      select3: 1,
      select4: 1,
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      options1: ["2019", "2020", "2021", "2022"],
      open: false,
      courier_company: "",
      address: "",
      from_address:
        "3rd Floor, A-3/6, IDA Kukatpally, Gandhinagar, Hyderabad, Telangana, 500037,IN",
      phoneNumber: "",
      emailID: "",

      dispatch_date: new Date(),
    };
  },
  watch: {
    // dispatch_date(value) {
    //   this.dispatch_date = Math.floor(new Date(value).getTime() / 1000).toFixed(0)
    //   console.log("date to in==unix",this.dispatch_date);
    // },
  },
  mounted() {
    EventBus.$on("open-warehouse-popup", (payload) => {
      console.log(payload);
      this.courierDisable = false;
      this.bookList = [];
      payload.deliverables.forEach((subject) => {
        if (subject.type === "book") {
          let obj = {
            id: subject.id,
            book: subject.name,
            edition: this.options1[1],
            selected: true,
            disabledState: false,
            preselected: false,
          };
          this.bookList.push(obj);
        }
      });
      if (payload.enrollment) {
        this.address = payload.enrollment.address;
      } else {
        this.address = "";
      }
      if (payload.phone_number) {
        this.phoneNumber = payload.phone_number.phone_number;
      }
      if (payload.email) {
        this.emailID = payload.email.email;
      }
      this.courier = "";
      this.courier_company = "";
      // this.mainData = payload;
      // this.courierNumbers = payload2;
      this.checkSentBooks(payload.deliverables);
      this.open = true;
    });
  },
  methods: {
    sendBooks() {
      if (this.courier_company === "") {
        this.$vs.notify({
          title: "Provide the courier details",
          text: "provide the courier details for the selected books",
          color: "danger",
        });
        return;
      }
      this.sendDispatchStatus();
    },
    sendDispatchStatus() {
      let obj = {
        dispatch_ids: [],
        dispatch_editions: [],
        courier_number: this.courier,
        courier_company: this.courier_company,
        address: this.address,
        from_address: this.from_address,
        // email:this.emailID,
        // phone_number:this.phoneNumber,
        dispatch_date: Math.floor(
          new Date(this.dispatch_date).getTime() / 1000
        ).toFixed(0),
      };
      console.log("obj", obj);
      this.bookList.forEach((book) => {
        if (!book.disabledState && book.selected) {
          obj.dispatch_ids.push(book.id);
          obj.dispatch_editions.push(book.edition);
        }
      });
      obj.dispatch_ids = obj.dispatch_ids.join();
      obj.dispatch_editions = obj.dispatch_editions.join();
      console.log(obj);
      this.sendRequest(obj);
    },
    sendBooksRequest() {
      let paramObj = {
        warehouse_id: this.mainData.id,
        aud_sent: "",
        bec_sent: "",
        far_sent: "",
        reg_sent: "",
        aud_version: "",
        bec_version: "",
        far_version: "",
        reg_version: "",
        books_sent_status: "",
        courier_number: this.courier,
        books_sent: "",
      };
      // THIS DECIDES THE books_sent_status.
      // let finalCourseCheck = [];
      let allcheck = 0;
      this.bookList.forEach((book) => {
        if (book.selected !== true) {
          allcheck = allcheck + 1;
        }
      });
      if (allcheck !== 0) {
        paramObj.books_sent_status = "partially_sent";
      } else {
        paramObj.books_sent_status = "sent";
      }
      // ENDS HERE

      // THIS MAKES THE books_sent STRING OF CURRENTLY SENT BOOKS *PREVIOUS BOOKS ARE NOT INCLUDED*. AND ALSO SETS THE VERSION AND SENT STATUS.
      let currentsentbooks = [];
      this.bookList.forEach((book) => {
        if (book.preselected === false && book.selected === true) {
          currentsentbooks.push(book.book);
          paramObj[book.book.toLowerCase() + "_sent"] = 1;
          paramObj[book.book.toLowerCase() + "_version"] = book.edition;
        }
      });
      paramObj.books_sent = currentsentbooks.join();
      // ENDS HERE
      console.log(paramObj);
      // this.sendRequest(paramObj);
    },
    sendRequest(paramObj) {
      let url = `${constants.SERVER_API}updateDispatchStatus`;
      axios
        .post(url, paramObj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "error") {
            EventBus.$emit("reload-warehouse-custodian");
            this.open = false;
          }
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkSentBooks(deliverables) {
      console.log(deliverables);
      let count = 0;
      // deliverables.forEach((book, index) => {
      //   if (book.dispatch_status === "dispatched") {
      //     this.bookList[index].disabledState = true;
      //     count = count + 1;
      //   }
      // });
      this.bookList.forEach((book) => {
        deliverables.forEach((deli_book) => {
          if (book.id === deli_book.id) {
            if (deli_book.dispatch_status === "dispatched") {
              book.disabledState = true;
              count = count + 1;
            }
          }
        });
      });
      if (count === this.bookList.length) {
        this.courierDisable = true;
      }
    },
  },
};
</script>
<style>
.vs-component.vs-con-input-label.vs-input.inputx.vs-input-primary {
  width: 100%;
  color: black;
}
.vdp-datepicker div input {
  width: 100%;
}
.vdp-datepicker.uniquename {
  width: 100%;
}
textarea.vs-textarea {
  height: 100px;
}
</style>
