<template>
  <div>
    <vs-row
      v-for="(item, index) in transfer_history"
      :key="index"
      style="height: fit-content; margin-top: 2%"
    >
      <vs-row style="margin-bottom: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          <vs-button type="filled">
            <b>{{ formatDate(item.created_at) }}</b
            ><br />
          </vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
          <b>- {{ item.transferred_name }}</b>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <vs-card>
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                ><h6>
                  <b>
                    {{ item.previous_spoc ? item.previous_spoc : "-" }}
                    <vs-icon
                      icon="forward"
                      color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"
                    ></vs-icon>
                    {{ item.current_spoc ? item.current_spoc : "-" }} </b
                  >{{ " | " + item.source }}
                </h6></vs-col
              >
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import constants from "../../../../constants.json";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.NetEnquireHistory();
    console.log("student details NetEnquireHistory", this.studentDetails);
  },
  data() {
    return {
      transfer_history: [],
    };
  },
  methods: {
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY h:mm A");
      return formttedDate;
    },
    NetEnquireHistory() {
      this.$vs.loading();
      let url = `${constants.SERVER_API}NetEnquireHistory?mwb_id=${this.studentDetails.mwb.id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("NetEnquireHistory", response.data);
          this.transfer_history = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>