<template>
  <div>
    <!-- <vs-dialog></vs-dialog> -->
    <vs-popup title="LEAD EXISTS" :active.sync="open">
      <p>{{ message }}</p>
      <vs-row style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>Can-ID :{{ identityInfo }}</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 4%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button color="dark" type="filled" @click="mapToThisLead()">Map to this lead</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      open: false,
      payload: "",
      message: "",
      identityInfo: "",
    };
  },
  mounted() {
    eventbus.$on("open-netEnquiries-map-popup", (payload, message) => {
      //   this.openDialog(payload);
      this.identityInfo = this.generateMessage(message);
      this.message = message;
      this.payload = payload;
      this.open = true;
    });
  },
  methods: {
    generateMessage(message) {
      let b2cIndex = message.indexOf("ity :");
      b2cIndex = b2cIndex + 5;
      let levelIndex = message.indexOf("Level");

      let returnMessage = message.substring(b2cIndex, levelIndex);
      return returnMessage;
    },
    mapToThisLead() {
      let url = `${constants.SERVER_API}matchNetEnquiryWithMwb`;
      let obj = {
        mobile: this.payload,
        identity: this.identityInfo.replace(/ /g, ""),
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.open = false;
            if (this.$route.path === "/queue") {
              eventbus.$emit("update-queue");
            }
          }
          eventbus.$emit("close-addLead-popup");
          eventbus.$emit("close-ne-popup");
          this.handleNotification(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
