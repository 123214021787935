<template>
  <vs-popup
    style="z-index:53005"
    class="holamundo"
    title="Change Batch Code"
    :active.sync="open"
  >
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b style="color: gray; font-size: 12px">
          Enter the new batch code
        </b>
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-input
          class="inputx"
          placeholder="Batch code"
          v-model="new_batch_code"
        />
      </vs-col>
    </vs-row>
    <vs-row class="gap">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-button color="dark" type="filled" @click="editBatch()"
          >Save</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
// import moment from "moment";
import constants from "../../../constants.json";
export default {
  mounted() {
    eventbus.$on("open-edit-batch-code", (payload) => {
      this.details = payload;
      this.open = true;
    });
  },
  data() {
    return {
      open: false,
      new_batch_code: "",
      details: {},
    };
  },
  methods: {
    editBatch() {
      let url = `${constants.SERVER_API}changeBatch`;
      let obj = {
        enrollment_id: this.details.id,
        batch: this.new_batch_code,
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            eventbus.$emit("reload-bigpopup-data");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
