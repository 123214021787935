<template>
    <div>
        <vs-card style="background: #7e8f90; margin-top: 2%" v-for="(enquiry, index) in placementdrive_data" :key="index">
            <div>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" style="color: white">
                        <b>{{ enquiry.topic }}
                        </b>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6" style="color: white">
                        <b>{{ formatDate(enquiry.created_at) }}</b>
                    </vs-col>
                </vs-row>
                <vs-divider color="rgb(255, 255, 255)" />
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6"
                        style="font-size: 13px; color: white">
                        <span v-if="enquiry.attended == 0">Registered</span>
                        <span v-if="enquiry.attended == 1">Attended</span>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6"
                        style="font-size: 13px; color: white">
                        {{ enquiry.source }}
                    </vs-col>
                </vs-row>
            </div>
        </vs-card>
    </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
import constants from "../../../../constants.json";
export default {
    props: {
        studentDetails: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            placementdrive_data: [],
        };
    },
    mounted() {
        this.placementDriveData();
    },
    methods: {
        placementDriveData() {
            this.$vs.loading();
            let url = `${constants.MILES_WEBINARS}getPlacementDriveHistory?mwb_id=${this.studentDetails.mwb.id}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    this.$vs.loading.close();
                    console.log("response", response.data);
                    this.placementdrive_data = response.data;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        formatDate(created_at) {
            let date = new Date(created_at);
            let formttedDate = moment(date).format("DD-MMM-YYYY h:mm A");
            return formttedDate;
        },
    },
};
</script>
  
<style></style>
  