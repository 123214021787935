<template>
   <vs-popup
      style="margin-left: 500px"
      background-color="rgba(0,0,0,0)"
      title="PREVIEW" :active.sync="open">
      <div id="preview">

      </div>
    </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
  mounted() {
    EventBus.$on('open-preview-engagement', (payload) => {
      this.open = true
      this.data = payload
      this.append()
    })
  },
  data() {
    return {
      open: false,
      data: ''
    }
  },
  methods: {
    append() {
      document.getElementById('preview').innerHTML = this.data
    }
  }
}
</script>

<style>

</style>
