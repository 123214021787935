<template>
  <div>
    <div style="display: flex" class="m-5">
      <vs-checkbox v-model="checkBox1" vs-value="CD"><b style="font-size:15px;">CD</b></vs-checkbox>
      <vs-checkbox v-model="checkBox1" vs-value="ND"><b style="font-size:15px;">ND</b></vs-checkbox>
    </div>
    <div v-if="chat_history_data.length == 0" class="mt-4" style="text-align:center;">
      No Chat History
    </div>
    <vs-row class="mt-4" v-for="fetch in chat_history_data" :key="fetch.key" style="height: fit-content">
      <vs-row style="margin-bottom: 1%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <vs-button :color="getColor(fetch)"  type="filled">
           <div style="display:flex; justify-content:center;align-items:center;">
            <div><b  :style="getTextColor(fetch)">{{ formatDate(fetch.created_at) }}</b></div> &nbsp; &nbsp;
            <div @click="fetchRecording(fetch)" style="margin-left: -3px; margin-top: 2px;">
              <vs-chip style="min-height: 30px;" v-if="fetch.call_data != undefined && fetch.call_data != ''" >
                <vs-icon
                class="clickableIcon"
                icon="play_arrow"
                size="small"
                color="dark"
                ></vs-icon>
                {{timeConvert(fetch.call_data.call_duration) }}
              </vs-chip>
            </div>
           </div>
          </vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <b>- {{ fetch.added_by_name }} - {{ fetch.team }} - {{ fetch.city_classification }}</b>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <vs-card>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <h6>
                  <b>
                    {{ fetch.previous_level ? fetch.previous_level : "-" }}
                    <vs-icon icon="forward" color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"></vs-icon>
                    {{ fetch.level ? fetch.level : "-" }} |
                    {{
                      fetch.previous_iiml_level
                      ? fetch.previous_iiml_level
                      : "-"
                    }}
                    <vs-icon icon="forward" color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"></vs-icon>
                    {{ fetch.iiml_level ? fetch.iiml_level : "-" }}
                  </b>
                </h6>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">{{ fetch.details }}</vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
import moment from "moment";
import constants from "../../../constants.json";
import axios from "axios";
import EventBus from "../eventbus";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      checkBox1: ["CD"],
      chat_history_data: [],
      textColor:"#FFFFFF"
    };
  },
  mounted() {
    console.log("engagements",this.studentDetails.engagements)
    let data = ['CD']
    this.getchatHistory(data);
  },
  watch: {
    checkBox1(val) {
      this.getchatHistory(val);
    }
  },
  methods: {
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
    },
    fetchRecording(call_log) {
      // console.log("clicked", call_log)

      // this.$vs.loading();
      let obj = {
        call_log_id: call_log.call_data.call_log_id,
      };
      let url = `${constants.MILES_CRM_DEV}fetchPreSignedUrl`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("fetch call_log", response);
          if (response.data.data !== null) {
            this.playAudio(response.data.data, call_log);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    playAudio(audiofile, call_log) {
      this.audio = new Audio(audiofile);
      this.audio.onerror = function (error) {
        console.log(error);
        this.info = "Cannot find the call";
        this.errorshow = true;
        setTimeout(() => {
          this.open = false;
          this.errorshow = false;
        }, 1000);
      };
      EventBus.$emit("open-call-recording-popup-chat-history", audiofile, call_log.call_data, call_log);
      // console.log("opening");
      // let audioplayer = document.getElementById("my-audio");
      // audioplayer.load();
      // this.open = true;
      this.$vs.loading.close();
    },
    fetchIcon(type) {
      // console.log(type);
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    getchatHistory(val) {
      let data = this.studentDetails.engagements;
      this.chat_history_data = [];
      val.forEach((checkData) => {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          console.log("team",element.team,"sub_team",element.sub_team)
          if (checkData == "CD") {
            if (element.connection_status == "CD" || element.hasOwnProperty('call_data')) {

              this.chat_history_data.push(element);
            }
          } else if (checkData == "ND") {
            if (element.connection_status !== "CD" && !element.hasOwnProperty('call_data')) {
              this.chat_history_data.push(element);
            }
          } else if (this.checkBox1.length > 0) {
            this.chat_history_data.push(element);
          } else {
            this.chat_history_data = [];
          }

        }
      });
      this.chat_history_data.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY h:mm A");
      return formttedDate;
    },
    getTextColor(fetch){
      if (fetch.team == 'ExEd' && fetch.sub_team == 'MAcc' || fetch.team == "Admin"|| fetch.team == "GM") {
        let style = {
          "color":"#000000",
          "font-size":"11px"
        }
        return style; //Macc
      }else{
        let style = {
          "color":"#ffffff",
          "font-size":"11px"
        }
        return style;
      }
    },
    getColor(fetch) {
      // switch (fetch.added_by_team) {
      //   case "ac1":
      //     return "dark";
      //   default:
      //     return "primary";
      // }
      if (fetch.type == "visit") {
        return  "success";
      }else if (fetch.type == "gmeet") {
        return  "success";
      } else if (fetch.team == "CM") {
        return "primary";
      } else if (fetch.team == "SR") {
        return  "warning";
      } else if (fetch.team == "Acads") {
        return  "#2D2727";
      } else if (fetch.team == 'ExEd' && fetch.sub_team == 'MAcc') {
        this.textColor = "#000000";
        return  "#FFD56F"; //Macc
      } else if (fetch.team == 'ExEd' && fetch.sub_team != 'MAcc') {
        return  "#EF6387"; //ExEd
      } else if (fetch.team == "Admin") {
        return  "#A3C5E7";
      } else if (fetch.team == "GM") {
        return  "#93FF8E";
      } else {
        return  "primary";
      }

    },
  },
};
</script>

<style></style>
