<template>
  <vs-popup title="Approve this purchase?" :active.sync="open">
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <b>List of all the deliverables for this purchase</b>
      </vs-col>
    </vs-row>
    <div v-if="open">
      <vs-row
        style="margin-top: 3%"
        v-for="(title, index) in getNames()"
        :key="index"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>{{ title }}</b>
        </vs-col>
      </vs-row>
    </div>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <!-- <b>List of all the deliverables for this purchase</b> -->
        <vs-textarea label="Comments" v-model="comments" />
      </vs-col>
    </vs-row>

    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
        <vs-button color="success" @click="decision('approve')" type="gradient"
          >Approve</vs-button
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
        <vs-button color="danger" @click="decision('reject')" type="gradient"
          >Reject</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("openPurchaseApprovalPopup", payload => {
      console.log(payload);
      this.details = payload;
      this.comments = "";
      this.open = true;
    });
  },
  data() {
    return {
      details: "",
      open: false,
      comments: ""
    };
  },
  methods: {
    getNames() {
      let arr = [];

      this.details.deliverables.forEach(deliverable => {
        let name = "";
        if (deliverable.type === "video_access") {
          name = `${deliverable.subject} Video`;
        } else if (deliverable.type === "otb") {
          name = `${deliverable.subject} OTB`;
        } else {
          name = `${deliverable.name}`;
        }
        arr.push(name);
      });

      return arr;
    },
    decision(choice) {
      let obj = {
        purchase_id: this.details.id,
        status: "",
        admin_comments: this.comments
      };
      if (choice === "approve") {
        obj.status = "approved";
      } else if (choice === "reject") {
        obj.status = "rejected";
      }
      // console.log("tested", obj);
      let url = `${constants.SERVER_API}approveThisPurchase`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log(response);
          let color = "success";
          if (response.data.status === "error") {
            color = "danger";
          }
          this.$vs.notify({
            title: response.data.status,
            text: response.data.message,
            color: color
          });
          EventBus.$emit("reloadapprovals");
          this.open = false;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getMode(mode) {
      switch (mode) {
        case "study_material_training":
          return "Study Material + Training";
        case "study_material_only":
          return "Study Material Only";
        case "only_license":
          return "Only License";
      }
    }
  }
};
</script>

<style></style>
