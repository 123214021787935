<!-- =========================================================================================
    File Name: VxSidebar.vue
    Description: Sidebar Component
    Component Name: VxSidebar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template lang="html">
  <div class="parentx">
    <vs-sidebar
      ref="mainSidebar"
      :parent="parent"
      :hiddenBackground="true"
      :reduce="reduce"
      default-index="-1"
      class="sidebarx main-menu-sidebar items-no-padding"
      v-model="isSidebarActive"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
    >
      <div @mouseenter="sidebarMouseEntered" @mouseleave="sidebarMouseLeave">
        <div
          class="header-sidebar flex items-end justify-between"
          slot="header"
        >
          <div class="logo flex items-center">
            <!-- <img src="milesLogo.png" alt="logo" class="w-10 mr-4" v-if="logo"> -->
            <span
              class="logo-text"
              v-show="isMouseEnter || !reduce"
              v-if="title"
              >Miles</span
            >
          </div>
          <div>
            <template v-if="showCloseButton">
              <feather-icon
                icon="XIcon"
                class="m-0 cursor-pointer"
                @click="$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)"
              ></feather-icon>
            </template>
            <template v-else-if="!showCloseButton && !sidebarItemsMin">
  <feather-icon
    icon="DiscIcon"
    class="mr-0 cursor-pointer"
    svg-classes="stroke-current"
    v-show="!reduce"
    @click="toggleReduce(true)"
    id="btnSidebarToggler"
  ></feather-icon>
  <feather-icon
    icon="CircleIcon"
    class="mr-0 cursor-pointer"
    svg-classes="stroke-current"
    v-show="reduce"
    @click="toggleReduce(false)"
    id="btnSidebarToggler"
  ></feather-icon>
</template>
          </div>
        </div>

        <div class="shadow-bottom" v-show="showShadowBottom"></div>

        <VuePerfectScrollbar
          ref="mainSidebarPs"
          class="scroll-area--main-sidebar pt-2"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
        >
          <template v-for="(sidebarItem, index) in actual_side_bar">
            <!-- GROUP ITEM HEADER -->
            <span
              :key="`header-${index}`"
              v-if="sidebarItem.header && !sidebarItemsMin"
              class="navigation-header truncate"
              >{{ sidebarItem.header }}</span
            >
            <template v-else-if="!sidebarItem.header">
  <!-- IF IT'S SINGLE ITEM -->
  <vx-sidebar-item
    ref="sidebarLink"
    :key="`sidebarItem-${index}`"
    v-if="!sidebarItem.submenu"
    :index="index"
    :to="sidebarItem.slug != 'external' ? sidebarItem.url : ''"
    :href="sidebarItem.slug == 'external' ? sidebarItem.url : ''"
    :icon="sidebarItem.icon"
    :target="sidebarItem.target"
    :isDisabled="sidebarItem.isDisabled"
  >
    <span v-show="!sidebarItemsMin" class="truncate">{{
      sidebarItem.name
    }}</span>
    <vs-chip
      class="ml-auto"
      :color="sidebarItem.tagColor"
      v-if="sidebarItem.tag && (isMouseEnter || !reduce)"
      >{{ sidebarItem.tag }}</vs-chip
    >
  </vx-sidebar-item>

  <!-- IF HAVE SUBMENU / DROPDOWN -->
  <template v-else>
    <vx-sidebar-group
      ref="sidebarGrp"
      :key="`group-${index}`"
      :openHover="openGroupHover"
      :group="sidebarItem"
      :groupIndex="index"
      :open="isGroupActive(sidebarItem)"
    ></vx-sidebar-group>
  </template>
</template>
           </template>
        </VuePerfectScrollbar>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VxSidebarGroup from "./VxSidebarGroup.vue";
import VxSidebarItem from "./VxSidebarItem.vue";
import eventbus from "../../../components/eventbus";
import axios from "axios";
import constants from "../../../../constants.json";
import accessControl from "../../../accessControl";

export default {
  name: "vx-sidebar",
  props: {
    sidebarItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    logo: {
      type: String,
    },
    parent: {
      type: String,
    },
    openGroupHover: {
      type: Boolean,
      default: false,
    },
    reduceNotRebound: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    actual_side_bar: [],
    searchIn: {
      id: "",
      name: "",
    },
    clickNotClose: false, // disable close sidebar on outside click
    reduce: false, // determines if sidebar is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    isMouseEnter: false,
    settings: {
      // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true,
    },
    copysidebar: "",
    windowWidth: window.innerWidth, //width of windows
    showShadowBottom: false,
    searchInid: "",
  }),
  computed: {
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", val);
      },
    },
    reduceSidebar() {
      return Boolean(this.reduce && this.reduceButton);
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton;
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", val);
      },
    },
    sidebarItemsMin() {
      return this.$store.state.sidebarItemsMin;
    },
    isGroupActive() {
      return (sidebarItem) => {
        const path = this.$route.fullPath;
        let open = false;
        let func = function (sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach((item) => {
              if (path == item.url) {
                open = true;
              } else if (item.submenu) {
                func(item);
              }
            });
          }
        };
        func(sidebarItem);
        return open;
      };
    },
  },
  watch: {
    reduce(val) {
      if (val == true) {
        this.$store.dispatch("updateSidebarWidth", "reduced");
      } else {
        this.$store.dispatch("updateSidebarWidth", "default");
      }

      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    reduceButton() {
      this.setSidebarWidth();
    },
    $route() {
      if (this.isSidebarActive && this.showCloseButton)
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
    },
  },
  methods: {
    logItem(params) {
      console.log(params);
    },
    sidebarMouseEntered() {
      if (this.reduce) this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      this.isMouseEnter = true;
    },
    sidebarMouseLeave() {
      if (this.reduce) {
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
      }
      this.isMouseEnter = false;
    },
    toggleReduce(val) {
      this.reduceButton = val;
      this.setSidebarWidth();
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 992)
          this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "md");
        else this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "lg");

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
        if (this.reduceButton) this.reduce = false;
        // this.reduceButton = false;
        this.showCloseButton = true;
        this.clickNotClose = false;
        this.$store.dispatch("updateSidebarWidth", "no-sidebar");
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      } else {
        this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "xl");
        if (this.reduceButton) this.reduce = true;
        else this.reduce = false;

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
        if (this.reduceButton && !this.isMouseEnter)
          this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
        else this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);

        this.clickNotClose = true;
        this.showCloseButton = false;
        if (this.reduceSidebar)
          this.$store.dispatch("updateSidebarWidth", "reduced");
        else this.$store.dispatch("updateSidebarWidth", "default");
      }
    },
    psSectionScroll() {
      if (this.$refs.mainSidebarPs.$el.scrollTop > 0)
        this.showShadowBottom = true;
      else this.showShadowBottom = false;
    },
    loggedInSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          console.log("navbar", response);
          this.searchInid = response.data.logged_in_user_id;
          console.log("this.searchInid", this.searchInid);

          this.checkAllowedSidebarItems();

          // this.configureSidebarBasedOnRole(this.searchInid)
          this.searchIn.name = response.data.logged_in_user_name;
          // localStorage.setItem("spoc_name", this.searchIn.id);
          // localStorage.setItem(
          //   "google_meet_link",
          //   response.data.google_meet_link
          // );
        })
        .catch((error) => {
          // if (error.response) {
          //   // console.log('error', error.response.status)
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    checkAllowedSidebarItems() {
      this.actual_side_bar = []
      console.log("SIDEBAR ITEMS NEW---", this.sidebarItems);
      for (let i = 0; i < this.sidebarItems.length; i++) {
        if (accessControl.checkSidebarItems(this.sidebarItems[i].name)) {
          this.actual_side_bar.push(this.sidebarItems[i]);
          // Checking for key submenu in an object
          if (this.sidebarItems[i]["submenu"]) {
            // assigning new submenu
            this.sidebarItems[i].submenu = this.checkSubMenus(
            this.sidebarItems[i].submenu);

            for (let j = 0; j < this.sidebarItems[i].submenu.length; j++) {
              const element = this.sidebarItems[i].submenu[j];
              if(element.name == 'Leads'){
                element.submenu = this.nestedCheckSubmenu(
                    element.submenu);
              }
              if(element.name == 'ExEd'){
                element.submenu = this.nestedCheckSubmenu(
                    element.submenu);
              }
            }

          }
        }
      }
      console.log("actual_side_bar", this.actual_side_bar);
      // this.sidebarItems = this.actual_side_bar;
    },
    checkSubMenus(submenu) {
      let sub_menu = [];
      for (let i = 0; i < submenu.length; i++) {
        if (accessControl.checkSubMenu(submenu[i].name)) {
          sub_menu.push(submenu[i]);
        }
      }
      return sub_menu;
    },
    nestedCheckSubmenu(submenu){
      let nested_submenu = [];
      for (let i = 0; i < submenu.length; i++) {
        if (accessControl.nestedCheckSubmenu(submenu[i].name)) {
          nested_submenu.push(submenu[i]);
        }
      }
      return nested_submenu;
    },
    configureSidebarBasedOnRole(searchInid) {
      console.log("ID", searchInid);
      console.log("SIDEBAR ITEMS OLD---", this.sidebarItems);
      if (localStorage.getItem("role") === "cm1") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          // If statement condition has the items which are not required
          if (
            item.name === "Net Enquiries Transfer" ||
            item.name === "Admin Reports" ||
            (item.name === "ExEd Assignment") | (item.name === "HR Reports") ||
            item.name === "Accounts" ||
            item.name === "Game Plan" ||
            item.name === "WareHouse" ||
            item.name === "Net Enquiry Form" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            // item.name === "Receipts" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Acads Utilization" ||
            item.name === "ExEd Utilization" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "GM Utilization" ||
            item.name === "Edit Mobile Number" ||
            item.name === "Utilization Report" ||
            item.name === "Utilization Report CCF" ||
            item.name === "Google Meet" ||
            item.name === "Warehouse Inventory" ||
            item.name === "Net Enquiries Transfer" ||
            item.name === "Receipts" ||
            item.name === "Dues Summary" ||
            item.name === "ExEd Dues Summary" ||
            item.name === "ExEd SPOC Dashboard" ||
            item.name === "Queue"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
          });
        });
        console.log("SIDEBAR ITEMS", this.sidebarItems);
      } else if (localStorage.getItem("role") === "cm4") {
        let indexarr = [];
        let ListOfItems = [
          "Net Enquiry Form",
          "Excel Upload",
          "Net Enquiry Table",
          "Receipts Table",
          "Receipts",
          "WH Account(WA)",
          "WareHouse(WC)",
          "Tally Receipts",
          "Queue",
          "ExEd Assignment",
          "Google Meet",
          "Dues Summary",
          "Net Enquiries Transfer",
          "ExEd SPOC Dashboard",
          "Whatsapp Broadcast",
          "My Team Attendance",
        ];
        if (localStorage.getItem("team") !== "GM") {
          ListOfItems.push("GM Work Dashboard");
        }
        if (localStorage.getItem("team") !== "SR") {
          ListOfItems.push("SR Work Dashboard");
        }
        if (localStorage.getItem("team") !== "Corporate") {
          ListOfItems.push("Corporate Dashboard");
        }
        if (localStorage.getItem("team") !== "Collections") {
          ListOfItems.push("Collection Dashboard");
        }

        this.sidebarItems.forEach((item) => {
          if (ListOfItems.indexOf(item.name) !== -1) {
            indexarr.push(item.name);
          }
        });
        console.log("list of items", indexarr);
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
          });
        });
        this.sidebarItems.forEach((item) => {
          if (item.name === "DashBoard") {
            //   indexarr.push(item.name);
            item.url = "/adminDashboard";
          }
        });
      } else if (localStorage.getItem("role") === "cm2") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Net Enquiry Form" ||
            item.name === "ExEd Assignment" ||
            item.name === "My Team Attendance" ||
            item.name === "Log Out" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Attendance"
          ) {
            indexarr.push(item);
          }
        });
        // console.log(indexarr);
        // console.log(this.sidebarItems);
        for (var i = this.sidebarItems.length - 1; i >= 0; i--) {
          this.sidebarItems.splice(i, 1);
        }
        indexarr.forEach((item) => {
          this.sidebarItems.push(item);
        });
      } else if (localStorage.getItem("role") === "cm3") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Receipts" ||
            item.name === "Log Out" ||
            item.name === "Tally Receipts" ||
            item.name === "ExEd Assignment" ||
            // item.name === "MWB" ||
            item.name === "EWB"
          ) {
            indexarr.push(item);
          }
          if (item.name === "DashBoard") {
            //   indexarr.push(item.name);
            item.url = "/receipts";
          }
        });
        for (var a = this.sidebarItems.length - 1; a >= 0; a--) {
          this.sidebarItems.splice(a, 1);
        }
        indexarr.forEach((item) => {
          this.sidebarItems.push(item);
        });
      } else if (localStorage.getItem("role") === "wa") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "WH Account(WA)" ||
            item.name === "Log Out" ||
            item.name === "ExEd Assignment" ||
            item.name === "My Team Attendance"
          ) {
            indexarr.push(item);
          }
        });
        for (var t = this.sidebarItems.length - 1; t >= 0; t--) {
          this.sidebarItems.splice(t, 1);
        }
        indexarr.forEach((item) => {
          this.sidebarItems.push(item);
        });
      } else if (localStorage.getItem("role") === "wc") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Net Enquiry Form" ||
            item.name === "ExEd Assignment" ||
            item.name === "Enrollments" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "Dues Summary" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "DashBoard" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "Utilization Report" ||
            item.name === "ExEd Utilization" ||
            item.name === "IIML SPOC Dashboard" ||
            item.name === "Acads Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "Admin Reports") {
              item.submenu = [
                {
                  url: "/warehouse",
                  name: "WareHouse CPA",
                  i18n: "warehouse",
                },
                {
                  url: "/WarehouseCMA",
                  name: "Warehouse CMA",
                  i18n: "warehouse",
                },
                // {
                //   url: "/Warehouseaccount",
                //   name: "WH Account(WA)",
                //   i18n: ""
                // }
              ];
            }
          });
        });
      } else if (localStorage.getItem("role") === "ac1") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Admin Reports" ||
            item.name === "ExEd Assignment" ||
            item.name === "Accounts" ||
            item.name === "Game Plan" ||
            item.name === "WareHouse" ||
            item.name === "Net Enquiry Form" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "Receipts" ||
            item.name === "Utilization Report" ||
            item.name === "ExEd Utilization" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "SR Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "DashBoard") {
              //   indexarr.push(item.name);
              item.url = "/acadsDashboard";
            }
          });
        });
      } else if (localStorage.getItem("role") === "exed") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Admin Reports" ||
            item.name === "ExEd Assignment" ||
            // item.submenu[0].name === 'Assignment' ||
            item.name === "HR Reports" ||
            item.name === "Accounts" ||
            item.name === "Game Plan" ||
            item.name === "WareHouse" ||
            item.name === "Net Enquiry Form" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            // item.name === "Queue" ||
            item.name === "EWB" ||
            item.name === "Dues Summary" ||
            item.name === "Enrollments" ||
            // item.name === "Receipts" ||
            item.name === "Acads Utilization" ||
            item.name === "Utilization Report" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard"
          ) {
            if (item.name === "Admin Reports") {
              console.log("item.name", item.submenu[8]);
              indexarr.push(item.submenu[8].name);
            }
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
          });
        });

        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "DashBoard") {
              //   indexarr.push(item.name);
              item.url = "/iimlDashboard";
            }
          });
        });
      } else if (localStorage.getItem("role") === "exed2") {
        console.log("deep testing", this.searchInid);
        if (searchInid === 574 || searchInid === 972 || searchInid === 764) {
          let indexarr = [];
          this.sidebarItems.forEach((item) => {
            // console.log("sidebarItems", item)

            if (
              item.name === "Admin Reports" ||
              // item.submenu[0].name === 'Assignment' ||
              item.name === "HR Reports" ||
              item.name === "Accounts" ||
              item.name === "Game Plan" ||
              item.name === "WareHouse" ||
              item.name === "Net Enquiry Form" ||
              item.name === "Excel Upload" ||
              item.name === "Net Enquiry Table" ||
              item.name === "Receipts Table" ||
              item.name === "WH Account(WA)" ||
              item.name === "WareHouse(WC)" ||
              item.name === "Tally Receipts" ||
              // item.name === "Queue" ||
              item.name === "EWB" ||
              item.name === "Dues Summary" ||
              item.name === "Enrollments" ||
              // item.name === "Receipts" ||
              item.name === "Acads Utilization" ||
              item.name === "Utilization Report" ||
              item.name === "SR Utilization" ||
              item.name === "SR Work Dashboard" ||
              item.name === "Corporate Dashboard" ||
              item.name === "Collection Dashboard" ||
              item.name === "GM Work Dashboard"
            ) {
              // console.log("item.name", item)
              indexarr.push(item.name);
            }
          });
          indexarr.forEach((arr) => {
            this.sidebarItems.forEach((item, index) => {
              if (item.name === arr) {
                this.sidebarItems.splice(index, 1);
              }
            });
          });

          indexarr.forEach((arr) => {
            this.sidebarItems.forEach((item, index) => {
              if (item.name === arr) {
                this.sidebarItems.splice(index, 1);
              }
              if (item.name === "DashBoard") {
                //   indexarr.push(item.name);
                item.url = "/iimlDashboard";
              }
            });
          });
        } else {
          console.log("testing");
          let indexarr = [];
          this.sidebarItems.forEach((item) => {
            // console.log("sidebarItems", item)

            if (
              item.name === "Admin Reports" ||
              item.name === "ExEd Assignment" ||
              // item.submenu[0].name === 'Assignment' ||
              item.name === "HR Reports" ||
              item.name === "Accounts" ||
              item.name === "Game Plan" ||
              item.name === "WareHouse" ||
              item.name === "Net Enquiry Form" ||
              item.name === "Excel Upload" ||
              item.name === "Net Enquiry Table" ||
              item.name === "Receipts Table" ||
              item.name === "WH Account(WA)" ||
              item.name === "WareHouse(WC)" ||
              item.name === "Tally Receipts" ||
              // item.name === "Queue" ||
              item.name === "EWB" ||
              item.name === "Dues Summary" ||
              item.name === "Enrollments" ||
              // item.name === "Receipts" ||
              item.name === "Acads Utilization" ||
              item.name === "Utilization Report" ||
              item.name === "SR Utilization" ||
              item.name === "SR Work Dashboard" ||
              item.name === "Corporate Dashboard" ||
              item.name === "Collection Dashboard" ||
              item.name === "GM Work Dashboard"
            ) {
              // console.log("item.name", item)
              indexarr.push(item.name);
            }
          });
          indexarr.forEach((arr) => {
            this.sidebarItems.forEach((item, index) => {
              if (item.name === arr) {
                this.sidebarItems.splice(index, 1);
              }
            });
          });

          indexarr.forEach((arr) => {
            this.sidebarItems.forEach((item, index) => {
              if (item.name === arr) {
                this.sidebarItems.splice(index, 1);
              }
              if (item.name === "DashBoard") {
                //   indexarr.push(item.name);
                item.url = "/iimlDashboard";
              }
            });
          });
        }
      } else if (localStorage.getItem("role") === "fi1") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Tally Receipts" ||
            item.name === "ExEd Assignment" ||
            item.name === "My Team Attendance" ||
            item.name === "Log Out" ||
            item.name === "Receipts" ||
            item.name === "EWB"
          ) {
            indexarr.push(item);
          }
        });
        for (var e = this.sidebarItems.length - 1; e >= 0; e--) {
          this.sidebarItems.splice(e, 1);
        }
        indexarr.forEach((item) => {
          this.sidebarItems.push(item);
        });
      } else if (localStorage.getItem("role") === "ac2") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Admin Reports" ||
            item.name === "ExEd Assignment" ||
            item.name === "Accounts" ||
            item.name === "Game Plan" ||
            item.name === "WareHouse" ||
            item.name === "Net Enquiry Form" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "Utilization Report" ||
            item.name === "ExEd Utilization" ||
            item.name === "ExEd2 Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "DashBoard") {
              //   indexarr.push(item.name);
              item.url = "/acadsAdminDashboard";
            }
          });
        });
      } else if (localStorage.getItem("role") === "fe") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Net Enquiry Form" ||
            item.name === "ExEd Assignment" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "DashBoard" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "Utilization Report" ||
            item.name === "ExEd Utilization" ||
            item.name === "IIML SPOC Dashboard" ||
            item.name === "Acads Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "Admin Reports") {
              item.submenu = [
                {
                  url: "/MrReco",
                  name: "MR Reco",
                  i18n: "MRReco",
                },

                {
                  url: "/tallyReceipts",
                  name: "Tally Receipts",
                  i18n: "Tally Receipts",
                },

                {
                  url: "/warehouse",
                  name: "WareHouse(WC)",
                  i18n: "warehouse",
                },
                // {
                //   url: "/Warehouseaccount",
                //   name: "WH Account(WA)",
                //   i18n: ""
                // }
              ];
            }
          });
        });
      } else if (localStorage.getItem("role") === "cmca") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Net Enquiry Form" ||
            item.name === "ExEd Assignment" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "DashBoard" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "ExEd Utilization" ||
            item.name === "IIML SPOC Dashboard" ||
            item.name === "Acads Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "Admin Reports") {
              item.submenu = [
                {
                  url: "/CallLogs",
                  name: "Call Logs",
                  i18n: "calllogs",
                },
              ];
            }
          });
        });
      } else if (localStorage.getItem("role") === "baca") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Net Enquiry Form" ||
            item.name === "ExEd Assignment" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Queue" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "DashBoard" ||
            item.name === "Utilization Report" ||
            item.name === "IIML SPOC Dashboard" ||
            item.name === "Acads Utilization"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
            if (item.name === "Admin Reports") {
              item.submenu = [
                {
                  url: "/IIMLManagementcalllogs",
                  name: "IIML CallLogs",
                  i18n: "iilmcalllogs",
                },
              ];
            }
          });
        });
      } else if (localStorage.getItem("role") === "hr") {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            
            item.name === "Queue" ||
            item.name === "Google Meet" ||
            item.name === "Utilization Report" ||
            item.name === "Utilization Report CCF" ||
            item.name === "ExEd SPOC Dashboard" ||
            item.name === "ExEd Dues Summary" ||
            item.name === "Dues Summary" ||
            item.name === "Receipts" ||
            item.name === "Enrollments" ||
            item.name === "Leave Requests" ||
            item.name === "Whatsapp Broadcast" ||
            item.name === "Warehouse Inventory" ||
            item.name === "MWB" ||
            item.name === "EWB" ||
            item.name === "DashBoard" ||
            item.name === "Net Enquiries Transfer" ||
            item.name === "Admin Reports" ||
            item.name === "ExEd Assignment" ||
            item.name === "Accounts" ||
            item.name === "Game Plan" ||
            item.name === "WareHouse" ||
            item.name === "Net Enquiry Form" ||
            item.name === "Excel Upload" ||
            item.name === "Net Enquiry Table" ||
            item.name === "Receipts Table" ||
            item.name === "WH Account(WA)" ||
            // item.name === "Receipts" ||
            item.name === "WareHouse(WC)" ||
            item.name === "Tally Receipts" ||
            item.name === "Acads Utilization" ||
            item.name === "ExEd Utilization" ||
            item.name === "ExEd2 Utilization" ||
            item.name === "SR Utilization" ||
            item.name === "SR Work Dashboard" ||
            item.name === "Corporate Dashboard" ||
            item.name === "Collection Dashboard" ||
            item.name === "GM Work Dashboard" ||
            item.name === "DashBoard" ||
            item.name === "IIML SPOC Dashboard" ||
            item.name === "Receipts" ||
            item.name === "Dues Summary" ||
            item.name === "Enrollments" ||
            item.name === "MWB" ||
            item.name === "EWB" ||
            item.name === "Warehouse Inventory" ||
            item.name === "Leave Requests" ||
            item.name === "ExEd Dues Summary" ||
            item.name === "ExEd SPOC Dashboard" ||
            item.name === "ExEd Dues Summary" ||
            item.name === "ExEd Utilization" ||
            item.name === "Utilization Report CCF" ||
            item.name === "Utilization Report" ||
            item.name === "Google Meet"
          ) {
            indexarr.push(item.name);
          }
        });
        indexarr.forEach((arr) => {
          this.sidebarItems.forEach((item, index) => {
            if (item.name === arr) {
              this.sidebarItems.splice(index, 1);
            }
          });
        });
        console.log("SIDEBAR _ HR", this.sidebarItems);
      } else {
        let indexarr = [];
        this.sidebarItems.forEach((item) => {
          if (
            item.name === "Log Out" ||
            item.name === "Attendance" ||
            item.name === "My Team Attendance" ||
            item.name === "ExEd Assignment"
          ) {
            indexarr.push(item);
          }
        });
        for (var gg = this.sidebarItems.length - 1; gg >= 0; gg--) {
          this.sidebarItems.splice(gg, 1);
        }
        indexarr.forEach((item) => {
          this.sidebarItems.push(item);
        });
      }

      // if(localStorage.getItem("role") === "cm1"){
      //   let items = []
      //   this.sidebarItems.forEach(item => {
      //       for (let i = 0; i < items.length; i++) {
      //           if(items[i].name === item.name){
      //             continue;
      //           }else {
      //             items.push(item)
      //           }

      //       }
      //   });
      //   console.log("TESTING",items)
      // }
    },
  },
  components: {
    VxSidebarGroup,
    VxSidebarItem,
    VuePerfectScrollbar,
  },
  mounted() {
    // PRIMARY ACL;
    // cm1 => SPOC
    // cm2 => CHANDHAN (NET ENQUIRIES)
    // cm3 => ACCOUNTS GUY
    // cm4 => ADMIN (NIDA)
    this.loggedInSpoc();
    eventbus.$on("sidebar-config-queue", (bool) => {
      // console.log("got the config");
      this.toggleReduce(bool);
    });

    // eventbus.$on("configure-sidebar-based-on-role", () => {

    //   this.configureSidebarBasedOnRole();
    // });

    this.copysidebar = this.sidebarItems;
    // this.configureSidebarBasedOnRole();

    // PRIMARY ACL;
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    console.log("this is before destroy");
    // this.sidebarItems = this.copysidebar;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxSidebar.scss";
</style>
