<template>
  <vs-popup class="holamundo"  title="SR Connect Request" :active.sync="open" style="z-index:53002">
    <vs-row >
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
        <b style="color: gray; font-size: 12px">Comments:</b>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
        <vs-textarea v-model="textarea" />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="border" @click="open = false">Save</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from '../eventbus.js'
export default {
  mounted() {
    EventBus.$on('poa-escalation', (studentDetails) => {
      console.log(`Student details from SR Connect popup`,  studentDetails);
      this.open = true
    })
  },
  data() {
    return {
      textarea: '',
      open: false
    }
  }
}
</script>

<style>

</style>
