<template>
  <vs-popup title="OPTIONS" :active.sync="open" style="z-index: 53005">
    <vs-tabs v-model="link" vs-position="right">
      <vs-tab label="Call">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <h3 style="color: gray">
              <b>Call this Number? ({{ maskedNumber }})</b>
            </h3>
            <vs-button
              style="margin-left: 2%"
              color="primary"
              type="border"
              id="click-to-call"
              icon="local_phone"
              class="vs-con-loading__container"
              @click="call"
            ></vs-button>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="View">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <h3 v-if="hidden === true" style="color: gray">
              <b>{{ maskedNumber }}</b>
            </h3>
            <h3 v-else-if="hidden === false" style="color: gray">
              <b>{{ mobileNumber }}</b>
            </h3>
            <vs-button
              style="margin-left: 2%"
              color="primary"
              type="border"
              icon="visibility"
              @click="switchHidden"
            ></vs-button>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Invalid">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              :color="buttonInfo.color"
              type="gradient"
              @click="markThemobile"
              >{{ buttonInfo.text }}</vs-button
            >
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Primary">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              color="success"
              type="gradient"
              @click="markAsPrimary"
              v-if="this.index.pri_mary == 0"
              >Mark as Primary</vs-button
            >

            <vs-button
              color="success"
              type="gradient"
              @click="markAsPrimary"
              disabled
              v-else
              >Mark as Primary</vs-button
            >
          </vs-col>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Edit">
        <div>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-input style="width: 10%" label-placeholder="Code" v-model="country_code" />
            <vs-input icon="add" label-placeholder="Mobile Number" v-model="editNumber" />
            <vs-button
              style="margin-top: 3%; margin-left: 2%"
              color="warning"
              type="gradient"
              icon="add"
              @click="editMobile"
            >SAVE</vs-button>
          </vs-col>
        </div>
      </vs-tab>-->
      <!-- <vs-tab label="Delete">
        <div>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <h3 style="color: gray">
              <b>Delete this number?</b>
            </h3>
            <vs-button
              style="margin-left: 2%"
              color="danger"
              type="gradient"
              @click="deleteMobile"
            >YES</vs-button>
          </vs-col>
        </div>
      </vs-tab>-->
    </vs-tabs>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import eventbus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("open-mobile-options-popup", (payload) => {
      this.link = 0;
      this.assignButtonInfo(payload[2]);
      this.mobileNumber = payload[0];
      this.details = payload[1];
      this.maskedNumber = payload[2].masked_number;
      this.index = payload[2];
      this.editNumber = this.mobileNumber;
      this.hidden = true;
      this.open = true;
    });
  },
  data() {
    return {
      buttonInfo: {
        url: "",
        color: "",
        text: "",
        id: "",
      },
      link: 0,
      mobileInfo: "",
      details: "",
      open: false,
      hidden: true,
      mobileNumber: "",
      index: "",
      maskedNumber: "",
      editNumber: "",
      country_code: "",
    };
  },
  methods: {
    assignButtonInfo(payload) {
      if (payload.invalid === 0) {
        this.buttonInfo.url = "markMobileAsInvalid";
        this.buttonInfo.color = "danger";
        this.buttonInfo.text = "Mark as Invalid";
        this.buttonInfo.willChangeTo = 1;
      } else {
        this.buttonInfo.url = "markMobileAsValid";
        this.buttonInfo.color = "success";
        this.buttonInfo.text = "Mark as Valid";
        this.buttonInfo.willChangeTo = 0;
      }
      this.buttonInfo.id = payload.id;
    },
    markAsPrimary() {
      // return;
      let obj = {
        mobile_id: this.buttonInfo.id,
      };
      axios
        .post(`${constants.SERVER_API}markPrimaryMobile`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            eventbus.$emit("reload-bigpopup-data");
            eventbus.$emit("reload-queue-data");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    markThemobile() {
      // console.log(this.buttonInfo.url, this.buttonInfo.id);
      let obj = {
        mobile_id: this.buttonInfo.id,
      };
      axios
        .post(`${constants.SERVER_API}${this.buttonInfo.url}`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            let newobj = {
              id: this.index.id,
              masked_number: this.index.masked_number,
              invalid: this.buttonInfo.willChangeTo,
            };
            this.$store.commit("EDIT_MOBILE", [
              this.details,
              this.index,
              newobj,
            ]);
            if (this.$route.path === "/queue") {
              EventBus.$emit("update-queue");
            }
            this.open = false;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async editMobile() {
      let id = this.index.id;
      let obj = {
        mobile_id: id,
        edited_number: this.editNumber,
        country_code: this.country_code,
      };
      axios
        .post(`${constants.SERVER_API}editMobile`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status === "success") {
            let newobj = {
              id: response.data.edited_mobile_id,
              masked_number: response.data.edited_masked_number,
            };
            this.$vs.notify({
              title: "Success",
              text: "This Number is successfully edited",
              color: "success",
            });
            this.$store.commit("EDIT_MOBILE", [
              this.details,
              this.index,
              newobj,
            ]);
            // EventBus.$emit("reload-data");
            // this.$nextTick(() => {
            // console.log("next tick next tick");
            // });
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: `${response.data.message}`,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     // this.EmitEvent('close-popup-redirect')
          //     this.ReDirect_to_login();
          //     //
          //   }
          // }
          this.handleError(error);
        });
    },
    async call() {
      this.$vs.loading({
        container: "#click-to-call",
        scale: 0.45,
      });
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/placementDrive" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error",
            });
            this.$vs.loading.close("#click-to-call > .con-vs-loading");
            return;
          }
        }
      }
      axios
        .get(
          `${constants.SERVER_API}clickToCallPusher?mobile_id=${this.index.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$vs.loading.close("#click-to-call > .con-vs-loading");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async deleteMobile() {
      let id = this.index.id;
      axios
        .post(
          `${constants.SERVER_API}deleteMobile`,
          {
            mobile_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Success",
              text: "This Number is successfully deleted",
              color: "success",
            });
            this.$store.commit("DELETE_MOBILE", [this.details, this.index]);
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "An Error occured while deleting the mobile number, Please Try Again.",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
          this.handleError(error);
        });
    },
    switchHidden() {
      if(this.mobileNumber != 'Unauthorized'){
        this.hidden = !this.hidden;
      }else{
        this.$vs.notify({
          time: 3000,
          title: 'Unauthorized',
          text: "You are not authorized to view this mobile number.",
          color: "danger",
        });
      }
    },
    openAddEngagement() {
      this.open = false;
      EventBus.$emit("add-engagement", this.details);
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
  },
};
</script>

<style></style>
