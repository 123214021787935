<template>
  <vs-popup
    v-if="open"
    vs-type="flex"
    :id="'popup'"
    class="size"
    fullscreen
    :title="
      studentDetails.cpa.person_name +
        ' - ' +
        studentDetails.cpa.batch +
        ' - ' +
        this.studentDetails.cpa.next_call
    "
    :active.sync="open"
  >
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-row>
          <card1 v-if="open" :studentDetails="studentDetails" />
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <card2
          v-if="open"
          :payload="studentDetails"
          :selected="sectionselected"
        />
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import Card1 from "./Card1.vue";
import Card2 from "./Card2.vue";
import EventBus from "../../../../eventbus";
export default {
  components: {
    Card1,
    Card2
  },
  mounted() {
    EventBus.$on("update_purchases", payload => {
      this.studentDetails.purchases.push(payload[1]);
      payload[2].forEach(deliverable => {
        this.studentDetails.deliverables.push(deliverable);
      });
    });
    EventBus.$on("open-student-details-popup-call", () => {
      this.sectionselected = "Call";
    });
    EventBus.$on("open-student-details-popup-whatsapp", () => {
      this.sectionselected = "Whatsapp";
    });
    EventBus.$on("open-student-report-popup", payload => {
      EventBus.$emit("open-cards", payload);
      this.studentDetails = payload;
      EventBus.$emit("mwb-cards", this.studentDetails);
      this.open = true;
    });
    EventBus.$on("reload-dashboard", () => {
      this.savedashboard = true;
    });
    EventBus.$on("close-popup-redirect", () => {
      this.open = false;
    });
  },
  data() {
    return {
      savedashboard: false,
      open: false,
      studentDetails: "",
      vuexIndex: 0,
      sectionselected: "All",
      elementcountmain: ""
    };
  },
  methods: {
    EmitEvent(emit, payload) {
      EventBus.$emit(emit, payload);
    }
  },
  watch: {
    open: function(value) {
      if (value === false) {
        if (this.$route.path === "/") {
          EventBus.$emit("update-dashboard");
        }
        if (this.$route.path === "/coverage") {
          EventBus.$emit("refreshCoveragePopup");
        }
      }
    }
  }
};
</script>

<style></style>
