<template>
  <vs-popup title="OPTIONS" :active.sync="open" style="z-index:53005">
    <vs-tabs vs-position="right">
      <vs-tab label="Email">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <h3 style="color: gray">
              <b>Email?</b>
            </h3>
            <vs-button
              style=" margin-left: 2%"
              color="primary"
              type="border"
              icon="email"
              @click="emailPerson"
            ></vs-button>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="View">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <h3 v-if="hidden === true" style="color: gray">
              <b>{{ maskedemail.masked_email }}</b>
            </h3>
            <h3 v-else-if="hidden === false" style="color: gray">
              <b>{{ email }}</b>
            </h3>
            <vs-button
              style=" margin-left: 2%"
              color="primary"
              type="border"
              icon="visibility"
              @click="switchHidden"
            ></vs-button>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Primary">
        <div>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button color="success" type="gradient" @click="markAsPrimary"
              >Mark as Primary</vs-button
            >
          </vs-col>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Edit">
        <div>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-input icon="add" label-placeholder="Email" v-model="email" />
            <vs-button
              style="margin-top: 3%; margin-left: 2%"
              color="warning"
              type="gradient"
              icon="add"
              @click="editEmail"
            >SAVE</vs-button>
          </vs-col>
        </div>
      </vs-tab>-->
      <!-- <vs-tab label="Delete">
        <div>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <h3 style="color: gray">
              <b>Delete this Email?</b>
            </h3>
            <vs-button
              style="margin-left: 2%"
              color="danger"
              type="gradient"
              @click="deleteEmail"
            >YES</vs-button>
          </vs-col>
        </div>
      </vs-tab>-->
    </vs-tabs>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import eventbus from "../eventbus.js";
export default {
  mounted() {
    EventBus.$on("open-email-options-popup", (payload) => {
      this.email = payload[0];
      this.details = payload[1];
      this.maskedemail = payload[2];
      this.hidden = true;
      this.open = true;
    });
  },
  data() {
    return {
      details: "",
      open: false,
      hidden: true,
      email: "",
      maskedemail: "",
    };
  },
  methods: {
    markAsPrimary() {
      // console.log(this.details);
      // return;
      let obj = {
        email_id: this.maskedemail.id,
      };
      // console.log(obj);
      // return;
      axios
        .post(`${constants.SERVER_API}markPrimaryEmail`, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.open = false;
            eventbus.$emit("reload-bigpopup-data");
            eventbus.$emit("reload-queue-data");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async editEmail() {
      let id = this.maskedemail.id;
      axios
        .post(
          `${constants.SERVER_API}editEmail`,
          {
            email_id: id,
            edited_email: this.email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            let newobj = {
              id: response.data.edited_email_id,
              masked_email: response.data.edited_masked_email,
            };
            this.$vs.notify({
              title: "Success",
              text: "This Email is successfully edited",
              color: "success",
            });
            this.$store.commit("EDIT_EMAIL", [
              this.details,
              this.maskedemail,
              newobj,
            ]);
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text:
                "An Error occured while editing the editing the email, Please Try Again.",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
          this.handleError(error);
        });
    },
    emailPerson() {
      EventBus.$emit("open-email-engagement");
    },
    async deleteEmail() {
      let id = this.maskedemail.id;
      axios
        .post(
          `${constants.SERVER_API}deleteEmail`,
          {
            email_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Success",
              text: "This Email is successfully deleted",
              color: "success",
            });
            this.$store.commit("DELETE_EMAIL", [
              this.details,
              this.maskedemail,
            ]);
            this.open = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text:
                "An Error occured while deleting the email, Please Try Again.",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //     // this.EmitEvent('close-popup-redirect')
          //   }
          // }
          this.handleError(error);
        });
      // console.log(postReq)
    },
    switchHidden() {
      this.hidden = !this.hidden;
    },
  },
};
</script>

<style></style>
