<template>
  <vs-popup class="holamundo" title="EmailForm" :active.sync="open">
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">To:</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
        <vs-input />
      </vs-col>
    </vs-row>
    <vs-divider></vs-divider>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">Message:</vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
        <vs-textarea height="200" v-model="textarea2" />
      </vs-col>
    </vs-row>
    <vs-divider></vs-divider>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
        <vs-button color="dark" type="border" @click="open = false">Send</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
export default {
  watch: {
    templates: function(type) {
      this.types.forEach(value => {
        if (value.select === type) {
          this.textarea2 = value.text;
        }
      });
    }
  },
  mounted() {
    EventBus.$on("open-asdaw-engagement", () => {
      this.open = true;
    });
  },
  data() {
    return {
      templates: "custom",
      open: false,
      textarea2: "",
      types: [
        {
          select: "custom",
          text: ""
        },
        {
          select: "Template 1",
          text: "message for template 1"
        },
        {
          select: "Template 2",
          text: "message for template 2"
        },
        {
          select: "Template 3",
          text: "message for template 3"
        }
      ]
    };
  }
};
</script>

<style>
</style>
