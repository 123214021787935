<template>
    <div>
        <vs-card class="card-width-outer">
            <div class="card-header">
                <p>{{subjects[subject_details.subject_id]}}</p>
            </div>

            <vs-card :class="['card-width',class_color[subject_details.subject_id]]" style="margin-left: -10px;">
                <!-- <p class="webinars-attended" @click="openPopup(subject_details.int_subject_id)"> {{
                getWebinarAttended(subject_details.int_subject_id) }} webinars attended</p> -->

                <div class="card-text-back" @click="openPopup(subject_details.subject_id)" v-if="webinar_attended != undefined">
                    <h6 class="card-text">Webinars Attended :  {{ getWebinarAttended(subject_details.subject_id) }} </h6>
                </div>
                <div class="card-text-back" v-if="subject_details.gameplanData != undefined">
                    <h6 class="card-text">Date : {{ subject_details.gameplanData.date_of_exam}}</h6>
                </div>

                <div class="card-text-back" v-if="subject_details.gameplanData != undefined">
                    <h6 class="card-text">Status : {{ subject_details.gameplanData.exam_status_type}}</h6>
                </div>

                <p class="otb">OTB</p>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                        <p class="otb">Videos</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                        <p class="otb">{{subject_details.total_videos_viewed}}/{{subject_details.total_video_cnt}}</p>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                        <p class="otb">MCQs</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                        <p class="otb">
                            {{subject_details.attemptedquestions_count}}/{{subject_details.total_questions}}</p>
                    </vs-col>
                </vs-row>
                <!-- <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                        <p class="otb">SIMS</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                        <p class="otb">
                            {{subject_details.sims_attemptedquestions_count}}/{{subject_details.sims_total_questions}}
                        </p>
                    </vs-col>
                </vs-row> -->

            </vs-card>
        </vs-card>
    </div>
</template>

<script>

export default {
    props: ["subject_details", "webinar_attended"],
    data() {
        return {
            class_color: {
                "101": "aud",
                "102": "bec",
                "103": "far",
                "104": "reg",
                "105" : "aud",
                "106" : "bec"

            },
            subjects: {
                101: "AUD",
                102: "BEC",
                103: "FAR",
                104: "REG",
                105: "Part - 1",
                106: "Part - 2",
            }
        }
    },
    mounted() {
        console.log("subject_details in child", this.subject_details)
        console.log("webinar_attended in child", this.webinar_attended)

    },
    methods: {
        openPopup(subject_id) {
            this.$emit("open-popup", subject_id)
        },
        getWebinarAttended(subject_id) {
            let count = 0
            if (this.webinar_attended.length > 0) {
                for (let i = 0; i < this.webinar_attended.length; i++) {
                    if (this.webinar_attended[i].subject_id == subject_id) {
                        count++;
                    }

                }
            }
            return count
        }
    }
}
</script>

<style scoped>
.card-width {
    width: 324.6px;
    height: 135.72px;
    border-radius: 15px;
    /* width: 102%;
  height: 84%; */

}

.aud {
    background-color: #DEEAC6;
}

.bec {
    background-color: #FEEDC2;
}

.far {
    background-color: #C3E6F9;
}

.reg {
    background-color: #F3C1C0;
}

.webinars-attended {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-decoration-line: underline;
    color: #000000;
    padding-left: 10px;
}

.card-header {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    padding: 10px;

}

.card-width-outer {
    width: 324.6px;
    height: 150.57px;
    background: #FFFFFF;
    border-radius: 15px;
}

.otb {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #000000;
}

.popup-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #000000;

}

.popup-header {
    width: 563.62px;
    height: 57.12px;

    background: #0044BA;
    border-radius: 15px;
}

.card-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin-left: 3px;
}

.card-text-back {
    width: 98%;
    height: 20.22px;
    background-color: white;
    padding: 1%;
    margin-bottom: 9px;
    border-radius: 5px;
}
</style>