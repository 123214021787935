<template>
  <div>
    <p style="padding-left:4%;font-size: 13px;color: red">*In case of Co-applicant Loan, please fill the Co-applicant details instead of the Candidate details.</p>
    <div>
      
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>PAN *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-input
                type="text"
                class="w-full"
                placeholder="PAN"
                :maxlength="10"
                :minlength="10"
                v-model="liqui_loans.pan"
              ></vs-input>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Full Name *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              type="text"
              class="w-full"
              placeholder="Full Name"
              :maxlength="100"
              v-model="liqui_loans.full_name"
            ></vs-input>
          </vs-col>
        </vs-row>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Gender *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-radio
                v-model="liqui_loans.gender"
                vs-name="gender"
                vs-value="Male"
                >Male</vs-radio
              >
              <vs-radio
                style="padding-left: 3%"
                v-model="liqui_loans.gender"
                vs-name="gender"
                vs-value="Female"
                >Female</vs-radio
              >
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Date of Birth *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              type="date"
              id="birthday"
              class="w-full"
              v-model="date"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Email *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-input
                type="text"
                class="w-full"
                placeholder="Email"
                :maxlength="100"
                v-model="liqui_loans.email"
              ></vs-input>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Present Age</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              type="text"
              class="w-full"
              disabled
              v-model="currentAge"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Courses *</span></p>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-select
                width="250px"
                label="Select Course "
                v-model="liqui_loans.course"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in course_list"
                />
              </vs-select>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Contact Number*</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-on:keypress="isNumber(event)"
              :maxlength="10"
              :minlength="10"
              type="text"
              class="w-full"
              placeholder="Contact Number"
              v-model="liqui_loans.mobile_number"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Loan Type *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <!-- <vs-radio
                v-model="selected_loan_type"
                vs-name="loan_type"
                vs-value="PL"
                >PL</vs-radio
              > -->
              <vs-radio
                v-model="selected_loan_type"
                vs-name="loan_type"
                vs-value="CL"
                >CL</vs-radio
              >
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Amount *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              class="w-full"
              placeholder="Amount"
              v-on:keypress="isNumber(event)"
              v-model="liqui_loans.amount"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <!-- <vs-row v-if="show_tenure_emi" vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <p style="font-size: 12px"><span>Schemes *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
              v-for="item in scheme_data"
              :key="item.scheme_code"
            >
              <vs-radio
                v-model="selected_scheme"
                :vs-value="item"
                ><p>{{ item.scheme_name }}</p>
                <p>{{ item.roi_type }}</p></vs-radio
              >
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row> -->
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Tenure *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-radio
                v-model="liqui_loans.tenure"
                vs-name="tenure"
                vs-value="3"
                >3</vs-radio
              >
              <vs-radio
                style="padding-left: 3%"
                v-model="liqui_loans.tenure"
                vs-name="tenure"
                vs-value="6"
                >6</vs-radio
              >
              <!-- <vs-radio
                style="padding-left: 3%"
                v-model="liqui_loans.tenure"
                vs-name="tenure"
                vs-value="9"
                >9</vs-radio
              > -->
              <!-- <vs-radio
                style="padding-left: 3%"
                v-model="liqui_loans.tenure"
                vs-name="tenure"
                vs-value="12"
                >12</vs-radio
              > -->
              <!-- <vs-radio
                style="padding-left: 3%"
                v-model="liqui_loans.tenure"
                vs-name="tenure"
                vs-value="24"
                >24</vs-radio
              > -->
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Adhaar Number *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              :maxlength="12"
              :minlength="12"
              type="text"
              class="w-full"
              placeholder="Adhaar Number"
              v-model="liqui_loans.adhaar_number"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Full Address *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-textarea
                type="text"
                label="Full Address"
                v-model="liqui_loans.full_address"
              />
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Pin Code *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-on:keypress="isNumber(event)"
              :maxlength="6"
              :minlength="6"
              type="text"
              class="w-full"
              placeholder="Pin code"
              v-model="liqui_loans.pincode"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>City *</span></p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-input
                type="text"
                :maxlength="25"
                class="w-full"
                placeholder="City"
                v-model="liqui_loans.city"
              ></vs-input>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>State *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              type="text"
              :maxlength="25"
              class="w-full"
              placeholder="State"
              v-model="liqui_loans.state"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>

      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-row type="flex" style="margin-top: 2%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="font-size: 12px"><span>Occupation *</span></p>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-select
                width="250px"
                label="Select Occupation Type"
                v-model="liqui_loans.occupation"
              >
                <vs-select-item
                  :key="index"
                  :value="item.text"
                  :text="item.text"
                  v-for="(item, index) in occupation"
                />
              </vs-select>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Name Of Comapny *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              type="text"
              :maxlength="100"
              class="w-full"
              placeholder="Name of Company"
              v-model="liqui_loans.name_of_company"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px"><span>Monthly income *</span></p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              v-on:keypress="isNumber(event)"
              type="text"
              class="w-full"
              placeholder="Monthly Income"
              v-model="liqui_loans.monthly_income"
            ></vs-input>
          </vs-col>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="4"
          >
            <p style="font-size: 12px">
              <span>Alternate Contact Number * </span>
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="8"
          >
            <vs-input
              :maxlength="10"
              :minlength="10"
              type="text"
              class="w-full"
              v-on:keypress="isNumber(event)"
              placeholder="Contact Number"
              v-model="liqui_loans.contact_number"
            ></vs-input>
          </vs-col>
        </vs-col>
      </vs-row>
      <vs-row  style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            color="primary"
            style="align: right"
            @click="applyLoan()"
            type="filled"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
      
    </div>

    <vs-popup class="holamundo" title="Are you sure?" icon="error_outline" :active.sync="open_loan_popup">
      <vs-row>
        <vs-col vs-type='flex' vs-align='center' vs-justify='flex-end' vs-w='5'>
          <vs-row>
            <vs-col style="padding-left: 0;" vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='12'>
              <p>Lead Name : </p>
            </vs-col>
            <vs-col style="padding-left: 0;" vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='12'>
              <b>{{ studentDetails.mwb.person_name }}</b>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-type='flex' vs-align='center' vs-justify='center' vs-w='2'>
          <vs-icon style="color: red;" icon="error_outline" size="large"></vs-icon>
        </vs-col>
        <vs-col vs-type='flex' vs-align='center' vs-justify='flex-start' vs-offset="1" vs-w='4'>
          <vs-row>
            <vs-col style="padding-left: 0;" vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='12'>
              <p>Applicant Name : </p>
            </vs-col>
            <vs-col style="padding-left: 0;" vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='12'>
              <b>{{ liqui_loans.full_name }}</b>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <vs-row class="mt-6">
        <vs-col vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='4'>
          <p>Can Id : <b>{{ studentDetails.mwb.identity }}</b></p>
        </vs-col>
        <vs-col vs-type='flex' vs-align='center' vs-justify='center' vs-w='4'>
          <p>Course : <b>{{ liqui_loans.course }}</b></p>
        </vs-col>
        <vs-col vs-type='flex' vs-align='center' vs-justify='flex-start' vs-w='4'>
          <p>Amount : <b>{{  liqui_loans.amount }}</b></p>
        </vs-col>
      </vs-row>
      <vs-row class="mt-6">
        <vs-col vs-type='flex' vs-align='center' vs-justify='center' vs-w='12'>
          <p>The name given in loan application is different from the lead name. Are you sure you want to submit the loan application?</p>
        </vs-col>
      </vs-row>
      <br/>
      <vs-row>
        <vs-col vs-type='flex' vs-align='center' vs-justify='center' vs-w='12'>
          <vs-button class="mr-4" color="danger" @click='open_loan_popup = false'>No</vs-button>&nbsp;
          <vs-button color="primary" @click='submitLoanApplication'>Yes</vs-button>
        </vs-col>
      </vs-row>	
    </vs-popup>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      open_loan_popup: false,
      eligible_age: "no",
      selected_scheme: {},
      show_tenure_emi: false,
      scheme_data: [],
      date: "",
      event: "",
      liqui_loans: {
        pan: "",
        full_name: "",
        gender: "",
        monthly_income: null,
        email: "",
        loan_type: "",
        amount: null,
        tenure: null,
        adhaar_number: null,
        pincode: null,
        contact_number: null,
        dob: "",
        state: "",
        city: "",
        full_address: "",
        name_of_company: "",
        occupation: "",
        mobile_number: null,
        mwb_id: "",
        identity: "",
        scheme_id: "",
        scheme_code: "",
        course: "",
      },
      selected_loan_type: "CL",
      occupation: [
        { text: "Salaried", value: 1 },
        { text: "SelfEmployed", value: 2 },
      ],
      course_list: [
        { text: "CPA", value: 'CPA' },
        { text: "CMA", value: 'CMA' },
        { text: "CFA", value: 'CFA' },
        { text: "FRM", value: 'FRM' },
        { text: "CPA USP Bridge Course – Public Accounting", value: 'CPA USP Arka BU' },
      ],
      loader : false,
      currentAge: '',
    };
  },
  mounted() {
    // console.log("student deatails in applyloan", this.studentDetails);
    // for (let i = 0; i < this.studentDetails.emails.length; i++) {
    //   const element = this.studentDetails.emails[i];
    //   if (element.pri_mary == 1) {
    //     this.liqui_loans.email = element.email;
    //   } else {
    //     this.liqui_loans.email = this.studentDetails.emails[0].email;
    //   }
    // }
    // for (let i = 0; i < this.studentDetails.mobiles.length; i++) {
    //   const element = this.studentDetails.mobiles[i];
    //   if (element.pri_mary == 1) {
    //     this.liqui_loans.mobile_number = element.phone_number;
    //   } else {
    //     this.liqui_loans.mobile_number =
    //       this.studentDetails.mobiles[0].phone_number;
    //   }
    // }
    // this.liqui_loans.city = this.studentDetails.mwb.city;
  },
  watch: {
    date(val){
      this.currentAge = moment().diff(val, 'years');
      if(this.currentAge >= 21 && this.currentAge < 60){
        this.eligible_age = "yes";
      } else {
        this.eligible_age = "no";
      }
      console.log("currentAge in years", this.currentAge);
    },
    selected_loan_type(val) {
      console.log("loan_type val", val);
      if (val === "CL") {
        // this.getSchemeListLiquiLoans();
      }
      if (val === "PL") {
        this.show_tenure_emi = false;
      }
    },
    selected_scheme(val) {
      console.log(val, "selected_scheme");
      for (let i = 0; i < this.scheme_data.length; i++) {
        const scheme_item = this.scheme_data[i];
        if (scheme_item.scheme_id == val) {
          console.log("retretr2213", scheme_item.scheme_id, val, scheme_item);
          this.liqui_loans.scheme_id = scheme_item.scheme_id;
          this.liqui_loans.scheme_code = scheme_item.scheme_code;
        }
      }
      console.log(this.liqui_loans, "liqui_loans object");
    },
    loader(val){
      if(val == true){
        this.$vs.loading();
      }else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    applyLoan() {
      console.log("this.selected_scheme;",this.selected_scheme)
      this.liqui_loans.mwb_id = this.studentDetails.mwb.id;
      this.liqui_loans.identity = this.studentDetails.mwb.identity;
      this.liqui_loans.dob = moment(this.date).format("YYYY-MM-DD");
      this.liqui_loans.loan_type = this.selected_loan_type;
      // this.liqui_loans.scheme_id = this.selected_scheme.scheme_code;
      // this.liqui_loans.scheme_code = this.selected_scheme.scheme_name_codes;
      // console.log("liqui_loans", this.liqui_loans);
      
      if(this.eligible_age == "no"){
        this.$vs.notify({
          text: "Age Must be between 21 and 60",
          color: "warning",
        });
        return;
      }
      if(this.liqui_loans.amount > 79000 && (this.liqui_loans.course == "CPA" || this.liqui_loans.course == "CMA")){
        this.$vs.notify({
          text: `Amount should not exceed 79000 for ${this.liqui_loans.course}`,
          color: "danger",
        });
        return;
      }
      if(this.liqui_loans.amount > 99000 && this.liqui_loans.course == "CPA USP Arka BU"){
        this.$vs.notify({
          text: `Amount should not exceed 99000 for ${this.liqui_loans.course}`,
          color: "danger",
        });
        return;
      }

      if (
        this.liqui_loans.amount != null &&
        this.liqui_loans.pan != "" &&
        this.liqui_loans.full_name != "" &&
        this.liqui_loans.gender != "" &&
        this.liqui_loans.monthly_income != null &&
        this.liqui_loans.email != "" &&
        this.liqui_loans.loan_type != "" &&
        this.liqui_loans.tenure != null &&
        this.liqui_loans.adhaar_number != null &&
        this.liqui_loans.pincode != null &&
        this.liqui_loans.contact_number != null &&
        this.liqui_loans.dob != "" &&
        this.liqui_loans.dob != "YYYY-MM-DD" &&
        this.liqui_loans.state != "" &&
        this.liqui_loans.city != "" &&
        this.liqui_loans.full_address != "" &&
        this.liqui_loans.name_of_company != "" &&
        this.liqui_loans.occupation != "" &&
        this.liqui_loans.mobile_number != null &&
        this.liqui_loans.course != "" &&
        this.liqui_loans.contact_number != null
        // this.liqui_loans.scheme_id != '' &&
        // this.liqui_loans.scheme_code != ''
       
      ) {
        if(this.liqui_loans.full_name != this.studentDetails.mwb.person_name){
          this.open_loan_popup = true
        }else{
        this.loader = true;
        console.log("liquiloans request obj", this.liqui_loans);
        let url = `https://liquiloans.milesforce.com/api/createLoanLiquiLoansMultiple`;
        axios
          .post(url, this.liqui_loans, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.loader = false;
            console.log("data apply loan", response);
            if (response.data.status == "error") {
              if (
                response.data.message !=
                "The request could not be processed due to validation errors."
              ) {
                this.$vs.notify({
                  text: response.data.message,
                  color: "danger",
                });
              } else {
                this.$vs.notify({
                  text: response.data,
                  color: "danger",
                });
              }
            }
            if (response.data.status == "success") {
              this.$vs.notify({
                text: response.data.message,
                color: "success",
              });
              this.emptyObject();
             
            }
            
            
          })
          .catch((error) => {
            this.loader = false;
            this.handleError(error);
       });
       }
      } else {
        this.loader = false;
        this.$vs.notify({
          text: "Please Enter All Details",
          color: "danger",
        });
      }
    },
    submitLoanApplication(){
      this.loader = true;
      console.log("liquiloans request obj2", this.liqui_loans);
        let url = `https://liquiloans.milesforce.com/api/createLoanLiquiLoansMultiple`;
        axios
          .post(url, this.liqui_loans, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.loader = false;
            console.log("data apply loan", response);
            if (response.data.status == "error") {
              if (
                response.data.message !=
                "The request could not be processed due to validation errors."
              ) {
                this.$vs.notify({
                  text: response.data.message,
                  color: "danger",
                });
              } else {
                this.$vs.notify({
                  text: response.data,
                  color: "danger",
                });
              }
            }
            if (response.data.status == "success") {
              this.$vs.notify({
                text: response.data.message,
                color: "success",
              });
              this.emptyObject();
             
            }
            this.open_loan_popup = false;
            
          })
          .catch((error) => {
            this.loader = false;
            this.handleError(error);
            this.open_loan_popup = false;
       });
    },
    emptyObject(){
      this.date =  "",
      this.liqui_loans = {
        pan: "",
        full_name: "",
        gender: "",
        monthly_income: null,
        email: "",
        loan_type: "",
        amount: null,
        tenure: null,
        adhaar_number: null,
        pincode: null,
        contact_number: null,
        dob: "",
        state: "",
        city: "",
        full_address: "",
        name_of_company: "",
        occupation: "",
        mobile_number: null,
        mwb_id: "",
        identity: "",
        scheme_id: "",
        scheme_code: "",
        course: "",
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        this.$vs.notify({
          text: "Please enter numbers",
          color: "danger",
        });
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>