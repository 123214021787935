<template>
  <vs-card>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card title="Enrollment Details">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Course</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="select2"
                :options="course"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="show_cfa_levels">
            <div class="vx-col sm:w-1/6">
              <span>CFA Levels</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="cfalevelselect"
                class="w-full"
                placeholder="CFA Levels"
                v-model="cfa_selected_level"
                :options="cfa_levels"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="show_frm_parts">
            <div class="vx-col sm:w-1/6">
              <span>FRM Parts</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="frmlevelselect"
                class="w-full"
                placeholder="FRM Parts"
                v-model="frm_selected_part"
                :options="frm_parts"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Category</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="select3"
                :options="category"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Category type</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Choose"
                v-model="typeSelection"
                :options="typeCategories"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-if="enableCorporateSelection">
            <div class="vx-col sm:w-1/6">
              <span>Corporate Type:</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Choose"
                v-model="selectedCorporateOption"
                :options="CorporateOptions"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Mode</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                v-if="this.select2 !== 'CPA-AA' && this.select2 !== 'USP' && this.select2 !== 'Bridge Course – Public Accounting'"
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="select_training"
                :options="levels"
                style="height: 30px"
              ></v-select>
              <v-select
                v-else
                disabled
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="select_training"
                :options="levels"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span></span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                v-if="this.select2 === 'CPA-AA'"
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="cpa_aa_select"
                :options="cpaAAlevels"
                style="height: 30px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="modeSubjects">
            <div class="vx-col sm:w-1/6">
              <span>Mode Of Study</span>
            </div>
            <div class="vx-col sm:w-2/6">
              <ul>
                <li>
                  <vs-radio
                    v-model="radios2"
                    class="inline-flex"
                    vs-value="online_training"
                    >Online Training</vs-radio
                  >
                </li>
                <!-- <li>
                  <vs-radio
                    v-model="radios2"
                    class="inline-flex"
                    vs-value="classroom_training"
                    >Classroom Training</vs-radio
                  >
                </li>
                <li>
                  <vs-radio
                    v-model="radios2"
                    class="inline-flex"
                    vs-value="online_classroom"
                    >Online + Classroom Training</vs-radio
                  >
                </li> -->
                <!-- <li>
                  <vs-radio
                    v-model="radios2"
                    class="inline-flex"
                    vs-value="training_only"
                    >Training Only</vs-radio
                  >
                </li>-->
              </ul>
            </div>
          </div>
           <div class="vx-row mb-6" v-show="show_cfa_subjects">
             <!-- <div class="vx-row mb-6"> -->
            <div class="vx-col sm:w-1/6">
              <span>Your CFA Subject</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <ul v-for="(item, index) in cfa_subject_levels" :key="index">
               <li>
                  <!-- <vs-checkbox
                    :disabled="disablestate"
                    color="dark"
                    class="inline-flex"
                    :vs-value="item"
                    >{{item}}</vs-checkbox
                  > -->
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="show_frm_subjects">
             <!-- <div class="vx-row mb-6"> -->
            <div class="vx-col sm:w-1/6">
              <span>Choosen FRM Subjects</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <ul v-for="(item, index) in frm_subject_parts" :key="index">
               <li>
                  <!-- <vs-checkbox
                    :disabled="disablestate"
                    color="dark"
                    class="inline-flex"
                    :vs-value="item"
                    >{{item}}</vs-checkbox
                  > -->
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="CmaSubjects">
            <div class="vx-col sm:w-1/6">
              <span v-if="select2 == 'Bridge Course – Public Accounting'">Electives Choosen</span>
              <span v-else>Choose Your Subject</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <ul>

                <li>
                  <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    :disabled="disablestate"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_subjects"
                    :vs-value="auditingfees"
                    >Auditing&Attestation</vs-checkbox
                  >
                </li>
                <li>
                  <!-- <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    color="dark"
                    :disabled="disablestate"
                    class="inline-flex"
                    v-model="total_selected_subjects"
                    :vs-value="bussinessenvfees"
                    >Business environment & Concepts</vs-checkbox
                  > -->
                  <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    color="dark"
                    :disabled="disablestate"
                    class="inline-flex"
                    v-model="total_selected_subjects"
                    :vs-value="bussinessenvfees"
                    >Business analysis and reporting</vs-checkbox
                  >
                </li>
                <li>
                  <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_subjects"
                    :disabled="disablestate"
                    :vs-value="financialfees"
                    >Financial Accounting & Reporting</vs-checkbox
                  >
                </li>
                <li>
                  <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_subjects"
                    :disabled="disablestate"
                    :vs-value="regulationfees"
                    >Regulation</vs-checkbox
                  >
                </li>
                <li>
                  <vs-checkbox
                    v-if="select2 != 'USP' && select2 != 'Bridge Course – Public Accounting'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_license_fee"
                    :disabled="disablestate"
                    :vs-value="cpaleFees"
                    >CPA Exam & License Assistance</vs-checkbox
                  >
                   <vs-checkbox
                    v-if="select2 == 'USP'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_license_fee"
                    :disabled="disablestate"
                    :vs-value="cpaleFees"
                    >Assistance for US-Placement</vs-checkbox
                  >
                  <!-- <vs-checkbox
                    v-if="select2 == 'Bridge Course – Public Accounting'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_license_fee"
                    :disabled="disablestate"
                    :vs-value="cpaleFees"
                    >Bridge Course – Public Accounting</vs-checkbox
                  > -->
                  <div v-for="(elective, i) in electives_choosen" :key="i">
                    <vs-checkbox
                    v-if="select2 == 'Bridge Course – Public Accounting'"
                    color="dark"
                    class="inline-flex"
                    v-model="total_selected_license_fee"
                    :disabled="disablestate"
                    :vs-value="cpaleFees"
                    >{{ elective.elective }}</vs-checkbox
                  >
                  </div>
                  
                  <!-- <vs-checkbox
                    color="dark"
                    class="inline-flex"
                    v-model="cpalemodel"
                    :vs-value="cpaleFees"
                  >CPA Exam & License Assistance</vs-checkbox>-->
                </li>
              </ul>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="toggle">
            <div class="vx-col sm:w-1/6">
              <span>Choose Your Subject</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <ul>
                <li>
                  <vs-checkbox
                    color="dark"
                    class="inline-flex"
                    disabled="true"
                    v-model="cmaSubjects"
                    :vs-value="cmapart1"
                    >CMA Part-1</vs-checkbox
                  >
                </li>
                <li>
                  <vs-checkbox
                    color="dark"
                    class="inline-flex"
                    disabled="true"
                    v-model="cmaSubjects"
                    :vs-value="cmapart2"
                    >CMA Part-2</vs-checkbox
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Reason for Discount</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Reason"
                :clearable="true"
                v-model="reason"
                :options="discountReasons"
                style="height: 20px"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-if="reason !== '' && reason !== null">
            <div class="vx-col sm:w-1/6"></div>
            <div class="vx-col sm:w-2/3">
              <vs-textarea v-model="reasoncomment" />
            </div>
          </div>
          <br/>
          <div v-if="select2 != 'USP'  && select2 != 'Bridge Course – Public Accounting'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Loan</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                :vs-value="1"
                >Yes</vs-radio
              >
              <vs-radio color="dark" v-model="enableLoan" :vs-value="0">No</vs-radio>
            </div>
          </div>

          <div class="vx-row mb-6" v-if="this.enableLoan === 1">
            <div class="vx-col sm:w-1/6">
              <span>Tenure</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="number_of_months"
                :vs-value="3"
                >3 Months</vs-radio
              >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="number_of_months"
                :vs-value="6"
                >6 Months</vs-radio
              >
              <!-- <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="number_of_months"
                :vs-value="9"
                >9 Months</vs-radio
              >
              <vs-radio color="dark" v-model="number_of_months" :vs-value="12"
                >12 Months</vs-radio
              > -->
            </div>
          </div>
          <div class="vx-row mb-6" v-if="this.enableLoan == 1">
            <div class="vx-col sm:w-1/6">
              <span>Loan Providers</span>
            </div>
            <div class="vx-col sm:w-2/3">
              <vs-radio v-model="loan_providers" style="margin-right: 3%" color="dark" vs-value="eduvanz">Eduvanz</vs-radio>
              <vs-radio v-model="loan_providers" style="margin-right: 3%" color="dark"  vs-value="liquiloans">Liqui Loans</vs-radio>
            </div>
          </div>
          <referral-component  @searchedData="assignReferral"></referral-component>
          <div class="vx-row mb-6" v-if="(select2 == 'USP'  || select2 == 'Bridge Course – Public Accounting') && selected_cpa_us_fee != 0 && selected_bridge_course_fee != 0">
          <!-- <div class="vx-row mb-6" v-if="select2 == 'USP' && usp_zero_discount == false"> -->
            <div class="vx-col sm:w-1/6">
              <span>Agreed Cost</span>
            </div>
            <!-- <vs-radio style="margin-left: 2%" color="dark" v-model="selected_cpa_us_fee"  :vs-value="29000">29000</vs-radio> -->
            <vs-radio v-if="select2 == 'USP'" style="margin-left: 2%" color="dark" v-model="selected_cpa_us_fee"  :vs-value="20000">20000</vs-radio>
            <vs-radio v-else-if="select2 == 'Bridge Course – Public Accounting'" style="margin-left: 2%" color="dark" v-model="selected_bridge_course_fee"  :vs-value="30000">30000</vs-radio>
          </div>
          <div v-if="select2 == 'CFA' || select2 == 'FRM'&& this.select_training == 'Study Material + Training'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Select Discount</span>
            </div>
            <div  class="vx-col sm:w-2/3">
              <v-select
                id="discountselect"
                class="w-full"
                placeholder="Discount"
                v-model="selected_discount"
                :options="discount_options"
                style="height: 20px"
              ></v-select>
            </div>
          </div>
        </vx-card>
        <!-- CPA Payment Details -->
        <vx-card title="CPA Payment Details" v-show="cpa_payments">
          <table style="width: 100%" class="border-collapse">
            <tr>
              <th class="p-2 border border-solid border-grey-light text-center">
                Price Title
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">Price</th>
              <th class="p-2 border border-solid border-grey-light text-center">
                Discount
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">=</th>
              <th class="p-2 border border-solid border-grey-light text-center">Total</th>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Study Material Cost
                <b v-if="select2 === 'CMA'" style="color: gray">$</b>
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="enrollment_study_material_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-if='enrollment_study_material_fees > 0 && cma_enrolled == true'
                  v-model="Studydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                 <vs-input
                  v-else
                  disabled
                  v-model="Studydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                /> -->
                 <vs-input
                  disabled
                  v-model="Studydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="Studytotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="total_study_material_cost"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles CPA Videos and Training Fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="Trainingfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="enrollment_training_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-if="enrollment_training_fees>0 && (this.cma_enrolled == false || (this.cma_enrolled == true && this.select_training != 'Study Material + Training'))"
                  v-model="Trainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-else
                  disabled
                  v-model="Trainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                /> -->
                <vs-input
                  v-if="enrollment_training_fees == 0"
                  v-model="Trainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  disabled
                  @input="restrictInput"
                />
                 <vs-input
                  v-else
                  v-model="Trainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  @input="restrictInput"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="Trainingtotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="total_training_material_cost"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Training & Support Fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="enrollment_exam_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-if="enrollment_exam_fees>0 && (this.cma_enrolled == false || (this.cma_enrolled == true && this.select_training != 'Study Material + Training'))"
                  value="0"
                  v-model="ExamDiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                /> -->
                <vs-input
                  disabled
                  value="0"
                  v-model="ExamDiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center"> = </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="total_exam_license_cost"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">Total Course Fees</td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="Totalfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="total_enrollment_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  min="0"
                  disabled
                  v-model="Totaldiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center"> = </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="Total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="total_enrollment_cost"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
          </table>
        </vx-card>

        <!-- CMA Payment Details -->
        <vx-card title="CMA Payment Details" v-show="cma_payments">
          <table style="width: 100%" class="border-collapse">
            <tr>
              <th class="p-2 border border-solid border-grey-light text-center">
                Price Title
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">Price</th>
              <th class="p-2 border border-solid border-grey-light text-center">
                Discount
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">=</th>
              <th class="p-2 border border-solid border-grey-light text-center">Total</th>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Study Material Cost
                <!-- <b v-if="select2 === 'CMA'" style="color: gray">$</b> -->
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cmaStudyfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  v-if="this.select_training == 'Study Material + Training'"
                  disabled
                  v-model="cmaStudydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-if="this.select_training == 'Study Material Only'"
                  v-model="cmaStudydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cmaStudytotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>

            <tr>
              <td class="p-2 border border-solid border-grey-light">
                <!-- Miles Training and Support fee -->
                Miles CMA Videos and Training Fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cmaTrainingfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  v-if="cmaTrainingfee > 0"
                  min="0"
                  v-model="cmaTrainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-else
                  disabled
                  min="0"
                  v-model="cmaTrainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cmaTrainingtotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>

            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles CMA support fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="enrollment_cma_exam_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-if="enrollment_cma_exam_fees>0 && (this.cma_enrolled == false || (this.cma_enrolled == true && this.select_training != 'Study Material + Training'))"
                  value="0"
                  v-model="ExamDiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                /> -->
                <vs-input
                  disabled
                  value="0"
                  v-model="ExamDiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center"> = </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="total_cma_exam_license_cost"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>

            <tr>
              <td class="p-2 border border-solid border-grey-light">Total Course Fees</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="total_cma_enrollment_fees"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  min="0"
                  disabled
                  v-model="Totaldiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center"> = </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cmaTotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
          </table>
        </vx-card>
        <!-- CFA Payment Details -->
        <vx-card title="CFA Payment Details" v-show="cfa_payments">
          <table style="width: 100%" class="border-collapse">
            <tr>
              <th class="p-2 border border-solid border-grey-light text-center">
                Price Title
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">Price</th>
              <th class="p-2 border border-solid border-grey-light text-center">
                Discount
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">=</th>
              <th class="p-2 border border-solid border-grey-light text-center">Total</th>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Study Material Cost
                <!-- <b v-if="select2 === 'CMA'" style="color: gray">$</b> -->
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cfaStudyfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cfaStudytotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>

            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Training and Support fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="cfaTrainingfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  min="0"
                  disabled
                  v-model="cfaTrainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  v-if="show_discount_total == false"
                  disabled
                  v-model="cfaTrainingtotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-if="show_discount_total == true"
                  disabled
                  v-model="CFA_discount_training_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">Total Course Fees</td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  v-if="show_discount_total == false"
                  disabled
                  v-model="cfaTotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-if="show_discount_total == true"
                  disabled
                  v-model="CFA_discount_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
          </table>
        </vx-card>
         <!-- FRM Payment Details -->
        <vx-card title="FRM Payment Details" v-show="frm_payments">
          <table style="width: 100%" class="border-collapse">
            <tr>
              <th class="p-2 border border-solid border-grey-light text-center">
                Price Title
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">Price</th>
              <th class="p-2 border border-solid border-grey-light text-center">
                Discount
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">=</th>
              <th class="p-2 border border-solid border-grey-light text-center">Total</th>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Study Material Cost
                <!-- <b v-if="select2 === 'CMA'" style="color: gray">$</b> -->
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="frmStudyfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="frmStudytotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>

            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Training and Support fee
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="frmTrainingfee"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  min="0"
                  disabled
                  v-model="frmTrainingdiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  v-if="show_frm_discount_total == false"
                  disabled
                  v-model="frmTrainingtotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-if="show_frm_discount_total == true"
                  disabled
                  v-model="frm_discount_training_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">Total Course Fees</td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                v-if="show_frm_discount_total == false"
                  disabled
                  v-model="frmTotal"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
                <vs-input
                  v-if="show_frm_discount_total == true"
                  disabled
                  v-model="frm_discount_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
          </table>
        </vx-card>
        <!-- AICPA Payment Details -->
        <vx-card title="AICPA Payment Details" v-show="aicpa_payments">
          <table style="width: 100%" class="border-collapse">
            <tr>
              <th class="p-2 border border-solid border-grey-light text-center">
                Products
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">Taxable</th>
              <th class="p-2 border border-solid border-grey-light text-center">
                GST
              </th>
              <th v-if ="aicpa_training_total != 0" class="p-2 border border-solid border-grey-light text-center">
                Discount
              </th>
              <th class="p-2 border border-solid border-grey-light text-center">=</th>
              <th class="p-2 border border-solid border-grey-light text-center">Total</th>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                Miles Training Fees - US Audit & Tax
                <!-- <b v-if="select2 === 'CMA'" style="color: gray">$</b> -->
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_tax"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="cmaStudydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  disabled
                  v-model="aicpa_gst"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td v-if ="aicpa_training_total != 0" class="border border-solid border-grey-light text-center">
                <!-- <vs-input
                  v-model="cmaStudydiscount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />-->
                <vs-input
                  v-model="aicpa_training_fee_discount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_training_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                AICPA Exam Fees - Audit Staff Essentials
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_exam_audit_essentials_tax"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  min="0"
                  disabled
                  v-model="aicpa_exam_audit_essentials_gst"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td v-if ="aicpa_training_total != 0" class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_ASE_exam_fee_discount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_exam_audit_essentials_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid border-grey-light">
                AICPA Exam Fees - Tax Staff Essentials
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_exam_tax_essentials_tax"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  min="0"
                  v-model="aicpa_exam_tax_essentials_gst"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td v-if ="aicpa_training_total != 0" class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_TSE_exam_fee_discount"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
              <td class="border border-solid border-grey-light text-center">=</td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_exam_tax_essentials_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
            
            <tr>
              <td class="p-2 border border-solid border-grey-light">Total Course Fees</td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center"></td>
              <td v-if ="aicpa_training_total != 0" class="border border-solid border-grey-light text-center"></td>
              <td class="border border-solid border-grey-light text-center">
                <vs-input
                  disabled
                  v-model="aicpa_total"
                  class="w-full"
                  placeholder
                  size="medium"
                  type="number"
                />
              </td>
            </tr>
          </table>
        </vx-card>
        <!--Time Span-->
        <vx-card title="Time Span">
          <table style="width: 100%" class="border-collapse">
            <tr></tr>
            <tr>
              <td>Date Of Joining</td>
              <td>
                {{ new Date().getDate() }}
                {{ new Date().toLocaleString("default", { month: "long" }) }}
                {{ new Date().getFullYear() }}
              </td>
            </tr>
            <tr>
              <td>Batch start Date</td>
              <td>
                <p v-if="this.select2 === 'CPA' || this.select2 === 'CPA-AA' || this.select2 === 'USP'  || this.select2 == 'Bridge Course – Public Accounting'">{{ this.cpaBatchDate }}</p>
                <p v-if="this.select2 === 'CMA'">{{ this.cmaBatchDate }}</p>
                <p v-if="this.select2 === 'CFA'">{{ this.cfaBatchDate }}</p>
                <p v-if="this.select2 === 'FRM'">{{ this.frmBatchDate }}</p>
                <p v-if="this.select2 === 'AICPA'">{{ this.aicpa_batch_date }}</p>
                <!-- <datepicker
                  placeholder="Select Date"
                  v-model="date2"
                  :disabledDates="disabledDates"
                  size="small"
                ></datepicker> -->
              </td>
            </tr>
            <tr v-if="this.select2 != 'CFA'">
              <td>Batch</td>
              <td>
                <vs-input disabled class="inputx" style="width: 200px" v-model="batch" />
              </td>
            </tr>
            <tr v-if="this.select2 == 'CFA'">
              <td>Batch</td>
              <td>
                <v-select
                id="CFABatch"
                placeholder="Select CFA Batch"
                v-model="batch"
                :options="cfa_batch_list"
                style="height: 30px; width: 200px"
              ></v-select>
              </td>
            </tr>
          </table>

          <br />
          <div class="vx-row">
            <div class="vx-col">
              <vs-button
                class="float-right"
                color="primary"
                type="gradient"
                style="left: 440px"
                @click="EventForSubmit()"
                :disabled="u_level_not_matched"
                >Submit</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </vs-card>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import constants from "../../../constants.json";
import axios from "axios";
import referralComponent from "../../components/enrollments/referral.vue";
import moment from "moment";
export default {
  props: {
    payload: {
      required: true,
    },
  },
  components: {
    vSelect,
    Datepicker,
    "referral-component": referralComponent,
  },
  mounted() {
    EventBus.$on("detailsToSend", (payload) => {
      // console.log("enrollment key", key);
      // console.log("enrollment apyload", payload);
      this.submitForm(payload);
    });
    EventBus.$on("add-usp-course", (payload) => {
      this.add_usp_course=payload;
    });
    this.StartBatchDate();
    EventBus.$on("enrollment-opened", () => {
      this.enrollment_study_material_fees = 0;
      this.enrollment_training_fees = 0;
      this.ExamDiscount = 0;
      this.Totaldiscount = 0; 
      // this.cpa_enrolled = false;
      this.cmaStudydiscount = 0;
      // this.usp_zero_discount = false;
    //   // console.log("check courses and disable", this.dates);
    //   // console.log(details.payloadEmails);
    //   this.total_selected_subjects = [];
    //   this.cmaSubjects = [];
    //   this.cmaStudyfee = 0;
    //   this.cmaTrainingfee = 0;
    //   this.cmaStudytotal = 0;
    //   this.cmaTrainingtotal = 0;
    //   this.cmaTotal = 0;
    //   this.wiley_study_material_cost_with_gst = 0;
    //   this.wiley_study_material_cost_without_gst = 0;
    //   this.enrollment_study_material_fees = 0;
    //   this.total_study_material_cost = 0;
    //   this.enrollment_training_fees = 0;
    //   this.clearAll();
    //   this.payloadEmails = details.payloadEmails;
    //   let courseDetails = ["CPA", "CMA"];
    //   let preCourses = this.getEnrolledCourses(details);
    //   preCourses.forEach((course) => {
    //     if (courseDetails.indexOf(course) !== -1) {
    //       courseDetails.splice(courseDetails.indexOf(course), 1);
    //       // let StartDate = this.dates;
    //     }
    //   });
    //   // console.log("tag", StartDate);
    //   this.course = courseDetails;
    //   this.batch = "";
    //   this.reason = "";
    //   this.date = new Date();
    //   this.date2 = "";
    //   // this.select2 = "CPA";
    //   this.select2 = courseDetails[0];
    //   this.Studydiscount = 0;
    //   this.Trainingdiscount = 0;
    //   // this.cmaSubjects = [];
    //   this.cmaTrainingdiscount = 0;
    //   this.select_training = "";
    //   // this.total_selected_license_fee = [];
    });
  },
  data() {
    return {
      // usp_zero_discount: false, 
      add_usp_course:false,
      u_level_not_matched:false,
      selected_cpa_us_fee: 20000,
      selected_bridge_course_fee: 30000,
      // cma_enrolled: false,
      // cpa_enrolled: false,
      enrolled_cfa_levels: [],
      enrolled_frm_parts: [],
      aicpa_training_fee_discount: 0,
      aicpa_ASE_exam_fee_discount: 0,
      aicpa_TSE_exam_fee_discount: 0,
      aicpa_exam_audit_essentials_total: 0,
      aicpa_exam_audit_essentials_gst: 0,
      aicpa_exam_audit_essentials_tax: 0,
      aicpa_exam_tax_essentials_total: 0,
      aicpa_exam_tax_essentials_gst: 0,
      aicpa_exam_tax_essentials_tax: 0,
      aicpa_training_total: 0,
      aicpa_tax: 0,
      aicpa_gst: 0,
      preCourses: [],
      show_frm_discount_total: false,
      show_discount_total: false,
      CFA_discount_total: 0,
      frm_discount_total: 0,
      CFA_discount_training_total: 0,
      frm_discount_training_total: 0,
      selected_discount: "",
      discount_options: ["5%", "10%"],
      frmFees: {},
      cfaFees: {},
      selected_cfa_batch: "",
      cfa_batch_list: [ "CFA-M7-01", "CFA-M7-02", "CFA-M7-03"],
      cfa_level_selected_subjects: [],
      frm_part_selected_subjects: [],
      cfa_subject_levels: [],
      frm_subject_parts: [],
      cfa_level1_subjects: [
        "Quantitative Methods and Economics",
        "Final Statement Analysis",
        "Corporate Issuers and Equity Investments",
        "Fixed Income | Derivatives and Alternative Investments",
        "Portfolio Management and Ethical and Profesional Standards",
        "Schweser's QuickSheet Critical Concepts For The 2022",
      ],
      cfa_level2_subjects: [
        "Quantitative Methods and Economics",
        "Financial Statement Analysis and Corporate Issuers",
        "Equity Valuation",
        "Fixed Income | Derivatives and Alternative Investments",
        "Portfolio Management and Ethical and Profesional Standards",
        "Schweser's QuickSheet Critical Concepts For The 2022",
      ],
      cfa_level3_subjects: [
        "Behavioral Finance | Capital Market Expectations and Asset Allocation",
        "Derivatives and Currency Management",
        "Fixed Income and Equity",
        "Alternative Investments | Private Wealth Management and Institutional Investors",
        "Trading | Performance Evaluation | Manager Selection | Case studies and Ethical and Professional Standards",
        "Schweser's QuickSheet Critical Concepts For The 2022",
      ],
      frm_part1_subjects: [
        "Foundations of Risk Management",
        "Quantitative Analysis",
        "Financial Markets and Products",
        "Valuation and Risk Models",
        "SCHWESER'S QuickSheet Critical Concepts for the 2022 FRM(R) Exam "
      ],
      frm_part2_subjects: [
        "Market Risk Measurement and Management",
        "Credit Risk Measurement and Managemen",
        "Operational Risk and Resiliency",
        "Liquidity and Treasury Risk Measurement",
        "Risk Management and Investment Management",
        "SCHWESER'S QuickSheet Critical Concepts for the 2022 FRM(R) Exam",
      ],
      show_cfa_subjects: false,
      show_frm_subjects: false,
      cfa_levels: [],
      frm_parts: [],
      cfa_selected_level: "Level 1",
      frm_selected_part: "Part 1",
      show_cfa_levels: false,
      show_frm_parts: false,
      cfaTrainingfee: 0,
      frmTrainingfee: 0, 
      cmagst: 0,
      traningGst: 0,
      wiley_study_material_cost_with_gst: 0,
      wiley_study_material_cost_without_gst: 0,
      cpaBatchDate: "",
      cmaBatchDate: "",
      cfaBatchDate: "",
      frmBatchDate: "",
      aicpa_batch_date: "",
      cpaBatch: "",
      cmaBatch: "",
      cfaBatch: "",
      frmBatch: "",
      aicpa_batch: "",
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      referralData: {
        identity: "",
        person_id: null,
        referral_bool: "no",
      },
      number_of_months: 3,
      payloadEmails: [],
      enableLoan: 0,
      loan_providers: "liquiloans",
      cpa_payments: true,
      cma_payments: false,
      aicpa_payments: false,
      cfa_payments: false,
      frm_payments: false,
      typeCategories: ["B2C", "University"],
      typeSelection: "",
      cmaSubjects: [],
      cfaSubjects: [],
      loan_status_values: [
        "Not created",
        "Created",
        "In Review",
        "Documentation",
        "Push for disbursal",
        "Disbursed",
        "Rejected",
        "Dropped",
      ],
      loan_id: "",
      loan_status: "Created",
      cmapart1: {
        material: 262.5,
        training: 28850,
        name: "CMA Part 1",
      },
      cmapart2: {
        material: 262.5,
        training: 28850,
        name: "CMA Part 2",
      },
      batchDropdown: [
        "CPA-M7-WW",
        "CPA-M7-AA",
        "CPA-M7-BB",
        "CPA-M7-EE",
        "CPA-M7-46",
      ],
      disablestate: false,
      auditingfees: {
        miles_material: 9750,
        wiley_material: 7500,
        name: "AUD",
      },
      // bussinessenvfees: {
      //   miles_material: 9750,
      //   wiley_material: 7500,
      //   name: "BEC",
      // },
      bussinessenvfees: {
        miles_material: 9750,
        wiley_material: 7500,
        name: "BAR",
      },
      financialfees: {
        miles_material: 9750,
        wiley_material: 7500,
        name: "FAR",
      },
      regulationfees: {
        miles_material: 9750,
        wiley_material: 7500,
        name: "REG",
      },
      cpaleFees: {
        material: 0,
        training: 49000,
        name: "exam_license_assistance",
      },
      discountReasons: [
        // "Alumini Discount",
        // "CA Discount",
        // "CA",
        // "CA Inter",
        // "CS",
        // "CS Inter",
        // "CWA",
        // "CWA Inter",
        // "Corporate Discount",
        "Custom",
      ],
      cmaStudyfee: 0,
      cfaStudyfee: 0,
      frmStudyfee: 0,
      cmaStudydiscount: 0,
      cfaStudydiscount: 0,
      cmaStudytotal: 0,
      aicpa_study_total: 0,
      cfaStudytotal: 0,
      frmStudytotal: 0,
      cmaTrainingfee: 0,
      aicpa_training_fee: 0,
      cfaTrainingdiscount: 0,
      frmTrainingdiscount: 0,
      cmaTrainingdiscount: 0,
      aicpa_training_discount: 0,
      cmaTrainingtotal: 0,
      cfaTrainingtotal: 0,
      frmTrainingtotal: 0,
      cmaTotal: 0,
      aicpa_total: 0,
      cfaTotal: 0,
      frmTotal: 0,
      reason: "",
      reasoncomment: "",
      cpalemodel: [],
      check1: "",
      batch: "",
      check2: "",
      date: "",
      date2: "",
      indexpopup: 0,
      connected: false,
      toggle: false,
      CmaSubjects: true,
      modeSubjects: false,
      customComment: false,
      select_training: "",
      show: false,
      disable: true,
      subjectarr: [],
      check5: "",
      radios2: "",
      Studytotal: 0,
      Studydiscount: 0,
      Studyfee: 0,
      batchStart: true,
      Totaldiscount: 0,
      Total: 0,
      Totalfee: 0,
      Trainingtotal: 0,
      Trainingdiscount: 0,
      Trainingfee: 0,
      ExamFee: 0,
      ExamDiscount: 0,
      ExamTotal: 0,
      studycalculus: 1,
      trainingcalculus: 1,
      levels: ["Study Material Only", "Study Material + Training", "Only License", "Assistance for US-Placement", "Bridge Course – Public Accounting"],

      cpaAAlevels: ["Passed all 4 exams", "Not passed all 4 exams"],
      cpa_aa_select: "",
      course: [],
      select2: "",
      category: ["Professional", "Student"],
      select3: "Student",
      calculations: [
        { text: "+", value: 0 },
        { text: "-", value: 1 },
      ],
      total_selected_subjects: [],
      total_selected_license_fee: [],
      enableCorporateSelection: false,
      selectedCorporateOption: "",
      CorporateOptions: ["B2B", "B2C"],
      enrollment_study_material_fees: 0,
      // total_study_material_cost: 0,
      enrollment_training_fees: 0,
      // total_enrollment_cost:0,
      // total_training_material_cost:0
      electives_choosen: [],
      is_candidate_can_choose_arka_elective:'',
      electives_data: false,
    };
  },
  computed: {
    
    enrollment_exam_fees() {
      let returnValue = 0;
      // this.total_selected_license_fee.forEach((subject) => {
      //   returnValue = returnValue + subject.training;
      // });
      // if (
      //   this.total_selected_subjects.length === 4 &&
      //   this.total_selected_license_fee.length === 1 &&
      //   this.select_training !== "Study Material Only"
      // ) {
      //   returnValue = returnValue / 2;
      // }
      // return returnValue;
      if (this.select_training == "Study Material Only") {
        returnValue = 0;
      } else if (this.select_training == "Study Material + Training") {
        if (this.total_selected_license_fee.length === 1) {
          returnValue = 10000;
        }
      } else if (this.select_training == "Only License") {
        if (this.total_selected_license_fee.length === 1 && this.select2 === "CPA") {
          returnValue = 41525;
        } 
        if (this.total_selected_license_fee.length === 1 && this.select2 === "CPA-AA") {
          if(this.cpa_aa_select === "Passed all 4 exams") {
            returnValue = 33050;
          }
          if(this.cpa_aa_select === "Not passed all 4 exams") {
            returnValue = 41525;
          }
          
        }
      } else if (this.select_training == "Assistance for US-Placement") {
        if(this.select2 === "USP"){
          // returnValue = 29000;
          returnValue = this.selected_cpa_us_fee;
        }
      }
      else if (this.select_training == "Bridge Course – Public Accounting") {
        if(this.select2 == 'Bridge Course – Public Accounting'){
          returnValue = this.selected_bridge_course_fee;
        }
      }
      return returnValue;
    },
    enrollment_cma_exam_fees() {
      let returnValue = 0;
      // this.total_selected_license_fee.forEach((subject) => {
      //   returnValue = returnValue + subject.training;
      // });
      // if (
      //   this.total_selected_subjects.length === 4 &&
      //   this.total_selected_license_fee.length === 1 &&
      //   this.select_training !== "Study Material Only"
      // ) {
      //   returnValue = returnValue / 2;
      // }
      // return returnValue;
      if (this.select_training == "Study Material Only") {
        returnValue = 0;
      } else if (this.select_training == "Study Material + Training") {
          returnValue = 10000;
      } else if (this.select_training == "Training Only") {
        returnValue = 0;
      } else if (this.select_training == "Assistance for US-Placement") {
        if(this.select2 === "USP"){
          // returnValue = 29000;
          returnValue = this.selected_cpa_us_fee;
        }
      } else if (this.select_training == "Bridge Course – Public Accounting") {
        if(this.select2 == 'Bridge Course – Public Accounting'){
          returnValue = this.selected_bridge_course_fee;
        }
      }
      return returnValue;
    },
    total_enrollment_fees() {
      let total =
        this.enrollment_study_material_fees +
        this.enrollment_training_fees +
        this.wiley_study_material_cost_without_gst +
        this.enrollment_exam_fees;
      return total;
    },
    total_cma_enrollment_fees() {
      let total =
        this.cmaStudyfee +
        this.cmaTrainingfee +
        this.enrollment_cma_exam_fees;
      return total;
    },
    // Totaldiscount, ExamDiscount, Trainingdiscount, Studydiscount
    total_study_material_cost() {
      // this.changeStudyDiscount();
      let value = this.enrollment_study_material_fees - this.Studydiscount;
      return value;
    },
    total_training_material_cost() {
      let gst = 0;
      if (
        this.select_training !== "Study Material Only" &&
        this.enrollment_training_fees !== 0
      ) {
        gst = this.enrollment_training_fees*(18/100) ;
      }
      // this.changeTrainingDiscount();
      let value = this.enrollment_training_fees + gst - this.Trainingdiscount;
      return Math.round(value);
    },
    total_exam_license_cost() {
      let value = 0;
      if (
        this.select_training === "Study Material + Training" &&
        this.enrollment_exam_fees !== 0
      ) {
        if (this.total_selected_license_fee.length === 1) {
          // this.changeExamDiscount();
          value = 11800 - this.ExamDiscount;
        }
        return value;
      } else if (
        this.select_training === "Only License" &&
        this.enrollment_exam_fees !== 0
      ) {
        if (this.total_selected_license_fee.length === 1 && this.select2 === "CPA") {
          value = 49000 - this.ExamDiscount;
          value;
        } 
        if(this.total_selected_license_fee.length === 1 && this.select2 === "CPA-AA") {
          if(this.cpa_aa_select === "Passed all 4 exams") {
            value = 39000 - this.ExamDiscount;
          }
          if(this.cpa_aa_select === "Not passed all 4 exams") {
            value = 49000 - this.ExamDiscount;
          }
        }
        // if(this.select2 === "USP"){
        //   value = 29000 - this.ExamDiscount;
        // }
        return value;
      } else if (
        this.select_training === "Assistance for US-Placement" &&
        this.enrollment_exam_fees !== 0
      ) {
        // this.changeDiscount();  //R
        value = this.selected_cpa_us_fee - this.ExamDiscount;
        return value;
      }else if (
        this.select_training === "Bridge Course – Public Accounting" &&
        this.enrollment_exam_fees !== 0
      ) {
        // this.changeDiscount();  //R
        value = this.selected_bridge_course_fee - this.ExamDiscount;
        return value;
      }
      else if (
        this.select_training === "Study Material Only" &&
        this.enrollment_exam_fees !== 0
      ) {
        return 0;
      }
      // let value = this.enrollment_exam_fees - this.ExamDiscount + gst;
      return 0;
    },
    total_cma_exam_license_cost() {
      let value = 0;
      if (
        this.select_training === "Study Material + Training" &&
        this.enrollment_cma_exam_fees !== 0
      ) {
          // this.changeExamDiscount();
          value = 11800 - this.ExamDiscount;
        return value;
      } else if (
        this.select_training === "Training Only" &&
        this.enrollment_cma_exam_fees !== 0
      ) {
        return value;
      } else if (
        this.select_training === "Assistance for US-Placement" &&
        this.enrollment_cma_exam_fees !== 0
      ) {
        // this.changeDiscount();  //R
        value = this.selected_cpa_us_fee - this.ExamDiscount;
        return value;
      }else if (
        this.select_training === "Bridge Course – Public Accounting" &&
        this.enrollment_cma_exam_fees !== 0
      ) {
        // this.changeDiscount();  //R
        value = this.selected_bridge_course_fee - this.ExamDiscount;
        return value;
      }
      else if (
        this.select_training === "Study Material Only" && 
        this.enrollment_cma_exam_fees !== 0
      ) {
        return 0;
      }
      // let value = this.enrollment_cma_exam_fees - this.ExamDiscount + gst;
      return 0;
    },
    total_enrollment_cost() {
      let value = 0;
      if (this.select_training === "Study Material + Training") {
        value =
          this.total_study_material_cost +
          this.wiley_study_material_cost_with_gst +
          this.total_training_material_cost +
          this.total_exam_license_cost;
      } else if (this.select_training === "Study Material Only") {
        value =
          this.total_study_material_cost +
          this.wiley_study_material_cost_with_gst;
      } else if (this.select_training === "Only License") {
        value = this.total_exam_license_cost;
      } else if (this.select_training === "Assistance for US-Placement") {
        value = this.total_exam_license_cost;
      }else if (this.select_training === "Bridge Course – Public Accounting") {
        value = this.total_exam_license_cost;
      }
      return value;
    },
  },
  watch: {
    cmaStudydiscount(val){
        if(this.cmaStudyfee != 0){
          this.cmaStudytotal = this.cmaStudyfee - val;
        }
    },
    selected_discount(val) {
      if(this.select_training == 'Study Material + Training'){
        if(this.select2 == 'CFA'){
          this.CFA_discount_total = 0;
          this.cfaTrainingdiscount = 0;
          this.CFA_discount_training_total = 0;
          if(val == "5%"){
            this.show_discount_total = true;
            let percentage = 5/100;
            this.cfaTrainingdiscount = this.cfaTotal*percentage ;
            this.CFA_discount_training_total = this.cfaTrainingfee - this.cfaTrainingdiscount;
            this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
          }
          if(val == "10%"){
            this.show_discount_total = true;
            let percentage = 10/100;
            this.cfaTrainingdiscount = this.cfaTotal*percentage ;
            this.CFA_discount_training_total = this.cfaTrainingfee - this.cfaTrainingdiscount;
            this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
          }
        }
        if(this.select2 == 'FRM'){
          this.frm_discount_total = 0;
          this.frmTrainingdiscount = 0;
          this.frm_discount_training_total = 0;
          if(val == '5%'){
            this.show_frm_discount_total = true;
            let percentage = 5/100;
            this.frmTrainingdiscount = this.frmTotal*percentage;
            this.frm_discount_training_total = this.frmTrainingfee - this.frmTrainingdiscount;
            this.frm_discount_total = this.frmTotal - this.frmTrainingdiscount ;
          }
          if(val == '10%'){
            this.show_frm_discount_total = true;
            let percentage = 10/100;
            this.frmTrainingdiscount = this.frmTotal*percentage;
            this.frm_discount_training_total = this.frmTrainingfee - this.frmTrainingdiscount;
            this.frm_discount_total = this.frmTotal - this.frmTrainingdiscount ;
          }
        }
      }
      if(this.select_training != 'Study Material + Training'){
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
      }
    },
    cfa_selected_level(val) {
      if(val === "Level 1"){
        this.cfa_subject_levels = this.cfa_level1_subjects;
        this.total_selected_subjects = this.cfa_level1_subjects;
      }
      if(val === "Level 2"){
        this.cfa_subject_levels = this.cfa_level2_subjects;
        this.total_selected_subjects = this.cfa_level2_subjects;
      }
      if(val === "Level 3"){
        this.cfa_subject_levels = this.cfa_level3_subjects;
        this.total_selected_subjects = this.cfa_level3_subjects;
      }
    },
    frm_selected_part(val){
      if(val === "Part 1"){
        this.frm_subject_parts = this.frm_part1_subjects;
        this.total_selected_subjects = this.frm_part1_subjects;
      } if(val === "Part 2"){
        this.frm_subject_parts = this.frm_part2_subjects;
        this.total_selected_subjects = this.frm_part2_subjects;
      }

    },
    total_selected_subjects(val) {
      this.cpaSubjectCalculation(val.length);
    },
    enableLoan(value) {
      if(value == 1 ){
        this.loan_providers = "liquiloans";
        console.log(this.loan_providers);
      }
      // console.log(value);
      this.getDownpayment();
    },
    reason(value) {
      switch (value) {
        case "Custom":
          this.customComment = true;
          break;
        case "Alumini Discount":
          this.customComment = false;
          break;
        case "CA Discount":
          this.customComment = false;
          break;
        case "Corporate Discount":
          this.customComment = false;
          break;
        case "CA":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CS":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CWA":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CA Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CS Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CWA Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
      }
    },
    select2(payload) {
      this.u_level_not_matched = false
      if(payload !== "AICPA"){
        this.aicpa_tax = 0;
        this.aicpa_gst = 0;
        this.aicpa_training_total = 0;
        this.aicpa_exam_tax_essentials_tax = 0;
        this.aicpa_exam_tax_essentials_gst = 0;
        this.aicpa_exam_tax_essentials_total = this.aicpa_exam_tax_essentials_tax + this.aicpa_exam_tax_essentials_gst;
        this.aicpa_exam_audit_essentials_tax = 0;
        this.aicpa_exam_audit_essentials_gst = 0;
        this.aicpa_exam_audit_essentials_total = this.aicpa_exam_audit_essentials_tax + this.aicpa_exam_audit_essentials_gst;
        this.aicpa_training_fee = 0 ;
        this.aicpa_total = 0; 
        this.aicpa_training_fee_discount = 0; 
        // this.usp_zero_discount = false;
      }
      if (payload === "CMA") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.date2 = this.cmaBatch;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.batch = "";
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_cfa_levels = false;
        this.show_frm_parts = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.selected_discount = "";
        // console.log(this.datatoTimestamp(this.date2))
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.cfaTrainingdiscount = 0;
        this.frmTrainingdiscount = 0;
        this.reasoncomment = "";
        this.aicpa_training_fee_discount = 0; 
      } else if (payload === "CFA") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.date2 = this.cfaBatch;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.CmaSubjects = false;
        this.toggle = false;
        this.cfaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.batch = this.selected_cfa_batch;
        this.show_cfa_levels = true;
        this.show_frm_parts = false;
        this.show_cfa_subjects = true;
        this.show_frm_subjects = false;
        if(this.cfa_selected_level === "Level 1"){
          this.cfa_subject_levels = this.cfa_level1_subjects;
          this.total_selected_subjects = this.cfa_level1_subjects;
        }
        this.frm_selected_part = "";
        // console.log(this.datatoTimestamp(this.date2))
        this.selected_discount = "";
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.cfaTrainingdiscount = 0;
        this.cfaStudyfee = 0;
        this.cfaTrainingfee = 0;
        this.cfaTrainingtotal = 0;
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
      } else if (payload === "FRM") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.show_cfa_subjects = false;
        this.date2 = this.frmBatch;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.show_cfa_levels = false;
        this.show_frm_parts = true;
        this.show_frm_subjects = true;
        this.CmaSubjects = false;
        this.toggle = false;
        this.cmaStudytotal = 0
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.batch = "";
        if(this.frm_selected_part === "Part 1"){
          this.frm_subject_parts = this.frm_part1_subjects;
          this.total_selected_subjects = this.frm_part1_subjects;
        }
        this.cfa_selected_level = "";
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        // console.log(this.datatoTimestamp(this.date2))
        this.cfaTrainingdiscount = 0;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.aicpa_training_fee_discount = 0; 
        this.frmTrainingdiscount = 0;
      } else if (payload === "CPA") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.batch = ""
        this.date2 = this.cpaBatch;
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_frm_parts = false;
        this.show_cfa_levels = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.cfaTrainingdiscount = 0;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
      } else if (payload === "CPA-AA") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.batch = ""
        this.date2 = this.cpaBatch;
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_cfa_levels = false;
        this.show_frm_parts = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.cfaTrainingdiscount = 0;
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
        let bDate = this.datatoTimestamp(this.date2);
        this.generateBatch("only_license", bDate);
      } else if (payload === "USP") {
        // this.usp_zero_discount = false;
        this.selected_cpa_us_fee = 20000;
        this.batch = ""
        this.select_training = "Assistance for US-Placement";
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.date2 = this.cpaBatch;
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_cfa_levels = false;
        this.show_frm_parts = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.cfaTrainingdiscount = 0;
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
        let bDate = this.datatoTimestamp(this.date2);
        this.generateBatch("only_license", bDate);
        let u_levels = ["U0--","U0","U1","U1+","U2","U3-","U3","U3+","U3++","U4","U5","U6","U7-"]
        if (u_levels.includes(this.payload.iiml_level)) {
          this.u_level_not_matched = true
          this.$vs.notify({title: `Not Valid`,text: "Lead's level must be U7 or above to start the enrollment process",color: "danger",time:3000});
        }
        // this.changeDiscount(); //R
      }else if (payload === "Bridge Course – Public Accounting") {
        // this.usp_zero_discount = false;
        this.getBridgeCourseData();
        this.selected_bridge_course_fee = 30000;
        this.batch = ""
        this.select_training = "Bridge Course – Public Accounting";
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.date2 = this.cpaBatch;
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_cfa_levels = false;
        this.show_frm_parts = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.cfaTrainingdiscount = 0;
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
        let bDate = this.datatoTimestamp(this.date2);
        this.generateBatch("only_license", bDate);
        // let u_levels = ["U0--","U0","U1","U1+","U2","U3-","U3","U3+","U3++","U4","U5","U6","U7-"]
        // if (u_levels.includes(this.payload.iiml_level)) {
        //   this.u_level_not_matched = true
        //   this.$vs.notify({title: `Not Valid`,text: "Lead's level must be U7 or above to start the enrollment process",color: "danger",time:3000});
        // }
        // this.changeDiscount(); //R
      }  
      else if (payload === "AICPA") {
        // this.cma_enrolled = false;
        // this.cpa_enrolled = false;
        this.date2 = this.aicpa_batch;
        this.wiley_study_material_cost_with_gst = 0;
        this.wiley_study_material_cost_without_gst = 0;
        this.enrollment_study_material_fees = 0;
        this.total_study_material_cost = 0;
        this.enrollment_training_fees = 0;
        this.cmaStudytotal = 0;
        this.aicpa_study_total = 0;
        this.cfaStudytotal = 0
        this.batch = ""
        this.show_cfa_subjects = false;
        this.show_frm_subjects = false;
        this.show_cfa_levels = false;
        this.show_frm_parts = false;
        this.frm_selected_part = "";
        this.cfa_selected_level = "";
        this.cfaTrainingdiscount = 0;
        this.show_discount_total = false;
        this.show_frm_discount_total = false;
        this.selected_discount = "";
        this.reasoncomment = "";
        this.frmTrainingdiscount = 0;
        this.aicpa_training_fee_discount = 0; 
      }
      EventBus.$emit("changeCourse", payload);
      this.Studyfee = 0;
      this.Studydiscount = 0;
      this.Studytotal = 0;
      this.Trainingfee = 0;
      this.Trainingdiscount = 0;
      this.Trainingtotal = 0;
      this.Total = 0;
      switch (payload) {
        case "CMA":
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.cmaSubjects = [];
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.levels = [
            "Study Material Only",
            "Study Material + Training",
            "Training Only",
          ];
          this.typeSelection = "";
          this.select_training = "";
          this.modeSubjects = false;
          this.CmaSubjects = false;
          this.toggle = true;
          this.cma_payments = true;
          this.aicpa_payments = false;
          this.cpa_payments = false;
          this.cfa_payments = false;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          break;
          case "CFA":
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.cmaSubjects = [];
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.levels = [
            "Study Material Only",
            "Study Material + Training",
            "Training Only",
          ];
          this.typeSelection = "";
          this.select_training = "";
          this.modeSubjects = false;
          this.CmaSubjects = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = false;
          this.cfa_payments = true;
          this.frm_payments = false;
          this.show_frm_discount_total = false;
          // this.show_discount_total = true;
          break;
          case "FRM":
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.frmSubjects = [];
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.levels = [
            "Study Material Only",
            "Study Material + Training",
            "Training Only",
          ];
          this.typeSelection = "";
          this.select_training = "";
          this.modeSubjects = false;
          this.FrmSubjects = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = false;
          this.cfa_payments = false;
          this.frm_payments = true;
          this.show_discount_total = false;
          // this.show_frm_discount_total = false;
          break;
          case "CPA":
          this.modeSubjects = false;
          this.levels = [
            "Study Material Only",
            "Study Material + Training",
            "Only License",
          ];
          this.cmaSubjects = [];
          this.select_training = "";
          this.typeSelection = "";
          // this.modeSubjects = true;
          this.CmaSubjects = true;
          this.toggle = false;
          this.subjectarr = [];
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.cfa_payments = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = true;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          break;
        case "CPA-AA":
          this.modeSubjects = false;
          // this.levels = [
          //   // "Study Material Only",
          //   // "Study Material + Training",
          //   "Only License",
          // ];
          this.cmaSubjects = [];
          this.select_training = "Only License";
          this.cpa_aa_select = "Passed all 4 exams";
          // this.total_selected_licens\e_fee.push(this.cpaleFees);
          // this.disablestate = true;
          this.typeSelection = "";
          // this.modeSubjects = true;
          this.CmaSubjects = true;
          this.toggle = false;
          this.subjectarr = [];
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.cfa_payments = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = true;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          break;
          case "USP":
          this.modeSubjects = false;
          // this.levels = [
          //   // "Study Material Only",
          //   // "Study Material + Training",
          //   "Only License",
          // ];
          this.cmaSubjects = [];
          // this.cpa_aa_select = "Passed all 4 exams";
          // this.total_selected_licens\e_fee.push(this.cpaleFees);
          // this.disablestate = true;
          this.typeSelection = "";
          // this.modeSubjects = true;
          this.CmaSubjects = true;
          this.toggle = false;
          this.subjectarr = [];
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          // if(this.usp_zero_discount == true){
          //   console.log("total discount hellooooooo");
          //   this.Totaldiscount = 20000;
          // }
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.cfa_payments = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = true;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          this.select_training = "Assistance for US-Placement";
          break;
          case "Bridge Course – Public Accounting":
          this.modeSubjects = false;
          // this.levels = [
          //   // "Study Material Only",
          //   // "Study Material + Training",
          //   "Only License",
          // ];
          this.cmaSubjects = [];
          // this.cpa_aa_select = "Passed all 4 exams";
          // this.total_selected_licens\e_fee.push(this.cpaleFees);
          // this.disablestate = true;
          this.typeSelection = "";
          // this.modeSubjects = true;
          this.CmaSubjects = true;
          this.toggle = false;
          this.subjectarr = [];
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          // if(this.usp_zero_discount == true){
          //   console.log("total discount hellooooooo");
          //   this.Totaldiscount = 20000;
          // }
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.cfa_payments = false;
          this.cma_payments = false;
          this.aicpa_payments = false;
          this.cpa_payments = true;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          this.select_training = "Bridge Course – Public Accounting";
          break;
          case "AICPA":
          this.modeSubjects = false;
          this.levels = [
            "Training Only",
          ];
          this.cmaSubjects = [];
          this.select_training = "";
          this.typeSelection = "";
          // this.modeSubjects = true;
          this.CmaSubjects = false;
          this.toggle = false;
          this.subjectarr = [];
          this.ExamFee = 0;
          this.ExamDiscount = 0;
          this.ExamTotal = 0;
          this.Totalfee = 0;
          this.Totaldiscount = 0;
          this.Total = 0;
          this.Studyfee = 0;
          this.Trainingfee = 0;
          this.Studydiscount = 0;
          this.Studytotal = 0;
          this.Trainingdiscount = 0;
          this.Trainingtotal = 0;
          this.cfa_payments = false;
          this.cma_payments = false;
          this.aicpa_payments = true;
          this.cpa_payments = false;
          this.frm_payments = false;
          this.show_discount_total = false;
          this.show_frm_discount_total = false;
          break;
      }
    },
    select3(value) {
      if(this.select2 === "CPA-AA") {
        switch (value) {
        case "Professional":
          this.typeCategories = ["B2C", "Corporate"];
          this.select_training = "Only License";
          this.cmaStudyfee = 0;
          this.cfaStudyfee = 0;
          this.cmaTrainingfee = 0;
          this.cfaTrainingfee = 0;
          this.cmaStudytotal = 0;
          this.cfaStudytotal = 0;
          this.cmaTrainingtotal = 0;
          this.cfaTrainingtotal = 0;
          this.cmaTotal = 0;
          this.cfaTotal = 0;
          break;
        case "Student":
          this.typeCategories = ["B2C", "University"];
          this.select_training = "Only License";
          this.cmaStudyfee = 0;
          this.cfaStudyfee = 0;
          this.cmaTrainingfee = 0;
          this.cfaTrainingfee = 0;
          this.cmaStudytotal = 0;
          this.cfaStudytotal = 0;
          this.cmaTrainingtotal = 0;
          this.cfaTrainingtotal = 0;
          this.cmaTotal = 0;
          this.cfaTotal = 0;
          break;
        }
      } else {
        switch (value) {
        case "Professional":
          this.typeCategories = ["B2C", "Corporate"];
          // this.clearAll();
          this.cmaStudyfee = 0;
          this.cfaStudyfee = 0;
          this.cmaTrainingfee = 0;
          this.cfaTrainingfee = 0;
          this.cmaStudytotal = 0;
          this.cmaTrainingtotal = 0;
          this.cfaTrainingtotal = 0;
          this.cmaTotal = 0;
          this.cfaTotal = 0;
          break;
        case "Student":
          this.typeCategories = ["B2C", "University"];
          // this.clearAll();
          this.cmaStudyfee = 0;
          this.cfaStudyfee = 0;
          this.cmaTrainingfee = 0;
          this.cfaTrainingfee = 0;
          this.cmaStudytotal = 0;
          this.cmaTrainingtotal = 0;
          this.cfaTrainingtotal = 0;
          this.cmaTotal = 0;
          this.cfaTotal = 0;
          break;
      }
      
      }
    },
    typeSelection(value) {
      if(this.select2 === 'CPA'){
        this.selectedCorporateOption = "";
        this.select_training = "";
      }
      // this.generateBatch();
      if (value === "Corporate") {
        // console.log("ting");
        this.enableCorporateSelection = true;
        this.selectedCorporateOption = "B2B";
      } else {
        this.enableCorporateSelection = false;
      }
    },
    radios2: function (value) {
      if (this.select2 === "CPA") {
        this.subjectarr.push(this.cpaleFees);
      } else if (this.select2 === "CMA") {
        if (value === "online_training") {
          this.cmapart1 = {
            material: 262.5,
            training: 22950,
            name: "CMA Part 1",
          };
          this.cmapart2 = {
            material: 262.5,
            training: 22950,
            name: "CMA Part 2",
          };
        } else if (value === "classroom_training") {
          this.cmapart1 = {
            material: 262.5,
            training: 22950,
            name: "CMA Part 1",
          };
          this.cmapart2 = {
            material: 262.5,
            training: 22950,
            name: "CMA Part 2",
          };
        } else if (value === "online_classroom") {
          this.cmapart1 = {
            material: 262.5,
            training: 28850,
            name: "CMA Part 1",
          };
          this.cmapart2 = {
            material: 262.5,
            training: 28850,
            name: "CMA Part 2",
          };
        }
        this.cmaSubjects = [];
        this.cmaSubjects.push(this.cmapart1);
        this.cmaSubjects.push(this.cmapart2);
      } else if (this.select2 === "CFA") {
        if (value === "online_training") {
           this.cfaFees = { 
             material: 9000,
            training: 30000,
           }
        } else if (value === "classroom_training") {
          this.cfaFees = { 
             material: 9000,
            training: 30000,
           }
        } else if (value === "online_classroom") {
          this.cfaFees = { 
             material: 9000,
            training: 30000,
           }
        }
        this.cfaSubjects = [];
        this.cfaSubjects.push(this.cfaFees);
      }
    },
    select_training: function (value) {
      if (this.select2 === "CPA") {
        if (value === "Study Material + Training") {
          this.Studydiscount = 0;
          this.Trainingdiscount = 0;
          this.ExamDiscount = 0;
          this.cmagst = 5400;
          this.cpaleFees.training = 10000;
          this.wiley_study_material_cost_with_gst = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.enrollment_study_material_fees = 39000;
          this.total_study_material_cost = this.enrollment_study_material_fees - this.Studydiscount;
          if(this.selectedCorporateOption == "B2B"){
            this.enrollment_training_fees = 25949;
          }else{
          this.enrollment_training_fees = 32373;
          }
          this.disablestate = true;
          this.cpalemodel = [];
          this.subjectarr.push(this.cpaleFees);
          this.total_selected_subjects = [];
          this.total_selected_license_fee = []; 
          this.total_selected_subjects.push(this.auditingfees);
          this.total_selected_subjects.push(this.bussinessenvfees);
          this.total_selected_subjects.push(this.financialfees);
          this.total_selected_subjects.push(this.regulationfees);
          this.total_selected_license_fee.push(this.cpaleFees);
          this.radios2 = "online_training";
          this.modeSubjects = true;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material_training", bDate);
        } else if (value === "Only License") {
          this.Trainingdiscount = 0;
          this.ExamDiscount = 0;
          this.cmagst = 0;
          this.disablestate = true;
          this.modeSubjects = false;
          this.subjectarr = [this.cpaleFees];
          this.total_selected_subjects = [];
          this.total_selected_license_fee = [];
          this.cpaleFees.training = 49000;
          this.total_selected_license_fee.push(this.cpaleFees);
          this.wiley_study_material_cost_with_gst = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.enrollment_study_material_fees = 0;
          this.total_study_material_cost = 0 - this.Studydiscount;
          this.enrollment_training_fees = 0;
          this.cpalemodel.push(this.cpaleFees);
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("only_license", bDate);
        } else if (value === "Study Material Only") {
          this.Trainingdiscount = 0;
          this.ExamDiscount = 0;
          this.cpaleFees.training = 0;
          this.disablestate = true;
          this.radios2 = "";
          this.cmagst = 0;
          this.wiley_study_material_cost_with_gst = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.enrollment_study_material_fees = 39000;
          this.total_study_material_cost = this.enrollment_study_material_fees - this.Studydiscount;
          this.enrollment_training_fees = 0;
          this.subjectarr = [];
          this.cpalemodel = [];
          this.total_selected_subjects = [];
          this.total_selected_license_fee = [];
          this.total_selected_subjects.push(this.auditingfees);
          this.total_selected_subjects.push(this.bussinessenvfees);
          this.total_selected_subjects.push(this.financialfees);
          this.total_selected_subjects.push(this.regulationfees);
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material_only", bDate);
        }
      } else if (this.select2 === "CMA") {
        if (value === "Study Material + Training") {
          this.radios2 = "online_training";
          if (this.select3 === "Student") {
            this.cmaStudyfee = 39000;
            // this.isCPAEnrolled();
            // this.wiley_study_material_cost_without_gst = 525;
            // this.wiley_study_material_cost_with_gst = 39900;
            this.cmaTrainingfee = 23898;
            this.cmaStudytotal = this.cmaStudyfee - this.cmaStudydiscount;
            // this.cmaStudytotal = 49000;
            this.traningGst = 4302;
            this.cmaTrainingtotal =
              this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
            this.cmaTotal =
              this.cmaStudytotal +
              this.cmaTrainingtotal;
            this.Total = this.cmaTotal;
          } else if (this.select3 === "Professional") {
            this.cmaStudyfee = 39000;
            // this.isCPAEnrolled();
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.cmaTrainingfee = 23898;
            // this.cmaStudytotal = 49000;
            this.cmaStudytotal = this.cmaStudyfee - this.cmaStudydiscount;
            this.traningGst = 4302;
            this.cmaTrainingtotal =
              this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
            this.cmaTotal =
              this.cmaStudytotal +
              this.cmaTrainingtotal ;
            this.Total = this.cmaTotal;
          }
          this.cmaSubjects = [];
          this.disablestate = true;
          this.cpalemodel = [];
          this.modeSubjects = true;
          this.cmaSubjects.push(this.cmapart1);
          this.cmaSubjects.push(this.cmapart2);
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material_training", bDate);
        } else if (value === "Study Material Only") {
          if (this.select3 === "Student") {
            this.cmaStudyfee = 39000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.cmaStudydiscount = 0;
            // this.cmaStudytotal = 49000;
            this.cmaStudytotal = this.cmaStudyfee - this.cmaStudydiscount;
            this.cmaTrainingfee = 0;
            this.cmaTrainingtotal = 0;
            this.traningGst = 0;
            this.cmaTotal =
              this.cmaStudytotal ;
            this.Total = this.cmaTotal;
          } else if (this.select3 === "Professional") {
            this.cmaStudyfee = 39000;
            // this.isCPAEnrolled();
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            // this.cmaStudytotal = 49000;
            this.cmaStudydiscount = 0;
            this.cmaStudytotal = this.cmaStudyfee - this.cmaStudydiscount;
            this.cmaTrainingfee = 0;
            this.cmaTrainingtotal = 0;
            this.traningGst = 0;
            this.cmaTotal =
              this.cmaStudytotal ;
            this.Total = this.cmaTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.cmapart1.training = 0;
          this.cmapart2.training = 0;
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material", bDate);
        } else if (value === "Training Only") {
          if (this.select3 === "Student") {
            this.cmaStudyfee = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.cmaTrainingfee = 23898;
            this.cmaStudytotal = 0;
            this.traningGst = 4302;
            //9100 is study note cost
            this.cmaTrainingtotal =
              this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
            this.cmaTotal =
              this.cmaStudytotal +
              this.cmaTrainingtotal +
              this.wiley_study_material_cost_with_gst;
            this.Total = this.cmaTotal;
          } else if (this.select3 === "Professional") {
            this.cmaStudyfee = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.cmaTrainingfee = 23898;
            this.cmaStudytotal = 0;
            this.traningGst = 4302;
            //9100 is study note cost
            this.cmaTrainingtotal =
              this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
            this.cmaTotal =
              this.cmaStudytotal +
              this.cmaTrainingtotal +
              this.wiley_study_material_cost_with_gst;
            this.Total = this.cmaTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.cmaStudyfee = 0;
          this.cmaStudytotal = 0;
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("training_only", bDate);
        }
      } else if (this.select2 === "CFA") {
        if (value === "Study Material + Training") {
          this.show_discount_total = false;
          this.cfaTrainingdiscount = 0;
          this.radios2 = "online_training";
          if (this.select3 === "Student") {
            this.cfaStudyfee = 29000;
            // this.wiley_study_material_cost_without_gst = 525;
            // this.wiley_study_material_cost_with_gst = 39900;
            this.cfaTrainingfee = 10000;
            this.cfaStudytotal = 29000;
            this.traningGst = 4302;
            this.cfaTrainingtotal = this.cfaTrainingfee;
            // this.cfaTrainingtotal =
            //   this.cfaTrainingfee + this.traningGst - this.cfaTrainingdiscount;
            this.cfaTotal =
              this.cfaStudytotal + this.cfaTrainingfee;
            this.Total = this.cfaTotal;
            if(this.selected_discount == "5%"){
              this.show_discount_total = true;
              let percentage = 5/100;
              this.cfaTrainingdiscount = this.cfaTotal*percentage ;
              this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
            }
            if(this.selected_discount == "10%"){
              this.show_discount_total = true;
              let percentage = 10/100;
              this.cfaTrainingdiscount = this.cfaTotal*percentage ;
              this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
            }
          } else if (this.select3 === "Professional") {
            this.cfaStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.cfaTrainingfee = 10000;
            this.cfaStudytotal = 29000;
            this.traningGst = 4302;
            this.cfaTrainingtotal =
              this.cfaTrainingfee;
            this.cfaTotal =
              this.cfaStudytotal +
              this.cfaTrainingtotal ;
            this.Total = this.cfaTotal;
          }
          this.cmaSubjects = [];
          this.disablestate = true;
          this.cpalemodel = [];
          this.modeSubjects = true;
          this.cmaSubjects.push(this.cfaFees);
          this.batch = this.selected_cfa_batch;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material_training", bDate);
          if(this.selected_discount == "5%"){
              this.show_discount_total = true;
              let percentage = 5/100;
              this.cfaTrainingdiscount = this.cfaTotal*percentage ;
              this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
            }
            if(this.selected_discount == "10%"){
              this.show_discount_total = true;
              let percentage = 10/100;
              this.cfaTrainingdiscount = this.cfaTotal*percentage ;
              this.CFA_discount_total = this.cfaTotal - this.cfaTrainingdiscount ;
            }
        } else if (value === "Study Material Only") {
          this.show_discount_total = false;
          this.cfaTrainingdiscount = 0;
          if (this.select3 === "Student") {
            this.show_discount_total == false;
            this.cfaStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.cfaStudytotal = 29000;
            this.cfaTrainingfee = 0;
            this.cfaTrainingtotal = this.cfaTrainingfee ;
            this.traningGst = 0;
            this.cfaTotal = this.cfaStudytotal + this.cfaTrainingfee;
            this.Total = this.cfaTotal;
          } else if (this.select3 === "Professional") {
            this.show_discount_total == false;
            this.cfaStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.cfaStudytotal = 29000;
            this.cfaTrainingfee = 0;
            this.cfaTrainingtotal = this.cfaTrainingfee ;
            this.traningGst = 0;
            this.cfaTotal = this.cfaStudytotal ;
            this.Total = this.cfaTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.cfaFees.training = 0;
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material", bDate);
        } else if (value === "Training Only") {
          this.cfaTrainingdiscount = 0;
          this.show_discount_total = false;
          if (this.select3 === "Student") {
            this.cfaStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.cfaTrainingfee = 10000;
            this.cfaStudytotal = 29000;
            this.traningGst = 0;
            //9100 is study note cost
            this.cfaTrainingtotal =
              this.cfaTrainingfee;
            this.cfaTotal =
              this.cfaStudytotal +
              this.cfaTrainingfee;
            this.Total = this.cfaTotal;
          } else if (this.select3 === "Professional") {
            this.cfaStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.cfaTrainingfee = 10000;
            this.cfaStudytotal = 0;
            this.traningGst = 4302;
            //9100 is study note cost
            this.cfaTrainingtotal =
              this.cfaTrainingfee;
            this.cfaTotal =
              this.cfaStudytotal +
              this.cfaTrainingtotal +
              this.wiley_study_material_cost_with_gst;
            this.Total = this.cfaTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.cfaStudyfee = 0;
          this.cfaStudytotal = 0;
          this.modeSubjects = false;
          this.batch = this.selected_cfa_batch;
          let bDate = this.datatoTimestamp(this.date2);
          console.log("batch start date AICPA",bDate);
          this.generateBatch("training_only", bDate);
        }
      } else if (this.select2 === "FRM") {
        if (value === "Study Material + Training") {
          this.show_frm_discount_total = false;
          this.frmTrainingdiscount = 0;
          this.radios2 = "online_training";
          if (this.select3 === "Student") {
            this.frmStudyfee = 29000;
            // this.wiley_study_material_cost_without_gst = 525;
            // this.wiley_study_material_cost_with_gst = 39900;
            this.frmTrainingfee = 10000;
            this.frmStudytotal = 29000;
            this.traningGst = 4302;
            this.frmTrainingtotal = this.frmTrainingfee;
            // this.cfaTrainingtotal =
            //   this.cfaTrainingfee + this.traningGst - this.cfaTrainingdiscount;
            this.frmTotal =
              this.frmStudytotal + this.frmTrainingfee;
            this.Total = this.frmTotal;
            if(this.selected_discount == '5%'){
              this.show_frm_discount_total = true;
              let percentage = 5/100;
              this.frmTrainingdiscount = this.frmTotal*percentage;
              this.frm_discount_training_total = this.frmTotal - this.frmTrainingdiscount ;
            }
            if(this.selected_discountal == '10%'){
              this.show_frm_discount_total = true;
              let percentage = 10/100;
              this.frmTrainingdiscount = this.frmTotal*percentage;
              this.frm_discount_training_total = this.frmTrainingfee - this.frmTrainingdiscount;
              // this.frm_discount_total = this.frmTotal - this.frmTrainingdiscount ;
            }
          } else if (this.select3 === "Professional") {
            this.frmStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.frmTrainingfee = 10000;
            this.frmStudytotal = 29000;
            this.traningGst = 4302;
            this.frmTrainingtotal =
              this.frmTrainingfee;
            this.frmTotal =
              this.frmStudytotal +
              this.frmTrainingtotal ;
            this.Total = this.frmTotal;
          }
          this.cmaSubjects = [];
          this.disablestate = true;
          this.cpalemodel = [];
          this.modeSubjects = true;
          this.cmaSubjects.push(this.cfaFees);
          this.batch = this.selected_cfa_batch;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material_training", bDate);
          if(this.selected_discount == '5%'){
              this.show_frm_discount_total = true;
              let percentage = 5/100;
              this.frmTrainingdiscount = this.frmTotal*percentage;
              this.frm_discount_training_total = this.frmTrainingfee - this.frmTrainingdiscount;
              // this.frm_discount_total = this.frmTotal - this.frmTrainingdiscount ;
            }
            if(this.selected_discountal == '10%'){
              this.show_frm_discount_total = true;
              let percentage = 10/100;
              this.frmTrainingdiscount = this.frmTotal*percentage;
              this.frm_discount_training_total = this.frmTrainingfee - this.frmTrainingdiscount;
              // this.frm_discount_total = this.frmTotal - this.frmTrainingdiscount ;ss
            }
        } else if (value === "Study Material Only") {
          this.show_frm_discount_total = false;
          this.frmTrainingdiscount = 0;
          if (this.select3 === "Student") {
            this.show_frm_discount_total = false;
            this.frmStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.frmStudytotal = 29000;
            this.frmTrainingfee = 0;
            this.frmTrainingtotal = this.frmTrainingfee ;
            this.traningGst = 0;
            this.frmTotal = this.frmStudytotal + this.frmTrainingfee;
            this.Total = this.frmTotal;
          } else if (this.select3 === "Professional") {
            this.show_frm_discount_total = false;
            this.frmStudyfee = 29000;
            this.wiley_study_material_cost_without_gst = 525;
            this.wiley_study_material_cost_with_gst = 0;
            this.frmStudytotal = 29000;
            this.frmTrainingfee = 0;
            this.frmTrainingtotal = this.frmTrainingfee ;
            this.traningGst = 0;
            this.frmTotal = this.frmStudytotal ;
            this.Total = this.frmTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.frmFees.training = 0;
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("study_material", bDate);
        } else if (value === "Training Only") {
          this.frmTrainingdiscount = 0;
          this.show_frm_discount_total = false;
          if (this.select3 === "Student") {
            this.frmStudyfee = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.frmTrainingfee = 10000;
            this.frmStudytotal = 0;
            this.traningGst = 0;
            //9100 is study note cost
            this.frmTrainingtotal =
              this.frmTrainingfee;
            this.frmTotal =
              this.frmStudytotal +
              this.frmTrainingfee;
            this.Total = this.frmTotal;
          } else if (this.select3 === "Professional") {
            this.frmStudyfee = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.wiley_study_material_cost_with_gst = 0;
            this.frmTrainingfee = 10000;
            this.frmStudytotal = 0;
            // this.traningGst = 4302;
            //9100 is study note cost
            this.frmTrainingtotal =
              this.frmTrainingfee;
            this.frmTotal =
              this.frmStudytotal +
              this.frmTrainingtotal +
              this.wiley_study_material_cost_with_gst;
            this.Total = this.frmTotal;
          }
          this.disablestate = true;
          this.radios2 = "";
          this.frmStudyfee = 0;
          this.frmStudytotal = 0;
          this.modeSubjects = false;
          this.batch = this.selected_frm_batch;
          let bDate = this.datatoTimestamp(this.date2);
          this.generateBatch("training_only", bDate);
        }
      } else if (this.select2 === "AICPA") {
        if (value === "Training Only") {
          if (this.select3 === "Student") {
            this.aicpa_tax = 0;
            this.aicpa_gst = 0;
            this.aicpa_training_total = this.aicpa_tax + this.aicpa_gst;
            this.aicpa_exam_tax_essentials_tax = 17440.68;
            this.aicpa_exam_tax_essentials_gst = 3139.32;
            this.aicpa_exam_tax_essentials_total = this.aicpa_exam_tax_essentials_tax + this.aicpa_exam_tax_essentials_gst;
            this.aicpa_exam_audit_essentials_tax = 15305.08;
            this.aicpa_exam_audit_essentials_gst = 2754.92;
            this.aicpa_exam_audit_essentials_total = this.aicpa_exam_audit_essentials_tax + this.aicpa_exam_audit_essentials_gst;
            this.aicpa_training_fee = this.aicpa_training_total + this.aicpa_exam_tax_essentials_total + this.aicpa_exam_audit_essentials_total ;
            if(this.preCourses.length == 0){
              this.aicpa_tax = 40000.00;
              this.aicpa_gst = 7200.00;
              this.aicpa_training_total = this.aicpa_tax + this.aicpa_gst;
              this.aicpa_training_fee = this.aicpa_training_total + this.aicpa_exam_tax_essentials_total + this.aicpa_exam_audit_essentials_total;
            }
            this.aicpa_total = this.aicpa_training_fee;
            this.Total = this.aicpa_total;
          } else if (this.select3 === "Professional") {
            this.aicpa_tax = 0;
            this.aicpa_gst = 0;
            this.aicpa_training_total = this.aicpa_tax + this.aicpa_gst;
            this.aicpa_exam_tax_essentials_tax = 17440.68;
            this.aicpa_exam_tax_essentials_gst = 3139.32;
            this.aicpa_exam_tax_essentials_total = this.aicpa_exam_tax_essentials_tax + this.aicpa_exam_tax_essentials_gst;
            this.aicpa_exam_audit_essentials_tax = 15305.08;
            this.aicpa_exam_audit_essentials_gst = 2754.92;
            this.aicpa_exam_audit_essentials_total = this.aicpa_exam_audit_essentials_tax + this.aicpa_exam_audit_essentials_gst;
            this.aicpa_training_fee = 38640.00 ;
            if(this.preCourses.length == 0){
              this.aicpa_tax = 40000.00;
              this.aicpa_gst = 7200.00;
              this.aicpa_training_total = this.aicpa_tax + this.aicpa_gst;
              this.aicpa_training_fee = 85840.00;
            }
            this.aicpa_total = this.aicpa_training_fee;
            this.Total = this.aicpa_total;
          }
          this.disablestate = true;
          this.radios2 = "";
          // this.aicpa_study_fee = 0;
          this.aicpa_study_total = 0;
          this.modeSubjects = false;
          let bDate = this.datatoTimestamp(this.date2);
          console.log("batch start date AICPA",bDate);
          this.generateBatch("training_only", bDate);
        }
      } 
      if (this.select2 === "CPA-AA") {
        if (value === "Only License") {
          if(this.cpa_aa_select === "Passed all 4 exams") {
            this.Trainingdiscount = 0;
            this.ExamDiscount = 0;
            this.cmagst = 0;
            this.disablestate = true;
            this.modeSubjects = false;
            this.subjectarr = [this.cpaleFees];
            this.total_selected_subjects = [];
            this.total_selected_license_fee = [];
            this.cpaleFees.training = 39000;
            this.total_selected_license_fee.push(this.cpaleFees);
            this.wiley_study_material_cost_with_gst = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.enrollment_study_material_fees = 0;
            this.total_study_material_cost = 0 - this.Studydiscount;
            this.enrollment_training_fees = 0;
            this.cpalemodel.push(this.cpaleFees);
            let bDate = this.datatoTimestamp(this.date2);
            this.generateBatch("only_license", bDate);
          }
          if(this.cpa_aa_select === "Not passed all 4 exams") {
            this.Trainingdiscount = 0;
            this.ExamDiscount = 0;
            this.cmagst = 0;
            this.disablestate = true;
            this.modeSubjects = false;
            this.subjectarr = [this.cpaleFees];
            this.total_selected_subjects = [];
            this.total_selected_license_fee = [];
            this.cpaleFees.training = 49000;
            this.total_selected_license_fee.push(this.cpaleFees);
            this.wiley_study_material_cost_with_gst = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.enrollment_study_material_fees = 0;
            this.total_study_material_cost = 0 - this.Studydiscount;
            this.enrollment_training_fees = 0;
            this.cpalemodel.push(this.cpaleFees);
            let bDate = this.datatoTimestamp(this.date2);
            this.generateBatch("only_license", bDate);
          }
          
        } 
      } 
      if (this.select2 === "USP" || this.select2 === "Bridge Course – Public Accounting") {
        if (value === "Assistance for US-Placement") {
            this.Trainingdiscount = 0;
            this.ExamDiscount = 0;
            this.cmagst = 0;
            this.disablestate = true;
            this.modeSubjects = false;
            this.subjectarr = [this.cpaleFees];
            this.total_selected_subjects = [];
            this.total_selected_license_fee = [];
            this.cpaleFees.training = this.selected_cpa_us_fee;
            this.total_selected_license_fee.push(this.cpaleFees);
            this.wiley_study_material_cost_with_gst = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.enrollment_study_material_fees = 0;
            this.total_study_material_cost = 0 - this.Studydiscount;
            this.enrollment_training_fees = 0;
            this.cpalemodel.push(this.cpaleFees);
            let bDate = this.datatoTimestamp(this.date2);
            this.generateBatch("only_license", bDate);
        } 
        if (value === "Bridge Course – Public Accounting") {
            this.Trainingdiscount = 0;
            this.ExamDiscount = 0;
            this.cmagst = 0;
            this.disablestate = true;
            this.modeSubjects = false;
            this.subjectarr = [this.cpaleFees];
            this.total_selected_subjects = [];
            this.total_selected_license_fee = [];
            this.cpaleFees.training = this.selected_bridge_course_fee;
            this.total_selected_license_fee.push(this.cpaleFees);
            this.wiley_study_material_cost_with_gst = 0;
            this.wiley_study_material_cost_without_gst = 0;
            this.enrollment_study_material_fees = 0;
            this.total_study_material_cost = 0 - this.Studydiscount;
            this.enrollment_training_fees = 0;
            this.cpalemodel.push(this.cpaleFees);
            let bDate = this.datatoTimestamp(this.date2);
            this.generateBatch("only_license", bDate);
        } 
      } 
    },
    cmaSubjects() {
      this.calculateCMA();
    },
    subjectarr: function (value) {
      // console.log(value);
      this.ExamFee = 0;
      let totalmaterial = 0;
      let totaltraining = 0;
      value.forEach((subject) => {
        if (subject.material === 0 && subject.training === 0) {
          // console.log("this command is running somewhere");
          this.getexam();
        } else {
          totalmaterial = totalmaterial + subject.material;
          totaltraining = totaltraining + subject.training;
        }
      });
      if (value.length === 5 && this.select_training === "Study Material + Training") {
        this.Studyfee = totalmaterial;
        this.Trainingfee = totaltraining - 29500;
        this.ExamFee = 29500;
      } else if (
        value.length === 4 &&
        this.select_training === "Study Material + Training"
      ) {
        this.Studyfee = totalmaterial - 26000;
        this.Trainingfee = totaltraining - 32850;
      } else if (value.length === 1 && value[0].name === "exam_license_assistance" && this.select2 != 'USP' && this.select2 != 'Bridge Course – Public Accounting' ) {
        this.select_training = "Only License";
        this.Studyfee = 0;
        this.Trainingfee = 0;
        this.ExamFee = 29500;
      } else if (value.length === 1 && value[0].name === "exam_license_assistance" && this.select2 == 'USP') {
        this.select_training = "Assistance for US-Placement";
        this.Studyfee = 0;
        this.Trainingfee = 0;
        this.ExamFee = 29500;
      }else if (value.length === 1 && value[0].name === "exam_license_assistance" && this.select2 == 'Bridge Course – Public Accounting') {
        this.select_training = "Bridge Course – Public Accounting";
        this.Studyfee = 0;
        this.Trainingfee = 0;
        this.ExamFee = 29500;
      }
      else if (value.length === 5 && this.select_training === "Study Material Only") {
        this.Studyfee = totalmaterial - 26000;
        this.Trainingfee = totaltraining;
      } else if (value.length === 4 && this.select_training === "Study Material Only") {
        if (this.ContainsLicence(value)) {
          this.Studyfee = totalmaterial;
          this.Trainingfee = totaltraining;
          // console.log();
        } else {
          this.Studyfee = totalmaterial - 26000;
          this.Trainingfee = 0;
        }
      } else {
        this.Studyfee = totalmaterial;
        this.Trainingfee = totaltraining;
      }
    },
    Studyfee: function () {
      this.calculatetotal();
    },
    Trainingfee: function () {
      this.calculatetotal();
    },
    Studydiscount: function () {
      this.calculatetotal();
    },
    Trainingdiscount: function () {
      // if (value === "") {
      //     this.Trainingdiscount = 0;
      //   }
      this.calculatetotal();
    },
    aicpa_training_fee_discount: function () {
      this.calculateAICPA();
    },
    cpalemodel: function (value) {
      if (value.length !== 0) {
        this.getexam();
      } else {
        this.ExamFee = 29500;
      }
    },
    ExamFee: function () {
      this.calculatetotal();
    },
    ExamDiscount: function () {
      this.calculatetotal();
    },
    Studytotal: function () {
      this.calculateMegaTotal();
    },
    Trainingtotal: function () {
      this.calculateMegaTotal();
    },
    ExamTotal: function () {
      this.calculateMegaTotal();
    },
    cmaStudytotal: function () {
      this.calculatemanualtotal();
    },
    cfaStudytotal: function () {
      this.calculatecfamanualtotal();
    },
    cmaTrainingdiscount: function () {
      this.calculatecmatrainediscount();
    },
    cmaTrainingtotal: function () {
      this.calculatemanualtotal();
    },
    cfaTrainingtotal: function () {
      this.calculatecfamanualtotal();
    },
  },
  methods: {
    // changeDiscount(){ //R
    //   for (let i = 0; i < this.payload.enrollments.length; i++) {
    //     const element = this.payload.enrollments[i];
    //     console.log("enrollment_date",element.enrollment_date, 1688103414);
    //     if((element.course == 'CMA' || element.course == 'CPA') && element.enrollment_date < 1688103414){
    //       this.usp_zero_discount = true;
    //       this.selected_cpa_us_fee = 20000;
    //       this.ExamDiscount = 20000;
    //     }
    //   }
    // },
    // changeTrainingDiscount(){
    //   this.isCMAEnrolled();
    //   if(this.cma_enrolled === true && this.select_training === "Study Material + Training"){ 
    //     return this.Trainingdiscount = 53100;
    //   }
    // },
    restrictInput() {
      this.Trainingdiscount = this.Trainingdiscount.replace(/[^0-9]/g, '');
    },
    // changeStudyDiscount(){
    //   this.isCMAEnrolled();
    //   if(this.cma_enrolled === true && this.select_training === "Study Material + Training"){ 
    //     return this.Studydiscount = 39000;
    //   }
    // },
    // changeExamDiscount(){
    //   this.isCMAEnrolled();
    //   if(this.cma_enrolled === true && this.select_training === "Study Material + Training"){ 
    //     return this.ExamDiscount = 11800;
    //   }
    // },
    getBridgeCourseData(){
      this.electives_choosen = []
      let obj = {
        can_id : this.payload.identity
      }
      let  url = `${constants.SERVER_API}getBridgeCourseData`
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getBridgeCourseData", response.data);
          if(response.data != 'Miles bridge course eligibility not found'){
            this.electives_choosen = response.data.recommend_arka_elective_ids
            this.electives_data = false;
          }
          else {
            this.electives_data = true;
            this.$vs.notify({
              title: "Bridge course eligibility not found",
              text: "",
              color: "danger",
            });
            }

        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // isCMAEnrolled(){
    //   let obj = {
    //     identity : this.payload.identity
    //   }
    //   let  url = `${constants.SERVER_API}isCMAEnrolled`
    //   console.log("isCMAEnrolled",obj, url);
    //   axios
    //     .get(url, {
    //       params: obj,
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       this.cma_enrolled = response.data;
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    // isCPAEnrolled(){
    //   let obj = {
    //     identity : this.payload.identity
    //   }
    //   let  url = `${constants.SERVER_API}isCPAEnrolled`
    //   console.log("isCPAEnrolled",obj, url);
    //   axios
    //     .get(url, {
    //       params: obj,
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       this.cpa_enrolled = response.data;
    //       if(this.cpa_enrolled == true && this.select_training == 'Study Material + Training'){
    //         this.cmaStudydiscount = 39000;
    //       }
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    cpaSubjectCalculation(val) {
      // console.log(val);
      if(this.select2 !== 'CFA' && this.select2 !== 'FRM')
      {
        if(this.select_training !== "")
        {
          console.log(val);
        }
        else{
          this.$vs.notify({
            title: ``,
            text: "Please select above fields",
            color: "warning",
          });
        }
      }
    },
    uspReEnrollmentStatus(data) {
      this.course = data;
      if (this.add_usp_course) {
        if (!this.course.includes("USP")) {
          this.course.push("USP");
        }
      }
    },
    StartBatchDate() {
      let url = "https://website-api.mileseducation.com/getMilesBatchStartDate";
      axios
        .get(url, {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1OTI2MzU1MjMsImV4cCI6MTU5MjcyMTkyM30.WvYnPPPD_0kk1glz3jHtydV3a3b-Ud006aT1ysMaeos",
          },
        })
        .then((response) => {
          this.dates = response.data.data;
          let StartDate = this.dates;
          console.log("StartDate",StartDate);
          this.BatchDate(StartDate);
          let check_frm = StartDate.some(function(el) {
            return el.name === "frm";
          });
          if(check_frm){
            // console.log(" coming",);
            this.BatchDate(StartDate);
          }
          if(!check_frm){
            let date = new Date("April 23, 2022");
            this.frmBatch = moment(date).format("DD MMMM YYYY");
            this.frmBatchDate = moment(date).format("DD MMMM YYYY");
          }
          if (this.dates !== "") {
            EventBus.$on("enrollment-opened", (details) => {
              // console.log("check courses and disable", this.dates);
              // console.log(details.payloadEmails);
              this.clearAll();
              this.payloadEmails = details.payloadEmails;
              let courseDetails = ["CPA", "CMA", "CPA-AA", "CFA", "FRM", "USP", "Bridge Course – Public Accounting"];
              this.preCourses = this.getEnrolledCourses(details);
              this.getEnrolledCfaLevels(details);
              this.getEnrolledFrmLevels(details);
              this.preCourses.forEach((course) => {
                if (courseDetails.indexOf(course) !== -1) {
                  courseDetails.splice(courseDetails.indexOf(course), 1);
                  // let StartDate = this.dates;
                }
              });
              // console.log("tag", StartDate);
              this.uspReEnrollmentStatus(courseDetails)
              this.batch = "";
              this.reason = "";
              this.date = new Date();
              this.date2 = "";
              // this.select2 = "CPA";
              this.select2 = courseDetails[0];
              this.Studydiscount = 0;
              this.Trainingdiscount = 0;
              // this.cmaSubjects = [];
              this.cmaTrainingdiscount = 0;
              this.select_training = "";
              // this.total_selected_license_fee = [];
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    unixTimestampConvert(value) {
      var dateString = moment(value).format("DD MMMM YYYY");
      switch (dateString) {
        case "01 january 1970":
          return "";
        default:
          return dateString;
      }
    },
    clearAll() {
      // console.log("data");
      this.typeSelection = "";
      this.select_training = "";
      this.radios2 = "";
      this.reason = "";
      // this.StartBatchDate();
    },
    assignReferral(referralData) {
      // console.log("firing");
      this.referralData = referralData;
    },
    getEnrolledCourses(details) {
      let enrolled_courses = [];
      details.purchases.forEach((purchase) => {
        if(purchase.type == 'course' && purchase.enrollment_id != null){
        // if(purchase.type == 'course' && purchase.defaulter == 0 && purchase.enrollment_id != null){
          if(purchase.name != 'CFA' && purchase.name != 'FRM'){
            enrolled_courses.push(purchase.name);
          }
         
        }
      });
     
      return enrolled_courses;
    },
    getEnrolledCfaLevels(details){
      this.enrolled_cfa_levels = [];
       //To get enrolled CFA parts
      details.purchases.forEach((purchase) => {
        if(purchase.type == 'course' && purchase.enrollment_id != null){
        // if(purchase.type == 'course' && purchase.defaulter == 0 && purchase.enrollment_id != null){
          if(purchase.name == 'CFA'){
            details.enrollments.forEach((enrollment) => {
              this.enrolled_cfa_levels.push(enrollment.cfa_selected_level);
            });
          }
        }
      });
      //To remove enrolled CFA parts
      let cfa_all_levels = ["Level 1", "Level 2", "Level 3"];
       this.enrolled_cfa_levels.forEach((level) => {
        if (cfa_all_levels.indexOf(level) !== -1) {
          cfa_all_levels.splice(cfa_all_levels.indexOf(level), 1);
        }
      });
      //To remove enrolled CFA course, if all parts enrolled
      this.cfa_levels = cfa_all_levels;
      if(this.cfa_levels.length == 0){
        this.preCourses.push('CFA');
      }
    },
    getEnrolledFrmLevels(details){
      this.enrolled_frm_parts = [];
      //To get enrolled FRM parts
      details.purchases.forEach((purchase) => {
        if(purchase.type == 'course' && purchase.enrollment_id != null){
        // if(purchase.type == 'course' && purchase.defaulter == 0 && purchase.enrollment_id != null){
          if(purchase.name == 'FRM'){
            details.enrollments.forEach((enrollment) => {
              this.enrolled_frm_parts.push(enrollment.frm_selected_part);
            });
          }
        }
      });
      //To remove enrolled FRM parts
      let frm_all_parts = ["Part 1", "Part 2"];
       this.enrolled_frm_parts.forEach((part) => {
        if (frm_all_parts.indexOf(part) !== -1) {
          frm_all_parts.splice(frm_all_parts.indexOf(part), 1);
        }
      });
      //To remove enrolled FRM course, if all parts enrolled
      this.frm_parts = frm_all_parts;
      if(this.frm_parts.length == 0){
        this.preCourses.push('FRM');
      }
    },
    BatchDate(StartDate) {
      StartDate.forEach((element) => {
        // console.log(element);
        if (element.country === "india") {
          // console.log("course", element.course);
          if (element.course === "cpa") {
            this.cpaBatch = this.returnBatchDate(element.start_date);
            this.cpaBatchDate = this.returnBatchDate(element.start_date).format(
              "DD MMMM YYYY"
            );
            // console.log("date", this.cpaBatchDate, this.cpaBatch);
          } else if (element.course === "cma") {
            this.cmaBatch = this.returnBatchDate(element.start_date);
            this.cmaBatchDate = this.returnBatchDate(element.start_date).format(
              "DD MMMM YYYY"
            );
            // console.log("date", this.cmaBatchDate, this.cmaBatch);
          } else if (element.course === "cfa") {
            this.cfaBatch = this.returnBatchDate(element.start_date);
            this.cfaBatchDate = this.returnBatchDate(element.start_date).format(
              "DD MMMM YYYY"
            );
            // console.log("date", this.cmaBatchDate, this.cmaBatch);
          } else if (element.course === "frm") {
            this.frmBatch = this.returnBatchDate(element.start_date);
            this.frmBatchDate = this.returnBatchDate(element.start_date).format(
              "DD MMMM YYYY"
            );
            // console.log("date", this.cmaBatchDate, this.cmaBatch);
          } else if (element.course === "aicpa") {
            this.aicpa_batch = this.returnBatchDate(element.start_date);
            this.aicpa_batch_date = this.returnBatchDate(element.start_date).format(
              "DD MMMM YYYY"
            );
            // console.log("date", this.cmaBatchDate, this.cmaBatch);
          }
          
        }
      });
    },
    returnBatchDate(batchDate) {
      let dd = moment(batchDate, "DD/MM/YYYY");
      // console.log("dd", dd);
      return dd;
    },
    calculatemanualtotal() {
      this.cmaTotal =
        parseInt(this.cmaStudytotal) +
        parseInt(this.cmaTrainingtotal) +
        parseInt(this.wiley_study_material_cost_with_gst) + this.total_cma_exam_license_cost;
      this.Total = this.cmaTotal;
    },
    calculatecfamanualtotal() {
      this.cfaTotal =
        parseInt(this.cfaStudytotal) +
        parseInt(this.cfaTrainingtotal) +
        parseInt(this.wiley_study_material_cost_with_gst);
      this.Total = this.cfaTotal;
    },
    // cmaStudyfeetotal(){
    //    console.log("this.cmaStudyfee",this.cmaStudytotal)
    //    this.calculateCMA();
    // },
    calculateCMA() {
      // console.log("function called");
      this.ExamFee = 0;
      this.ExamDiscount = 0;
      this.ExamTotal = 0;
      this.cmaStudyfee = 0;
      // this.cmaStudydiscount = 0;
      // this.cmaStudytotal=0
      this.cmaTrainingfee = 0;
      this.cmaTrainingdiscount = 0;
      this.cmaTrainingtotal = 0;
      this.cmaTotal = 0;
      if (this.select3 === "Student") {
        if (this.select_training === "Training Only") {
          this.cmaStudyfee = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 23898;
          this.cmaStudytotal = 0;
          console.log("this.cmaStudytotall 19",this.cmaStudytotal);
          this.traningGst = 4302;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal +
            this.wiley_study_material_cost_with_gst;
          this.Total = this.cmaTotal;
        } else if (this.select_training === "Study Material Only") {
          this.cmaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 0;
          // this.cmaStudytotal = 49000;
          console.log("this.cmaStudytotall 20",this.cmaStudytotal);
          this.traningGst = 0;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal ;
          this.Total = this.cmaTotal;
        } else if (this.select_training === "Study Material + Training") {
          // this.cmaStudytotal = 38325;
          this.cmaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 23898;
          // this.cmaStudytotal = 49000;
          this.traningGst = 4302;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal ;
          this.Total = this.cmaTotal;
        }
        //5400 is gst
      } else if (this.select3 === "Professional") {
        if (this.select_training === "Training Only") {
          this.cmaStudyfee = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 23898;
          this.cmaStudytotal = 0;
          this.traningGst = 4302;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal +
            this.wiley_study_material_cost_with_gst;
          this.Total = this.cmaTotal;
        } else if (this.select_training === "Study Material Only") {
          this.cmaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 0;
          // this.cmaStudytotal = 49000;
          this.traningGst = 0;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal ;
          this.Total = this.cmaTotal;
        } else if (this.select_training === "Study Material + Training") {
          // this.cmaStudytotal = 38325;
          this.cmaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cmaTrainingfee = 23898;
          // this.cmaStudytotal = 49000;
          this.traningGst = 4302;
          this.cmaTrainingtotal =
            this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cmaTotal =
            this.cmaStudytotal +
            this.cmaTrainingtotal ;
          this.Total = this.cmaTotal;
        }
      }
    },
    calculateCFA() {
      // console.log("function called");
      this.ExamFee = 0;
      this.ExamDiscount = 0;
      this.ExamTotal = 0;
      this.cfaStudyfee = 0;
      // this.cmaStudydiscount = 0;
      // this.cmaStudytotal=0
      this.cfaTrainingfee = 0;
      this.cmaTrainingdiscount = 0;
      this.cfaTrainingtotal = 0;
      this.cfaTotal = 0;
      if (this.select3 === "Student") {
        if (this.select_training === "Training Only") {
          this.cfaStudyfee = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 30000;
          this.cfaStudytotal = 0;
          this.traningGst = 4302;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal +
            this.wiley_study_material_cost_with_gst;
          this.Total = this.cfaTotal;
        } else if (this.select_training === "Study Material Only") {
          this.cfaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 0;
          this.cfaStudytotal = 39000;
          this.traningGst = 0;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal ;
          this.Total = this.cfaTotal;
        } else if (this.select_training === "Study Material + Training") {
          // this.cmaStudytotal = 38325;
          this.cfaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 9000;
          this.cfaStudytotal = 39000;
          this.traningGst = 4302;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal ;
          this.Total = this.cfaTotal;
        }
        //5400 is gst
      } else if (this.select3 === "Professional") {
        if (this.select_training === "Training Only") {
          this.cfaStudyfee = 0;
          this.wiley_study_material_cost_without_gst = 0;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 9000;
          this.cfaStudytotal = 0;
          this.traningGst = 4302;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cfaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal +
            this.wiley_study_material_cost_with_gst;
          this.Total = this.cfaTotal;
        } else if (this.select_training === "Study Material Only") {
          this.cfaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 0;
          this.cfaStudytotal = 39000;
          this.traningGst = 0;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cfaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal ;
          this.Total = this.cfaTotal;
        } else if (this.select_training === "Study Material + Training") {
          // this.cmaStudytotal = 38325;
          this.cfaStudyfee = 39000;
          this.wiley_study_material_cost_without_gst = 525;
          this.wiley_study_material_cost_with_gst = 0;
          this.cfaTrainingfee = 9000;
          this.cfaStudytotal = 49000;
          this.traningGst = 4302;
          this.cfaTrainingtotal =
            this.cfaTrainingfee + this.traningGst - this.cfaTrainingdiscount;
          this.cfaTotal =
            this.cfaStudytotal +
            this.cfaTrainingtotal ;
          this.Total = this.cfaTotal;
        }
      }
    },
    calculatecmatrainediscount() {
      // 9100 is study note cost
      this.cmaTrainingtotal =
        this.cmaTrainingfee + this.traningGst - this.cmaTrainingdiscount;
    },
    ContainsLicence(value) {
      let bool = false;
      value.forEach((single) => {
        if (single.name === "exam_license_assistance") {
          bool = true;
        }
      });
      return bool;
    },
    generateBatch(mode, date) {
      console.log("generateBatch123",mode, date);
      this.StartBatchDate();
      if((this.select2 === "CPA") && this.select3 === "Professional" && this.typeSelection === "Corporate" && this.selectedCorporateOption === "B2B" && mode === "study_material_training"){
       this.batch = "CPA-M7-EE" ;
      }else if(this.select2 === "CMA" && this.select3 === "Professional" && this.typeSelection === "Corporate" && this.selectedCorporateOption === "B2B" && mode === "study_material_training"){
       this.batch = "CMA-M7-EE" ;
      } else{
        const url = `${constants.SERVER_API}getBatchCode`;
        console.log(url);
        let obj = "";
        if(this.select2 !== "CPA-AA" && this.select2 !== "USP") {
          obj = {
            mode : mode,
            batch_start_date : date,
            course: this.select2
          }
        }
        if(this.select2 === "CPA-AA" || this.select2 === "USP") {
          obj = {
            mode : mode,
            batch_start_date : date,
            course: "CPA"
          }
        }
        if(this.select2 === "Bridge Course – Public Accounting") {
          obj = {
            mode : mode,
            batch_start_date : date,
            course: "Bridge Course"
          }
        }
        axios
          .get(url, {
            params: obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            this.batch = response.data;
            console.log("batch response", this.batch);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    calculatetotal() {
      if (this.select2 === "CPA") {
        this.Studytotal = this.Studyfee - this.Studydiscount;
        this.Trainingtotal = this.Trainingfee - this.Trainingdiscount;
        this.ExamTotal = this.ExamFee - this.ExamDiscount;
      } else if (this.select2 === "CMA") {
        this.calculateCMA();
      }
    },
    calculateAICPA(){
      if (this.select2 === "AICPA") {
        this.aicpa_training_total = (this.aicpa_tax + this.aicpa_gst) - this.aicpa_training_fee_discount;
        this.aicpa_total = this.aicpa_training_fee - this.aicpa_training_fee_discount;
      }
    },
    calculateMegaTotal() {
      if (this.select2 === "CPA") {
        this.Totalfee =
          parseInt(this.Studyfee) + parseInt(this.Trainingfee) + parseInt(this.ExamFee);
        this.Totaldiscount =
          parseInt(this.Studydiscount) +
          parseInt(this.Trainingdiscount) +
          parseInt(this.ExamDiscount);
        this.Total =
          parseInt(this.Studytotal) +
          parseInt(this.Trainingtotal) +
          parseInt(this.ExamTotal);
      } else if (this.select2 === "CMA") {
        this.calculateCMA();
      }
        else if (this.select2 === "CFA") {
        this.Totalfee = 39000;
      } 
      if (this.select2 === "CPA-AA") {
        this.Totalfee =
          parseInt(this.Studyfee) + parseInt(this.Trainingfee) + parseInt(this.ExamFee);
        this.Totaldiscount =
          parseInt(this.Studydiscount) +
          parseInt(this.Trainingdiscount) +
          parseInt(this.ExamDiscount);
        this.Total =
          parseInt(this.Studytotal) +
          parseInt(this.Trainingtotal) +
          parseInt(this.ExamTotal);
      } 
    },
    getexam() {
      // console.log(this.subjectarr.length);
      if (this.subjectarr.length === 0) {
        this.ExamFee = 30000;
      } else {
        this.ExamFee = 29500;
      }
    },
    EventForSubmit() {
      if(this.electives_data == true && this.select2 == 'Bridge Course – Public Accounting'){
        this.$vs.notify({
          title: "Bridge course eligibility not found",
          text: "",
          color: "danger",
        });
      }else{
        EventBus.$emit("submittingForm");
      }
    },
    datatoTimestamp(stringyDate) {
      console.log("datatoTimestamp123",stringyDate);
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      if (isNaN(ms)) {
        let str = "";
        return str;
      }
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    submitForm(payload) {
      console.log("state, pincode", payload[8], payload[9]);
      let obj = {
        // selected_cfa_batch: this.selected_cfa_batch,
        identity: payload[0].identity,
        person_id: payload[0].person_id,
        person_name: payload[0].person_name,
        can_id: payload[1],
        city: payload[0].city,
        profile_pic_link: "",
        photo_id_proof_link: "",
        address_proof_link: "",
        reason_for_discount: "",
        address: payload[3],
        shipping_address: payload[7],
        state: payload[8],
        pincode: payload[9],
        // need_for_bridge_course: payload[4],
        course: this.select2,
        frm_selected_part: this.frm_selected_part,
        cfa_selected_level: this.cfa_selected_level,
        category: "",
        type: "",
        mode: "",
        mwb_id: payload[0].id,
        can_email: this.getEmailForCandidate(this.payloadEmails),
        mode_of_study: "",
        subjects_chosen: "",
        actual_cost: this.Totalfee,
        agreed_cost: this.Total,
        enrollment_date: this.datatoTimestamp(this.date),
        batch_start_date: this.datatoTimestamp(this.date2),
        batch: this.batch,
        study_material_cost: 0,
        training_fee_cost: 0,
        exam_license_cost: 0,
        study_material_discount: 0,
        training_fee_discount: 0,
        exam_license_discount: 0,
        loan_id: null,
        loan_status: null,
        loan: this.enableLoan,
        loan_vendor: this.loan_providers,
        tenure: this.number_of_months,
        referral_identity: null,
        referral_person_id: null,
        referral_bool: this.referralData.referral_bool,
        referral_account_payee_name: this.referralData.account_payee_name,
        referral_account_number: this.referralData.account_number,
        referral_bank_name: this.referralData.bank_name,
        referral_bank_branch: this.referralData.bank_branch,
        referral_ifsc_code: this.referralData.ifsc_code,
      };
      if (this.enableLoan === 0) {
        obj.tenure = 0;
        obj.loan_vendor= "";
      }
      if (this.referralData.referral_bool === "yes") {
        // console.log("Inside");
        if (
          this.referralData.identity === "" ||
          this.referralData.identity === null ||
          this.referralData.person_id === null 
          // this.referralData.account_payee_name === null ||
          // this.referralData.account_number === null ||
          // this.referralData.bank_name === null ||
          // this.referralData.bank_branch === null ||
          // this.referralData.ifsc_code === null
        ) {
          // console.log(this.referralData.referral_account_payee_name);
          this.$vs.notify({
            title: `Error`,
            text: "Referral details are required",
            color: "danger",
          });
          return;
        }
        // merges completed with narendra
        // if (
        //   this.referralData.account_payee_name.length < 4 ||
        //   this.referralData.account_number.length < 10
        // ) {
        //   this.$vs.notify({
        //     title: `Error`,
        //     text: " Please enter mandatory referral account details",
        //     color: "danger",
        //   });
        //   return;
        // }
      }
      if (
        this.referralData.referral_bool === "yes" &&
        this.referralData.identity !== "" &&
        this.referralData.identity !== null &&
        this.referralData.person_id === null
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "A valid Can-ID is required while adding a referral",
          color: "danger",
        });
        return;
      } else if (
        (this.referralData.referral_bool === "yes" &&
          this.referralData.identity === "") ||
        this.referralData.identity === null
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "A valid Can-ID is required while adding a referral",
          color: "danger",
        });
        return;
      } else {
        obj.referral_identity = this.referralData.identity;
        obj.referral_person_id = this.referralData.person_id;
      }
      obj.type = this.typeSelection;
      if (this.typeSelection === "Corporate") {
        obj.type = `${this.typeSelection} - ${this.selectedCorporateOption}`;
      }
      if (this.reason) {
        obj.reason_for_discount = `${this.reason} - ${this.reasoncomment}`;
      }
      if (this.select2 === "CPA") {
        obj.wiley_study_material_cost = parseInt(
          this.wiley_study_material_cost_with_gst
        );
        obj.study_material_cost =
          parseInt(this.total_study_material_cost) + parseInt(this.Studydiscount);
        obj.training_fee_cost =
          parseInt(this.total_training_material_cost) + parseInt(this.Trainingdiscount);
        obj.exam_license_cost =
          parseInt(this.total_exam_license_cost) + parseInt(this.ExamDiscount);
        obj.agreed_cost = this.total_enrollment_cost;
        obj.actual_cost =
          this.total_enrollment_cost +
          parseInt(this.Studydiscount) +
          parseInt(this.Trainingdiscount) +
          parseInt(this.ExamDiscount);
           obj.study_material_cost_dollars = null;
        obj.study_material_discount = parseInt(this.Studydiscount);
        obj.training_fee_discount = parseInt(this.Trainingdiscount);
        obj.exam_license_discount = parseInt(this.ExamDiscount);
      } else if (this.select2 === "CMA") {
        obj.wiley_study_material_cost = 0;
        // obj.study_material_cost = parseInt(this.cmaStudytotal) + this.wiley_study_material_cost_with_gst;
        obj.study_material_cost = parseInt(this.cmaStudydiscount) +  parseInt(this.cmaStudytotal);
        obj.study_material_cost_dollars = this.wiley_study_material_cost_without_gst;
        // console.log(this.cmaTrainingfee);
        obj.training_fee_cost = parseInt(this.cmaTrainingtotal)+
          parseInt(this.cmaTrainingdiscount);
        obj.study_material_discount = parseInt(this.cmaStudydiscount);
        obj.training_fee_discount = parseInt(this.cmaTrainingdiscount);
        obj.exam_license_cost =
          parseInt(this.total_cma_exam_license_cost) + parseInt(this.ExamDiscount);
        obj.exam_license_discount = parseInt(this.ExamDiscount);
      } 
      else if (this.select2 === "CFA") {
        if(this.select_training === "Study Material + Training"){
          obj.study_material_cost = 29000;
          obj.training_fee_cost = 10000;
          obj.wiley_study_material_cost = 0;
          if (this.reasoncomment !== ""){
            obj.agreed_cost = this.CFA_discount_total;
          } else {
            obj.agreed_cost = 39000;
          }
          obj.actual_cost = 39000;
          obj.training_fee_discount = this.cfaTrainingdiscount;
        }
        if(this.select_training === "Study Material Only"){
          obj.study_material_cost = 29000;
          obj.training_fee_cost = 0;
          obj.wiley_study_material_cost = 0;
          obj.agreed_cost = 29000;
          obj.actual_cost = 29000;
        }
        if(this.select_training === "Training Only"){
          obj.study_material_cost = 0;
          obj.training_fee_cost = 10000;
          obj.wiley_study_material_cost = 0;
          obj.agreed_cost = 0;
          obj.actual_cost = 0;
        }
        if(obj.cfa_selected_level === "Level 1"){
        this.cfa_subject_levels = this.cfa_level1_subjects;
        this.total_selected_subjects = this.cfa_level1_subjects;
        }
        if(obj.cfa_selected_level === "Level 2"){
          this.cfa_subject_levels = this.cfa_level2_subjects;
          this.total_selected_subjects = this.cfa_level2_subjects;
        }
        if(obj.cfa_selected_level === "Level 3"){
          this.cfa_subject_levels = this.cfa_level3_subjects;
          this.total_selected_subjects = this.cfa_level3_subjects;
        }
      }
      else if (this.select2 === "FRM") {
        if(this.select_training === "Study Material + Training"){
          obj.study_material_cost = 29000;
          obj.training_fee_cost = 10000;
          obj.wiley_study_material_cost = 0;
          // obj.agreed_cost = 39000;
          obj.actual_cost = 39000;
          if (this.reasoncomment !== ""){
            console.log("comment",this.frm_discount_total);
            obj.agreed_cost = this.frm_discount_total;
            obj.training_fee_discount = this.frmTrainingdiscount;
          } else {
            obj.agreed_cost = 39000;
          }
          
        }
        if(this.select_training === "Study Material Only"){
          obj.study_material_cost = 29000;
          obj.training_fee_cost = 0;
          obj.wiley_study_material_cost = 0;
          obj.agreed_cost = 29000;
          obj.actual_cost = 29000;
        }
        if(this.select_training === "Training Only"){
          obj.study_material_cost = 0;
          obj.training_fee_cost = 10000;
          obj.wiley_study_material_cost = 0;
          obj.agreed_cost = 0;
          obj.actual_cost = 0;
        }
        if(obj.frm_selected_part === "Part 1"){
        this.frm_subject_parts = this.frm_part1_subjects;
        this.total_selected_subjects = this.frm_part1_subjects;
        }
        if(obj.frm_selected_part === "Part 2"){
          this.frm_subject_parts = this.frm_part2_subjects;
          this.total_selected_subjects = this.frm_part2_subjects;
        }
      }
      else if (this.select2 === "CPA-AA") {
        obj.wiley_study_material_cost = parseInt(
          this.wiley_study_material_cost_with_gst
        );
        obj.study_material_cost =
          parseInt(this.total_study_material_cost) + parseInt(this.Studydiscount);
        obj.training_fee_cost =
          parseInt(this.total_training_material_cost) + parseInt(this.Trainingdiscount);
        obj.exam_license_cost =
          parseInt(this.total_exam_license_cost) + parseInt(this.ExamDiscount);
        obj.agreed_cost = this.total_enrollment_cost;
        obj.actual_cost =
          this.total_enrollment_cost +
          parseInt(this.Studydiscount) +
          parseInt(this.Trainingdiscount) +
          parseInt(this.ExamDiscount);
           obj.study_material_cost_dollars = null;
        obj.study_material_discount = parseInt(this.Studydiscount);
        obj.training_fee_discount = parseInt(this.Trainingdiscount);
        obj.exam_license_discount = parseInt(this.ExamDiscount);
      } else if (this.select2 === "USP" || this.select2 === "Bridge Course – Public Accounting") {
        obj.wiley_study_material_cost = parseInt(
          this.wiley_study_material_cost_with_gst
        );
        // if(select_training == 'Assistance for US-Placement'){

        // }
        obj.study_material_cost =
          parseInt(this.total_study_material_cost) + parseInt(this.Studydiscount);
        obj.training_fee_cost =
          parseInt(this.total_training_material_cost) + parseInt(this.Trainingdiscount);
        obj.exam_license_cost =
          parseInt(this.total_exam_license_cost) + parseInt(this.ExamDiscount);
        obj.agreed_cost = this.total_enrollment_cost;
        obj.actual_cost =
          this.total_enrollment_cost +
          parseInt(this.Studydiscount) +
          parseInt(this.Trainingdiscount) +
          parseInt(this.ExamDiscount);
           obj.study_material_cost_dollars = null;
        obj.study_material_discount = parseInt(this.Studydiscount);
        obj.training_fee_discount = parseInt(this.Trainingdiscount);
        obj.exam_license_discount = parseInt(this.ExamDiscount);
      }else if (this.select2 === "AICPA"){
        if(this.select_training === "Training Only"){
          obj.study_material_cost = 0;
          obj.actual_cost = 85840;
          obj.training_fee_cost = 85840;
          obj.training_fee_discount = parseInt(this.aicpa_training_fee_discount);
          obj.agreed_cost = obj.training_fee_cost - obj.training_fee_discount;
          if(this.preCourses.length != 0){
            obj.training_fee_discount = 0;
            obj.actual_cost = 38640;
            obj.agreed_cost = 38640;
            obj.training_fee_cost = 38640;
          }
          obj.wiley_study_material_cost = 0;
          // obj.agreed_cost = 0;
          // obj.actual_cost = 0;
        }
      }
      if (this.select_training !== "Study Material + Training" && this.select_training !== "Assistance for US-Placement" && this.select_training != 'Bridge Course – Public Accounting') {
        obj.mode_of_study = "none";
        obj.mode = this.select_training.toLowerCase().split(" ").join("_");
      } else if (this.select_training == "Assistance for US-Placement" && this.select2 == 'USP') {
        obj.mode_of_study = "none";
        obj.mode =  "only_license";
      }else if (this.select_training == "Bridge Course – Public Accounting" && this.select2 == 'Bridge Course – Public Accounting') {
        obj.mode_of_study = "none";
        obj.mode =  "only_license";
      }
      else {
        obj.mode_of_study = this.radios2;
        obj.mode = "study_material_training";
      }
      let newarr = [];
      if(this.select2 != "CFA" && this.select2 != "FRM"){
        this.total_selected_subjects.forEach((subject) => {
          newarr.push(subject.name); 
        });
        this.total_selected_license_fee.forEach((subject) => {
          console.log(subject);
          newarr.push("E&L");
        });
         obj.subjects_chosen = newarr.join();
      }
      
      if(this.select2 === "CFA" || this.select2 === "FRM"){
        newarr = this.total_selected_subjects;
        obj.subjects_chosen = newarr.toString();
      }
      if (this.select2 === "CMA") {
        let newarr = [];
        this.cmaSubjects.forEach((course) => {
          newarr.push(course.name);
        });
        obj.subjects_chosen = newarr.join();
        obj.actual_cost = this.cmaTotal;
        if (this.cmaTrainingdiscount) {
          obj.actual_cost = obj.actual_cost + parseInt(this.cmaTrainingdiscount);
        }
        if(this.cmaStudydiscount > 0){
           obj.actual_cost = obj.actual_cost + parseInt(this.cmaStudydiscount);
        }
        // obj.actual_cost = obj.training_fee_cost;
      }
      obj.category = this.select3;

      if (this.select2 === "CPA") {
        if (this.select_training === "") {
            this.$vs.notify({
            title: "Select Mode details",
            text: "select the mode required details to continue",
            color: "warning",
          });
          return;
        }
      } else if (this.select2 === "CMA") {
        if (this.typeSelection === "") {
          this.$vs.notify({
            title: "Select Category Type details",
            text: "select the category type required details to continue",
            color: "warning",
          });
          return;
        }
        if (this.select_training === "") {
          this.$vs.notify({
            title: "Select Mode details",
            text: "select the mode required details to continue",
            color: "warning",
          });
          return;
        }
      } 
      if (this.reason !== "") {
        if (this.reasoncomment === "") {
          this.$vs.notify({
            title: "Give the reason for discount",
            text: "Give reason for discount in comment section",
            color: "warning",
          });
          return;
        }
      }
      if (obj.reason_for_discount) {
        if (
          obj.study_material_discount == "" &&
          obj.training_fee_discount == "" &&
          obj.exam_license_discount == ""
        ) {
          this.$vs.notify({
            title: "Discount is required if a discount reason is provided",
            text: "Discount reason is provided without a discount amount.",
            color: "warning",
          });
          return;
        }
      }
      if (obj.batch === "") {
        this.$vs.notify({
          title: "Fill the batch details",
          text: "Fill the batch required details to continue",
          color: "warning",
        });
        return;
      }
      console.log("obj data test", obj, this.select_training);
      this.$vs.loading();
      // const url = `https://crm.milesforce.com/api/addEnrollment`;
      const url = `${constants.SERVER_API}addEnrollment`;
      axios
      .post(url, obj, {
      headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      })
      .then((response) => {
      this.$vs.loading.close();
      this.$vs.notify({
      title: response.data.status,
      text: response.data.message,
      color: this.getcolor(response.data.status),
      time:10000
      });
      if (response.data.status === "success") {
      EventBus.$emit("reloadEntireData");
      EventBus.$emit("close-enrollment");
      // this.getBatchDates();
      }
        })
      .catch((error) => {
      this.$vs.loading.close();
      this.handleError(error);
      });
    },
    getcolor(message) {
      switch (message) {
        case "success":
          return "success";
        case "error":
          return "danger";
      }
    },
  },
};
</script>

<style>
.contact_buttons {
  font-size: 50px;
  width: 100px !important;
  height: 100px !important;
  padding-top: 25px;
}
</style>