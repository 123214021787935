<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" style="height: 10%">
        <vs-navbar text-color="black" color="#ffffff" active-text-color="#FFFFFF" class="myNavbar"
          v-model="ActiveNavBarTab">
          <vs-navbar-item index="SCRIPT" class="nav_item">
            <a> Persona Script</a>
          </vs-navbar-item>
          <vs-navbar-item index="ONBOARDING" v-if="onboarding_tab" class="nav_item">
            <a>Onboarding Script</a>
          </vs-navbar-item>
          <vs-navbar-item index="FLOW CHART" class="nav_item">
            <a>Flow Chart</a>
          </vs-navbar-item>
        </vs-navbar>
      </vs-col>
    </vs-row>
    <div v-show="ActiveNavBarTab === 'SCRIPT'" style="width: 100%" class="mt-4">
      <h4 v-if="ActiveNavBarTab === 'SCRIPT'">
        <!-- <script-details v-if="Show_data == true" :personna_details="personna_details"></script-details> -->
        <NewScriptDetails v-if="Show_data == true"  :studentDetails="studentDetails.mwb" :upcoming_visits="studentDetails.upcoming_visits"></NewScriptDetails>
      </h4>
    </div>
    <div v-show="ActiveNavBarTab === 'ONBOARDING'" style="width: 100%" class="mt-4">
      <h4 v-if="ActiveNavBarTab === 'ONBOARDING'">
        <on-boarding-script v-if="Show_data == true" :studentDetails="studentDetails"></on-boarding-script>
      </h4>
    </div>
    <div v-show="ActiveNavBarTab === 'FLOW CHART'" style="width: 100%" class="mt-4">
      <div v-if="ActiveNavBarTab === 'FLOW CHART'" style="display:flex;justify-content:center;align-content:center">
        <img src="../../../assets/images/pages/flow-chart.png" alt="Flow Chart" style="width: -webkit-fill-available;">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import ScriptDetails from "../ScriptDetails.vue";
import OnBoardingScript from "./OnBoardingScript.vue";
import NewScriptDetails from "./NewScriptDetails.vue";
export default {
  props: {
    studentDetails: {
      required: true,
    },
  },
  components: {
    // ScriptDetails,
    OnBoardingScript,
    NewScriptDetails
  },
  mounted() {
    console.log("script-section", this.studentDetails);
    this.getCandidatePersonaDetails();
    if (this.studentDetails.mwb.enrollments.length > 0) {
      this.studentDetails.mwb.enrollments.forEach(data => {
        if (data.course == "CPA" || data.course == "CMA" || data.course == "CFA" || data.course == "FRM" || data.course == "CPA-AA" || data.course == "AICPA" || data.course == "CPA-US") {
          this.onboarding_tab = true;
        }
      })
    }
  },
  data() {
    return {
      ActiveNavBarTab: "SCRIPT",
      personna_details: {},
      Show_data: false,
      onboarding_tab: false,
    };
  },

  methods: {
    getCandidatePersonaDetails() {
      this.personna_details = {};
      this.$vs.loading();
      let mwb_id = this.studentDetails.mwb.id;
      let url = `https://mfcomms.2x2.ninja/api/getCandidatePersonaDetails?mwb_id=${mwb_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.personna_details = response.data;
          console.log(
            "Personna call Data",
            this.personna_details,
            this.personna_details.length
          );
          this.Show_data = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.Show_data = true;
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style></style>