<template>
  <div>
    <vs-row class="gaps" style="margin-top: 2%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
        >Referral</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="6"
        style="font-size: 15px; color: gray"
      >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="referral_bool"
          vs-value="yes"
          >Yes</vs-radio
        >
        <vs-radio color="dark" v-model="referral_bool" vs-value="no"
          >No</vs-radio
        ></vs-col
      >
    </vs-row>
    <div v-if="referral_bool == 'yes'">
      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray;margin-left: -3%;"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>Referral Can-ID</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                icon-no-border
                icon="account_circle"
                placeholder="Can-ID"
                v-model="identity"
            /></vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span style="margin-left: 4%;">Referral Info</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <b id="div-with-loading" class="vs-con-loading__container">{{
                details
              }}</b></vs-col
            >
          </vs-row>
        </vs-col>
      </vs-row>

      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray;margin-left: -3%;"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>Account Payee Name</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                icon-no-border
                icon="account_circle"
                placeholder="Payee Name"
                v-model="account_payee_name"
                :danger="account_name_warning"
                danger-text="Account Payee Name  : Please enter min 4 letters         ** Mandatory"
            /></vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>Account Number</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                class="inputx"
                icon-no-border
                icon="account_circle"
                placeholder="Acc No"
                :danger="account_number_warning"
                danger-text="Account Number : 10 letters (minimum)        ** Mandatory"
                maxlength="30"
                v-model="account_number"
              />
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>

      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray;margin-left: -3%;"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>Bank Name</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                icon-no-border
                icon="account_circle"
                placeholder="Bank Name"
                v-model="bank_name"
              />
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>Bank Branch</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                icon-no-border
                icon="account_circle"
                placeholder="Bank Branch"
                v-model="bank_branch"
              />
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray;margin-left: -3%;"
        >
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
              ><span>IFSC Code</span></vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
              style="font-size: 15px; color: gray"
            >
              <vs-input
                icon-no-border
                icon="account_circle"
                placeholder="IFSC"
                v-model="ifsc_code"
              />
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
          style="font-size: 15px; color: gray"
        >
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import constants from "../../../constants.json";
export default {
  mounted() {
    this.referral_bool = "no";
  },
  data() {
    return {
      identity: "",
      person_id: null,
      details: "No Referral",
      account_payee_name: null,
      account_number: null,
      bank_name: null,
      bank_branch: null,
      ifsc_code: null,
      referral_bool: "no",
      account_number_warning: false,
      account_name_warning: false,
    };
  },
  watch: {
    identity(val) {
      if (val !== "") {
        this.fetchDetails(val, this);
      } else {
        this.details = "No Referral";
        this.emitInfomationEvent();
      }
    },
    account_payee_name(val) {
      if (val.length < 4) {
        this.account_name_warning = true;
        this.emitInfomationEvent();
      } else {
        this.account_name_warning = false;
        this.emitInfomationEvent();
      }
    },
    account_number(val) {
      if (val.length < 10) {
        this.account_number_warning = true;
        this.emitInfomationEvent();
      } else {
        this.account_number_warning = false;
        this.emitInfomationEvent();
      }
    },
    bank_name() {
      this.emitInfomationEvent();
    },
    bank_branch() {
      this.emitInfomationEvent();
    },
    ifsc_code() {
      this.emitInfomationEvent();
    },
    referral_bool() {
      this.refresh();
      this.emitInfomationEvent();
    },
  },
  methods: {
    refresh() {
      this.identity = "";
      this.person_id = null;
      this.account_payee_name = null;
      this.account_number = null;
      this.bank_name = null;
      this.bank_branch = null;
      this.ifsc_code = null;
    },
    fetchDetails: _.debounce((search, vm) => {
      let identity = vm.identity;
      vm.$vs.loading({
        container: "#div-with-loading",
        scale: 0.3,
      });
      let url = `${
        constants.SERVER_API
      }getLeadDetailsWithIdentity?identity=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.length === 0) {
            vm.details = "No candidate with this Can-ID.";
            vm.person_id = null;
          } else if (response.data.status === "success") {
            identity = response.data.lead_details.identity;
            vm.person_id = response.data.lead_details.person_id;
            vm.details = `${response.data.lead_details.name} - ${
              response.data.lead_details.level
            }`;
          }
          vm.emitInfomationEvent(identity);
          vm.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    }, 350),
    emitInfomationEvent(identity) {
      let emitObj = {
        identity: this.identity,
        person_id: this.person_id,
        referral_bool: this.referral_bool,
        account_payee_name: this.account_payee_name,
        account_number: this.account_number,
        bank_name: this.bank_name,
        bank_branch: this.bank_branch,
        ifsc_code: this.ifsc_code,
      };

      if (identity !== undefined) {
        emitObj.identity = identity;
      }

      this.$emit("searchedData", emitObj);
    },
  },
};
</script>

<style></style>
