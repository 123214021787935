<template>
    <div>
        <vs-card style="background: #f1f1f1; border-radius: 15px">
            <vs-row>
                <vs-col vs-w="10" vs-type="flex" vs-justify="center" vs-align="center">
                    <p class="header">STUDENT JOURNEY</p>

                </vs-col>
                <vs-col vs-w="2" vs-type="flex" vs-justify="flex-end" vs-align="center">
                    <vs-button radius color="warning" type="border" icon="chat" @click="editPOA()"></vs-button>

                </vs-col>
            </vs-row>
            <vs-divider />
            <p v-if="loading" style="display:flex; justify-content:center">Loading...</p>
            <p v-if="not_found" style="display:flex; justify-content:center">Attendance details not found</p>
            <!-- <vs-row class="gap" v-if="!loading">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                    <p class="heading">POA</p>
                </vs-col>
                
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <vs-button radius color="warning" type="border" icon="chat" @click="editPOA()"></vs-button>
                </vs-col>
            </vs-row> -->
            <vs-row v-if="course == 'CPA'">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"
                    v-for="(subject,index) in subject_details" :key="index">
                    <subject-card :subject_details="subject" @open-popup="openSubjectPopup"
                        :webinar_attended="webinar_attended"></subject-card>
                </vs-col>
            </vs-row>
            <vs-row v-if="course == 'CMA'">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"
                    v-for="(subject,index) in subject_details" :key="index">
                    <cma-subject-card :subject_details="subject" @open-popup="openSubjectPopup"
                        :webinar_attended="webinar_attended"></cma-subject-card>
                </vs-col>
            </vs-row>
        </vs-card>
        <vs-popup class="holamundo" title="" :active.sync="popup">
            <div class="popup-header">
                <p
                    style="font-family: 'Montserrat'; font-style: normal; font-weight: 700; font-size: 26px; text-align: center; color: #FFFFFF;">
                    Student Attendance </p>
            </div><br>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">Name :</p>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">{{candidate_name}}</p>
                </vs-col>
            </vs-row><br>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">Candidate ID :</p>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">{{candidate_id}}</p>
                </vs-col>
            </vs-row><br>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">Subject :</p>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <p class="popup-text">{{subject}} </p>
                </vs-col>
            </vs-row><br>
            <vs-card style="background: #F1F1F1; border-radius: 15px;">
                <p class="popup-text mb-2">Webinar Attended</p>
                <vs-row class="mb-2" v-for="(value,key,index) in total_webinars" :key="index">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                        <p class="attendance_details mb-2">Year {{key}}</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                        <p class="attendance_details mb-2">{{value}}</p>
                    </vs-col>
                </vs-row>

            </vs-card>
        </vs-popup>
        <POAEdit v-on:poa-updated="udatePOA" />
        <CMAPOAEdit v-on:poa-updated="udatePOACMA" />

    </div>
</template>

<script>
import ApiServices from "../../../../../ApiServices";
import SubjectCard from "./SubjectCard.vue";
import CmaSubjectCard from "./CMASubjectCard.vue";
import POAEdit from "../../../../pagesComponents/POAEdit";
import EventBus from "../../../../eventbus";
import CMAPOAEdit from "../../../../pagesComponents/CMAPOAEdit.vue";



export default {
    components: { SubjectCard, CmaSubjectCard, POAEdit, CMAPOAEdit },
    props: ["student_details", "course"],
    data() {
        return {
            popup: false,
            study_planner_details: [],
            subject_details: [],
            subjects: {
                "101": "AUD",
                "102": "BEC",
                "103": "FAR",
                "104": "REG",
                "105": "Part 1",
                "106": "Part 2",
            },
            webinar_details: {},
            total_webinars: {},
            webinar_attended: [],
            mwb_id: null,
            // course: "",
            candidate_name: "",
            candidate_id: null,
            subject: "",
            not_found: false,
            loading: true,

        };
    },
    mounted() {
        console.log("student_details in poa", this.student_details)
        this.mwb_id = this.student_details.mwb.id;
        // this.course = this.student_details.mwb.courses;
        this.candidate_name = this.student_details.mwb.person_name;
        this.candidate_id = this.student_details.mwb.identity
        this.getAttendance();

        this.getStudyPlannerDetails();
        this.getWebinarDetails();
        // this.getOtbAttempts();
        // this.getExamDetails();

    },
    methods: {
        editPOA() {
            if (this.course == 'CPA') {
                EventBus.$emit("open-edit-POA", this.student_details);
            } else if (this.course == 'CMA') {
                EventBus.$emit("open-edit-cma-POA", this.student_details);
            }
        },
        udatePOA(cpa) {
            if (this.course == 'CPA') {
                this.student_details.cpa = cpa;
            }
        },
        udatePOACMA(cma) {
            if (this.course == 'CMA') {
                this.student_details.cma = cma;
            }
        },
        getStudyPlannerDetails() {
            // this.$vs.loading();
            if (this.course == "CPA") {
                ApiServices.getCPAStudyPlannerDetails(this.mwb_id)
                    .then((response) => {
                        console.log("study planner details", response.data);
                        if (response.data.status == true) {
                            this.study_planner_details = response.data.data;
                            this.subject_details = this.study_planner_details.subject_details
                            this.not_found = false
                            this.loading = false
                        } else {
                            this.not_found = true
                            this.loading = false

                        }
                        if (!response.data.hasOwnProperty("status")) {
                            this.not_found = true
                            this.loading = false
                        }
                        // this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.loading = false
                        this.not_found = true
                        console.log("error--", error)
                        this.$vs.notify({
                            title: `Error`,
                            text: error.response.data.message,
                            color: "danger",
                        });


                        // this.$vs.loading.close();
                    });
            }

            if (this.course == "CMA") {
                ApiServices.getCMAStudyPlannerDetails(this.mwb_id)
                    .then((response) => {
                        console.log("study planner details", response.data);
                        if (response.data.status == true) {
                            this.study_planner_details = response.data.data;
                            this.subject_details = this.study_planner_details
                            this.loading = false

                        }
                        // this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$vs.notify({
                            title: `Error`,
                            text: error.response.data.message,
                            color: "danger",
                        });
                        // this.$vs.loading.close();
                    });
            }

        },
        getWebinarDetails() {
            // this.$vs.loading();
            ApiServices.getWebinarDetails()
                .then((response) => {
                    console.log("webinar details", response.data);
                    this.webinar_details = response.data;

                })
                .catch((error) => {
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                    // this.$vs.loading.close();
                });
        },
        getOtbAttempts() {
            // this.$vs.loading();
            ApiServices.getOtbAttempts(this.mwb_id)
                .then((response) => {
                    console.log("otb attempts details", response.data);

                })
                .catch((error) => {
                    // this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
        },
        getExamDetails() {
            // this.$vs.loading();
            ApiServices.getExamDetails(this.mwb_id)
                .then((response) => {
                    console.log("exam details", response.data);

                })
                .catch((error) => {
                    // this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
        },
        getAttendance() {
            // this.$vs.loading();
            ApiServices.getAttendance(this.course, this.mwb_id)
                .then((response) => {
                    console.log("attendance", response.data);
                    if (response.data.attended != undefined && response.data.attended.length > 0) {
                        this.webinar_attended = response.data.attended;

                    }
                })
                .catch((error) => {
                    // this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error,
                        color: "danger",
                    });
                });
        },
        openSubjectPopup(subject_id) {
            this.popup = true
            console.log("subject_id in parent", subject_id);
            this.getTotalWebinars(subject_id);
        },
        getTotalWebinars(subject_id) {
            console.log("subject_id in parent", subject_id);
            this.subject = this.subjects[subject_id];

            this.total_webinars = {};
            for (let key in this.webinar_details) {
                let year = key.split('_');
                console.log("year", year[0])
                let year_string = year[0]

                this.total_webinars[year_string] = this.getAttendedWebinarCount(subject_id, year_string) + "/" + this.webinar_details[key][this.course][this.subject]
                // this.total_webinars[year_string] = this.getAttendedWebinarCount(subject_id, year_string)


            }
            console.log("total_webinars", this.total_webinars)
        },
        getAttendedWebinarCount(subject_id, year_string) {
            let webinar_attended_count = 0;
            if (this.webinar_attended.length > 0) {
                for (let i = 0; i < this.webinar_attended.length; i++) {
                    let date = new Date(this.webinar_attended[i].webinar_date)
                    let year = date.getFullYear();
                    if (this.webinar_attended[i].subject_id == subject_id && year == year_string) {
                        webinar_attended_count++;
                    }

                }
            }
            return webinar_attended_count;
        },
    },
};
</script>
<style>
.header {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #000000;
    line-height: 17px;
}

.popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.attendance_details {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
</style>