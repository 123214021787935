<template>
  <div class="personna-hide-container mb-5">
    <div class="m-6">
       <vs-row class="mt-5" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <vs-col
          class="details_hide_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2.9"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Pathway Profiling</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="8.7">
          <span>
            <vs-icon color="#000000" icon="lock"></vs-icon>
          </span>
        </vs-col>
      </vs-row>
      <div
        class="persona_hide_details_contant"
        style="display: flex; min-height: 105.45px"
      >
        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="my-4">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
            <div style="width: 100%">
              <p class="input_heading">Are you interested working in the US for 3 years?</p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
                class="input_contant"
              >
                <p class="input_details">{{ select_pathway_value }}</p>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.3"
            style="padding: 0px"
          >
          <div style="width: 100%;">
            <p class="input_heading">Are you looking at job change in India right now ?</p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
                class="input_contant"
              >
                <p class="input_details">{{ selected_placement_assistance }}</p>
              </div>
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.2"
            style="padding: 0px"
            class="mt-3"
          >
          <div style="width: 100%">
                <p class="input_heading">Graduation Year</p>
                <div
                  style="
                    display: flex;
                    align-items: center;
                  "
                  class="input_contant"
                >
                  <p class="input_details" style="line-break: anywhere;">{{ select_graduation_year }}</p>
                </div>
          </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="5.7"
            vs-offset="0.3"
            style="padding: 0px"
            class="mt-3"
          >
          <div style="width: 100%">
              <p class="input_heading">Current Location</p>
              <div
                style="display: flex; align-items: center"
                class="input_contant"
              >
                <p class="input_details" style="line-break: anywhere;">{{ current_location }}</p>
              </div>
          </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="mt-3">
        <vs-col
          class="details_hide_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Work Ex Profiling</p>
        </vs-col>
        
      </vs-row>

      <div
        class="persona_hide_details_contant"
        style="display: flex; min-height: 105.45px"
      >
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" class="my-4">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.4"
            vs-offset="0.2"
            style="padding: 0px"
          >
            <div style="width: 100%">
              <p class="input_heading">Work Ex Profiling</p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
                class="input_contant"
              >
                <p class="input_details">{{ selected_persona }}</p>
                <p>
                  <vs-icon
                    color="rgba(0, 0, 0, .25)"
                    class="mr-2"
                    icon="expand_more"
                  ></vs-icon>
                </p>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
            <div style="width: 100%">
              <p class="input_heading">Company Name</p>
              <div
                style="display: flex; align-items: center"
                class="input_contant"
              >
                <p class="input_details">{{ company }}</p>
              </div>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px 5px 0px 0px"
          >
            <div style="width: 100%">
              <p class="input_heading">No of Years of Work Experience</p>
              <div
                style="display: flex; align-items: center"
                class="input_contant"
              >
                <p class="input_details">{{ experience }}</p>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="mt-5">
        <vs-col
          class="details_hide_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4.8"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Professional Qualification Profiling</p>
        </vs-col>
      </vs-row>
      <div class="persona_hide_details_contant" style="min-height: 105.45px">
          <vs-row vs-type="flex" vs-justify="center" vs-align="center" class="my-4">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="5.7"
              vs-offset="0.2"
              style="padding: 0px"
            >
              <div style="width: 100%">
                <p class="input_heading">Indian Professional Qualification</p>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                  class="input_contant"
                >
                  <p class="input_details">{{ selected_indian_prof }}</p>
                </div>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="5.7"
              vs-offset="0.2"
              style="padding: 0px"
            >
              <div style="width: 100%">
                <p class="input_heading">Global Professional Qualification</p>
                <div
                  style="display: flex; align-items: center"
                  class="input_contant"
                >
                  <p class="input_details">{{ selected_global_prof }}</p>
                </div>
              </div>
            </vs-col>
          </vs-row>
      </div>

     
      <vs-row class="mt-3">
        <vs-col
          class="details_hide_heading"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2.5"
          vs-offset="0.3"
        >
          <p class="details_sub_heading">Others</p>
        </vs-col>
      </vs-row>
      <div
        class="persona_hide_details_contant"
      >
        <vs-row class="my-4">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
            <div style="width: 100%">
                <p class="input_heading">Under Graduate Qualification</p>
                <div
                  style="
                    display: flex;
                    align-items: center;
                  "
                  class="input_contant"
                >
                  <p class="input_details" style="line-break: anywhere;">{{ selected_ug }}</p>
                </div>
              </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="3.7"
            vs-offset="0.2"
            style="padding: 0px"
          >
             <div style="width: 100%">
                <p class="input_heading">Post Graduate Qualification</p>
                <div
                  style="display: flex; align-items: center"
                  class="input_contant"
                >
                  <p class="input_details" style="line-break: anywhere;">{{ selected_pg }}</p>
                </div>
              </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Treeselect from "@riophae/vue-treeselect";
export default {
  props: ["persona_details"],
  components: {
    "vue-google-autocomplete": VueGoogleAutocomplete,
    "v-select": vSelect,
    Treeselect,
  },
  mounted() {
    // this.getCandidatePersonaDetails();
    // console.log("personaaaaa",this.persona_details)
  },
  data() {
    return {
      selected_placement_assistance: "",
      persona_option: [
        "No work ex - College Student",
        "No work ex - Graduate",
        "Working Professional",
        "Prior work-ex - but currently not working",
      ],
      company: null,
      current_location: null,
      select_pathway_value: "",
      selected_indian_prof: null,
      selected_global_prof: null,
      selected_persona: "",
      selected_pg: null,
      selected_ug: null,
      select_graduation_year: null,
      experience: "",
    };
  },
  methods: {
    getCandidatePersonaDetails() {
      this.selected_ug = null;
      this.selected_pg = null;
      this.select_graduation_year = null;
      // this.persona_details = response.data;
      this.current_location = "";
      this.company = "";
      this.select_pathway_value = "";
      this.selected_indian_prof = null;
      this.selected_global_prof = null;
      this.selected_persona = "";
      this.company = "";
      this.experience = ""  ;

      if (Object.keys(this.persona_details).length > 3) {
        if (this.persona_details.ug_qualification != "NA") {
          this.selected_ug = this.persona_details.ug_qualification;
        }

        if (this.persona_details.pg_qualification != "NA") {
          this.selected_pg = this.persona_details.pg_qualification;
        }

        if (this.persona_details.years_of_experience != "NA" || this.persona_details.years_of_experience != null ) {
          this.experience = this.persona_details.years_of_experience;
        }

        if (this.persona_details.pathway != "NA") {
          this.select_pathway_value = this.persona_details.pathway_value;
        }

        if (this.persona_details.pathway != "NA") {
          this.select_pathway_value = this.persona_details.pathway_value;
        }

        if (this.persona_details.placement_assistance != "NA") {
         this.selected_placement_assistance = this.persona_details.placement_assistance;
        }

        if (this.persona_details.indian_professional_qualification != "NA") {
          this.selected_indian_prof =
            this.persona_details.indian_professional_qualification;
        }

        if (this.persona_details.global_professional_qualification != "NA") {
          this.selected_global_prof =
            this.persona_details.global_professional_qualification;
        }

        if (
          this.persona_details.persona != "NA" &&
          this.persona_details.persona != "NA (Not Available) Non-define Data"
        ) {
          this.selected_persona = this.persona_details.persona;
        }

        if (this.persona_details.company != "NA") {
          this.company = this.persona_details.company;
        }

        this.current_location = this.persona_details.location;
        if (this.persona_data.persona_details != "NA" || this.persona_data.persona_details != null) {
          this.select_graduation_year = this.persona_data.persona_details
        }
      } else {
        if (this.persona_details.company != "NA") {
          this.company = this.persona_details.company;
        }

        this.current_location = this.persona_details.location;
        this.experience = this.persona_details.years_of_experience;
        if (this.persona_details.graduation_year == "NA" || this.persona_details.graduation_year == null || this.persona_details.graduation_year == "") {
          this.select_graduation_year = null;
        } else {
          this.select_graduation_year = this.persona_details.graduation_year
        }
      }
    },
  },
};
</script>

<style>
.input_details {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-inline-start: 2%;
  color: rgb(0, 0, 0);
}
.input_heading {
  font-family: "Montserrat";
  font-style: normal;
  margin-inline-start: 2%;
  font-weight: 600;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
}
.personna-hide-container {
  background: #f0f0f0;
  border: 1px solid #94989b;
  border-radius: 15px;
  margin-left: 5px;
}
.input_contant {
  min-height: 38px;
  padding: 3px 0px 3px 0px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.persona_hide_details_contant {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}
.details_hide_heading {
  min-height: 35.93px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0px 0px;
}
</style>