<template>
  <vs-popup
    v-if="open"
    class="popupwidth"
    style="z-index: 53001"
    :title="
      this.candidate_details.identity +
      ' - ' +
      this.candidate_details.person_name
    "
    :active.sync="open"
  >
    <vs-row v-if="open">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <vs-card class="cardConf">
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Can-ID</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <vs-input
                size="medium"
                class="w-full"
                v-model="selectedcity"
                disabled
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <vs-input
                size="medium"
                class="w-full"
                v-model="select2"
                disabled
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <vs-input
                size="medium"
                v-model="candidateId"
                class="w-full"
                disabled
              />
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >City: -</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <b style="font-size: 15px; color: gray">{{ city }}</b>
            </vs-col>
          </vs-row>
          <!-- <vs-row class="gap" v-if="render">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-w="4"
              vs-align="center"
            >
              <vs-upload
                :limit="1"
                text="Profile Pic"
                action="http://13.232.242.239:5055/fileupload/"
                @on-error="errorUpload"
                @change="changeUpload"
                @on-success="successUpload"
                :data="attachmentKey"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-w="4"
              vs-align="center"
            >
              <vs-upload
                :limit="1"
                text="Address Proof"
                action="http://13.232.242.239:5055/fileupload/"
                @on-error="errorUpload"
                @change="changeUpload"
                @on-success="successUpload"
                :data="attachmentKeyAddress"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-w="4"
              vs-align="center"
            >
              <vs-upload
                :limit="1"
                text="Identity Proof"
                action="http://13.232.242.239:5055/fileupload/"
                @on-error="errorUpload"
                @change="changeUpload"
                @on-success="successUpload"
                :data="attachmentKeyIdentity"
              />
            </vs-col>
          </vs-row>-->
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Mobile:</vs-col
            >
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <div
          v-for="(mobile, index) in studentDetails.mobile"
          :key="index"
          style="margin-left: 1%"
          class="tooltip"
        >
          <span class="tooltiptext">
            <b>{{mobile.masked_number}}</b>
          </span>
          <vs-button
            color="warning"
            type="gradient"
            icon="local_phone"
            @click="mobilePopup(mobile)"
          >
          </vs-button>
        </div>
            </vs-col>-->
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="5"
            >
              <!-- <vs-input class="w-full" v-model="mobileview" /> -->
              <b style="font-size: 15px; color: gray">{{ mobileview }}</b>
              <!-- <b style="font-size: 15px; color: gray">{{ mobileview }}</b> -->
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="3"
            >
              <!-- <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="local_phone"
          @click="EmitEvent('add-mobile-number', studentDetails)"
              >ADD</vs-button>-->
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Email:</vs-col
            >
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <div
          v-for="(email, indexemail) in studentDetails.emails"
          :key="indexemail"
          style="margin-left: 1%"
          class="tooltip"
        >
          <span class="tooltiptext">
            <b>{{email.masked_email}}</b>
          </span>
          <vs-button
            style="margin-left: 1%"
            color="warning"
            type="gradient"
            icon="email"
            @click="emailPopup(email)"
          ></vs-button>
        </div>
            </vs-col>-->
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="5"
            >
              <!-- <vs-input class="w-full" v-model="emailview" /> -->
              <b style="font-size: 15px; color: gray">{{ emailview }}</b>
              <!-- <b style="font-size: 15px; color: gray">{{ emailview }}</b> -->
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="3"
            >
              <!-- <vs-button
          style="margin-left: 1%"
          color="warning"
          type="border"
          icon="email"
          @click="EmitEvent('add-email')"
              >ADD</vs-button>-->
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
              >Address:</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            ></vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
            >
              <vs-textarea v-model="address" />
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Company:</vs-col
            >
            <!-- <edit-save :studentDetails="studentDetails"/> -->
            <vs-col
              v-if="companyEdit === false"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <b>{{ company }}</b>
            </vs-col>
            <vs-col
              v-else-if="companyEdit === true"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <vs-input icon="edit" v-model="company" />
            </vs-col>
            <vs-col
              v-if="companyEditbutton === true"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <!-- <vs-button radius color="warning" type="border" icon="create" @click="companyeditfunc"></vs-button> -->
            </vs-col>
            <vs-col
              v-if="companyEditbutton === false"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <vs-button
                radius
                color="warning"
                type="border"
                icon="done_outline"
                @click="companyeditfunc"
              ></vs-button>
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Experience:</vs-col
            >
            <vs-col
              v-if="exEdit === false"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <b>{{ experience }}</b>
            </vs-col>
            <vs-col
              v-else-if="exEdit === true"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <vs-input icon="edit" v-model="experience" />
            </vs-col>
            <vs-col
              v-if="exEditbutton === true"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <!-- <vs-button radius color="warning" type="border" icon="create" @click="exeditfunc"></vs-button> -->
            </vs-col>
            <vs-col
              v-if="exEditbutton === false"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <!-- <vs-button radius color="warning" type="border" icon="done_outline" @click="exeditfunc"></vs-button> -->
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
              style="font-size: 15px; color: gray"
              >Designation:</vs-col
            >
            <vs-col
              v-if="designationEdit === false"
              vs-type="flex"
              vs-justify="flex-start"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <b>{{ designation }}</b>
            </vs-col>
            <vs-col
              v-else-if="designationEdit === true"
              vs-type="flex"
              vs-justify="flex-start"
              vs-w="8"
              style="font-size: 15px; color: gray"
            >
              <vs-input icon="edit" v-model="designation" />
            </vs-col>
            <vs-col
              v-if="designationEditbutton === true"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <!-- <vs-button radius color="warning" type="border" icon="create" @click="designationeditfunc"></vs-button> -->
            </vs-col>
            <vs-col
              v-if="designationEditbutton === false"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="2"
              style="font-size: 15px; color: gray"
            >
              <vs-button
                radius
                color="warning"
                type="border"
                icon="done_outline"
                @click="designationeditfunc"
              ></vs-button>
            </vs-col>
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              <p style="color: gray; font-size: 15px">Educational Tags:</p>
            </vs-col>
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
        <vs-select class="selectExample" v-model="select1">
          <vs-select-item :key="index" :text="item.text" v-for="(item,index) in options1" />
        </vs-select>
            </vs-col>-->
          </vs-row>
          <vs-row class="gap">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="12"
            >
              <vs-chip
                @click="remove(chip)"
                color="primary"
                v-for="chip in select1"
                :key="chip.index"
              >
                <b>{{ chip }}</b>
              </vs-chip>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="6"
      >
        <!-- <vs-card> -->
        <!-- <div class="vx-row"> -->
        <!-- <div class="vx-col w-full mb-base"> -->
        <vx-card class="cardConf" title="Enrollment Details">
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Course</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <v-select
                id="courseselect"
                class="w-full"
                placeholder="Course"
                v-model="select2"
                :options="IIML_Course"
                style="height: 30px"
              ></v-select>
            </vs-col>
          </vs-row>
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Discount</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <v-select
                id="discountselect"
                class="w-full"
                placeholder="Discount"
                v-model="select3"
                :options="discount"
                style="height: 30px"
              ></v-select>
            </vs-col>
          </vs-row>
          <vs-row v-if="select2 == 'IIML-SF' && base_fee != ''" class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Base Fee:</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <b>{{base_fee}}</b>&nbsp;Rs/-&nbsp;(Incl. GST)
              <!-- {{iimlsf_base_fee}} -->

            </vs-col>
          </vs-row>
          <vs-row  v-if="select2 == 'IIML-SF'" class="gaps">
            <vs-col
              v-if="immersion_fee != ''&& select2 == 'IIML-SF'"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Immersion Fee:</span>
            </vs-col>
            <vs-col
              v-if="immersion_fee != '' && select2 == 'IIML-SF'"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <b>{{immersion_fee}}</b>&nbsp;Rs/-&nbsp;(Incl. GST)

            </vs-col>
          </vs-row>
            <!-- <vs-row  v-if="select2 == 'IIML-SF'" class="gaps">
            <vs-col
              v-if="alumni_fee != '' && select2 == 'IIML-SF'"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Alumni Fee:</span>
            </vs-col>
            <vs-col
              v-if="alumni_fee != '' && select2 == 'IIML-SF'"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-checkbox v-model="including_alumni_fee"><b>{{alumni_fee}}</b>Rs/-(Incl. GST)</vs-checkbox>
              <b>{{alumni_fee}}</b>&nbsp;Rs/-&nbsp;(Incl. GST)
              {{iimlsf_base_fee}} 

            </vs-col>
          </vs-row>-->
          <!-- <vs-row class="gaps" v-if="this.select2 === 'IITM-AA' || this.select2 === 'IITR-CC'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Discount</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-input
                id="discountselect"
                class="w-full"
                placeholder=""
                v-model="select3"
                style="height: 30px"
                disabled
              ></vs-input>
            </vs-col>
          </vs-row> -->
          <!-- <vs-row class="gaps" v-if="this.select2 === 'IITM-AA'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Fee Type</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <v-select
                id="discountselect"
                class="w-full"
                placeholder="Fee Type"
                v-model="selectedfeeType"
                :options="feeType"
                style="height: 30px"
              ></v-select>
            </vs-col>
          </vs-row>
          <vs-row class="gaps" v-if="this.select2 === 'IITM-AA'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Discount</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <v-select
                id="discountselect"
                class="w-full"
                placeholder="Discount"
                v-model="select3"
                :options="discount"
                style="height: 30px"
              ></v-select>
            </vs-col>
          </vs-row> -->
           <vs-row class="gaps" v-if="this.select3 === 'Custom'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Custom Discount</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-input
                id="feesselect"
                class="w-full"
                placeholder="Custom Discount"
                v-model="custom_discount"
                style="height: 15px"
              ></vs-input>
            </vs-col>
          </vs-row>
           <vs-row class="gaps" v-if="this.select3 === 'Custom'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Reason</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-input
                id="feesselect"
                class="w-full"
                placeholder="Reason for custom discount"
                v-model="reason_for_custom_discount"
                style="height: 15px"
              ></vs-input>
            </vs-col>
          </vs-row>
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Fees</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-input
                id="feesselect"
                class="w-full"
                placeholder="Fees"
                v-model="select4"
                style="height: 15px"
                disabled
              ></vs-input>
            </vs-col>
          </vs-row>
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>
                Place Of Supply*
                <small>For GST Purpose</small>
              </span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <v-select
                id="placeselect"
                class="w-full"
                placeholder="Places"
                v-model="PlaceSelect"
                :options="places"
                style="height: 30px"
              ></v-select>
            </vs-col>
          </vs-row>
          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Loan</span>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="loanType"
                vs-value="1"
                >Yes</vs-radio
              >
              <vs-radio color="dark" v-model="loanType" vs-value="0"
                >No</vs-radio
              >
              <!-- <vs-button
                v-if="loanType === 'yes'"
                style="margin-left: 3%;"
                size="small"
                class="float-center"
                color="primary"
                type="gradient"
                >Send Loan Application Link</vs-button
              >-->
            </vs-col>
          </vs-row>
          <!-- <vs-row class="gaps" v-if="loanType !== '1'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
              v-if="this.select2 === 'IITM-AA' || this.select2 === 'IITR-CC'"
            >
              <span>Non Loan</span>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
              v-if="this.select2 === 'IITM-AA' || this.select2 === 'IITR-CC'"
            >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="nonloanType"
                vs-value="1"
                >Yes</vs-radio
              >
            </vs-col>
          </vs-row> -->
          <vs-row class="gaps" v-if="loanType === '1'">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Installments</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                vs-value="6"
                >6 Months</vs-radio
              >
              <vs-radio
                v-if="this.select2 !== 'IITM-AA' && this.select2 !== 'IITR-CC'"
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                vs-value="9"
                >9 Months</vs-radio
              >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                vs-value="12"
                >12 Months</vs-radio
              >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                vs-value="24"
                >24 Months</vs-radio
              >
            </vs-col>
          </vs-row>
          <!-- <div class="vx-row mb-6" v-if="loanType === 'yes'">
            <div class="vx-col sm:w-1/6">
              <span>Installments</span>
            </div>
            <div class="vx-col lg:w-2/6" style="margin-left: 3%;">
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="enableLoan"
                vs-value="3"
                >3 Months</vs-radio
              >
              <vs-radio color="dark" v-model="enableLoan" vs-value="6"
                >6 Months</vs-radio
              >
              <vs-radio
                style="margin-left: 3%;"
                color="dark"
                v-model="enableLoan"
                vs-value="9"
                >9 Months</vs-radio
              >
              <vs-button
                style="margin-left: 7%;"
                size="small"
                class="float-center"
                color="primary"
                type="gradient"
                @click="SendLoanApplication()"
                >Send Loan Application Link</vs-button
              >
            </div>
          </div>-->
          <vs-row class="gaps">
            <vs-col>
              <referral-component
                @searchedData="assignReferral"
              ></referral-component>
            </vs-col>
          </vs-row>

          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <span>Batch</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="10"
            >
              <vs-input disabled v-model="batch" />
              <v-select
                id="placeselect"
                class="w-full"
                placeholder="Select Batch Number"
                v-model="number"
                :options="batch_number"
              ></v-select>
            </vs-col>
          </vs-row>

          <vs-row class="gaps">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            ></vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="5"
            ></vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <vs-button
                class="float-center"
                color="primary"
                type="gradient"
                @click="EventForSubmit()"
                >Submit</vs-button
              >
            </vs-col>
          </vs-row>
        </vx-card>
        <!-- </div> -->
        <!-- </div> -->
        <!-- </vs-card> -->
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import referralComponent from "../../components/enrollments/referral.vue";
export default {
  components: {
    vSelect,
    Datepicker,
    "referral-component": referralComponent,
  },
  watch: {
    including_alumni_fee(val){
      if(val == true){
       this.select4 = this.iiml_sf[this.select3];
      }
      if(val == false){
       this.select4 = this.feesStructure['IIML-SF'][this.select3];
      }
      // console.log("including_alumni_fee 1",val);
      // if(val == true){
      //   console.log("including_alumni_fee 5",val);
      //   if(this.select3 == 'None' && this.select2 == 'IIML-SF'){
      //     this.select4 = 336300;
      //     console.log("including_alumni_fee 2",this.select4);
      //   }
      //   if(this.select3 == '5%' && this.select2 == 'IIML-SF'){
      //     this.select4 = 320783;
      //     console.log("including_alumni_fee 3",this.select4);
      //   }
      //   if(this.select3 == '10%' && this.select2 == 'IIML-SF'){
      //     this.select4 = 305266;
      //     console.log("including_alumni_fee 4",this.select4);
      //   }
      // }
      // if(val == false){
      //   if(this.select3 == 'None' && this.select2 == 'IIML-SF'){
      //     this.select4 = 324500;
      //     console.log("including_alumni_fee 2",this.select4);
      //   }
      //   if(this.select3 == '5%' && this.select2 == 'IIML-SF'){
      //     this.select4 = 308983;
      //     console.log("including_alumni_fee 3",this.select4);
      //   }
      //   if(this.select3 == '10%' && this.select2 == 'IIML-SF'){
      //     this.select4 = 293466;
      //     console.log("including_alumni_fee 4",this.select4);
      //   }
      // }
    },
    loanType(value) {
      console.log(value);
      this.nonloanType = "";
      this.enableLoan = "";
      // if(this.select2 === "IITM-AA") {
      //   if(value === "0") {
      //     this.select4 = 141600;
      //     this.select3 = 0;
      //   }
      // }
      // if(this.select2 === "IITR-CC") {
      //   if(value === "0") {
      //     this.select4 = 141600;
      //     this.select3 = 0;
      //   }
      // }
    },
    enableLoan(value) {
      console.log(value);
      // if(this.select2 === "IITM-AA") {
      //   if(value === "6") {
      //     this.select4 = 118000;
      //     this.select3 = 20000;
      //   }
      //   if(value === "12") {
      //     this.select4 = 123900;
      //     this.select3 = 15000;
      //   }
      //   if(value === "24") {
      //     this.select4 = 134520;
      //     this.select3 = 6000;
      //   }

      // }
      // if(this.select2 === "IITR-CC") {
      //   if(value === "6") {
      //     this.select4 = 118000;
      //     this.select3 = 20000;
      //   }
      //   if(value === "12") {
      //     this.select4 = 123900;
      //     this.select3 = 15000;
      //   }
      //   if(value === "24") {
      //     this.select4 = 134520;
      //     this.select3 = 6000;
      //   }
      // }
    },
    // nonloanType(value) {
    //   if(this.select2 === "IITM-AA") {
    //     if(value === "1") {
    //       this.select4 = 116820;
    //       this.select3 = 21000;
    //     }
    //   }
    //   if(this.select2 === "IITR-CC") {
    //     if(value === "1") {
    //       this.select4 = 116820;
    //       this.select3 = 21000;
    //     }
    //   }
    // },
    cities_selected: function (value) {
      // console.log(value);
      this.titlebatch = value.value;
    },
    reason(value) {
      switch (value) {
        case "Custom":
          this.customComment = true;
          break;
        case "Alumini Discount":
          this.customComment = false;
          break;
        case "CA Discount":
          this.customComment = false;
          break;
        case "Corporate Discount":
          this.customComment = false;
          break;
        case "CA":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CS":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CWA":
          this.Trainingdiscount = 22715;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CA Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CS Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
        case "CWA Inter":
          this.Trainingdiscount = 11151;
          this.reasoncomment = `${value} Discount`;
          break;
      }
    },
    // discount: ["5%", "None"],
    select2(val) {
      this.loanType = "0";
      this.enableLoan = "";
      this.select4 = "";
      this.select3 = "";
      this.discount = [];

      if (val === "IIMK-CX") {
        // this.select4 = this.feesStructure[val]["Fee"];
        this.discount.push("5%", "None");
        this.batch = "IIMK-CX-M7-";
      } else if (val === "IIML-FA") {
        this.discount.push("5%", "None");
        this.batch = "IIML-FA-M7-";
      } else if (val === "IIML-BA") {
        this.discount.push("5%", "None");
        this.batch = "IIML-BA-M7-";
      } else if (val === "IIML-PM") {
        this.discount.push("5%","10%", "None");
        this.batch = "IIML-PM-M7-";
      } else if (val === "IIML-HR") {
        this.discount.push("5%", "None");
        this.batch = "IIML-HR-M7-";
      } else if (val === "IIML-SH") {
        this.discount.push("5%","10%", "None");
        this.batch = "IIML-SH-M7-";
      }else if (val === "IITR-BF") {
        this.discount.push("5%", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "IITR-BF-M7-";
      } else if (val === "IITR-DB") {
        this.discount.push("5%", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "IITR-DB-M7-";
      } else if (val === "IIML-SF") {
        this.immersion_fee = 18880;
        this.alumni_fee = 11800;
        this.discount.push("5%", "10%", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "IIML-SF-M7-";
      } else if (val === "IITM-AA") {
        this.discount.push("None", "21000 for scholarship", "20000 for 6 months", "15000 for 12 months", "6000 for 24 months","Custom");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "IITM-AA-M7-";
      } else if (val === "IITM-FS") {
        this.discount.push("5%", "None");
        // this.select4 = this.feesStructure[val]["Fee"];
        this.batch = "IITM-FS-M7-";
        this.loanType = "0";
        this.enableLoan = "";
      } else if (val === "IITR-CC") {
        // this.discount.push("5%", "10%", "None");
        // // this.select4 = this.feesStructure[val]["Fee"];
        this.discount.push("None", "21000 for scholarship", "20000 for 6 months", "15000 for 12 months", "6000 for 24 months","Custom");
        // this.select4 = 141600;
        // this.select3 = 0;
        // this.loanType = "0";
        // this.enableLoan = "";
        this.batch = "IITR-CC-M7-";
      } else if (val === "XLRI-HR") {
        this.discount.push("10000", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "XLRI-HR-M7-";
      }
      else if (val === "XLRI-SH") {
        this.discount.push("10000", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "XLRI-SH-M7-";
      }
      else if (val === "XLRI-DM") {
        this.discount.push("5%", "10%", "None");
        this.loanType = "0";
        this.enableLoan = "";
        this.batch = "XLRI-DM-M7-";
      } else if (val === "IIMI-BA") {
        this.discount.push("5%", "10%", "None");
        this.batch = "IIMI-BA-M7-";
      } else if (val === "IIMI-AA") {
        this.discount.push("None");
        this.batch = "IIMI-AA-M7-";
      }else if (val === "IIML-FT") {
        this.discount.push("5%", "10%", "None");
        this.batch = "IIML-FT-M7-";
      }
    },
    // selectedfeeType(val) {
    //   //Full Fee","Professional","Student"

    //   if (val === "Full Fee") {
    //     if (this.select3 == "5%") {
    //       this.select4 = 112100;
    //     } else {
    //       this.select4 = 118000;
    //     }
    //   } else if (val === "Professional") {
    //     if (this.select3 == "5%") {
    //       this.select4 = 94164;
    //     } else {
    //       this.select4 = 99120;
    //     }
    //   } else if (val === "Student") {
    //     if (this.select3 == "5%") {
    //       this.select4 = 72865;
    //     } else {
    //       this.select4 = 76700;
    //     }
    //   }

    //   // if (value === "BA") {
    //   //   this.select3 = "None";
    //   //   this.select4 = 244820;
    //   // } else if (value === "FA") {
    //   //   this.select3 = "None";
    //   //   this.select4 = 165200;
    //   // }
    // },
    select3(val) {
      console.log("selected discount",val);
      if (val !== "") {
        this.getFees();
      }
      if(val == 'None' && this.select2 == 'IIML-SF'){
       this.base_fee = 310340;
       this.select4 = this.base_fee + 18880
      }
      if(val == '5%' && this.select2 == 'IIML-SF'){
        this.base_fee = 294823;
        this.select4 = this.base_fee + 18880
      }
      if(val == '10%' && this.select2 == 'IIML-SF'){
        this.base_fee = 279306;
        this.select4 = this.base_fee + 18880
      }
      // else {
      //   if (this.selectedfeeType === "Full Fee") {
      //     if (val === "5%") {
      //       // 5900 is 5%
      //       this.select4 = 112100;
      //     } else {
      //       this.select4 = 118000;
      //     }
      //   } else if (this.selectedfeeType === "Professional") {
      //     if (val === "5%") {
      //       // 4956 is 5%
      //       this.select4 = 94164;
      //     } else {
      //       this.select4 = 99120;
      //     }
      //   } else if (this.selectedfeeType === "Student") {
      //     if (val === "5%") {
      //       // 3835 is 5%
      //       this.select4 = 72865;
      //     } else {
      //       this.select4 = 76700;
      //     }
      //   }
      // }

    },
    custom_discount(val) {
      this.getCustomFee(val)
    }
  },
  data() {
    return {
      including_alumni_fee: false,
      base_fee: '',
      immersion_fee: '',
      alumni_fee: '',
      reason_for_custom_discount : '',
      custom_discount : null,
      nonloanType: "",
      number: "",
      batch_number: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      batch: "",
      feeType: ["Full Fee", "Professional", "Student"],
      selectedfeeType: "",
      referralData: {
        identity: "",
        person_id: null,
        referral_bool: "no",
      },
      PlaceSelect: "",
      loanType: "0",
      select4: "",
      threemonths: "",
      sixmonths: "",
      ninemonths: "",
      titlebatch: "",
      navTitleName: "",
      selectedcity: "",
      radios: "no",
      address: "",
      iiml_sf: {
        "5%": 320783,
        "10%": 305266,
        None: 336300,
      },
      feesStructure: {
        "IIML-FA": {
          "5%": 156940,
          None: 165200,
        },
        "IIML-BA": {
          "5%": 223079,
          None: 234820,
        },
        "IIML-PM": {
          // "5%": 167029,
          // None: 175820,
          "5%": 211869,
          "10%": 200718,
          None: 223020,
          // change fees
        },
        "IIML-HR": {
          "5%": 167029,
          None: 175820,
        },
        "IIML-SH": {
          "5%": 167029,
          "10%": 158238,
          None: 175820,
        },
        "IITR-BF": {
          "5%": 140125,
          // "5%": 0,
          None: 147500,
        },
        "IITR-DB": {
          "5%": 140125,
          None: 147500,
        },
        "IIML-SF": {
          "5%": 313703,
          "10%": 298186,
          None: 329220,
        },
        "IITM-AA": {
          "6000 for 24 months": 134520,
          "15000 for 12 months": 123900,
          "20000 for 6 months": 118000,
          "21000 for scholarship": 116820,
          None: 141600,
        },
        "IIMK-CX": {
          "5%": 223079,
          None: 234820
        },
        "IITM-FS": {
          "5%": 112100,
          None: 118000
        },
        "IITR-CC": {
          "6000 for 24 months": 134520,
          "15000 for 12 months": 123900,
          "20000 for 6 months": 118000,
          "21000 for scholarship": 116820,
          None: 141600,
        },
        "XLRI-HR": {
          // "5%": 167029,
          // "10%": 158238,
          "10000": 201220,
          None: 211220,
        },
        "XLRI-SH": {
          // "5%": 178239,
          // "10%": 168858,
          "10000": 224820,
          None: 234820,
        },
        "XLRI-DM": {
          "5%": 189449,
          "10%": 179478,
          None: 199420,
        },
        // "IIMI-BA": {
        //   "5%": 253907,
        //   "10%": 240543,
        //   None: 267270,
        // },
        "IIMI-BA": {
          "5%": 276326.5,
          "10%": 261783,
          None: 290870,
        },
        "IIMI-AA": {
          // "5%": 560500,
          // "10%": 531000,
          None: 590000,
        },
        "IIML-FT": {
          "5%": 220399,
          "10%": 208799,
          None: 231998,
        },
      },

      places: [
        "ANDAMAN_AND_NICOBAR_ISLANDS",
        "ANDHRA_PRADESH",
        "ARUNACHAL_PRADESH",
        "ASSAM",
        "BIHAR",
        "CHANDIGARH",
        "CHHATTISGARH",
        "DADRA_AND_NAGAR_HAVELI",
        "DAMAN_AND_DIU",
        "DELHI",
        "GOA",
        "GUJARAT",
        "HARYANA",
        "HIMACHAL_PRADESH",
        "JAMMU_AND_KASHMIR",
        "JHARKHAND",
        "KARNATAKA",
        "KERALA",
        "LAKSHADWEEP",
        "MADHYA_PRADESH",
        "MAHARASHTRA",
        "MANIPUR",
        "MEGHALAYA",
        "MIZORAM",
        "NAGALAND",
        "ODISHA",
        "PONDICHERRY",
        "PUNJAB",
        "RAJASTHAN",
        "SIKKIM",
        "TAMIL_NADU",
        "TELANGANA",
        "TRIPURA",
        "UTTAR_PRADESH",
        "UTTARAKHAND",
        "WEST_BENGAL",
        "OUTSIDE_INDIA",
      ],
      cities_selected: "H",
      select3: "None",
      // IIML_Courses: [
      //   // "IIML BA",
      //   // "IIML FA",
      //   // "IIML PA",
      //   // "PA",
      //   // "IIML HR"
      // ],
      IIML_Course: [
        // "RPO",
        "IIML-FT",
        "XLRI-SH",
        // "IIML-BA",
        // "IIML-FA",
        // "IIML-PM",
        // "IIML-HR",
        // "IIML-SH",
        // "IITM-AA",
        // "IITR-BF",
        // "IITR-DB",
        "IIML-SF",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
        "XLRI-HR",
        "IIMI-BA",
        "IIMI-AA",
        // "XLRI-DM"
      ],
      select2: "",
      candidateId: "",
      eligibilityOptions: [
        { text: "Clear", value: 1 },
        { text: "Check", value: 2 },
        { text: "PGDPA", value: 3 },
        { text: "Eligibility", value: 4 },
      ],
      companyEdit: false,
      companyEditbutton: true,
      designationEdit: false,
      designationEditbutton: true,
      educationEdit: false,
      educationEditbutton: true,
      exEdit: false,
      exEditbutton: true,
      city: "",
      company: "",
      experience: "",
      designation: "",
      additionalInfo: "",
      eligibility: "",
      select1: [],
      url: "",
      engagementsload: [],
      mobileview: "",
      uploaded: false,
      sendDatails: "",
      emailview: "",
      attachmentKey: null,
      attachmentKeyAddress: null,
      attachmentKeyIdentity: null,
      saveFile: [],
      render: false,
      open: false,
      enableLoan: "",
      cpa_payments: true,
      cma_payments: false,
      typeCategories: ["B2C", "University"],
      typeSelection: "",
      cmaSubjects: [],
      loan_status_values: [
        "Not created",
        "Created",
        "In Review",
        "Documentation",
        "Push for disbursal",
        "Disbursed",
        "Rejected",
        "Dropped",
      ],
      loan_id: "",
      loan_status: "Created",
      cmapart1: {
        material: 262.5,
        training: 28850,
        name: "CMA Part 1",
      },
      cmapart2: {
        material: 262.5,
        training: 28850,
        name: "CMA Part 2",
      },
      batchDropdown: [
        "CPA-M7-WW",
        "CPA-M7-AA",
        "CPA-M7-BB",
        "CPA-M7-EE",
        "CPA-M7-46",
      ],
      disablestate: true,
      auditingfees: {
        material: 9750,
        training: 17500,
        name: "AUD",
      },
      bussinessenvfees: {
        material: 9750,
        training: 17500,
        name: "BEC",
      },
      financialfees: {
        material: 9750,
        training: 17500,
        name: "FAR",
      },
      regulationfees: { material: 9750, training: 17500, name: "REG" },
      cpaleFees: {
        material: 0,
        training: 59000,
        name: "exam_license_assistance",
      },
      indexpopup: 0,
      radios2: "",
      fees: [],
      discount: ["5%", "None"],
      total_selected_subjects: [],
      total_selected_license_fee: [],
      enableCorporateSelection: false,
      selectedCorporateOption: "B2B",
      CorporateOptions: ["B2B", "B2C"],
      loan_UUID: null,
    };
  },
  destroyed() {
    console.log("closed");
  },
  computed: {
    enrollment_study_material_fees() {
      let returnValue = 0;

      this.total_selected_subjects.forEach((subject) => {
        returnValue = returnValue + subject.material;
      });

      return returnValue;
    },
    enrollment_training_fees() {
      let returnValue = 0;
      let additionalPrice = 0;

      if (this.radios2 === "online_classroom") {
        additionalPrice = 5900;
      }

      this.total_selected_subjects.forEach((subject) => {
        returnValue = returnValue + subject.training + additionalPrice;
      });

      if (
        this.total_selected_subjects.length === 4 &&
        this.total_selected_license_fee.length === 1
      ) {
        returnValue = returnValue / 2;
      }

      return returnValue;
    },
    enrollment_exam_fees() {
      let returnValue = 0;

      this.total_selected_license_fee.forEach((subject) => {
        returnValue = returnValue + subject.training;
      });

      if (
        this.total_selected_subjects.length === 4 &&
        this.total_selected_license_fee.length === 1 &&
        this.select_training !== "Study Material Only"
      ) {
        returnValue = returnValue / 2;
      }

      return returnValue;
    },
    total_enrollment_fees() {
      let total =
        this.enrollment_study_material_fees +
        this.enrollment_training_fees +
        this.enrollment_exam_fees;
      return total;
    },
    // Totaldiscount, ExamDiscount, Trainingdiscount, Studydiscount
    total_study_material_cost() {
      let value = this.enrollment_study_material_fees - this.Studydiscount;
      return value;
    },
    total_training_material_cost() {
      let gst = 0;
      if (
        this.select_training !== "Study Material Only" &&
        this.enrollment_training_fees !== 0 &&
        this.total_selected_subjects.length === 4 &&
        this.total_selected_license_fee.length === 1
      ) {
        gst = 6300;
      }
      let value = this.enrollment_training_fees - this.Trainingdiscount + gst;
      return value;
    },
    total_exam_license_cost() {
      let gst = 0;
      if (
        this.select_training === "Study Material + Training" &&
        this.enrollment_exam_fees !== 0 &&
        this.total_selected_subjects.length === 4 &&
        this.total_selected_license_fee.length === 1
      ) {
        gst = 4500;
      }
      let value = this.enrollment_exam_fees - this.ExamDiscount + gst;
      return value;
    },
    total_enrollment_cost() {
      let value =
        this.total_study_material_cost +
        this.total_training_material_cost +
        this.total_exam_license_cost;
      return value;
    },
  },
  mounted() {
    EventBus.$on("open-IIMLEnrollmentPopup", (tr) => {
      console.log(tr);
      this.select2 = '';
      this.base_fee = '';
      this.including_alumni_fee = false;
      this.alumni_fee = '';
      this.immersion_fee = '';
      this.candidate_details = tr;
      this.open = true;
      this.render = true;
      this.loan_UUID = null;
      this.getAttachmentKey();
      this.uploaded = false;
      this.saveFile = [];
      this.address = "";
      this.select4 = "";
      this.loanType = "0";
      this.PlaceSelect = "";
      this.enableLoan = "";
      this.number = "";
      this.mobileview = this.candidate_details.mobileString;
      this.emailview = this.candidate_details.emailString;
      this.sendDatails = this.candidate_details;
      this.company = this.candidate_details.company;
      this.designation = this.candidate_details.designation;
      this.experience = this.candidate_details.experience;
      this.selectedcity = this.candidate_details.city.charAt(0);
      this.city = this.candidate_details.city;
      this.getSpoc();

      let actualEnrollments = [
        // "IIML-BA",
        // "IIML-FA",
        // "IIML-PM",
        // "IIML-HR",
        // "IITM-AA",
        // "IITR-BF",
        // "IITR-DB",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
        "XLRI-HR",
        // "XLRI-SH",
        "IIML-FT",
        "IIMI-BA",
        "IIMI-AA",
        "IIML-SF",
      ];

      tr.purchases.forEach((purchase) => {
        if (actualEnrollments.indexOf(purchase.name) !== -1) {
          actualEnrollments.splice(actualEnrollments.indexOf(purchase.name), 1);
        }
      });

      this.IIML_Courses = actualEnrollments;
      console.log("exed course", this.IIML_Courses);
      let courseDetails = [
        "IIML-FT",
        "IIMI-BA"
        // "IIML-BA",
        // "IIML-FA",
        // "IIML-PM",
        // "IIML-HR",
        // "IITM-AA",
        // "IITR-BF",
        // "IITR-DB",
        // "IIML-SF",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
      ];
      this.course = courseDetails;
      this.batch = "";
      this.reason = "";
      this.date = new Date();
      this.date2 = "";
      this.select3 = "";
      // this.select2 = "CPA";
      this.select2 = this.IIML_Courses[0];
      // this.select2 = courseDetails[0];
      this.Studydiscount = 0;
      this.Trainingdiscount = 0;
      this.select_training = "Study Material + Training";
      this.selec1 = "";
      if (tr.education_tags !== null) {
        this.select1 = this.candidate_details.education_tags.split(",");
      }
      this.candidateId = this.candidate_details.can_id;
    });
    // EventBus.$on("detailsToSend", () => {
    //   this.submitForm();
    // });
    // EventBus.$on("submittingForm", () => {
    //   let newcanid = `${this.selectedcity}-${this.select2}-${this.candidateId}`;
    //   let profilePic = this.attachmentKey.key;
    //   let addressProof = this.attachmentKeyAddress.key;
    //   let identityproof = this.attachmentKeyIdentity.key;
    //   let addressbar = this.address;
    //   let needBridge = this.radios;
    //   if (!this.uploaded) {
    //     profilePic = "";
    //   }
    //   // if (addressbar === "") {
    //   // this.$vs.notify({
    //   //   title: "Enter an address",
    //   //   text: "Enter an address to continue",
    //   //   color: "warning"
    //   // });
    //   // } else {
    //   EventBus.$emit("detailsToSend", [
    //     this.sendDatails,
    //     newcanid,
    //     profilePic,
    //     addressbar,
    //     needBridge,
    //     addressProof,
    //     identityproof,
    //   ]);
    //   // }
    // });
  },
  methods: {
    assignReferral(referralData) {
      // console.log("firing");
      this.referralData = referralData;
    },
    SendLoanApplication() {
      // this.$vs.loading();
      let obj = {
        mwb_id: this.candidate_details.id,
        spoc_name: this.candidate_details.assigned_iiml_name,
        spoc_id: this.candidate_details.assigned_iiml_id,
        user_name: this.candidate_details.person_name,
        email: this.emailview,
        mobile_nuber: this.mobileview,
        loan_amount: this.select4,
        role: "IIML",
        course: this.select2,
      };
      console.log(obj);
      const url = `${constants.SERVER_API}GenerateLoanApplication`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.loan_UUID = response.data.loan_uuid;
          } else {
            this.loan_UUID = null;
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getLabel() {
      return "ting";
    },
    getCustomFee(discount){
      let discounted_amount = (120000-discount)
      let gst = (discounted_amount)*0.18
      this.select4 = gst + discounted_amount;
      return this.select4;

    },
    getFees() {
      console.log("dummy get fee", this.select2, this.select3)

      if(this.select3 === 'Custom'){
         this.select4 = null
      }else {
         this.select4 = this.feesStructure[this.select2][this.select3];
         if(this.select2 == 'IIML-SF' && this.including_alumni_fee == true){
          this.select4 = this.iiml_sf[this.select3];
         }
      }
      console.log("dummy", this.select4)
    },
    getSpoc() {
      // console.log("from add lead");
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          // this.selectSpocCity(response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    selectSpocCity(response) {
      // console.log(response);
      response.data.spocs.forEach((spoc) => {
        if (spoc.id === response.data.logged_in_user_id) {
          // this.selectedcity = spoc.city.charAt(0);
          this.city = spoc.city;
        }
      });
    },
    getAttachmentKey() {
      const url = `${constants.SERVER_API}setKeysForEnrollment`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.attachmentKey = []
          // console.log(response);
          this.attachmentKey = { key: response.data.pic_key };
          this.attachmentKeyAddress = { key: response.data.address_proof_key };
          this.attachmentKeyIdentity = {
            key: response.data.photo_id_proof_key,
          };
        })
        .catch((error) => {
          if (error.response) {
            this.ReDirect_to_login();
          }
        });
    },
    async mobilePopup(mobile) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewMobileNumberNew?mobile_id=${mobile.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-mobile-options-popup", [
            response.data,
            this.studentDetails,
            mobile,
          ]);
        })
        .catch((error) => {
          if (error.response) {
            // console.log("error", error.response.status);
            if (error.response.status === 401) {
              this.ReDirect_to_login();
              // this.EmitEvent('close-popup-redirect')
            }
          }
        });
    },
    async emailPopup(email) {
      axios
        .get(`${constants.MILES_GOOGLE_INVITE}viewEmailIdNew?email_id=${email.id}`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.EmitEvent("open-email-options-popup", [
            response.data,
            this.studentDetails,
            email,
          ]);
        })
        .catch((error) => {
          if (error.response) {
            console.log("error", error.response.status);
            if (error.response.status === 401) {
              this.EmitEvent("close-MWB-popup");
              this.ReDirect_to_login();
            }
          }
        });
    },
    successUpload() {
      this.uploaded = true;
      // console.log("success", e.currentTarget.response);
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      this.saveFile = files;
      console.log("result", this.saveFile);
    },
    deleteFile(e) {
      this.saveFile.splice(e, 1);
      console.log("delete file", this.saveFile.splice(e, 1));
    },
    // remove(item) {
    //   this.select1.splice(this.select1.indexOf(item), 1);
    // },
    EmitEvent(emit) {
      if (this.candidate_details === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, this.candidate_details);
      }
    },
    companyeditfunc() {
      this.companyEdit = !this.companyEdit;
      this.companyEditbutton = !this.companyEditbutton;
    },
    designationeditfunc() {
      this.designationEdit = !this.designationEdit;
      this.designationEditbutton = !this.designationEditbutton;
    },
    educationeditfunc() {
      this.educationEdit = !this.educationEdit;
      this.educationEditbutton = !this.educationEditbutton;
    },
    exeditfunc() {
      this.exEdit = !this.exEdit;
      this.exEditbutton = !this.exEditbutton;
    },
    textfunction(edited) {
      console.log(edited);
      console.log("the tooltip is calling the function");
      return edited;
    },
    lastdigitfunc(mob) {
      let number = mob.toString().length - 3;
      let x = "";
      for (let v = 0; v < number; v++) {
        x = x + "X";
      }
      x = x + mob.toString().slice(-3);
      return x;
    },
    fetchIcon(type) {
      switch (type) {
        case "call":
          return "local_phone";
        case "visit":
          return "place";
        case "email":
          return "mail";
      }
    },
    engagementStorefetch(id) {
      this.engagementsload = [];
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.candidate_id === id) {
          this.engagementsload.push(engagement);
        }
      });
    },
    navSettings(text) {
      this.navTitleName = text;
    },
    // EventForSubmit() {
    //   EventBus.$emit("submittingForm");
    // },
    EventForSubmit() {

      let obj = {
        identity: this.candidate_details.identity,
        person_id: this.candidate_details.person_id,
        person_name: this.candidate_details.person_name,
        can_id: this.candidate_details.identity,
        city: this.candidate_details.city,
        profile_pic_link: null,
        photo_id_proof_link: null,
        address_proof_link: null,
        address: this.address,
        // need_for_bridge_course: this.candidate_details[4],
        course: this.select2,
        // fees: this.select4,
        // discount: this.select3,
        place_of_supply: this.PlaceSelect,
        mwb_id: this.candidate_details.id,
        can_email: this.emailview,
        mode: "training_only",
        mode_of_study: null,
        subjects_chosen: null,
        actual_cost: null,
        // agreed_cost: this.Total,
        agreed_cost: 0,
        enrollment_date: "",
        batch_start_date: null,
        batch: `${this.batch}${this.number}`,
        study_material_cost: 0,
        training_fee_cost: 0,
        exam_license_cost: 0,
        study_material_discount: 0,
        training_fee_discount: 0,
        exam_license_discount: 0,
        iiml_loan_uuid: this.loan_UUID,
        loan: 0,
        iiml_loan: null,
        referral_identity: null,
        referral_person_id: null,
        referral_bool: this.referralData.referral_bool,
        referral_account_payee_name: this.referralData.account_payee_name,
        referral_account_number: this.referralData.account_number,
        referral_bank_name: this.referralData.bank_name,
        referral_bank_branch: this.referralData.bank_branch,
        referral_ifsc_code: this.referralData.ifsc_code,
        reason_for_discount : this.reason_for_custom_discount
        // loan_id: null,
        // loan_status: null,
        // loan: this.enableLoan,
      };
      // let code = "01";
      // if (obj.course === "IIML-BA") {
      //   code = "02";
      // }

      console.log(this.referralData);
      if (this.select2 === "IITM-AA" || this.select2 === "IITR-CC") {
        if(this.select3 === "6000 for 24 months" || this.select3 === "15000 for 12 months" || this.select3 === "20000 for 6 months" ) {
          if(this.loanType === "0") {
            this.$vs.notify({
            title: `Error`,
            text: "Please select loan tenure",
            color: "danger",
          });
          return;
          }

        }

      }
      if (
        obj.batch === "" ||
        this.number === ""
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "Batch Code is required",
          color: "danger",
        });
        return;
      }
      if (
        obj.place_of_supply === "" ||
        obj.place_of_supply === null ||
        obj.place_of_supply === undefined
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "Place of supply is required",
          color: "danger",
        });
        return;
      }

      if (this.referralData.referral_bool === "yes") {
        // console.log("Inside");
        if (
          this.referralData.identity === "" ||
          this.referralData.identity === null ||
          this.referralData.person_id === null ||
          this.referralData.account_payee_name === null ||
          this.referralData.account_number === null ||
          this.referralData.bank_name === null ||
          this.referralData.bank_branch === null ||
          this.referralData.ifsc_code === null
        ) {
          // console.log(this.referralData.referral_account_payee_name);
          this.$vs.notify({
            title: `Error`,
            text: "Referral details are required",
            color: "danger",
          });
          return;
        }

        if (
          this.referralData.account_payee_name.length < 4 ||
          this.referralData.account_number.length < 10
        ) {
          this.$vs.notify({
            title: `Error`,
            text: " Please enter mandatory referral account details",
            color: "danger",
          });
          return;
        }
      }

      if (
        this.referralData.referral_bool === "yes" &&
        this.referralData.identity !== "" &&
        this.referralData.identity !== null &&
        this.referralData.person_id === null
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "A valid Can-ID is required while adding a referral",
          color: "danger",
        });
        return;
      } else if (
        (this.referralData.referral_bool === "yes" &&
          this.referralData.identity === "") ||
        this.referralData.identity === null
      ) {
        this.$vs.notify({
          title: `Error`,
          text: "A valid Can-ID is required while adding a referral",
          color: "danger",
        });
        return;
      } else {
        obj.referral_identity = this.referralData.identity;
        obj.referral_person_id = this.referralData.person_id;
      }

      // if (
      //   this.referralData.identity !== "" &&
      //   this.referralData.person_id === null
      // ) {
      //   this.$vs.notify({
      //     title: `Error`,
      //     text: "A valid Can-ID is required while adding a referral",
      //     color: "danger",
      //   });
      //   return;
      // } else {
      //   obj.referral_identity = this.referralData.identity;
      //   obj.referral_person_id = this.referralData.person_id;
      // }

      // obj.batch = `${obj.course}-M7-${code}`;
      obj.enrollment_date = this.datatoTimestamp(new Date());
      if (this.select2 !== "IITM-AA" || this.select2 !== "IITR-CC") {
        obj.actual_cost = this.feesStructure[this.select2]["None"];
        obj.training_fee_cost = this.feesStructure[this.select2]["None"];
        obj.agreed_cost = this.feesStructure[this.select2][this.select3];
        obj.training_fee_discount = obj.actual_cost - obj.agreed_cost;
        if(this.select2 == 'IIML-SF' && this.including_alumni_fee == true){
          this.select4 = this.iiml_sf[this.select3];
           obj.actual_cost = this.iiml_sf["None"];
           obj.agreed_cost = this.iiml_sf[this.select3];
           obj.training_fee_cost = this.iiml_sf[this.select3];
           obj.training_fee_discount = obj.actual_cost - obj.agreed_cost;
         }
      }
      if (this.select2 === "IITM-AA" || this.select2 === "IITR-CC") {
        obj.actual_cost = this.feesStructure[this.select2]["None"];
        obj.training_fee_cost = this.feesStructure[this.select2][this.select3];
        obj.agreed_cost = this.feesStructure[this.select2][this.select3];

        if(this.select3 === "6000 for 24 months") {
          obj.training_fee_discount = 6000;
        }
        if(this.select3 === "15000 for 12 months") {
          obj.training_fee_discount = 15000;
        }
        if(this.select3 === "20000 for 6 months") {
          obj.training_fee_discount = 20000;
        }
        if(this.select3 === "21000 for scholarship") {
          obj.training_fee_discount = 21000;
        }
        if(this.select3 === "None") {
          obj.training_fee_discount = obj.actual_cost - obj.agreed_cost;
        }

        if(this.select3 === "Custom") {
          obj.agreed_cost = this.getCustomFee(this.custom_discount)
          obj.training_fee_cost = this.getCustomFee(this.custom_discount)
          obj.training_fee_discount = this.custom_discount;
        }
      }

      // obj.agreed_cost = this.select4;



      if (this.loanType === "1") {
        obj.iiml_loan = this.enableLoan;
      }

      // if (this.select3 !== "None") {
      //   obj.reason_for_discount = this.select3;
      // }
      // if (this.select2 === "IITM-AA") {
      //   if (this.selectedfeeType === "Full Fee") {
      //     if (this.select3 === "5%") {
      //       // 5619 is 5%
      //       obj.reason_for_discount = "Full Fee with 5% Discount";
      //     } else {
      //       obj.reason_for_discount = "Full Fee ";
      //     }
      //   } else if (this.selectedfeeType === "Professional") {
      //     if (this.select3 === "5%") {
      //       // 4720 is 5%
      //       obj.reason_for_discount = "Professional Fee with 5% Discount";
      //     } else {
      //       obj.reason_for_discount = "Professional Fee ";
      //     }
      //   } else if (this.selectedfeeType === "Student") {
      //     if (this.select3 === "5%") {
      //       // 3652 is 5%

      //       obj.reason_for_discount = "Student Fee with 5% Discount";
      //     } else {
      //       obj.reason_for_discount = "Student Fee";
      //     }
      //   }
      // } else {
        // obj.reason_for_discount = "";
      // }

      //  if (this.select2 === "IITM-FS") {
      //   obj.actual_cost = this.select4;
      //   obj.training_fee_cost = this.select4;
      //   obj.agreed_cost = this.select4;
      // }
      // if (this.select2 === "IIMK-CX") {
      //   obj.actual_cost = this.feesStructure[this.select2]["None"];
      //   obj.training_fee_cost = this.feesStructure[this.select2]["None"];
      //   obj.agreed_cost = this.feesStructure[this.select2][this.select3];
      //   obj.training_fee_discount = obj.actual_cost - obj.agreed_cost;
      // }

      this.$vs.loading();
      console.log('iimk_cX-----',obj);
      //    console.log('iimk_cX',this.select4);
      // this.$vs.loading.close();
     // return;
    //const url = `https://crm.milesforce.com/api/addEnrollmentIIML`;
      const url = `${constants.SERVER_API}addEnrollmentIIML`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            EventBus.$emit("reload-leads-IIMLBA");
            this.open = false;
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getcolor(message) {
      switch (message) {
        case "success":
          return "success";
        case "error":
          return "danger";
      }
    },
  },
};
</script>
<style>
.cardConf {
  height: 100%;
}
</style>
