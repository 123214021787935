<template>
    <vs-card style="height: 840px; overflow-y: auto">
        <vs-row>
            <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="height: 100px"
            >
                <vs-navbar
                    text-color="black"
                    active-text-color="black"
                    class="myNavbar"
                    v-model="indexActive"
                >
                    <!-- <div slot="title">
            <vs-navbar-title>{{ navTitleName }}</vs-navbar-title>
          </div>-->

                    <vs-navbar-item index="0">
                        <a href="#" @click="navSettings('All')">ALL</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="1">
                        <a href="#" @click="navSettings('Call')">CALL</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="2">
                        <a href="#" @click="navSettings('Visit')">VISIT</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="3">
                        <a href="#" @click="navSettings('Email')">EMAIL</a>
                    </vs-navbar-item>
                    <vs-navbar-item index="4">
                        <a href="#" @click="navSettings('Whatsapp')"
                            >WHATSAPP</a
                        >
                    </vs-navbar-item>
                </vs-navbar>
            </vs-col>
            <div v-show="allpage === true" style="width: 100%">
                <vs-row style="margin-left: 2%">
                    <div v-for="(badge, index) in arr" :key="index">
                        <vs-avatar
                            v-show="badge.value !== 0"
                            size="32px"
                            color="danger"
                            badge-color="black"
                            :badge="badge.value"
                            :text="badge.name"
                        />
                    </div>
                </vs-row>
                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-end"
                        vs-w="12"
                    >
                        <vs-card style="margin-bottom: 0%">
                            <vs-row>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="flex-start"
                                    vs-align="center"
                                    vs-w="12"
                                >
                                    <vs-icon
                                        style="margin-right: 1%"
                                        :icon="fetchIcon(payload.type)"
                                    ></vs-icon>
                                    <b style="margin-right: 2%">{{
                                        payload.engagement_added_on
                                    }}</b>
                                    - {{ payload.engagement_details }}
                                </vs-col>
                            </vs-row>
                        </vs-card>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 2%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-start"
                        vs-w="12"
                    >
                        <vs-button
                            :color="getNextDateColor(payload)"
                            type="filled"
                            v-if="tagVisible"
                        >
                            <b>{{ getNextDateStatus(payload) }}</b>
                        </vs-button>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col>
                        <vs-card>
                            <vs-row>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="center"
                                    vs-align="center"
                                    vs-w="3"
                                >
                                    <span
                                        class="contact_buttons_span"
                                        @click="navSettings('Call')"
                                    >
                                        <vs-icon
                                            class="contact_buttons"
                                            icon="local_phone"
                                            color="white"
                                            bg="#d76c2f"
                                            round
                                        ></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="center"
                                    vs-align="center"
                                    vs-w="3"
                                >
                                    <span
                                        class="contact_buttons_span"
                                        @click="navSettings('Visit')"
                                    >
                                        <vs-icon
                                            class="contact_buttons"
                                            icon="place"
                                            color="white"
                                            bg="#d76c2f"
                                            round
                                        ></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="center"
                                    vs-align="center"
                                    vs-w="3"
                                >
                                    <span
                                        class="contact_buttons_span"
                                        @click="navSettings('Email')"
                                    >
                                        <vs-icon
                                            class="contact_buttons"
                                            icon="mail"
                                            color="white"
                                            bg="#d76c2f"
                                            round
                                        ></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="center"
                                    vs-align="center"
                                    vs-w="3"
                                >
                                    <span
                                        class="contact_buttons_span"
                                        @click="navSettings('Whatsapp')"
                                    >
                                        <vs-icon
                                            class="contact_buttons"
                                            icon="message"
                                            color="white"
                                            bg="#d76c2f"
                                            round
                                        ></vs-icon>
                                    </span>
                                </vs-col>
                            </vs-row>
                        </vs-card>

                        <vs-card
                            style="background: #dc6161"
                            v-if="payload.mhp_flag === 1"
                        >
                            <div>
                                <vs-row
                                    style="margin-top: 3%; margin-bottom: 3%"
                                >
                                    <vs-col
                                        vs-type="flex"
                                        vs-justify="flex-start"
                                        vs-align="center"
                                        vs-w="12"
                                        style="font-size: 20px; color: white"
                                    >
                                        <b>MISSED HOT POCKET</b>
                                        <!-- <vs-icon
                      style="margin-left:2%"
                      size="medium"
                      icon="notification_important"
                      color="white"
                    ></vs-icon>-->
                                    </vs-col>
                                </vs-row>
                                <vs-divider color="rgb(255, 255, 255)" />
                                <vs-row
                                    style="margin-top: 3%; margin-bottom: 3%"
                                >
                                    <vs-col
                                        vs-type="flex"
                                        vs-justify="flex-start"
                                        vs-align="center"
                                        vs-w="12"
                                        style="font-size: 15px; color: white"
                                    >
                                        <b>Type:</b>
                                        <b style="margin-left: 2%">{{
                                            payload.mhp_type
                                        }}</b>
                                    </vs-col>
                                </vs-row>
                                <vs-row class="gaps">
                                    <vs-col
                                        vs-type="flex"
                                        vs-justify="flex-start"
                                        vs-align="center"
                                        vs-w="12"
                                        style="font-size: 15px; color: white"
                                    >
                                        <b>Date:</b>
                                        <b style="margin-left: 2%">{{
                                            convertTimestampToDate(
                                                payload.mhp_date
                                            )
                                        }}</b>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </vs-card>
                    </vs-col>
                </vs-row>
            </div>
            <div v-show="emailpage === true" style="width: 100%">
                <vs-col
                    style="margin-bottom: 3%"
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="flex-start"
                    vs-w="12"
                >
                    <vs-button
                        color="danger"
                        type="gradient"
                        icon="email"
                        @click="emailform"
                        >Send Email</vs-button
                    >
                </vs-col>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Engagement:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                    </vs-col>
                </vs-row>
                <vs-divider class="my-6"></vs-divider>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Level:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <div>
                            <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.text"
                label="text"
              ></v-select>-->
                            <vs-select
                                class="w-full"
                                label="Levels"
                                v-model="levels"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in labelOptions"
                                />
                            </vs-select>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <h6 style="color: gray">Course:</h6>
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CPA"
                            >CPA</vs-checkbox
                        >
                    </vs-col>
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CIA"
                            >CIA</vs-checkbox
                        >
                    </vs-col> -->
                    
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CMA"
                            >CMA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CFA"
                            >CFA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="FRM"
                            >FRM</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="USP"
                            >USP</vs-checkbox
                        >
                    </vs-col>
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                    <vs-checkbox
                        color="dark"
                        v-model="course"
                        vs-value="RPO"
                        >RPO</vs-checkbox
                    >
                    </vs-col> -->
                    <!-- <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CISA"
                            >CISA</vs-checkbox
                        >
                    </vs-col> -->
                    
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FA"
                            >IIML-FA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-SF"
                            >IIML-SF</vs-checkbox
                        >
                    </vs-col>
                    <!-- <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FT"
                            >IIML-FT</vs-checkbox
                        >
                    </vs-col> -->
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-DA"
                            >IIML-DA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-HR"
                            >IIML-HR</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-PM"
                            >IIML-PM</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IITR-DB"
                            >IITR-DB</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IITM-AA"
                            >IITM-AA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIMK-CX"
                            >IIMK-CX</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IITM-FS"
                            >IITM-FS</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IITR-CC"
                            >IITR-CC</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IITR-CC"
                            >IITR-CC</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIMI-BA"
                            >IIMI-BA</vs-checkbox
                        >
                    </vs-col>
                    <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="DA">DA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="RPA">RPA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="FOF(O)">FOF(O)</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="WCBA">WCBA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="NO-BA">NO-BA</vs-checkbox>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
            <vs-checkbox color="dark" v-model="course" vs-value="MAYBE">MAYBE</vs-checkbox>
          </vs-col> -->
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="AICPA"
                            >AICPA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-HR"
                            >XLRI-HR</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-SH"
                            >XLRI-SH</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        v-if="checkIfRoleis('ac1')"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="1"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIMI-AA"
                            >IIMI-AA</vs-checkbox
                        >
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Next follow up date:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <datepicker
                            placeholder="Select Date"
                            :format="customFormatter"
                            v-model="dates"
                        ></datepicker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-start"
                        vs-w="12"
                    >
                        <vs-button
                            color="danger"
                            type="gradient"
                            icon="done_outline"
                            @click="API_post_add_engagement"
                            >ADD</vs-button
                        >
                    </vs-col>
                </vs-row>
            </div>
            <div v-show="visitpage === true" style="width: 100%">
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Engagement:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                    </vs-col>
                </vs-row>
                <vs-divider class="my-6"></vs-divider>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Enrollment:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <!-- <h6 style="color: gray">Enrollment:</h6> -->
                        <vs-checkbox
                            color="dark"
                            v-model="enrollment"
                        ></vs-checkbox>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    ></vs-col>
                </vs-row>
                <vs-row v-if="checkIfRoleis('cm1')">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Escalation:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="escalation_boolean"
                        ></vs-checkbox>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <vs-select
                            v-if="escalation_boolean === false"
                            disabled
                            placeholder
                            style="margin-top: 4%; margin: 10px"
                            class="w-full"
                            v-model="escalation_acads_member"
                        >
                            <vs-select-item
                                :key="index"
                                :value="item.value"
                                :text="item.text"
                                v-for="(item, index) in escaltionOptions"
                            />
                        </vs-select>
                        <vs-select
                            v-if="escalation_boolean === true"
                            placeholder
                            style="margin-top: 4%; margin: 10px"
                            class="w-full"
                            v-model="escalation_acads_member"
                        >
                            <vs-select-item
                                :key="index"
                                :value="item.value"
                                :text="item.text"
                                v-for="(item, index) in escaltionOptions"
                            />
                        </vs-select>
                    </vs-col>
                </vs-row>
                <vs-row v-if="checkIfRoleis('cm1')">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Second Visit:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="second_visit"
                        ></vs-checkbox>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <vs-select
                            v-if="second_visit === false"
                            disabled
                            placeholder
                            style="margin-top: 4%; margin: 10px"
                            class="w-full"
                            v-model="second_visit_select"
                        >
                            <vs-select-item
                                :key="index"
                                :value="item.value"
                                :text="item.text"
                                v-for="(item, index) in secondvisitOptions"
                            />
                        </vs-select>
                        <vs-select
                            v-if="second_visit === true"
                            placeholder
                            style="margin-top: 4%; margin: 10px"
                            class="w-full"
                            v-model="second_visit_select"
                        >
                            <vs-select-item
                                :key="index"
                                :value="item.value"
                                :text="item.text"
                                v-for="(item, index) in secondvisitOptions"
                            />
                        </vs-select>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Level:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <div v-if="connected === true">
                            <vs-select
                                class="w-full"
                                label="Levels"
                                v-model="levels"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in labelOptionsvisit"
                                />
                            </vs-select>
                        </div>
                        <div v-else>
                            <vs-select
                                disabled
                                class="w-full"
                                label="Levels"
                                v-model="levels"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in labelOptionsvisit"
                                />
                            </vs-select>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <h6 style="color: gray">Course:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="10"
                    >
                        <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="CPA"
                            >CPA</vs-checkbox
                        >
                                                <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="AICPA"
                            >AICPA</vs-checkbox
                        >
                        <!-- <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="CIA"
                            >CIA</vs-checkbox
                        > -->
                        <!-- <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="RPO"
                            >RPO</vs-checkbox
                        > -->
                        <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FT"
                            >IIML-FT</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="CMA"
                            >CMA</vs-checkbox
                        >
                        <!-- <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="CISA"
                            >CISA</vs-checkbox
                        > -->
                        <vs-checkbox
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FA"
                            >IIML-FA</vs-checkbox
                        >
                        <vs-checkbox
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-SF"
                            >IIML-SF</vs-checkbox
                        >
                        <vs-checkbox
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FT"
                            >IIML-FT</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-DA"
                            >IIML-DA</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-HR"
                            >IIML-HR</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-PM"
                            >IIML-PM</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IITR-DB"
                            >IITR-DB</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IITM-AA"
                            >IITM-AA</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIMK-CX"
                            >IIMK-CX</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IITM-FS"
                            >IITM-FS</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IITR-CC"
                            >IITR-CC</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIMK-FT"
                            >IIMK-FT</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-AB"
                            >IIML-AB</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIML-SH"
                            >IIML-SH</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IITJ-DE"
                            >IITJ-DE</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-HR"
                            >XLRI-HR</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-SH"
                            >XLRI-SH</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIMI-BA"
                            >IIMI-BA</vs-checkbox
                        >
                        <vs-checkbox
                            v-if="checkIfRoleis('exed')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-DM"
                            >XLRI-DM</vs-checkbox
                        >
                        <!-- <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="DA"
            >DA</vs-checkbox>
            <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="RPA"
            >RPA</vs-checkbox>
            <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="FOF(O)"
            >FOF(O)</vs-checkbox>
            <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="WCBA"
            >WCBA</vs-checkbox>
            <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="NO-BA"
            >NO-BA</vs-checkbox>
            <vs-checkbox
              :disabled="disable"
              style="margin-right: 2%"
              color="dark"
              v-model="course"
              vs-value="MAYBE"
            >MAYBE</vs-checkbox> -->
                        <!-- <vs-checkbox :disabled="disable" color="dark" v-model="course" vs-value="BA">BA</vs-checkbox> -->
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                       
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="10"
                    >
                        <vs-checkbox
                            v-if="checkIfRoleis('cm1') || checkIfRoleis('ac1')"
                            :disabled="disable"
                            style="margin-right: 2%"
                            color="dark"
                            v-model="course"
                            vs-value="IIMI-AA"
                            >IIMI-AA</vs-checkbox
                        >
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Next follow up date:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <datepicker
                            placeholder="Select Date"
                            :format="customFormatter"
                            v-model="dates"
                        ></datepicker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-start"
                        vs-w="12"
                    >
                        <vs-button
                            color="danger"
                            type="gradient"
                            icon="done_outline"
                            @click="API_post_add_engagement"
                            >ADD</vs-button
                        >
                    </vs-col>
                </vs-row>
            </div>
            <div v-show="callpage === true" style="width: 100%">
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="12"
                    >
                        <vs-card>
                            <vs-row>
                                <vs-col
                                    vs-type="flex"
                                    vs-justify="flex-start"
                                    vs-align="center"
                                    vs-w="12"
                                >
                                    <p>
                                        <strong>PREVIOUS ENGAGEMENT -</strong>
                                        {{ payload.engagement_details }}
                                    </p>
                                </vs-col>
                            </vs-row>
                        </vs-card>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                        style="font-size: 15px"
                    >
                        <h6 style="color: gray">Connection Status:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                        style="font-size: 15px"
                    >
                        <!-- <v-select
              placeholder="Status"
              class="w-full"
              v-model="select1"
              :options="options1"
              label="text"
            ></v-select>-->
                        <vs-select
                            class="w-full"
                            label="status"
                            v-model="select1"
                        >
                            <vs-select-item
                                :key="index"
                                :value="item.text"
                                :text="item.text"
                                v-for="(item, index) in options1"
                            />
                        </vs-select>
                    </vs-col>
                </vs-row>
                <div v-if="show === true">
                    <vs-divider class="my-6"></vs-divider>
                    <div v-if="connected === true">
                        <vs-row style="margin-bottom: 3%">
                            <vs-col
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                                vs-w="4"
                                style="font-size: 15px"
                            >
                                <h6 style="color: gray">Response:</h6>
                            </vs-col>
                            <vs-col
                                vs-justify="center"
                                vs-align="flex-start"
                                vs-w="4"
                            >
                                <vs-radio
                                    color="dark"
                                    v-model="responseSelection"
                                    vs-value="sameResponse"
                                >
                                    <p style="font-size: 12px">SAME RESPONSE</p>
                                </vs-radio>
                            </vs-col>
                            <vs-col
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                                vs-w="4"
                            >
                                <vs-radio
                                    color="dark"
                                    id="new"
                                    v-model="responseSelection"
                                    vs-value="new"
                                >
                                    <p style="font-size: 12px">NEW</p>
                                </vs-radio>
                            </vs-col>
                        </vs-row>
                    </div>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="4"
                        >
                            <h6 style="color: gray">Engagement:</h6>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="8"
                        >
                            <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                        </vs-col>
                    </vs-row>
                    <vs-divider class="my-6"></vs-divider>
                    <vs-row
                        style="margin-bottom: 3%"
                        v-if="checkIfRoleis('cm1')"
                    >
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="4"
                        >
                            <h6 style="color: gray">Escalation:</h6>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="2"
                        >
                            <vs-checkbox
                                color="dark"
                                v-model="escalation_boolean"
                            ></vs-checkbox>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="4"
                        >
                            <vs-select
                                v-if="escalation_boolean === false"
                                disabled
                                placeholder
                                style="margin-top: 4%; margin: 10px"
                                class="w-full"
                                v-model="escalation_acads_member"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in escaltionOptions"
                                />
                            </vs-select>
                            <vs-select
                                v-if="escalation_boolean === true"
                                placeholder
                                style="margin-top: 4%; margin: 10px"
                                class="w-full"
                                v-model="escalation_acads_member"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in escaltionOptions"
                                />
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                            vs-w="4"
                        >
                            <h6 style="color: gray">Level:</h6>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="8"
                        >
                            <div v-if="connected === true">
                                <!-- <v-select
                  placeholder="Levels"
                  class="w-full"
                  v-model="levels"
                  :options="labelOptions"
                  label="text"
                ></v-select>-->
                                <vs-select
                                    class="w-full"
                                    label="Levels"
                                    v-model="levels"
                                >
                                    <vs-select-item
                                        :key="index"
                                        :value="item.value"
                                        :text="item.text"
                                        v-for="(item, index) in labelOptions"
                                    />
                                </vs-select>
                            </div>
                            <div v-else>
                                <!-- <v-select
                  disabled
                  placeholder="Levels"
                  class="w-full"
                  v-model="levels"
                  :options="labelOptions"
                  label="text"
                ></v-select>-->
                                <vs-select
                                    disabled
                                    class="w-full"
                                    label="Levels"
                                    v-model="levels"
                                >
                                    <vs-select-item
                                        :key="index"
                                        :value="item.value"
                                        :text="item.text"
                                        v-for="(item, index) in labelOptions"
                                    />
                                </vs-select>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="1"
                        >
                            <h6 style="color: gray">Course:</h6>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="flex-start"
                            vs-w="11"
                        >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="CPA"
                                style="margin-right: 1%"
                                >CPA</vs-checkbox
                            >
                            <!-- <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="CIA"
                                >CIA</vs-checkbox
                            > -->
                            <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="IIMI-BA"
                                >IIMI-BA</vs-checkbox
                            >
                            
                            <!-- <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="RPO"
                                >RPO</vs-checkbox
                            > -->
                            <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="IIML-FT"
                                >IIML-FT</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="CMA"
                                style="margin-right: 1%"
                                >CMA</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="CFA"
                                style="margin-right: 1%"
                                >CFA</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="FRM"
                                style="margin-right: 1%"
                                >FRM</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="USP"
                                style="margin-right: 1%"
                                >USP</vs-checkbox
                            >
                            <!-- <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="CISA"
                                >CISA</vs-checkbox
                            > -->
                            <!-- <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="DA"
                style="margin-right: 1%"
                >DA</vs-checkbox
              >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="RPA"
                style="margin-right: 1%"
                >RPA</vs-checkbox
              >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="FOF(O)"
                style="margin-right: 1%"
                >FOF(O)</vs-checkbox
              >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="WCBA"
                style="margin-right: 1%"
                >WCBA</vs-checkbox
              >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="NO-BA"
                style="margin-right: 1%"
                >NO-BA</vs-checkbox
              >
              <vs-checkbox
                :disabled="disable"
                color="dark"
                v-model="course"
                vs-value="MAYBE"
                style="margin-right: 1%"
                >MAYBE</vs-checkbox
              > -->
                            <!-- <vs-checkbox :disabled="disable" color="dark" v-model="course" vs-value="BA">BA</vs-checkbox> -->
                        </vs-col>
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="1"
                        >
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="flex-start"
                            vs-w="11"
                        >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="AICPA"
                                style="margin-right: 1%"
                                >AICPA</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                color="dark"
                                v-model="course"
                                vs-value="IIMI-AA"
                                style="margin-right: 1%"
                                >IIMI-AA</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="XLRI-HR"
                                >XLRI-HR</vs-checkbox
                            >
                            <vs-checkbox
                                :disabled="disable"
                                style="margin-right: 1%"
                                color="dark"
                                v-model="course"
                                vs-value="XLRI-SH"
                                >XLRI-SH</vs-checkbox
                            >
                        </vs-col>
                        
                    </vs-row>
                    <vs-row style="margin-bottom: 3%">
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="4"
                        >
                            <h6 style="color: gray">Next follow up date:</h6>
                        </vs-col>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                            vs-w="8"
                        >
                            <datepicker
                                :disabledDates="disabledDates"
                                placeholder="Select Date"
                                :format="customFormatter"
                                v-model="dates"
                            ></datepicker>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-start"
                            vs-w="12"
                        >
                            <vs-button
                                color="danger"
                                type="gradient"
                                icon="done_outline"
                                @click="API_post_add_engagement"
                                >ADD</vs-button
                            >
                        </vs-col>
                    </vs-row>
                </div>
            </div>
            <div v-show="whatsapp_page === true" style="width: 100%">
                <vs-col
                    style="margin-bottom: 3%"
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="flex-start"
                    vs-w="12"
                >
                    <vs-button color="danger" type="gradient" icon="email"
                        >Send Whatsapp</vs-button
                    >
                </vs-col>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Engagement:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
                    </vs-col>
                </vs-row>
                <vs-divider class="my-6"></vs-divider>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Level:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <div>
                            <!-- <v-select
                placeholder="Levels"
                class="w-full"
                v-model="levels"
                :options="labelOptions"
                :reduce="text => text.value"
                label="text"
              ></v-select>-->
                            <vs-select
                                class="w-full"
                                label="Levels"
                                v-model="levels"
                            >
                                <vs-select-item
                                    :key="index"
                                    :value="item.value"
                                    :text="item.text"
                                    v-for="(item, index) in labelOptions"
                                />
                            </vs-select>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <h6 style="color: gray">Course:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CPA"
                            >CPA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CMA"
                            >CMA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="CFA"
                            >CFA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="FRM"
                            >FRM</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="USP"
                            >USP</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIMI-BA"
                            >IIMI-BA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="IIML-FT"
                            >IIML-FT</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox color="dark" v-model="course" vs-value="DA"
                            >DA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="RPA"
                            >RPA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="FOF(O)"
                            >FOF(O)</vs-checkbox
                        >
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="AICPA"
                            >AICPA</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-HR"
                            >XLRI-HR</vs-checkbox
                        >
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="XLRI-SH"
                            >XLRI-SH</vs-checkbox
                        >
                    </vs-col>
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                    </vs-col>
                   <!-- <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="2"
                    >
                        <vs-checkbox
                            color="dark"
                            v-model="course"
                            vs-value="RPO"
                            >RPO</vs-checkbox
                        >
                        
                    </vs-col> -->
                </vs-row>
                <vs-row style="margin-bottom: 3%">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="4"
                    >
                        <h6 style="color: gray">Next follow up date:</h6>
                    </vs-col>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="8"
                    >
                        <datepicker
                            placeholder="Select Date"
                            :format="customFormatter"
                            v-model="dates"
                        ></datepicker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-start"
                        vs-w="12"
                    >
                        <vs-button
                            color="danger"
                            type="gradient"
                            icon="done_outline"
                            @click="API_post_add_engagement"
                            >ADD</vs-button
                        >
                    </vs-col>
                </vs-row>
            </div>
        </vs-row>
    </vs-card>
</template>

<script>
import axios from "axios";
import chatInterface from "./DashboardChatInterface.vue";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import EventBus from "../eventbus.js";
import moment from "moment";
import vSelect from "vue-select";
export default {
    props: {
        payload: {
            required: true,
        },
        selected: {
            required: true,
        },
    },
    mounted() {
        if (
            this.payload.level === "M4-" ||
            this.payload.level === "M4" ||
            this.payload.level === "M5" ||
            this.payload.level === "M6" ||
            this.payload.level === "L4-" ||
            this.payload.level === "L4" ||
            this.payload.level === "L5" ||
            this.payload.level === "L6" ||
            this.payload.level === "M7"
            // this.payload.level === "M7-" ||
            // this.payload.level === "M8" ||
            // this.payload.level === "M8-" ||
            // this.payload.level === "M9" ||
            // this.payload.level === "M9-" ||
            // this.payload.level === "M10"
        ) {
            this.labelOptions = this.labelOptionsvisit;
        }

        if(this.getTeam('SR')) {
            if (this.payload.level.indexOf("M10") !== -1) {
                this.connected = false;
            }
        } 
        if(!this.getTeam('SR')) {
            if (this.payload.level.indexOf("M7") !== -1) {
                    this.connected = false;
            } 
        }
        this.course = [];
        this.course = this.payload.courses.split(",");
        this.levels = this.payload.level;
        this.navSettings(this.selected);
        // console.log(this.payload)
        // EventBus.$on("mwb-cards", payload => {
        //   this.course = [];
        //   this.select1 = "";
        //   this.course.push(payload.courses);
        //   this.levels = payload.level;
        //   // this.navSettings("All");
        //   // console.log(this.payload)
        // });
        this.generateEngagementInfoBadges();
        EventBus.$on("netEnquiryDetails", (payload) => {
            this.netEnquiryDetails = payload;
            console.log("updated");
        });
    },
    components: {
        Datepicker,
        "v-select": vSelect,
        chatInterface,
    },
    watch: {
        visit_escalation: function (value) {
            this.escalation_visit = value;
        },
        levels: function (value) {
            console.log(value);
            let limit = 180;
            let suggested = 180;
            const nfds = {
                M6: {
                    limit: 14,
                    suggested: 7,
                },
                M5: {
                    limit: 14,
                    suggested: 7,
                },
                M4: {
                    limit: 180,
                    suggested: 90,
                },
                "M4-": {
                    limit: 180,
                    suggested: 120,
                },
                "M3++": {
                    limit: 14,
                    suggested: 1,
                },
                "M3+": {
                    limit: 14,
                    suggested: 7,
                },
                M3: {
                    limit: 30,
                    suggested: 14,
                },
                M2: {
                    limit: 180,
                    suggested: 90,
                },
                M1: {
                    limit: 180,
                    suggested: 120,
                },
                M7: {
                    limit: null,
                    suggested: null,
                },
                "M7-": {
                    limit: 30,
                    suggested: 30,
                },
                M8: {
                    limit: 30,
                    suggested: 30,
                },
                "M8-": {
                    limit: 90,
                    suggested: 90,
                },
                M9: {
                    limit: 30,
                    suggested: 30,
                },
                "M9-": {
                    limit: 90,
                    suggested: 90,
                },
                M10: {
                    limit: 30,
                    suggested: 30,
                },
                L6: {
                    limit: 30,
                    suggested: 14,
                },
                L5: {
                    limit: 60,
                    suggested: 15,
                },
                L4: {
                    limit: 120,
                    suggested: 30,
                },
                "L4-": {
                    limit: 180,
                    suggested: 120,
                },
                "L3+": {
                    limit: 90,
                    suggested: 30,
                },
                L3: {
                    limit: 90,
                    suggested: 30,
                },
                L2: {
                    limit: 120,
                    suggested: 30,
                },
                L1: {
                    limit: 180,
                    suggested: 120,
                },
            };
            limit = nfds[value].limit;
            suggested = nfds[value].suggested;
            this.disabledDates.from = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + limit
            );
            this.disabledDates.to = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            );
            this.dates = new Date(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + suggested
                )
            );
            if (value === "M7") {
                this.dates = "";
            }
        },
        select1: function (value) {
            console.log(value);
            let valuetext = value;
            if (valuetext === "Connected / Discussed") {
                this.disable = false;
                if(this.getTeam('SR')) {
                    if (this.payload.level.indexOf("M10") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                    } else {
                    if (this.payload.level.indexOf("M7") !== -1) {
                        this.connected = false;
                    } else {
                        this.connected = true;
                    }
                }
                this.connection_status = true;
                this.responseSelection = "new";
                this.textAre = "";
                this.show = true;
            } else if (valuetext === "") {
                this.show = false;
            } else if (valuetext === "Connected / Never call back") {
                this.connection_status = true;
                this.textAre = valuetext;
                this.disable = true;
                this.connected = false;
                this.show = true;
            } else {
                this.disable = true;
                this.connected = false;
                this.generateEngagementText(valuetext);
                this.show = true;
            }
        },
        responseSelection: function (val) {
            switch (val) {
                case "sameResponse":
                    this.textAre = this.payload.engagement_details;
                    break;
                case "noResponse":
                    this.textAre = "Not Reachable";
                    break;
                case "new":
                    this.textAre = "";
                    break;
            }
        },
    },
    data() {
        return {
            checkboxdisable: false,
            tagVisible: true,
            netEnquiryDetails: "",
            arr: [
                {
                    name: "Connected, Busy",
                    value: 0,
                    form: "CB",
                },
                {
                    name: "Connected, Never call back",
                    value: 0,
                    form: "CN",
                },
                {
                    name: "Connected, Wrong Number",
                    value: 0,
                    form: "CW",
                },
                {
                    name: "Busy",
                    value: 0,
                    form: "B",
                },
                {
                    name: "Not Lifting",
                    value: 0,
                    form: "NL",
                },
                {
                    name: "Not Reachable",
                    value: 0,
                    form: "NR",
                },
                {
                    name: "Disconnected",
                    value: 0,
                    form: "D",
                },
                {
                    name: "Invalid Number",
                    value: 0,
                    form: "IN",
                },
                {
                    name: "Switched Off",
                    value: 0,
                    form: "SO",
                },
            ],
            indexActive: 0,
            disabledDates: {
                to: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 2,
                    5
                ),
                from: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 2,
                    26
                ),
            },
            connected: false,
            escalation_connect: false,
            escalations_visit: false,
            secondvisit_connect: false,
            show: false,
            disable: true,
            options1: [
                { text: "", value: 1 },
                { text: "Connected / Discussed", value: 2 },
                { text: "Connected / Busy", value: 3 },
                { text: "Connected / Never call back", value: 4 },
                { text: "Connected / Wrong Number", value: 4 },
                { text: "Busy", value: 5 },
                { text: "Not Lifting", value: 6 },
                { text: "Not Reachable", value: 7 },
                { text: "Disconnected", value: 8 },
                { text: "Invalid Number", value: 9 },
                { text: "Switched Off", value: 10 },
            ],
            dates: "",
            course: [],
            levels: "",
            textAre: "",
            phone_number_toupdate: "",
            responseSelection: "",
            select1: "",
            latestEngagement: "",
            navTitleName: "",
            allpage: true,
            visitpage: false,
            callpage: false,
            whatsapp_page: false,
            emailpage: false,
            lastengagement: "",
            details: "",
            labelOptionsvisit: [
                { text: "", value: "" },
                { text: "M10 - Alumini (30 days)", value: "M10" },
                { text: "M9 - One exam cleared (30 days)", value: "M9" },
                {
                    text:
                        "M9- - Drop-out (after clearing an exam) (once every 3 months)",
                    value: "M9-",
                },
                { text: "M8 - Evaluation (30 days)", value: "M8" },
                {
                    text:
                        "M8- - Drop-out (after evaluation) (once every 3 months)",
                    value: "M8-",
                },
                { text: "M7- - Drop-out (30 days)", value: "M7-" },
                { text: "M7 - Enrolled", value: "M7" },
                { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                { text: "M5 - Visited & Positive", value: "M5" },
                { text: "M4 - Visited but Postponed", value: "M4" },
                { text: "M4- - Visited but not interested", value: "M4-" },
            ],
            labelOptions: [
                { text: "", value: "" },
                { text: "M7 - Enrolled", value: "M7" },
                { text: "M6 - Visited & Ready to Enroll", value: "M6" },
                { text: "M5 - Visited & Positive", value: "M5" },
                { text: "M4 - Visited but Postponed", value: "M4" },
                { text: "M4- - Visited but not interested", value: "M4-" },
                { text: "M3++ - Ready to enroll - Not visited", value: "M3++" },
                { text: "M3+ - Called & Coming", value: "M3+" },
                { text: "M3 - Called & Positive", value: "M3" },
                { text: "M2 - Did not Visit & Postponed", value: "M2" },
                { text: "M1 - Did not Visit & not intersted", value: "M1" },
            ],
            escalation_boolean: false,
            escalation_acads_member: "",
            escaltionOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
            ],
            second_visit: false,
            second_visit_select: "",
            secondvisitOptions: [
                { text: "", value: "" },
                { text: "Samiya Zafar", value: 114 },
            ],
            visit_escalation: "",
            escalation_visit: false,
            escaltionvisitOptions: [
                { text: "", value: "" },
                { text: "Esccalation_1", value: "Esccalation_1" },
                { text: "Esccalation_2", value: "Esccalation_2" },
                { text: "Esccalation_3", value: "Esccalation_3" },
                { text: "Esccalation_4", value: "Esccalation_4" },
            ],
            typeSelction: "",
            courseSelection: "",
            connection_status: false,
            latestDate: "",
            enrollment: false,
        };
    },
    methods: {
        checkIfRoleis(role) {
            if (localStorage.getItem("role") === role) {
                return true;
            } else {
                return false;
            }
        },
        getNextDateStatus(payload) {
            console.log(payload.next_call);
            if (payload.next_call === null) {
                this.tagVisible = false;
                return;
            }
            this.tagVisible = true;
            let nextCall = new Date(payload.next_call * 1000);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (nextCall > today) {
                return `Waiting - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            } else if (nextCall < today) {
                return `Delay - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            } else {
                return `Today - ${this.convertTimestampToDate(
                    payload.next_call
                )}`;
            }
        },
        getNextDateColor(payload) {
            let nextCall = new Date(payload.next_call * 1000);
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            if (nextCall > today) {
                return `success`;
            } else if (nextCall < today) {
                return `danger`;
            } else {
                return `warning`;
            }
        },
        generateEngagementInfoBadges() {
            if (this.payload.incommunicado_status !== 0) {
                for (let x = 0; x <= this.payload.engagements.length - 1; x++) {
                    if (
                        this.payload.engagements[x].connection_status === "CD"
                    ) {
                        break;
                    } else {
                        this.arr.forEach((ar) => {
                            if (
                                this.payload.engagements[x]
                                    .connection_status === ar.form
                            ) {
                                ar.value = ar.value + 1;
                            }
                        });
                    }
                }
            }
            // console.log(this.arr);
        },
        generateEngagementText(value) {
            let engagementString = "";
            // console.log(this.payload);
            if (
                this.payload.engagement_details === null ||
                this.payload.engagement_details === ""
            ) {
                this.textAre = value;
                this.latestDate = `${this.getData()} - ${value}, |`;
                this.connection_status = false;
            } else {
                if (this.payload.engagement_details.includes("|")) {
                    // console.log("in the if block");
                    let index = this.payload.engagement_details.indexOf("|");
                    index = index + 1;
                    engagementString = `${this.getData()} - ${value}, | ${this.payload.engagement_details.substring(
                        index
                    )}`;
                } else {
                    // console.log("in the else block");
                    engagementString = `${this.getData()} - ${value}, | ${
                        this.payload.engagement_details
                    }`;
                }
                this.textAre = value;
                this.latestDate = engagementString;
                this.connection_status = false;
            }
            console.log(this.latestDate);
        },
        getData() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            return today;
        },
        customFormatter(date) {
            return moment(date).format("Do MMMM YYYY");
        },
        emailform() {
            EventBus.$emit("open-email-engagement", this.payload);
        },
        fetchIcon(type) {
            switch (type) {
                case "call":
                    return "local_phone";
                case "visit":
                    return "place";
                case "email":
                    return "mail";
            }
        },
        getEscalationStatus() {
            if (this.escalation_boolean) {
                return 1;
            } else {
                return 0;
            }
        },
        getStatus(bool) {
            if (bool) {
                return 1;
            } else {
                return 0;
            }
        },
        async API_post_add_engagement() {
            this.textAre = this.textAre.trim();
            if (this.textAre === "" || this.date === "") {
                this.$vs.notify({
                    title: "Fill out the form",
                    text: "Please fill out the details in the engagement form",
                    color: "danger",
                });
            } else {
                const engagementType = this.navTitleName.toLowerCase();
                if (
                    this.connection_status === false &&
                    engagementType === "call"
                ) {
                    this.textAre = this.latestDate;
                }
                let timestmp = "";
                if (this.payload.level !== "M7") {
                    timestmp = new Date(this.dates).getTime() / 1000;
                }
                let connect_status = "";
                switch (this.select1) {
                    case "Connected / Discussed":
                        connect_status = "cd";
                        break;
                    case "Connected / Busy":
                        connect_status = "cb";
                        break;
                    case "Connected / Never call back":
                        connect_status = "cn";
                        break;
                    case "Connected / Wrong Number":
                        connect_status = "cw";
                        break;
                    case "Busy":
                        connect_status = "b";
                        break;
                    case "Not Lifting":
                        connect_status = "nl";
                        break;
                    case "Not Reachable":
                        connect_status = "nr";
                        break;
                    case "Disconnected":
                        connect_status = "d";
                        break;
                    case "Invalid Number":
                        connect_status = "in";
                        break;
                    case "Switched Off":
                        connect_status = "so";
                        break;
                }
                if (this.navTitleName.toLowerCase() === "visit") {
                    connect_status = "cd";
                }
                let copyenrollment = "";
                if (this.enrollment) {
                    copyenrollment = "Yes";
                } else {
                    copyenrollment = "No";
                }
                // console.log(this.enrollment);
                // this.course.forEach((subject, index) => {
                //   if (subject === "None") {
                //     this.course.splice(index, 1);
                //   }
                // });
                let co = this.course.join();
                let url = `${constants.SERVER_API}addEngagement`;
                let obj = {
                    can_id: this.payload.can_id,
                    person_id: this.payload.person_id,
                    person_name: this.payload.person_name,
                    escalation_status: this.getStatus(this.escalation_boolean),
                    escalated_to: this.escalation_acads_member,
                    second_visit_status: this.getStatus(this.second_visit),
                    second_visit_to: this.second_visit_select,
                    level: this.levels,
                    courses: co,
                    details: this.textAre,
                    type: this.navTitleName.toLowerCase(),
                    acads: 0,
                    next_call: timestmp,
                    update_call_log: "Yes",
                    connection_status: connect_status.toUpperCase(),
                    enrollment: copyenrollment,
                };
                console.log(obj);
                // return
                axios
                    .post(url, obj, {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === "error") {
                            this.$vs.notify({
                                title: "Error",
                                text: response.data.message,
                                color: "danger",
                            });
                            return;
                        }
                        this.$store.commit("UPDATE_ENGAGEMENT", [
                            this.payload,
                            this.textAre,
                            this.getData(),
                            connect_status,
                        ]);
                        this.$store.commit("UPDATE_ENGAGEMENT_AND_LEVEL", [
                            this.payload,
                            response.data.engagement,
                        ]);
                        this.$vs.notify({
                            title: "Engagement Added",
                            text: "Engagement is successfully added",
                            color: "success",
                        });
                        if (this.$route.path === "/queue") {
                            // console.log("this is in queue page");
                            EventBus.$emit("update-queue");
                        }
                        if (this.$route.path === "/netEnquiryTable") {
                            console.log(" ");
                            EventBus.$emit("updateCompleteNetEnquiry");
                            EventBus.$emit("close-MWB-popup");
                            EventBus.$emit("close-popup-redirect");
                        } else {
                            EventBus.$emit("close-MWB-popup");
                            EventBus.$emit("close-popup-redirect");
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },
        navSettings(text) {
            this.navTitleName = text;
            this.escalation_boolean = false;
            this.escalation_acads_member = "";
            this.second_visit = false;
            this.second_visit_select = "";
            switch (text) {
                case "All":
                    this.indexActive = 0;
                    this.allpage = true;
                    this.callpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Call":
                    this.indexActive = 1;
                    this.textAre = "";
                    this.select1 = "";
                    this.levels = this.payload.level;
                    this.enrollment = false;
                    this.callpage = true;
                    this.allpage = false;
                    this.visitpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Visit":
                    this.indexActive = 2;
                    this.textAre = "";
                    this.enrollment = false;
                    this.visitpage = true;
                    this.disable = false;
                    this.connected = true;
                    this.connection_status = true;
                    this.allpage = false;
                    this.callpage = false;
                    this.emailpage = false;
                    this.whatsapp_page = false;
                    if (
                        !this.payload.level.includes("4") &&
                        !this.payload.level.includes("5") &&
                        !this.payload.level.includes("6") &&
                        !this.payload.level.includes("7")
                    ) {
                        1;
                        this.levels = "M5";
                    } else {
                        if (this.payload.level.includes("L")) {
                            this.levels = "M5";
                        } else {
                            this.levels = this.payload.level;
                        }
                    }
                    break;
                case "Email":
                    this.indexActive = 3;
                    this.emailpage = true;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    this.whatsapp_page = false;
                    break;
                case "Whatsapp":
                    this.indexActive = 4;
                    this.whatsapp_page = true;
                    this.emailpage = false;
                    this.visitpage = false;
                    this.allpage = false;
                    this.callpage = false;
                    break;
            }
        },
    },
};
</script>

<style>
.contact_buttons {
    font-size: 50px;
    width: 100px !important;
    height: 100px !important;
    padding-top: 25px;
}
</style>
