<template>
  <div>
    <vs-popup class="assignspocpopupwidth" :id="'assignlead'" :title="'Assign Leads'" :active.sync="open">
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">Search</b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
          <vs-chip color="warning" v-for="(chip, index) in search" :key="index">{{chip}}</vs-chip>
        </vs-col>
      </vs-row>-->
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
          <b style="color: gray">Cources</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="9"
        >
          <v-select
            :options="cities"
            v-model="cityselected"
            style="width:450px; position: fixed; z-index:1000;width : 30% !important;"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
          <b style="color: gray">SPOC</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="9"
        >
          <v-select
            label="full_name"
            :multiple="true"
            :options="cityspoc"
            v-model="value1"
            style="width:450px; position: fixed; z-index:900;width : 30% !important;"
          />
        </vs-col>
         <vs-col
          vs-justify="center"
          vs-align="center"
          vs-w="1"
        >
        <vs-button color="dark" type="filled" @click="assignAPI" style="margin-left:-15px"
            >Assign</vs-button
          >  
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
export default {
  data() {
    return {
      open: false,
      decidebool: false,
      cityspoc: [],
      title: "Example",
      spoc_names: [],
      totalleadcount: "",
      value1: "",
      cities: [],
      cityselected: "",
      selected: "",
      deselected: "",
      allselect: "",
      sendobj: "",
      search: "",
      leadAssignCount: "",
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    EventBus.$on(
      "assign-iiml-lead-count",
      (
        payload,
        payload2,
        selectall,
        sendobj,
        totalleadcount,
        leadAssignCount
      ) => {
        //   this.open = true;
        console.log(payload);
        console.log(payload2);
        console.log(selectall);
        console.log(sendobj);
        console.log(totalleadcount);
        console.log(leadAssignCount);
        this.cities = [];
        this.getSpoc();
        this.decidebool = false;
        this.value1 = "";
        this.cityselected = "";
        this.totalleadcount = totalleadcount;
        this.selected = payload;
        this.deselected = payload2;
        let lengthstring = 0;
        this.allselect = selectall;
        this.sendobj = sendobj;
        this.leadAssignCount = leadAssignCount;
        this.filtersearch();
        if (selectall === true) {
          this.decidebool = true;
          lengthstring = totalleadcount - payload2.length;
          let title = "";
          title = `Assigning ${lengthstring} leads`;
          this.title = title;
        } else {
          lengthstring = payload.length;
          this.title = `Assigning ${lengthstring} lead`;
        }
        if (lengthstring > 1) {
          this.decidebool = true;
        }
        this.open = true;
      }
    );
  },
  watch: {
    cityselected(value) {
      this.filterspoc(value);
    },
  },
  methods: {
    filterspoc(city) {
      this.value1 = "";
      this.cityspoc = [];
      this.spoc_names.forEach((spoc) => {
        if (spoc.sub_team === city) {
          this.cityspoc.push(spoc);
          console.log("spoc name", spoc);
        }
      });
    },
    filtersearch() {
      let course = [];
      if (this.sendobj.courses !== "") {
        course = this.sendobj.courses.split(",");
      }
      let levels = [];
      if (this.sendobj.levels !== "") {
        /* iiml_levels for IIML */
        levels = this.sendobj.levels.split(",");
      }
      let bde = [];
      if (this.sendobj.spoc_ids !== "") {
        /* assigned_iiml_ids for IIML */
        bde = this.sendobj.spoc_ids.split(",");
      }
      // console.log(course);
      // console.log(levels);
      // console.log(bde);
      let bdenames = [];
      bde.forEach((id) => {
        // console.log(this.spoc_names);
        this.spoc_names.forEach((spoc) => {
          if (parseInt(id) === spoc.id) {
            bdenames.push(spoc.full_name);
          }
        });
      });
      // console.log(bdenames);
      this.search = course.concat(levels, bdenames);
      // console.log(this.search);
    },
    assignAPI() {
      this.$vs.loading({
        type: "default",
      });
      if (this.value1 === "") {
        this.$vs.notify({
          title: "Select a Spoc",
          text: "Select a Spoc to assign the selected leads",
          color: "danger",
        });
      } else {
        let selected = [];
        let deselected = [];
        if (this.allselect === true) {
          selected = [];
          deselected = [];
          this.deselected.forEach((each) => {
            deselected.push(each.id);
          });
        } else {
          selected = [];
          this.selected.forEach((each) => {
            selected.push(each.id);
          });
          deselected = [];
        }
        // let allselect = "";
        // if (this.allselect == true) {
        //   allselect = "yes";
        // } else {
        //   allselect = "no";
        // }
        let allselect = "yes";
        // this.sendobj.selected_lead_ids = selected.join();
        // if (this.sendobj.selected_lead_ids === "") {
        //   this.sendobj.selected_lead_ids = null;
        // }
        // this.sendobj.excluded_lead_ids = deselected.join();
        // if (this.sendobj.excluded_lead_ids === "") {
        //   this.sendobj.excluded_lead_ids = null;
        // }
        this.sendobj.selected_leads_ids = null;
        this.sendobj.excluded_lead_ids = null;
        // this.sendobj.spoc_name = this.value1.first_name;
        // let ids = [];
        // console.log(this.value1);
        // if (this.decidebool === false) {
        //   this.sendobj.spoc_id = this.value1.id;
        // } else {
        //   this.value1.forEach(value => {
        //     ids.push(value.id);
        //   });
        //   this.sendobj.spoc_id = ids.join();
        // }
        // let names = [];
        // if (this.decidebool === false) {
        //   this.sendobj.spoc_name = this.value1.first_name;
        // } else {
        //   this.value1.forEach(value => {
        //     names.push(value.first_name);
        //   });
        //   this.sendobj.spoc_name = names.join();
        // }

        let spocsNew = [];
        // if (this.decidebool === false) {
        //   this.sendobj.spocs = [
        //     { spoc_name: this.value1.first_name, spoc_id: this.value1.id }
        //   ];
        // } else {
        this.value1.forEach((value) => {
          let obj = {
            spoc_name: value.full_name,
            spoc_id: value.id,
          };
          spocsNew.push(obj);
        });
        this.sendobj.spocs = spocsNew;
        /* iiml_spocs for IIML */
        // }

        this.sendobj.select_all_leads_state = allselect;
        let url = `${constants.SERVER_API}assignLeadsIIML`;
        if (this.sendobj.my_focus) {
          this.sendobj.my_focus = this.sendobj.my_focus.toLowerCase();
        }
        this.sendobj.count = parseInt(this.leadAssignCount);
        if (this.sendobj.my_focus === "untapped without level") {
          this.sendobj.my_focus = "untapped_without_level";
        } else if (this.sendobj.my_focus === "untapped with level") {
          this.sendobj.my_focus = "untapped_with_level";
        }
        console.log('iiml assing count',this.sendobj, url);
        axios
          .post(url, this.sendobj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
            if (response.data.status === "success") {
              EventBus.$emit("reload-assignments");
              this.open = false;
            }
            this.$vs.loading.close();
            // console.log(response);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.sub_team,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
                if(key !== 'null' &&  key !== 'undefined'){
                    this.cities.push(key);
                }
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
