<template>
  <vs-popup :title="'Adding Enagement to ' + details.name" :active.sync="open">
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
        <vs-card>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <p>
                <strong>PREVIOUS ENGAGEMENT -</strong>
                {{lastengagement}}
              </p>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="4"
        style="font-size: 15px"
      >
        <h6 style="color: gray">Type:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-radio color="dark" v-model="typeSelction" vs-value="calls">CALLS</vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-radio color="dark" v-model="typeSelction" vs-value="emails">EMAILS</vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
        <vs-radio color="dark" v-model="typeSelction" vs-value="visit">VISIT</vs-radio>
      </vs-col>
    </vs-row>
    <vs-divider class="my-6"></vs-divider>
    <vs-row style="margin-bottom: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="2"
        style="font-size: 15px"
      >
        <h6 style="color: gray">Response:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="4">
        <vs-radio color="dark" v-model="responseSelection" vs-value="sameResponse">
          <p style="font-size:12px">SAME RESPONSE</p>
        </vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="flex-start" vs-w="4">
        <vs-radio color="dark" v-model="responseSelection" vs-value="noResponse">
          <p style="font-size:12px">NOT REACHABLE</p>
        </vs-radio>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-radio color="dark" v-model="responseSelection" vs-value="new">
          <p style="font-size:12px">NEW</p>
        </vs-radio>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
        <h6 style="color: gray">Engagement:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
        <vs-textarea label="ENGAGEMENT" v-model="textAre" />
      </vs-col>
    </vs-row>
    <vs-divider class="my-6"></vs-divider>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
        <h6 style="color: gray">Level:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
        <v-select
          label="Levels"
          style="z-index: 1000;  margin-top: 4%; margin: 10px;"
          class="w-full"
          placeholder="Select Templates"
          :options="labelOptions"
        ></v-select>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
        <h6 style="color: gray">Course:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-checkbox color="dark">CPA</vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-checkbox color="dark">CMA</vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-checkbox color="dark">CFA</vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-checkbox color="dark">FRM</vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <vs-checkbox color="dark">USP</vs-checkbox>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="3">
        <vs-checkbox color="dark">DA</vs-checkbox>
      </vs-col>
    </vs-row>
    <vs-row style="margin-bottom: 3%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="4">
        <h6 style="color: gray">Next follow up date:</h6>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="8">
        <datepicker placeholder="Select Date"></datepicker>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
        <vs-button color="danger" type="gradient" icon="done_outline" @click="open = false">ADD</vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
export default {
  components: {
    Datepicker,
    "v-select": vSelect
  },
  mounted() {
    EventBus.$on("add-engagement", payload => {
      this.details = payload;
      // console.log(this.details)
      this.responseSelection = "";
      this.textAre = "";
      this.lastengagement = "";
      // this.typeSelction = ''
      // this.courseSelection = ''
      this.engagementlast(this.details.engagement);
      this.open = true;
      this.typeSelction = "calls";
      this.courseSelection = "";

      EventBus.$emit("close-ne-popup", this.details);
    });
  },
  watch: {
    responseSelection: function(val) {
      switch (val) {
        case "sameResponse":
          this.textAre = this.lastengagement;
          break;
        case "noResponse":
          this.textAre = "Not Reachable";
          break;
        case "new":
          this.textAre = "";
          break;
      }
    }
  },
  data() {
    return {
      textAre: "",
      responseSelection: "",
      lastengagement: "",
      details: "",
      labelOptions: [
        { text: "M1", value: 1 },
        { text: "M2", value: 2 },
        { text: "M3", value: 3 },
        { text: "M4", value: 4 },
        { text: "L1", value: 5 },
        { text: "L2", value: 6 },
        { text: "L3", value: 7 },
        { text: "L4", value: 8 }
      ],
      typeSelction: "",
      courseSelection: "",
      open: false
    };
  },
  methods: {
    engagementlast(engagements) {
      this.lastengagement = engagements.slice(-1)[0];
    }
  }
};
</script>

<style>
/* .colspanclass {
  colspan: 12
} */
</style>
