<template>
  <vs-popup class="irpopupwidth" v-if="open" id="IRLead" title="ADD IR LEAD" :active.sync="open">
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Name*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="nameinput" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Email</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="emailinput" class="w-full" type="email" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Institution Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="companyinput" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Professor Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="professorinput" class="w-full" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Designation</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="designationinput" class="w-full" />
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Mobile*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="mobileinput" class="w-full" />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>City</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <v-select
                style="z-index: 1000;"
                class="w-full"
                placeholder="Cities"
                v-model="cityinput"
                :options="city"
                :reduce="text => text.text"
                label="text"
              ></v-select>
            </div>
            <div class="vx-col sm:w-1/6 w-full">
              <span>Engagement (optional)</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <vs-textarea v-model="engagementinput" />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/6">
              <span>Source*</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-radio
                v-model="radios2"
                class="inline-flex"
                vs-value="net_enquiry"
                style="margin-right: 20%;"
              >Net Enquiry</vs-radio>
              <vs-radio
                v-model="radios2"
                class="inline-flex"
                vs-value="referral"
                style="margin-right: 20%;"
              >Referral</vs-radio>
              <vs-radio v-model="radios2" class="inline-flex" vs-value="direct">Direct</vs-radio>
            </div>
            <div class="vx-col sm:w-1/6">
              <span>Next Call date</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <datepicker placeholder="Select Date" v-model="dateinput"></datepicker>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="toggle">
            <div class="vx-col sm:w-1/6">
              <span>Reference</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-radio v-model="radios1" vs-value="ewb" style="margin-right: 30%;">EWB</vs-radio>
              <vs-radio v-model="radios1" vs-value="mwb" style="margin-right: 23%;">MWB</vs-radio>
              <vs-radio v-model="radios1" vs-value="others">Others</vs-radio>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="ewb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <v-select
                style="width:300px; z-index:1000; position:fixed"
                placeholder
                v-model="source_details"
                :options="ewb_names"
                :reduce="label => label.label"
                label="label"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="mwb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Search With :</span>
            </div>
            <div class="vx-col sm:w-1/3">
             <div>
            <span><vs-radio v-model="searchType" vs-name="searchType" vs-value="Name">Name</vs-radio>&nbsp;&nbsp;</span>
            <span>&nbsp;&nbsp;<vs-radio v-model="searchType" vs-name="searchType" vs-value="Identity">Can-ID</vs-radio></span>
          </div>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="mwb_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <v-select
                style="width:300px; z-index:1000; position:fixed"
                :filterable="false"
                :options="mwb_names"
                @search="autoCompleteName"
                v-model="source_details"
              ></v-select>
            </div>
          </div>
          <div class="vx-row mb-6" v-show="others_reference">
            <div class="vx-col sm:w-1/6">
              <span>Name</span>
            </div>
            <div class="vx-col sm:w-1/3">
              <vs-input v-model="source_details" class="w-full" />
            </div>
          </div>
          <div class="vx-row mb-6" v-show="socialMedia">
            <div class="vx-col sm:w-1/6 w-full">
              <span>Direct</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                style="width:300px; z-index:1000; position:fixed"
                placeholder
                v-model="source_details"
                :options="social_media"
                :reduce="text => text.text"
                label="text"
              ></v-select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button
                class="mr-3 mb-2 float-right"
                color="danger"
                type="gradient"
                @click="B2BIRAPI"
              >Submit</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    "v-select": vSelect,
    Datepicker
  },
  data() {
    return {
       searchType:'Name',
      options1: [
        { text: "", value: "" },
        { text: "", value: 0 },
        { text: "B.Com", value: 0 },
        { text: "B.Com(P)", value: 18 },
        { text: "M.Com", value: 1 },
        { text: "M.Com(P)", value: 19 },
        { text: "MBA", value: 2 },
        { text: "MBA(P)", value: 20 },
        { text: "CA", value: 3 },
        { text: "CWA", value: 4 },
        { text: "CS", value: 5 },
        { text: "BMS", value: 6 },
        { text: "BMS(P)", value: 21 },
        { text: "BBA", value: 7 },
        { text: "BBA(P)", value: 17 },
        { text: "PGD", value: 8 },
        { text: "PGD(P)", value: 22 },
        { text: "LLB", value: 9 },
        { text: "LLB(P)", value: 23 },
        { text: "B.Tech", value: 10 },
        { text: "B.Tech(P)", value: 24 },
        { text: "B.Sc", value: 11 },
        { text: "B.Sc(P)", value: 25 },
        { text: "BA", value: 12 },
        { text: "BA(P)", value: 26 },
        { text: "CA-Inter", value: 13 },
        { text: "CWA-Inter", value: 14 },
        { text: "CS-Inter", value: 15 },
        { text: "PG(P)", value: 16 },
        { text: "CA Final", value: 16 },
        { text: "CWA Final", value: 16 },
        { text: "CS Final", value: 16 },
        // { text: "IGNOU", value: 16 },
        { text: "IGNOU - B.Com (CA)", value: 16 },
        { text: "IGNOU - B.Com (CWA)", value: 16 },
        { text: "IGNOU - B.Com (CS)", value: 16 },
        { text: "MCA", value: 16 },
        { text: "PGDPA", value: 16 },
        { text: "PGDSF", value: 16 },
        { text: "PGDM", value: 16 },
        { text: "CFA", value: 16 },
        { text: "FRM", value: 16 },
        { text: "USP", value: 16 },
        { text: "CFA(P)", value: 16 },
        { text: "MFA", value: 16 },
        { text: "MFA(P)", value: 16 },
        { text: "CMA(US)", value: 16 },
        { text: "CPA(US)", value: 16 }
      ],
      toggle: false,
      socialMedia: false,
      ewb_reference: false,
      mwb_reference: false,
      others_reference: false,
      radios2: "",
      titlebatch: "",
      radios1: "",
      open: false,
      check1: [],
      check2: "",
      check3: "",
      name: "",
      email: "",
      mobile: "",
      levels: [
        { text: "", value: "" },
        { text: "L1", value: 1 },
        { text: "L2", value: 2 },
        { text: "L3", value: 3 },
        { text: "L4", value: 4 },
        { text: "L5", value: 5 },
        { text: "L6", value: 6 },
        { text: "L7", value: 7 },
        { text: "M1", value: 8 },
        { text: "M2", value: 9 },
        { text: "M3", value: 10 },
        { text: "M4", value: 11 },
        { text: "M5", value: 12 },
        { text: "M6", value: 13 },
        { text: "M7", value: 14 }
      ],
      course: "",
      company: "",
      designation: "",
      education: "",
      experience: "",
      nameAutocomplete: [],
      city: [
        { text: "", value: "" },
        { text: "Hyderabad", value: 1 },
        { text: "Delhi", value: 2 },
        { text: "Mumbai", value: 3 },
        { text: "Chennai", value: 4 },
        { text: "Bengaluru", value: 5 },
        { text: "Ernakulam", value: 6 },
        { text: "Ahmadabad", value: 7 },
        { text: "Jaipur", value: 8 },
        { text: "Pune", value: 9 },
        { text: "Kolkata", value: 10 }
      ],
      social_media: [
        { text: "", value: "" },
        { text: "Google Ads", value: 1 },
        { text: "Twitter", value: 2 },
        { text: "Whatsapp", value: 3 },
        { text: "Youtube", value: 4 },
        { text: "SMS", value: 5 },
        { text: "Facebook", value: 6 },
        { text: "Linkedin", value: 7 },
        // { text: "Whatsapp", value: 8 },
        { text: "Website", value: 9 },
        { text: "Hordingd", value: 10 },
        { text: "Word of mouth", value: 11 },
        { text: "Corpoatres", value: 12 },
        { text: "University", value: 13 },
        { text: "CA Activity", value: 14 }
      ],
      miles_employees: [
        { text: "", value: "" },
        { text: "Rohini Sripada", value: 1 },
        { text: "Akanksha Singh", value: 2 },
        { text: "Rohan Chopra", value: 3 },
        { text: "Allaka Rao", value: 4 },
        { text: "Vishal Parmar", value: 5 },
        { text: "Anoop Sharma", value: 6 },
        { text: "Niraj Sachdev", value: 7 },
        { text: "Amrita Thapar", value: 8 },
        { text: "Neha Sidana", value: 9 },
        { text: "Maria Divya", value: 10 },
        { text: "Preeti Pawar", value: 11 },
        { text: "Hadi Rajani", value: 12 },
        { text: "Vidya Kaushaley", value: 13 },
        { text: "Vikas Khosla", value: 14 }
      ],
      ewb_names: [
        { text: "", value: "" },
        { label: "kiran - CPA-M7-01", value: 1 },
        { label: "Rafi - CPA-M7-02", value: 2 },
        { label: "Siva - CPA-M7-03", value: 3 },
        { label: "Sampath - CPA-M7-04", value: 4 },
        { label: "Rani - CPA-M7-05", value: 5 },
        { label: "Anusha - CPA-M7-06", value: 6 },
        { label: "Usha - CPA-M7-07", value: 7 }
      ],
      mwb_names: [],
      textarea: "",
      nameinput: "",
      levelinput: "",
      institutioninput: "",
      professorinput: "",
      additionalinput: "",
      spocinput: "",
      coursesinput: "",
      companyinput: "",
      designationinput: "",
      experienceinput: "",
      engagementinput: "",
      dateinput: "",
      educationinput: [],
      cityinput: "",
      eligibilityinput: "",
      sourceinput: "",
      source_details: "",
      emailinput: "",
      mobileinput: "",
      url: "",
      post_API_obj: "",
      untrackedcall: false
    };
  },
  mounted() {
    EventBus.$on("open-AddLeadIRPopup", () => {
      this.nameinput = "";
      this.levelinput = "M2";
      this.coursesinput = "";
      this.companyinput = "";
      this.designationinput = "";
      this.experienceinput = "";
      this.source_details = "";
      this.educationinput = [];
      this.engagementinput = "";
      this.dateinput = "";
      this.cityinput = "";
      this.eligibilityinput = "";
      this.sourceinput = "";
      this.emailinput = "";
      this.mobileinput = "";
      this.corporateList = false;
      this.universityList = false;
      this.toggle = false;
      this.socialMedia = false;
      this.ewb_reference = false;
      this.mwb_reference = false;
      this.others_reference = false;
      this.radios2 = "";
      this.titlebatch = "";
      this.radios1 = "";
      this.check1 = [];
      this.open = true;
      this.untrackedcall = false;
      this.getSpoc();
    });
    EventBus.$on("pre-filled-b2bir", payload => {
      this.nameinput = "";
      this.levelinput = "M2";
      this.coursesinput = "";
      this.companyinput = "";
      this.designationinput = "";
      this.experienceinput = "";
      this.source_details = "";
      this.educationinput = [];
      this.engagementinput = "";
      this.dateinput = "";
      this.cityinput = "";
      this.eligibilityinput = "";
      this.sourceinput = "";
      this.emailinput = "";
      this.mobileinput = "";
      this.corporateList = false;
      this.universityList = false;
      this.toggle = false;
      this.socialMedia = false;
      this.ewb_reference = false;
      this.mwb_reference = false;
      this.others_reference = false;
      this.radios2 = "";
      this.titlebatch = "";
      this.radios1 = "";
      this.check1 = [];
      this.mobileinput = payload.mobile_number;
      this.untrackedcall = true;
      this.getSpoc();
      // this.url = payload.url
    });
    // document.getElementById("IRLead").children[1].style.width = "70%";
  },
  methods: {
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          response.data.spocs.forEach(spoc => {
            if (spoc.id === response.data.logged_in_user_id) {
              this.cityinput = { text: spoc.city };
            }
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      // console.log('what are we searching for', search)
      loading(true);
      let searchtype = this.searchType;
      this.fetchMwbs(loading, search,searchtype, this);
    },
    fetchMwbs: _.debounce((loading, search,searchtype, vm) => {
      let url = `${constants.SERVER_API}searchMwbNameForReferenceWEB?name=${escape(
				search
			)}&type=${searchtype}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          vm.mwb_names = response.data.map(
            lead => `${lead.identity} - ${lead.person_name} - ${lead.level}`
          );
          loading(false);
        });
    }, 350),
    async B2BIRAPI() {
      if (
        this.nameinput === "" ||
        this.mobileinput === "" ||
        this.source === "" ||
        this.source_details === ""
      ) {
        this.$vs.notify({
          title: "Fill all the required forms",
          text: "please fill all the required forms to continue adding a lead",
          color: "danger",
          position: "top-center"
        });
      } else {
        switch (this.untrackedcall) {
          case true:
            this.url = `${constants.SERVER_API}saveUntrackedToNewLead`;
            this.post_API_obj = {
              phone_number: this.mobileinput,
              name: this.nameinput,
              type: "B2BIR",
              email: this.emailinput,
              courses: this.check1.join(),
              level: this.levelinput,
              company: this.companyinput,
              designation: this.designationinput,
              experience: this.experienceinput,
              education: this.educationinput,
              eligibility: this.eligibilityinput,
              city: this.cityinput.text,
              source: this.sourceinput,
              source_details: this.source_details,
              next_call: new Date(this.dateinput).getTime() / 1000,
              details: this.engagementinput,
              acads: 0
            };
            break;
          case false:
            this.url = `${constants.SERVER_API}addMwb`;
            this.post_API_obj = {
              name: this.nameinput,
              level: this.levelinput,
              courses: this.check1.join(),
              type: "B2BIR",
              company: this.companyinput,
              designation: this.designationinput,
              experience: this.experienceinput,
              education: this.educationinput,
              city: this.cityinput.text,
              eligibility: this.eligibilityinput,
              source: this.sourceinput,
              source_details: this.source_details,
              emails: this.emailinput,
              mobiles: this.mobileinput,
              details: this.engagementinput,
              acads: 0,
              next_call: new Date(this.dateinput).getTime() / 1000
            };
            break;
        }
        console.log(this.url);
        console.log(this.post_API_obj);
        axios
          .post(this.url, this.post_API_obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            if (response.data.status === "success") {
              this.open = false;
              this.$vs.notify({
                time: 20000,
                title: "Response",
                text: response.data.message,
                color: "success",
                position: "top-center",
                click: () => {}
              });
            } else if (response.data.status === "error") {
              this.$vs.notify({
                time: 20000,
                title: "Error",
                text: response.data.message,
                color: "danger",
                position: "top-center",
                click: () => {}
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    }
  },
  watch: {
     searchType(val){
      if(val === ""){
      this.mwb_names = []
      this.source_details = ""
      }
    },
    open: function(value) {
      if (value === false) {
        EventBus.$emit("reloadEntireData");
      }
    },
    radios2: function(value) {
      // console.log(value)
      switch (value) {
        case "direct":
          this.socialMedia = true;
          this.toggle = false;
          this.ewb_reference = false;
          this.mwb_reference = false;
          this.others_reference = false;
          this.sourceinput = "Direct";
          this.source_details = "";
          break;
        case "referral":
          this.sourceinput = "Referral";
          this.source_details = "";
          this.toggle = true;
          this.socialMedia = false;
          break;
        case "net_enquiry":
          this.sourceinput = "Net Enquiry";
          this.source_details = "Net Enquiry";
          this.toggle = false;
          this.socialMedia = false;
          this.ewb_reference = false;
          this.mwb_reference = false;
          this.others_reference = false;
          break;
      }
    },
    radios1: function(value) {
      // console.log(value)
      switch (value) {
        case "ewb":
          this.ewb_reference = true;
          this.source_details = "";
          this.mwb_reference = false;
          this.others_reference = false;
          break;
        case "mwb":
          this.source_details = "";
          this.ewb_reference = false;
          this.mwb_reference = true;
          this.others_reference = false;
          break;
        case "others":
          this.source_details = "";
          this.ewb_reference = false;
          this.mwb_reference = false;
          this.others_reference = true;
          break;
      }
    }
  }
};
</script>
<style>
.irpopupwidth .vs-popup {
  min-width: 98%;
}
</style>
