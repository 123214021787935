<template>
    <div style="color: #2c2c2c">
        <vs-row class="heading_section" style="
            padding: 4rem 2rem;
            background-image: url(https://milesforce.com/img/vuesax-login-bg.04351a33.jpg);
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            ">
            <vs-col vs-w="12">
                <div>
                    <h1 class="mb-4">Welcome</h1>
                    <p>Hi, this is <b>{{ spoc_name }}</b> from Miles. This call is regarding the enquiry you have made with
                        us. Am I talking to <b>{{ studentDetails.person_name }}</b>? <br><br>
                        Miles has an amazing pathway for Indian accountants to work in the US! Are you interested in going to the US in Jan’25 ?"<br><br>
                        and the best part is that you will start work in the US as early as 2025!<br><br>
                        Let me explain!<br><br>
                        In 2024, you will obtain your visa.<br>
                        - By 2025, you will complete your MS accounting Stem and start work in the US!<br>
                        - In 2026, 2027, and 2028, you will have the opportunity to work in the US!<br><br>
                        What are your thoughts on this pathway?<br><br>
                        - To proceed further, can you please let me know if you are a working professional or a student?<br>
                        - What’s your year of graduation? 

                    </p>
                </div>
            </vs-col>
        </vs-row>
        <!-- <vs-row class="mt-3 row_data">
            <vs-col vs-w="12">
                <p class="mt-3">If no,
                        Let me discuss the career opportunities in India.</p>
                <h4 class="mb-4 mt-3"><b>Profiling :</b></h4>
                <ul>
                    <li> To proceed further, could you please let me know if you are a working professional or a student?
                        (If a student or a fresher, college name is mandatory)
                    </li>
                    <li> If a working professional, which company do you work for? What’s your total years of experience?
                        What is your current designation?
                    </li>
                    <li> Do you have any professional qualifications?
                    </li>
                    <li>What is your academic qualification? </li>
                    <li>Where are you based out of?
                    </li>
                </ul>
            </vs-col>
        </vs-row> -->
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p><b>Miles US Pathway - “YES”:</b></p><br>
                <p><b>First, let me inform you that there is a huge shortage of accountants in the US.</b></p><br>
                <ul>
                    <li><b>Are you on your system now?</b> Can you go to google and search with keywords “Accountant
                        shortage in the US”.
                    </li>
                    <li>Want to let you know that Miles has partnered with more than 350 accounting firms in the US who want
                        to hire Indian accountants with a minimum salary of INR 50 lakh.
                    </li>
                </ul>

            </vs-col>

        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4"><b>Do you have a family or friend who is an engineer and working in the US?
                    </b></p>
                <ul>
                    <li>So Indian engineers do B.Tech in India. Then go to the US for a 1 year MS in Computer Science. After
                        that, they get a 3-year work permit in the US.
                    </li>
                    <li>This is because MS Computer Science is a STEM program. STEM stands for Science, Technology,
                        Engineering, Maths
                    </li>
                    <li>Similarly, Miles has cracked the puzzle for Indian accountants to work in the US.
                    </li>
                    <li>Miles is working with US universities to convert their MS Accounting into STEM by adding business
                        analytics into the curriculum. <b>So it becomes MS Accounting with Analytics – a STEM program –
                            which now allows accountants the same 3 year work permit like engineers.</b>
                    </li>
                </ul>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data  ">
            <vs-col vs-w="12" >
                <p class="mb-4">
                    <span>For the current intake of January 2025 we will be closing the admission process within a few days.</span><br>
                    <span>In order for me to proceed further your application you would have to visit my office and meet the General Manager so we can discuss your eligibility for the Miles US Pathway</span>
                </p>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <!-- <p class="mb-4">We have a dedicated US visa and placement team who will hand
                    hold you through this process. We have a <b>US visa assistance fee of 20,000 rupees</b> which you can
                    enroll for. This amount is <b>fully refundable</b> until you get your US visa - so if you decide to drop
                    or you do not receive your US visa for any reason, a full refund will be granted. No questions asked.
                    &#60;Additional point (optional) - 4 situations to clarify the refund scenarios:
                </p> -->
                <!-- <ol>
                    <li>
                        If you change your mind and you don’t want to go to US, you can ask for the refund
                    </li>
                    <li>
                        If you are not eligible, we will issue a refund
                    </li>
                    <li>
                        If the seats are full, you can either defer to the next batch or take a refund
                    </li>
                    <li>
                        If your US visa gets rejected which chances are pretty less, this fee will be refunded>
                    </li>
                </ol> -->
                <p class="mt-3">
                    <!-- Also, the number of applications for the US are too many and it will be first come, first 
                    served. For 2024, applications will be closing soon. We are also having our recruitment drive on 
                    21st January 5 PM , Please register from our website and be a part of it.<br><br>
                    I am sending the payment link – send me the screenshot once the payment is done – I will then create
                    your Enrollment Form and the US placement team will connect with you. <br><br> -->
                    &#60;For any questions, rebuttal is: Why don’t you go to linkedin and follow Varun’s handle. You will
                    see so many of Miles candidates in the US and you will get all your answers.>
                </p>

            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p><b>Miles US Pathway - L1 FAQs:</b></p><br>

                <p class="mb-4">Additional details [removed from main pitch]:
                </p>
                <ul>
                    <li>
                        Miles has partnered with more than 350 public accounting firms in the US. They are ready to hire
                        Indian accountants at a minimum salary of 50
                        lakh rupees – that is $60,000. Are you on your system now - can you type “accountant shortage in the
                        US” on google?
                    </li>
                    <li>Then we have 20+ partner universities in the US. They are converting MS Accounting into STEM so that
                        you can have the 3 year work permit. Also, Miles is getting them to reduce tuition fees by 50% to
                        70%. We have a credit-transfer model and we are getting bulk scholarships as Miles is giving a
                        full-class commitment. So at every university, full batches of 30 to 60 Miles alumni are going.

                    </li>
                    <li>Bottom line is that  you can now work in the US and live your American dream. Miles will take care of 
                        your US visa, your US university admission and your US job.
                    </li>
                    <li>&#60;Additional points - optional>
                        <ul style="list-style-type:circle;">
                            <li>You will not be going alone. Full Miles batches are going to each university. In 2025, more than 3000+ Miles alumni will be going to the US.
                            </li>
                            <li>You only have a very small amount of upfront investment. About 2 lakh rupees. Over and above
                                that, you will get loans for all your tuition and stay expenses – anyways on university fees
                                you are getting 50-70% discounts. And you need to pay the loan only once you start working -
                                wherein you will be earning more than 50 lakhs every year.
                            </li>
                        </ul>
                    </li>
                </ul>

            </vs-col>
        </vs-row>
        <!-- <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4"><b>Next steps / questions - IF YOU SEE INTEREST [IF NOT INTERESTED, MOVE TO INDIA PITCH]:
                    </b>

                </p>
                <p>We have a dedicated US visa and placement team who will hand hold you through this process.
                </p>
                <p class="mt-5">We have a US visa assistance fee of 20,000 rupees which you can enroll for. This amount is
                    fully
                    refundable until you get your US visa – so if you decide to drop or you do not receive your US visa for
                    any reason, a full refund will be granted. No questions asked.
                </p>
                <p>(Additional point (optional) - 4 situations to clarify the refund scenarios:
                </p>
                <ol class="mt-5">
                    <li>If you change your mind and you don’t want to go to US, you can ask for the refund
                    </li>
                    <li>If you are not eligible, we will issue a refund
                    </li>
                    <li>If the seats are full, you can either defer to the next batch or take a refund
                    </li>
                    <li>If your US visa gets rejected which chances are pretty less, this fee will be refunded)
                    </li>

                </ol>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4">Also, the number of applications for the US are too many and it will be first come, first
                    served.
                </p>
                <ul>
                    <li>For the Jan 2024 batch, applications will be closed by August 31.
                    </li>
                    <li>For the Aug 2024 batch, applications will be closed by September 30.
                    </li>
                    <li>Then we may stop accepting applications. So if you are serious about the US, start now. The iron is
                        very hot now due to the accountant shortage.
                    </li>
                </ul>
                <p class="mt-5">I am sending the payment link – send me the screenshot once the payment is done – I will
                    then create your
                    Enrollment Form and connect you to the US placement team. (If not too excited, invite to office)
                </p>



            </vs-col>
        </vs-row> -->
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4">
                    <b>Why? - Jobs</b>
                </p>
                <ul>
                    <li><b>How is this adding up? Are there jobs in India or the US?
                        </b>
                        <ul style="list-style-type:circle;" class="mt-5">
                            <li>There’s a huge shortage of accountants in the US. Leading to 2 key opportunities:
                                <ol>
                                    <li>There is extensive outsourcing of US work to India by Big 4 & MNCs. And they want to
                                        hire tens of thousands of CPAs in their India teams. Starting CPA salary is INR 8
                                        lakhs and much more if you have experience.
                                    </li>
                                    <li>Miles has cracked the puzzle for Indian accountants to work in the US. Where your
                                        starting salary is $60,000 or 50 lakh rupees.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Is Masters in the US mandatory? Can I work in the US with CPA alone?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">CPA is a professional qualification and does not grant you any US visa. To get
                                the visa to work in the US for 3 years, you have to do a Masters with STEM. That’s exactly
                                what the engineers from India also do.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Is CPA mandatory for me to pursue a Masters in Accountancy?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">For you to get the US F1 visa, Masters is a must.
                            </li>
                            <li>CPA is a good add-on which will set you up for success in the US.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Which visa will I get?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">You will be getting the US F1 visa. On this visa you will study for 1 year and
                                be able to work for 3 years. So a total of 4 years in the US.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What is OPT?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">OPT stands for Optional Practical Training. It is as good as full-time work
                                experience with full pay. They use the word training as this allows you to work on your F1
                                student visa.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>How is Miles helping us?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Jobs in the US
                            </li>
                            <li>University fees reduced by more than 50%
                            </li>
                            <li> Full cohorts from Miles going as a group - which means you’re not going to be alone. Also,
                                we will support you for your university admission, student loan and most important your US
                                visa
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What is my salary in the US?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Starting pay is $60,000 or 50 lakhs. This is 5x of India fresher CPA salary of
                                10 lakhs. &#60;Do not talk about any salary amount more than $60,000 even if the candidate
                                is
                                experienced. We should not over-promise>
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What are the job roles / designations?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Most of the job placements will be with public accounting firms in audit, tax
                                or accounting roles.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Does Miles provide a job guarantee?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Miles does placement assistance. We have a track record of 100% placements.
                                Generally, if we have a doubt about you getting a job, the US placement team will not even
                                process your application and will refund you the 20K.
                            </li>
                            <li>Anyways with the accountant shortage, we are very confident of getting you placements in the
                                US.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Big 4 jobs?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">We are actually seeing a lot more excitement with mid-tier public accounting
                                firms. With the accountant shortage in the US, Big 4 are doing extensive outsourcing to
                                India. But mid-tier firms are not so interested in outsourcing and they want to hire
                                accountants in the US.

                            </li>
                            <li>And, as a matter of fact, you will have more growth opportunities with these mid-tier public
                                accounting firms. Also, all the mid-tier firms empanelled with Miles have agreed on pursuing
                                the H1B sponsorship for Miles candidates.
                            </li>
                            <li>
                                But with Big 4, they mostly use their H1B sponsorship quota for engineers in their
                                consulting teams. So mid-tier firms may be better as they are happy to sponsor your H1B.

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What happens after 3 years of OPT?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">1.Plan A- Miles is working with firms to sponsor your H1Bs during those 3 years. If that happens, you get another 6 years work permit.<br>
                                2.Plan B-Cap - exempt HIB*. These 2 Plans will allow you to continue working in the US with another 6 years work permit.
                            </li>
                            <li>Lead Indian Team of the same firm , If the above does not happen, after 3 years you can return back to India. 
                            </li>
                            <li>Placement at Big 4 or MNC by Miles<br>
                                Given that you will have US exposure, many of the US firms will be happy to hire you in India and Miles will take care of your India placements.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Tell me more about the H1B sponsorship?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">While you are working in the US for 3 years, the Miles partner firms may
                                sponsor your H1B which is the work visa and will allow you an extra 6 years of work in the
                                US. And lead to a green card.
                            </li>
                            <li>For H1B there are 3 steps:
                                <ol>
                                    <li class="mt-5">Your firm has to sponsor you and nominate you for the H1B annual
                                        lottery.
                                    </li>
                                    <li>You need to be selected in the lottery. In the lottery, there are 2 quotas - 1st  is general quota and 2nd is special quota for those with Masters in the US.  You will
                                        essentially be eligible for both quotas so you will have 2 chances every year. So 6
                                        chances across 3 years. Exactly like the engineers.
                                    </li>
                                    <li>Once you are selected in the lottery, the visa officer will check whether your
                                        degree matches with the job profile. And bingo, your Masters in Accounting will be a
                                        perfect match for audit, tax or accounting job roles. So you are sorted.
                                    </li>


                                </ol>


                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What are the chances of getting a H1B visa?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Let’s say you have a 20% chance every year. So 60% chance of getting a H1B visa in 3 years. We also have Plan B available where you can opt for a HIB cap-exempt visa.
                            </li>
                            <li>But to clarify, Miles is only assuring a 4-year stint in the US - 1 year of study and 3 years of work on the F1 student visa.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Why USA (not Canada / UK / Australia)?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Firstly, the US is the world’s largest economy with a GDP of more than 25.46 trillion dollars. Compare that with the UK, Canada or Australia - all of which have GDP of 3 trillion dollars or less. So all these are even smaller than India.
                            </li>
                            <li>Secondly, in the US, there is an accountant shortage and Miles has a dedicated US placement
                                team for jobs in the US.
                            </li>
                            <li>In short, Miles can only help you with India or US placements - we don’t have a placement
                                team for any other country.
                            </li>
                        </ul>
                    </li>

                    <li class="mt-5"><b>Only 3 years of university education?
                        </b>
                        <p>Generally, you need to have completed 4 years of university education to be eligible for the miles US pathway (basically the US undergrad requirement) you are all set if you have B.Com/BBA/+M.Com/MBA or  CA/CS/CWA
                        <br><br>
                        But if you are a B.com/BBA ,don’t worry!<br>
                        We have a university Bridge course (Arka Jain) for you at a cost of only INR 30K.
                        The best part is that this is fully integrated with the CPA coursework-which means no extra studies over-and -above CPA prep(that’s why this is available only for the candidates who have the access to the Miles CPA study materials)
                        </p>
                    </li>
                </ul>
            </vs-col>

        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4"><b>How? - University</b>
                </p>
                <ul>
                    <li><b>What can go wrong - “20K enrollments”?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Things that can go wrong:
                                <ul>
                                    <li>You change your mind. You decide that you want to stay back and work in India, and
                                        not go to the US.
                                    </li>
                                    <li>The Miles US placement team finds you ineligible. They typically check your English
                                        communication skills and your attitude. Plus your education qualifications.
                                    </li>
                                    <li>You don’t get accepted into US universities. Generally, the US universities accept
                                        most candidates who are approved by the Miles US placement team so this may not be a
                                        big worry.
                                    </li>
                                    <li>You may not get your student loan. Now, we work with both India and US lenders. The
                                        US lenders are much more lenient - they issue the loan basis of your university
                                        offer letter. They are not looking for any collateral or co-applicant. But if your
                                        credit score is not good enough, even the US lender may back out.
                                    </li>
                                    <li>Your US visa may get rejected. Now, Miles has more than 90% visa acceptance rate but
                                        there is still a chance of rejection.
                                    </li>

                                </ul>
                            </li>
                            <li>In any of these 5 situations, the 20K fees that you pay will be refunded.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Which university? Can I choose?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Miles is working with <b>30+ universities</b>. Like Michigan State University which is
                                an IIM-level
                                ranked university in the US. We also have location specific like Rutgers University near New
                                York. We also have other universities which have much lower fees. Miles is assisting with
                                jobs, so choice of university is not so critical and actually does not matter. Anyways, the
                                US placement team will give you more information and guide you.
                            </li>
                        </ul>

                    </li>
                    <li class="mt-5"><b>Why is the program so expensive? How is Miles helping in reducing cost?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Firstly, Miles is helping US universities convert MS Accounting into STEM so
                                that you can
                                have the 3 year work permit.

                            </li>
                            <li>Also, Miles is getting them to reduce tuition fees by nearly 50%. Sometimes even 70%. How?<br>
                                - We have a credit-transfer model from JagSom whereby 30% of the course is happening in India.<br>
                                - We are getting bulk scholarships and discounts as Miles is giving a full-class commitment. So at every university, full batches of 30 to 60 Miles alumni are going. 
                                
                            </li>
                        </ul>

                    </li>
                    <!-- <li class="mt-5"><b>Why IIM Indore?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Reduces US university fees as 30% of the coursework is happening from India.
                                Since IIM
                                Indore is AACSB-accredited, 9 credits out of 30 credits are transferred from IIM Indore to
                                the respective US university.
                            </li>
                            <li>Reduce the time and US stay cost. Generally, it takes 1.5 years to complete the US masters.
                                Since you are completing 30% of your coursework in India, you will be able to complete the
                                Masters’ in 1 year and your cost of staying in the US reduces by about 6 months.
                            </li>
                            <li>Given the reputation of IIM Indore, you get GMAT/GRE as well as IELTS waivers.
                            </li>
                        </ul>

                    </li> -->
                    <li class="mt-5"><b>What is the syllabus for Masters in Accounting?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">The Masters in Accounting has 2 kinds of courses: (i) Approx 60% accounting
                                courses, (ii)
                                Approx. 40% analytics courses

                            </li>
                            <li>Accounting courses overlap withC PA subjects.

                            </li>
                            <li>Analytics courses are needed for the program to qualify as STEM. Plus you learn tools like
                                Excel, Tableau and Power BI which will help you in your career.

                            </li>
                        </ul>

                    </li>
                    
                    <li class="mt-5"><b>What are the timelines?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Step 1 - University offer letter - takes about 15 days after submission
                            </li>
                            <li>Step 2 - Loan sanction letter - another 15 days
                            </li>
                            <li>Step 3 - i20 from the US university - another 15 days
                            </li>
                            <li>Step 4 - JagSom
                            </li>
                            <li>Step 5 - US visa which happens about 90 days before your US university start date
                            </li>
                            <li>Step 6 - Miles US placements which will starts immediately after you get your US visa (even
                                before you land in the US)
                            </li>
                            <li>Step 7 - You finally land in the US. Which will be Jan 2025.
                            </li>


                        </ul>

                    </li>
                    <li class="mt-5"><b>What is the cost of the program?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Total cost will be about $45,000 - around INR 35 lakhs. In this, you only need to have 2 lakhs and for the remaining 40 lakhs you will get a loan. After you graduate, you will earn $60,000 or INR 50 lakhs per year and you can easily pay off this loan in less than 3 years.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What are the chances of my loan approval? What if my loan gets rejected? Does the
                            loan cover 100%
                            of my expenses?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Your total cost will be approx. $50,000 or INR 42 lakhs.

                            </li>
                            <li>You need to have about INR 2 lakhs for your upfront expenses. Like Miles fees of INR 20,000,
                                US university advance deposit, US visa fees and US flight ticket.
                            </li>
                            <li>Rest INR 40 lakhs will be loan.

                            </li>
                            <li>For loans, we work with both India and US lenders. The US lenders are much more lenient -
                                they issue the loan basis of your university offer letter. They are not looking for any
                                collateral or co-applicant. But your credit score has to be good.

                            </li>
                            <li>If your loan gets rejected, you can drop out and your full fees of 20K which you have paid
                                to Miles will be refunded back.

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Can I work while studying? Or do an internship?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">During your Masters’ you are legally not allowed to work. You are only allowed
                                to do
                                on-campus jobs for 20 hours every week.

                            </li>
                            <li>Miles does not assure this but if you are able to land up an on-campus job, you can easily
                                earn about $10,000. Which will reduce your overall cost from $50,000 to about $40,000.

                            </li>

                        </ul>
                    </li>
                    <li class="mt-5"><b>Is a Masters in Accountancy only available in the US? Can I study online?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">You have to study in the US only. Only exception that Miles is able to achieve is 30% of your coursework which happens at Jagsom in online mode.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What about my accommodation in the US?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">A full batch of more than 30 candidates are going to each of the US
                                universities. So you can
                                identify your fellow Miles students and take up sharing apartments to reduce your cost of
                                stay in the US.
                            </li>
                            <li>There are many accommodation options available near each and every university and Miles team
                                will guide you on how to search.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>What if I don't complete my Masters in Accountancy?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">You can extend a semester to complete any backlogs. But this will increase your
                                overall
                                cost.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Why MS Accounting and not MS Finance or MBA Finance?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Jobs are in accounting (not in finance). For accounting, we have 100%
                                placements. We had 5
                                students who chose MS Finance on their own and till date none of them have got jobs.

                            </li>
                            <li>Also for H1B visa, the visa officer will match your job to your degree. So if you are doing
                                an accounting, audit or tax job with a finance degree or MBA degree, your visa will be
                                rejected.
                            </li>
                        </ul>
                    </li>
                </ul>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4"><b>What? - Candidate-specific
                    </b>

                </p>
                <ul>
                    <li class="mt-5"><b>What are the other pathways Miles provides other than Masters in Accountancy in the
                            US? </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Besides US placements, Miles offers India placements.  No other country. 
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Why should I pay 20K to Miles?</b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">We have a US visa assistance package of 20,000 rupees which you can enroll for.
                                This amount
                                is fully refundable until you get your US visa – so if you decide to drop or you do not
                                receive your US visa for any reason, a full refund will be granted.

                            </li>
                            <li>3 situations for refund:
                                <ul style="list-style-type:square;">
                                    <li class="mt-5">If you change your mind and you don’t want to go to US
                                    </li>
                                    <li>If you are not eligible, we will issue a refund
                                    </li>
                                    <li>If your US visa gets rejected, we will refund
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Why should I choose this particular intake cohort? 
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">The iron is very hot now with the accountant shortage in the US. So this is a
                                very good time
                                to go. I will only say - Sooner the better.

                            </li>
                            <li>Also we have more applications than seats - it will be first come, first served

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>If my visa gets rejected, what next?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Miles' team will guide on visa application and visa interview. So 90%+ chances
                                of getting a
                                visa in the 1st attempt.
                            </li>
                            <li>In case of rejection, we can do a 2nd attempt.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Will I get a refund on the visa fee if it's rejected?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Miles fee of 20K will be refunded.
                            </li>
                            <li>Visa fee of $500 will not be refunded.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Can I come back to India in between semesters?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Yes.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Can I change the university while studying?

                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">No, not at all recommended.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Does the loan cover every single expense of mine?
                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Yes, your stay costs are also covered.

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Can my spouse and children join me?

                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Yes, your spouse and children will be getting a F2 dependent visa.
                            </li>
                            <li>But F2 visa holders are not allowed to work. So if your spouse also wants to work, he/she
                                can follow a similar pathway of Masters with STEM.

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>Can I stay with my relatives while pursuing Masters in the US and will it reduce my
                            expenses or
                            do I have to take out a loan for the same amount?


                        </b>
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">Yes, you can stay with relatives and save on stay cost
                            </li>

                        </ul>
                    </li>
                </ul>
            </vs-col>
        </vs-row>
        <vs-row class="mb-6 mt-6 row_data">
            <vs-col vs-w="12">
                <p class="mb-4"><b>Miles India Pathway - “Maybe / No”: </b>
                </p>
                <p class="mt-5"><b>Which program do you want to pursue - CPA or CMA? </b><br>Suggest CMA only if college
                    student. <br>For
                    everyone else, start with CPA [CMA only if they ask].


                </p>
                <p class="mb-4 mt-5"><b>CPA - </b></p>

                <ul>
                    <li>First, let me discuss why CPA?
                        <ul style="list-style-type:circle;">
                            <li>There is a huge shortage of accountants in the US. Because of this, there are
                                lakhs of CPA jobs coming to India. Even fresher CPAs now earn 8 lakhs. Miles is working with all of the
                                Big 4 and MNCs in India for placements.
                            </li>
                            <li>CPA is the equivalent to Indian CA. It has only 4 exams which you can pass in 20 months.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5">Many candidates ask us why CPA has only 4 exams while CA has 20 exams?
                        <ul style="list-style-type:circle;">
                            <li>We can do Indian CA after Class 12th.

                            </li>
                            <li>But US CPA is different – it requires graduation as an eligibility criteria. So it’s
                                basically a university degree plus 4 exams.

                            </li>
                        </ul>
                    </li>
                    <li class="mt-5">Also, the CPA program is designed for full time working professionals and you have a
                        lot of
                        flexibility

                        <ul style="list-style-type:circle;">
                            <li>The CPA exams happen all 365 days a year and you choose your dates. With CPA
                                exams in India,
                                you can appear for only 1 exam at a time. So you can study for 1 exam every 4 months and
                                pass CPA in 16 months. This is totally possible with a full time job if you are able to
                                spend 15-20 hours every week.

                            </li>

                        </ul>
                    </li>
                    <li class="mt-5">More than 80% of CPAs in India are Miles alumni. Our alumni says #CPAmatlabMiles
                        <ul style="list-style-type:circle;">
                            <li>We have an online LMS, live online classes and face to face classes. You will
                                have 360
                                degree support for CPA eligibility, exam and license.
                            </li>
                            <li>You also have videos and masterclasses from Varun Jain – who is the world’s favourite CPA
                                instructor.
                            </li>
                            <li>Once you complete your CPA, Miles provides job assitance in India..
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5">The next batch start for the 2024 edition is on <b>30th June 2024.</b>
                    </li>
                    <li class="mt-5"> &#60;Eligibility discussion: We also have a dedicated CPA team to guide you on your CPA
                            eligibility.
                            Based on your qualification
                        <ul style="list-style-type:square;">
                            <li>If only B.Com - in the US, graduation is 4 years but you have only 3 years. So
                                we will help
                                you with a bridge course.
                            </li>
                            <li>If B.Com + M.Com/MBA - you are eligible.
                            </li>
                            <li>If B.Com + CA - you are eligible as you will get credits for both B.Com and CA.
                            </li>
                            <li>If B.Com + CA Inter - you are eligible as you will get credits for both B.Com and CA Inter.>
                            </li>


                        </ul>
                    </li>
                </ul>
                <p class="mb-4 mt-5"><b>CMA – for college students
                    </b></p>
                <ul>
                    <li class="mt-5">CMA is the US equivalent of Indian CMA. It has only 2 exams which you can pass in the
                        next 12
                        months.
                    </li>
                    <li class="mt-5">Many candidates ask us why CMA has only 2 exams while Indian CMA has 20 exams?
                        <ul style="list-style-type:circle;">
                            <li>
                                We can do Indian CMA after Class 12th.

                            </li>
                            <li>But the US CMA is different. You need to have a university degree and pass the 2 exams.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-5"><b>&#60;For students> IMA has a special category of members called student members – which
                            allows you to
                            appear for the CMA exams even before you graduate.
                            Also, in your Miles offering, we have also included the AICPA certificate in tax staff 
                            essentials which is worth 70 thousand rupees. This is free and will be relevant if you are 
                            looking for a career in US tax where there are many jobs.
                        </b></li>
                    <li class="mt-5"><b>&#60;For professionals> The CMA program is designed for full time working professionals
                            and you have
                            a lot of flexibility. CMA exams happen 6 months in a year and you choose your dates. Also, you
                            can appear for only 1 exam at a time. This is totally doable alongside a full time job if you
                            are able to spend 15-20 hours every week.
                        </b></li>

                    <li class="mt-5">
                        The next batch start for the 2024 edition is on <b>30th June 2024.</b>
                    </li>
                    <li class="mt-5">Miles is IMA’s platinum partner. Did you know that Miles is the world’s largest CMA
                        course provider?
                        <ul style="list-style-type:circle;">
                            <li class="mt-5">We have a comprehensive online LMS, live online classes and face to face
                                classes. </li>
                            <li>Plus you also have videos and masterclasses from Varun Jain – who is the world’s favourite
                                CPA and CMA instructor.
                            </li>
                            <li>Once you complete your CMA, Miles provides placement assistance in India for US based companies.
                            </li>
                        </ul>
                    </li>
                    <p class="mt-5"><b><u>As per new update by Varun - CPA Pitch</u>
                    </b></p>
                    <p class="mt-5">Hi, this is <b>{{ spoc_name }}</b> from Miles. This call is regarding the enquiry you have made with us. Am I talking to <b>{{ studentDetails.person_name }}</b>? </p>
                    <p class="mt-5"><b>{{ studentDetails.person_name }}</b>, Miles has an amazing pathway for Indian accountants to work in the US. Are you interested in going to the US in Jan’25 ? (If No) Continue with CPA/CMA</p><br>
                    <p>- To proceed further, can you please let me know if you are a working professional or a student?</p>
                    <p>- What’s your year of graduation?</p>
                    <p>- We have a CPA program for you which has 4 exams and you can complete the course within 20 months.CPA is the US equivalent of CA and we have Jobs in India and US for this in Big 4 and MNCs.</p>
                    <p class="mt-5">Wanted to let you know that 80% of the CPA in India are Miles Alumni and that’s why #CPAMatlabmiles </p>

                    <p class="mt-5"><b><u>As per new update by Varun - CMA Pitch</u></b></p>
                    <p class="mt-5">Hi, this is <b>{{ spoc_name }}</b> from Miles. This call is regarding the enquiry you have made with us. Am I talking to <b>{{ studentDetails.person_name }}</b>? </p>
                    <p class="mt-5"><b>{{ studentDetails.person_name }}</b>, Miles has an amazing pathway for Indian accountants to work in the US. Are you interested in going to the US in Jan’25 ? (If No) Continue with CPA/CMA</p><br>
                    <p>- To proceed further, can you please let me know if you are a working professional or a student?</p>
                    <p>- What’s your year of graduation?</p>
                    <p>- We have a CMA program for you which has 2 exams and you can complete the course within 12 months. You can appear for the CMA exam prior to graduation and we have Jobs in India and US for this in MNC’s.</p>

                </ul>
                


            </vs-col>
        </vs-row>




    </div>
</template>
<script>
import moment from "moment";
export default {
    props: ["studentDetails", "upcoming_visits"],
    data() {
        return {
            spoc_name: "",

        }
    },
    mounted() {
        this.spoc_name = localStorage.getItem("spoc_name");
        console.log("fdg", this.studentDetails, this.upcoming_visits)
    },
    methods: {
        getDate(stamp){
            return moment.unix(stamp).format("DD-MMM-YYYY")
        }
    }

}

</script>
<style scoped>
h1 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

.get_data {
    width: 250px;
    margin-block: 10px;
}

.hide_select_data {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 0px;
    background-color: #ffffff;
}

ul {
    list-style-type: disc;
    margin-inline-start: 44px;
}

ol {
    list-style-type: decimal;
    margin-inline-start: 44px;
}

li {
    font-size: 14px;
}

label {
    font-size: 16px;
}

li>p {
    font-size: 14px;
}

p {
    font-size: 14px;
}

.row_data {
    padding: 0rem 2rem;
}
/* .highlighted_area{
    background-color: yellow;
} */
</style>