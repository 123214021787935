<template>
  <div>
    <vs-row v-for="item in lth_data" :key="item.id" style="height: fit-content">
      <vs-row style="margin-bottom: 1%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          <vs-button :color="getColor(item)" type="filled">
            <b>{{ formatDate(item.created_at) }}</b
            ><br />
          </vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
          <b>- {{ item.transfered_by_name }} {{item.transfered_by_last_name}} - {{item.team}} - {{item.city_classification}}</b>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <vs-card>
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                ><h6>
                  <b>
                    {{ item.previous_spoc_name ? item.previous_spoc_name : "-" }} {{item.previous_spoc_last_name}}
                    <vs-icon
                      icon="forward"
                      color="dark"
                      style="margin-right: 4px; position: relative; top: 2px"
                    ></vs-icon>
                    {{ item.current_spoc_name ? item.current_spoc_name: "-" }} {{item.current_spoc_last_name}}
                  </b>{{" | "+item.source}} 
                </h6></vs-col
              >
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import constants from "../../../../constants.json";
export default {
  props: {
    studentDetails: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.getLeadTransferHistory();
    console.log("student details", this.studentDetails);
  },
  data() {
    return {
      lth_data: [],
    };
  },
  methods: {
    formatDate(created_at) {
      let date = new Date(created_at);
      let formttedDate = moment(date).format("DD-MMM-YYYY h:mm A");
      return formttedDate;
    },
    getColor(fetch) {
      // console.log(fetch);
      // return "primary";
      switch (fetch.added_by_team) {
        case "ac1":
          return "dark";
        default:
          return "primary";
      }
    },
    getLeadTransferHistory() {
      console.log("identity", this.studentDetails.mwb.identity);
      let url = `${constants.SERVER_API}leadTransferHistory?mwb_id=${this.studentDetails.mwb.id}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.lth_data = response.data.data.data;
          console.log("lth data", this.lth_data);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>